import title from '../../../helpers/title';

const errorMessages = (doc, data, info, docStyles) => {
  const { Response } = data;

  title(
    doc,
    docStyles,
    'Error Message(s)',
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );

  const columnsBody = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];
  const rowsBody = [];

  // DATA SOURCE RESPONSE
  let visaEntitlement = Response.serviceResponses['Australia Visa Entitlement'];
  let residentialLookup =
    Response.serviceResponses['Australia Residential Lookup'];
  let visa =
    Response.serviceResponses['Australia Government Identity Documents 4'];
  let residential = Response.serviceResponses['Australia Residential'];
  let propertyOwnership =
    Response.serviceResponses['Australia Property Ownership'];
  let medicareCard =
    Response.serviceResponses['Australia Government Identity Documents 3'];
  let driverLicence =
    Response.serviceResponses['Australia Government Identity Documents 1'];
  let passport =
    Response.serviceResponses['Australia Government Identity Documents 2'];
  let centerlinkCard =
    Response.serviceResponses['Australia Government Identity Documents 5'];
  let birthCertificate =
    Response.serviceResponses['Australia Government Identity Documents 6'];
  let creditBureau = Response.serviceResponses['Australia Credit Bureau'];
  let citizens = Response.serviceResponses['Australia Citizens'];
  let payroll = Response.serviceResponses['Australia Payroll'];
  let superannuation = Response.serviceResponses['Australia Superannuation'];
  let deathCheck = Response.serviceResponses['Australia Death Check'];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  // AUSTRALIA VISA ENTITLEMENT ERRORS
  if (visaEntitlement) {
    let status;
    if (visaEntitlement.status === 3) {
      status = visaEntitlement.errorMessage
        ? visaEntitlement.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Visa Entitlement',
      col2: status,
    });
  } else {
    visaEntitlement = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA RESIDENTIAL LOOKUP ERRORS
  if (residentialLookup) {
    let status;
    if (residentialLookup.status === 3) {
      status = residentialLookup.errorMessage
        ? residentialLookup.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Residential Lookup',
      col2: status,
    });
  } else {
    residentialLookup = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA VISA ERRORS
  if (visa) {
    let status;
    if (visa.status === 3) {
      status = visa.errorMessage ? visa.errorMessage : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Visa',
      col2: status,
    });
  } else {
    visa = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA RESIDENTIAL ERRORS
  if (residential) {
    let status;
    if (residential.status === 3) {
      status = residential.errorMessage
        ? residential.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Residential',
      col2: status,
    });
  } else {
    residential = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA PROPERTY OWNER SHIP ERRORS
  if (propertyOwnership) {
    let status;
    if (propertyOwnership.status === 3) {
      status = propertyOwnership.errorMessage
        ? propertyOwnership.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Property Ownership',
      col2: status,
    });
  } else {
    propertyOwnership = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA MEDICARE CARD ERRORS
  if (medicareCard) {
    let status;
    if (medicareCard.status === 3) {
      status = medicareCard.errorMessage
        ? medicareCard.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Medicare Card',
      col2: status,
    });
  } else {
    medicareCard = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA DRIVER LICENCE ERRORS
  if (driverLicence) {
    let status;
    if (driverLicence.status === 3) {
      status = driverLicence.errorMessage
        ? driverLicence.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Driver Licence',
      col2: status,
    });
  } else {
    driverLicence = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA PASSPORT ERRORS
  if (passport) {
    let status;
    if (passport.status === 3) {
      status = passport.errorMessage
        ? passport.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Passport',
      col2: status,
    });
  } else {
    passport = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA CENTRELINK CARD ERRORS
  if (centerlinkCard) {
    let status;
    if (centerlinkCard.status === 3) {
      status = centerlinkCard.errorMessage
        ? centerlinkCard.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Centrelink Card',
      col2: status,
    });
  } else {
    centerlinkCard = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA CENTRELINK CARD ERRORS
  if (birthCertificate) {
    let status;
    if (birthCertificate.status === 3) {
      status = birthCertificate.errorMessage
        ? birthCertificate.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Birth Certificate',
      col2: status,
    });
  } else {
    birthCertificate = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA CREDIT BUREAU
  if (creditBureau) {
    let status;
    if (creditBureau.status === 3) {
      status = creditBureau.errorMessage
        ? creditBureau.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Credit Bureau',
      col2: status,
    });
  } else {
    creditBureau = { verifications: {}, returnedData: {} };
  }

  if (deathCheck) {
    let status;
    if (deathCheck.status === 3) {
      status = creditBureau.errorMessage
        ? creditBureau.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Death Check',
      col2: status,
    });
  } else {
    deathCheck = { verifications: {}, returnedData: {} };
  }

  if (payroll) {
    let status;
    if (payroll.status === 3) {
      status = payroll.errorMessage
        ? payroll.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Payroll',
      col2: status,
    });
  } else {
    payroll = { verifications: {}, returnedData: {} };
  }

  if (superannuation) {
    let status;
    if (superannuation.status === 3) {
      status = superannuation.errorMessage
        ? superannuation.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Superannuation',
      col2: status,
    });
  } else {
    superannuation = { verifications: {}, returnedData: {} };
  }

  if (citizens) {
    let status;
    if (creditBureau.status === 3) {
      status = citizens.errorMessage
        ? citizens.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Australia Citizens',
      col2: status,
    });
  } else {
    citizens = { verifications: {}, returnedData: {} };
  }

  if (watchlistAML) {
    let status;
    if (watchlistAML.status === 3) {
      status = watchlistAML.errorMessage
        ? watchlistAML.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Global Watchlist AML',
      col2: status,
    });
  } else {
    watchlistAML = { verifications: {}, returnedData: {} };
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable({ 
    body: rowsBody, 
    columns: columnsBody,
    didParseCell: (data) => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === 'col1') {
        cell.styles.textColor = docStyles.color;
      }
    },
    showHead: 'never',
    styles: {
      textColor: docStyles.colorText,
      font: docStyles.fontFamily.time,
      ...docStyles.tableStyles,
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
      },
    },
    // margin: { bottom: 100 }, // PREVENTS OVERLAP WITH FOOTER
    startY: finalY,
  });
};

export default errorMessages;
