import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// UI components
import Button from '../../../components/btn/Button';

// Child Components
import FileUpload from './fileUpload/FileUpload';
import CameraUpload from './CameraUpload';

// Utilities
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';
import dataURItoBlobUTL from '../../../utils/dataURItoBlobUTL';
import blobToFileUTL from '../../../utils/blobToFileUTL';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

//  Actions
import {
  bioTriggerBooleansAXN,
  saveIdentityDocumentsAXN,
  daonDocumentVerificationAXN
} from '../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../actions/alert/alertActions';

import COUNTRIES_FOR_TEMPLATE from "../../../constants/countriesForTemplate";
import { CROSS_BORDER_VERIFICATION } from "../../../constants/crossBorderDataSources";

const DocumentScan = ({
  authRXS,
  countryRXS,
  daonDocumentVerificationAXN,
  bioTriggerBooleansAXN,
  saveIdentityDocumentsAXN,
  setAlertAXN,
  biometricsRXS,
  history
}) => {
  const PREVIOUS_PAGE_ROUTE = COUNTRIES_FOR_TEMPLATE.includes(CROSS_BORDER_VERIFICATION)
    ? "/form-overview-template"
    : "/form-overview"

  const [fileUploadPage, toggleFileUploadPage] = useState(true);
  const [uploadedDocuments, setUploadedDocuments] = useState({
    front: undefined,
    back: undefined
  });

  const handleOnUpload = (document, side) => {
    setUploadedDocuments({
      ...uploadedDocuments,
      [side]: document
    });
  }

  const handleClearDocument = (side) => {
    setUploadedDocuments({
      ...uploadedDocuments,
      [side]: undefined
    });
  }

  const verifyFiles = () => {
    if (uploadedDocuments.front) return true;

    setAlertAXN(transUTL('translateBiometrics.noFileError'), 'error');
    return false;
  }

  const handleOnSubmit = () => {
    if (!verifyFiles()) return;

    let documentFiles = []

    //const blob = dataURItoBlobUTL(uploadedDocuments.front.dataURI, 'ocrDocument - front'); //not working in Thai OCR
    const blob = dataURItoBlobUTL(uploadedDocuments.front.dataURI, uploadedDocuments.front.name);
    const file = blobToFileUTL(blob);
    documentFiles.push(file);

    if (!isEmptyVAL(uploadedDocuments.back)) {
      const blob_back = dataURItoBlobUTL(uploadedDocuments.back.dataURI, uploadedDocuments.back.name);
      const file_back = blobToFileUTL(blob_back);
      documentFiles.push(file_back);
    }

    // TODO: I want to get rid of this.
    let countryCode = '';
    const selectedCountry = authRXS.user.countries.find(
      (item) => item.country.toLowerCase() === countryRXS
    );
    if (selectedCountry) countryCode = selectedCountry.code.alpha2;


    // Need to add this otherwise things break.
    // Please help me, this code base is a mess.
    const frontDataURI = uploadedDocuments.front ? uploadedDocuments.front.dataURI : null;
    const backDataURI = uploadedDocuments.back ? uploadedDocuments.back.dataURI : null;
    const multiDocs = [];
    if (frontDataURI) multiDocs.push(frontDataURI);
    if (backDataURI) multiDocs.push(backDataURI);
    saveIdentityDocumentsAXN(multiDocs);
    bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: true, isDocUploaded: false});

    // TODO: Need to redo this function
    daonDocumentVerificationAXN(
      documentFiles,
      'licence',
      countryRXS,
      countryCode,
      history
    );

    loggerUTL('DOCUMENTS SUBMITTED!!!');
  }


  return (
    <div className="common-form">
      <header className="header-primary">
        <h2>{transUTL('translateBiometrics.docScan')}</h2>
      </header>
      <div className="common-form__body ocr">
        <div
          className="ocr__camera-toggle"
        >
          <h2 onClick={() => toggleFileUploadPage(!fileUploadPage)}>
            {
              fileUploadPage ?
                <span style={{ verticalAlign: '0.5rem' }}>{transUTL('translateBiometrics.takeAPicture')}</span>
                :
                <span style={{ verticalAlign: '0.5rem' }}>{transUTL('translateBiometrics.uploadAFile')}</span>
            }
            <span className="material-icons">
              double_arrow
            </span>
          </h2>
        </div>
        {
          fileUploadPage ?
            <FileUpload
              uploadedDocuments={uploadedDocuments}
              onUpload={handleOnUpload}
              onClear={handleClearDocument}
              alertAXN={setAlertAXN}
            />
            :
            <CameraUpload
              uploadedDocuments={uploadedDocuments}
              onUpload={handleOnUpload}
              clearDocument={handleClearDocument}
            />
        }
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to={PREVIOUS_PAGE_ROUTE}
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
}

DocumentScan.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  daonDocumentVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  countryRXS: state.countryRXS,
  authRXS: state.authRXS,
  biometricsRXS: state.biometricsRXS
});

export default connect(mapStateToProps, {
  daonDocumentVerificationAXN,
  bioTriggerBooleansAXN,
  saveIdentityDocumentsAXN,
  setAlertAXN
})(DocumentScan);