const dataSourcesBR = {
  brazilNationalID: {
    title: "Brazil National ID",
    safeHarbourScore: "Safe Harbour Score",
    verified: "Verified",
    addressMatchScore: "Address Match Score",
    nameMatchScore: "Name Match Score",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    fullName: "Full Name",
    dateOfBirth: "Date of Birth",
    streetName: "Street",
    locality: "Locality",
    district: "District",
    postCode: "Post Code",
    cpf: "CPF"
  },
  taxRegistration: {
    title: "Brazil Tax Registration",
    safeHarbourScore: "Safe Harbour Score",
    verified: "Verified",
    addressMatchScore: "Address Match Score",
    nameMatchScore: "Name Match Score",
    fullName: "Full Name",
    dateOfBirth: "Date of Birth",
    cpf: "CPF"
  }
};

export default dataSourcesBR;
