import store from '../../../../store/store';
import { retainFormDataAXN, saveFormDataAXN } from '../../../../actions/formData/formDataActions';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const formDataSessionDataRestore = () => {
  loggerUTL('FORM DATA SESSION DATA RESTORE...');

  /*
    This is only used by countries where the template is applied.
  */
  const country = sessionStorage.getItem('selectedCountry');
  const formDataName = `${country}FormData_Template`;

  if(sessionStorage[formDataName]) {
    store.dispatch(
      retainFormDataAXN(JSON.parse(sessionStorage[formDataName]), country)
    );
  }

  /* ============================================
                  AUSTRALIA
   ============================================ */
  if (sessionStorage.australiaFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.australiaFormData), 'australia')
    );
  }

  /* ============================================
                  BRAZIL
   ============================================ */
  if (sessionStorage.brazilFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.brazilFormData), 'brazil')
    );
  }
  /* ============================================
                  CHINA
   ============================================ */
  if (sessionStorage.chinaFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.chinaFormData), 'china')
    );
  }

  /* ============================================
                  BRAZIL
   ============================================= */
  if (sessionStorage.brazilFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.brazilFormData), 'brazil')
    );
  }

  /* ============================================
                  INDIA
   ============================================ */
  if (sessionStorage.indiaFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.indiaFormData), 'india')
    );
  }

  /* ============================================
                  INDONESIA
   ============================================ */
  if (sessionStorage.indonesiaFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.indonesiaFormData), 'indonesia')
    );
  }

  /* ============================================
                  MALAYSIA
   ============================================ */
  if (sessionStorage.malaysiaFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.malaysiaFormData), 'malaysia')
    );
  }

  /* ============================================
                  VIETNAM
   ============================================ */
  if (sessionStorage.vietnamFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.vietnamFormData), 'vietnam')
    );
  }

  /* ============================================
                  PHILIPPINES
   ============================================ */
  if (sessionStorage.philippinesFormData) {
    store.dispatch(
      saveFormDataAXN(
        JSON.parse(sessionStorage.philippinesFormData),
        'philippines'
      )
    );
  }

  /* ============================================
                  CAMBODIA
   ============================================ */
  if (sessionStorage.cambodiaFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.cambodiaFormData), 'cambodia')
    );
  }

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  if (sessionStorage.newzealandFormData) {
    store.dispatch(
      saveFormDataAXN(
        JSON.parse(sessionStorage.newzealandFormData),
        'newzealand'
      )
    );
  }

  /* ============================================
                  Nigeria
  ============================================ */
  if (sessionStorage.nigeriaFormData) {
    store.dispatch(
      saveFormDataAXN(
        JSON.parse(sessionStorage.nigeriaFormData),
        'nigeria'
      )
    );
  }

  /* ============================================
                  HONG KONG
   ============================================ */
  if (sessionStorage.hongkongFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.hongkongFormData), 'hongkong')
    );
  }

  /* ============================================
                  SINGAPORE
   ============================================ */
  if (sessionStorage.singaporeFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.singaporeFormData), 'singapore')
    );
  }

  /* ============================================
                  UNITED STATES
   ============================================ */
  if (sessionStorage.unitedstatesFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.unitedstatesFormData), 'unitedstates')
    );
  }

  /* ============================================
                  MEXICO
   ============================================ */
  if (sessionStorage.mexicoFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.mexicoFormData), 'mexico')
    );
  }

  /* ============================================
                  GERMANY
   ============================================ */
  if (sessionStorage.germanyFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.germanyFormData), 'germany')
    );
  }

  /* ============================================
                   FRANCE
   ============================================ */
  if (sessionStorage.franceFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.franceFormData), 'france')
    );
  }

  /* ============================================
                  ITALY
  ============================================ */
  if (sessionStorage.italyFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.italyFormData), 'italy')
    );
  }

  /* ============================================
                 SPAIN
 ============================================ */
  if (sessionStorage.spainFormData) {
    store.dispatch(
      saveFormDataAXN(JSON.parse(sessionStorage.spainFormData), 'spain')
    );
  }

  /* ============================================
                  WATCHLIST AML
   ============================================ */
  if (sessionStorage.watchlistAMLFormData) {
    store.dispatch(
      saveFormDataAXN(
        JSON.parse(sessionStorage.watchlistAMLFormData),
        'watchlistAML'
      )
    );
  }

  /* ============================================
                  MULTI SERVICE
   ============================================ */
  if (sessionStorage.multiServiceFormData) {
    store.dispatch(
      saveFormDataAXN(
        JSON.parse(sessionStorage.multiServiceFormData),
        'multiService'
      )
    );
  }
};

export default formDataSessionDataRestore;
