import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { saveDataSourcesAXN } from '../../actions/dataSources/dataSourcesActions';
import { setAlertAXN } from '../../actions/alert/alertActions';

// BUTTON
import Button from '../../components/btn/Button';
import DropdownNon from '../../components/dropdown/DropdownNon';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import {
  saveSelectedDateAXN,
  getUsageReportDataAXN,
} from '../../actions/usageReport/usageReportAction';

const DateSelection = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  usageReportRXS,
  saveDataSourcesAXN,
  saveSelectedDateAXN,
  getUsageReportDataAXN,
  setAlertAXN,
  history,
}) => {
  const { user } = authRXS;
  const [viewDaySTH, setViewDaySTH] = useState({
    weeklyToggle: false,
    monthlyToggle: false,
  });
  const [reportDateST, setReportDateST] = useState({ date: '', type: '' });

  useEffect(() => {
    getUsageReportDataAXN();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { selectedDate, type } = usageReportRXS;
    setReportDateST({ ...reportDateST, date: selectedDate, type });
    if (!isEmptyVAL(type))
      setViewDaySTH({
        ...reportDateST,
        [`${type}Toggle`]: !viewDaySTH[`${type}Toggle`],
      });
    // eslint-disable-next-line
  }, []);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    if (isEmptyVAL(reportDateST.date))
      return setAlertAXN(transUTL('translateAlertMsg.dateSelection'), 'error');

    saveSelectedDateAXN({
      ...reportDateST,
      type: reportDateST.type,
      date: reportDateST.date,
    });

    history.push(
      `/date-selection/client-selection/:${user.name.toLowerCase()}`
    );
  };

  // HANDLE ON CHANGE
  const handleOnChange = (e) =>
    setViewDaySTH({ ...viewDaySTH, [e.target.name]: e.target.value });

  // HANDLE RADIO BUTTON
  const handleRadioButton = (opt) =>
    setReportDateST({ ...reportDateST, date: opt });

  // HANDLE TOGGLE VIEW
  const handleToggleView = (opt) => {
    if (opt === 'weekly') {
      setViewDaySTH({
        weeklyToggle: !viewDaySTH.weeklyToggle,
        monthlyToggle: false,
      });
    } else if (opt === 'monthly') {
      setViewDaySTH({
        weeklyToggle: false,
        monthlyToggle: !viewDaySTH.monthlyToggle,
      });
    }

    setReportDateST({ ...reportDateST, type: opt });
  };

  // WEEKLY / MONTHLY DATA
  const weekly = usageReportRXS.chartData.filter(
    (item) => item.type === 'Weekly'
  );
  const monthly = usageReportRXS.chartData.filter(
    (item) => item.type === 'Monthly'
  );

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>Weekly / Monthly selection</h2>
        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        {!isEmptyVAL(weekly) && (
          <Fragment>
            <div
              className="common-form__box"
              onClick={() => handleToggleView('weekly')}
            >
              <h3 className="common-form__title">Weekly</h3>
              <span className="material-icons common-form__arrow">
                arrow_drop_down
              </span>
            </div>

            {viewDaySTH.weeklyToggle && (
              <DropdownNon
                handleOnChange={handleOnChange}
                target={reportDateST.date}
                obj={weekly}
                objKey="date"
                handelRadio={handleRadioButton}
                name="reportDate"
                transType=""
              />
            )}
          </Fragment>
        )}
        {!isEmptyVAL(monthly) && (
          <Fragment>
            <div
              className="common-form__box"
              onClick={() => handleToggleView('monthly')}
            >
              <h3 className="common-form__title">Monthly</h3>
              <span className="material-icons common-form__arrow">
                arrow_drop_down
              </span>
            </div>

            {viewDaySTH.monthlyToggle && (
              <DropdownNon
                handleOnChange={handleOnChange}
                target={reportDateST.date}
                obj={monthly}
                objKey="date"
                handelRadio={handleRadioButton}
                name="reportDate"
                transType=""
              />
            )}
          </Fragment>
        )}
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to={`/dashboard`}
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

DateSelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  usageReportRXS: PropTypes.object.isRequired,
  saveDataSourcesAXN: PropTypes.func.isRequired,
  saveSelectedDateAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  usageReportRXS: state.usageReportRXS,
});

export default connect(mapStateToProps, {
  saveDataSourcesAXN,
  saveSelectedDateAXN,
  getUsageReportDataAXN,
  setAlertAXN,
})(DateSelection);
