const country = {
  aruba: '阿鲁巴岛',
  afghanistan: '阿富汗',
  angola: '安哥拉',
  anguilla: '安圭拉岛',
  alandIslands: '奥兰群岛',
  albania: '阿尔巴尼亚',
  andorra: '安道尔',
  unitedArabEmirates: '阿拉伯联合酋长国',
  argentina: '阿根廷',
  armenia: '亚美尼亚',
  americanSamoa: '美属萨摩亚',
  antarctica: '南极洲',
  frenchSouthernTerritories: '法属南部领地',
  antiguaAndBarbuda: '安提瓜和巴布达',
  australia: '澳大利亚',
  austria: '奥地利',
  azerbaijan: '阿塞拜疆',
  burundi: '布隆迪',
  belgium: '比利时',
  benin: '贝宁',
  bonaireSintEustatiusAndSaba: '博内尔，圣尤斯特歇斯和萨巴',
  burkinaFaso: '布基纳法索',
  bangladesh: '孟加拉国',
  bulgaria: '保加利亚',
  bahrain: '巴林',
  bahamas: '巴哈马',
  bosniaAndHerzegovina: '波斯尼亚和黑塞哥维那（简称：波黑',
  saintBarthélemy: '圣巴塞洛缪',
  belarus: '白俄罗斯',
  belize: '伯利兹',
  bermuda: '百慕大',
  bolivia: '玻利维亚（多民族国）',
  brazil: '巴西',
  barbados: '巴巴多斯',
  bruneiDarussalam: '文莱达鲁萨兰国',
  bhutan: '不丹',
  bouvetIsland: '布维岛',
  botswana: '博茨瓦纳',
  centralAfricanRepublic: '中非共和国',
  canada: '加拿大',
  cocosKeelingIslands: '科科斯（基林）群岛',
  switzerland: '瑞士',
  chile: '智利',
  china: '中国',
  côteDIvoire: "Côte d'Ivoire",
  cameroon: '喀麦隆',
  congoDemocraticRepublic: '刚果民主共和国',
  congo: '刚果',
  cookIslands: '库克群岛',
  colombia: '哥伦比亚',
  comoros: '科摩罗',
  caboVerde: '佛得角',
  costaRica: '哥斯达黎加',
  cuba: '古巴',
  curaçao: '库拉索',
  christmasIsland: '圣诞岛',
  caymanIslands: '开曼群岛',
  cyprus: '塞浦路斯',
  czechia: '捷克语',
  germany: '德国',
  djibouti: '吉布地',
  dominica: '多米尼加',
  denmark: '丹麦',
  dominicanRepublic: '多明尼加共和国',
  algeria: '阿尔及利亚',
  ecuador: '厄瓜多尔',
  egypt: '埃及',
  eritrea: '厄立特里亚',
  westernSahara: '西撒哈拉',
  spain: '西班牙',
  estonia: '爱沙尼亚',
  ethiopia: '埃塞俄比亚',
  finland: '芬兰',
  fiji: '斐济',
  falklandIslandsMalvinas: '福克兰群岛（马尔维纳斯）',
  france: '法国',
  faroeIslands: '法罗群岛',
  micronesia: '密克罗尼西亚（联邦国）',
  gabon: '加蓬',
  unitedKingdomOfGreatBritainAndNorthernIreland:
    '大不列颠及北爱尔兰联合王国',
  greatbritain: '大不列颠',
  georgia: '佐治亚州',
  guernsey: '根西岛',
  ghana: '加纳',
  gibraltar: '直布罗陀',
  guinea: '几内亚',
  guadeloupe: '瓜德罗普岛',
  gambia: '冈比亚',
  guineaBissau: '几内亚比绍',
  equatorialGuinea: '赤道几内亚',
  greece: '希腊',
  grenada: '格林纳达',
  greenland: '格陵兰',
  guatemala: '危地马拉',
  frenchGuiana: '法属圭亚那',
  guam: '关岛',
  guyana: '圭亚那',
  hongKong: '香港',
  heardIslandAndMcDonaldIslands: '希尔德岛和麦当劳群岛',
  honduras: '洪都拉斯',
  croatia: '克罗地亚',
  haiti: '海地',
  hungary: '匈牙利',
  indonesia: '印度尼西亚',
  isleOfMan: '马恩岛',
  india: '印度',
  britishIndianOceanTerritory: '英属印度洋领地',
  ireland: '爱尔兰',
  iran: '伊朗（伊朗伊斯兰共和国）',
  iraq: '伊拉克',
  iceland: '冰岛',
  israel: '以色列',
  italy: '意大利',
  jamaica: '牙买加',
  jersey: '泽西岛',
  jordan: '约旦',
  japan: '日本',
  kazakhstan: '哈萨克斯坦',
  kenya: '肯尼亚',
  kyrgyzstan: '吉尔吉斯斯坦',
  cambodia: '柬埔寨',
  kiribati: '基里巴斯',
  saintKittsAndNevis: '圣基茨和尼维斯',
  korea: '韩国',
  kuwait: '科威特',
  laoPeoplesDemocraticRepublic: "Lao People's Democratic Republic",
  lebanon: '黎巴嫩',
  liberia: '利比里亚',
  libya: '利比亚',
  saintLucia: '圣卢西亚',
  liechtenstein: '列支敦士登',
  sriLanka: '斯里兰卡',
  lesotho: '莱索托',
  lithuania: '立陶宛',
  luxembourg: '卢森堡',
  latvia: '拉脱维亚',
  macao: '澳门',
  saintMartinFrenchPart: '圣马丁（法语部分）',
  morocco: '摩洛哥',
  monaco: '摩纳哥',
  moldova: '摩尔多瓦共和国',
  madagascar: '马达加斯加',
  maldives: '马尔代夫',
  mexico: '墨西哥',
  marshallIslands: '马绍尔群岛',
  macedonia: '马其顿，前南斯拉夫共和国',
  mali: 'Mali',
  malta: '马耳他',
  myanmar: '缅甸',
  montenegro: '黑山共和国',
  mongolia: '蒙古',
  northernMarianaIslands: '北马里亚纳群岛',
  mozambique: '莫桑比克',
  mauritania: '毛里塔尼亚',
  montserrat: '蒙特塞拉特',
  martinique: '马提尼克岛',
  mauritius: '毛里求斯',
  malawi: '马拉维',
  malaysia: '马来西亚',
  mayotte: '丢失',
  namibia: '纳米比亚',
  newCaledonia: '新喀里多尼亚',
  niger: '尼日尔',
  norfolkIsland: '诺福克岛',
  nigeria: '奈及利亚',
  nicaragua: '尼加拉瓜',
  niue: '纽埃',
  netherlands: '荷兰',
  norway: '挪威',
  nepal: '尼泊尔',
  nauru: '瑙鲁',
  newZealand: '新西兰',
  oman: '阿曼',
  pakistan: '巴基斯坦',
  panama: '巴拿马',
  pitcairn: '皮特凯恩',
  peru: '秘鲁',
  philippines: '菲律宾',
  palau: 'u琉',
  papuaNewGuinea: '巴布亚新几内亚',
  poland: '波兰',
  puertoRico: '波多黎各',
  portugal: '葡萄牙',
  paraguay: '巴拉圭',
  palestine: '巴勒斯坦国',
  frenchpPolynesia: '法属波利尼西亚',
  qatar: '卡塔尔',
  réunion: '会议',
  romania: '罗马尼亚',
  russianFederation: '俄罗斯联邦',
  rwanda: '卢旺达',
  saudiArabia: '沙特阿拉伯',
  sudan: 'Sudan',
  senegal: '塞内加尔',
  singapore: '新加坡',
  southGeorgiaAndTheSouthSandwichIslands:
    '南乔治亚岛和南桑威奇群岛',
  saintHelenaAscensionAndTristanDaCunha:
    '圣海伦娜，提升和特里斯坦达库尼亚',
  svalbardAndJanMayen: '斯瓦尔巴和扬·马延',
  solomonIslands: '所罗门群岛',
  sierraLeone: '塞拉利昂',
  elSalvador: '救星',
  sanMarino: '圣马力诺',
  somalia: '索马里',
  saintPierreAndMiquelon: '圣皮埃尔和密克隆群岛',
  serbia: '塞尔维亚',
  southSudan: '南苏丹',
  saoTomeAndPrincipe: '圣多美和普林西比',
  suriname: '苏里南',
  slovakia: '斯洛伐克',
  slovenia: '斯洛文尼亚',
  sweden: '瑞典',
  eswatini: '斯威士兰',
  sintMaartenDutchPart: '圣马丁（荷兰语部分）',
  seychelles: '塞舌尔',
  syrianArabRepublic: '阿拉伯叙利亚共和国',
  turksAndCaicosIslands: '特克斯和凯科斯群岛',
  chad: '乍得',
  togo: '多哥',
  thailand: '泰国',
  tajikistan: '塔吉克斯坦',
  tokelau: '托克劳',
  turkmenistan: '土库曼斯坦',
  timorLeste: '东帝汶',
  tonga: '汤加',
  trinidadAndTobago: '特立尼达和多巴哥',
  tunisia: '突尼斯',
  turkey: '火鸡',
  tuvalu: '图瓦卢',
  taiwanProvinceOfChina: '中国台湾省',
  tanzania: '坦桑尼亚联合共和国',
  uganda: '乌干达',
  ukraine: '乌克兰',
  unitedStatesMinorOutlyingIslands: '美国本土外小岛屿',
  uruguay: '乌拉圭',
  unitedStatesOfAmerica: '美国',
  uzbekistan: '乌兹别克斯坦',
  saintVincentAndTheGrenadines: '圣文森特和格林纳丁斯',
  venezuela: '委内瑞拉（玻利瓦尔共和国）',
  virginIslandsBritish: '维尔京群岛（英国）',
  virginIslandsUS: '维尔京群岛（美国）',
  vietNam: '越南',
  vanuatu: '瓦努阿图',
  wallisAndFutuna: '瓦利斯和富图纳群岛',
  samoa: '萨摩亚',
  yemen: 'Yemen',
  southAfrica: '南非',
  zambia: '赞比亚',
  zimbabwe: '津巴布韦',
};

export default country;
