const translateCountrySelection = {
  selectCountry: 'PILIH NEGARA',
  subscription:
    'Pilih Wilayah / Negara untuk melihat sumber data yang dilanggan:',
  apac: 'APAC',
  africa: 'AFRIKA',
  europe: 'EROPAH',
  middleEast: 'TIMUR TENGAH',
  theAmericas: 'AMERIKA',
};

export default translateCountrySelection;
