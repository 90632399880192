import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { clearCurrentAlertAXN } from '../../../actions/alert/alertActions';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';

const AlertMsg = ({ alertRXS, clearCurrentAlertAXN }) => {
  let iconType = null;

  if (alertRXS[alertRXS.length - 1].type === 'error') {
    iconType = <span className="material-icons error">cancel</span>;
  } else if (alertRXS[alertRXS.length - 1].type === 'warning') {
    iconType = <span className="material-icons warning">report_problem</span>;
  } else if (alertRXS[alertRXS.length - 1].type === 'success') {
    iconType = <span className="material-icons success">check_circle</span>;
  }

  return (
    alertRXS.length > 0 &&
    alertRXS.map((alert, index) => {
      return (
        <div
          key={alert.id}
          className={`fadeInRightAlert alert-message alert-message-${alert.type}`}
        >
          <span
            className={`material-icons alert-message__close ${alert.type}`}
            onClick={() => clearCurrentAlertAXN(alert)}
          >
            close
          </span>

          <div className="alert-message__main">
            {iconType}
            <div className="alert-message__msg-container">
              <p
                className={`alert-message__text alert-message__type-${alert.type}`}
              >
                {transUTL(`translateAlertMsg.${alert.type}`)}
              </p>

              <p className="alert-message__msg-text">{alert.msg}</p>
            </div>
          </div>
        </div>
      );
    })
  );
};

AlertMsg.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  alertRXS: PropTypes.array.isRequired,
  clearCurrentAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  alertRXS: state.alertRXS,
});

export default connect(mapStateToProps, { clearCurrentAlertAXN })(AlertMsg);
