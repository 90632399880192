const translateTitle = {
  // NAVIGATION
  chinese: 'Cina',
  malay: 'Bahasa Melayu',
  english: 'Bahasa Inggeris',
  logo: 'Logo',
  profile: 'Profil',
  dashboard: 'Papan Pemuka',
  history: 'Sejarah',
  dataAnalytics: 'Analisis Data',
  documentLibrary: 'Perpustakaan Dokumen',
  aboutUs: 'Tentang kita',
  logout: 'Log keluar',
  nextPage: 'Muka surat seterusnya',
  costEstimation: 'Anggaran kos',
  admin: 'Pentadbir',
};

export default translateTitle;
