import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackSK = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {
    personalInformation: {}
  };


  try {
    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    // if (findErrorUTL(errors, 'dateOfBirth')) {
    //   inputError.dateOfBirth = `Date of Birth ${
    //     errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
    //   }`;
    // }

    /* ============================================
            CURRENT RESIDENTAIL ADDRESS
      ============================================ */
      if (findErrorUTL(errors, 'addressElement1')) {
      inputError.addressElement1 = `addressElement1 ${
        errors.find((item) => item.includes('addressElement1')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'addressElement2')) {
      inputError.addressElement3 = `addressElement2 ${
        errors.find((item) => item.includes('addressElement2')).split(':')[1]
      }`;
    }
  
    if (findErrorUTL(errors, 'addressElement3')) {
      inputError.addressElement3 = `addressElement3 ${
        errors.find((item) => item.includes('addressElement3')).split(':')[1]
      }`;
    }

    // if (findErrorUTL(errors, 'addressElement5')) {
    //   inputError.addressElement4 = `addressElement5 ${
    //     errors.find((item) => item.includes('addressElement5')).split(':')[1]
    //   }`;
    // }

  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES SLOVAKIA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackSK;