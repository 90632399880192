import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const IndonesiaResidentialID = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL('translateSearchVerification.dataSourcesID.nik.title')}
      />

      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesID.nik.searchSuccessful'
                    )}
                  </p>
                  <span className="material-icons success">check_circle</span>
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesID.nik.safeHarbourScore'
                    )}
                  </p>
                  {!isEmptyVAL(safeHarbourScore) ? (
                    safeHarbourScore.toLowerCase() === 'add' ? (
                      <span className="material-icons success">add_circle</span>
                    ) : (
                      <span>{safeHarbourScore}</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesID.nik.identityVerified'
                    )}
                  </p>
                  {!isEmptyVAL(identityVerified) ? (
                    identityVerified ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesID.nik.nik'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.nik) ? (
                    verifications.nik ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )
                  }
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL('translateSearchVerification.dataSourcesID.nik.name')}
                  </p>
                  {!isEmptyVAL(verifications.fullName) ? (
                    verifications.fullName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesID.nik.dateOfBirth'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.dateOfBirth) ? (
                    verifications.dateOfBirth ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL('translateSearchVerification.dataSourcesID.nik.gender')}
                  </p>
                  {!isEmptyVAL(verifications.gender) ? (
                    verifications.gender ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesID.nik.nameMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(nameMatchScore) ? (
                    <span>{nameMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesID.nik.addressMatchScore'
              )}
            </p>
            {!isEmptyVAL(addressMatchScore) ? (
              <span>{addressMatchScore}</span>
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesID.nik.address'
              )}
            </p>
            {!isEmptyVAL(returnedData.address) ? (
             <span> {returnedData.address} </span> 
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesID.nik.addressElement2'
              )}
            </p>
            {!isEmptyVAL(verifications.addressElement2) ? (
              verifications.addressElement2 ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                <span className="material-icons error">cancel</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesID.nik.addressElement5'
              )}
            </p>
            {!isEmptyVAL(verifications.addressElement5) ? (
              verifications.addressElement5 ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                <span className="material-icons error">cancel</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
        </div>
        </>
        }
      </div>
    </div>
  );
};

IndonesiaResidentialID.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default IndonesiaResidentialID;
