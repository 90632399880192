import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  getUserTransactionsAXN,
  filterReferenceNumberAXN,
  getUserPIIDataAXN,
} from '../../actions/transactionHistory/transactionHistoryActions';
import { setAlertAXN } from '../../actions/alert/alertActions';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import paginateUTL from '../../utils/paginateUTL';
import windowTopUTL from '../../utils/windowTopUTL';

import TransactionDetails from "./TransactionDetails";

const TransactionHistory = ({
  authRXS,
  countryRXS,
  transHistoryRXS,
  totalRecordRXS,
  getUserTransactionsAXN,
  getUserPIIDataAXN,
  filterReferenceNumberAXN,
  setAlertAXN,
}) => {

  const DATE_OPTION = {
    none: '',
    today: 'today',
    singleDay: 'single_day',
    dateRange: 'date_range',
  }

  const [transSTH, setTransSTH] = useState({
    userTransactions: [], // up to 30 per page
    totalTransactions: 0, // the count/length of total trans
    totalPages: 0, // ??
    currentPage: 1,
    totalItems: 0, // ??

    // FILTER
    filter: 'kms_user',
    kmsRef: '',
    dateStart: '',
    dateEnd: '',
    dateStart_error: '',
    dateEnd_error: '',
    dateOption: DATE_OPTION.none,
    showDetails: false,
    selectedTransactionData: {}
  });

  useEffect(() => {
    // RETRIEVE INITIAL TRANSACTION
    getUserTransactionsAXN(authRXS.user, 0, 'kms_user'); // INITIAL
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const userTransactions = [...transHistoryRXS];
    let totalPages = 1;
    let totalTransactions = 1;

    if (!isEmptyVAL(userTransactions[0])) {
      totalTransactions = userTransactions[0].totalTransactions;
    }

    // 30 = TOTAL TRANSACTIONS RETURNED FROM API
    if (userTransactions.length === 30) {
      totalPages = Math.ceil(userTransactions.length);
    }

    setTransSTH({
      ...transSTH,
      userTransactions,
      totalTransactions,
      totalPages,
    });
    // eslint-disable-next-line
  }, [transHistoryRXS]);

  // HANDLE KMS REF
  const handelKmsRef = (event) =>
    setTransSTH({
      ...transSTH,
      [event.target.name]: event.target.value
    });

  const getCurrentDate = () => moment().format('YYYY-MM-DD');

  // SORT BY DATE IN Ascending OR Descending ORDER
  const onChangeSelectionFilter = (event) => {
    setTransSTH({
      ...transSTH,
      [event.target.name]: event.target.value,
      totalItems: 0, // REST TO AVOID PAGINATION ISSUES
      currentPage: 1, // REST TO AVOID PAGINATION ISSUES
    });
    if (transSTH.kmsRef !== "" || (transSTH.dateStart !== '' && transSTH.dateEnd !== '')) {
      if (event.target.value === 'kms_user') {
        filterReferenceNumberAXN(transSTH.kmsRef, 'kms_user', transSTH.currentPage, transSTH.dateStart, transSTH.dateEnd);
      } else if (event.target.value === 'kms_user_old') {
        filterReferenceNumberAXN(transSTH.kmsRef, 'kms_user_old', transSTH.currentPage, transSTH.dateStart, transSTH.dateEnd);
      }
    }
    else {
      if (event.target.value === 'kms_user') {
        getUserTransactionsAXN(authRXS.user, 0, 'kms_user');
      } else if (event.target.value === 'kms_user_old') {
        getUserTransactionsAXN(authRXS.user, 0, 'kms_user_old');
      }
    }
  };

  // CHANGE DATE OPTIONS
  const onChangeDateSelection = (event) => {
    if (event.target.value === DATE_OPTION.today) {
      let currentDate = getCurrentDate();
      setTransSTH({
        ...transSTH,
        dateStart: currentDate,
        dateEnd: currentDate,
        dateOption: DATE_OPTION.today
      });

    } else if (event.target.value === DATE_OPTION.singleDay) {
      setTransSTH({
        ...transSTH,
        dateStart: '',
        dateEnd: '',
        dateOption: DATE_OPTION.singleDay,
      });

    } else if (event.target.value === DATE_OPTION.dateRange) {
      setTransSTH({
        ...transSTH,
        dateStart: '',
        dateEnd: '',
        dateOption: DATE_OPTION.dateRange
      });

    } else {
      setTransSTH({
        ...transSTH,
        dateStart: '',
        dateEnd: '',
        dateOption: DATE_OPTION.none
      });
    }
  }

  // PROVIDE DATE TO FILTER TRANSACTIONS
  const handleOnChangeDate = (event) => {
    let filledDate = event.target.value; // 'yyyy-mm-dd'
    let maxDate = getCurrentDate();

    let isFutureDate = moment(filledDate).isAfter(maxDate)

    if (transSTH.dateOption === DATE_OPTION.singleDay) {
      setTransSTH({
        ...transSTH,
        dateStart: event.target.value,
        dateEnd: event.target.value,
        dateStart_error: isFutureDate ? transUTL('translateTransactionHistory.noFutureDate') : ''
      });

    } else if (transSTH.dateOption === DATE_OPTION.dateRange) {
      if (event.target.name === 'dateStart') {
        setTransSTH({
          ...transSTH,
          [event.target.name]: event.target.value,
          isFutureDate_start: isFutureDate,
          dateStart_error: isFutureDate ? transUTL('translateTransactionHistory.noFutureDate') : ''
        })
      }
      if (event.target.name === 'dateEnd') {
        setTransSTH({
          ...transSTH,
          [event.target.name]: event.target.value,
          isFutureDate_end: isFutureDate,
          dateEnd_error: isFutureDate ? transUTL('translateTransactionHistory.noFutureDate') : ''
        })
      }
    }

  }

  // When user clicks Nexr or Back button
  const handelNavigation = (opt) => {
    let placeholder = transSTH.currentPage;
    if (opt === 'next') {
      placeholder += 1;
    } else if (opt === 'back') {
      placeholder -= 1;
    }
    if (transSTH.kmsRef === "") {
      getUserTransactionsAXN(authRXS.user, placeholder, transSTH.filter); // transSTH.filter: kms_user == true, "kms_user_old" == false
    }
    else {
      filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, placeholder, transSTH.dateStart, transSTH.dateEnd);
    }
    setTransSTH({
      ...transSTH,
      totalItems: placeholder,
      currentPage: placeholder,
    });
    windowTopUTL();
  };

  // When user clicks page number
  const handleDirectNavigation = (page) => {
    let placeholder = page - 1;

    if (transSTH.kmsRef === "") {
      getUserTransactionsAXN(authRXS.user, placeholder, transSTH.filter);
    }
    else {
      filterReferenceNumberAXN(authRXS.user.name, transSTH.kmsRef, transSTH.filter, page);
    }
    setTransSTH({
      ...transSTH,
      totalItems: placeholder,
      currentPage: page,
    });
    windowTopUTL();
  };

  // WHEN USER CLICK SEARCH BUTTON
  const handleFilterByRefOrDate = (page) => {
    // To make a successful search
    // either DATE or REFERENCE NUMBER should be provided

    if (isEmptyVAL(transSTH.dateStart_error) && isEmptyVAL(transSTH.dateEnd_error)) {
      if (!isEmptyVAL(transSTH.kmsRef)) {
        if (transSTH.dateOption === DATE_OPTION.dateRange) {
          if (isEmptyVAL(transSTH.dateStart) && !isEmptyVAL(transSTH.dateEnd))
            setAlertAXN(transUTL('translateTransactionHistory.requiredDateStart'), 'error')
          else if (isEmptyVAL(transSTH.dateEnd) && !isEmptyVAL(transSTH.dateStart))
            setAlertAXN(transUTL('translateTransactionHistory.requiredDateEnd'), 'error')
          else {
            filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, page, transSTH.dateStart, transSTH.dateEnd);
            setTransSTH({
              ...transSTH,
              currentPage: page,
            });
          }

        } else {
          filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, page, transSTH.dateStart, transSTH.dateEnd);
          setTransSTH({
            ...transSTH,
            currentPage: page,
          });
        }

      } else {
        if (transSTH.dateOption === DATE_OPTION.dateRange) {
          if (isEmptyVAL(transSTH.dateStart) && isEmptyVAL(transSTH.dateEnd)) {
            setAlertAXN(transUTL('translateTransactionHistory.requiredDateOrRef'), 'error')
          } else if (!isEmptyVAL(transSTH.dateStart) && !isEmptyVAL(transSTH.dateEnd)) {
            if (moment(transSTH.dateStart).isAfter(transSTH.dateEnd)) {
              setAlertAXN(transUTL('translateTransactionHistory.startDateLaterThanEndDate'), 'error')
            } else {
              filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, page, transSTH.dateStart, transSTH.dateEnd);
              setTransSTH({
                ...transSTH,
                currentPage: page,
              });
            }
          } else {
            if (isEmptyVAL(transSTH.dateStart))
              setAlertAXN(transUTL('translateTransactionHistory.requiredDateStart'), 'error')
            if (isEmptyVAL(transSTH.dateEnd))
              setAlertAXN(transUTL('translateTransactionHistory.requiredDateEnd'), 'error')
          }

        } else if (transSTH.dateOption === DATE_OPTION.singleDay) {
          if (isEmptyVAL(transSTH.dateStart) && isEmptyVAL(transSTH.dateEnd)) {
            setAlertAXN(transUTL('translateTransactionHistory.requiredDateOrRef'), 'error')
          } else {
            filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, page, transSTH.dateStart, transSTH.dateEnd);
            setTransSTH({
              ...transSTH,
              currentPage: page,
            });
          }

        } else {
          filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, page, transSTH.dateStart, transSTH.dateEnd);
          setTransSTH({
            ...transSTH,
            currentPage: page,
          });
        }
      }
    } else {
      setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error')
    }

  };

  // CLICK NEXT BUTTON
  const handleAddOnePageNumber = (currentPageNumber) => {
    filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, currentPageNumber + 1, transSTH.dateStart, transSTH.dateEnd);
    setTransSTH({
      ...transSTH,
      currentPage: currentPageNumber += 1
    })
    windowTopUTL();
  }
  // CLICK BACK BUTTON
  const handleMinusOnePageNumber = (currentPageNumber) => {
    filterReferenceNumberAXN(transSTH.kmsRef, transSTH.filter, currentPageNumber - 1, transSTH.dateStart, transSTH.dateEnd);
    if (currentPageNumber > 1)
      setTransSTH({
        ...transSTH,
        currentPage: currentPageNumber -= 1
      })
    windowTopUTL();
  }

  const handleShowTransactionDetails = (refNo) => {
    setTransSTH({
      ...transSTH,
      showDetails: !transSTH.showDetails,
      selectedTransactionData: transHistoryRXS.find(row => row.DZReference === refNo)
    })
  }

  // NOTE: Comment the code below temporarily, 
  //       would be in use when backend can get the count of all transactions(totalTransactions) without Time out issue

  // PAGINATION SETUP
  // const { startPage, pages } = paginateUTL(
  //   transSTH.totalItems,
  //   transSTH.totalPages,
  //   transSTH.currentPage,
  //   3,
  //   5,
  //   totalRecordRXS,
  //   transSTH.kmsRef
  // );

  // INDEX PAGINATION
  // const paginationArray = [];
  // for (let index = startPage - 1; index < pages[pages.length - 1]; index++) {
  //   let conditionalClass =
  //     'trans-history__pagination-item trans-history__pagination-number';

  //   if (pages.length === 1) {
  //     conditionalClass = 'trans-history__pagination-items trans-history__pagination-number trans-history__pagination-active';  // added trans-history__pagination-active
  //   }

  //   let page = index + 1; // Page user clicks to view
  //   if (page === transSTH.currentPage) {
  //     conditionalClass += ' trans-history__pagination-active';
  //   }
  //   const item = (
  //     <li
  //       key={page}
  //       className={conditionalClass}
  //       onClick={pages.length > 1 ? () => handleDirectNavigation(page) : () => { }}
  //     >
  //       {page}
  //     </li>
  //   );
  //   paginationArray.push(item);
  // }

  return (
    !transSTH.showDetails
      ? (
        <div className="trans-history common-form">
          <header className="header-primary">
            <h2>
              {transUTL('translateTransactionHistory.searchTransactionHistory')}
            </h2>
          </header>
          <div className="common-form__body">
            {/* FILTERS */}
            <div className="trans-history__filters">
              <p className="trans-history__filter-count">
                {transSTH.userTransactions.length === 1
                  ? `${transSTH.userTransactions.length} ${transUTL(
                    'translateTransactionHistory.search'
                  )}`
                  : `${transSTH.userTransactions.length} ${transUTL(
                    'translateTransactionHistory.searches'
                  )}`}
              </p>

              <div className="trans-history__filter-input">
                <div className="trans-history__div">
                  {/* REF NO. FILTER */}
                  <input
                    type="text"
                    name="kmsRef"
                    value={transSTH.kmsRef}
                    placeholder={transUTL(
                      'translatePlaceholder.others.sortByRefNo'
                    )}
                    onChange={handelKmsRef}
                    onKeyUp={(event) => {
                      const code = event.keyCode || event.which;
                      if (code === 13) {
                        filterReferenceNumberAXN(
                          transSTH.kmsRef,
                          transSTH.dateStart,
                          transSTH.currentPage,
                          transSTH.dateStart,
                          transSTH.dateEnd
                        );
                      }
                    }}
                  />
                  {/* DATE FILTER OPTIONS */}
                  <div className="trans-history__selection">
                    <select onChange={onChangeDateSelection} name="dateFilterOptions">
                      <option value={DATE_OPTION.none}>
                        {transUTL('translateTransactionHistory.searchByDate')}
                      </option>
                      <option value={DATE_OPTION.today}>
                        {transUTL('translateTransactionHistory.today')}
                      </option>
                      <option value={DATE_OPTION.singleDay}>
                        {transUTL('translateTransactionHistory.singleDay')}
                      </option>
                      <option value={DATE_OPTION.dateRange}>
                        {transUTL('translateTransactionHistory.dateRange')}
                      </option>
                    </select>
                  </div>
                </div>
                {/* SEARCH BUTTON */}
                <button
                  onClick={() => {
                    handleFilterByRefOrDate(1);
                  }}
                >
                  <span className="material-icons">search</span>
                  <span>{transUTL('translateTransactionHistory.search')}</span>
                </button>
              </div>

              {/* DATE SORTING & FILTER INPUTS */}
              <div className="trans-history__filter-input">
                {/* DATE FILTER INPUTS*/}
                {transSTH.dateOption === DATE_OPTION.singleDay && (
                  <div className="trans-history__div">
                    <div>
                      <input type='date' name='singleDay' max={getCurrentDate()} onChange={handleOnChangeDate} />
                      {!isEmptyVAL(transSTH.dateStart_error) && (
                        <small className="form-group__error-text">
                          <span className="material-icons">error</span>
                          {transSTH.dateStart_error}
                        </small>
                      )}
                    </div>
                  </div>)}
                {transSTH.dateOption === DATE_OPTION.dateRange && (
                  <div className="trans-history__div">
                    <div>
                      <input type='date' name='dateStart' max={getCurrentDate()} onChange={handleOnChangeDate} />
                      {!isEmptyVAL(transSTH.dateStart_error) && (
                        <small className="form-group__error-text">
                          <span className="material-icons">error</span>
                          {transSTH.dateStart_error}
                        </small>
                      )}
                    </div>
                    <span>{transUTL('translateTransactionHistory.to')}</span>
                    <div>
                      <input type='date' name='dateEnd' max={getCurrentDate()} onChange={handleOnChangeDate} />
                      {!isEmptyVAL(transSTH.dateEnd_error) && (
                        <small className="form-group__error-text">
                          <span className="material-icons">error</span>
                          {transSTH.dateEnd_error}
                        </small>
                      )}
                    </div>
                  </div>)}
                {/* DATE ORDER SORTING */}
                <div className="trans-history__selection filter">
                  <select onChange={onChangeSelectionFilter} name="filter">
                    <option value="kms_user">
                      {transUTL('translateTransactionHistory.new')}
                    </option>
                    <option value="kms_user_old">
                      {transUTL('translateTransactionHistory.old')}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            {/* HISTORY CARDS */}
            {transSTH.userTransactions.map((history, index) => {
              const { PIIDataAvailable, DataSources, DZReference } = history;

              const country = history.Country.split(' ').join('').toLowerCase();

              const countryTrans = `translateCountryNames.${country}`;

              const additionalInfo = {
                country: transUTL(countryTrans),
                countryConditional: history.Country.toLowerCase(),
                kmsReference: history.KMSReference,
                dzReference: history.DZReference,
                clientRef: history.ClientReference,
                totalTransactions: history.TotalTransactions,
                username: authRXS.user.name,
              };

              let datSources = DataSources.map((item) => {
                const dataSource = item.split(' ').join('').toLowerCase(); // JOIN TO A STRING WITH NO SPACE, ALL LOWERCASE
                return transUTL(`translateDataSources.${dataSource}`); // TRANSLATE DATA SOURCES
              });

              // REMOVE EMPTY SLOT
              datSources = datSources.filter(
                (dataSource) => !isEmptyVAL(dataSource)
              );

              return (
                <div
                  key={index}
                  className={
                    PIIDataAvailable
                      ? 'trans-history__transaction trans-history__border-success'
                      : 'trans-history__transaction trans-history__border-error'
                  }
                >
                  <div className="trans-history__availability">
                    {PIIDataAvailable ? (
                      <span className="material-icons icon-size trans-history__icon-success">
                        check_circle
                      </span>
                    ) : (
                      <span className="material-icons icon-size trans-history__icon-error">
                        cancel
                      </span>
                    )}
                  </div>

                  <div className="trans-history__body">
                    <header className="trans-history__header">
                      <h4>{transUTL(countryTrans)}</h4>
                      <p className="trans-history__transaction-timestamp">
                        {history.CreatedAt.split('.')[0].split('T')[0]}
                        &nbsp;&nbsp;
                        {history.CreatedAt.split('.')[0].split('T')[1]}
                        &nbsp;&nbsp;UTC
                      </p>
                    </header>

                    <p>
                      {transUTL(`translateTransactionHistory.dataSource`)}{' '}
                      {datSources.join(', ')}
                    </p>

                    <p>
                      {transUTL(`translateTransactionHistory.reportingRef`)}{' '}
                      {!isEmptyVAL(history.KMSReference) && history.KMSReference}
                      {!isEmptyVAL(history.DZReference) && history.DZReference}
                    </p>

                    <p>
                      {transUTL(`translateTransactionHistory.clientRef`)}{' '}
                      {isEmptyVAL(history.ClientReference)
                        ? transUTL(`translateReuse.na`)
                        : history.ClientReference}
                    </p>
                  </div>

                  <div className="trans-history__div">
                    <button
                      className={
                        PIIDataAvailable
                          ? 'trans-history__btn trans-history__btn-enabled'
                          : 'trans-history__btn trans-history__btn-disabled'
                      }
                      onClick={() => {
                        if (PIIDataAvailable) {
                          getUserPIIDataAXN(additionalInfo, authRXS.user);
                        }
                      }}
                    >
                      {PIIDataAvailable
                        ? transUTL('translateTransactionHistory.generatePDF')
                        : transUTL('translateTransactionHistory.unavailable')}
                    </button>

                    <button
                      className='trans-history__btn trans-history__btn-enabled'
                      onClick={() => handleShowTransactionDetails(DZReference)}
                    >
                      {transUTL('translateTransactionHistory.showDetails')}
                    </button>
                  </div>
                </div>
              );
            })}

            {/* PAGINATION */}
            {/* back button */}
            <ul className="trans-history__pagination">
              <li
                className={
                  transSTH.currentPage <= 1
                    ? 'trans-history__pagination-items trans-history__pagination-opacity-off'
                    : 'trans-history__pagination-item trans-history__pagination-opacity-on'
                }
                // onClick={transSTH.currentPage <= 1 ? () => { } : () => handelNavigation('back')} // previous pagination
                onClick={transSTH.currentPage <= 1 ? () => { } : () => handleMinusOnePageNumber(transSTH.currentPage)}
              >
                <span className="trans-history__pagination-navigation">
                  <span className="material-icons trans-history__arrow">
                    chevron_left
                  </span>
                  <span>{transUTL(`translateTransactionHistory.back`)}</span>
                </span>
              </li>

              {/* page numbers */}
              {/* {paginationArray.map((item) => item)} */}
              <p
                className='trans-history__pagination-items trans-history__pagination-number trans-history__pagination-active'
              >{transSTH.currentPage}
              </p>

              {/* next button */}
              <li
                className={
                  transSTH.userTransactions.length != 30
                    ? 'trans-history__pagination-items trans-history__pagination-opacity-off'
                    : 'trans-history__pagination-item trans-history__pagination-opacity-on'
                }
                // onClick={transSTH.userTransactions.length != 30 ? () => { } : () => handelNavigation('next')} // previous pagination
                onClick={transSTH.userTransactions.length != 30 ? () => { } : () => handleAddOnePageNumber(transSTH.currentPage)}
              >
                <span className="trans-history__pagination-navigation">
                  <span>{transUTL(`translateTransactionHistory.next`)}</span>
                  <span className="material-icons trans-history__arrow">
                    chevron_right
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <TransactionDetails
          data={transSTH.selectedTransactionData}
          handleToggleShowDetails={handleShowTransactionDetails}
        />
      )
  );
};

TransactionHistory.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  transHistoryRXS: PropTypes.array.isRequired,
  totalRecordRXS: PropTypes.array.isRequired,
  getUserTransactionsAXN: PropTypes.func.isRequired,
  getUserPIIDataAXN: PropTypes.func.isRequired,
  filterReferenceNumberAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  transHistoryRXS: state.transHistoryRXS,
  totalRecordRXS: state.totalRecordRXS,
});

export default connect(mapStateToProps, {
  getUserTransactionsAXN,
  getUserPIIDataAXN,
  filterReferenceNumberAXN,
  setAlertAXN,
})(TransactionHistory);
