import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextFieldGroup from '../../../components/common/textFieldGroup/TextFieldGroup';
import { checkLength, checkUniqueChars, checkCharacterTypes } from './validations/passwordValidation';

const PasswordResetForm = ({
  resetPasswordAXN,
  closeModal,
  user
}) => {

  const [passwordFormSTH, setPasswordFromSTH] = useState({
    password: '',
    passwordConfirm: '',
    passwordTouched: false,
    confirmTouched: false,
    isLengthValid: false,
    hasEnoughUniqueChars: false,
    hasEnoughTypes: false,
    isPasswordMatched: false
  });

  const handleOnSubmit = () => {
    resetPasswordAXN(user.username, passwordFormSTH.password);
    closeModal();
  }

  const handleOnPasswordChange = (event) => {
    let updatedPasswordFormSTH;

    if (event.target.name === 'password') {
      updatedPasswordFormSTH = {
        ...passwordFormSTH,
        password: event.target.value,
        passwordTouched: true,
        isLengthValid: checkLength(event.target.value),
        hasEnoughUniqueChars: checkUniqueChars(event.target.value),
        hasEnoughTypes: checkCharacterTypes(event.target.value),
        isPasswordMatched: (event.target.value === passwordFormSTH.passwordConfirm)
      };
    } else {
      updatedPasswordFormSTH = {
        ...passwordFormSTH,
        passwordConfirm: event.target.value,
        confirmTouched: true,
        isPasswordMatched: (event.target.value === passwordFormSTH.password)
      };
    }

    setPasswordFromSTH({
      ...updatedPasswordFormSTH
    });
  };

  const isPasswordValid = () => {
    const {isPasswordMatched, isLengthValid, hasEnoughTypes, hasEnoughUniqueChars} = passwordFormSTH;
    return (isPasswordMatched && isLengthValid && hasEnoughTypes && hasEnoughUniqueChars);
  }

  const isPasswordErrorShown = () => {
    const { passwordTouched, confirmTouched, isPasswordMatched, isLengthValid, hasEnoughUniqueChars, hasEnoughTypes} = passwordFormSTH;
    return ((passwordTouched && (!isLengthValid || !hasEnoughUniqueChars || !hasEnoughTypes)) || (confirmTouched && !isPasswordMatched));
  }

  const isPasswordConfirmErrorShown = () => {
    const { confirmTouched, isPasswordMatched, isLengthValid, hasEnoughUniqueChars,hasEnoughTypes } = passwordFormSTH;
    return (confirmTouched && (!isPasswordMatched || !isLengthValid || !hasEnoughUniqueChars || !hasEnoughTypes));
  }

  return (
    <div>
      <div className="modal__dialogue__body">
        <div className="modal__dialogue__body__content">
          <p className="title"><span>Reset Password for {user.username}</span></p>
          <div
            className="form-overview__form-inputs"
            style={{ top: "10%" }}
          >
            <div className="password-hint">
              <p>Password requirements:</p>
              <p>
                1. Password must contain 10 or more characters&nbsp;
                {
                  passwordFormSTH.isLengthValid ? 
                    (<span className="success">&#10003;</span>) : 
                    (<span className="error">&#10005;</span>)
                }
              </p>
              <p>
                2. Password must contain 8 unique characters&nbsp;
                {
                  passwordFormSTH.hasEnoughUniqueChars ?
                  (<span className="success">&#10003;</span>) : 
                  (<span className="error">&#10005;</span>)
                }
              </p>
              <p>
                3. Password must have at least 3 of the following: upper case letters, lower case letters, numbers and non alphabetic symbols&nbsp;
                {
                  passwordFormSTH.hasEnoughTypes ?
                  (<span className="success">&#10003;</span>) : 
                  (<span className="error">&#10005;</span>)
                }
              </p>
            </div>
            <TextFieldGroup
            id="password"
            type="password"
            name="password"
            labelClass="form-group__label"
            label="New Password"
            dataName="password"
            value={passwordFormSTH.password}
            handleOnChange={handleOnPasswordChange}
            errorTrigger={
              isPasswordErrorShown() ?
               'input-error' : ''
            }
          />

          <TextFieldGroup
            id="passwordConfirm"
            type="password"
            name="passwordConfirm"
            labelClass="form-group__label"
            label="Confirm Password"
            dataName="passwordConfirm"
            value={passwordFormSTH.passwordConfirm}
            handleOnChange={handleOnPasswordChange}
            errorTrigger={
              isPasswordConfirmErrorShown() ?
                'input-error' : ''
            }
            error={
              (passwordFormSTH.confirmTouched && !passwordFormSTH.isPasswordMatched) ?
                'Confirm password does not match your new password!'
                : null
            }
          />
          </div>
        </div>
      </div>
      <div className="modal__dialogue__footer">
        <button
          className="modal__dialogue__footer__btn submit"
          disabled={!isPasswordValid()}
          onClick={() => handleOnSubmit()}
        >
          Reset
        </button>
        <button
          className="modal__dialogue__footer__btn cancel"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

PasswordResetForm.propTypes = {
  resetPasswordAXN: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default PasswordResetForm;