import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  authRXS: { isAuthenticated },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

PrivateRoute.propTypes = {
  authRXS: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
});

export default connect(mapStateToProps)(PrivateRoute);
