import React from 'react';
import PropTypes from 'prop-types';

import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const TaxRegistration = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    npwp,
    errors
  } = formState;

  const inputElements = [
    {
      belongsTo: ['Indonesia Tax Registration'],
      element: (
        <TextFieldGroup
          key="npwp"
          id="npwp"
          type="text"
          name="npwp"
          dataName="npwpID"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.npwp')}
          value={npwp}
          placeholder={transUTL('translatePlaceholder.inputID.npwp')}
          error={errors.npwp}
          errorTrigger={errors.npwp ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    }
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
    <span />
  </div>
  );
};

TaxRegistration.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
}

export default TaxRegistration;