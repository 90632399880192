import gender from './gender/gender';
import stateCapitalAU from './stateCapital/stateCapitalAU';
import medicareAU from './medicare/medicareAU';
import country from './country/country';
import streetType from './streetType/streetType';
import stateAU from './state/stateAU';
import stateMY from './state/stateMY';
import idCardTypePH from './cardType/idCardType';
import cardTypeOCR from './cardType/cardTypeOCR';
import searchType from './searchType/searchType';
import phoneType from './phoneType/phoneType';

// import idCardTypePH from './cardType/idCardTypePH';

const translateDropdown = {
  select: '选择',
  gender,
  stateCapital: { stateCapitalAU },
  medicare: { medicareAU },
  country,
  streetType,
  stateAU,
  stateMY,
  idCardTypePH,
  cardTypeOCR,
  searchType,
  phoneType,
};

export default translateDropdown;
