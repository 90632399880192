import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const IndiaPan = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL('translateSearchVerification.dataSourcesIN.pan.title')}
      />

      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.searchSuccessful'
                    )}
                  </p>
                  <span className="material-icons success">check_circle</span>
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.safeHarbourScore'
                    )}
                  </p>
                  {!isEmptyVAL(safeHarbourScore) ? (
                    safeHarbourScore.toLowerCase() === 'add' ? (
                      <span className="material-icons success">add_circle</span>
                    ) : (
                      <span>{safeHarbourScore}</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.identityVerified'
                    )}
                  </p>
                  {!isEmptyVAL(identityVerified) ? (
                    identityVerified ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.documentNumber'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.documentNumber) ? (
                    verifications.documentNumber ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL('translateSearchVerification.dataSourcesIN.pan.name')}
                  </p>
                  {!isEmptyVAL(verifications.fullName) ? (
                    verifications.fullName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.dateOfBirth'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.dateOfBirth) ? (
                    verifications.dateOfBirth ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL('translateSearchVerification.dataSourcesIN.pan.gender')}
                  </p>
                  {!isEmptyVAL(verifications.gender) ? (
                    verifications.gender ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.nameMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(nameMatchScore) ? (
                    <span>{nameMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesIN.pan.addressMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(addressMatchScore) ? (
                    <span>{addressMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};

IndiaPan.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default IndiaPan;
