import isEmptyVAL from '../validations/checks/isEmptyVAL';

const naUTL = (data) => {
  if (!isEmptyVAL(data)) {
    return data;
  } else {
    return 'N/A';
  }
};

export default naUTL;
