import React from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const IdentityDetailsNigeria = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    nationalIdNo,
    bankVerificationNo,
    errors
  } = formState;

  const inputElements = [
    {
      belongsTo: ['Nigeria National ID'],
      element: (
        <TextFieldGroup
          key="nationalIdNo"
          id="nationalIdNo"
          type="text"
          name="nationalIdNo"
          dataName="nationalIdNoNG"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.vin')}
          value={nationalIdNo}
          placeholder={transUTL('translatePlaceholder.inputNG.nationalIdNo')}
          placeholderTrigger={isEmptyVAL(nationalIdNo) ? 'input-empty' : ''}
          error={errors.nationalIdNo}
          errorTrigger={errors.nationalIdNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Nigeria Bank Verification'],
      element: (
        <TextFieldGroup
          key="bankVerificationNo"
          id="bankVerificationNo"
          type="text"
          name="bankVerificationNo"
          dataName="bankVerificationNG"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.bankVerificationNo')}
          value={bankVerificationNo}
          placeholder={transUTL('translatePlaceholder.inputNG.bankVerificationNo')}
          placeholderTrigger={isEmptyVAL(bankVerificationNo) ? 'input-empty' : ''}
          error={errors.bankVerificationNo}
          errorTrigger={errors.bankVerificationNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    }
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

IdentityDetailsNigeria.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default IdentityDetailsNigeria;
