import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackPE = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {
    nationalIDNumber: {
    },
    landLineNumber: {
    },
    personalInformation: {
    },
    personalInformationResidential: {
    },
    personalInformationSuper: {
    },
  };
  
  try {

    /* ============================================
              PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
      inputError.personalInformationResidential.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
      inputError.personalInformationSuper.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
      inputError.personalInformationResidential.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
      inputError.personalInformationSuper.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'middleName')) {
      inputError.personalInformation.middleName = `Middle name ${
        errors.find((item) => item.includes('middleName')).split(':')[1]
      }`;
      inputError.personalInformationResidential.middleName = `Middle name ${
        errors.find((item) => item.includes('middleName')).split(':')[1]
      }`;
      inputError.personalInformationSuper.middleName = `Middle name ${
        errors.find((item) => item.includes('middleName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'dateOfBirth')) {
      inputError.dateOfBirth = `Date of Birth ${
        errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
      }`;
      inputError.personalInformationResidential.dateOfBirth = `Date of Birth ${
        errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
      }`;
      inputError.personalInformationSuper.dateOfBirth = `Date of Birth ${
        errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
      }`;
    }

    /* ============================================
              IDENTITY DETAILS
       ============================================ */
    if (findErrorUTL(errors, 'nationalIDNo')) {
      inputError.nationalIDNumber.nationalIDNo = `National ID number ${
        errors.find((item) => item.includes('nationalIDNo')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'landlineNo')) {
      inputError.landLineNumber.landlineNo = `Land Line number ${
        errors.find((item) => item.includes('landlineNo')).split(':')[1]
      }`;
    }

    /* ============================================
              CURRENT RESIDENTAIL ADDRESS
       ============================================ */
    if (findErrorUTL(errors, 'addressElement1')) {
      inputError.addressElement1 = `addressElement1 ${
        errors.find((item) => item.includes('addressElement1')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'addressElement3')) {
      inputError.addressElement3 = `addressElement3 ${
        errors.find((item) => item.includes('addressElement3')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'addressElement4')) {
      inputError.addressElement4 = `addressElement4 ${
        errors.find((item) => item.includes('addressElement4')).split(':')[1]
      }`;
    }


  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE PERU VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackPE;