const translateCountryNames = {
  // ----------- REGION (Global) -----------
  global: 'global',

  // ----------- REGION (APAC) -----------
  india: 'India',
  indonesia: 'Indonesia',
  japan: 'Jepun',
  cambodia: 'Kemboja',
  hongkong: 'Hong Kong',
  'hongkong(specialadministrativeregionofchina)': 'Hong Kong',
  singapore: 'Singapura',
  australia: 'Australia',
  china: 'China',
  newzealand: 'New Zealand',
  malaysia: 'Malaysia',
  philippines: 'Filipina',
  vietnam: 'Vietnam',

  // ----------- REGION (AFRICA) -----------
  southafrica: 'Afrika Selatan',

  // ----------- REGION (EUROPE) -----------
  austria: 'Austria',
  belgium: 'Belgium',
  denmark: 'Denmark',
  finland: 'Finland',
  france: 'Perancis',
  germany: 'Jerman',
  unitedkingdom: 'United Kingdom Britain dan Ireland Utara',
  ireland: 'Ireland',
  italy: 'Itali',
  luxembourg: 'Luxembourg',
  netherlands: 'Belanda',
  norway: 'Norway',
  portugal: 'Portugal',
  russia: 'Rusia',
  spain: 'Sepanyol',
  slovakia: 'Slovakia',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  bulgaria: 'Bul Bulgaria',
  greece: 'Yunani',
  hungary: 'Hungary',
  poland: 'Poland',
  czechrepublic:'Republik Czech',

  // ----------- REGION (MIDDLE EAST) -----------
  bahrain: 'Bahrain',
  jordan: 'Jordan',
  kuwait: 'kuwait',
  lebanon: 'Lubnan',
  oman: 'oman',
  saudiarabia: 'Arab Saudi',
  turkey: 'Turki',
  unitedarabemirates: 'Emiriah Arab Bersatu',

  // ----------- REGION (THE AMERICAS) -----------
  peru: 'Peru',
  argentina: 'Argentina',
  brazil: 'Brazil',
  canada: 'Kanada',
  chile: 'Cili',
  mexico: 'Mexico',
  unitedstates: 'Amerika Syarikat',

  // ----------- WATCHLIST AML -----------
  watchlistaml: 'Australia',
  watchlistAML: 'Senarai pantau AML',

  /* ============================================
    PAKISTAN, NEPAL, THAILAND & PAPUA NEW GUINEA
   ============================================ */
  pakistan: 'Pakistan',
  nepal: 'Nepal',
  thailand: 'Thailand',
  papuanewguinea: 'Papua New Guinea',
  kenya: 'Kenya',
  nigeria: 'Nigeria',
  bangladesh:'Bangladesh',
  morocco: 'Maghribi',
};

export default translateCountryNames;
