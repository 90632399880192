import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MuiTablePagination from '@material-ui/core/TablePagination';

const CustomFooter = ({
  changeRowsPerPage,
  changePage,
  count,
  textLabels,
  rowsPerPage,
  page,
  history,
}) => {
  const handleRowChange = (event) => {
    changeRowsPerPage(event.target.value);
  };

  const handlePageChange = (_, page) => {
    changePage(page);
  };

  const footerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap-reverse',
    padding: '0px 12px 0px 12px',
  };

  return (
    <TableFooter>
      <TableRow>
        <TableCell style={footerStyle} colSpan={1000}>
          <div
            style={{
              paddingTop: '1.1rem',
              flex: '1 1 auto',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                cursor: 'pointer',
                fontWeight: 'bold',
                width: '15rem',
              }}
              onClick={() => history.goBack()}
            >
              <span
                style={{ verticalAlign: 'middle', marginBottom: '0.2rem' }}
                className="material-icons"
              >
                arrow_back
              </span>
              <span style={{ paddingLeft: '1rem' }}>Previous Page</span>
            </p>
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <MuiTablePagination
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={textLabels.rowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${textLabels.displayRows} ${count}`
              }
              backIconButtonProps={{
                'aria-label': textLabels.previous,
              }}
              nextIconButtonProps={{
                'aria-label': textLabels.next,
              }}
              rowsPerPageOptions={[10, 20, 100]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowChange}
            />
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CustomFooter;
