import {
  SET_SEARCH_VERIFICATION,
  RESET_SEARCH_VERIFICATION,
} from '../../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_VERIFICATION:
      return action.payload;
    case RESET_SEARCH_VERIFICATION:
      return initialState;
    default:
      return state;
  }
}
