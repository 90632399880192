import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import { saveSelectedCountryAXN } from '../../../../actions/country/countryActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';

// HELPERS
import initialStateAML from './helpers/initialStateAML';
import whitelistedInputDataAML from './helpers/whitelistedInputDataAML';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import watchlistVAL from '../../../../validations/inputFields/watchlistVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationAML from './inputForms/PersonalInformationAML';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';
// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';
// UTILITIES
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';

const WatchlistAMLForms = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  formDataRXS,
  saveSelectedCountryAXN,
  saveFormDataAXN,
  searchVerificationAXN,
  dzSearchVerificationAXN,
  setAlertAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateAML);
  const selectedDataSources = ['Watchlist AML'];

  let navigationPath = '/dashboard';

  useEffect(() => {
    saveSelectedCountryAXN('watchlistAML'); // SET WATCHLIST AML AS COUNTRY

    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.watchlistAMLFormData,
      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH };

    // VALIDATE INPUT
    const { inputErrors, isValid } = watchlistVAL(
      inputData,
      selectedDataSources
    );
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // DATE FORMAT
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELIST DATA
    const whitelisted = whitelistedInputDataAML(inputData, selectedDataSources);

    const service = [...selectedDataSources];

    // JSON BUILDER
    const submitData = jsonBuilder({
      countryCode: 'All',
      service,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const { errors, ...filteredFormData } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    // saveFormDataAXN(filteredFormData, countryRXS);
    saveFormDataAXN(filteredFormData, 'watchlistAML');
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');
    const { errors, ...reset } = initialStateAML;
    setUserInputSTH(initialStateAML);
    // saveFormDataAXN(reset, countryRXS);
    saveFormDataAXN(reset, 'watchlistAML');
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (selectedDataSources.includes('Indonesia Resident Id Card')) {
  } else {
    conditionalClientRef.push(...['Watchlist AML']);
  }

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>{transUTL('translateFormOverview.watchlistAML')}</h2>

        <span
          className="material-icons icon-size"
          title="Edit Country"
          onClick={() => history.push('/country-selection')}
        >
          create
        </span>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationAML
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />

        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
        {/* CLEAR BUTTON */}
        <button onClick={handleReset} className="btn-secondary">
          {transUTL('translateFormOverview.clear')}
        </button>

        {/* BUTTON */}
        <Button
          myClass={'btn-under'}
          history={history}
          to={navigationPath}
          handleOnSubmit={handleOnSubmit}
          btn1Text={transUTL('translateBtn.previousPage')}
          btn2Text={transUTL('translateBtn.submit')}
          additionalClass="form-overview__form-btn"
        />
      </div>
    </div>
  );
};

WatchlistAMLForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  saveSelectedCountryAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  saveSelectedCountryAXN,
  setAlertAXN,
})(withRouter(WatchlistAMLForms));
