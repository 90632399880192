const filterServicesUTL = (country, countries) => {
  if (country === 'watchlistAML') {
    return {
      region: '',
      country: '',
      belongsTo: '',
      code: { alpha2: 'AU', alpha3: 'AUS' },
      dataSources: ['Watchlist AML'],
    };
  }

  return countries.find((item) => item.country.toLowerCase() === country);
};
export default filterServicesUTL;
