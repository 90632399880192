import React from "react";
import PropTypes from "prop-types";

// COMPONENTS
import Header from "../../../../../components/header/Header";
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

// UTILITIES
import { transUTL } from "../../../../../utils/transUTL";

// COMPONENTS
const BrazilNationalID = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};

  const { identityVerified, safeHarbourScore, addressMatchScore, nameMatchScore, verifications, status,
    errorMessage } = data;

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          "translateSearchVerification.dataSourcesBR.brazilNationalID.title"
        )}
      />
      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.safeHarbourScore"
                    )}
                  </p>
                  {!isEmptyVAL(safeHarbourScore) ? (
                    safeHarbourScore.toLowerCase() === "add" ? (
                      <span className="material-icons success">add_circle</span>
                    ) : (
                      <span>{safeHarbourScore}</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.br")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.verified"
                    )}
                  </p>
                  {!isEmptyVAL(identityVerified) ? (
                    identityVerified ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.br")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.addressMatchScore"
                    )}
                  </p>
                  {!isEmptyVAL(addressMatchScore) ? (
                    <span>{addressMatchScore}</span>
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.nameMatchScore"
                    )}
                  </p>
                  {!isEmptyVAL(nameMatchScore) ? (
                    <span>{nameMatchScore}</span>
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
              </div>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.cpf"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.cpf) ? (
                    verifications.cpf ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.firstName"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.firstName) ? (
                    verifications.firstName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.lastName"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.lastName) ? (
                    verifications.lastName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.dateOfBirth"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.dateOfBirth) ? (
                    verifications.dateOfBirth ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
              </div>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.streetName"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement1) ? (
                    verifications.addressElement1 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.locality"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement3) ? (
                    verifications.addressElement3 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.district"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement4) ? (
                    verifications.addressElement4 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      "translateSearchVerification.dataSourcesBR.brazilNationalID.postCode"
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement5) ? (
                    verifications.addressElement5 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL("translateReuse.na")}</span>
                  )}
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};
BrazilNationalID.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default BrazilNationalID;
