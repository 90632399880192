// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { consumerData, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(consumerData.status),
      col3: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(consumerData.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(consumerData.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'Voter Number',
      col2: naUTL(consumerData.verifications.documentNo),
      col3: naUTL(watchlistAML.verifications.documentNo),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(consumerData.verifications.firstName),

      col3: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(consumerData.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(consumerData.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(consumerData.verifications.gender),
      col3: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(consumerData.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(consumerData.addressMatchScore),
      col3: naUTL(watchlistAML.addressMatchScore),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
