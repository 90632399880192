const infoMsg = (doc, docStyles, col, row, y = 15) => {
  const tableY = doc.previousAutoTable.finalY + y;

  doc.autoTable({
    columns: col, 
    body: row,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.lineWidth = 0;
      cell.styles.cellPadding = { top: 0, bottom: 5, left: 0, right: 0 };
      cell.styles.fillColor = [255, 255, 255];
    },
    showHead: 'never',
    styles: {
      ...docStyles.tableStyles,
      halign: 'left',
    },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
      },
    },
    startY: tableY,
  });
};

export default infoMsg;
