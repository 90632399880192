const inputDE = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetAddress: '',
  city: '',
  state: '',
  postCode: '',

  // CONTACT DETAILS
  phoneNo: '',

  // REFERENCE
  reportingReference: '',
};

export default inputDE;
