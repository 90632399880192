/*
  This array is being used for BE validation. 
  This can help not to overlap the validation msg from FE and BE at the sme time that cause the issue.
*/
const VALIDATION_FOR_TEMPLATE = [
  'chile',
  // 'peru',
  // 'argentina',
];

export default VALIDATION_FOR_TEMPLATE;