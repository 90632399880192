const returnDataToString = data => {
  let { fields } = data;
  if (fields) {
    fields = Object.keys(fields)
      .map(key => {
        return `${key}: ${
          fields[key] || typeof fields[key] === 'boolean' || !isNaN(fields[key]) ? fields[key] : 'N/A'
        }`;
      })
      .join('\n');
  } else {
    fields = 'N/A';
  }

  return fields;
};

export default returnDataToString;
