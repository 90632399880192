import React from 'react';
import PropTypes from 'prop-types';

const TickBoxGroup = ({ checkboxBool, label, handleToggleTickbox }) => {
  return (
    <div className="form-overview__checkbox-container" onClick={handleToggleTickbox}>
      <p className="form-overview__checkbox-label">{label}</p>

      {checkboxBool ? (
        <span className="material-icons icon-size success">check_box</span>
      ) : (
        <span className="material-icons icon-size">
          check_box_outline_blank
        </span>
      )}
    </div>
  );
};

TickBoxGroup.defaultProps = {
  toggleTickbox: false,
};

TickBoxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  checkboxBool: PropTypes.bool.isRequired,
  handleToggleTickbox: PropTypes.func.isRequired,
};

export default TickBoxGroup;
