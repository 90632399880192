/* ============================================
                  ERROR  
   ============================================ */
export const ERROR_FEEDBACK = 'ERROR_FEEDBACK';
/* ============================================
                  AUTH  
   ============================================ */
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_LOGOUT = 'USER_LOGOUT';

/* ============================================
                  LOADING  
   ============================================ */
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

/* ============================================
                  ALERT  
   ============================================ */
export const SET_NORMAL_ALERT = 'SET_NORMAL_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

/* ============================================
                  LANGUAGE  
   ============================================ */
export const SET_LANGUAGE = 'SET_LANGUAGE';

/* ============================================
                  COUNTRY  
   ============================================ */
export const SET_COUNTRY = 'SET_COUNTRY';
export const RESET_COUNTRY = 'RESET_COUNTRY';
/* ============================================
                  REGION  
   ============================================ */
export const SET_REGION = 'SET_REGION';
export const RESET_REGION = 'RESET_REGION';

/* ============================================
                  DATA SOURCES  
   ============================================ */
export const SAVE_AUSTRALIA_DATA_SOURCES = 'SAVE_AUSTRALIA_DATA_SOURCES';
export const SAVE_BRAZIL_DATA_SOURCES = 'SAVE_BRAZIL_DATA_SOURCES';
export const SAVE_CHINA_DATA_SOURCES = 'SAVE_CHINA_DATA_SOURCES';
export const SAVE_MALAYSIA_DATA_SOURCES = 'SAVE_MALAYSIA_DATA_SOURCES';
export const SAVE_NEW_ZEALAND_DATA_SOURCES = 'SAVE_NEW_ZEALAND_DATA_SOURCES';
export const SAVE_PHILIPPINES_DATA_SOURCES = 'SAVE_PHILIPPINES_DATA_SOURCES';
export const SAVE_INDIA_DATA_SOURCES = 'SAVE_INDIA_DATA_SOURCES';
export const SAVE_INDONESIA_DATA_SOURCES = 'SAVE_INDONESIA_DATA_SOURCES';
export const SAVE_CAMBODIA_DATA_SOURCES = 'SAVE_CAMBODIA_DATA_SOURCES';
export const SAVE_VIETNAM_DATA_SOURCES = 'SAVE_VIETNAM_DATA_SOURCES';
export const SAVE_NIGERIA_DATA_SOURCES = 'SAVE_NIGERIA_DATA_SOURCES';
export const SAVE_HONG_KONG_DATA_SOURCES = 'SAVE_HONG_KONG_DATA_SOURCES';
export const SAVE_FRANCE_DATA_SOURCES = 'SAVE_FRANCE_DATA_SOURCES';
export const SAVE_SINGAPORE_DATA_SOURCES = 'SAVE_SINGAPORE_DATA_SOURCES';
export const SAVE_UNITED_STATES_DATA_SOURCES = 'SAVE_UNITED_STATES_DATA_SOURCES';
export const SAVE_MEXICO_DATA_SOURCES = 'SAVE_MEXICO_DATA_SOURCES';
export const SAVE_GERMANY_DATA_SOURCES = 'SAVE_GERMANY_DATA_SOURCES';
export const SAVE_ITALY_DATA_SOURCES = 'SAVE_ITALY_DATA_SOURCES';
export const SAVE_SPAIN_DATA_SOURCES = 'SAVE_SPAIN_DATA_SOURCES';
export const SAVE_MULTI_SERVICE_DATA_SOURCE = 'SAVE_MULTI_SERVICE_DATA_SOURCE';
export const SAVE_WATCHLISTAML_DATA_SOURCES = 'SAVE_WATCHLISTAML_DATA_SOURCES';
export const RESET_DATA_SOURCES = 'RESET_DATA_SOURCES';
export const SAVE_DATA_SOURCE_SELECTIONS = 'SAVE_DATA_SOURCES';
export const SAVE_FIND_IDU_DATA_SOURCE = 'SAVE_FIND_IDU_DATA_SOURCE';
export const DIGITAL_ID_SAFEGUARD_DATA_SOURCE = 'DIGITAL_ID_SAFEGUARD_DATA_SOURCE';

/* ============================================
                  FORM DATA 
   ============================================ */
export const SAVE_AUSTRALIA_FORM_DATA = 'SAVE_AUSTRALIA_FORM_DATA';
export const SAVE_BRAZIL_FORM_DATA = 'SAVE_BRAZIL_FORM_DATA';
export const SAVE_CHINA_FORM_DATA = 'SAVE_CHINA_FORM_DATA';
export const SAVE_MALAYSIA_FORM_DATA = 'SAVE_MALAYSIA_FORM_DATA';
export const SAVE_NEW_ZEALAND_FORM_DATA = 'SAVE_NEW_ZEALAND_FORM_DATA';
export const SAVE_PHILIPPINES_FORM_DATA = 'SAVE_PHILIPPINES_FORM_DATA';
export const SAVE_INDIA_FORM_DATA = 'SAVE_INDIA_FORM_DATA';
export const SAVE_INDONESIA_FORM_DATA = 'SAVE_INDONESIA_FORM_DATA';
export const SAVE_CAMBODIA_FORM_DATA = 'SAVE_CAMBODIA_FORM_DATA';
export const SAVE_VIETNAM_FORM_DATA = 'SAVE_VIETNAM_FORM_DATA';
export const SAVE_NIGERIA_FORM_DATA = 'SAVE_NIGERIA_FORM_DATA';
export const SAVE_HONG_KONG_FORM_DATA = 'SAVE_HONG_KONG_FORM_DATA';
export const SAVE_UNITED_STATES_FORM_DATA = 'SAVE_UNITED_STATES_FORM_DATA';
export const SAVE_SINGAPORE_FORM_DATA = 'SAVE_SINGAPORE_FORM_DATA';
export const SAVE_MEXICO_FORM_DATA = 'SAVE_MEXICO_FORM_DATA';
export const SAVE_GERMANY_FORM_DATA = 'SAVE_GERMANY_FORM_DATA';
export const SAVE_FRANCE_FORM_DATA = 'SAVE_FRANCE_FORM_DATA';
export const SAVE_ITALY_FORM_DATA = 'SAVE_ITALY_FORM_DATA';
export const SAVE_SPAIN_FORM_DATA = 'SAVE_SPAIN_FORM_DATA';
export const SAVE_MULTI_SERVICE_FORM_DATA = 'SAVE_MULTI_SERVICE_FORM_DATA';
export const SAVE_WATCHLISTAML_FORM_DATA = 'SAVE_WATCHLISTAML_FORM_DATA';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const SAVE_FIND_IDU_FORM_DATA = 'SAVE_FIND_IDU_FORM_DATA';
export const SAVE_DIGITAL_ID_SAFEGUARD_FORM_DATA = 'SAVE_DIGITAL_ID_SAFEGUARD_FORM_DATA';

/* ============================================
                  SEARCH VERIFICATION
   ============================================ */
export const SET_SEARCH_VERIFICATION = 'SET_SEARCH_VERIFICATION';
export const RESET_SEARCH_VERIFICATION = 'RESET_SEARCH_VERIFICATION';

/* ============================================
            TRANSACTION HISTORY
   ============================================ */
export const GET_USER_TRANSACTION_HISTORY = 'GET_USER_TRANSACTION_HISTORY';
export const FILTER_TRANSACTIONS = 'FILTER_TRANSACTIONS';
export const GET_TOTAL_RECORD = 'GET_TOTAL_RECORD';

/* ============================================
            DATA ANALYTICS
   ============================================ */
export const GET_PROD_DATA_SOURCE_THIS_MONTH =
   'GET_PROD_DATA_SOURCE_THIS_MONTH';

/* ============================================
            DATA ANALYTICS
   ============================================ */
export const SET_DATA_ANALYTICS = 'SET_DATA_ANALYTICS';
export const SET_USAGE_REPORT_DATA = 'SET_USAGE_REPORT_DATA';
export const SET_USAGE_REPORT_SELECTED_DATE = 'SET_USAGE_REPORT_SELECTED_DATE';
export const SET_USAGE_REPORT_SELECTED_CLIENT =
   'SET_USAGE_REPORT_SELECTED_CLIENT';
/* ============================================
            COST ESTIMATION
   ============================================ */
export const SET_PRICE_TIER_SELECTED_DATA_SOURCE =
   'SET_PRICE_TIER_SELECTED_DATA_SOURCE';
export const SET_PRICE_TIER_SELECTED_COUNTRY =
   'SET_PRICE_TIER_SELECTED_COUNTRY';

/* ============================================
            BIO METRICS
   ============================================ */
export const SET_MULTI_DOCS = 'SET_MULTI_DOCS'; // Remove This
export const SET_IDENTITY_DOC = 'SET_IDENTITY_DOCS';
export const SET_SELFIE = 'SET_SELFIE';
export const DELETE_MULTI_DOCS = 'DELETE_MULTI_DOCS'; // Remove this
export const DELETE_IDENTITY_DOC = 'DELETE_IDENTITY_DOC';
export const DELETE_IDENTITY_DOC_FRONT = 'DELETE_IDENTITY_DOC_FRONT';
export const DELETE_IDENTITY_DOC_BACK = 'DELETE_IDENTITY_DOC_BACK';
export const FACIAL_RECOGNITION_DATA = 'FACIAL_RECOGNITION_DATA';
export const BIO_BOOLEAN_TRIGGERS = 'BIO_BOOLEAN_TRIGGERS';
export const REST_BIOMETRICS = 'REST_BIOMETRICS';
export const SAVE_OCR_RESPONSE_DATA = 'SAVE_OCR_RESPONSE_DATA';

/* ============================================
            Initialise Data Source Selections
   ============================================ */
export const INITIALISE_DATA_SOURCE_SELECTIONS = 'INITIALISE_DATA_SOURCE_SELECTIONS';

/* ============================================
            ADMIN
   ============================================ */
export const SET_ORGANISATION_USERS = 'SET_ORGANISATION_USERS';
export const SET_ORGANISATIONS = 'SET_ORGANISATIONS';

/* ============================================
            USER INPUT  
   ============================================ */
export const SAVE_USER_INPUT = 'SAVE_USER_INPUT';
export const SAVE_API_CALL = 'SAVE_API_CALL';


