const dataSourcesUS = {
  residential: {
    title: 'United States Residential',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    nationalIDNo: 'National ID',
    firstName: 'First Name',
    lastName: 'Last Name',
    addressElement1: 'Street Name',
    addressElement3: 'Suburb',
    addressElement4: 'State',
    addressElement5: 'Post Code',
    streetNo: 'Street Number',
    email: 'Email Address',
    phoneNo: 'Phone Number',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    dateOfBirth: 'Date Of Birth',
    streetAddress: 'Street Name',
    socialSecurityNo: 'Social Security Number',
  },
  phone: {
    title: 'United States Phone',
    addressElement1: 'Street Name',
    addressElement2: 'Street No',
    addressElement3: 'Suburb',
    addressElement4: 'State',
    addressElement5: 'Post Code',
    streetNo: 'Street Number',
  }
};

export default dataSourcesUS;
