import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackBR = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};


  try {
    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'dateOfBirth')) {
      inputError.dateOfBirth = `Date of Birth ${
        errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
      }`;
    }

    /* ======================================
            TAX REGISTRATION
      ======================================= */

    if (findErrorUTL(errors, 'cpf')) {
      inputError.cpf = `CPF ${
        errors.find((item) => item.includes('cpf')).split(':')[1]
      }`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES CHINA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackBR;