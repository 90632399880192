import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const indonesiaVAL = (data, dataSource) => {
  const inputErrors = {};

  /* ============================================
            INDONESIA RESIDENT IDENTITY CARD
   ============================================ */
  if (dataSource.includes('Indonesia Resident Identity Card')) {
    loggerUTL('VALIDATING INDONESIA RESIDENT IDENTITY CARD...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.gender = !isEmptyVAL(data.gender) ? data.gender : '';
    data.nik = !isEmptyVAL(data.nik) ? data.nik : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.gender)) {
      inputErrors.gender = transUTL('translateFormOverview.formErrors.gender');
    }
    if (isEmptyVAL(data.nik)) {
      inputErrors.nik = transUTL('translateFormOverview.formErrors.nik');
    }
  }

  /* =============================================
              INDONESIA TAX REGISTRATION
    ============================================== */
  if (dataSource.includes('Indonesia Tax Registration')) {
    loggerUTL('VALIDATING INDONESIA TAX REGISTRATION...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.nik = !isEmptyVAL(data.nik) ? data.nik : '';
    data.npwp = !isEmptyVAL(data.npwp) ? data.npwp : '';


    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.nik)) {
      inputErrors.nik = transUTL('translateFormOverview.formErrors.nik');
    }
    if (isEmptyVAL(data.npwp)) {
      inputErrors.npwp = transUTL('translateFormOverview.formErrors.npwp');
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSource.includes('Watchlist AML')) {
    loggerUTL('Validating Watchlist AML...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default indonesiaVAL;
