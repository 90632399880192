// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const {
    visaEntitlement,
    residentialLookup,
    visa,
    residential,
    propertyOwnership,
    medicareCard,
    driverLicence,
    passport,
    centerlinkCard,
    birthCertificate,
    creditBureau,
    citizens,
    payroll,
    superannuation,
    deathCheck,
    watchlistAML,
  } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(visaEntitlement.status),
      col3: naUTL(residentialLookup.status),
      col4: naUTL(visa.status),
      col5: naUTL(residential.status),
      col6: naUTL(propertyOwnership.status),
      col7: naUTL(medicareCard.status),
      col8: naUTL(driverLicence.status),
      col9: naUTL(passport.status),
      col10: naUTL(centerlinkCard.status),
      col11: naUTL(birthCertificate.status),
      col12: naUTL(creditBureau.status),
      col13: naUTL(visa.watchlistAML),
      col14: naUTL(citizens.status),
      col15: naUTL(payroll.status),
      col16: naUTL(superannuation.status),
      col17: naUTL(deathCheck.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(visaEntitlement.safeHarbourScore),
      col3: naUTL(residentialLookup.safeHarbourScore),
      col4: naUTL(visa.safeHarbourScore),
      col5: naUTL(residential.safeHarbourScore),
      col6: naUTL(propertyOwnership.safeHarbourScore),
      col7: naUTL(medicareCard.safeHarbourScore),
      col8: naUTL(driverLicence.safeHarbourScore),
      col9: naUTL(passport.safeHarbourScore),
      col10: naUTL(centerlinkCard.safeHarbourScore),
      col11: naUTL(birthCertificate.safeHarbourScore),
      col12: naUTL(creditBureau.safeHarbourScore),
      col13: naUTL(visa.watchlistAML),
      col14: naUTL(citizens.safeHarbourScore),
      col15: naUTL(payroll.safeHarbourScore),
      col16: naUTL(superannuation.safeHarbourScore),
      col17: naUTL(deathCheck.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(visaEntitlement.identityVerified),
      col3: naUTL(residentialLookup.identityVerified),
      col4: naUTL(visa.identityVerified),
      col5: naUTL(residential.identityVerified),
      col6: naUTL(propertyOwnership.identityVerified),
      col7: naUTL(medicareCard.identityVerified),
      col8: naUTL(driverLicence.identityVerified),
      col9: naUTL(passport.identityVerified),
      col10: naUTL(centerlinkCard.identityVerified),
      col11: naUTL(birthCertificate.identityVerified),
      col12: naUTL(creditBureau.identityVerified),
      col13: naUTL(watchlistAML.identityVerified),
      col14: naUTL(citizens.identityVerified),
      col15: naUTL(payroll.identityVerified),
      col16: naUTL(superannuation.identityVerified),
      col17: naUTL(deathCheck.identityVerified),
    },
    {
      col1: 'Document Number',
      col2: naUTL(visaEntitlement.verifications.documentNo),
      col3: naUTL(residentialLookup.verifications.documentNo),
      col4: naUTL(visa.verifications.documentNo),
      col5: naUTL(residential.verifications.documentNo),
      col6: naUTL(propertyOwnership.verifications.documentNo),
      col7: naUTL(medicareCard.verifications.documentNo),
      col8: naUTL(driverLicence.verifications.documentNo),
      col9: naUTL(passport.verifications.documentNo),
      col10: naUTL(centerlinkCard.verifications.documentNo),
      col11: naUTL(birthCertificate.verifications.documentNo),
      col12: naUTL(creditBureau.verifications.documentNo),
      col13: naUTL(watchlistAML.verifications.documentNo),
      col14: naUTL(citizens.verifications.documentNo),
      col15: naUTL(payroll.verifications.documentNo),
      col16: naUTL(superannuation.verifications.documentNo),
      col17: naUTL(deathCheck.verifications.documentNo),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(visaEntitlement.verifications.firstName),
      col3: naUTL(residentialLookup.verifications.firstName),
      col4: naUTL(visa.verifications.firstName),
      col5: naUTL(residential.verifications.firstName),
      col6: naUTL(propertyOwnership.verifications.firstName),
      col7: naUTL(medicareCard.verifications.firstName),
      col8: naUTL(driverLicence.verifications.firstName),
      col9: naUTL(passport.verifications.firstName),
      col10: naUTL(centerlinkCard.verifications.firstName),
      col11: naUTL(birthCertificate.verifications.firstName),
      col12: naUTL(creditBureau.verifications.firstName),
      col13: naUTL(watchlistAML.verifications.firstName),
      col14: naUTL(citizens.verifications.firstName),
      col15: naUTL(payroll.verifications.firstName),
      col16: naUTL(superannuation.verifications.firstName),
      col17: naUTL(deathCheck.verifications.firstName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(visaEntitlement.verifications.lastName),
      col3: naUTL(residentialLookup.verifications.lastName),
      col4: naUTL(visa.verifications.lastName),
      col5: naUTL(residential.verifications.lastName),
      col6: naUTL(propertyOwnership.verifications.lastName),
      col7: naUTL(medicareCard.verifications.lastName),
      col8: naUTL(driverLicence.verifications.lastName),
      col9: naUTL(passport.verifications.lastName),
      col10: naUTL(centerlinkCard.verifications.lastName),
      col11: naUTL(birthCertificate.verifications.lastName),
      col12: naUTL(creditBureau.verifications.lastName),
      col13: naUTL(watchlistAML.verifications.lastName),
      col14: naUTL(citizens.verifications.lastName),
      col15: naUTL(payroll.verifications.lastName),
      col16: naUTL(superannuation.verifications.lastName),
      col17: naUTL(deathCheck.verifications.lastName),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(visaEntitlement.verifications.dateOfBirth),
      col3: naUTL(residentialLookup.verifications.dateOfBirth),
      col4: naUTL(visa.verifications.dateOfBirth),
      col5: naUTL(residential.verifications.dateOfBirth),
      col6: naUTL(propertyOwnership.verifications.dateOfBirth),
      col7: naUTL(medicareCard.verifications.dateOfBirth),
      col8: naUTL(driverLicence.verifications.dateOfBirth),
      col9: naUTL(passport.verifications.dateOfBirth),
      col10: naUTL(centerlinkCard.verifications.dateOfBirth),
      col11: naUTL(birthCertificate.verifications.dateOfBirth),
      col12: naUTL(creditBureau.verifications.dateOfBirth),
      col13: naUTL(watchlistAML.verifications.dateOfBirth),
      col14: naUTL(citizens.verifications.dateOfBirth),
      col15: naUTL(payroll.verifications.dateOfBirth),
      col16: naUTL(superannuation.verifications.dateOfBirth),
      col17: naUTL(deathCheck.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(visaEntitlement.verifications.gender),
      col3: naUTL(residentialLookup.verifications.gender),
      col4: naUTL(visa.verifications.gender),
      col5: naUTL(residential.verifications.gender),
      col6: naUTL(propertyOwnership.verifications.gender),
      col7: naUTL(medicareCard.verifications.gender),
      col8: naUTL(driverLicence.verifications.gender),
      col9: naUTL(passport.verifications.gender),
      col10: naUTL(centerlinkCard.verifications.gender),
      col11: naUTL(birthCertificate.verifications.gender),
      col12: naUTL(creditBureau.verifications.gender),
      col13: naUTL(watchlistAML.verifications.gender),
      col14: naUTL(citizens.verifications.gender),
      col15: naUTL(payroll.verifications.gender),
      col16: naUTL(superannuation.verifications.gender),
      col17: naUTL(deathCheck.verifications.gender),
    },
    {
      col1: 'Phone Number Verified',
      col2: naUTL(visaEntitlement.verifications.phoneNo),
      col3: naUTL(residentialLookup.verifications.phoneNo),
      col4: naUTL(visa.verifications.phoneNo),
      col5: naUTL(residential.verifications.phoneNo),
      col6: naUTL(propertyOwnership.verifications.phoneNo),
      col7: naUTL(medicareCard.verifications.phoneNo),
      col8: naUTL(driverLicence.verifications.phoneNo),
      col9: naUTL(passport.verifications.phoneNo),
      col10: naUTL(centerlinkCard.verifications.phoneNo),
      col11: naUTL(birthCertificate.verifications.phoneNo),
      col12: naUTL(creditBureau.verifications.phoneNo),
      col13: naUTL(watchlistAML.verifications.phoneNo),
      col14: naUTL(citizens.verifications.phoneNo),
      col15: naUTL(payroll.verifications.phoneNo),
      col16: naUTL(superannuation.verifications.phoneNo),
      col17: naUTL(deathCheck.verifications.phoneNo),
    },
    {
      col1: 'Email Verified',
      col2: naUTL(visaEntitlement.verifications.email),
      col3: naUTL(residentialLookup.verifications.email),
      col4: naUTL(visa.verifications.email),
      col5: naUTL(residential.verifications.email),
      col6: naUTL(propertyOwnership.verifications.email),
      col7: naUTL(medicareCard.verifications.email),
      col8: naUTL(driverLicence.verifications.email),
      col9: naUTL(passport.verifications.email),
      col10: naUTL(centerlinkCard.verifications.email),
      col11: naUTL(birthCertificate.verifications.email),
      col12: naUTL(creditBureau.verifications.email),
      col13: naUTL(watchlistAML.verifications.email),
      col14: naUTL(citizens.verifications.email),
      col15: naUTL(payroll.verifications.email),
      col16: naUTL(superannuation.verifications.email),
      col17: naUTL(deathCheck.verifications.email),
    },
    {
      col1: 'Email Verified',
      col2: naUTL(visaEntitlement.verifications.birthCertificateNo),
      col3: naUTL(residentialLookup.verifications.birthCertificateNo),
      col4: naUTL(visa.verifications.birthCertificateNo),
      col5: naUTL(residential.verifications.birthCertificateNo),
      col6: naUTL(propertyOwnership.verifications.birthCertificateNo),
      col7: naUTL(medicareCard.verifications.birthCertificateNo),
      col8: naUTL(driverLicence.verifications.birthCertificateNo),
      col9: naUTL(passport.verifications.birthCertificateNo),
      col10: naUTL(centerlinkCard.verifications.birthCertificateNo),
      col11: naUTL(birthCertificate.verifications.birthCertificateNo),
      col12: naUTL(creditBureau.verifications.birthCertificateNo),
      col13: naUTL(watchlistAML.verifications.birthCertificateNo),
      col14: naUTL(citizens.verifications.birthCertificateNo),
      col15: naUTL(payroll.verifications.birthCertificateNo),
      col16: naUTL(superannuation.verifications.birthCertificateNo),
      col17: naUTL(deathCheck.verifications.birthCertificateNo),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(visaEntitlement.nameMatchScore),
      col3: naUTL(residentialLookup.nameMatchScore),
      col4: naUTL(visa.nameMatchScore),
      col5: naUTL(residential.nameMatchScore),
      col6: naUTL(propertyOwnership.nameMatchScore),
      col7: naUTL(medicareCard.nameMatchScore),
      col8: naUTL(driverLicence.nameMatchScore),
      col9: naUTL(passport.nameMatchScore),
      col10: naUTL(centerlinkCard.nameMatchScore),
      col11: naUTL(birthCertificate.nameMatchScore),
      col12: naUTL(creditBureau.nameMatchScore),
      col13: naUTL(watchlistAML.nameMatchScore),
      col14: naUTL(citizens.nameMatchScore),
      col15: naUTL(payroll.nameMatchScore),
      col16: naUTL(superannuation.nameMatchScore),
      col17: naUTL(deathCheck.nameMatchScore),
    },
    {
      col1: 'Alt Address Match Score',
      col2: naUTL(visaEntitlement.returnedData.altAddressMatchScore),
      col3: naUTL(residentialLookup.returnedData.altAddressMatchScore),
      col4: naUTL(visa.returnedData.altAddressMatchScore),
      col5: naUTL(residential.returnedData.altAddressMatchScore),
      col6: naUTL(propertyOwnership.returnedData.altAddressMatchScore),
      col7: naUTL(medicareCard.returnedData.altAddressMatchScore),
      col8: naUTL(driverLicence.returnedData.altAddressMatchScore),
      col9: naUTL(passport.returnedData.altAddressMatchScore),
      col10: naUTL(centerlinkCard.returnedData.altAddressMatchScore),
      col11: naUTL(birthCertificate.returnedData.altAddressMatchScore),
      col12: naUTL(creditBureau.returnedData.altAddressMatchScore),
      col13: naUTL(watchlistAML.returnedData.altAddressMatchScore),
      col14: naUTL(citizens.returnedData.altAddressMatchScore),
      col15: naUTL(payroll.returnedData.altAddressMatchScore),
      col16: naUTL(superannuation.returnedData.altAddressMatchScore),
      col17: naUTL(deathCheck.returnedData.altAddressMatchScore),
    },
    {
      col1: 'Alt Name Match Score',
      col2: naUTL(visaEntitlement.returnedData.altNameMatchScore),
      col3: naUTL(residentialLookup.returnedData.altNameMatchScore),
      col4: naUTL(visa.returnedData.altNameMatchScore),
      col5: naUTL(residential.returnedData.altNameMatchScore),
      col6: naUTL(propertyOwnership.returnedData.altNameMatchScore),
      col7: naUTL(medicareCard.returnedData.altNameMatchScore),
      col8: naUTL(driverLicence.returnedData.altNameMatchScore),
      col9: naUTL(passport.returnedData.altNameMatchScore),
      col10: naUTL(centerlinkCard.returnedData.altNameMatchScore),
      col11: naUTL(birthCertificate.returnedData.altNameMatchScore),
      col12: naUTL(creditBureau.returnedData.altNameMatchScore),
      col13: naUTL(watchlistAML.returnedData.altNameMatchScore),
      col14: naUTL(citizens.returnedData.altNameMatchScore),
      col15: naUTL(payroll.returnedData.altNameMatchScore),
      col16: naUTL(superannuation.returnedData.altNameMatchScore),
      col17: naUTL(deathCheck.returnedData.altNameMatchScore),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(visaEntitlement.addressMatchScore),
      col3: naUTL(residentialLookup.addressMatchScore),
      col4: naUTL(visa.addressMatchScore),
      col5: naUTL(residential.addressMatchScore),
      col6: naUTL(propertyOwnership.addressMatchScore),
      col7: naUTL(medicareCard.addressMatchScore),
      col8: naUTL(driverLicence.addressMatchScore),
      col9: naUTL(passport.addressMatchScore),
      col10: naUTL(centerlinkCard.addressMatchScore),
      col11: naUTL(birthCertificate.addressMatchScore),
      col12: naUTL(creditBureau.addressMatchScore),
      col13: naUTL(watchlistAML.addressMatchScore),
      col14: naUTL(citizens.addressMatchScore),
      col15: naUTL(payroll.addressMatchScore),
      col16: naUTL(superannuation.addressMatchScore),
      col17: naUTL(deathCheck.addressMatchScore),
    },
    {
      col1: 'Street Verified',
      col2: naUTL(visaEntitlement.verifications.addressElement1),
      col3: naUTL(residentialLookup.verifications.addressElement1),
      col4: naUTL(visa.verifications.addressElement1),
      col5: naUTL(residential.verifications.addressElement1),
      col6: naUTL(propertyOwnership.verifications.addressElement1),
      col7: naUTL(medicareCard.verifications.addressElement1),
      col8: naUTL(driverLicence.verifications.addressElement1),
      col9: naUTL(passport.verifications.addressElement1),
      col10: naUTL(centerlinkCard.verifications.addressElement1),
      col11: naUTL(birthCertificate.verifications.addressElement1),
      col12: naUTL(creditBureau.verifications.addressElement1),
      col13: naUTL(watchlistAML.verifications.addressElement1),
      col14: naUTL(citizens.verifications.addressElement1),
      col15: naUTL(payroll.verifications.addressElement1),
      col16: naUTL(superannuation.verifications.addressElement1),
      col17: naUTL(deathCheck.verifications.addressElement1),
    },
    {
      col1: 'Suburb Verified',
      col2: naUTL(visaEntitlement.verifications.addressElement2),
      col3: naUTL(residentialLookup.verifications.addressElement2),
      col4: naUTL(visa.verifications.addressElement2),
      col5: naUTL(residential.verifications.addressElement2),
      col6: naUTL(propertyOwnership.verifications.addressElement2),
      col7: naUTL(medicareCard.verifications.addressElement2),
      col8: naUTL(driverLicence.verifications.addressElement2),
      col9: naUTL(passport.verifications.addressElement2),
      col10: naUTL(centerlinkCard.verifications.addressElement2),
      col11: naUTL(birthCertificate.verifications.addressElement2),
      col12: naUTL(creditBureau.verifications.addressElement2),
      col13: naUTL(watchlistAML.verifications.addressElement2),
      col14: naUTL(citizens.verifications.addressElement2),
      col15: naUTL(payroll.verifications.addressElement2),
      col16: naUTL(superannuation.verifications.addressElement2),
      col17: naUTL(deathCheck.verifications.addressElement2),
    },
    {
      col1: 'State Verified',
      col2: naUTL(visaEntitlement.verifications.addressElement4),
      col3: naUTL(residentialLookup.verifications.addressElement4),
      col4: naUTL(visa.verifications.addressElement4),
      col5: naUTL(residential.verifications.addressElement4),
      col6: naUTL(propertyOwnership.verifications.addressElement4),
      col7: naUTL(medicareCard.verifications.addressElement4),
      col8: naUTL(driverLicence.verifications.addressElement4),
      col9: naUTL(passport.verifications.addressElement4),
      col10: naUTL(centerlinkCard.verifications.addressElement4),
      col11: naUTL(birthCertificate.verifications.addressElement4),
      col12: naUTL(creditBureau.verifications.addressElement4),
      col13: naUTL(watchlistAML.verifications.addressElement4),
      col14: naUTL(citizens.verifications.addressElement4),
      col15: naUTL(payroll.verifications.addressElement4),
      col16: naUTL(superannuation.verifications.addressElement4),
      col17: naUTL(deathCheck.verifications.addressElement4),
    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(visaEntitlement.verifications.addressElement5),
      col3: naUTL(residentialLookup.verifications.addressElement5),
      col4: naUTL(visa.verifications.addressElement5),
      col5: naUTL(residential.verifications.addressElement5),
      col6: naUTL(propertyOwnership.verifications.addressElement5),
      col7: naUTL(medicareCard.verifications.addressElement5),
      col8: naUTL(driverLicence.verifications.addressElement5),
      col9: naUTL(passport.verifications.addressElement5),
      col10: naUTL(centerlinkCard.verifications.addressElement5),
      col11: naUTL(birthCertificate.verifications.addressElement5),
      col12: naUTL(creditBureau.verifications.addressElement5),
      col13: naUTL(watchlistAML.verifications.addressElement5),
      col14: naUTL(citizens.verifications.addressElement5),
      col15: naUTL(payroll.verifications.addressElement5),
      col16: naUTL(superannuation.verifications.addressElement5),
      col17: naUTL(deathCheck.verifications.addressElement5),
    },
    {
      col1: 'Source',
      col2: naUTL(visaEntitlement.returnedData.source),
      col3: naUTL(residentialLookup.returnedData.source),
      col4: naUTL(visa.returnedData.source),
      col5: naUTL(residential.returnedData.source),
      col6: naUTL(propertyOwnership.returnedData.source),
      col7: naUTL(medicareCard.returnedData.source),
      col8: naUTL(driverLicence.returnedData.source),
      col9: naUTL(passport.returnedData.source),
      col10: naUTL(centerlinkCard.returnedData.source),
      col11: naUTL(birthCertificate.returnedData.source),
      col12: naUTL(creditBureau.returnedData.source),
      col13: naUTL(watchlistAML.returnedData.source),
      col14: naUTL(citizens.returnedData.source),
      col15: naUTL(payroll.returnedData.source),
      col16: naUTL(superannuation.returnedData.source),
      col17: naUTL(deathCheck.returnedData.source),
    },
    {
      col1: 'ABN',
      col2: naUTL(visaEntitlement.verifications.ABN),
      col3: naUTL(residentialLookup.verifications.ABN),
      col4: naUTL(visa.verifications.ABN),
      col5: naUTL(residential.verifications.ABN),
      col6: naUTL(propertyOwnership.verifications.ABN),
      col7: naUTL(medicareCard.verifications.ABN),
      col8: naUTL(driverLicence.verifications.ABN),
      col9: naUTL(passport.verifications.ABN),
      col10: naUTL(centerlinkCard.verifications.ABN),
      col11: naUTL(birthCertificate.verifications.ABN),
      col12: naUTL(creditBureau.verifications.ABN),
      col13: naUTL(watchlistAML.verifications.ABN),
      col14: naUTL(citizens.verifications.ABN),
      col15: naUTL(payroll.verifications.ABN),
      col16: naUTL(superannuation.verifications.ABN),
      col17: naUTL(deathCheck.verifications.ABN),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
