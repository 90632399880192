import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import en from './locales/en/en';
import ms from './locales/ms/ms';
import zh from './locales/zh/zh';

import { isProd } from './dev';

i18n
  .use(XHR)
  .use(LngDetector)
  .init({
    debug: isProd ? false : true,

    // INITIALIZE RESOURCES
    resources: {
      en: { translation: en },
      ms: { translation: ms },
      zh: { translation: zh },
    },

    interpolation: { escapeValue: false }, // REACT ALREADY DOES ESCAPING

    react: {
      useSuspense: false,
    },
  });

export default i18n;
