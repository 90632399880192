const dataSourcesIN = {
  driverLicence: {
    title: '印度驾驶执照',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    documentNumber: '文件号码',
    name: '名称',
    address: '地址',
    dateOfBirth: '出生日期',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
  },
  epic: {
    title: '印度EPIC',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    documentNumber: '文件号码',
    name: '名称',
    gender: '性别',
    address: '地址',
    dateOfBirth: '出生日期',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
  },
  pan: {
    title: '印度PAN',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    documentNumber: '文件号码',
    name: '名称',
    gender: '性别',
    address: '地址',
    dateOfBirth: '出生日期',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
  },
  passport: {
    title: '印度护照',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    dateOfBirth: '出生日期',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    firstName: '名字',
    lastName: '姓',
    dateOfIssue: '发行日期',
    fileNo: '文件编号',
    passportNo: '护照号',
  },
  aadhaarNo: {
    title: '印度Aadhaar',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    documentNumber: '文件号码',
    dateOfBirth: '出生日期',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    name: '名称',
    firstName: '名字',
    lastName: '姓',
    dateOfIssue: '发行日期',
    fileNo: '文件编号',
  },
};

export default dataSourcesIN;
