import store from "../store/store";

import { dataSourcesIsCheckedUTL } from "./outputMasterUTL";

/*
  This function is a copy of the function selectedDataSourceTemplateUTL.js
  The only difference between this tow is the constant suffix. Once all data 
  sources are migrated to the template, one of these two will be deleted.
*/
const selectedDataSourceTemplateUTL = () => {
  const dataSources = store.getState().dataSourceSelectionsRXS;
  const country = store.getState().countryRXS;

  if (country === "canada") {
    dataSources["canada"]["Canada Consumer"] = false;
    dataSources["canada"]["Canada Residential"] = false;
  }

  let extractedDataSource = dataSources[country];

  // RETURNS THE SELECTED DATA SOURCES
  /*
   * e.g {'Malaysia Credit Bureau': true, 'Malaysia National ID': false, 'Watchlist AML': true}
   * returns =+ ['Malaysia Credit Bureau', 'Watchlist AML']
   */

  return dataSourcesIsCheckedUTL(extractedDataSource);
};

export default selectedDataSourceTemplateUTL;
