import {
  GET_TOTAL_RECORD
} from '../../actions/types';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOTAL_RECORD:
      return action.payload;
    default:
      return state;
  }
}
