import React from 'react';
import PropTypes from 'prop-types';

const FormController = ({ title, inputForm }) => (
  <div className="form-overview__form-section">
    <header className="header-secondary">
      <h4 className="header-title-secondary">{title}</h4>
    </header>
    {inputForm}
  </div>
);

FormController.propTypes = {
  title: PropTypes.string.isRequired,
  inputForm: PropTypes.object.isRequired,
};

export default FormController;
