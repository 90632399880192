import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const DriversLicenceNewZealand = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    driversLicenceNo,
    driversLicenceVersion,
    vehiclePlate,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['New Zealand Driver Licence'],
      element: (
        <TextFieldGroup
          key="driversLicenceNo"
          id="driversLicenceNo"
          type="text"
          name="driversLicenceNo"
          dataName="driversLicenceNoNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.driversLicenceNo')}
          value={driversLicenceNo}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.driversLicenceNo'
          )}
          placeholderTrigger={isEmptyVAL(driversLicenceNo) ? 'input-empty' : ''}
          error={errors.driversLicenceNo}
          // errorTrigger={errors.driversLicenceNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['New Zealand Driver Licence'],
      element: (
        <TextFieldGroup
          key="driversLicenceVersion"
          id="driversLicenceVersion"
          type="text"
          name="driversLicenceVersion"
          dataName="driversLicenceVersionNZ"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.driversLicenceVersion'
          )}
          value={driversLicenceVersion}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.driversLicenceVersion'
          )}
          placeholderTrigger={
            isEmptyVAL(driversLicenceVersion) ? 'input-empty' : ''
          }
          error={errors.driversLicenceVersion}
          errorTrigger={errors.driversLicenceVersion ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['MVRVR-ALL', 'MVRVR-DOB', 'MVRVR-NAD'],
      element: (
        <TextFieldGroup
          key="vehiclePlate"
          id="vehiclePlate"
          type="text"
          name="vehiclePlate"
          dataName="vehiclePlateNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.vehiclePlate')}
          value={vehiclePlate}
          placeholder={transUTL('translatePlaceholder.inputNZ.vehiclePlate')}
          placeholderTrigger={isEmptyVAL(vehiclePlate) ? 'input-empty' : ''}
          error={errors.vehiclePlate}
          errorTrigger={errors.vehiclePlate ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

DriversLicenceNewZealand.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default DriversLicenceNewZealand;
