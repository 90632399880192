// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataNG = (inputData, dataSources) => {
  let nigeriaNationalID, nigeriaBankVerification, watchlistData;

  /* ============================================
              NIGERIA NATIONAL ID
   ============================================ */
  if (dataSources.includes('Nigeria National ID')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'nationalIdNo',
      'streetAddress',
      'town',
      'lga',
      'state',
      'reportingReference',
      'nationalIDConsentObtained',
    ]);

    nigeriaNationalID = { ...whitelisted };
  }

  /* ============================================
              Nigeria Bank Verification
   ============================================ */
  if (dataSources.includes('Nigeria Bank Verification')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'bankVerificationNo',
      'streetAddress',
      'town',
      'lga',
      'state',
      'reportingReference',
    ]);

    nigeriaBankVerification = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...nigeriaNationalID,
    ...nigeriaBankVerification,
    ...watchlistData,
  };
};
export default whitelistedInputDataNG;
