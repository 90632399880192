const watchlistAML = {
  title: 'Global Watchlist AML',
  WatchlistStatusText: 'International PEPs Watchlist Status',
  watchlistStatusClear: 'CLEAR',
  watchlistStatusFound: 'FOUND',
  category: 'Category',
  otherNames: 'Other Names',
  fullReport: 'Full Report',
  safeHarbourScore: 'Safe Harbour Score',
  pdfLink: 'PDF Link',
  searchSuccessful: 'Search Successful',
};

export default watchlistAML;
