import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const SearchChina = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleChinaPhone,
}) => {
  const { passportNo, idCardNo, bankCardNo, phoneNo, errors } = formState;

  // CHINA PHONE CLASS
  let phoneNoClass = isEmptyVAL(phoneNo) ? 'input-empty' : '';
  if (!isEmptyVAL(errors.phoneNo)) phoneNoClass = `${phoneNoClass} input-error`;

  const inputElements = [
    {
      belongsTo: [
        'China ID Verification',
        'China Bank Card',
        'China National ID',
        'China Phone',
        'China Passport Verification',
        'China Driver License'
      ],
      element: (
        <TextFieldGroup
          key="idCardNo"
          id="idCardNo"
          type="text"
          name="idCardNo"
          dataName="idCardNoCN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.idCardNo')}
          value={idCardNo}
          placeholder={transUTL('translatePlaceholder.inputCN.idCardNo')}
          placeholderTrigger={isEmptyVAL(idCardNo) ? 'input-empty' : ''}
          error={errors.idCardNo}
          errorTrigger={errors.idCardNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['China Bank Card'],
      element: (
        <TextFieldGroup
          key="bankCardNo"
          id="bankCardNo"
          type="text"
          name="bankCardNo"
          dataName="bankCardNoCN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.bankCardNo')}
          value={bankCardNo}
          placeholder={transUTL('translatePlaceholder.inputCN.bankCardNo')}
          placeholderTrigger={isEmptyVAL(bankCardNo) ? 'input-empty' : ''}
          error={errors.bankCardNo}
          errorTrigger={errors.bankCardNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['China Phone'],
      element: (
        <div className="form-group" key="phoneNoCN">
          <label className="formOverview__label" htmlFor="phoneNo">
            {transUTL('translateFormOverview.inputLabels.phoneNo')}
            <PhoneInput
              id="phoneNoCN"
              key="phoneNoCN"
              country={'cn'}
              onlyCountries={['cn']}
              disableDropdown={true}
              defaultCountry={'cn'}
              preferredCountries={['cn']}
              masks={{ cn: '...........' }}
              value={phoneNo}
              inputClass={`${phoneNoClass}`}
              onChange={handleChinaPhone}
              onKeyDown={handleEnterKeyTrigger}
              onBlur={handleOnBlur}
            />
          </label>

          {formState.errors.phoneNo && (
            <small className="form-group__error-text">
              <span className="material-icons">error</span>
              {formState.errors.phoneNo}
            </small>
          )}
        </div>
        // <TextFieldGroup
        //   key="phoneNo"
        //   id="phoneNo"
        //   type="text"
        //   name="phoneNo"
        //   dataName="phoneNoCN"
        //   labelClass="form-group__label"
        //   label={transUTL('translateFormOverview.inputLabels.phoneNo')}
        //   value={phoneNo}
        //   placeholder={transUTL('translatePlaceholder.inputCN.phoneNo')}
        //   placeholderTrigger={isEmptyVAL(phoneNo) ? 'input-empty' : ''}
        //   error={errors.phoneNo}
        //   errorTrigger={errors.phoneNo ? 'input-error' : ''}
        //   handleOnChange={handleOnChange}
        //   handleOnBlur={handleOnBlur}
        //   handleEnterKeyTrigger={handleEnterKeyTrigger}
        // />
      ),
    },
    {
      belongsTo: [
        'China Passport Verification'
      ],
      element: (
        <TextFieldGroup
          key="passportNo"
          id="passportNo"
          type="text"
          name="passportNo"
          dataName="passportNoCN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.passportNo')}
          value={passportNo}
          placeholder={transUTL('translatePlaceholder.inputCN.passportNo')}
          placeholderTrigger={isEmptyVAL(passportNo) ? 'input-empty' : ''}
          error={errors.passportNo}
          errorTrigger={errors.passportNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

SearchChina.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleChinaPhone: PropTypes.func.isRequired,
};

export default SearchChina;
