// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { bankCard, idVerification, phone, watchlistAML, passportVerification, driversLicense } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(idVerification.status),
      col3: naUTL(phone.status),
      col4: naUTL(watchlistAML.status),
      col5: naUTL(bankCard.status),
      col6: naUTL(passportVerification.status),
      col7: naUTL(driversLicense.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(idVerification.safeHarbourScore),
      col3: naUTL(phone.safeHarbourScore),
      col4: naUTL(watchlistAML.safeHarbourScore),
      col5: naUTL(bankCard.safeHarbourScore),
      col6: naUTL(passportVerification.safeHarbourScore),
      col7: naUTL(driversLicense.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(idVerification.identityVerified),
      col3: naUTL(phone.identityVerified),
      col4: naUTL(watchlistAML.identityVerified),
      col5: naUTL(bankCard.identityVerified),
      col6: naUTL(passportVerification.identityVerified),
      col7: naUTL(driversLicense.identityVerified),
    },
    {
      col1: 'Document Number',
      col2: naUTL(idVerification.verifications.documentNo),
      col3: naUTL(phone.verifications.documentNo),
      col4: naUTL(watchlistAML.verifications.documentNo),
      col5: naUTL(bankCard.verifications.documentNo),
      col6: naUTL(passportVerification.verifications.documentNo),
      col7: naUTL(driversLicense.verifications.documentNo),
    },
    {
      col1: 'Bank Card No Verified',
      col2: naUTL(idVerification.verifications.bankCardNo),
      col3: naUTL(phone.verifications.bankCardNo),
      col4: naUTL(watchlistAML.verifications.bankCardNo),
      col5: naUTL(bankCard.verifications.bankCardNo),
      col6: naUTL(passportVerification.verifications.bankCardNo),
      col7: naUTL(driversLicense.verifications.bankCardNo),
    },
    {
      col1: 'ID Card No Verified',
      col2: naUTL(idVerification.verifications.idCardNo),
      col3: naUTL(phone.verifications.idCardNo),
      col4: naUTL(watchlistAML.verifications.idCardNo),
      col5: naUTL(bankCard.verifications.idCardNo),
      col6: naUTL(passportVerification.verifications.idCardNo),
      col7: naUTL(driversLicense.verifications.idCardNo),
    },
    {
      col1: 'Passport No Verified',
      col2: naUTL(idVerification.verifications.idCardNo),
      col3: naUTL(phone.verifications.idCardNo),
      col4: naUTL(watchlistAML.verifications.idCardNo),
      col5: naUTL(bankCard.verifications.idCardNo),
      col6: naUTL(passportVerification.verifications.passportNo),
      col7: naUTL(driversLicense.verifications.passportNo),
    },
    {
      col1: 'Phone Verified',
      col2: naUTL(idVerification.verifications.phoneVerified),
      col3: naUTL(phone.verifications.phoneVerified),
      col4: naUTL(watchlistAML.verifications.phoneVerified),
      col5: naUTL(bankCard.verifications.phoneVerified),
      col6: naUTL(passportVerification.verifications.phoneVerified),
      col7: naUTL(driversLicense.verifications.phoneVerified),
    },
    {
      col1: 'Full Name Verified',
      col2: naUTL(idVerification.verifications.fullName),
      col3: naUTL(phone.verifications.fullName),
      col4: naUTL(watchlistAML.verifications.fullName),
      col5: naUTL(bankCard.verifications.fullName),
      col6: naUTL(passportVerification.verifications.fullName),
      col7: naUTL(driversLicense.verifications.fullName),
    },

    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(idVerification.verifications.dateOfBirth),
      col3: naUTL(phone.verifications.dateOfBirth),
      col4: naUTL(watchlistAML.verifications.dateOfBirth),
      col5: naUTL(bankCard.verifications.dateOfBirth),
      col6: naUTL(passportVerification.verifications.dateOfBirth),
      col7: naUTL(driversLicense.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(idVerification.verifications.gender),
      col3: naUTL(phone.verifications.gender),
      col4: naUTL(watchlistAML.verifications.gender),
      col5: naUTL(bankCard.verifications.gender),
      col6: naUTL(passportVerification.verifications.gender),
      col7: naUTL(driversLicense.verifications.gender),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(idVerification.nameMatchScore),
      col3: naUTL(phone.nameMatchScore),
      col4: naUTL(watchlistAML.nameMatchScore),
      col5: naUTL(bankCard.nameMatchScore),
      col6: naUTL(passportVerification.nameMatchScore),
      col7: naUTL(driversLicense.nameMatchScore),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(idVerification.addressMatchScore),
      col3: naUTL(phone.addressMatchScore),
      col4: naUTL(watchlistAML.addressMatchScore),
      col5: naUTL(bankCard.addressMatchScore),
      col6: naUTL(passportVerification.addressMatchScore),
      col7: naUTL(driversLicense.addressMatchScore),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
