const dataSourcesID = {
  nik: {
    title: 'Indonesia Resident Identity Card',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    nik: 'NIK',
    name: 'Name',
    gender: 'Gender',
    address: 'Address',
    dateOfBirth: 'Date Of Birth',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    addressElement1: 'Alamat',
    addressElement2: 'Village',
    addressElement4: 'Kecamatan',
    addressElement5: 'City/Regency',
  },
  npwp: {
    title: 'Indonesia Tax Registration',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    addressMatchScore: 'Address Match Score',
    npwp: 'NPWP',
    nameMatchScore: 'Name Match Score',
    fullName: 'Full Name',
    dateOfBirth: 'Date Of Birth',
    nik: 'NIK',
  },
};

export default dataSourcesID;
