import store from '../../../../store/store';
import { saveDataSourcesAXN, initialiseGlobalDataSourceSelections } from '../../../../actions/dataSources/dataSourcesActions';
import { FIND_IDU } from "../../../../constants/findIDU";

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const dataSourcesSessionDataRestore = () => {
  loggerUTL('DATA SOURCES SESSION DATA RESTORE...');

  store.dispatch(
    initialiseGlobalDataSourceSelections()
  );


  /* ============================================
                  AUSTRALIA
   ============================================ */
  if (sessionStorage.australiaDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'australia',
        JSON.parse(sessionStorage.australiaDataSources)
      )
    );
  }

  /* ============================================
                  BRAZIL
   ============================================ */
  if (sessionStorage.brazilDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'brazil',
        JSON.parse(sessionStorage.brazilDataSources)
      ) 
    )
  }

  /* ============================================
                  CHINA
   ============================================ */
  if (sessionStorage.chinaDataSources) {
    store.dispatch(
      saveDataSourcesAXN('china', JSON.parse(sessionStorage.chinaDataSources))
    );
  }

  /* ============================================
                  INDIA
   ============================================ */
  if (sessionStorage.indiaDataSources) {
    store.dispatch(
      saveDataSourcesAXN('india', JSON.parse(sessionStorage.indiaDataSources))
    );
  }

  /* ============================================
                  INDONESIA
   ============================================ */
  if (sessionStorage.indonesiaDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'indonesia',
        JSON.parse(sessionStorage.indonesiaDataSources)
      )
    );
  }

  /* ============================================
                  MALAYSIA
   ============================================ */
  if (sessionStorage.malaysiaDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'malaysia',
        JSON.parse(sessionStorage.malaysiaDataSources)
      )
    );
  }

  /* ============================================
                  VIETNAM
   ============================================ */
  if (sessionStorage.vietnamDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'vietnam',
        JSON.parse(sessionStorage.vietnamDataSources)
      )
    );
  }

  /* ============================================
                  PHILIPPINES
   ============================================ */
  if (sessionStorage.philippinesDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'philippines',
        JSON.parse(sessionStorage.philippinesDataSources)
      )
    );
  }

  /* ============================================
                  CAMBODIA
   ============================================ */
  if (sessionStorage.cambodiaDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'cambodia',
        JSON.parse(sessionStorage.cambodiaDataSources)
      )
    );
  }

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  if (sessionStorage.newzealandDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'newzealand',
        JSON.parse(sessionStorage.newzealandDataSources)
      )
    );
  }

  /* ============================================
                  NIGERIA
   ============================================ */
  if (sessionStorage.nigeriaDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'nigeria',
        JSON.parse(sessionStorage.nigeriaDataSources)
      )
    );
  }

  /* ============================================
                  HONG KONG
   ============================================ */
  if (sessionStorage.hongkongDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'hongkong',
        JSON.parse(sessionStorage.hongkongDataSources)
      )
    );
  }

  /* ============================================
                  FRANCE
   ============================================ */
  if (sessionStorage.franceDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'france',
        JSON.parse(sessionStorage.franceDataSources)
      )
    );
  }

  /* ============================================
                  MULTI SERVICE
                  SINGAPORE
   ============================================ */
  if (sessionStorage.singaporeDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'singapore',
        JSON.parse(sessionStorage.singaporeDataSources)
      )
    );
  }

  /* ============================================
                  UNITED STATES
   ============================================ */
  if (sessionStorage.unitedstatesDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'unitedstates',
        JSON.parse(sessionStorage.unitedstatesDataSources)
      )
    );
  }

  /* ============================================
                  MEXICO
   ============================================ */
  if (sessionStorage.mexicoDataSources) {
    store.dispatch(
      saveDataSourcesAXN('mexico', JSON.parse(sessionStorage.mexicoDataSources))
    );
  }

  /* ============================================
                  GERMANY
   ============================================ */
  if (sessionStorage.germanyDataSources) {
    store.dispatch(
      saveDataSourcesAXN('germany', JSON.parse(sessionStorage.germanyDataSources))
    );
  }

  /* ============================================
                  ITALY
   ============================================ */
  if (sessionStorage.italyDataSources) {
    store.dispatch(
      saveDataSourcesAXN('italy', JSON.parse(sessionStorage.italyDataSources))
    );
  }

  /* ============================================
                 SPAIN
  ============================================ */
  if (sessionStorage.spainDataSources) {
    store.dispatch(
      saveDataSourcesAXN('spain', JSON.parse(sessionStorage.spainDataSources))
    );
  }

  /* ============================================
                  MULTI SERVICE
   ============================================ */
  if (sessionStorage.multiServiceDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        'multiService',
        JSON.parse(sessionStorage.multiServiceDataSources)
      )
    );
  }

  if (sessionStorage.findIDUDataSources) {
    store.dispatch(
      saveDataSourcesAXN(
        FIND_IDU,
        JSON.parse(sessionStorage.findIDUDataSources)
      )
    );
  }
};

export default dataSourcesSessionDataRestore;
