// HELPERS
import title from "../../../helpers/title";
import { setCellHeight, setImagePos } from "../../../helpers/didParseCell";

// IDENTITY DATA
import personalInformation from "../../../identityData/personalInformation";
import identityDetails from "../../../identityData/identityDetails";
import currentResidentialAddress from "../../../identityData/currentResidentialAddress";
import reference from "../../../identityData/reference";

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

// UTILS
import getImageDimensionsFromBase64UTL from "../../../../../utils/getImageDimensionsFromBase64UTL";

const consumerDataSupplied = (doc, data, info, docStyles, bioImgs) => {
  title(
    doc,
    docStyles,
    "Consumer Data Supplied",
    docStyles.headers.secondary,
    docStyles.separation
  );

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  const { service, consentObtained, ...identityData } = data.Request;

  // IDENTITY INFORMATION
  const { personalInformationData } = personalInformation(identityData);
  const { identityDetailsData } = identityDetails(identityData);
  const { currentResidentialAddressData } = currentResidentialAddress(
    identityData,
    "newzealand"
  );
  const { referenceData } = reference(identityData);

  let combinedData = [
    ...personalInformationData,
    ...identityDetailsData,
    ...currentResidentialAddressData,
    ...referenceData,
  ];
  // Removing empty slots
  combinedData = combinedData.filter((v) => !isEmptyVAL(v));

  // DATA SOURCE CONSENT
  let dataSourceString = [];
  if (consentObtained["New Zealand DIA Birth"]) {
    dataSourceString.push("New Zealand DIA Birth: Yes");
  }
  if (consentObtained["New Zealand DIA Citizenship"]) {
    dataSourceString.push("New Zealand DIA Citizenship: Yes");
  }
  if (consentObtained["New Zealand DIA Passport"]) {
    dataSourceString.push("New Zealand DIA Passport: Yes");
  }
  if (consentObtained["New Zealand Driver Licence"]) {
    dataSourceString.push("New Zealand Driver Licence: Yes");
  }
  if (consentObtained["New Zealand LINZ Property"]) {
    dataSourceString.push("New Zealand LINZ Property: Yes");
  }
  if (consentObtained["New Zealand Credit Bureau"]) {
    dataSourceString.push("New Zealand Credit Bureau: Yes");
  }
  if (service.includes("new zealand residential")) {
    dataSourceString.push("New Zealand Residential: Not Required");
  }
  if (service.includes("new zealand companies office")) {
    dataSourceString.push("New Zealand Companies Office: Not Required");
  }
  if (service.includes("new zealand yellow pages")) {
    dataSourceString.push("New Zealand Yellow Pages: Not Required");
  }
  if (service.includes("watchlist aml")) {
    dataSourceString.push("Global Watchlist AML: Not Required");
  }

  const columns = [
    { title: "", dataKey: "col1" },
    {
      title: "",
      dataKey: "col2",
    },
  ];

  const rows = [
    {
      col1: "Identity Data:",
      col2: combinedData.join("\n"),
    },
    {
      col1: "Consent Obtained",
      col2: dataSourceString.join("\n"),
    },
  ];

  // OCR IMAGES
  if (!isEmptyVAL(bioImgs)) {
    rows.push({
      col1: "",
      col2: "",
    });
  }
  let ratioResize = 3.75;

  doc.autoTable({columns: columns, body: rows, 
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = docStyles.colorWhite;

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> SELFIE
      if (
        data.cell.section === "body" &&
        data.column.index === 0 &&
        data.cell.raw === ""
      ) {
        if (!isEmptyVAL(bioImgs.identityDoc0)) {
          data.cell.styles.valign = "center";
          data.cell.styles.halign = "center";

          let { height, width } = getImageDimensionsFromBase64UTL(
            bioImgs.identityDoc0
          );

          cell.styles.minCellHeight = setCellHeight({ height, width, ratioResize });
        }
      }

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> SELFIE
      if (
        data.cell.section === "body" &&
        data.column.index === 1 &&
        data.cell.raw === ""
      ) {
        data.cell.styles.valign = "center";
        data.cell.styles.halign = "center";

        if (!isEmptyVAL(bioImgs.selfie)) {
          let { height, width } = getImageDimensionsFromBase64UTL(
            bioImgs.selfie
          );

          cell.styles.minCellHeight = setCellHeight({ height, width, ratioResize });
        }
      }
    },
    didDrawCell: (data, index) => {
      // const textPos = data.cell.textPos;
      const { cell } = data;

      // CENTER X,Y COORDINATES OF  ---> IDENTITY DOCUMENT
      if (
        data.cell.section === "body" &&
        data.column.index === 0 &&
        data.cell.raw === ""
      ) {
        if (!isEmptyVAL(bioImgs.identityDoc0)) {
          let { width, height } = getImageDimensionsFromBase64UTL(
            bioImgs.identityDoc0
          );
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width,
            height,
            ratioResize
          })

          doc.addImage(
            bioImgs.identityDoc0,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }
      }

      // CENTER X,Y COORDINATES OF  ---> IDENTITY DOCUMENT
      if (
        data.cell.section === "body" &&
        data.column.index === 1 &&
        data.cell.raw === ""
      ) {
        if (!isEmptyVAL(bioImgs.selfie)) {
          let { width, height } = getImageDimensionsFromBase64UTL(
            bioImgs.selfie
          );
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width,
            height,
            ratioResize
          })

          doc.addImage(
            bioImgs.selfie,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }
      }
    },
    showHead: "never",
    styles: docStyles.tableStyles,
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
      col2: {
        font: docStyles.fontFamily.arialUnicodeMS,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
    },
    startY: finalY,
  });
};

export default consumerDataSupplied;
