import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';

// HELPERS
import initialStateIN from './helpers/initialStateIN';
import consentCheckerIN from './helpers/consentCheckerIN';
import whitelistedInputDataIN from './helpers/whitelistedInputDataIN';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import indiaVAL from '../../../../validations/inputFields/indiaVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationIndia from './inputForms/PersonalInformationIndia';
import AccountNumberIndia from './inputForms/AccountNumberIndia';
import DriversLicenceIndia from './inputForms/DriversLicenceIndia';
import PassportIndia from './inputForms/PassportIndia';
import CurrentResidentialAddressIndia from './inputForms/CurrentResidentialAddressIndia';
import TermsAndConditionsIndia from './inputForms/TermsAndConditionsIndia';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import rmSpaceUTL from '../../../../utils/rmSpaceUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL";
import isScannedDataEditedUTL from "../../../../utils/isScannedDataEditedUTL";

const IndiaForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateIN);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.indiaDataSources
  );

  // LOAD FORM DATA
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.indiaFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.indiaFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = indiaVAL(inputData, selectedDataSources);
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // USER CONSENT CHECK
    const { isConsent, consentObtained } = consentCheckerIN(
      selectedDataSources,
      inputData
    );

    loggerUTL(isConsent, consentObtained);

    // PROVIDE ERROR FEEDBACK IF CONSENT IS NOT CHECKED
    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataIN(inputData, selectedDataSources);
    loggerUTL(whitelisted);
    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // WATCHLIST AML REQUIRES `firstName` & `lastName`
    // WHERE AS THE OTHER SERVICES REQUIRES `fullName`
    const {firstName, middleName, lastName} = inputData;
    const names = `${firstName} ${middleName} ${lastName}`.trim();
    const fullName = rmSpaceUTL(names);

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      consentObtained,
      fullName,
      firstName,
      lastName,
      addressElement1: whitelisted.streetNo,
      addressElement2: whitelisted.streetName,
      addressElement3: whitelisted.state,
      addressElement4: whitelisted.city,
      addressElement5: whitelisted.postCode,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const {
      errors,
      indiaPanConsentObtained,
      indiaDriversLicenceConsentObtained,
      indiaEpicConsentObtained,
      indiaPassportConsentObtained,
      indiaAadhaarConsentObtained,
      ...filteredFormData
    } = userInputSTH;

    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));

    // Check if scanned data from a doc is edited 
    const { ocrResponseData } = biometricsRXS;
    isScannedDataEditedUTL(countryRXS, ocrResponseData, filteredFormData);

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');
    const { errors, ...reset } = initialStateIN;
    setUserInputSTH(initialStateIN);
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    loggerUTL('CHECKBOX...');
    const {
      indiaEpicConsentObtained,
      indiaDriversLicenceConsentObtained,
      indiaPanConsentObtained,
      indiaPassportConsentObtained,
      indiaAadhaarConsentObtained,
    } = userInputSTH;

    let epicConsent = false,
      panConsent = false,
      driverLicenceConsent = false,
      passportConsent = false,
      aadhaarnoConsent = false;

    if (selectedDataSources.includes('India EPIC')) {
      epicConsent = !indiaEpicConsentObtained;
    }
    if (selectedDataSources.includes('India Drivers Licence')) {
      driverLicenceConsent = !indiaDriversLicenceConsentObtained;
    }
    if (selectedDataSources.includes('India PAN')) {
      panConsent = !indiaPanConsentObtained;
    }
    if (selectedDataSources.includes('India Passport')) {
      passportConsent = !indiaPassportConsentObtained;
    }
    if (selectedDataSources.includes('India Aadhaar')) {
      aadhaarnoConsent = !indiaAadhaarConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      indiaDriversLicenceConsentObtained: driverLicenceConsent,
      indiaEpicConsentObtained: epicConsent,
      indiaPanConsentObtained: panConsent,
      indiaPassportConsentObtained: passportConsent,
      indiaAadhaarConsentObtained: aadhaarnoConsent,
    });
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
          };
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded selfie 
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (
    selectedDataSources.includes('India Drivers Licence') ||
    selectedDataSources.includes('India EPIC') ||
    selectedDataSources.includes('India PAN') ||
    selectedDataSources.includes('India Passport') ||
    selectedDataSources.includes('India Aadhaar')
  ) {
  } else {
    conditionalClientRef.push(...['Watchlist AML']);
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'India PAN',
        'India EPIC',
        'India Drivers Licence',
        'India Passport',
        'India Aadhaar',
        'Watchlist AML',
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationIndia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['India PAN', 'India EPIC', 'India Aadhaar'],
      element: (
        <FormController
          key="accountNumber"
          title={transUTL('translateFormOverview.formHeaders.accountNumber')}
          inputForm={
            <AccountNumberIndia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <FormController
          key="driversLicence"
          title={transUTL('translateFormOverview.formHeaders.driversLicence')}
          inputForm={
            <DriversLicenceIndia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['India Passport'],
      element: (
        <FormController
          key="passport"
          title={transUTL('translateFormOverview.formHeaders.passport')}
          inputForm={
            <PassportIndia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressIndia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'India PAN',
        'India EPIC',
        'India Drivers Licence',
        'India Passport',
        'India Aadhaar',
      ],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            'translateFormOverview.formHeaders.termsAndConditions'
          )}
          inputForm={
            <TermsAndConditionsIndia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

IndiaForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  saveIdentityDocumentsAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  saveSelfieAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(IndiaForms));
