// HELPERS
import title from './title';
import { setCellHeight, setImagePos } from "./didParseCell";

// UTILS
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import getImageDimensionsFromBase64UTL from "../../../utils/getImageDimensionsFromBase64UTL__";

const ocrImages = async (doc, data, info, docStyles, bioData) => {
  title(
    doc,
    docStyles,
    'Biometric Scans',
    docStyles.headers.secondary,
    docStyles.separation
  );

  const columns = [
    { header: 'Scanned Document', dataKey: 'col1' },
    {
      header: 'Selfie',
      dataKey: 'col2',
    },
  ];
  const rows = [];

  const selfieImg = bioData.selfie;
  delete bioData.selfie;
  let selfieHeight, selfieWidth;
  if (selfieImg) {
    const {imgHeight, imgWidth} = await getImageDimensionsFromBase64UTL(selfieImg);
    selfieHeight = imgHeight
    selfieWidth = imgWidth
  }

  const docImg_front = bioData['identityDocument-0']
  let docFrontHeight, docFrontWidth;
  if (docImg_front) {
    const {imgHeight, imgWidth}  = await getImageDimensionsFromBase64UTL(docImg_front);
    docFrontHeight = imgHeight;
    docFrontWidth = imgWidth;
  }
  const docImg_back = bioData['identityDocument-1']
  let docBackHeight, docBackWidth;
  if (docImg_back) {
    const {imgHeight, imgWidth} = await getImageDimensionsFromBase64UTL(docImg_back);
    docBackHeight = imgHeight;
    docBackWidth = imgWidth;
  }

  const arrayImages = Object.values(bioData);
  const arrayKeyNames = Object.keys(bioData);

  let biometricFlagsParsed = '';
  let biometricFlagsUnParse = '';

  for (let element of arrayKeyNames) {
    if (
      element.includes('selfieIsMatch') ||
      element.includes('scannedDataEdited')
    ) {
      biometricFlagsUnParse = element;
      biometricFlagsParsed = element.replace(/%22/g, '"');
      biometricFlagsParsed = JSON.parse(biometricFlagsParsed);
    }
  }

  for (let [index] of arrayImages.entries()) {
    // ! REMOVES THE DUPLICATE IMAGE - TEMP SOLUTION
    if (!arrayKeyNames[index].includes(biometricFlagsUnParse)) {
      if (index === 0) {
        rows.push({
          col1: index,
          col2: 'selfie',
        });
      } else {
        rows.push({
          col1: index,
          col2: '',
        });
      }
    }
  }

  let finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  let ratioResize = 3.75;

  doc.autoTable({
    columns: columns,
    body: rows,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = docStyles.colorWhite; // COLOR TABLE WHITE
      cell.styles.textColor = docStyles.colorGreen; // COLOR TEXT

      if (data.cell.section === 'body') {
        cell.styles.textColor = docStyles.colorWhite; // HIDE BACKGROUND TEXT THAT IS USED AS REFERENCE TO PLACE IMAGES...
      }

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> IDENTITY DOCUMENTS
      if (data.cell.section === 'body' && data.column.index === 0) {

        if (!isEmptyVAL(docImg_front) && data.cell.raw === 0) {
          cell.styles.minCellHeight = setCellHeight({ height: docFrontHeight, width: docFrontWidth, ratioResize });
        }

        if (!isEmptyVAL(docImg_back) && data.cell.raw === 1) {
          cell.styles.minCellHeight = setCellHeight({ height: docBackHeight, width: docBackWidth, ratioResize });
        }
      }

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> SELFIE
      if (
        data.cell.section === 'body' &&
        data.column.index === 1 &&
        data.cell.raw === 'selfie' &&
        !isEmptyVAL(selfieImg)
      ) {
        if (!isEmptyVAL(selfieImg)) {
          cell.styles.minCellHeight = setCellHeight({ height: selfieHeight, width: selfieWidth, ratioResize });
        }
      }
    },
    didDrawCell: (data, index) => {
      const { cell } = data;
      // CENTER X,Y COORDINATES OF  ---> IDENTITY DOCUMENT
      if (data.cell.section === 'body' && data.column.index === 0) {

        if (!isEmptyVAL(docImg_front) && data.cell.raw === 0) {
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width: docFrontWidth,
            height: docFrontHeight,
            ratioResize
          })

          doc.addImage(
            docImg_front,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }

        if (!isEmptyVAL(docImg_back) && data.cell.raw === 1) {
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width: docBackWidth,
            height: docBackHeight,
            ratioResize
          })

          doc.addImage(
            docImg_back,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }
      }

      // CENTER X,Y COORDINATES OF IMAGE ---> SELFIE
      if (
        data.cell.section === 'body' &&
        data.column.index === 1 &&
        data.cell.raw === 'selfie' &&
        !isEmptyVAL(selfieImg)
      ) {
        const base64IMG = selfieImg;
        if (!isEmptyVAL(base64IMG)) {
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width: selfieWidth,
            height: selfieHeight,
            ratioResize
          })

          doc.addImage(
            base64IMG,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }
      }
    },
    showHead: 'firstPage',
    rowPageBreak: 'avoid',
    styles: {
      minCellWidth: docStyles.minCellWidth,
      ...docStyles.tableStyles,
      valign: 'center',
      halign: 'center',
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.fullCellLength / 2,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.fullCellLength / 2,
      },
    },
    startY: finalY,
  });

  // NEW TABLE
  finalY = doc.previousAutoTable.finalY;

  let selfieIsMatch = 'N/A';
  if (biometricFlagsParsed.selfieIsMatch === true) {
    selfieIsMatch = 'Pass';
  } else if (
    biometricFlagsParsed.selfieIsMatch === false
    && !isEmptyVAL(selfieImg)) {
    selfieIsMatch = 'Fail';
  }

  let scannedDataEdited = 'N/A';
  if (biometricFlagsParsed.scannedDataEdited === true) {
    scannedDataEdited = 'Yes';
  } else if (biometricFlagsParsed.scannedDataEdited === false) {
    scannedDataEdited = 'No';
  }

  const columns2 = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];

  const rows2 = [
    {
      col1: 'Facial Matching',
      col2: selfieIsMatch,
    },
    {
      col1: 'Scanned Data Edited',
      col2: scannedDataEdited,
    },
    {
      col1: 'Liveness',
      col2: 'N/A',
    },
    {
      col1: 'Document Authentication',
      col2: 'N/A',
    },
  ];

  doc.autoTable({
    columns: columns2,
    body: rows2,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = docStyles.colorWhite;
      data.cell.styles.fontStyle = 'normal';

      if (
        data.cell.section === 'body' &&
        data.column.index === 1 &&
        (data.cell.raw === 'Pass' || data.cell.raw === 'Yes')
      ) {
        data.cell.styles.textColor = [89, 193, 11];
        data.cell.styles.fontStyle = 'bold';
      } else if (
        data.cell.section === 'body' &&
        data.column.index === 1 &&
        (data.cell.raw === 'Fail' || data.cell.raw === 'No')
      ) {
        data.cell.styles.textColor = [185, 45, 0];
        data.cell.styles.fontStyle = 'bold';
      }
    },

    showHead: 'never',
    styles: {
      ...docStyles.tableStyles,
      valign: 'center',
    },
    headStyles: {
      fontSize: docStyles.textSize,
      font: docStyles.fontFamily.time,
      textColor: docStyles.colorText,
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.fullCellLength / 2,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.fullCellLength / 2,
      },
    },
    startY: finalY,
  });
};

export default ocrImages;
