const inputVN = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',

  // IDENTITY
  idCardNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '',
  ward: '',
  district: '',
  city: '',
  address: '',

  // CONTACT DETAILS
  phoneNo: '',
  emailAddress: '',

  // REFERENCE
  reportingReference: '',

  // TEMPORARY
  message: 'Message...',
};

export default inputVN;
