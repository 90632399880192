const translateDashboard = {
  hello: 'Hello',
  iduMsg: 'IDU, a simple and secure digital identity verification service.',
  readMore: 'Read More',

  // TILES
  identityVerification: 'IDENTITY VERIFICATION',
  pdfHistory: 'SEARCH PDF HISTORY',
  history: 'HISTORY',
  pep: 'PEP & SANCTIONS | GLOBAL WATCHLIST',
  watchlist: 'WATCHLIST',
  mobileVerification: 'MOBILE VERIFICATION',
  mobile: 'MOBILE',
  digitalID: 'DIGITAL ID SAFEGUARD',
  passwordAdvice: 'PASSWORD ADVICE',
  sms: 'SMS',
  usageReport: 'USAGE REPORT',
  data: 'DATA',
  dataAnalytics: 'DATA SOURCE ANALYTICS',
  globalCoverage: 'global coverage',
  comingSoon: 'Coming Soon',
  noSubscription: 'No Subscription',
  subscribedCountryData: 'Subscribed Country Data',
  costEstimation: 'Cost Estimation',
  cost: 'Cost',
  transactions: 'Transactions',
  eToro: 'eToro',
  eToroTwo: 'eToro Usage',
  multiService: 'Cross-Border',
  findIdU: 'Find ',
  searchForIndividuals: 'Search For Individuals'
};

export default translateDashboard;
