import store from '../../../../store/store';

import { setCurrentUserAXN } from '../../../../actions/auth/authActions';

// SET AUTHORIZATION TOKEN
import setAuthToken from '../../../../utils/setAuthTokenUTL';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const authSessionDataRestore = () => {
  loggerUTL('AUTH SESSION DATA RESTORE...');
  if (sessionStorage.user) {
    // CHECK FOR TOKEN
    const user = JSON.parse(sessionStorage.user);

    // SET USER
    store.dispatch(setCurrentUserAXN(user));

    // SET AUTH SESSION
    setAuthToken({
      dz: { sessionToken: user.sessionToken },
    });
  }
};

export default authSessionDataRestore;
