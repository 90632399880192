import React from "react";
import PropTypes from "prop-types";

// COMPONENTS
import Header from "../../../../../components/header/Header";
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

// UTILITIES
import { transUTL } from "../../../../../utils/transUTL";

// COMPONENTS
const HongKongCreditBureau = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage,
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          "translateSearchVerification.dataSourcesHK.creditBureau.title"
        )}
      />

      <div className="search-verification__verify">
        {status === 3 ? (
          <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
        ) : (
          <>
            <div className="search-verification__col">
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.searchSuccessful"
                  )}
                </p>
                <span className="material-icons success">check_circle</span>
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.safeHarbourScore"
                  )}
                </p>
                {!isEmptyVAL(safeHarbourScore) ? (
                  safeHarbourScore.toLowerCase() === "add" ? (
                    <span className="material-icons success">add_circle</span>
                  ) : (
                    <span>{safeHarbourScore}</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.identityVerified"
                  )}
                </p>
                {!isEmptyVAL(identityVerified) ? (
                  identityVerified ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.nationalIDNo"
                  )}
                </p>
                {!isEmptyVAL(verifications.nationalIdNo) ? (
                  verifications.nationalIdNo ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
            </div>

            <div className="search-verification__col">
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.firstName"
                  )}
                </p>
                {!isEmptyVAL(verifications.firstName) ? (
                  verifications.firstName ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.lastName"
                  )}
                </p>
                {!isEmptyVAL(verifications.lastName) ? (
                  verifications.lastName ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>

              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.dateOfBirth"
                  )}
                </p>
                {!isEmptyVAL(verifications.dateOfBirth) ? (
                  verifications.dateOfBirth ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.nameMatchScore"
                  )}
                </p>
                {!isEmptyVAL(nameMatchScore) ? (
                  <span>{nameMatchScore}</span>
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.addressMatchScore"
                  )}
                </p>
                {!isEmptyVAL(addressMatchScore) ? (
                  <span>{addressMatchScore}</span>
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
            </div>

            <div className="search-verification__col">
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.street"
                  )}
                </p>
                {!isEmptyVAL(verifications.addressElement1) ? (
                  verifications.addressElement1 ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.district"
                  )}
                </p>
                {!isEmptyVAL(verifications.addressElement2) ? (
                  verifications.addressElement2 ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.city"
                  )}
                </p>
                {!isEmptyVAL(verifications.addressElement3) ? (
                  verifications.addressElement3 ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.state"
                  )}
                </p>
                {!isEmptyVAL(verifications.addressElement4) ? (
                  verifications.addressElement4 ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    "translateSearchVerification.dataSourcesHK.creditBureau.postCode"
                  )}
                </p>
                {!isEmptyVAL(verifications.postCode) ? (
                  verifications.postCode ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

HongKongCreditBureau.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default HongKongCreditBureau;
