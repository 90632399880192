// UTILITIES
import whitelistKeys from "../../../../../utils/whitelistKeysUTL";
import loggerUTL from "../../../../../utils/loggerUTL";

const whitelistedInputDataBR = (inputData, dataSources) => {
	let nationalIDService = {};
	let taxRegistration = {};
	let watchlistData = {};

	if (dataSources.includes("Brazil National ID")) {
		loggerUTL("WHITELISTING Brazil National ID...");
		const whitelisted = whitelistKeys(inputData, [
			"firstName",
			"middleName",
			"lastName",
			"dateOfBirth",		
			"streetName",
			"locality",
			"district",
			"postCode",
			"cpf"
		]);

		nationalIDService = { ...whitelisted };
	}

	/* ============================================
							BRAZIL TAX REGISTRATION
	 ============================================ */
	if (dataSources.includes("Brazil Tax Registration")) {
		const whitelisted = whitelistKeys(inputData, [
			"firstName",
			"middleName",
			"lastName",
			"dateOfBirth",
			"cpf",
			"reportingReference"
		]);

		taxRegistration = { ...whitelisted };
	}

	/* ============================================
              WATCHLIST AML
   ============================================ */
	if (dataSources.includes("Watchlist AML")) {
		const whitelisted = whitelistKeys(inputData, [
			"firstName",
			"middleName",
			"lastName",
			"dateOfBirth",
			"reportingReference"
		]);
		watchlistData = { ...whitelisted };
	}

	return {
		...nationalIDService,
		...taxRegistration,
		...watchlistData,
	};
};

export default whitelistedInputDataBR;
