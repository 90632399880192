const consentCheckerNZ = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('New Zealand DIA Passport')) {
    // CONSENT COLLECTOR
    consentObtained['New Zealand DIA Passport'] = true;

    if (!inputData.diaPassportConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('New Zealand Driver Licence')) {
    // CONSENT COLLECTOR
    consentObtained['New Zealand Driver Licence'] = true;

    if (!inputData.driverLicenceConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('New Zealand DIA Birth')) {
    // CONSENT COLLECTOR
    consentObtained['New Zealand DIA Birth'] = true;

    if (!inputData.diaBirthConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('New Zealand DIA Citizenship')) {
    // CONSENT COLLECTOR
    consentObtained['New Zealand DIA Citizenship'] = true;

    if (!inputData.diaCitizenshipConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('New Zealand Credit Bureau')) {
    // CONSENT COLLECTOR
    consentObtained['New Zealand Credit Bureau'] = true;

    if (!inputData.creditBureauConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerNZ;
