const dataSourcesGR = {
  creditBureau: {
    title: 'Greece Credit Bureau',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',

  }
};

export default dataSourcesGR;