import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
// import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// ADDRESS OPTIONS TODO: COME BACK AND ADD A FILTER INPUT / SELECTION
// import communeOptions from './addressOptions/communeOptions';
// import districtOptions from './addressOptions/districtOptions';
// import provinceOptions from './addressOptions/provinceOptions';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

// TODO: CREATE A FILTER DROPDOWN WHEN A USER ENTERS A VALUE
const CurrentResidentialAddressCambodia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { commune, district, province, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['Cambodia National ID'],
      element: (
        <TextFieldGroup
          key="commune"
          id="commune"
          type="text"
          name="commune"
          dataName="communeKH"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.commune')}
          value={commune}
          placeholder={transUTL('translatePlaceholder.inputKH.commune')}
          placeholderTrigger={isEmptyVAL(commune) ? 'input-empty' : ''}
          error={errors.commune}
          errorTrigger={errors.commune ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Cambodia National ID'],
      element: (
        <TextFieldGroup
          key="district"
          id="district"
          type="text"
          name="district"
          dataName="districtKH"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.district')}
          value={district}
          placeholder={transUTL('translatePlaceholder.inputKH.district')}
          placeholderTrigger={isEmptyVAL(district) ? 'input-empty' : ''}
          error={errors.district}
          errorTrigger={errors.district ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Cambodia National ID'],
      element: (
        <TextFieldGroup
          key="province"
          id="province"
          type="text"
          name="province"
          dataName="provinceKH"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.province')}
          value={province}
          placeholder={transUTL('translatePlaceholder.inputKH.province')}
          placeholderTrigger={isEmptyVAL(province) ? 'input-empty' : ''}
          error={errors.province}
          errorTrigger={errors.province ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CurrentResidentialAddressCambodia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressCambodia;
