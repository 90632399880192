import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveSelectedCountryAXN } from '../../actions/country/countryActions';
import { saveSelectedRegionAXN } from '../../actions/region/regionAction';
import { setAlertAXN } from '../../actions/alert/alertActions';
import { resetBiometricsAXN } from '../../actions/biometrics/biometricActions';

// COMPONENTS
import Dropdown from '../../components/dropdown/Dropdown';

// INITIAL STATE
import { initRegion } from './helpers/initialState';

// BUTTON
import Button from '../../components/btn/Button';

// VALIDATION
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import sortAlphaUTL from '../../utils/sortAlphaUTL';
import { transUTL } from '../../utils/transUTL';
import windowTopUTL from '../../utils/windowTopUTL';

const CountrySelection = ({
  authRXS,
  countryRXS,
  regionRXS,
  saveSelectedCountryAXN,
  saveSelectedRegionAXN,
  setAlertAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [countrySTH, setCountrySTH] = useState('');
  const [regionSTH, setRegionSTH] = useState(initRegion);
  const { countries } = authRXS.user;

  useEffect(() => {
    windowTopUTL();
    if (!isEmptyVAL(countryRXS)) {
      setRegionSTH({ ...regionSTH, [regionRXS]: !regionSTH[regionRXS] });
      setCountrySTH(countryRXS);
    }
    // eslint-disable-next-line
  }, []);

  const apacServices = countries
    .filter((item) => item.region.toLowerCase() === 'apac')
    .sort((a, b) => sortAlphaUTL(a, b, 'country'));
  const africaServices = countries
    .filter((item) => item.region.toLowerCase() === 'africa')
    .sort((a, b) => sortAlphaUTL(a, b, 'country'));
  const europeServices = countries
    .filter((item) => item.region.toLowerCase() === 'europe')
    .sort((a, b) => sortAlphaUTL(a, b, 'country'));
  const middleEastServices = countries
    .filter((item) => item.region.toLowerCase() === 'middleeast')
    .sort((a, b) => sortAlphaUTL(a, b, 'country'));
  const theAmericasServices = countries
    .filter((item) => item.region.toLowerCase() === 'theamericas')
    .sort((a, b) => sortAlphaUTL(a, b, 'country'));

  const handleOnSubmit = () => {
    if (isEmptyVAL(countrySTH))
      return setAlertAXN(
        transUTL('translateAlertMsg.pleaseSelectCountry'),
        'error'
      );

    saveSelectedCountryAXN(countrySTH);
    resetBiometricsAXN(); // CLEAR
    history.push('/data-source-selection');
  };

  const handleOnChange = (e) => setCountrySTH(e.target.value);
  const handelRadio = (opt) => setCountrySTH(opt);
  const handelRegionToggle = (opt) => setRegionSTH({ ...initRegion, ...opt });

  return (
    <div className="country-selection common-form">
      <header className="header-primary">
        <h2>{transUTL('translateCountrySelection.selectCountry')}</h2>
        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <p className="common-form__info">
          {transUTL('translateCountrySelection.subscription')}
        </p>

        {/* APAC REGION SERVICES */}
        {!isEmptyVAL(apacServices) && (
          <div
            className="common-form__box"
            onClick={() => {
              saveSelectedRegionAXN('apac');
              handelRegionToggle({ apac: !regionSTH.apac });
            }}
          >
            <h3 className="common-form__title">
              {transUTL('translateCountrySelection.apac')}
            </h3>
            <span className="material-icons common-form__arrow">
              arrow_drop_down
            </span>
          </div>
        )}
        {regionSTH.apac && (
          <Fragment>
            <Dropdown
              handleOnChange={handleOnChange}
              target={countrySTH}
              obj={apacServices}
              objKey="country"
              handelRadio={handelRadio}
              name="country"
              transType="translateCountryNames"
            />
          </Fragment>
        )}

        {/* AFRICA REGION SERVICES */}
        {!isEmptyVAL(africaServices) && (
          <div
            className="common-form__box"
            onClick={() => {
              saveSelectedRegionAXN('africa');
              handelRegionToggle({ africa: !regionSTH.africa });
            }}
          >
            <h3 className="common-form__title">
              {transUTL('translateCountrySelection.africa')}
            </h3>
            <span className="material-icons common-form__arrow">
              arrow_drop_down
            </span>
          </div>
        )}
        {regionSTH.africa && (
          <Fragment>
            <Dropdown
              handleOnChange={handleOnChange}
              target={countrySTH}
              obj={africaServices}
              objKey="country"
              handelRadio={handelRadio}
              name="country"
              transType="translateCountryNames"
            />
          </Fragment>
        )}

        {/* EUROPE REGION SERVICES */}
        {!isEmptyVAL(europeServices) && (
          <div
            className="common-form__box"
            onClick={() => {
              saveSelectedRegionAXN('europe');
              handelRegionToggle({ europe: !regionSTH.europe });
            }}
          >
            <h3 className="common-form__title">
              {transUTL('translateCountrySelection.europe')}
            </h3>
            <span className="material-icons common-form__arrow">
              arrow_drop_down
            </span>
          </div>
        )}
        {regionSTH.europe && (
          <Fragment>
            <Dropdown
              handleOnChange={handleOnChange}
              target={countrySTH}
              obj={europeServices}
              objKey="country"
              handelRadio={handelRadio}
              name="country"
              transType="translateCountryNames"
            />
          </Fragment>
        )}

        {/* MIDDLE EAST REGION SERVICES */}
        {!isEmptyVAL(middleEastServices) && (
          <div
            className="common-form__box"
            onClick={() => {
              saveSelectedRegionAXN('middleEast');
              handelRegionToggle({ middleEast: !regionSTH.middleEast });
            }}
          >
            <h3 className="common-form__title">
              {transUTL('translateCountrySelection.middleEast')}
            </h3>
            <span className="material-icons common-form__arrow">
              arrow_drop_down
            </span>
          </div>
        )}
        {regionSTH.middleEast && (
          <Fragment>
            <Dropdown
              handleOnChange={handleOnChange}
              target={countrySTH}
              obj={middleEastServices}
              objKey="country"
              handelRadio={handelRadio}
              name="country"
              transType="translateCountryNames"
            />
          </Fragment>
        )}

        {/* THE AMERICAS REGION SERVICES */}
        {!isEmptyVAL(theAmericasServices) && (
          <div
            className="common-form__box"
            onClick={() => {
              saveSelectedRegionAXN('theAmericas');
              handelRegionToggle({ theAmericas: !regionSTH.theAmericas });
            }}
          >
            <h3 className="common-form__title">
              {transUTL('translateCountrySelection.theAmericas')}
            </h3>
            <span className="material-icons common-form__arrow">
              arrow_drop_down
            </span>
          </div>
        )}
        {regionSTH.theAmericas && (
          <Fragment>
            <Dropdown
              handleOnChange={handleOnChange}
              target={countrySTH}
              obj={theAmericasServices}
              objKey="country"
              handelRadio={handelRadio}
              name="country"
              transType="translateCountryNames"
            />
          </Fragment>
        )}
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/dashboard"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

CountrySelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  regionRXS: PropTypes.string.isRequired,
  saveSelectedCountryAXN: PropTypes.func.isRequired,
  saveSelectedRegionAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  regionRXS: state.regionRXS,
});

export default connect(mapStateToProps, {
  saveSelectedCountryAXN,
  saveSelectedRegionAXN,
  setAlertAXN,
  resetBiometricsAXN,
})(CountrySelection);
