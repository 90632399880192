const dataSourcesBR = {
  brazilNationalID: {
    title: "巴西国民身份证",
    safeHarbourScore: "安全港评分",
    verified: "已验证",
    addressMatchScore: "地址匹配分数",
    nameMatchScore: "名字比赛分数",
    nationalIdNo: "身份证号码已验证",
    firstName: "名已验证",
    middleName: "中间名已验证",
    lastName: "姓已验证",
    dateOfBirth: "生日已验证",
    unitNo: "单元已认证",
    streetName: "街道地址已认证",
    locality: "城市已认证",
    district: "地区已认证",
    postCode: "邮编已认证"
  },
  taxRegistration: {
    title: "巴西税务登记",
    safeHarbourScore: "安全港评分",
    verified: "已验证",
    addressMatchScore: "地址匹配分数",
    nameMatchScore: "名字比赛分数",
    fullName: "全名已验证",
    dateOfBirth: "生日已验证",
    cpf: "CPF已验证"
  }
};

export default dataSourcesBR;
