const dataSourcesDE = {
  germanyResidential: {
    title: '德国住宅',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    gender: '性别验证',
    phoneNo: '电话号码已验证',
    street: '街道验证',
    city: '城市认证',
    state: '状态已验证',
    postCode: '邮编已验证',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
  },
  germanyUtility: {
    title: '德国公用事业',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    gender: '性别验证',
    phoneNo: '电话号码已验证',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
    street: '街道验证',
    city: '城市认证',
    state: '状态已验证',
    postCode: '邮编已验证',
  },
  germanyPhone: {
    title: '德国电话',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    phoneNo: '电话号码已验证',
    emailAddress: '电子邮件已验证',
    street: '街道已验证',
    city: '城市已验证',
    state: '州已验证',
    postCode: '邮政编码已验证',
  },
  germanyCreditBureau: {
    title: '德国信贷局',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    gender: '性别已验证',
    streetNo: '门牌号已验证',
    streetName: '街道名已验证',
    city: '城市已认证',
    state: '状态已验证',
    postCode: '邮编已验证',
  }
};

export default dataSourcesDE;
