const separator = doc => {
  // Makes sure that the footer does not overlap with Error Message Table
  const finalY = doc.previousAutoTable.finalY;
  const heightOfPage = doc.internal.pageSize.height;
  const diff = heightOfPage - finalY;
  if (diff < 200) {
    doc.addPage();
  }
};

export default separator;
