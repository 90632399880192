import React, {useState} from 'react';
import InputFields from './InputFields';
import AddressAutoComplete from '../../../pages/formOverview/reusableForms/AddressAutoComplete';
import { transUTL } from '../../../utils/transUTL';

const CurrentAddressAutoComplete = ({sectionKey, fields, data, errors, handleOnChange, handleOnBlur, handleOcrFileUpload}) => {
  const [isAutoComplete, setIsAutoComplete] = useState(false);
 
  const toggleAutoComplete = () => {
    setIsAutoComplete(!isAutoComplete);
  }

  return (
    <div>
      {
        isAutoComplete ? (
          <AddressAutoComplete/>
        ) : (
          <InputFields sectionKey={sectionKey} fields={fields} data={data} errors={errors} handleOnChange={handleOnChange} handleOnOcrFileUpload={handleOcrFileUpload}/>
        )
      }
      {
        !isAutoComplete && (
            <div
              onClick={toggleAutoComplete}
              className="form-overview__address-switch"
            >
              {transUTL('translateFormOverview.useAddressAutocomplete')}
            </div>
        )
      }
    </div>
  );
}

export default CurrentAddressAutoComplete;