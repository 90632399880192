const translateDashboard = {
  hello: 'Helo',
  iduMsg:
    'IDU, perkhidmatan pengesahan identiti digital yang mudah dan selamat.',
  readMore: 'Baca Lagi',

  // TILES
  identityVerification: 'PENGESAHAN IDENTITI',
  pdfHistory: 'SEJARAH PDF CARI',
  history: 'SEJARAH',
  pep: 'PEP & PEMBATALAN | ',
  watchlist: 'SENARAI TONTON',
  mobileVerification: 'PENGESAHAN BERGERAK',
  mobile: 'BERGERAK',
  digitalID: 'SIMPANAN ID DIGITAL',
  passwordAdvice: 'NASIHAT KATA LALUAN',
  sms: 'SMS',
  usageReport: 'LAPORAN PENGGUNAAN',
  data: 'DATA',
  dataAnalytics: 'ANALISA SUMBER DATA',
  globalCoverage: 'liputan global',
  comingSoon: 'Akan datang',
  noSubscription: 'Tanpa Langganan',
  subscribedCountryData: 'Data Negara yang Dilanggan',
  costEstimation: 'Anggaran kos',
  cost: 'Kos',
  eToro: 'eToro',
  eToroTwo: 'Penggunaan eToro',
  transactions: 'Urus Niaga',
  multiService: 'Merentas Sempadan',
  findIdU: 'Find ',
  searchForIndividuals: 'Cari Individu'
};

export default translateDashboard;
