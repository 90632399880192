
/*
  This array is being used temporarily.  It will be deleted after the PDF template 
  has been applied to all services of all countries.
  If the country is about to use its own PDF generation rather than pdf_template,
  it will need to add in the list below.
*/

const COUNTRIES_NOT_USE_PDF_TEMPLATE = [
  // 'global', // Cross-boder & Global Watchlist AML
  // 'australia',
  // 'watchlistaml',
  // 'bangladesh',
  // 'brazil',
  // 'chile',
  // 'cambodia',
  // 'china',
  // 'hongkong(specialadministrativeregionofchina)',
  // 'india',
  // 'indonesia',
  'malaysia', // using its own pdf implementation
  // 'singapore',
  // 'thailand',
  // 'vietnam',
  // 'philippines',
  // 'nigeria',
  // 'mexico',
  // 'unitedstates',
  // 'newzealand',
  // 'morocco',
  // 'germany',
  // 'france',
  // 'southafrica',
  // 'switzerland',
  // 'greece',
  // 'poland',
  // 'austria',
  // 'argentina',
  // 'peru',
  // 'slovakia',
  // 'italy',
  // 'unitedkingdom',
  // 'czechrepublic',
]

export default COUNTRIES_NOT_USE_PDF_TEMPLATE;
