const country = {
  aruba: 'Aruba',
  afghanistan: 'Afghanistan',
  angola: 'Angola',
  anguilla: 'Anguilla',
  alandIslands: 'Kepulauan Åland',
  albania: 'albania',
  andorra: 'Andorra',
  unitedArabEmirates: 'Emiriah Arab Bersatu',
  argentina: 'Argentina',
  armenia: 'Armenia',
  americanSamoa: 'American Samoa',
  antarctica: 'Antartika',
  frenchSouthernTerritories: 'Wilayah Selatan Perancis',
  antiguaAndBarbuda: 'Antigua dan Barbuda',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijan: 'Azerbaijan',
  burundi: 'Burundi',
  belgium: 'Belgium',
  benin: 'Benin',
  bonaireSintEustatiusAndSaba: 'Bonaire, Sint Eustatius dan Saba',
  burkinaFaso: 'Burkina Faso',
  bangladesh: 'Bangladesh',
  bulgaria: 'Bulgaria',
  bahrain: 'Bahrain',
  bahamas: 'Bahama',
  bosniaAndHerzegovina: 'Bosnia dan Herzegovina',
  saintBarthélemy: 'Saint Barthélemy',
  belarus: 'Belarus',
  belize: 'Belize',
  bermuda: 'Bermuda',
  bolivia: 'Bolivia (Negara Plurinasional)',
  brazil: 'Brazil',
  barbados: 'Barbados',
  bruneiDarussalam: 'Brunei Darussalam',
  bhutan: 'Bhutan',
  bouvetIsland: 'Pulau Bouvet',
  botswana: 'Botswana',
  centralAfricanRepublic: 'Republik Afrika Tengah',
  canada: 'Kanada',
  cocosKeelingIslands: 'Kepulauan Cocos (Keeling)',
  switzerland: 'Switzerland',
  chile: 'Cili',
  china: 'China',
  côteDIvoire: "Côte d'Ivoire",
  cameroon: 'Kamerun',
  congoDemocraticRepublic: 'Congo, Republik Demokratik',
  congo: 'Kongo',
  cookIslands: 'Kepulauan Cook',
  colombia: 'Colombia',
  comoros: 'Komoro',
  caboVerde: 'Cape Verde',
  costaRica: 'Costa rica',
  cuba: 'Cuba',
  curaçao: 'Curaçao',
  christmasIsland: 'Pulau Krismas',
  caymanIslands: 'Pulau Cayman',
  cyprus: 'Cyprus',
  czechia: 'Czechia',
  germany: 'Jerman',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  denmark: 'Denmark',
  dominicanRepublic: 'Republik Dominika',
  algeria: 'Algeria',
  ecuador: 'Ecuador',
  egypt: 'Mesir',
  eritrea: 'Eritrea',
  westernSahara: 'Sahara Barat',
  spain: 'Sepanyol',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  finland: 'Finland',
  fiji: 'Fiji',
  falklandIslandsMalvinas: 'Kepulauan Falkland (Malvinas)',
  france: 'Perancis',
  faroeIslands: 'Pulau Faroe',
  micronesia: 'Mikronesia (Negeri Bersekutu)',
  gabon: 'Gabon',
  unitedKingdomOfGreatBritainAndNorthernIreland:
    'United Kingdom',
  greatbritain: 'United Kingdom',
  georgia: 'Georgia',
  guernsey: 'Guernsey',
  ghana: 'Ghana',
  gibraltar: 'Gibraltar',
  guinea: 'Guinea',
  guadeloupe: 'Guadeloupe',
  gambia: 'Gambia',
  guineaBissau: 'Guinea-Bissau',
  equatorialGuinea: 'Guinea Khatulistiwa',
  greece: 'Yunani',
  grenada: 'Grenada',
  greenland: 'Tanah Hijau',
  guatemala: 'Guatemala',
  frenchGuiana: 'Guyana Perancis',
  guam: 'Guam',
  guyana: 'Guyana',
  hongKong: 'Hong Kong',
  heardIslandAndMcDonaldIslands: 'Pulau Heard dan Kepulauan McDonald',
  honduras: 'Honduras',
  croatia: 'Croatia',
  haiti: 'Haiti',
  hungary: 'Hungary',
  indonesia: 'Indonesia',
  isleOfMan: 'Pulau Man',
  india: 'India',
  britishIndianOceanTerritory: 'Wilayah Lautan Hindi British',
  ireland: 'Ireland',
  iran: 'Iran (Republik Islam Iran)',
  iraq: 'Iraq',
  iceland: 'Iceland',
  israel: 'Israel',
  italy: 'Itali',
  jamaica: 'Jamaica',
  jersey: 'Jersi',
  jordan: 'Jordan',
  japan: 'Jepun',
  kazakhstan: 'Kazakhstan',
  kenya: 'Kenya',
  kyrgyzstan: 'Kyrgyzstan',
  cambodia: 'Kemboja',
  kiribati: 'Kiribati',
  saintKittsAndNevis: 'Saint Kitts dan Nevis',
  korea: 'Korea',
  kuwait: 'Kuwait',
  laoPeoplesDemocraticRepublic: "Lao People's Democratic Republic",
  lebanon: 'Lubnan',
  liberia: 'Liberia',
  libya: 'Libya',
  saintLucia: 'Saint Lucia',
  liechtenstein: 'Liechtenstein',
  sriLanka: 'Sri Lanka',
  lesotho: 'lesotho',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  latvia: 'Latvia',
  macao: 'Makao',
  saintMartinFrenchPart: 'Saint Martin (bahagian Perancis)',
  morocco: 'Maghribi',
  monaco: 'Monaco',
  moldova: 'Moldova, Republik',
  madagascar: 'Madagascar',
  maldives: 'Maldives',
  mexico: 'Mexico',
  marshallIslands: 'Pulau Marshall',
  macedonia: 'Macedonia, bekas Republik Yugoslavia',
  mali: 'Mali',
  malta: 'Malta',
  myanmar: 'Myanmar',
  montenegro: 'Montenegro',
  mongolia: 'Mongolia',
  northernMarianaIslands: 'Kepulauan Mariana Utara',
  mozambique: 'Mozambik',
  mauritania: 'Mauritania',
  montserrat: 'Montserrat',
  martinique: 'Martinique',
  mauritius: 'Mauritius',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  mayotte: 'hilang',
  namibia: 'Namibia',
  newCaledonia: 'New Caledonia',
  niger: 'Niger',
  norfolkIsland: 'Pulau Norfolk',
  nigeria: 'Nigeria',
  nicaragua: 'Nicaragua',
  niue: 'Niue',
  netherlands: 'Belanda',
  norway: 'Norway',
  nepal: 'Nepal',
  nauru: 'Nauru',
  newZealand: 'New Zealand',
  oman: 'oman',
  pakistan: 'Pakistan',
  panama: 'Panama',
  pitcairn: 'Pitcairn',
  peru: 'Peru',
  philippines: 'Filipina',
  palau: 'Palau',
  papuaNewGuinea: 'Papua New Guinea',
  poland: 'Poland',
  puertoRico: 'Puerto Rico',
  portugal: 'Portugal',
  paraguay: 'Paraguay',
  palestine: 'Palestin, Negeri',
  frenchpPolynesia: 'Polynesia Perancis',
  qatar: 'Qatar',
  réunion: 'Mesyuarat',
  romania: 'Romania',
  russianFederation: 'Persekutuan Russia',
  rwanda: 'Rwanda',
  saudiArabia: 'Arab Saudi',
  sudan: 'Sudan',
  senegal: 'Senegal',
  singapore: 'Singapura',
  southGeorgiaAndTheSouthSandwichIslands:
    'South Georgia and the South Sandwich Islands',
  saintHelenaAscensionAndTristanDaCunha:
    'Saint Helena, Ascension and Tristan da Cunha',
  svalbardAndJanMayen: 'Svalbard dan Jan Mayen',
  solomonIslands: 'Kepulauan Solomon',
  sierraLeone: 'Sierra Leone',
  elSalvador: 'Penyelamat',
  sanMarino: 'San Marino',
  somalia: 'Somalia',
  saintPierreAndMiquelon: 'Saint Pierre dan Miquelon',
  serbia: 'Serbia',
  southSudan: 'selatan Sudan',
  saoTomeAndPrincipe: 'Sao Tome dan Principe',
  suriname: 'Nama Surin',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  sweden: 'Sweden',
  eswatini: 'Swaziland',
  sintMaartenDutchPart: 'Sint Maarten (bahagian Belanda)',
  seychelles: 'Seychelles',
  syrianArabRepublic: 'Republik Arab Syria',
  turksAndCaicosIslands: 'Kepulauan Turks dan Caicos',
  chad: 'Chad',
  togo: 'Untuk pergi',
  thailand: 'Thailand',
  tajikistan: 'Tajikistan',
  tokelau: 'Tokelau',
  turkmenistan: 'Turkmenistan',
  timorLeste: 'Timor-Leste',
  tonga: 'Tonga',
  trinidadAndTobago: 'Trinidad dan Tobago',
  tunisia: 'Tunisia',
  turkey: 'Turki',
  tuvalu: 'Tuvalu',
  taiwanProvinceOfChina: 'Taiwan, Wilayah China',
  tanzania: 'Tanzania, Republik Bersatu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  unitedStatesMinorOutlyingIslands: 'Kepulauan Terluar Kecil Amerika Syarikat',
  uruguay: 'Uruguay',
  unitedStatesOfAmerica: 'Amerika Syarikat',
  uzbekistan: 'Uzbekistan',
  saintVincentAndTheGrenadines: 'Saint Vincent dan Grenadines',
  venezuela: 'Venezuela (Republik Bolivarian)',
  virginIslandsBritish: 'Kepulauan Virgin (British)',
  virginIslandsUS: 'Kepulauan Virgin (A.S.)',
  vietNam: 'Vietnam',
  vanuatu: 'Vanuatu',
  wallisAndFutuna: 'Wallis dan Futuna',
  samoa: 'Samoa',
  yemen: 'Yaman',
  southAfrica: 'Afrika Selatan',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe',
};

export default country;
