const translateLogin = {
  loginBtn: 'Login',
  forgotPassword: 'Forgot your password?',
  contact: 'Contact Us',
  termsAndConditions: 'Terms & Conditions',
  privacy: 'Privacy Policy',
  copyright: 'Data Zoo',
  poweredBy: 'Powered By Data Zoo',
};

export default translateLogin;
