const dataSourcesNZ = {
  passport: {
    title: "New Zealand DIA Passport",
    searchSuccessful: "Search Successful",
    passportNo: "Passport Number",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    firstName: "First Name",
    lastName: "Last Name",
    dateOfBirth: "Date Of Birth",
  },
  driverLicence: {
    title: "New Zealand Driver Licence",
    searchSuccessful: "Search Successful",
    licenceNo: "Licence Number",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    addressMatchScore: "Address Match Score",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    dateOfBirth: "Date Of Birth",
  },
  companiesOffice: {
    title: "New Zealand Companies Office",
    searchSuccessful: "Search Successful",
    licenceNo: "Licence Number",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    address1: "Street No and Name",
    postCode: "Post Code",
    city: "City",
    suburb: "Suburb",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    phoneNo: "Phone No",
    landlineNo: "Landline No",
  },
  diaBirth: {
    title: "New Zealand DIA Birth",
    searchSuccessful: "Search Successful",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    firstName: "First Name",
    lastName: "Last Name",
    middleName: "Middle Name",
    dateOfBirth: "Date Of Birth",
  },
  diaCitizen: {
    title: "New Zealand DIA Citizenship",
    searchSuccessful: "Search Successful",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    firstName: "First Name",
    lastName: "Last Name",
    middleName: "Middle Name",
    dateOfBirth: "Date Of Birth",
  },
  linzProperty: {
    title: "New Zealand LINZ Property",
    searchSuccessful: "Search Successful",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    addressMatchScore: "Address Match Score",
    otherOwners: "Other Owners",
    address1: "Street No and Name",
    postCode: "Post Code",
    firstName: "First Name",
    lastName: "Last Name",
    middleName: "Middle Name",
    dateOfBirth: "Date Of Birth",
    city: "City",
    suburb: "Suburb",
    phoneNo: "Phone No",
    landlineNo: "Landline No",
  },
  yellowPages: {
    title: "New Zealand Yellow Pages",
    searchSuccessful: "Search Successful",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    addressMatchScore: "Address Match Score",
    firstName: "First Name",
    lastName: "last Name",
    address1: "Street",
    state: "State",
    suburb: "Suburb",
    postCode: "Post Code",
  },
  residential: {
    title: "New Zealand Residential",
    searchSuccessful: "Search Successful",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    addressMatchScore: "Address Match Score",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "last Name",
    dateOfBirth: "Date Of Birth",
    address1: "Street No and Name",
    city: "City",
    suburb: "Suburb",
    postCode: "Post Code",
    state: "State",
    phoneNo: "Phone No",
    landlineNo: "Landline No",
  },
  creditBureau: {
    title: "New Zealand Credit Bureau",
    searchSuccessful: "Search Successful",
    identityVerified: "Identity Verified",
    safeHarbourScore: "Safe Harbour Score",
    nameMatchScore: "Name Match Score",
    addressMatchScore: "Address Match Score",
    addressElement1: "Street No",
    addressElement2: "Street Name",
    addressElement3: "Suburb",
    addressElement4: "City",
    addressElement5: "Post Code",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "last Name",
    dateOfBirth: "Date Of Birth",
    postCode: "Post Code",
    state: "State",
    suburb: "Suburb",
  },
};

export default dataSourcesNZ;
