const dataSourcesNG = {
  nigeriaNationalID: {
    title: 'ID Nasional Nigeria',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    middleName: 'Nama Tengah Disahkan',
    lastName: 'Nama Akhir Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    nationalIdNo: 'Nombor ID Kebangsaan Disahkan',
    addressMatchScore: 'Markah Padanan Alamat',
    street: 'Alamat Jalan Disahkan',
    town: 'Bandar Disahkan',
    lga: 'Kawasan Kerajaan Tempatan Disahkan',
    state: 'Negeri Disahkan',
    gender: 'Jantina Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    email: 'E-mel Disahkan'
  },
  nigeriaBankVerification: {
    title: 'Pengesahan Bank Nigeria',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    middleName: 'Nama Tengah Disahkan',
    lastName: 'Nama Akhir Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    bankVerificationNo: 'Nombor Pengesahan Bank Disahkan',
    addressMatchScore: 'Markah Padanan Alamat',
    street: 'Alamat Jalan Disahkan',
    town: 'Bandar Disahkan',
    lga: 'Kawasan Kerajaan Tempatan Disahkan',
    state: 'Negeri Disahkan',
    gender: 'Jantina Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    email: 'E-mel Disahkan'
  }
};

export default dataSourcesNG;
