import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { saveDataSourceSelectionsAXN } from '../../actions/dataSources/dataSourcesActions';
import { setAlertAXN } from '../../actions/alert/alertActions';
import { saveSelectedCountryAXN } from '../../actions/country/countryActions';

// COMPONENTS
import Button from '../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { dataSourcesIsCheckedUTL } from '../../utils/outputMasterUTL';
import { transUTL } from '../../utils/transUTL';

//CONSTANTS
import { CROSS_BORDER_DATA_SOURCES, CROSS_BORDER_VERIFICATION } from "../../constants/crossBorderDataSources";
import CACHE from '../../constants/cache';

//HELPERS
import modifiedAuth from '../../actions/auth/helpers/modifiedAuth/modifiedAuth';

const CrossBorderSelection = ({
  authRXS,
  dataSourceSelectionsRXS,
  saveDataSourceSelectionsAXN,
  saveSelectedCountryAXN,
  setAlertAXN,
  history,
}) => {

  const [dataSourcesSTH, setDataSourcesSTH] = useState({});

  // If all data sources can be added to cross-border, crossBorderDataSourcesSTH will be same as authRXS.user.countries
  const getUserPermittedCrossBorderDataSources = () => {
    let userPermittedDataSources = [];
    [...authRXS.user.countries].forEach(country => userPermittedDataSources = [...userPermittedDataSources, ...country.dataSources]);
    let permittedCrossBorderDataSources = [];
    userPermittedDataSources.forEach(dataSourceP => {
      const result = CROSS_BORDER_DATA_SOURCES.find(dataSourceC => dataSourceC === dataSourceP);
      if (result)
        permittedCrossBorderDataSources = [...permittedCrossBorderDataSources, result]
    });
    return permittedCrossBorderDataSources;
  }
  const [crossBorderDataSourcesSTH, setCrossBorderDataSourcesSTH] = useState(modifiedAuth(getUserPermittedCrossBorderDataSources()));

  const { GLOBAL_DATA_SOURCE_SELECTIONS_CACHE } = CACHE;

  useEffect(() => {
    saveSelectedCountryAXN(CROSS_BORDER_VERIFICATION)
    // eslint-disable-next-line
  }, []);

  const initialiseDataSourceSelections = () => {
    let extractedDataSource = dataSourceSelectionsRXS[CROSS_BORDER_VERIFICATION];
    if (extractedDataSource)
      setDataSourcesSTH({ ...extractedDataSource });
  }
  useEffect(initialiseDataSourceSelections, []);

  const updateDataSourceSelections = () => {
    let globalDataSourceSelectionsCache = JSON.parse(sessionStorage.getItem(GLOBAL_DATA_SOURCE_SELECTIONS_CACHE));
    if (!globalDataSourceSelectionsCache) {
      globalDataSourceSelectionsCache = {};
    }
    globalDataSourceSelectionsCache[CROSS_BORDER_VERIFICATION] = { ...dataSourcesSTH };
    sessionStorage.setItem(GLOBAL_DATA_SOURCE_SELECTIONS_CACHE, JSON.stringify(globalDataSourceSelectionsCache));

    saveDataSourceSelectionsAXN(CROSS_BORDER_VERIFICATION, dataSourcesSTH);
  };
  useEffect(updateDataSourceSelections, [dataSourcesSTH]);

  const handleOnSubmit = () => {
    const selectedDataSources = dataSourcesIsCheckedUTL(dataSourcesSTH);

    if (isEmptyVAL(selectedDataSources))
      return setAlertAXN(
        transUTL('translateAlertMsg.pleaseSelectDataSource'),
        'error'
      );

    history.push('/form-overview-template')
  };

  const handelCheckbox = (opt) =>
    setDataSourcesSTH({
      ...dataSourcesSTH,
      [opt]: !dataSourcesSTH[opt],
    });

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>Data Source(s) Selection</h2>

        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <p className="common-form__info">
          {transUTL('translateDataSourceSelection.select2')}
        </p>
        {
          crossBorderDataSourcesSTH.map((item) => {
            let { country, dataSources } = item;

            return (
              <Fragment
                key={country}>
                <div
                  className="common-form__box"
                  onClick={() => {
                    setCrossBorderDataSourcesSTH(crossBorderDataSourcesSTH.map(c => {
                      if (c.country === country) {
                        return {
                          ...c,
                          isExpanded: !c.isExpanded,
                        }
                      } else {
                        return c
                      }
                    }))
                  }}>

                  <h3 className="common-form__title">
                    {transUTL(`translateCountryNames.${country}`)}
                  </h3>
                  <span className="material-icons common-form__arrow">
                    arrow_drop_down
                  </span>
                </div>

                {
                  item.isExpanded && (
                    <div className="dropdown">
                      {dataSources.sort().map((item) => {
                        const dataSource = item.split(' ').join('').toLowerCase();
                        const i18nTitleKey = `translateDataSources.${dataSource}`;
                        return (
                          <div
                            key={item}
                            className="data-source-selection__checkbox"
                            onClick={() => handelCheckbox(item)}
                          >
                            <h3 className="data-source-selection__title">
                              {transUTL(i18nTitleKey)}
                            </h3>
                            {dataSourcesSTH[item] ? (
                              <span className="material-icons data-source-selection__icon-checked">
                                check_box
                              </span>
                            ) : (
                              <span className="material-icons data-source-selection__icon-unchecked">
                                check_box_outline_blank
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )
                }
              </Fragment>
            );
          })
        }

        <div className="dropdown">
          <div
            className="data-source-selection__checkbox"
            onClick={() => handelCheckbox('Watchlist AML')}
          >
            <h3 className="data-source-selection__title">
              {transUTL('translateDataSources.watchlistaml')}
            </h3>
            {dataSourcesSTH['Watchlist AML'] ? (
              <span className="material-icons data-source-selection__icon-checked">
                check_box
              </span>
            ) : (
              <span className="material-icons data-source-selection__icon-unchecked">
                check_box_outline_blank
              </span>
            )}
          </div>
        </div>
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/dashboard"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

CrossBorderSelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  dataSourceSelectionsRXS: PropTypes.object.isRequired,
  saveDataSourceSelectionsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  saveSelectedCountryAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  dataSourceSelectionsRXS: state.dataSourceSelectionsRXS,
});

export default connect(mapStateToProps, {
  setAlertAXN,
  saveDataSourceSelectionsAXN,
  saveSelectedCountryAXN
})(
  CrossBorderSelection
);
