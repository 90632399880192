const stateCapitalAU = {
  queensland: '昆士兰州',
  newSouthWales: '新南威尔士州',
  southAustralia: '南澳大利亚',
  tasmania: '塔斯马尼亚',
  victoria: '维多利亚州',
  westernAustralia: '澳大利亚西部',
  australianCapitalTerritory: '澳大利亚首都领地',
  northernTerritory: '北方领土',
};

export default stateCapitalAU;
