import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// REDUX ACTIONS
import { saveFormDataAXN } from "../../../../actions/formData/formDataActions";
import { saveUserInputAXN } from "../../../../actions/formData/userInputActions";
import { getUserPIIDataV2AXN } from "../../../../actions/transactionHistory/transactionHistoryActions";

// SEARCH RESULTS
import NewZealandPassport from "./results/NewZealandPassport";
import NewZealandDriverLicence from "./results/NewZealandDriverLicence";
import NewZealandCompaniesOffice from "./results/NewZealandCompaniesOffice";
import NewZealandDiaBirth from "./results/NewZealandDiaBirth";
import NewZealandDiaCitizenship from "./results/NewZealandDiaCitizenship";
import NewZealandLinzProperty from "./results/NewZealandLinzProperty";
import NewZealandYellowPages from "./results/NewZealandYellowPages";
import NewZealandResidential from "./results/NewZealandResidential";
import NewZealandCreditBureau from "./results/NewZealandCreditBureau";
import GlobalWatchlistAML from "../../reusableSearch/GlobalWatchlistAML";
import ApiResults from "../../reusableComponent/ApiResults";

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

// UTILITIES
import { transUTL } from "../../../../utils/transUTL";
import whitelistedInputDataNZ from "../../../formOverview/countryForms/newzealand/helpers/whitelistedInputDataNZ";
import genderStringUTL from "../../../../utils/genderStringUTL";
import { dataSourcesIsCheckedUTL } from "../../../../utils/outputMasterUTL";

const NewZealandResults = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  formDataRXS,
  userInputRXS,
  apiReqRXS,
  searchVerificationRXS,
  saveFormDataAXN,
  getUserPIIDataV2AXN,
  watchlistStatus,
  handleClearResults,
  history,
}) => {
  const { newzealandFormData } = formDataRXS;
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.newzealandDataSources
  );

  let { serviceResponses } = searchVerificationRXS;

  if (isEmptyVAL(serviceResponses)) {
    serviceResponses = {};
  }

  const whitelistedData = whitelistedInputDataNZ(
    newzealandFormData,
    selectedDataSources
  );

  const { ...displayData } = whitelistedData;

  displayData.gender = genderStringUTL(displayData);

  const displayDataFiltered = Object.keys(displayData)
    .map((val) => displayData[val])
    .filter((v) => !isEmptyVAL(v));

  // GENERATE PDF
  const generatePDF = () =>
    getUserPIIDataV2AXN(authRXS.user, searchVerificationRXS.reportingReference);

  return (
    <Fragment>
      <header className="header-secondary search-verification__input-data">
        <h4 className="header-title-secondary">
          <strong>{transUTL("translateSearchVerification.inputData")}</strong>{" "}
          {displayDataFiltered.join(" | ")}
        </h4>
      </header>

      {!isEmptyVAL(serviceResponses) && (
        <NewZealandPassport
          results={serviceResponses}
          type="New Zealand DIA Passport"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandDriverLicence
          results={serviceResponses}
          type="New Zealand Driver Licence"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandCompaniesOffice
          results={serviceResponses}
          type="New Zealand Companies Office"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandDiaBirth
          results={serviceResponses}
          type="New Zealand DIA Birth"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandDiaCitizenship
          results={serviceResponses}
          type="New Zealand DIA Citizenship"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandLinzProperty
          results={serviceResponses}
          type="New Zealand LINZ Property"
        />
      )}
      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <NewZealandYellowPages
          results={searchVerificationRXS.serviceResponses}
          type="New Zealand Yellow Pages"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandResidential
          results={serviceResponses}
          type="New Zealand Residential"
        />
      )}
      {!isEmptyVAL(serviceResponses) && (
        <NewZealandCreditBureau
          results={serviceResponses}
          type="New Zealand Credit Bureau"
        />
      )}

      {!isEmptyVAL(serviceResponses) && (
        <GlobalWatchlistAML results={serviceResponses} type="Watchlist AML" />
      )}

      {userInputRXS.isTickedApiDisplay && (
        <ApiResults
          results={searchVerificationRXS}
          apiReq={apiReqRXS.apiCall}
        />
      )}

      {/* BUTTONS */}
      <div className="search-verification__btns">
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.push("/form-overview")}
          >
            {transUTL("translateBtn.editSearch")}
          </button>

          <button className="btn-primary" onClick={handleClearResults}>
            {transUTL("translateBtn.newSearch")}
          </button>
        </div>

        <div className="btn-container btn-under">
          <button className="btn-primary" onClick={generatePDF}>
            {transUTL("translateBtn.generatePDF")}
          </button>

          <button
            className="btn-primary"
            onClick={() => window.print(history.push("/search-verification"))}
          >
            {transUTL("translateBtn.printSearchResults")}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

NewZealandResults.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  apiReqRXS: PropTypes.object.isRequired,
  searchVerificationRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  saveUserInputAXN: PropTypes.func.isRequired,
  getUserPIIDataV2AXN: PropTypes.func.isRequired,
  watchlistStatus: PropTypes.object.isRequired,
  handleClearResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  apiReqRXS: state.apiReqRXS,
  searchVerificationRXS: state.searchVerificationRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  saveUserInputAXN,
  getUserPIIDataV2AXN,
})(withRouter(NewZealandResults));
