import jsonTableRow from './rows';

// HELPERS
import title from '../../../../helpers/title';

// UTILS
import arrayTo2DArrayUTL from '../../../../../../utils/arrayTo2DArrayUTL';

const dataSourceVerificationResults = (doc, data, info, docStyles) => {
  const { Response } = data;

  // TITLE
  title(
    doc,
    docStyles,
    'Summary of Data Source Verifications and Results',
    docStyles.headers.secondary,
    docStyles.separation
  );

  // DEFAULT COLUMN
  const columns = [{ title: 'Data Sources', dataKey: 'col1' }];

  // DATA SOURCES RESULTS
  let creditBureau = Response.serviceResponses['Singapore Credit Bureau'];
  let residential = Response.serviceResponses['Singapore Residential'];
  let utility = Response.serviceResponses['Singapore Utility'];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  // SINGAPORE CREDIT BUREAU
  if (creditBureau) {
    columns.push({
      title: 'Singapore Credit Bureau',
      dataKey: 'col2',
    });
  } else {
    creditBureau = { verifications: {}, returnedData: {} };
  }

  // SINGAPORE RESIDENTIAL
  if (residential) {
    columns.push({
      title: 'Singapore Residential',
      dataKey: 'col3',
    });
  } else {
    residential = { verifications: {}, returnedData: {} };
  }

  // SINGAPORE UTILITY
  if (utility) {
    columns.push({
      title: 'Singapore Utility',
      dataKey: 'col4',
    });
  } else {
    utility = { verifications: {}, returnedData: {} };
  }

  // GLOBAL WATCHLIST AML
  if (watchlistAML) {
    columns.push({
      title: 'Global Watchlist AML',
      dataKey: 'col5',
    });
  } else {
    watchlistAML = { verifications: {}, returnedData: {} };
  }

  // TABLE ROW
  const rows = jsonTableRow({
    creditBureau,
    residential,
    utility,
    watchlistAML,
  });

  // TABLE
  let finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  const maxAmountOfColumns = 3;
  const firstColumn = columns.shift();
  const maxColArray = arrayTo2DArrayUTL(columns, maxAmountOfColumns); // 3 COLUMNS ONLY

  maxColArray.map((col, index) => {
    col.unshift(firstColumn);

    if (index > 0) {
      finalY = doc.previousAutoTable.finalY + docStyles.separation;
    }

    let cellWidth = docStyles.fullCellLength / 4;
    if (maxColArray[index].length === 4) {
      cellWidth = docStyles.fullCellLength / 4;
    } else if (maxColArray[index].length === 3) {
      cellWidth = docStyles.fullCellLength / 3;
    } else if (maxColArray[index].length === 2) {
      cellWidth = docStyles.fullCellLength / 2;
    }

    doc.autoTable(col, rows, {
      didParseCell: (data) => {
        const { cell } = data;
        cell.styles.fillColor = [255, 255, 255];
        if (cell.section.includes('body') && cell.raw.length > 30) {
          cell.styles.cellPadding = { top: 15, bottom: 15 };
        }
      },
      showHead: 'firstPage',
      styles: {
        textColor: docStyles.colorText,
        font: docStyles.fontFamily.time,
        ...docStyles.tableStyles,
      },
      headStyles: {
        fontSize: docStyles.textSize,
        textColor: docStyles.colorGreen,
        fontStyle: 'normal',
      },
      columnStyles: {
        col1: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
        col2: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
        col3: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
      },
      startY: finalY,
    });
    return null;
  });
};

export default dataSourceVerificationResults;
