const translateNavigation = {
  individualIdentity: 'Individual Identity',
  verification: 'Verification',
  profile: 'Profile',
  dashboard: 'Dashboard',
  history: 'History',
  dataAnalytics: 'Data Analytics',
  documentLibrary: 'Document Library',
  aboutUs: 'About Us',
  logout: 'Logout',
  costEstimation: 'Cost Estimation',
  admin: 'Admin',
};

export default translateNavigation;
