const dataSourcesIN = {
  driverLicence: {
    title: 'Lesen Memandu India',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    documentNumber: 'nombor dokumen',
    name: 'Nama',
    address: 'Alamat',
    dateOfBirth: 'Tarikh lahir',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
  },
  epic: {
    title: 'EPIC India',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    documentNumber: 'nombor dokumen',
    name: 'Nama',
    gender: 'Jantina',
    address: 'Alamat',
    dateOfBirth: 'Tarikh lahir',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
  },
  pan: {
    title: 'PAN India',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    documentNumber: 'nombor dokumen',
    name: 'Nama',
    gender: 'Jantina',
    address: 'Alamat',
    dateOfBirth: 'Tarikh lahir',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
  },
  passport: {
    title: 'Pasport India',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    dateOfBirth: 'Tarikh lahir',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfIssue: 'Tarikh dikeluarkan',
    fileNo: 'Fail No.',
    passportNo: 'No Pasport',
  },
  aadhaarNo: {
    title: 'Aadhaar India',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    documentNumber: 'nombor dokumen',
    name: 'Nama',
    gender: 'Jantina',
    address: 'Alamat',
    dateOfBirth: 'Tarikh lahir',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    aadhaarNo: 'No Aadhaar',
  },
};

export default dataSourcesIN;
