import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';

// HELPERS
import initialStateFR from './helpers/initialStateFR';
import whitelistedInputDataFR from './helpers/whitelistedInputDataFR';
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL"; 
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import franceVAL from '../../../../validations/inputFields/franceVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationFrance from './inputForms/PersonalInformationFrance';
import CurrentResidentialAddressFrance from './inputForms/CurrentResidentialAddressFrance';
import ContactDetailsFrance from './inputForms/ContactDetailsFrance';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import rmSpaceUTL from '../../../../utils/rmSpaceUTL';

const FranceForms = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateFR);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.franceDataSources
  );

  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.franceFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = franceVAL(inputData, selectedDataSources);
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // FRANCE DATASOURCES DON'T REQUIRE CONSENT

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataFR(inputData, selectedDataSources);

    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // ADDRESS MANIPULATION
    const addressElement1 = rmSpaceUTL(inputData.streetAddress); // REMOVES ADDITIONAL WHITE SPACES
    const addressElement3 = rmSpaceUTL(inputData.city);
    const addressElement4 = rmSpaceUTL(inputData.state);
    const addressElement5 = rmSpaceUTL(inputData.postCode);

    // // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      addressElement1,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // FIRES THE MOMENT THE ELEMENT LOSES FOCUS
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const { errors, ...filteredFormData } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // WHEN USER HITS THE ENTER DURING/AFTER INPUT IT TRIGGERS A STATE SAVE
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');

    // OMITS `errors`
    const { errors, ...reset } = initialStateFR;

    // RESETS TO INITIAL STATE
    setUserInputSTH(initialStateFR);

    // RESETS FRANCE FORM DATA
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
          };
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded selfie 
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  // ALL THESES DATA SOURCES HERE DOES NOT REQUIRE CONSENT
  // UI - SHOWS (CLIENT REFERENCE) SECTION
  conditionalClientRef.push(...[
    'Watchlist AML',
    'France Residential',
    'France Residential 2',
    'France Residential 3',
  ]);


  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'France Residential',
        'France Residential 2',
        'France Residential 3',
        'Watchlist AML'
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationFrance
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'France Residential',
        'France Residential 2',
        'France Residential 3'
      ],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressFrance
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'France Residential 2',
        'France Residential 3'
      ],
      element: (
        <FormController
          key="contactDetails"
          title={transUTL('translateFormOverview.formHeaders.contactDetails')}
          inputForm={
            <ContactDetailsFrance
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      )
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

FranceForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(FranceForms));
