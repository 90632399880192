// import sizeOf from 'image-size';

// NOTE: commented this function to prevent breaking app.
// Because this function and 'image-size' library were used in 
// previous individual PDF generation files 'comsumerDataSupplied' for each country.
// Not delete it for in case individual PDF generation would be in use,
// then there would reqire some changes related,
// pls refer the changes in 'comsumerDataSupplied' of pdf-template
const getImageDimensionsFromBase64 = (base64) => {
  // const img = Buffer.from(base64, 'base64');
  // const dimensions = sizeOf(img);
  const dimensions = {height: 0, width: 0};
  return dimensions;
};

export default getImageDimensionsFromBase64;
