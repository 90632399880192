const splitFullName = (fullName) => {
  let FirstName = '';
  let MiddleName = '';
  let LastName = '';
  const fullNameSplit = fullName.trim().split(' ');
  FirstName = fullNameSplit.shift(); // Get first element as first name
  LastName = fullNameSplit.pop(); // Get last element as last name
  // If there is any element left, take it/them as middle name
  if (fullNameSplit.length > 1) {
    MiddleName = fullNameSplit.join(' ');
  }
  if (fullNameSplit.length === 1) {
    MiddleName = fullNameSplit[0];
  }
  return {FirstName, MiddleName, LastName};
}

export default splitFullName;