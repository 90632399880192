import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILS
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD AUSTRALIA API ERRORS TO INPUT FORM
const feedbackAU = (errors, dispatch) => {
  try {
    loggerUTL('ERRORS...', errors);
    const inputError = {};

    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'fullName')) {
      inputError.firstName = `Full name exceeds maximum length`;
      inputError.lastName = `Full name exceeds maximum length`;
    }

    /* ============================================
                BIRTH CERTIFICATE
     ============================================ */
    if (findErrorUTL(errors, 'birthRegistrationState')) {
      inputError.birthRegistrationState = `Birth registration state ${
        errors
          .find((item) => item.includes('birthRegistrationState'))
          .split(':')[1]
      }`;
    }

    /* ============================================
                CENTRELINK CARD
     ============================================ */
    /* ============================================
                DRIVERS LICENCE
     ============================================ */
    if (findErrorUTL(errors, 'driversLicenceNo')) {
      inputError.driversLicenceNo = `Driver licence number should be ${
        errors.find((item) => item.includes('driversLicenceNo')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'driversLicenceCardNo')) {
      inputError.driversLicenceCardNo = `Driver licence card number should be ${
        errors.find((item) => item.includes('driversLicenceCardNo')).split(':')[1]
      }`;
    }

    /* ============================================
                MEDICARE CARD
     ============================================ */
     if (findErrorUTL(errors, 'medicareCardNo')) {
      inputError.medicareCardNo = `Medicare card number ${
        errors.find((item) => item.includes('medicareCardNo')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'expiry')) {
      inputError.medicareExpiry = `Medicare Expiry date must be in the future`;
    }

    /* ============================================
          CURRENT RESIDENTIAL ADDRESS
     ============================================ */
    if (findErrorUTL(errors, 'addressElement5')) {
      inputError.postCode = `Post code ${
        errors.find((item) => item.includes('addressElement5')).split(':')[1]
      }`;
    }

    /* ============================================
                CONTACT
     ============================================ */
    if (findErrorUTL(errors, 'phoneNo')) {
      inputError.phoneNo = `Phone number should be ${
        errors.find((item) => item.includes('phoneNo')).split(':')[1]
      }`;
    }
    /* ============================================
                PASSPORT
     ============================================ */
    if (findErrorUTL(errors, 'passportNo')) {
      inputError.passportNo = `Passport number ${
        errors.find((item) => item.includes('passportNo')).split(':')[1]
      }`;
    }

    /* ============================================
                ABN
     ============================================ */
     if (findErrorUTL(errors, 'ABN')) {
      inputError.ABN = `ABN ${
        errors.find((item) => item.includes('ABN')).split(':')[1]
      }`;
    }

    dispatch({
      type: ERROR_FEEDBACK,
      payload: inputError,
    });
  } catch (err) {
    loggerUTL(err);
    Sentry.captureException(
      'FAILED TO PARES AUSTRALIA VALIDATION ERRORS...',
      JSON.stringify
    )(err);
  }
};

export default feedbackAU;
