// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataKH = (inputData, dataSources) => {
  let cambodiaConsumerData;
  let watchlistAMLData;

  /* ============================================
              CAMBODIA NATIONAL ID
   ============================================ */
  if (dataSources.includes('Cambodia National ID')) {
    loggerUTL('WHITELISTING CAMBODIA NATIONAL ID...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'gender',
      'dateOfBirth',
      'voterNo',
      'commune',
      'district',
      'province',
      'consumerDataConsentObtained',
      'reportingReference',
    ]);

    cambodiaConsumerData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  loggerUTL(dataSources, '=-=======')

  return {
    ...cambodiaConsumerData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataKH;
