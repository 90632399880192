const inputLabels = {
  // CREDENTIALS
  username: 'USERNAME',
  password: 'PASSWORD',
  // PERSONAL INFORMATION
  firstName: 'FIRST NAME',
  middleName: 'MIDDLE NAME',
  lastName: 'LAST NAME',
  dateOfBirth: 'DATE OF BIRTH',
  gender: 'GENDER',
  fullName: 'FULL NAME',

  // CURRENT RESIDENTIAL ADDRESS
  building: 'BUILDING',
  block: 'STREET/BLOCK NO.',
  unitNo: 'UNIT NO.',
  addressLine1: 'ADDRESS LINE 1',
  addressLine2: 'ADDRESS LINE 2',
  street: 'STREET',
  streetNo: 'STREET NO.',
  streetName: 'STREET NAME',
  streetNumberAndName: 'STREET NO. AND NAME',
  streetType: 'STREET TYPE',
  suburb: 'SUBURB',
  county: 'COUNTY',
  state: 'STATE',
  postCode: 'POST CODE',
  postcode: 'POSTCODE',
  zipCode: 'ZIP CODE',
  postCodeSG: 'Unit # and Post Code',
  streetNoAndUnitNo: 'UNIT / STREET NO.',
  city: 'CITY',
  townOrCity: 'TOWN/CITY',
  ward: 'WARD',
  region: 'REGION',
  barangay: 'BARANGAY',
  province: 'PROVINCE',
  locality: 'LOCALITY',
  district: 'DISTRICT',
  commune: 'COMMUNE',
  town: 'TOWN',
  streetNameIDN: 'STREET (ALAMAT)', // Indonesia
  villageIDN: 'VILLAGE (KELURAHAN)', // Indonesia
  districtIDN: 'DISTRICT (KECAMATAN)', // Indonesia
  cityIDN: 'CITY/REGENCY (KABUPATEN)', // Indonesia
  addressOCRVN: 'Address', // VIETNAM
  lga: 'LGA', // NIGERIA - Local Government Area
  streetNameAndHouseNo: 'STREET NAME AND HOUSE NO.',
  regionCode: 'REGION CODE', // ITALY, CZECH
  houseNumber: 'HOUSE NO.', // ITALY, CZECH

  // ABN
  ABN: 'ABN',

  // DRIVERS LICENCE
  driversLicenceNo: 'LICENCE NO.',
  driversLicenceNumber: 'LICENCE NO.',
  driversLicenceCardNo: 'CARD NO.',
  driversLicenceStateOfIssue: 'STATE OF ISSUE',
  driversLicenceExpiryDate: 'Expiry Date',
  driversLicenceVersion: 'DRIVERS LICENCE VERSION',
  vehiclePlate: 'VEHICLE REGISTRATION',

  // MEDICARE
  medicareCardNo: 'CARD NO.',
  medicareCardType: 'MEDICARE CARD TYPE',
  medicareReferenceNo: 'INDIVIDUAL REF NO.',
  medicareExpiry: 'EXPIRY DATE',

  // PASSPORT
  passportNo: 'PASSPORT NO.',
  passportIssuerCountry: 'COUNTRY OF ISSUE',
  passportExpiry: 'EXPIRY DATE',
  countryCode: 'COUNTRY CODE',
  fileNo: 'FILE NO',
  dateOfIssue: 'Date OF ISSUE',

  // CONTACT DETAILS
  mobileNo: 'MOBILE PHONE NO.',
  email: 'E-MAIL',
  phoneType: 'PHONE TYPE',
  phoneNumber: 'PHONE NO.',
  telephone: 'Telephone',
  emailAddress: 'EMAIL ADDRESS',

  // CHINESE SEARCH
  idCardNo: 'ID CARD NO.',
  bankCardNo: 'BANK CARD NO.',
  phoneNo: 'PHONE NO.',
  ocrScan: 'SCAN & OCR',

  // IDENTITY DATA
  laserNo: 'Laser NO.',
  idNo: 'ID CARD NO.',
  vin: 'National ID NO.',
  voterNo: 'Voter ID',
  nationalIDNo: 'National ID No',
  landlineNo: 'Land Line No',
  curp: 'Curp',
  rfc: 'RFC',
  taxCodeNo: 'Tax Code No.',
  nationalIdNo: 'National ID No',

  // BIRTH CERTIFICATE
  birthRegistrationState: 'BIRTH REGISTRATION STATE',
  birthRegistrationNo: 'BIRTH REGISTRATION NO.',
  birthRegistrationDate: 'BIRTH REGISTRATION DATE',
  birthCertificateNo: 'BIRTH CERTIFICATE NO.',
  isRequiredCertificateNo: 'Birth certificate number must be provided for:',
  isRequiredCertificateNo_NSW: 'NSW birth certificates issued after 01/07/2010',
  isRequiredCertificateNo_SA: 'SA birth certificates issued after 01/11/1999',
  isRequiredCertificateNo_Others: 'All ACT and NT birth certificates', // ACT and NT in Australia
  placeOfBirth: 'PLACE OF BIRTH',
  mothersFirstName: 'MOTHERS FIRST NAME',
  mothersLastName: 'MOTHERS LAST NAME',
  fathersFirstName: 'FATHERS FIRST NAME',
  fathersLastName: 'FATHERS LAST NAME',

  // CENTERLINK
  centrelinkCardType: 'CENTERLINK CARD TYPE',
  centrelinkCardExpiry: 'CENTERLINK CARD EXPIRY',
  centrelinkCustomerReferenceNo: 'CENTERLINK CUSTOMER REFERENCE NO.',

  // CITIZENSHIP
  countryOfBirth: 'COUNTRY OF BIRTH',
  citizenshipCertificateNo: 'CERTIFICATE NO.',

  // NATIONAL ID DATA
  idcardNoType: 'ID CARD TYPE',
  searchType: 'SEARCH TYPE',

  // ACCOUNT NUMBER
  pan: 'India PAN',
  epic: 'EPIC NO.',
  aadhaarNo: 'Aadhaar NO.', 

  // RESIDENT IDENTITY CARD (NIK)
  nik: 'NIK',

  // CARD TYPE
  cardType: 'Card Type',

  // NIGERIAN BANK NO
  bankVerificationNo: 'BANK VERIFICATION NO.',

  // MATERNAL NAMES
  maternalName: 'MATERNAL NAME',
  paternalName: 'PATERNAL NAME',

  // MOBILE VERIFICATION
  pinCode: 'Pin Code',
  receiver: 'Mobile NO.',
  sender: 'From',

  // SOCIAL SECURITY
  socialSecurityNo: 'Social Security No',

  //Consent
  consent: 'I agree to the terms and conditions',

  // REPORTING REFERENCE
  reportingReference: 'CLIENT (YOUR) REFERENCE',
  clientReference: 'CLIENT (YOUR) REFERENCE',

  // TAX REGISTRATION
  taxIDNo: 'Tax ID No.',
  cpf: 'CPF (Cadastro de Pessoas Físicas)',
  npwp: 'NPWP (Nomor Pokok Wajib Pajak - Tax ID Number)',

  // FIND IDU
  yearOfBirth: 'Birth Year',
  barangay: 'Barangay',
  city: 'City',
  province: 'Province',
  postcode: 'Postcode',
  emailID: 'Email',
  hitTarget: 'Target organization',
  orgId: 'Organization ID',
};
export default inputLabels;
