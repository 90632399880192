import {
  SAVE_API_CALL,
} from '../types';

import loggerUTL from '../../utils/loggerUTL';

// New form template
export const retainApiReqAXN = (formData, country) => (dispatch) => {
  
  sessionStorage.setItem(`${country}FormData_Template`, JSON.stringify(formData));
  
  dispatch({
    // type: SAVE_FORM_DATA,
    type: SAVE_API_CALL,
    payload: formData,
  });
}

// Old form template
export const saveApiReqAXN = (formData, country) => (dispatch) => {
  loggerUTL('RUNNING FORM DATA ACTION');
  /* ============================================
                  AUSTRALIA
   ============================================ */
  if (country === 'australia') {
    loggerUTL('SAVING AUSTRALIA API CALL...');

    sessionStorage.setItem('australiaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_API_CALL,
      payload: formData,
    });
  }

  /* ============================================
                  CHINA
   ============================================ */
  if (country === 'china') {
    loggerUTL('SAVING CHINA FORM DATA...');

    sessionStorage.setItem('chinaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_API_CALL,
      payload: formData,
    });
  }

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  if (country === 'newzealand') {
    loggerUTL('SAVING NEW ZEALAND FORM DATA...');

    sessionStorage.setItem('newzealandFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_API_CALL,
      payload: formData,
    });
  }


  /* ============================================
                  UNITED STATES
   ============================================ */
  if (country === 'unitedstates') {
    loggerUTL('SAVING UNITED STATES FORM DATA...');

    sessionStorage.setItem('unitedstatesFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_API_CALL,
      payload: formData,
    });
  }


  /* ============================================
                 GERMANY
   ============================================ */
  if (country === 'germany') {
    loggerUTL('SAVING GERMANY FORM DATA...');

    sessionStorage.setItem('germanyFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_API_CALL,
      payload: formData,
    });
  }

// /* ============================================
//                   RESET FORM DATA
//    ============================================ */
// export const resetFormDataAXN = () => (dispatch) => {
//   dispatch({
//     type: RESET_FORM_DATA,
//   });
// };
}