const dataSourcesUS = {
  residential: {
    title: '美国住宅',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    nationalIDNo: '国民身份证',
    firstName: '名字',
    lastName: '姓',
    addressElement1: '街道名称',
    addressElement3: '市郊',
    addressElement4: '州',
    addressElement5: '邮编',
    streetNo: '街道号码',
    email: '电子邮件地址',
    phoneNo: '电话号码',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    dateOfBirth: '出生日期',
    streetAddress: '街道名称',
    socialSecurityNo: '社会安全号码',

  },
  phone: {
    title: '美国电话',
    addressElement1: '街道名称',
    addressElement2: '街道号码',
    addressElement3: '市郊',
    addressElement4: '州',
    addressElement5: '邮编',
    phoneNo: '电话号码',
  }
};

export default dataSourcesUS;
