import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressNigeria = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    streetAddress,
    town,
    lga,
    state,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <TextFieldGroup
          key="streetAddress"
          id="streetAddress"
          type="text"
          name="streetAddress"
          dataName="streetAddressNG"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.street')}
          value={streetAddress}
          placeholder={transUTL('translatePlaceholder.inputNG.streetAddress')}
          placeholderTrigger={isEmptyVAL(streetAddress) ? 'input-empty' : ''}
          error={errors.streetAddress}
          errorTrigger={errors.streetAddress ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <TextFieldGroup
          key="town"
          id="town"
          type="text"
          name="town"
          dataName="townNG"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.town')}
          value={town}
          placeholder={transUTL('translatePlaceholder.inputNG.town')}
          placeholderTrigger={isEmptyVAL(town) ? 'input-empty' : ''}
          error={errors.town}
          errorTrigger={errors.town ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <TextFieldGroup
          key="lga"
          id="lga"
          type="text"
          name="lga"
          dataName="lgaNG"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.lga')}
          value={lga}
          placeholder={transUTL('translatePlaceholder.inputNG.lga')}
          placeholderTrigger={isEmptyVAL(lga) ? 'input-empty' : ''}
          error={errors.lga}
          errorTrigger={errors.lga ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <TextFieldGroup
          key="state"
          id="state"
          type="text"
          name="state"
          dataName="stateNG"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholder={transUTL('translatePlaceholder.inputNG.state')}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      <Fragment>
        {outputMasterUTL(selectedDataSources, inputElements)}
        <span />
      </Fragment>
    </div>
  );
};

CurrentResidentialAddressNigeria.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressNigeria;
