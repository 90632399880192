import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const AustraliaDriverLicence = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesAU.driverLicence.title'
        )}
      />

      <div className="search-verification__verify">

        {status === 3
          ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
          : <>
            <div className="search-verification__col">
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.searchSuccessful'
                  )}
                </p>
                <span className="material-icons success">check_circle</span>
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.safeHarbourScore'
                  )}
                </p>
                {!isEmptyVAL(safeHarbourScore) ? (
                  safeHarbourScore.toLowerCase() === 'add' ? (
                    <span className="material-icons success">add_circle</span>
                  ) : (
                    <span>{safeHarbourScore}</span>
                  )
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.identityVerified'
                  )}
                </p>
                {!isEmptyVAL(identityVerified) ? (
                  identityVerified ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
            </div>

            <div className="search-verification__col">
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.documentNo'
                  )}
                </p>
                {!isEmptyVAL(verifications.documentNo) ? (
                  verifications.documentNo ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.firstName'
                  )}
                </p>
                {!isEmptyVAL(verifications.firstName) ? (
                  verifications.firstName ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.lastName'
                  )}
                </p>
                {!isEmptyVAL(verifications.lastName) ? (
                  verifications.lastName ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
            </div>

            <div className="search-verification__col">
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.dateOfBirth'
                  )}
                </p>
                {!isEmptyVAL(verifications.dateOfBirth) ? (
                  verifications.dateOfBirth ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>

              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.nameMatchScore'
                  )}
                </p>
                {!isEmptyVAL(nameMatchScore) ? (
                  <span>{nameMatchScore}</span>
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
              <div className="search-verification__verify-item">
                <p>
                  {transUTL(
                    'translateSearchVerification.dataSourcesAU.driverLicence.addressMatchScore'
                  )}
                </p>
                {!isEmptyVAL(addressMatchScore) ? (
                  <span>{addressMatchScore}</span>
                ) : (
                  <span>{transUTL('translateReuse.na')}</span>
                )}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

AustraliaDriverLicence.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default AustraliaDriverLicence;
