import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveCostEstCountryAXN } from '../../actions/costEstimation/costEstimationAction';
import { setAlertAXN } from '../../actions/alert/alertActions';

// COMPONENTS
import DropdownArray from '../../components/dropdown/DropdownArray';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import Button from '../../components/btn/Button';

const CostEstCountrySelection = ({
  authRXS,
  costEstRXS,
  saveCostEstCountryAXN,
  setAlertAXN,
  history,
}) => {
  const [countrySTH, setCountrySTH] = useState(costEstRXS.selectedCountry);

  useEffect(() => {
    setCountrySTH(costEstRXS.selectedCountry);
    // eslint-disable-next-line
  }, []);

  const countries = [...Object.keys(costEstRXS.priceTier)];

  //  HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    if (isEmptyVAL(countrySTH))
      return setAlertAXN(
        transUTL('translateAlertMsg.pleaseSelectCountry'),
        'error'
      );

    saveCostEstCountryAXN(countrySTH);
    history.push(
      `/cost-estimation/country-selection/data-source-selection/:${authRXS.user.name.toLowerCase()}`
    );
  };
  const handleOnChange = (e) => setCountrySTH(e.target.value);
  const handelRadio = (opt) => setCountrySTH(opt);

  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2>Country Selection</h2>
        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <DropdownArray
          array={countries}
          target={countrySTH}
          name="country"
          // transType={}
          handleOnChange={handleOnChange}
          handelRadio={handelRadio}
        />
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/dashboard"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

CostEstCountrySelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  costEstRXS: PropTypes.object.isRequired,
  saveCostEstCountryAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  costEstRXS: state.costEstRXS,
});

export default connect(mapStateToProps, { saveCostEstCountryAXN, setAlertAXN })(
  CostEstCountrySelection
);
