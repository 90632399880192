import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TickBoxGroup from '../../../components/common/checkBoxGroup/TickBoxGroup';

// VALIDATIONS
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';

const ApiResult = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleToggleTickbox,
}) => {
  
  return (
    <div className="form-overview__terms">
      {selectedDataSources.includes('Australian Residential') && (
        <p className="form-overview__terms-label">
          {transUTL('translateFormOverview.digitalIDSafeguardMessage')}
        </p>
      )}
      
      {
        <TickBoxGroup
          key="displayApi"
          checkboxBool={formState.displayApiResultObtained}
          label={transUTL('translateFormOverview.apiResult')}
          handleToggleTickbox={() => handleToggleTickbox(false)}
        />
      }
      <span />
    </div>
  );
};

ApiResult.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleToggleTickbox: PropTypes.func.isRequired,
};

export default ApiResult;
