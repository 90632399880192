const dataSourcesSG = {
  creditBureau: {
    title: 'Singapore Credit Bureau',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    nationalIDNo: 'National ID',
    name: 'Name',
    address: 'Address',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    dateOfBirth: 'Date Of Birth',
  },
  residential: {
    title: 'Singapore Utility',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    nationalIDNo: 'National ID',
    firstName: 'First Name',
    lastName: 'Last Name',
    city: 'City',
    state: 'State',
    streetNo: 'Street Number',
    phoneNo: 'Phone Number',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    dateOfBirth: 'Date Of Birth',
    streetAddress: 'Street Name',
  },
  utility: {
    title: 'Singapore Utility',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    nationalIDNo: 'National ID',
    firstName: 'First Name',
    lastName: 'Last Name',
    city: 'City',
    state: 'State',
    streetNo: 'Street/Block No.',
    phoneNo: 'Phone Number',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    dateOfBirth: 'Date Of Birth',
    streetAddress: 'Street Name',
  },
};

export default dataSourcesSG;
