import { transUTL } from './transUTL';

const genderStringUTL = (data) => {
  if (data.gender === 'M') {
    return transUTL('translateDropdown.gender.male');
  } else if (data.gender === 'F') {
    return transUTL('translateDropdown.gender.female');
  }
};

export default genderStringUTL;
