import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import {
  saveIdentityDocumentsAXN,
  deleteSelectedIdentityDocumentsAXN,
  compressMultipleIdentityDocuments,
  vietnamIdentityDocumentScanAXN,
  bioTriggerBooleansAXN,
} from '../../actions/biometrics/biometricActions';
import { loadingToggleAXN } from '../../actions/loading/loadingAction';
import { setAlertAXN } from '../../actions/alert/alertActions';

// CAMERA
import Camera from './camera/Camera';

// BUTTON
import Button from '../../components/btn/Button';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';
import dataURItoBlobUTL from '../../utils/dataURItoBlobUTL';
import loggerUTL from '../../utils/loggerUTL';
import checkUploadedImage from "./helpers/checkUploadedImage";

// TODO CHANGE FROM STYLE MODULE TO NORMAL
const DocumentScanVietnam = ({
  authRXS,
  countryRXS,
  biometricsRXS,
  saveIdentityDocumentsAXN,
  deleteSelectedIdentityDocumentsAXN,
  loadingToggleAXN,
  compressMultipleIdentityDocuments,
  vietnamIdentityDocumentScanAXN,
  setAlertAXN,
  bioTriggerBooleansAXN,
  history,
}) => {
  const [isInit, setIsInit] = useState(true);
  const [cardImage, setCardImage] = useState('');
  const [cameraToggleST, setCameraToggleST] = useState(true);
  const [fileNamesST, setFileNamesST] = useState({
    fileNames: {
      file1: '',
    },
  });

  useEffect(() => {
    // add document
    loadingToggleAXN(true);
    if (isInit) {
      loadingToggleAXN(false);
    }
    // eslint-disable-next-line
  }, [isInit]);

  useEffect(() => {
    // add document
    if (!isEmptyVAL(cardImage)) {
      if (biometricsRXS.identityDocument.length === 1) {
        return setAlertAXN(transUTL('translateAlertMsg.max1'), 'error');
      }
      handleSaveIdentityDoc(cardImage);
    }
    // eslint-disable-next-line
  }, [cardImage]);

  const handleSaveIdentityDoc = (dataURI) => {
    saveIdentityDocumentsAXN([dataURI]);
  };

  // HANDLE ON CAPTURE IMAGE
  const handleOnCapture = (blob) => setCardImage(blob);

  // HANDLE ON CAPTURE IMAGE
  const handleInitialize = (bool) => setIsInit(bool);

  // CLEAR IMAGE
  const handleClearImage = () => setCardImage(undefined);

  // HANDLE ON SUBMIT
  const handleDeleteSelectedImage = (doc, index) =>
    deleteSelectedIdentityDocumentsAXN(index);

  // HANDLE CAMERA TOGGLE
  const handleCameraToggle = () => setCameraToggleST(!cameraToggleST);

  // RESETS ALL IMAGES IF MANUAL IMAGE SELECTION IS SELECTED
  const handleRemoveImages = () => saveIdentityDocumentsAXN([]);

  // RESETS ALL IMAGES IF MANUAL IMAGE SELECTION IS SELECTED
  const handleRemoveSelectedFiles = () =>
    setFileNamesST({
      fileNames: {
        file1: '',
        file2: '',
        file3: '',
      },
    });

    const fileToDataURI = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    }

  // HANDLE MANUAL FILE SELECTION
  const handleFileSelection = async (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      const fileSize = file.size;
      let fileType = '';

      const originalDataURI = await fileToDataURI(file)

      const { alertMsg, dataURI } = await checkUploadedImage(
        originalDataURI,
        fileSize
      )
      handleSaveIdentityDoc(dataURI);

      if (alertMsg.length > 0) {
        const alertMsgStr = alertMsg.join('\n')
        setAlertAXN(alertMsgStr, 'error')
      }

      if (targetName === 'file1') {
        fileType = 'file1';
      } else if (targetName === 'file2') {
        fileType = 'file2';
      } else if (targetName === 'file3') {
        fileType = 'file3';
      }

      setFileNamesST({
        ...fileNamesST,
        fileNames: {
          ...fileNamesST.fileNames,
          [fileType]: fileName,
        },
      });
    }
  };

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('SUBMITTING...');
    // TODO: ADD FILE VALIDATION

    if (isEmptyVAL(biometricsRXS.multiDocs))
      return setAlertAXN(
        transUTL('translateAlertMsg.uploadIdentityDocs'),
        'error'
      );

    let formData = new FormData();
    const requestJSON = {
      service: ['Vietnam National ID OCR'],
      countryCode: 'VNM',
      identityVariables: {},
      consentObtained: {
        'Vietnam National ID OCR': true,
      },
    };

    const json = JSON.stringify(requestJSON);

    const blobFile = dataURItoBlobUTL(
      biometricsRXS.multiDocs[0],
      'vietnam-national-id-card'
    );

    formData.append('json', json);
    formData.append('vnNationalIDImage', blobFile, blobFile.name);

    bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: true, isDocUploaded: false});
    vietnamIdentityDocumentScanAXN(formData, history);
  };

  return (
    <div className="common-form">
      <header className="header-primary">
        <h2>{transUTL('translateBiometrics.docScan')}</h2>
      </header>
      <div className="common-form__body ocr">
        {cameraToggleST && (
          <Fragment>
            <div className="ocr__form-btn">
              <p>{transUTL('translateBiometrics.identityCard')} 1</p>
              <div className="fileUpload ocr-btn">
                <span>
                  {!isEmptyVAL(fileNamesST.fileNames.file1)
                    ? `${fileNamesST.fileNames.file1} ${transUTL(
                      'translateBiometrics.uploaded'
                    )}`
                    : `${transUTL('translateBiometrics.noFiles')}`}
                </span>
                <input
                  type="file"
                  className="file-input"
                  name="file1"
                  onChange={handleFileSelection}
                />
              </div>
            </div>
          </Fragment>
        )}

        {cameraToggleST === false && isInit ? (
          <p
            onClick={() => {
              handleCameraToggle();
              handleRemoveImages([]); // RESET
            }}
            className="ocr__camera-toggle"
          >
            {transUTL('translateBiometrics.manualSelection')}
          </p>
        ) : null}

        {cameraToggleST ? (
          <p
            onClick={() => {
              handleRemoveSelectedFiles();
              handleCameraToggle();
              handleInitialize(false);
            }}
            className="ocr__camera-toggle"
          >
            {transUTL('translateBiometrics.orTakePhoto')}
          </p>
        ) : (
            <Camera
              onCapture={handleOnCapture}
              onClear={handleClearImage}
              handleInitialize={handleInitialize}
            />
          )}

        {/* TODO: USE SOMETHING ELE INSTATED OF DATA URL - TAKES TOO LONG TO LOAD */}
        {/* SETUP A PRELOADING GIF WHILE CAMERA INITIALIZES */}
        {/* ADD ON CLICK PREVIEW FUNCTION */}
        <div className="ocr__image-preview">
          {biometricsRXS.multiDocs.map((doc, index) => {
            if (!cameraToggleST && isInit) {
              return (
                <div key={index} className="ocr__image-preview-item">
                  {/* <img src={URL.createObjectURL(doc)} alt="Captured Image" /> */}
                  <img src={doc} alt="Document" />
                  <span
                    className="material-icons white"
                    onClick={() => handleDeleteSelectedImage(doc, index)}
                  >
                    cancel
                  </span>
                </div>
              );
            }

            return null;
          })}
        </div>
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/form-overview"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

DocumentScanVietnam.propTypes = {
  authRXS: PropTypes.object.isRequired,
  biometricsRXS: PropTypes.object.isRequired,
  saveIdentityDocumentsAXN: PropTypes.func.isRequired,
  deleteSelectedIdentityDocumentsAXN: PropTypes.func.isRequired,
  loadingToggleAXN: PropTypes.func.isRequired,
  compressMultipleIdentityDocuments: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveIdentityDocumentsAXN,
  deleteSelectedIdentityDocumentsAXN,
  loadingToggleAXN,
  compressMultipleIdentityDocuments,
  vietnamIdentityDocumentScanAXN,
  setAlertAXN,
  bioTriggerBooleansAXN,
})(DocumentScanVietnam);
