import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const IdentityDetailsHongKong = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { nationalIDNo, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['Hong Kong Credit Bureau'],
      element: (
        <TextFieldGroup
          key="nationalIDNo"
          id="nationalIDNo"
          type="text"
          name="nationalIDNo"
          dataName="nationalIDNoHK"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.nationalIDNo')}
          value={nationalIDNo}
          placeholder={transUTL('translatePlaceholder.inputHK.nationalIDNo')}
          placeholderTrigger={isEmptyVAL(nationalIDNo) ? 'input-empty' : ''}
          error={errors.nationalIDNo}
          errorTrigger={errors.nationalIDNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

IdentityDetailsHongKong.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default IdentityDetailsHongKong;
