const stateAU = {
  queensland: 'Queensland',
  newSouthWales: 'New South Wales',
  southAustralia: 'Australia Selatan',
  tasmania: 'Tasmania',
  victoria: 'Victoria',
  westernAustralia: 'Australia Barat',
  australianCapitalTerritory: 'Wilayah Ibu Kota Australia',
  northernTerritory: 'Wilayah Utara',
  pensionConcession: 'Konsesi Pencen',
  healthCare: 'Penjagaan Kesihatan',
  seniorHealth: 'Kesihatan Kanan',
};

export default stateAU;
