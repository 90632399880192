import React from 'react';
import PropTypes from 'prop-types';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

const UnsuccessfulSearchResultCards = ({ errorMessage }) => {

  const errMessage = isEmptyVAL(errorMessage)
    ? 'Error at Data Source'
    : errorMessage

  return (
    <>
      <div className="search-verification__col">
        <div className="search-verification__verify-item">
          <p>
            {transUTL(
              `translateSearchVerification.searchSuccessful`
            )}
          </p>
          <span className="material-icons error">cancel</span>
        </div>
      </div>
      
      <div className="search-verification__col">
        <div className="search-verification__verify-item">
          <p>
            {transUTL(
              `translateSearchVerification.errorMessage`
            )}
          </p>
          <span>{errMessage}</span>
        </div>
      </div>
    </>
  )
}

UnsuccessfulSearchResultCards.propTypes = {
  errorMessage: PropTypes.string.isRequired
};

export default UnsuccessfulSearchResultCards;
