import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { logoutUserAXN } from '../../../actions/auth/authActions';
import { saveSelectedLanguageAXN } from '../../../actions/language/languageActions';

// LOGO
import logoSVG from '../../../assets/logo/idu-logo.svg';

// SVG
import HamburgerSVG from '../../svg/HamburgerSVG';

// ASSETS
import userProfile from '../../../assets/icons/default-account-icon.png';
import zhFlagPNG from '../../../assets/flags/china.png';
import msFlagPNG from '../../../assets/flags/malaysia.png';
import enFlagPNG from '../../../assets/flags/britain.png';

// UTILITIES
import { langUTL, transUTL } from '../../../utils/transUTL';
import windowTopUTL from '../../../utils/windowTopUTL';

const Navigation = ({
  authRXS,
  languageRXS,
  logoutUserAXN,
  saveSelectedLanguageAXN,
  history,
}) => {
  const [navTriggers, setNavTriggers] = useState({
    isOpen: false,
    path: '/dashboard',
  });
  const { user } = authRXS;
  const windowPath = window.location.pathname;
  const iduVersion = 'v1.20200911';

  useEffect(() => {
    windowTopUTL();
    setNavTriggers({ ...navTriggers, path: windowPath });
    // eslint-disable-next-line
  }, []);

  // TODO - SET STATE FOR PAGE NAVIGATION

  // TOGGLE SIDE NAVIGATION
  const handleToggleSideNavigation = () =>
    setNavTriggers({ ...navTriggers, isOpen: !navTriggers.isOpen });

  const handlePathToggle = (opt) => {
    history.push(opt);
    setNavTriggers({ ...navTriggers, path: opt });
  };

  const handleForcedRefresh = () => window.location.reload();

  const flags = [
    {
      img: enFlagPNG,
      text: 'english', // DEFAULT
      alt: transUTL('translateAlt.britainFlag'),
      type: 'en',
    },
    {
      img: zhFlagPNG,
      text: '中文', // DEFAULT
      alt: transUTL('translateAlt.chinaFlag'),
      type: 'zh',
    },
    {
      img: msFlagPNG,
      text: 'malay', // DEFAULT
      alt: transUTL('translateAlt.malaysiaFlag'),
      type: 'ms',
    },
  ].find((item) => item.type === languageRXS);

  return (
    <Fragment>
      <nav className="top-navigation">
        <ul className="top-navigation__nav">
          <li className="top-navigation__nav-item top-navigation__hamburger">
            <HamburgerSVG
              handleToggleSideNavigation={handleToggleSideNavigation}
            />
          </li>
          <li className="top-navigation__nav-item">
            <p>
              <span className="top-navigation__word-plain">
                {transUTL('translateNavigation.individualIdentity')}
              </span>{' '}
              <span className="top-navigation__word-highlight">
                {transUTL('translateNavigation.verification')}
              </span>
              <span className="top-navigation__idu-version">{iduVersion}</span>
            </p>
          </li>
          <li className="top-navigation__nav-item top-navigation__language-selection top-navigation__dropdown">
            <div className="top-navigation__selection-head">
              <p>
                <img src={flags.img} alt={flags.alt} />
                <span>{flags.text}</span>
              </p>
              <span className="material-icons top-navigation__arrow">
                expand_more
              </span>
            </div>

            <ul className="top-navigation__selection-content">
              <div className="top-navigation__triangle"></div>
              <li
                onClick={() => {
                  langUTL('en');
                  saveSelectedLanguageAXN('en');
                  handleForcedRefresh();
                }}
              >
                <figure className="top-navigation__img">
                  <img src={enFlagPNG} alt="British flag" />
                </figure>
                <p>english</p>
              </li>
              <li
                onClick={() => {
                  langUTL('zh');
                  saveSelectedLanguageAXN('zh');
                  handleForcedRefresh();
                }}
              >
                <figure>
                  <img src={zhFlagPNG} alt="Chinese flag" />
                </figure>
                <p>中文</p>
              </li>
              <li
                onClick={() => {
                  langUTL('ms');
                  saveSelectedLanguageAXN('ms');
                  handleForcedRefresh();
                }}
              >
                <figure>
                  <img src={msFlagPNG} alt="Malaysian flag" />
                </figure>
                <p>malay</p>
              </li>
            </ul>
          </li>
          <li className="top-navigation__nav-item top-navigation__profile-selection top-navigation__dropdown">
            <div className="top-navigation__selection-head">
              <p>
                <img src={userProfile} alt="User" />
                <span>{user.name}</span>
              </p>
              <span className="material-icons top-navigation__arrow">
                expand_more
              </span>
            </div>

            <ul className="top-navigation__selection-content">
              <div className="top-navigation__triangle"></div>
              {/* <li>
                <span className="material-icons icon-size">person</span>
                <p>{transUTL('translateNavigation.profile')}</p>
              </li> */}
              <li onClick={() => history.push('/dashboard')}>
                <span className="material-icons icon-size">dashboard</span>
                <p>{transUTL('translateNavigation.dashboard')}</p>
              </li>
              <li onClick={() => history.push('/transaction-history')}>
                <span className="material-icons icon-size">history</span>
                <p>{transUTL('translateNavigation.history')}</p>
              </li>

              {user.isAdmin &&
                user.name.toLowerCase() !== 'temp_user_placeholder' && (
                  <li
                    onClick={() =>
                      history.push(
                        `/date-selection/:${user.name.toLowerCase()}`
                      )
                    }
                  >
                    <span className="material-icons icon-size">bar_chart</span>
                    <p>{transUTL('translateNavigation.dataAnalytics')}</p>
                  </li>
                )}

              <li onClick={() => history.push('/document-library')}>
                <span className="material-icons icon-size">folder</span>
                <p>{transUTL('translateNavigation.documentLibrary')}</p>
              </li>
              {user.isAdmin ? (
                <li onClick={() => history.push('/admin')}>
                  <span className="material-icons icon-size">admin_panel_settings</span>
                  <p>{transUTL('translateNavigation.admin')}</p>
                </li>
              ) : null}
              {/* <li onClick={() => history.push('/dashboard')}>
                <span className="material-icons icon-size">help</span>
                <p>{transUTL('translateNavigation.aboutUs')}</p>
              </li> */}
              <li onClick={logoutUserAXN}>
                <span className="material-icons icon-size">logout</span>
                <p>{transUTL('translateNavigation.logout')}</p>
              </li>
            </ul>
          </li>

          <li className="top-navigation__nav-item top-navigation__logo">
            <figure onClick={() => handlePathToggle('/dashboard')}>
              <img src={logoSVG} alt="Logo" />
            </figure>
          </li>
        </ul>
      </nav>

      <nav
        className={
          navTriggers.isOpen
            ? 'side-navigation side-navigation__open'
            : 'side-navigation side-navigation__close'
        }
      >
        <div className="side-navigation__profile">
          <figure className="side-navigation__profile-img">
            <img src={userProfile} alt="User" />
          </figure>
          <div>
            <h3 className="side-navigation__username">{user.username}</h3>
            <p className="side-navigation__msg">member</p>
          </div>
        </div>

        <div className="side-navigation__body">
          <ul className="side-navigation__nav">
            <li
              className="side-navigation__nav-item side-navigation__logo"
              title={transUTL('translateTitle.logo')}
            >
              <img
                src={logoSVG}
                alt="Logo"
                onClick={() => handlePathToggle('/dashboard')}
              />
            </li>
            <li
              className={
                windowPath === '/profile'
                  ? 'side-navigation__nav-item side-navigation__disabled side-navigation__nav-active'
                  : 'side-navigation__nav-item side-navigation__disabled'
              }
              title={transUTL('translateTitle.profile')}
            >
              <span className="material-icons icon-size side-navigation__nav-icon">
                person
              </span>
              <span className="side-navigation__nav-text">
                {transUTL('translateNavigation.profile')}
              </span>
            </li>

            <li
              className={
                windowPath === '/dashboard'
                  ? 'side-navigation__nav-item side-navigation__nav-active'
                  : 'side-navigation__nav-item'
              }
              onClick={() => handlePathToggle('/dashboard')}
              title={transUTL('translateTitle.dashboard')}
            >
              <span className="material-icons icon-size side-navigation__nav-icon">
                dashboard
              </span>
              <span className="side-navigation__nav-text">
                {transUTL('translateNavigation.dashboard')}
              </span>
            </li>
            <li
              className={
                windowPath === '/transaction-history'
                  ? 'side-navigation__nav-item side-navigation__nav-active'
                  : 'side-navigation__nav-item'
              }
              onClick={() => handlePathToggle('/transaction-history')}
              title={transUTL('translateTitle.history')}
            >
              <span className="material-icons icon-size side-navigation__nav-icon">
                history
              </span>
              <span className="side-navigation__nav-text">
                {transUTL('translateNavigation.history')}
              </span>
            </li>

            {user.isAdmin &&
              user.name.toLowerCase() !== 'temp_user_placeholder' && (
                <li
                  className={
                    windowPath.includes('/date-selection')
                      ? 'side-navigation__nav-item side-navigation__nav-active'
                      : 'side-navigation__nav-item'
                  }
                  onClick={() =>
                    handlePathToggle(
                      `/date-selection/:${user.name.toLowerCase()}`
                    )
                  }
                  title={transUTL('translateTitle.dataAnalytics')}
                >
                  <span className="material-icons icon-size side-navigation__nav-icon">
                    bar_chart
                  </span>
                  <span className="side-navigation__nav-text">
                    {transUTL('translateNavigation.dataAnalytics')}
                  </span>
                </li>
              )}

            {user.isAdmin &&
              user.name.toLowerCase() !== 'temp_user_placeholder' && (
                <li
                  className={
                    windowPath.includes('/cost-estimation')
                      ? 'side-navigation__nav-item side-navigation__nav-active'
                      : 'side-navigation__nav-item'
                  }
                  onClick={() =>
                    handlePathToggle(
                      `/cost-estimation/country-selection/:${user.name.toLowerCase()}`
                    )
                  }
                  title={transUTL('translateTitle.costEstimation')}
                >
                  <span className="material-icons icon-size side-navigation__nav-icon">
                    monetization_on
                  </span>
                  <span className="side-navigation__nav-text">
                    {transUTL('translateNavigation.costEstimation')}
                  </span>
                </li>
              )}

            <li
              className={
                windowPath === '/document-library'
                  ? 'side-navigation__nav-item side-navigation__nav-active'
                  : 'side-navigation__nav-item'
              }
              onClick={() => handlePathToggle('/document-library')}
              title={transUTL('translateTitle.documentLibrary')}
            >
              <span className="material-icons icon-size side-navigation__nav-icon">
                folder
              </span>
              <span className="side-navigation__nav-text">
                {transUTL('translateNavigation.documentLibrary')}
              </span>
            </li>
            {
              user.isAdmin ? (
                <li
                  className={
                    windowPath.includes('/admin')
                      ? 'side-navigation__nav-item side-navigation__nav-active'
                      : 'side-navigation__nav-item'
                  }
                  onClick={() => history.push('/admin')}
                  title={transUTL('translateTitle.admin')}
                >
                  <span className="material-icons icon-size side-navigation__nav-icon">admin_panel_settings</span>
                  <span className="side-navigation__nav-text">
                    {transUTL('translateNavigation.admin')}
                  </span>
                </li>
              ) : null
            }
            <li
              className={
                windowPath === '/about-us'
                  ? 'side-navigation__nav-item side-navigation__disabled side-navigation__nav-active'
                  : 'side-navigation__nav-item side-navigation__disabled'
              }
              // onClick={() => handlePathToggle('/about-us')}
              title={transUTL('translateTitle.aboutUs')}
            >
              <span className="material-icons icon-size side-navigation__nav-icon">
                help
              </span>
              <span className="side-navigation__nav-text">
                {transUTL('translateNavigation.aboutUs')}
              </span>
            </li>
            <li
              className="side-navigation__nav-item"
              onClick={logoutUserAXN}
              title={transUTL('translateTitle.logout')}
            >
              <span className="material-icons icon-size side-navigation__nav-icon">
                exit_to_app
              </span>
              <span className="side-navigation__nav-text">
                {transUTL('translateNavigation.logout')}
              </span>
            </li>
          </ul>
        </div>
      </nav>

      <div
        className={
          navTriggers.isOpen
            ? 'navigation-mask navigation-mask__active'
            : 'navigation-mask navigation-mask__deactivate'
        }
        onClick={handleToggleSideNavigation}
      ></div>
    </Fragment>
  );
};

Navigation.propTypes = {
  authRXS: PropTypes.object.isRequired,
  languageRXS: PropTypes.string.isRequired,
  logoutUserAXN: PropTypes.func.isRequired,
  saveSelectedLanguageAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  languageRXS: state.languageRXS,
});

export default connect(mapStateToProps, {
  logoutUserAXN,
  saveSelectedLanguageAXN,
})(Navigation);
