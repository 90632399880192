const inputHK = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // IDENTITY VARIABLES
  nationalIDNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  building: '',
  streetName: '',
  district: '',
  state: '',
  postCode: '',

  // REFERENCE
  reportingReference: '',
};

export default inputHK;
