// TYPES
import {
  SET_ORGANISATIONS,
  SET_ORGANISATION_USERS,
} from '../../actions/types';

// AXIOS INSTANCE
import { dzAPI } from "../../api/init";

// REDUX ACTIONS
import { loadingToggleAXN } from "../loading/loadingAction";
import { setAlertAXN } from "../alert/alertActions";

// UTILS
import loggerUTL from "../../utils/loggerUTL";

export const setOrganisationsAXN = (organisations) => (dispatch) => {
  dispatch({
    type: SET_ORGANISATIONS,
    payload: organisations
  });
}

export const setOrganisationUsersAXN = (users) => (dispatch) => {
  dispatch({
    type: SET_ORGANISATION_USERS,
    payload: users
  });
}

export const retrieveOrganisationUsersAXN = (orgId) => (dispatch) => {
  dispatch(loadingToggleAXN(true));

  dzAPI.get('api/v2/admin/users/' + orgId).then((res) => {
    dispatch(setOrganisationUsersAXN(res.data));
  }).catch((err) => {
    dispatch(setAlertAXN("Unable to retrieve users.", "error"));
    loggerUTL('ERROR... ', err);
  });

  dispatch(loadingToggleAXN(false));
}

export const retrieveOrganisationsAXN = () => (dispatch) => {
  dispatch(loadingToggleAXN(true));

  dzAPI.get('api/v2/admin/organisations').then((res) => {
    dispatch(setOrganisationsAXN(res.data));
  }).catch((err) => {
    dispatch(setAlertAXN("Unable to retrieve organisations.", "error"));
    loggerUTL('ERROR... ', err);
  });

  dispatch(loadingToggleAXN(false));
}

export const toggleLockAccountAXN = (userId, orgId, lockStatus) => (dispatch) => {
  dispatch(loadingToggleAXN(true));

  const reqBody = {
    isLocked: !lockStatus
  };

  const successMessage = lockStatus ? "Account has been unlocked." : "Account has been locked.";
  const failureMessage = lockStatus ? "Unable to unlock account." : "Unable to lock account.";

  dzAPI.patch('api/v2/admin/user/' + userId, reqBody).then((res) => {
    dispatch(setAlertAXN(successMessage, "success"));
    dispatch(retrieveOrganisationUsersAXN(orgId));
  }).catch((err) => {
    dispatch(setAlertAXN(failureMessage, "error"));
    loggerUTL('ERROR... ', err);
  });

  dispatch(loadingToggleAXN(false));
}

export const resetPasswordAXN = (username, newPassword) => (dispatch) => {
  dispatch(loadingToggleAXN(true));

  const reqBody = {
    username: username,
    password: newPassword
  }

  dzAPI.patch('api/v2/auth/signup', reqBody).then((res) => {
    dispatch(setAlertAXN("Password has been reset.", "success"));
    loggerUTL("RES: ", res);
  }).catch((err) => {
    if (err.response) {
      if (err.response.status === 503) {
        dispatch(setAlertAXN(err.response.data.requestError, "error"));
      } else {
        dispatch(setAlertAXN("Unable to reset password.", "error"));
      }
    } else {
      dispatch(setAlertAXN("Unable to reset password.", "error"));
    }
    loggerUTL('ERROR... ', err);
  });

  dispatch(loadingToggleAXN(false));
}

export const createUserAXN = (userData) => (dispatch) => {
  dispatch(loadingToggleAXN(true));

  dzAPI.post('api/v2/auth/signup', userData).then((res) => {
    dispatch(setAlertAXN(res.data.message, "success"));
  }).catch((err) => {
    if (err.response.status === 409) {
      let message = err.response.data.requestError;

      /*
        This is a temporary fix for duplicate email. When the back end fixes
        this problem, it should be removed.
      */
      if (message.includes("user_credentials_email_key")) {
        message = "User email already exists.";
      };

      dispatch(setAlertAXN(message, "error"));
    } else {
      dispatch(setAlertAXN("Unable to create user.", "error"));
    }
    loggerUTL('ERROR... ', err);
  });

  dispatch(loadingToggleAXN(false));
} 
