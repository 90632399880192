import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectedDataSourceTemplateUTL from "../../utils/selectedDataSourceTemplateUTL";
import isEmptyVAL from "../../validations/checks/isEmptyVAL";
import { transUTL } from "../../utils/transUTL";
import formDataVAL from "../../validations/inputFields/formDataVAL";
import filterServicesUTL from "../../utils/filterServicesUTL";
import FORM_SECTIONS from "../../constants/FormSections";
import CACHE from "../../constants/cache";
import loggerUTL from "../../utils/loggerUTL";
import CurrentAddressAutoComplete from "../../components/common/FormSection/CurrentAddressAutoComplete";
import TermsAndConditions from "../../components/common/FormSection/TermsAndConditions";
import DisplayApiResults from "../../components/common/FormSection/DisplayApiResults";
import InputFields from "../../components/common/FormSection/InputFields";
import { setAlertAXN } from "../../actions/alert/alertActions";
import { retainFormDataAXN } from "../../actions/formData/formDataActions";
import { retainApiReqAXN } from "../../actions/formData/apiReqActions";
import { retainUserInputAXN } from "../../actions/formData/userInputActions"; //Redux for saving checkbox result
import { searchVerificationAXN } from "../../actions/searchVerification/searchVerificationAction";
import { loadingToggleAXN } from "../../actions/loading/loadingAction";
import COUNTRIES_FOR_TEMPLATE from "../../constants/countriesForTemplate";
import FIELDS_FOR_TEMPLATE from "../../constants/fieldsForTemplate";
import VALIDATION_FOR_TEMPLATE from "../../constants/validationForTemplate";
import { resetBiometricsAXN } from "../../actions/biometrics/biometricActions";
import {
  CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE,
  CROSS_BORDER_VERIFICATION,
} from "../../constants/crossBorderDataSources";
import isScannedDataEditedUTL from "../../utils/isScannedDataEditedUTL";

const FormOverviewTemplate = ({
  authRXS: { user },
  errorsRXS,
  countryRXS,
  formDataRXS,
  biometricsRXS,
  searchVerificationAXN,
  retainFormDataAXN,
  retainUserInputAXN,
  retainApiReqAXN,
  loadingToggleAXN,
  setAlertAXN,
  history,
  resetBiometricsAXN,
}) => {
  const [formSectionFields, setFormSectionFields] = useState(null);
  const [formData, setFormData] = useState({ ...formDataRXS[countryRXS] });
  const [inputErrors, setInputErrors] = useState({});
  const [isConsentRequired, setIsConsentRequired] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isDisplayApiRequired, setIsDisplayApiRequired] = useState(false);
  const [isDisplayApiChecked, setIsDisplayApiChecked] = useState(false); //Dispay API REQ and RES in the result page
  const [apiReqDetails, setApiReqDetails] = useState(null);
  const [dataSourcesWithConsent, setDataSourcesWithConsent] = useState([]);
  const [dataSourcesWithApi, setDataSourcesWithApi] = useState([]);

  const {
    PERSONAL_INFORMATION,
    PERSONAL_INFORMATION_WATCHLIST_AML,
    CURRENT_RESIDENTIAL_ADDRESS,
    CURRENT_RESIDENTIAL_ADDRESS_AUTO_COMPLETE,
    TERMS_AND_CONDITIONS,
    CLIENT_REFERENCE,
  } = FORM_SECTIONS;

  const {
    GLOBAL_SECTION_DETAILS_CACHE,
    GLOBAL_DATA_SOURCE_SECTIONS_CACHE,
    GLOBAL_SECTION_ORDERS_CACHE,
  } = CACHE;

  const isCrossBorder = countryRXS === CROSS_BORDER_VERIFICATION;
  const COUNTRY_SELECTION_PAGE = isCrossBorder
    ? CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE
    : "/country-selection";
  const DATA_SOURCE_SELECTION_PAGE = isCrossBorder
    ? CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE
    : "/data-source-selection";

  /*
    Initialise all fields of formData with blank strings here so there is
    no need to initialise the formData state in redux reducer.
  */
  const getFormDataInitialState = (sections) => {
    const initialState = {};

    Object.values(sections).forEach((section) => {
      const { key, inputFields } = section;

      if (formDataRXS[countryRXS] && formDataRXS[countryRXS][key]) {
        initialState[key] = { ...formDataRXS[countryRXS][key] };
      } else {
        initialState[key] = {};
        inputFields.forEach((field) => {
          initialState[key][field.name] = "";
        });
      }
    });

    return initialState;
  };

  const handleOnChange = (event, sectionKey) => {
    const updatedFormData = { ...formData };
    updatedFormData[sectionKey][event.target.name] = event.target.value;

    setFormData(updatedFormData);
  };

  const handleOnBlur = () => {
    let extractFormData = {};
    Object.values(formData).forEach((field) => {
      extractFormData = { ...extractFormData, ...field };
    });
    // Check if scanned data from a doc is edited
    const { ocrResponseData } = biometricsRXS;
    isScannedDataEditedUTL(countryRXS, ocrResponseData, extractFormData);
    retainFormDataAXN({ ...formData }, countryRXS);
    retainApiReqAXN({ ...formData }, countryRXS);

    if (!isEmptyVAL(inputErrors)) {
      // Check if the mandatory field is empty or not
      // Clear validation error message for this field if it is filled
      const updatedInputErrors = formDataVAL(formData, formSectionFields);
      setInputErrors(updatedInputErrors);
    }
  };

  const handleToggleCheckbox = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  const handleToggleTickbox = () => {
    setIsDisplayApiChecked(!isDisplayApiChecked);
  };

  const handleReset = () => {
    loggerUTL("CLEARING STATE...");

    const initialState = {};
    Object.values(formSectionFields).forEach((section) => {
      const { key, inputFields } = section;

      initialState[key] = {};
      inputFields.forEach((field) => {
        initialState[key][field.name] = "";
      });
    });

    setIsDisplayApiChecked(false); //Reset checkbox for displaying API results
    setFormData(initialState);
    retainFormDataAXN({ ...initialState }, countryRXS);

    // OCR RESET
    resetBiometricsAXN(); // RESET
  };

  const prepareAndSendVerificationRequest = () => {
    const service = [...selectedDataSources];

    const country = filterServicesUTL(countryRXS, user.countries);

    const countryCode = isCrossBorder ? "All" : country.code.alpha2;

    let submitData = {
      countryCode: countryCode,
      service,
      clientReference: formData[CLIENT_REFERENCE]["clientReference"],
    };

    /*
      If there is terms and conditions, consent should be added to the request.  
    */
    if (isConsentRequired && isConsentChecked) {
      const consentObtained = {};

      for (const dataSourceName of dataSourcesWithConsent) {
        consentObtained[dataSourceName] = true;
      }

      submitData = {
        ...submitData,
        consentObtained,
      };
    }

    if (isDisplayApiRequired && isDisplayApiChecked) {
      const apiObtained = {};

      for (const dataSourceName of dataSourcesWithApi) {
        apiObtained[dataSourceName] = true;
      }

      submitData = {
        ...submitData,
        apiObtained,
      };
    }
    submitData["identityVariables"] = {};

    for (const [sectionKey, matchDetails] of Object.entries(apiReqDetails)) {
      const { identityVariables, apiReqFieldsMapping } = matchDetails;

      const sectionSubmitData = {};
      /*
        This for loop maps the api request field to the input field name according to
        the "apiReqFieldsMapping" property in json file.
      */
      for (const [fieldKey, fieldValue] of Object.entries(
        apiReqFieldsMapping
      )) {
        if (fieldValue instanceof Array) {
          let combinedValue = "";

          fieldValue.forEach((item) => {
            combinedValue += ` ${formData[sectionKey][item]}`;
          });
          combinedValue = combinedValue.trim();

          sectionSubmitData[fieldKey] = combinedValue;
        } else {
          // Below case is only for UK services for now
          if (service.includes("United Kingdom Citizens")) {
            /*
              UK citizens and phone have different addressElement1's name in request data obj
              'addressLine1' is for UK phone
              'street' is for UK citizens
              Since only keep 'street' when both services are selected to reduce confusion on web app
              'street' value also need to mapped to 'addressElement1' for 'addressLine1'
              in order to make 'United Kingdom Phone' get verified
            */
            if (fieldValue === "addressLine1") {
              // sectionSubmitData.addressElement1 = formData.currentResidentialAddress.street
              sectionSubmitData[fieldKey] = formData[sectionKey]["street"];
            } else if (fieldValue === "dateOfBirth") {
              // UK citizens accepts 'dd/mm/yyyy' format for dateOfBirth
              const dobArr = formData[sectionKey][fieldValue].split("-");
              const dob_ddmmyyyy =
                dobArr[2] + "/" + dobArr[1] + "/" + dobArr[0];
              sectionSubmitData[fieldKey] = dob_ddmmyyyy;
            } else {
              sectionSubmitData[fieldKey] = formData[sectionKey][fieldValue];
            }
          }

          if (service.includes("Czech Republic Residential")) {
            /*
              Czech Republic Residential addresselement 1 = addresselement 2 + addresselement 1 
            */
            if (fieldValue === "houseNumber") {
              sectionSubmitData.addressElement1 =
                formData[sectionKey]["houseNumber"] +
                " " +
                formData[sectionKey]["streetName"];
              // delete sectionSubmitData.addressElement2;
            }
            delete sectionSubmitData.addressElement2;
          }

          if (service.includes("Canada Phone")) {
            // if (service.includes('United Kingdom Phone')) {

            if (fieldValue === "addressLine2") {
              sectionSubmitData["addressElement1"] =
                formData[sectionKey]["addressLine1"] +
                " " +
                formData[sectionKey][fieldValue];
            } else if (fieldValue === "dateOfBirth") {
              const dobArr = formData[sectionKey][fieldValue].split("-");
              const dob_ddmmyyyy =
                dobArr[2] + "/" + dobArr[1] + "/" + dobArr[0];
              sectionSubmitData[fieldKey] = dob_ddmmyyyy;
            } else {
              sectionSubmitData[fieldKey] = formData[sectionKey][fieldValue];
            }
          } else {
            sectionSubmitData[fieldKey] = formData[sectionKey][fieldValue];
          }
        }
      }

      if (identityVariables) {
        submitData["identityVariables"] = {
          ...submitData["identityVariables"],
          ...sectionSubmitData,
        };
        if (submitData["identityVariables"].medicareReferenceNo) {
          submitData["identityVariables"].medicareReferenceNo = parseInt(
            submitData["identityVariables"].medicareReferenceNo
          );
        }
        if (!isEmptyVAL(submitData["identityVariables"].medicareExpiry)) {
          if (submitData["identityVariables"].medicareExpiry.length <= 7) {
            submitData["identityVariables"].medicareExpiry =
              submitData["identityVariables"].medicareExpiry + "-01";
          }
        }
      } else {
        submitData = { ...submitData, ...sectionSubmitData };
      }
    }
    loggerUTL(JSON.stringify(submitData, undefined, 4));
    searchVerificationAXN(submitData, history, countryRXS);
    retainApiReqAXN(submitData, countryRXS);
  };

  const handleOnSubmit = () => {
    const isValidationApplied = VALIDATION_FOR_TEMPLATE.includes(countryRXS);
    const inputErrors = isValidationApplied
      ? ""
      : formDataVAL(formData, formSectionFields);

    setInputErrors(inputErrors);
    retainUserInputAXN(isDisplayApiChecked, countryRXS);

    if (!isEmptyVAL(inputErrors)) {
      loggerUTL("ERRORS...", inputErrors);
      setAlertAXN(transUTL("translateAlertMsg.inputErrors"), "error");
    } else if (isConsentRequired && !isConsentChecked) {
      setAlertAXN(transUTL("translateAlertMsg.consent"), "error");
    } else {
      prepareAndSendVerificationRequest();
    }
  };

  const renderSectionContent = (section) => {
    const { inputFields, key } = section;

    switch (key) {
      case CURRENT_RESIDENTIAL_ADDRESS_AUTO_COMPLETE:
        return (
          <CurrentAddressAutoComplete
            sectionKey={key}
            fields={inputFields}
            errors={inputErrors[key] ? inputErrors[key] : {}}
            data={formData[key]}
            handleOnBlur={handleOnBlur}
            handleOnChange={handleOnChange}
          />
        );
      default:
        return (
          <InputFields
            sectionKey={key}
            fields={inputFields}
            errors={inputErrors[key] ? inputErrors[key] : {}}
            data={formData[key]}
            handleOnBlur={handleOnBlur}
            handleOnChange={handleOnChange}
            country={countryRXS}
          />
        );
    }
  };

  const selectedDataSources = selectedDataSourceTemplateUTL();

  /*
    This function returns the selected data source sections, like 'PersonalInformation', 'CurrentResidentialAddress'.
  */
  const getSelectedDataSourceSections = async () => {
    const selectedDataSourceSections = {};
    /*
      To get data source sections faster, we first read cached data from session storage to see if the data sources we
      need were already fetched before. If they were, just fetch data from the cache. Otherwise, read data from json files.
    */
    let globalDataSourceSectionsCache = JSON.parse(
      sessionStorage.getItem(GLOBAL_DATA_SOURCE_SECTIONS_CACHE)
    );

    if (!globalDataSourceSectionsCache) {
      globalDataSourceSectionsCache = {};
    }

    if (!globalDataSourceSectionsCache[countryRXS]) {
      globalDataSourceSectionsCache[countryRXS] = {};
    }

    const countryRegex = new RegExp(`${countryRXS}`, "gi");
    let dataSourcesWithConsent = [];
    let dataSourcesWithApi = [];
    const isTemplateApplied = COUNTRIES_FOR_TEMPLATE.includes(countryRXS);
    const watchlistAML = ["Watchlist AML"];

    for (let i = 0; i < selectedDataSources.length; i++) {
      /* 
        For some countries, the data source name has space between the country name but the countryRXS
        always has no space, so we need to remove the space first to make the countryRegex work.
      */
      let dataSourceName = selectedDataSources[i] //change from const to let
        .replace(/\s/g, "")
        .replace(countryRegex, "");

      /* 
        For providing all filds when a user selects more than 2 services
      */
      if (selectedDataSources.length > 1 && isTemplateApplied == true) {
        for (let i = 0; i < selectedDataSources.length; i++) {
          if (FIELDS_FOR_TEMPLATE.includes(selectedDataSources[i])) {
            dataSourceName = "Super";
            if (
              watchlistAML.includes(selectedDataSources[i + 1]) &&
              selectedDataSources[i].includes("Peru National ID")
            ) {
              dataSourceName = "WatchlistSuper";
            }
          }
          if (
            watchlistAML.includes(selectedDataSources[i + 1]) &&
            selectedDataSources[i].includes("Canada Phone")
          ) {
            dataSourceName = "WatchlistSuper";
          }
        }
      }

      if (globalDataSourceSectionsCache[countryRXS][dataSourceName]) {
        selectedDataSourceSections[dataSourceName] = [
          ...globalDataSourceSectionsCache[countryRXS][dataSourceName].sections,
        ];

        const isConsentRequired =
          globalDataSourceSectionsCache[countryRXS][dataSourceName]
            .isConsentRequired;

        if (isConsentRequired) {
          dataSourcesWithConsent.push(selectedDataSources[i]);
          setIsConsentRequired(isConsentRequired);
        }
        if (isDisplayApiRequired) {
          dataSourcesWithApi.push(selectedDataSources[i]);
          setIsDisplayApiRequired(isDisplayApiRequired);
        }
      } else {
        let path;

        if (dataSourceName !== "WatchlistAML") {
          path = `${countryRXS}/dataSources/${dataSourceName}.json`;
        } else {
          path = "watchlistAML/dataSources/WatchlistAML.json";
        }
        const dataSourceModule = await import(`../../forms_Json/${path}`);

        selectedDataSourceSections[dataSourceName] = [
          ...dataSourceModule.default.sections,
        ];

        globalDataSourceSectionsCache[countryRXS][dataSourceName] = {
          ...dataSourceModule.default,
        };

        const isConsentRequired =
          globalDataSourceSectionsCache[countryRXS][dataSourceName]
            .isConsentRequired;

        if (isConsentRequired) {
          dataSourcesWithConsent.push(selectedDataSources[i]);
          setIsConsentRequired(isConsentRequired);
        }
        if (isDisplayApiRequired) {
          dataSourcesWithApi.push(selectedDataSources[i]);
          setIsDisplayApiRequired(isDisplayApiRequired);
        }
      }
    }

    setDataSourcesWithConsent(dataSourcesWithConsent);
    setDataSourcesWithApi(dataSourcesWithApi);

    sessionStorage.setItem(
      GLOBAL_DATA_SOURCE_SECTIONS_CACHE,
      JSON.stringify(globalDataSourceSectionsCache)
    );
    return selectedDataSourceSections;
  };

  const getOrderedDataSourceSections = async (selectedDataSourceSections) => {
    const selectedSections = new Map();

    for (let sections of Object.values(selectedDataSourceSections)) {
      for (let section of sections) {
        const { key } = section;
        if (!selectedSections.has(key)) {
          selectedSections.set(key, section);
        }
      }
    }

    /*
      When WatchlistAML and data sources of a specific country are selected,
      the country's personal information section is used. If only WatchlistAML 
      is selected, then WatchlistAML's personal information section is used.
    */
    if (
      selectedSections.has(PERSONAL_INFORMATION) &&
      selectedSections.has(PERSONAL_INFORMATION_WATCHLIST_AML)
    ) {
      selectedSections.delete(PERSONAL_INFORMATION_WATCHLIST_AML);
    }

    let globalSectionOrdersCache = JSON.parse(
      sessionStorage.getItem(GLOBAL_SECTION_ORDERS_CACHE)
    );

    if (!globalSectionOrdersCache) {
      globalSectionOrdersCache = {};
    }

    if (!globalSectionOrdersCache[countryRXS]) {
      const ordersModule = await import(
        `../../forms_Json/${countryRXS}/sectionOrders.json`
      );

      globalSectionOrdersCache[countryRXS] = { ...ordersModule.default };
      sessionStorage.setItem(
        GLOBAL_SECTION_ORDERS_CACHE,
        JSON.stringify(globalSectionOrdersCache)
      );
    }

    const { orders } = globalSectionOrdersCache[countryRXS];
    const orderedSections = [];

    for (let [key, selectedSection] of selectedSections) {
      const index = orders.indexOf(key);
      orderedSections.push({
        index,
        selectedSection,
      });
    }

    orderedSections.sort((item1, item2) => item1.index - item2.index);

    return orderedSections;
  };

  /*
    This function returns an sectionDetails object.
    The sectionDetails is an object that has all the form sections needed by selected data sources.
  */
  const getSectionDetails = async (orderedDataSourceSections) => {
    const sectionDetails = {};

    /*
      To get form details data faster, we first read cached data from session storage to see if the sections we
      need were already fetched before. If they were, just fetch data from the cache. Otherwise, read data from
      json files.
    */
    let globalSectionDetailsCache = JSON.parse(
      sessionStorage.getItem(GLOBAL_SECTION_DETAILS_CACHE)
    );

    if (!globalSectionDetailsCache) {
      globalSectionDetailsCache = {};
    }

    if (!globalSectionDetailsCache[countryRXS]) {
      globalSectionDetailsCache[countryRXS] = {};
    }

    for (let item of orderedDataSourceSections) {
      const {
        selectedSection: { key, path },
      } = item;

      if (globalSectionDetailsCache[countryRXS][key]) {
        sectionDetails[key] = {
          ...globalSectionDetailsCache[countryRXS][key],
        };
      } else {
        const sectionModule = await import(`../../forms_Json/${path}`);
        sectionDetails[key] = { ...sectionModule.default };
        globalSectionDetailsCache[countryRXS][key] = {
          ...sectionModule.default,
        };
      }
    }

    sessionStorage.setItem(
      GLOBAL_SECTION_DETAILS_CACHE,
      JSON.stringify(globalSectionDetailsCache)
    );

    /*
      When WatchlistAML and data sources of a specific country are selected,
      the country's personal information section is used. If only WatchlistAML 
      is selected, then WatchlistAML's personal information section is used.
    */
    const watchlistAML = sectionDetails[PERSONAL_INFORMATION_WATCHLIST_AML];
    if (watchlistAML) {
      sectionDetails[PERSONAL_INFORMATION] = {
        ...watchlistAML,
        key: PERSONAL_INFORMATION,
      };
      delete sectionDetails[PERSONAL_INFORMATION_WATCHLIST_AML];
    }

    return sectionDetails;
  };

  const combineInputFields = (sectionInputFields) => {
    let combinedInputFields = [];

    sectionInputFields.forEach((field) => {
      let existingField = combinedInputFields.find(
        (fieldObj) => fieldObj.name === field.name
      );

      if (existingField) {
        // isMandatory : if there is a duplicate field's isMandatory is true, then set this field as true in combinedInputFields
        if (field.isMandatory) {
          existingField.isMandatory = true;
        }
        // field type : if there are two types ('selectlist' and 'text') for state field, then set is as 'text' in combinedInputFields
        if (field.name === "state" && field.type === "text") {
          existingField.type = field.type;
        }
      } else {
        combinedInputFields = [...combinedInputFields, field];
      }
    });

    /* 
      This is only for UK services for now
      UK phone has 'ADDRESS LINE 1'(mandatory) & 'ADDRESS LINE 2'(optional)
      but UK citizens has 'STREET'
      'ADDRESS LINE 1' is same as 'STREET'
      Thus, only keep 'STREET' when both services are selected.
    */
    if (
      selectedDataSources.includes("United Kingdom Citizens") &&
      selectedDataSources.includes("United Kingdom Phone")
    ) {
      combinedInputFields = combinedInputFields
        .filter((field) => field.name !== "addressLine1")
        .filter((field) => field.name !== "addressLine2");
      combinedInputFields.forEach((field) => {
        if (field.name === "street") {
          field.isMandatory = true;
        }
      });
    }

    return combinedInputFields;
  };

  const getCombinedInputFields = (key, formSectionFields) => {
    let sectionKeys = Object.keys(formSectionFields);
    let sectionBodys = Object.values(formSectionFields);
    let sectionInputFields = [];
    sectionBodys.forEach((s) => {
      if (s.key === key) {
        sectionInputFields = [...sectionInputFields, ...s.inputFields];
      }
    });
    let combinedSectionFields = combineInputFields(sectionInputFields);
    /* 
      In some cases, after the combination, 
      the address input fields are not in correct order displaying on web page
      so need to make them in specific order that following 'addressInputFieldsOrder'
      more address string can be added in if needed
    */
    if (key === CURRENT_RESIDENTIAL_ADDRESS) {
      const addressInputFieldsOrder = ["street", "suburb", "city", "postcode"];
      let reorderedCombinedInputFields = [];

      addressInputFieldsOrder.forEach((addressStr) => {
        const filteredFieldObj = combinedSectionFields.find(
          (fieldObj) => fieldObj.name === addressStr
        );
        reorderedCombinedInputFields = [
          ...reorderedCombinedInputFields,
          filteredFieldObj,
        ];
      });
      combinedSectionFields = reorderedCombinedInputFields;
    }

    // Delete duplicate section in formSectionFields
    const duplicatedKeys = sectionKeys.filter((s) => s.includes(key));
    duplicatedKeys.forEach((key) => {
      delete formSectionFields[key];
    });

    return combinedSectionFields;
  };

  const getReorderedFormSections = (formSectionFields) => {
    let orderedFormSections = [];
    let orderedFormSectionFields = {};

    let globalSectionOrdersCache = JSON.parse(
      sessionStorage.getItem(GLOBAL_SECTION_ORDERS_CACHE)
    );

    const { orders } = globalSectionOrdersCache[countryRXS];
    let formSections = Object.entries(formSectionFields);

    orders.forEach((key) => {
      const currentSection = formSections.find((section) =>
        section[0].includes(key)
      );
      if (currentSection) {
        // currentSection = [sectionKey, section:{key, inputFields}]
        orderedFormSections.push(currentSection[1]);
      }
    });

    for (let { key, inputFields } of orderedFormSections) {
      orderedFormSectionFields = {
        ...orderedFormSectionFields,
        [key]: { key, inputFields },
      };
    }

    return orderedFormSectionFields;
  };

  const getFormSectionFields = (sectionDetails) => {
    let formSectionFields = {};
    for (const [key, section] of Object.entries(sectionDetails)) {
      formSectionFields[key] = {
        key: section.key,
        inputFields: [...section.inputFields],
      };
    }

    /* 
      When more than 1 services are selected, if form section input fields are duplicated, 
      then the fields need to be combined in this section
      eg. CurrentResidentialAddress
      There are different address fields to submit in this section for different services,
      so we need to combine them when these services are selected at the same time.
      And add the combined inputFields back in that section
    */
    let sectionKeys = Object.keys(formSectionFields);

    // Check if there is more than 1 current residential address sections in formSectionFields
    let isMoreThanOneAddress = false;
    if (!isEmptyVAL(sectionKeys)) {
      isMoreThanOneAddress =
        sectionKeys.filter((key) => key.includes(CURRENT_RESIDENTIAL_ADDRESS))
          .length > 1;
    }

    // If there are more address sections, will need to combine the fields
    if (isMoreThanOneAddress) {
      formSectionFields[CURRENT_RESIDENTIAL_ADDRESS] = {
        key: CURRENT_RESIDENTIAL_ADDRESS,
        inputFields: getCombinedInputFields(
          CURRENT_RESIDENTIAL_ADDRESS,
          formSectionFields
        ),
      };

      formSectionFields = getReorderedFormSections(formSectionFields);
    }

    // Check if there is more than 1 personal information sections in formSectionFields
    let isMoreThanOnePersonalInfo = false;
    if (!isEmptyVAL(sectionKeys)) {
      isMoreThanOnePersonalInfo =
        sectionKeys.filter((key) => key.includes(PERSONAL_INFORMATION)).length >
        1;
    }

    // If there are more address sections, will need to combine the fields
    if (isMoreThanOnePersonalInfo) {
      formSectionFields[PERSONAL_INFORMATION] = {
        key: PERSONAL_INFORMATION,
        inputFields: getCombinedInputFields(
          PERSONAL_INFORMATION,
          formSectionFields
        ),
      };

      formSectionFields = getReorderedFormSections(formSectionFields);
    }

    /*
      Client Reference is required by all services so the template automatically
      adds it to the form. There is no need to add client reference explicitly in
      json files.
    */
    formSectionFields[CLIENT_REFERENCE] = {
      key: CLIENT_REFERENCE,
      inputFields: [
        {
          name: CLIENT_REFERENCE,
          type: "text",
          isMandatory: false,
        },
      ],
    };

    return formSectionFields;
  };

  /*
    This function returns the mapping of api request fields. Normally a section json file contains
    a property called "apiReq". The "apiReq" has a property "apiReqFieldsMapping" which is an object
    that maps request fields to input data. The keys of "apiReqFieldsMapping" are fields in api request
    and the values are input fields names. For example, "apiReqFields" is this object
    "apiReqFieldsMapping": {
      "addressElement3": "city",
    }
    It means that the api request has a field called "addressElement3" and its value comes from an 
    input field called "city" in the form. Please note that the value could be an array.
  */

  const combineApiReqFields = (sectionKey, apiReqDetails) => {
    const apiKeys = Object.keys(apiReqDetails).filter((key) =>
      key.includes(sectionKey)
    );
    if (!isEmptyVAL(apiKeys)) {
      let combinedApiReqFields = {};
      apiKeys.forEach((key) => {
        combinedApiReqFields = {
          ...combinedApiReqFields,
          ...apiReqDetails[key].apiReqFieldsMapping,
        };
        delete apiReqDetails[key];
      });
      apiReqDetails[sectionKey] = {
        identityVariables: false,
        apiReqFieldsMapping: combinedApiReqFields,
      };
    }
  };

  const getApiReqDetails = (sectionDetails) => {
    const apiReqDetails = {};

    for (const [key, section] of Object.entries(sectionDetails)) {
      apiReqDetails[key] = { ...section.apiReq };
    }

    //Combine address form section apiReqFieldsMapping
    combineApiReqFields(CURRENT_RESIDENTIAL_ADDRESS, apiReqDetails); //key: 'currentResidentialAddress'
    combineApiReqFields(PERSONAL_INFORMATION, apiReqDetails); //key: 'personalInformation'
    return apiReqDetails;
  };

  const prepareFormData = async () => {
    const selectedDataSourceSections = await getSelectedDataSourceSections();

    const orderedDataSourceSections = await getOrderedDataSourceSections(
      selectedDataSourceSections
    );

    const sectionDetails = await getSectionDetails(orderedDataSourceSections);

    const formSectionFields = getFormSectionFields(sectionDetails);
    setFormSectionFields(formSectionFields);

    const initialState = getFormDataInitialState(formSectionFields);
    setFormData(initialState);

    const apiReqDetails = getApiReqDetails(sectionDetails);
    setApiReqDetails(apiReqDetails);
  };

  /*
    Can't directly call an arrow function that includes prepareFormData as JS will
    throw a warning that prepareFormData should be passed to useEffect as a dependency,
    but there is no point to do that.
  */
  const initialiseForm = () => {
    loadingToggleAXN(true);
    prepareFormData().then(() => loadingToggleAXN(false));
  };

  useEffect(initialiseForm, []);

  useEffect(() => {
    setInputErrors({
      ...errorsRXS,
    });
  }, [errorsRXS]);

  if (isEmptyVAL(selectedDataSources)) {
    history.push(DATA_SOURCE_SELECTION_PAGE);
    return null;
  }

  // TODO: temporary fix. This should be handled in Auth in the future.
  let ocrAccess = false;
  user.countries.forEach((item) => {
    if (item.region.toLowerCase() === "global") {
      if (item.dataSources.includes("Global OCR")) {
        ocrAccess = true;
      }
    }
  });

  const {
    isDocScanned,
    isBioAuth,
    // isLiveness, ADD WHEN FEATURE IS AVAILABLE
    // isDocAuth, ADD WHEN FEATURE IS AVAILABLE
  } = biometricsRXS.bioTriggers;

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>{transUTL(`translateCountryNames.${countryRXS.toLowerCase()}`)}</h2>
        <span
          className="material-icons icon-size"
          title="Edit Country"
          onClick={() => history.push(COUNTRY_SELECTION_PAGE)}
        >
          create
        </span>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        <div className="form-overview__top">
          <header className="header-secondary">
            <h4 className="header-title-secondary">
              {transUTL("translateFormOverview.formHeaders.selectedServices")}
            </h4>
            <span
              className="material-icons icon-size"
              title="Edit Data Source"
              onClick={() => history.push(DATA_SOURCE_SELECTION_PAGE)}
            >
              create
            </span>
          </header>
          <p className="data-source-selection__selected-data">
            {selectedDataSources
              .sort()
              .map((item, index) => {
                const dataSource = item.split(" ").join("").toLowerCase();

                return dataSource === "canadaconsumer" ||
                  dataSource === "canadaresidential" ? (
                  ""
                ) : (
                  <span key={index}>
                    {transUTL(`translateDataSources.${dataSource}`)}
                  </span>
                );
              })
              .reduce((prev, curr) => [
                prev,
                <span key={prev}>, &nbsp;</span>,
                curr,
              ])}
          </p>
        </div>
        <div className="form-overview__top">
          <header className="header-secondary">
            <h4 className="header-title-secondary">
              {transUTL("translateFormOverview.formHeaders.ocrAndBiometric")}
            </h4>
          </header>

          <div className="form-overview__ocr-icons">
            <div
              className={
                ocrAccess
                  ? "form-overview__ocr-item"
                  : "form-overview__ocr-item form-overview__ocr-item-disabled"
              }
              onClick={() => {
                if (ocrAccess) {
                  if (countryRXS === "china") {
                    history.push("/document-scan/china");
                  } else if (countryRXS === "vietnam") {
                    history.push("/document-scan/vietnam");
                  } else {
                    history.push(`/document-scan`);
                  }
                }
              }}
            >
              <span
                className={
                  ocrAccess
                    ? "material-icons form-overview__ocr-item-no-cursor"
                    : "material-icons not-allowed"
                }
              >
                payment
              </span>
              <p>
                {transUTL("translateFormOverview.document")}{" "}
                {transUTL("translateFormOverview.scan")}
              </p>

              {isDocScanned ? (
                <span className="material-icons form-overview__ocr-error no-cursor success">
                  check_circle
                </span>
              ) : (
                <span
                  className={
                    ocrAccess
                      ? "material-icons form-overview__ocr-error no-cursor error"
                      : "material-icons form-overview__ocr-error no-cursor"
                  }
                >
                  cancel
                </span>
              )}
            </div>
            <span className="material-icons no-cursor form-overview__arrows ">
              double_arrow
            </span>
            <div
              className={
                ocrAccess
                  ? "form-overview__ocr-item"
                  : "form-overview__ocr-item-disabled form-overview__ocr-item"
              }
              onClick={() => {
                if (ocrAccess) {
                  if (isDocScanned) {
                    history.push("/biometric-auth");
                  } else {
                    setAlertAXN(
                      transUTL("translateAlertMsg.completeDocumentScanFirst"),
                      "error"
                    );
                  }
                }
              }}
            >
              <span
                className={
                  ocrAccess
                    ? "material-icons form-overview__ocr-item-no-cursor"
                    : "material-icons not-allowed"
                }
              >
                face
              </span>
              <p>
                {transUTL("translateFormOverview.biometric")}{" "}
                {transUTL("translateFormOverview.authentication")}
              </p>
              {isBioAuth ? (
                <span className="material-icons form-overview__ocr-error no-cursor success">
                  check_circle
                </span>
              ) : (
                <span
                  className={
                    ocrAccess
                      ? "material-icons form-overview__ocr-error no-cursor error"
                      : "material-icons form-overview__ocr-error no-cursor"
                  }
                >
                  cancel
                </span>
              )}
            </div>
            <span className="material-icons no-cursor form-overview__arrows">
              double_arrow
            </span>
            <div className="form-overview__ocr-item form-overview__ocr-item-disabled">
              <span className="material-icons not-allowed">how_to_reg</span>
              <p>
                {transUTL("translateFormOverview.liveness")}{" "}
                {transUTL("translateFormOverview.detection")}
              </p>
              <span className="material-icons form-overview__ocr-error no-cursor">
                cancel
              </span>
            </div>
            <span className="material-icons no-cursor form-overview__arrows">
              double_arrow
            </span>
            <div className="form-overview__ocr-item form-overview__ocr-item-disabled">
              <span className="material-icons not-allowed">security</span>
              <p>
                {transUTL("translateFormOverview.document")}{" "}
                {transUTL("translateFormOverview.authenticity")}
              </p>
              <span className="material-icons form-overview__ocr-error no-cursor">
                cancel
              </span>
            </div>
          </div>
        </div>
        {formSectionFields && formData ? (
          <div>
            {Object.values(formSectionFields).map((section) => {
              return (
                <div key={section.key} className="form-overview__form-section">
                  <header className="header-secondary">
                    <h4 className="header-title-secondary">
                      {transUTL(
                        `translateFormOverview.formHeaders.${section.key}`
                      )}
                    </h4>
                  </header>
                  {formData[section.key] && renderSectionContent(section)}
                </div>
              );
            })}
            {isConsentRequired && (
              <div
                key={TERMS_AND_CONDITIONS}
                className="form-overview__form-section"
              >
                <header className="header-secondary">
                  <h4 className="header-title-secondary">
                    {transUTL(
                      `translateFormOverview.formHeaders.${TERMS_AND_CONDITIONS}`
                    )}
                  </h4>
                </header>
                <TermsAndConditions
                  isConsentChecked={isConsentChecked}
                  handleToggleCheckbox={handleToggleCheckbox}
                />
              </div>
            )}

            {countryRXS === "canada" || countryRXS === "unitedkingdom" ? (
              <div key="displayApi" className="form-overview__form-section">
                <DisplayApiResults
                  isDisplayApiChecked={isDisplayApiChecked}
                  handleToggleTickbox={handleToggleTickbox}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <button onClick={handleReset} className="btn-secondary">
          {transUTL("translateFormOverview.clear")}
        </button>
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.push(DATA_SOURCE_SELECTION_PAGE)}
          >
            {transUTL("translateBtn.previousPage")}
          </button>

          <button className="btn-primary" onClick={handleOnSubmit}>
            {transUTL("translateBtn.submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

FormOverviewTemplate.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  regionRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  biometricsRXS: PropTypes.object.isRequired,
  retainFormDataAXN: PropTypes.func.isRequired,
  retainUserInputAXN: PropTypes.func.isRequired,
  retainApiReqAXN: PropTypes.func.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  errorsRXS: state.errorsRXS,
  countryRXS: state.countryRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  regionRXS: state.regionRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  setAlertAXN,
  retainFormDataAXN,
  retainApiReqAXN,
  retainUserInputAXN,
  searchVerificationAXN,
  loadingToggleAXN,
  resetBiometricsAXN,
})(FormOverviewTemplate);
