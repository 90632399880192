import inputLogin from './ghosting/inputLogin'; // LOGIN

// FORM OVERVIEW
import inputAU from './ghosting/inputAU'; // AUSTRALIA
import inputBR from './ghosting/inputBR'; // BRAZIL
import inputCN from './ghosting/inputCN'; // CHINA
import inputIN from './ghosting/inputIN'; // INDIA
import inputID from './ghosting/inputID'; // INDONESIA
import inputMY from './ghosting/inputMY'; // MALAYSIA
import inputVN from './ghosting/inputVN'; // VIETNAM
import inputPH from './ghosting/inputPH'; // PHILIPPINES
import inputKH from './ghosting/inputKH'; // CAMBODIA
import inputNZ from './ghosting/inputNZ'; // NEW ZEALAND
import inputHK from './ghosting/inputHK'; // HONG KONG
import inputSG from './ghosting/inputSG'; // SINGAPORE
import inputNG from './ghosting/inputNG'; // NIGERIA
import inputUS from './ghosting/inputUS'; // UNITED STATES
import inputMX from './ghosting/inputMX'; // MEXICO
import inputDE from './ghosting/inputDE'; // GERMANY
import inputFR from './ghosting/inputFR'; // FRANCE
import inputIT from './ghosting/inputIT'; // ITALY
import inputES from './ghosting/inputES'; // SPAIN

const translatePlaceholder = {
  // LOGIN
  inputLogin,

  // FORM OVERVIEW
  inputAU,
  inputBR,
  inputCN,
  inputIN,
  inputID,
  inputMY,
  inputVN,
  inputPH,
  inputKH,
  inputNZ,
  inputHK,
  inputSG,
  inputNG,
  inputUS,
  inputMX,
  inputDE,
  inputFR,
  inputIT,
  inputES,
  others: {
    sortByRefNo: 'Sort by reference number ...',
    receiver: '+6XXXXXXXX',
    pinCode: 'XXXXX',
  },
};

export default translatePlaceholder;
