import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getUserTransactionsAXN,
  filterReferenceNumberAXN,
  getUserPIIDataAXN,
} from '../../actions/transactionHistory/transactionHistoryActions';
import { setAlertAXN } from '../../actions/alert/alertActions';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import loggerUTL from '../../utils/loggerUTL';
import { proxyAPI } from '../../api/init';
import { loadingToggleAXN } from '../../actions/loading/loadingAction';

// LOGO
import logoBase64 from '../../actions/transactionHistory/helpers/logoB64';

const EToroTwoTransactions = ({ authRXS, loadingToggleAXN, history }) => {
  const [transSTH, setTransSTH] = useState({
    eToroTransactions: [],
    searchID: '',
    filtered: [],
  });

  useEffect(() => {
    if (
      authRXS.user.name === 'eToro_WebProd' ||
      authRXS.user.name === 'Mike_Doran_dz' ||
      authRXS.user.isAdmin
    ) {
    } else {
      history.push('/dashboard');
    }

    var config = {
      headers: {
        proxy_url: 'https://www.dropbox.com/s/k8kspvpxuhd4hgw/eToro-Usage-Jan-17-2021.json?dl=1',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    };

    loadingToggleAXN(true);
    proxyAPI
      .get('/proxy', config)
      .then((res) => {
        const data = res.data;
        loadingToggleAXN(false);
        setTransSTH({
          ...transSTH,
          eToroTransactions: data,
          filtered: data.slice(0, 50),
        });
      })
      .catch((err) => {
        loadingToggleAXN(false);
        loggerUTL(err);
      });
    // eslint-disable-next-line
  }, []);

  const filterNames = (inputValue) => {
    const { eToroTransactions } = transSTH;

    setTransSTH({
      ...transSTH,
      searchID: inputValue,
      filtered: eToroTransactions
        .filter(
          (item) =>
            item.DZReference.includes(inputValue) ||
            item.ClientReference.includes(inputValue)
        )
        .slice(0, 50),
    });
  };

  const getValueInput = (evt) => {
    const inputValue = evt.target.value;
    // setTransSTH({ ...transSTH, searchID: inputValue });
    filterNames(inputValue);
  };

  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2>eToro usage</h2>
      </header>
      <div className="common-form__body">
        {/* FILTERS */}
        <div className="trans-history__filters">
          <p className="trans-history__filter-count">
            {transSTH.eToroTransactions.length === 1
              ? `${transSTH.eToroTransactions.length} ${transUTL(
                'translateTransactionHistory.search'
              )}`
              : `${transSTH.eToroTransactions.length} ${transUTL(
                'translateTransactionHistory.searches'
              )}`}
          </p>

          <div className="trans-history__filter-input">
            <div className="trans-history__div">
              <input
                type="text"
                name="searchID"
                value={transSTH.searchID}
                placeholder={transUTL(
                  'translatePlaceholder.others.sortByRefNo'
                )}
                onChange={getValueInput}
              />
              <img src={logoBase64} alt="Logo" />
            </div>
          </div>
        </div>
        {/* HISTORY CARDS */}
        {transSTH.filtered.map((history, index) => {
          const match = {
            CNIDMatch: history.CNIDMatch,
            CNPhoneMatch: history.CNPhoneMatch,
            PHIDMatch: history.PHIDMatch,
            PHIDWithAddressMatch: history.PHIDWithAddressMatch,
            PHResidentialMatch: history.PHResidentialMatch,
            MYCreditMatch: history.MYCreditMatch,
            MYIDMatch: history.MYIDMatch,
            GlobalMatch: history.GlobalMatch,
          };
          const usage = {
            CNIDUsage: history.CNIDUsage,
            CNPhoneUsage: history.CNPhoneUsage,
            PHIDUsage: history.PHIDUsage,
            PHIDWithAddressUsage: history.PHIDWithAddressUsage,
            PHResidentialUsage: history.PHResidentialUsage,
            MYCreditUsage: history.MYCreditUsage,
            MYIDUsage: history.MYIDUsage,
            GlobalUsage: history.GlobalUsage,
          };

          return (
            <div
              key={index}
              className={
                true
                  ? 'trans-history__transaction trans-history__border-success'
                  : 'trans-history__transaction trans-history__border-error'
              }
            >
              <div className="trans-history__availability">
                {true ? (
                  <span className="material-icons icon-size trans-history__icon-success">
                    check_circle
                  </span>
                ) : (
                    <span className="material-icons icon-size trans-history__icon-error">
                      cancel
                    </span>
                  )}
              </div>

              <div className="trans-history__body">
                <header className="trans-history__header">
                  <h4>{history.Time}</h4>
                </header>

                <p>
                  {transUTL(`translateTransactionHistory.reportingRef`)}{' '}
                  {!isEmptyVAL(history.KMSReference) && history.KMSReference}
                  {!isEmptyVAL(history.DZReference) && history.DZReference}
                </p>

                <p>
                  {transUTL(`translateTransactionHistory.clientRef`)}{' '}
                  {isEmptyVAL(history.ClientReference)
                    ? transUTL(`translateReuse.na`)
                    : history.ClientReference}
                </p>

                {history.IP && <p>IP Address: {history.IP}</p>}

                <p>Match: {JSON.stringify(match, undefined, 4)}</p>
                <p>Usage: {JSON.stringify(usage, undefined, 4)}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="single-btn-container">
        <button
          className="btn-primary"
          onClick={() => history.goBack()}
        >
          {transUTL('translateBtn.previousPage')}
        </button>
      </div>
    </div>
  );
};

EToroTwoTransactions.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  transHistoryRXS: PropTypes.array.isRequired,
  getUserTransactionsAXN: PropTypes.func.isRequired,
  getUserPIIDataAXN: PropTypes.func.isRequired,
  filterReferenceNumberAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  loadingToggleAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  transHistoryRXS: state.transHistoryRXS,
});

export default connect(mapStateToProps, {
  getUserTransactionsAXN,
  getUserPIIDataAXN,
  filterReferenceNumberAXN,
  setAlertAXN,
  loadingToggleAXN,
})(EToroTwoTransactions);
