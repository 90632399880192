const translateTitle = {
  // NAVIGATION
  chinese: '中文',
  malay: 'Malay',
  english: 'English',
  logo: '商标',
  profile: '轮廓',
  dashboard: '仪表板',
  history: '历史',
  dataAnalytics: '数据分析',
  documentLibrary: '文件库',
  aboutUs: '关于我们',
  logout: '登出',
  nextPage: '下一页',
  costEstimation: '费用估算',
  admin: '管理员'
};

export default translateTitle;
