const dataSourcesSG = {
  creditBureau: {
    title: '新加坡信贷局',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    nationalIDNo: '国民身份证',
    name: '名称',
    address: '地址',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    dateOfBirth: '出生日期',
  },
  residential: {
    title: '新加坡公用事业',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    nationalIDNo: '国民身份证',
    firstName: '名字',
    lastName: '姓',
    city: '市',
    state: '州',
    streetNo: '街道号码',
    phoneNo: '电话号码',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    dateOfBirth: '出生日期',
    streetAddress: '街道名称',
  },
  utility: {
    title: '新加坡公用事业',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    nationalIDNo: '国民身份证',
    firstName: '名字',
    lastName: '姓',
    city: '市',
    state: '州',
    streetNo: '街道号码',
    phoneNo: '电话号码',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    dateOfBirth: '出生日期',
    streetAddress: '街道名称',
  },
};

export default dataSourcesSG;
