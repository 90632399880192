// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import logger from '../../../../../utils/loggerUTL';

const whitelistedInputDataHK = (inputData, dataSources) => {
  let creditBureauData, residentialData, marketingData, watchlistAMLData;

  /* ============================================
            HONG KONG CREDIT BUREAU
   ============================================ */
  if (dataSources.includes('Hong Kong Credit Bureau')) {
    logger('WHITELISTING HONG KONG CREDIT BUREAU...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'nationalIDNo',
      'building',
      'streetName',
      'district',
      'state',
      'postCode',
      'reportingReference',
    ]);

    creditBureauData = { ...whitelisted };
  }

  /* ============================================
            HONG KONG RESIDENTIAL 
   ============================================ */
  if (dataSources.includes('Hong Kong Residential')) {
    logger('WHITELISTING HONG KONG RESIDENTIAL...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'building',
      'streetName',
      'district',
      'state',
      'postCode',
      'reportingReference',
    ]);

    residentialData = { ...whitelisted };
  }

  /* ============================================
            HONG KONG RESIDENTIAL 
   ============================================ */
  if (dataSources.includes('Hong Kong Marketing')) {
    logger('WHITELISTING HONG KONG MARKETING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'building',
      'streetName',
      'district',
      'state',
      'postCode',
      'reportingReference',
    ]);

    marketingData = { ...whitelisted };
  }

  if (dataSources.includes('Watchlist AML')) {
    logger('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...creditBureauData,
    ...residentialData,
    ...marketingData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataHK;
