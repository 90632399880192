const dataSourcesNZ = {
  passport: {
    title: 'Pasport DIA New Zealand',
    searchSuccessful: 'Carian Berjaya',
    passportNo: 'Nombor pasport',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    phoneNo: 'Nombor telefon',
  },
  driverLicence: {
    title: 'Lesen Memandu New Zealand',
    searchSuccessful: 'Carian Berjaya',
    licenceNo: 'Nombor Lesen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  companiesOffice: {
    title: 'Pejabat Syarikat New Zealand',
    searchSuccessful: 'Carian Berjaya',
    licenceNo: 'Nombor Lesen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    address: 'Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    postCode: 'Poskod',
    state: 'Nyatakan',
    suburb: 'Pinggir bandar',
    phoneNo: 'Nombor telefon',
    address1: 'No Jalan dan Nama',
    city: 'Bandar',
    landlineNo: 'talian tetap No',
  },
  diaBirth: {
    title: 'Tarikh Lahir DIA New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    middleName: 'Nama tengah',
    dateOfBirth: 'Tarikh lahir',
  },
  diaCitizen: {
    title: 'Kewarganegaraan DIA New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    middleName: 'Nama tengah',
    dateOfBirth: 'Tarikh lahir',
  },
  linzProperty: {
    title: 'Harta LINZ New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    otherOwners: 'Pemilik Lain',
    address1: 'No Jalan dan Nama',
    postCode: 'Poskod',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    middleName: 'Nama tengah',
    dateOfBirth: 'Tarikh lahir',
    city: 'Bandar',
    suburb: 'Pinggir bandar',
    phoneNo: 'Nombor telefon',
    landlineNo: 'talian tetap No',
  },
  yellowPages: {
    title: 'Halaman Kuning New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    address1: 'Jalan',
    firstName: 'Nama pertama',
    lastName: 'nama terakhir',
    postCode: 'Poskod',
    state: 'Nyatakan',
    suburb: 'Pinggir bandar',
  },
  vehicle: {
    title: 'Kenderaan New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    addressElement1: 'Nombor jalan',
    addressElement2: 'Nama jalan',
    addressElement3: 'Pinggir bandar',
    addressElement4: 'Bandar',
    addressElement5: 'Poskod',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    postCode: 'Poskod',
    state: 'Nyatakan',
    suburb: 'Pinggir bandar',
  },
  residential: {
    title: 'Kediaman New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    postCode: 'Poskod',
    state: 'Nyatakan',
    suburb: 'Pinggir bandar',
    phoneNo: 'Nombor telefon',
    address1: 'No Jalan dan Nama',
    city: 'Bandar',
    landlineNo: 'talian tetap No',
  },
  creditBureau: {
    title: 'Biro Kredit New Zealand',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    addressElement1: 'Nombor jalan',
    addressElement2: 'Nama jalan',
    addressElement3: 'Pinggir bandar',
    addressElement4: 'Bandar',
    addressElement5: 'Poskod',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    postCode: 'Poskod',
    state: 'Nyatakan',
    suburb: 'Pinggir bandar',
  },
};

export default dataSourcesNZ;
