import React from 'react';
import PropTypes from 'prop-types';

const DocumentPreview = ({
  image,
  altTag,
  onClear
}) => {

  return (
    <div className="ocr__document-preview">
      <div className="ocr__document-preview__clear-button">
        <span
          className="material-icons"
          onClick={onClear}
        >
          remove_circle
        </span>
      </div>
      <img
        src={image}
        alt={altTag}
      />
    </div>
  );
}

DocumentPreview.propTypes = {
  image: PropTypes.string,
  altTag: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired
};

export default DocumentPreview;