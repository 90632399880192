const translateLogin = {
  loginBtn: 'Log masuk',
  forgotPassword: 'Lupa kata laluan anda?',
  contact: 'atau, hubungi Zoo',
  termsAndConditions: 'Terma & Syarat',
  privacy: 'Dasar Privasi',
  copyright: 'Zoo Data',
  poweredBy: 'Dikuasakan oleh Zoo Data',

};

export default translateLogin;
