import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { setAlertAXN } from '../../actions/alert/alertActions';
import { loadingToggleAXN } from '../../actions/loading/loadingAction';

// AXIOS INSTANCE
import { dzAPI } from '../../api/init';

import TextFieldGroup from '../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../components/common/selectListGroup/SelectListGroup';
import TextAreaFieldGroup from '../../components/common/textAreaFieldGroup/TextAreaFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';

const PasswordDistro = ({
  authRXS,
  countryRXS,
  setAlertAXN,
  loadingToggleAXN,
  history,
}) => {
  const [textMsgST, setMsgST] = useState({
    receiver: '',
    sender: 'DataZoo IDU',
    message: '',
    errors: '',
  });
  const { receiver, sender, message } = textMsgST;

  const options = [
    {
      value: 'DataZoo IDU',
      label: 'DataZoo IDU'
    },
    {
      value: '+61488849923',
      label: '+61488849923'
    }
  ];

  const handleOnChange = (e) =>
    setMsgST({ ...textMsgST, [e.target.name]: e.target.value });

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (isEmptyVAL(receiver))
      return setAlertAXN(
        transUTL('translateAlertMsg.passwordAdviceReceiver'),
        'error'
      );

    if (isEmptyVAL(message))
      return setAlertAXN(
        transUTL('translateAlertMsg.passwordAdviceMsg'),
        'error'
      );

    loadingToggleAXN(true);

    dzAPI
      .post('/api/v2/mobile/send', { receiver, sender, message })
      .then((res) => {
        loadingToggleAXN(false);
        setMsgST({ ...textMsgST, receiver: '', message: '' });
        setAlertAXN(transUTL('translateAlertMsg.msgSent'), 'success');
      })
      .catch((err) => {
        loadingToggleAXN(false);
        setAlertAXN(transUTL('translateAlertMsg.errTextMsg'), 'error');
      });
  };

  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2>{transUTL('translatePasswordDistro.passwordDistor')}</h2>
      </header>
      <div className="common-form__body">
        <div className="form-overview__form-inputs">
          <TextFieldGroup
            key="receiver"
            id="receiver"
            type="text"
            name="receiver"
            dataName="receiverVN"
            labelClass="form-group__label"
            label={transUTL('translatePasswordDistro.receiver')}
            value={receiver}
            // placeholder={transUTL('translatePlaceholder.inputVN.receiver')}
            placeholder="+61492451433"
            placeholderTrigger={isEmptyVAL(receiver) ? 'input-empty' : ''}
            // error={errors.receiver}
            // errorTrigger={errors.receiver ? 'input-error' : ''}
            handleOnChange={handleOnChange}
            // handleOnBlur={handleOnBlur}
            // handleEnterKeyTrigger={handleEnterKeyTrigger}
          />
          <SelectListGroup
            key="sender"
            id="sender"
            name="sender"
            dataName="senderVN"
            labelClass="form-group___label"
            label={transUTL('translatePasswordDistro.sender')}
            value={sender}
            options={options}
            handleOnChange={handleOnChange}
          />

          <TextAreaFieldGroup
            key="message"
            id="message"
            type="text"
            name="message"
            dataName="messageVN"
            placeholder={transUTL('translatePasswordDistro.message')}
            value={message}
            handleOnChange={handleOnChange}
          />
        </div>

        {/* BUTTON */}
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.goBack()}
          >
            {transUTL('translateBtn.previousPage')}
          </button>
          <button
            className="btn-primary"
            onClick={handleOnSubmit}
          >
            {transUTL('translateBtn.submit')}
          </button>
        </div>
      </div>
    </div>
  );
};

PasswordDistro.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  loadingToggleAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
});

export default connect(mapStateToProps, { setAlertAXN, loadingToggleAXN })(
  PasswordDistro
);
