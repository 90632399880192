const sortAlphaUTL = (a, b, key = 'value') => {
  if (a[key].toLowerCase() < b[key].toLowerCase()) {
    return -1;
  }
  if (a[key].toLowerCase() > b[key].toLowerCase()) {
    return 1;
  }
  return 0;
};

export default sortAlphaUTL;
