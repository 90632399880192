const initialStateIN = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // ACCOUNT DETAILS
  pan: '',
  epic: '',
  aadhaarNo: '',

  // DRIVERS LICENCE
  driversLicenceNo: '',

  // PASSPORT
  passportNo: '',
  fileNo: '',
  dateOfIssue: '',

  // CURRENT RESIDENTIAL ADDRESS
  city: '',
  streetNo: '',
  streetName: '',
  state: '',
  suburb: '',
  postCode: '',

  // TERMS & CONDITION
  indiaPanConsentObtained: false,
  indiaDriversLicenceConsentObtained: false,
  indiaEpicConsentObtained: false,
  indiaPassportConsentObtained: false,
  indiaAadhaarConsentObtained: false,

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStateIN;
