import {
  SAVE_DATA_SOURCE_SELECTIONS,
  INITIALISE_DATA_SOURCE_SELECTIONS
} from '../../actions/types';

const initialState = {

};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_DATA_SOURCE_SELECTIONS:
      return {
        ...state,
        [action.payload.country]: { ...action.payload.dataSources }
      };
    case INITIALISE_DATA_SOURCE_SELECTIONS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}