// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const {
    residential,
    residential2,
    residential3,
    watchlistAML,
  } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(residential.status),
      col3: naUTL(watchlistAML.status),
      col4: naUTL(residential2.status),
      col5: naUTL(residential3.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(residential.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
      col4: naUTL(residential2.safeHarbourScore),
      col5: naUTL(residential3.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(residential.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
      col4: naUTL(residential2.identityVerified),
      col5: naUTL(residential3.identityVerified),

    },
    {
      col1: 'First Name Verified',
      col2: naUTL(residential.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName),
      col4: naUTL(residential2.verifications.firstName),
      col5: naUTL(residential3.verifications.firstName),

    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(residential.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
      col4: naUTL(residential2.verifications.lastName),
      col5: naUTL(residential3.verifications.lastName),

    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(residential.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
      col4: naUTL(residential2.verifications.dateOfBirth),
      col5: naUTL(residential3.verifications.dateOfBirth),

    },
    {
      col1: 'Name Match Score',
      col2: naUTL(residential.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
      col4: naUTL(residential2.nameMatchScore),
      col5: naUTL(residential3.nameMatchScore),

    },
    {
      col1: 'Address Match Score',
      col2: naUTL(residential.addressMatchScore),
      col3: naUTL(watchlistAML.addressMatchScore),
      col4: naUTL(residential2.addressMatchScore),
      col5: naUTL(residential3.addressMatchScore),

    },
    {
      col1: 'Street Verified',
      col2: naUTL(residential.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1),
      col4: naUTL(residential2.verifications.addressElement1),
      col5: naUTL(residential3.verifications.addressElement1),

    },
    {
      col1: 'City Verified',
      col2: naUTL(residential.verifications.addressElement3),
      col3: naUTL(watchlistAML.verifications.addressElement3),
      col4: naUTL(residential2.verifications.addressElement3),
      col5: naUTL(residential3.verifications.addressElement3),

    },
    {
      col1: 'State Verified',
      col2: naUTL(residential.verifications.addressElement4),
      col3: naUTL(watchlistAML.verifications.addressElement4),
      col4: naUTL(residential2.verifications.addressElement4),
      col5: naUTL(residential3.verifications.addressElement4),

    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(residential.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5),
      col4: naUTL(residential2.verifications.addressElement5),
      col5: naUTL(residential3.verifications.addressElement5),

    },
    {
      col1: 'Email Address Verified',
      col2: naUTL(residential.verifications.emailAddress),
      col3: naUTL(watchlistAML.verifications.emailAddress),
      col4: naUTL(residential2.verifications.emailAddress),
      col5: naUTL(residential3.verifications.emailAddress),

    },
    {
      col1: 'Phone No. Verified',
      col2: naUTL(residential.verifications.phoneNo),
      col3: naUTL(watchlistAML.verifications.phoneNo),
      col4: naUTL(residential2.verifications.phoneNo),
      col5: naUTL(residential3.verifications.phoneNo),

    }
  ];

  return tableFilter(table);
};

export default jsonTableRow;
