import moment from 'moment-timezone';

// HELPERS
import title from './title';

// UTILS
import naUTL from '../../../utils/naUTL';

const entryDetails = (doc, data, info, docStyles) => {
  const { CreatedAt, Request } = data;
  const createdTime = CreatedAt;
  const timeUTC = createdTime.split('T')[1].split('.')[0];
  const dateUTC = createdTime.split('T')[0];
  const stringUTC = `${dateUTC} ${timeUTC}`;
  // const timeZone = 'Australia/Brisbane'; //timezone causes issue to provide wrong AEST
  const timeDateAEST = moment(moment(stringUTC))
    .add({
      hours: 10,
    })
    .format('YYYY-MM-DD HH:mm:ss')
    .split(' ');

  const columns = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];

  const rows = [
    {
      col1: 'User Name',
      col2: info.username,
    },
    {
      col1: 'Data Source(s) Selected:',
      col2: Request.service.join(', '),
    },
    {
      col1: 'Enquiry Reference:',
      col2: naUTL(info.dzReference),
    },
    {
      col1: 'Enquiry Date and Time:',
      col2: `${timeDateAEST[0]} ${timeDateAEST[1]} AEST | ${dateUTC} ${timeUTC} UTC`,
    },
    {
      col1: 'Client Reference:',
      col2: naUTL(info.clientRef),
    },
  ];

  title(doc, docStyles, 'Entry Details', docStyles.headers.secondary);

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  doc.autoTable({
    columns: columns, 
    body: rows,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = [255, 255, 255];
    },
    showHead: 'never',
    styles: docStyles.tableStyles,
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
      },
    },
    startY: finalY,
  });
};

export default entryDetails;
