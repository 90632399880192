const initialStateUS = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // SOCIAL SECURITY
  socialSecurityNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '',
  suburb: '',
  state: '',
  postCode: '',

  // CONTACT DETAILS
  phoneNo: '',
  email: '',

  // REFERENCE
  reportingReference: '',

  // DISPLAY API
  displayApiResultObtained: false,

  // ERROR HANDLING
  errors: {},
};

export default initialStateUS;
