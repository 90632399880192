import React from 'react';
import PropTypes from 'prop-types';

const CheckBoxGroup = ({ checkboxBool, label, handleToggleCheckbox }) => {
  return (
    <div className="form-overview__checkbox-container" onClick={handleToggleCheckbox}>
      <p className="form-overview__checkbox-label">{label}</p>

      {checkboxBool ? (
        <span className="material-icons icon-size success">check_box</span>
      ) : (
        <span className="material-icons icon-size">
          check_box_outline_blank
        </span>
      )}
    </div>
  );
};

CheckBoxGroup.defaultProps = {
  toggleCheckbox: false,
};

CheckBoxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  checkboxBool: PropTypes.bool.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default CheckBoxGroup;
