// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const {
    diaBirth,
    diaCitizenship,
    diaPassport,
    driverLicence,
    linzProperty,
    vehicle,
    residential,
    companiesOffice,
    yellowPages,
    creditBureau,
    watchlistAML,
  } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(diaBirth.status),
      col3: naUTL(diaCitizenship.status),
      col4: naUTL(diaPassport.status),
      col5: naUTL(driverLicence.status),
      col6: naUTL(linzProperty.status),
      col7: naUTL(vehicle.status),
      col8: naUTL(residential.status),
      col9: naUTL(companiesOffice.status),
      col10: naUTL(yellowPages.status),
      col11: naUTL(creditBureau.status),
      col12: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(diaBirth.safeHarbourScore),
      col3: naUTL(diaCitizenship.safeHarbourScore),
      col4: naUTL(diaPassport.safeHarbourScore),
      col5: naUTL(driverLicence.safeHarbourScore),
      col6: naUTL(linzProperty.safeHarbourScore),
      col7: naUTL(vehicle.safeHarbourScore),
      col8: naUTL(residential.safeHarbourScore),
      col9: naUTL(companiesOffice.safeHarbourScore),
      col10: naUTL(yellowPages.safeHarbourScore),
      col11: naUTL(creditBureau.safeHarbourScore),
      col12: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(diaBirth.identityVerified),
      col3: naUTL(diaCitizenship.identityVerified),
      col4: naUTL(diaPassport.identityVerified),
      col5: naUTL(driverLicence.identityVerified),
      col6: naUTL(linzProperty.identityVerified),
      col7: naUTL(vehicle.identityVerified),
      col8: naUTL(residential.identityVerified),
      col9: naUTL(companiesOffice.identityVerified),
      col10: naUTL(yellowPages.identityVerified),
      col11: naUTL(creditBureau.identityVerified),
      col12: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(diaBirth.verifications.firstName),
      col3: naUTL(diaCitizenship.verifications.firstName),
      col4: naUTL(diaPassport.verifications.firstName),
      col5: naUTL(driverLicence.verifications.firstName),
      col6: naUTL(linzProperty.verifications.firstName),
      col7: naUTL(vehicle.verifications.firstName),
      col8: naUTL(residential.verifications.firstName),
      col9: naUTL(companiesOffice.verifications.firstName),
      col10: naUTL(yellowPages.verifications.firstName),
      col11: naUTL(creditBureau.verifications.firstName),
      col12: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(diaBirth.verifications.middleName),
      col3: naUTL(diaCitizenship.verifications.middleName),
      col4: naUTL(diaPassport.verifications.middleName),
      col5: naUTL(driverLicence.verifications.middleName),
      col6: naUTL(linzProperty.verifications.middleName),
      col7: naUTL(vehicle.verifications.middleName),
      col8: naUTL(residential.verifications.middleName),
      col9: naUTL(companiesOffice.verifications.middleName),
      col10: naUTL(yellowPages.verifications.middleName),
      col11: naUTL(creditBureau.verifications.middleName),
      col12: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(diaBirth.verifications.lastName),
      col3: naUTL(diaCitizenship.verifications.lastName),
      col4: naUTL(diaPassport.verifications.lastName),
      col5: naUTL(driverLicence.verifications.lastName),
      col6: naUTL(linzProperty.verifications.lastName),
      col7: naUTL(vehicle.verifications.lastName),
      col8: naUTL(residential.verifications.lastName),
      col9: naUTL(companiesOffice.verifications.lastName),
      col10: naUTL(yellowPages.verifications.lastName),
      col11: naUTL(creditBureau.verifications.lastName),
      col12: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Name Match Score Verified',
      col2: naUTL(diaBirth.nameMatchScore),
      col3: naUTL(diaCitizenship.nameMatchScore),
      col4: naUTL(diaPassport.nameMatchScore),
      col5: naUTL(driverLicence.nameMatchScore),
      col6: naUTL(linzProperty.nameMatchScore),
      col7: naUTL(vehicle.nameMatchScore),
      col8: naUTL(residential.nameMatchScore),
      col9: naUTL(companiesOffice.nameMatchScore),
      col10: naUTL(yellowPages.nameMatchScore),
      col11: naUTL(creditBureau.nameMatchScore),
      col12: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(diaBirth.verifications.dateOfBirth),
      col3: naUTL(diaCitizenship.verifications.dateOfBirth),
      col4: naUTL(diaPassport.verifications.dateOfBirth),
      col5: naUTL(driverLicence.verifications.dateOfBirth),
      col6: naUTL(linzProperty.verifications.dateOfBirth),
      col7: naUTL(vehicle.verifications.dateOfBirth),
      col8: naUTL(residential.verifications.dateOfBirth),
      col9: naUTL(companiesOffice.verifications.dateOfBirth),
      col10: naUTL(yellowPages.verifications.dateOfBirth),
      col11: naUTL(creditBureau.verifications.dateOfBirth),
      col12: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(diaBirth.verifications.gender),
      col3: naUTL(diaCitizenship.verifications.gender),
      col4: naUTL(diaPassport.verifications.gender),
      col5: naUTL(driverLicence.verifications.gender),
      col6: naUTL(linzProperty.verifications.gender),
      col7: naUTL(vehicle.verifications.gender),
      col8: naUTL(residential.verifications.gender),
      col9: naUTL(companiesOffice.verifications.gender),
      col10: naUTL(yellowPages.verifications.gender),
      col11: naUTL(creditBureau.verifications.gender),
      col12: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Driver Licence Number Verified',
      col2: naUTL(diaBirth.verifications.licenceNo),
      col3: naUTL(diaCitizenship.verifications.licenceNo),
      col4: naUTL(diaPassport.verifications.licenceNo),
      col5: naUTL(driverLicence.verifications.licenceNo),
      col6: naUTL(linzProperty.verifications.licenceNo),
      col7: naUTL(vehicle.verifications.licenceNo),
      col8: naUTL(residential.verifications.licenceNo),
      col9: naUTL(companiesOffice.verifications.licenceNo),
      col10: naUTL(yellowPages.verifications.licenceNo),
      col11: naUTL(creditBureau.verifications.licenceNo),
      col12: naUTL(watchlistAML.verifications.licenceNo),
    },
    {
      col1: 'Passport Number Verified',
      col2: naUTL(diaBirth.verifications.passportNo),
      col3: naUTL(diaCitizenship.verifications.passportNo),
      col4: naUTL(diaPassport.verifications.passportNo),
      col5: naUTL(driverLicence.verifications.passportNo),
      col6: naUTL(linzProperty.verifications.passportNo),
      col7: naUTL(vehicle.verifications.passportNo),
      col8: naUTL(residential.verifications.passportNo),
      col9: naUTL(companiesOffice.verifications.passportNo),
      col10: naUTL(yellowPages.verifications.passportNo),
      col11: naUTL(creditBureau.verifications.passportNo),
      col12: naUTL(watchlistAML.verifications.passportNo),
    },
    {
      col1: 'Street Number Verified',
      col2: naUTL(diaBirth.verifications.addressElement1),
      col3: naUTL(diaCitizenship.verifications.addressElement1),
      col4: naUTL(diaPassport.verifications.addressElement1),
      col5: naUTL(driverLicence.verifications.addressElement1),
      col6: naUTL(linzProperty.verifications.addressElement1),
      col7: naUTL(vehicle.verifications.addressElement1),
      col8: naUTL(residential.verifications.addressElement1),
      col9: naUTL(companiesOffice.verifications.addressElement1),
      col10: naUTL(yellowPages.verifications.addressElement1),
      col11: naUTL(creditBureau.verifications.addressElement1),
      col12: naUTL(watchlistAML.verifications.addressElement1),
    },
    {
      col1: 'Street Name Verified',
      col2: naUTL(diaBirth.verifications.addressElement2),
      col3: naUTL(diaCitizenship.verifications.addressElement2),
      col4: naUTL(diaPassport.verifications.addressElement2),
      col5: naUTL(driverLicence.verifications.addressElement2),
      col6: naUTL(linzProperty.verifications.addressElement2),
      col7: naUTL(vehicle.verifications.addressElement2),
      col8: naUTL(residential.verifications.addressElement2),
      col9: naUTL(companiesOffice.verifications.addressElement2),
      col10: naUTL(yellowPages.verifications.addressElement2),
      col11: naUTL(creditBureau.verifications.addressElement2),
      col12: naUTL(watchlistAML.verifications.addressElement2),
    },
    {
      col1: 'Suburb Verified',
      col2: naUTL(diaBirth.verifications.addressElement3),
      col3: naUTL(diaCitizenship.verifications.addressElement3),
      col4: naUTL(diaPassport.verifications.addressElement3),
      col5: naUTL(driverLicence.verifications.addressElement3),
      col6: naUTL(linzProperty.verifications.addressElement3),
      col7: naUTL(vehicle.verifications.addressElement3),
      col8: naUTL(residential.verifications.addressElement3),
      col9: naUTL(companiesOffice.verifications.addressElement3),
      col10: naUTL(yellowPages.verifications.addressElement3),
      col11: naUTL(creditBureau.verifications.addressElement3),
      col12: naUTL(watchlistAML.verifications.addressElement3),
    },
    {
      col1: 'City Verified',
      col2: naUTL(diaBirth.verifications.addressElement4),
      col3: naUTL(diaCitizenship.verifications.addressElement4),
      col4: naUTL(diaPassport.verifications.addressElement4),
      col5: naUTL(driverLicence.verifications.addressElement4),
      col6: naUTL(linzProperty.verifications.addressElement4),
      col7: naUTL(vehicle.verifications.addressElement4),
      col8: naUTL(residential.verifications.addressElement4),
      col9: naUTL(companiesOffice.verifications.addressElement4),
      col10: naUTL(yellowPages.verifications.addressElement4),
      col11: naUTL(creditBureau.verifications.addressElement4),
      col12: naUTL(watchlistAML.verifications.addressElement4),
    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(diaBirth.verifications.addressElement5),
      col3: naUTL(diaCitizenship.verifications.addressElement5),
      col4: naUTL(diaPassport.verifications.addressElement5),
      col5: naUTL(driverLicence.verifications.addressElement5),
      col6: naUTL(linzProperty.verifications.addressElement5),
      col7: naUTL(vehicle.verifications.addressElement5),
      col8: naUTL(residential.verifications.addressElement5),
      col9: naUTL(companiesOffice.verifications.addressElement5),
      col10: naUTL(yellowPages.verifications.addressElement5),
      col11: naUTL(creditBureau.verifications.addressElement5),
      col12: naUTL(watchlistAML.verifications.addressElement5),
    },
    {
      col1: 'Address Match Score Verified',
      col2: naUTL(diaBirth.addressMatchScore),
      col3: naUTL(diaCitizenship.addressMatchScore),
      col4: naUTL(diaPassport.addressMatchScore),
      col5: naUTL(driverLicence.addressMatchScore),
      col6: naUTL(linzProperty.addressMatchScore),
      col7: naUTL(vehicle.addressMatchScore),
      col8: naUTL(residential.addressMatchScore),
      col9: naUTL(companiesOffice.addressMatchScore),
      col10: naUTL(yellowPages.addressMatchScore),
      col11: naUTL(creditBureau.addressMatchScore),
      col12: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'Error Message',
      col2: naUTL(diaBirth.errorMessage),
      col3: naUTL(diaCitizenship.errorMessage),
      col4: naUTL(diaPassport.errorMessage),
      col5: naUTL(driverLicence.errorMessage),
      col6: naUTL(linzProperty.errorMessage),
      col7: naUTL(vehicle.errorMessage),
      col8: naUTL(residential.errorMessage),
      col9: naUTL(companiesOffice.errorMessage),
      col10: naUTL(yellowPages.errorMessage),
      col11: naUTL(creditBureau.errorMessage),
      col12: naUTL(watchlistAML.errorMessage),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
