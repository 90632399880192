// HELPERS
// import isoCertificateB64 from '../../kms/helpers/isoCertificateB64';
import getImageDimensionsFromBase64UTL from '../../../utils/getImageDimensionsFromBase64UTL__';
import isoCertificateB64 from '../helpers/isoCertificateB64';

const footer = async (doc, data, info, docStyles, country) => {
  // DISPLAY ISO CERT IMAGE
  const b64 = isoCertificateB64.split(',')[1];
  const { imgWidth, imgHeight } = await getImageDimensionsFromBase64UTL(b64);
  doc.addImage(
    isoCertificateB64,
    'PNG',
    doc.internal.pageSize.width - 147,
    doc.internal.pageSize.height - 135,
    imgWidth / 4.52,
    imgHeight / 4.52
  );

  // LINE
  const finalY = doc.internal.pageSize.height - 85; //this gives you the              value of the end-y-axis-position of the previous autotable.
  const widthDoc = doc.internal.pageSize.width - 30;
  const posX = 30;
  const startPoint = finalY; // must be the same value if wanting horizontal line
  const endPoint = finalY; // must be the same value if wanting horizontal line
  doc.setDrawColor(...docStyles.colorGreen);
  doc.line(posX, startPoint, widthDoc, endPoint); // horizontal line

  // IMPORTANT INFORMATION TEXT
  doc.setFont(undefined, 'normal');
  doc.setFontSize(docStyles.textSize);
  doc.setTextColor(docStyles.colorText);
  doc.text(
    `IMPORTANT INFORMATION: This report is provided to the subscriber on the terms and conditions of the subscriber agreement.\nData Zoo Pty Limited (ABN 62 484 501 636) (Trading as Data Zoo) cannot and does not warrant the accuracy of the information or\nthat it is free from errors or defects. Data Zoo shall not be liable in any way whatsoever for the supply of the Information contained\nin this report or the use of the information.`,
    docStyles.startX,
    doc.internal.pageSize.height - 70,
    'left'
  );

  // FOOTER TEXT BOTTOM
  doc.setFontSize(8);
  doc.setTextColor(docStyles.colorText);
  doc.setFont(docStyles.fontFamily.time, 'normal');
  doc.text(
    'Data Zoo Consumer IDU Verification',
    docStyles.startX,
    doc.internal.pageSize.height - 20
  );

  doc.text(
    country,
    doc.internal.pageSize.width / 2 - docStyles.startX / 2,
    doc.internal.pageSize.height - 20,
    ''
  );
  doc.text(
    'Private and Confidential',
    doc.internal.pageSize.width - 90,
    doc.internal.pageSize.height - 20
  );
};

export default footer;
