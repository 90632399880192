
import sortAlphaUTL from "../../../../utils/sortAlphaUTL";
import genderOptions from "./optionsForSelectList/genderOptions";
import medicareCardTypeOptions from "./optionsForSelectList/medicareCardTypeOptions";
import stateCapitalAU from "./optionsForSelectList/stateCapitalAU";
import countryOptions from "./optionsForSelectList/countryOptions";

const optionsForSelectList = (selectListLabel) => {
  let optionsForSelectList = [];

  switch (selectListLabel) {
    case 'medicareCardType':
      optionsForSelectList = medicareCardTypeOptions().sort(sortAlphaUTL);
      break;
    case 'driversLicenceStateOfIssue':
    case 'state':
      optionsForSelectList = stateCapitalAU().sort(sortAlphaUTL);
      break;
    // case 'gender':
    //   optionsForSelectList = genderOptions().sort(sortAlphaUTL);
    //   break;
    case 'passportIssuerCountry':
      optionsForSelectList = countryOptions().sort(sortAlphaUTL);
    default:
      break;
  };

  return optionsForSelectList;
}

export default optionsForSelectList;
