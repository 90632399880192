const translateTransactionHistory = {
  dataSource: 'Data Source:',
  reportingRef: 'Reporting Ref:',
  clientRef: 'Client Ref:',
  generatePDF: 'Generate PDF',
  search: 'Search',
  searches: 'Searches',
  generationAvailable: 'PDF generation available',
  generationUnavailable: 'PDF generation can only be generated within 24hrs',
  notApplicable: 'N/A',
  unavailable: 'Unavailable',
  next: 'Next',
  back: 'Back',
  searchTransactionHistory: 'Search Transaction History',
  filter: 'Search',
  new: 'Sort by newest',
  old: 'Sort by oldest',
  to: 'To',
  searchByDate: 'Search by Date',
  today: 'Today',
  singleDay: 'Date',
  dateRange: 'Date Range',
  showDetails: 'Show Details',
  processingTime: 'Processing Time: ',
  organisation : 'Organisation: ',
  userIp: 'User Ip: ',
  username: 'Username: ',
  DOBYear: 'Birth Year: ',
  status: 'Status: ',
  transactionDetials: 'Transaction Details',
  RequestData: 'Request Data',
  ResponseData: 'Response Data',
  requiredDateStart: 'Please provide START DATE',
  requiredDateEnd: 'Please provide END DATE',
  requiredDate: 'Please provide DATE',
  requiredDateOrRef: 'Please provide DATE or REFERENCE NUMBER',
  noFutureDate: 'Cannot choose future date',
  startDateLaterThanEndDate: 'START DATE should be earlier than END DATE'
};

export default translateTransactionHistory;
