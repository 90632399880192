import store from '../../../../store/store';
import {
  saveCostEstCountryAXN,
  saveCostEstDataSourceAXN,
} from '../../../../actions/costEstimation/costEstimationAction';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const costEstimationSessionDataRestore = () => {
  loggerUTL('COST ESTIMATION SESSION DATA RESTORE...');
  if (sessionStorage.costEstCountry) {
    store.dispatch(
      saveCostEstCountryAXN(JSON.parse(sessionStorage.costEstCountry))
    );
  }

  if (sessionStorage.costEstDataSource) {
    store.dispatch(
      saveCostEstDataSourceAXN(JSON.parse(sessionStorage.costEstDataSource))
    );
  }
};

export default costEstimationSessionDataRestore;
