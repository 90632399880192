const dataSourcesKH = {
  consumerData: {
    title: '柬埔寨国民身份证',
    searchSuccessful: '搜索成功',
    identityVerified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    gender: '性别验证',
    voterNo: '选民编号已验证',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数e'
  }
};

export default dataSourcesKH;
