import isEmptyVAL from '../../validations/checks/isEmptyVAL';

import { SET_CURRENT_USER, USER_LOGOUT } from '../../actions/types';

/* ============================================
                  TEST ACCOUNT
   ============================================ */
// const initialState = {
//   // isAuthenticated: false,
//   isAuthenticated: true, // Hard coded
//   responsiveAccountSources: true,
//   user: {
//     sessionToken: 'A81FC56B-6617-4C4B-A090-D01F957C8D75',
//     username: 'Denis_Otim',
//     isAdmin: true,
//     countries: [
//       {
//         region: 'apac',
//         service: 'australia',
//         belongsTo: 'asiaPacific',
//         countryCode: { alpha2: 'AU', alpha3: 'AUS' },
//         dataSources: ['Watchlist AML'],
//       },
//       {
//         region: 'apac',
//         service: 'china',
//         belongsTo: 'asiaPacific',
//         countryCode: { alpha2: 'CH', alpha3: 'CHN' },
//         dataSources: ['Watchlist AML'],
//       },

//       // NOT FOUND SERVICES
//       {
//         region: '',
//         service: '',
//         belongsTo: '',
//         countryCode: { alpha2: '', alpha3: '' },
//         dataSources: [''],
//       },
//       {
//         region: '',
//         service: 'watchlistAML',
//         belongsTo: '',
//         countryCode: { alpha2: '', alpha3: '' },
//         dataSources: [''],
//       },
//     ],
//   },
// };

// export default function (state = initialState, action) {
//   switch (action.type) {
//     case SET_CURRENT_USER:
//       return {
//         ...state,
//         // isAuthenticated: !isEmptyVAL(action.payload),
//         // user: action.payload
//       };
//     default:
//       return state;
//   }
// }

/* ============================================
                  PRODUCTION
   ============================================ */
const initialState = {
  isAuthenticated: false,
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmptyVAL(action.payload),
        user: action.payload,
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
