import React, { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Modal = ({
  children,
}) => {

  const [dynamicCss, setDynamicCss] = useState('');

  // TODO:
  // This should trigger an exit animation when the component is destroyed.
  // It is not working for some reason. I think it's because I am assigning a
  // css class when the component is being destroyed. 
  // Don't have time to fix this now, but will need to think of a different
  // solution in the future. - Joel
  useEffect(() => {
    return (() => {
      setDynamicCss(' destroy');
      setTimeout(() => { }, 10000);
    })
  }, []);

  return (
    <div className={"modal" + dynamicCss}>
      <div className={"modal__dialogue" + dynamicCss}>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired
};

export default Modal;