import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';

// HELPERS
import initialStateNG from './helpers/initialStateNG';
import whitelistedInputDataNG from './helpers/whitelistedInputDataNG';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import nigeriaVAL from '../../../../validations/inputFields/nigeriaVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationNigeria from './inputForms/PersonalInformationNigeria';
import IdentityDetailsNigeria from './inputForms/IdentityDetailsNigeria';
import ContactDetailsNigeria from './inputForms/ContactDetailsNigeria';
import CurrentResidentialAddressNigeria from './inputForms/CurrentResidentialAddressNigeria';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import rmSpaceUTL from '../../../../utils/rmSpaceUTL';

const NigeriaForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateNG);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.nigeriaDataSources
  );

  // LOAD FORM
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.nigeriaFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.nigeriaFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = nigeriaVAL(inputData, selectedDataSources);
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // NIGERIA DATASOURCES DON'T REQUIRE CONSENT

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataNG(inputData, selectedDataSources);

    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // ADDRESS MANIPULATION
    const addressElement1 = rmSpaceUTL(inputData.streetAddress); // REMOVES ADDITIONAL WHITE SPACES
    const addressElement2 = rmSpaceUTL(inputData.town);
    const addressElement3 = rmSpaceUTL(inputData.lga);
    const addressElement4 = rmSpaceUTL(inputData.state);

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      addressElement1,
      addressElement2,
      addressElement3,
      addressElement4,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // FIRES THE MOMENT THE ELEMENT LOSES FOCUS
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const {
      errors,

      nationalIDConsentObtained,
      bankVerificationConsentObtained,

      ...filteredFormData
    } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // WHEN USER HITS THE ENTER DURING/AFTER INPUT IT TRIGGERS A STATE SAVE
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');

    // OMITS `errors`
    const { errors, ...reset } = initialStateNG;

    // RESETS TO INITIAL STATE
    setUserInputSTH(initialStateNG);

    // RESETS NIGERIA FORM DATA
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  // ALL THESES DATA SOURCES HERE DOES NOT REQUIRE CONSENT
  // UI - SHOWS (CLIENT REFERENCE) SECTION
  conditionalClientRef.push(
    ...['Watchlist AML', 'Nigeria National ID', 'Nigeria Bank Verification']
  );

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'Nigeria National ID',
        'Nigeria Bank Verification',
        'Watchlist AML',
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationNigeria
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <FormController
          key="identityDetails"
          title={transUTL('translateFormOverview.formHeaders.identityDetails')}
          inputForm={
            <IdentityDetailsNigeria
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressNigeria
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Nigeria National ID', 'Nigeria Bank Verification'],
      element: (
        <FormController
          key="contactDetails"
          title={transUTL('translateFormOverview.formHeaders.contactDetails')}
          inputForm={
            <ContactDetailsNigeria
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

NigeriaForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(NigeriaForms));
