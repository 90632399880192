const translateAlertMsg = {
  correctCardType: 'Sila pilih jenis kad yang betul',
  timeout: 'Masa tamat melebihi. r',
  pleaseSelectCountry: 'Sila pilih negaray',
  pleaseSelectDataSource: 'Sumber Data mesti dipilihd',
  consent: 'Anda mesti bersetuju dengan terma dan syarats',
  selectCompleteAddress: 'Sila pilih alamat pelengkap automatiks',
  didNotPickUpStreetNo:
    'Opps, did not pick up street number, manual input required',
  didNotPickUpStreetName:
    'Opps, did not pick up street name, manual input required',
  didNotPickUpStreetSuburb:
    'Opps, did not pick up suburb, manual input required',
  didNotPickUpPostCode: 'Op, tidak mengambil poskod, diperlukan input manuald',
  uploadPhoto: 'Sila ambil gambar atau muat naik fail ',
  selectCardType: 'Jenis kad mesti dipilih ',
  acceptableFileType:
    'Jenis fail yang boleh diterima adalah: jpeg, jpg, bmp dan png. ',
  thereIsNoImage: 'Tidak ada muat naik fail gambard',
  providePhoneNumber: 'Sila berikan nombor telefon andar',
  providePinCode: 'Sila berikan kod pine',
  authIncorrectCredentials:
    'Hmm, itu bukan gabungan nama pengguna dan kata laluan. ',
  contactSupport:
    'Ralat telah berlaku semasa carian, sila cuba sebentar lagi. ',
  error: 'kesilapanr',
  success: 'kejayaans',
  warning: 'memberi amarang',
  inputErrors: 'Sila isi borang dengan betuly',
  emptyInput: 'Minimum 3 input diperlukan. Sama ada nama pertama atau nama keluarga diperlukan',
  addressInput: 'Permintaan mesti menyertakan No Jalan dan Nama disertai sama ada Pinggir Bandar, Bandar atau Poskod',
  passwordAdviceMsg: 'Sila berikan meseje',
  passwordAdviceReceiver: 'Sila berikan penerimaanr',
  msgSent: 'Pembahagian kata laluan berjayal',
  searchSuccessful: 'Pencarian berjayal',
  searchUnSuccessful: 'Carian tidak berjayal',
  sessionTimeout: 'Tamat masa sesi, sila log masuk semulan',
  pdfError:
    'Penjanaan PDF pada masa ini tidak tersedia untuk sumber data yang dipilih',
  completeDocumentScanFirst: 'Anda mesti mengimbas dokumen pengenalan dirit',
  max3: 'Maksimum 3 fails',
  max1: 'Maksimum 1 faile',
  uploadIdentityDocs: 'Sila muat naik dokumen pengenalan diris',
  compareIDtoSelfie:
    'Pastikan mengambil gambar diri sendiri dan pilih dokumen pengenalan yang ingin anda bandingkan',
  takeASelfie: 'Sila ambil gambar selfie atau muat naik selfie',
  dateSelection: 'Sila pilih date',
  clientSelection: 'Sila pilih pelanggant',
  inputRequired: 'Input diperlukan untuk carianh',
  serverTimeOut: 'Tamat masa pelayan. r',
  collectiveSize: 'Ukuran kolektif dokumen terlalu besare',
  uploadError: 'Ukuran kolektif dokumen terlalu besar',
  expiredDocument:
    'Dokumen yang diimbas telah atau mungkin telah luput dan boleh mengakibatkan pengesahan.',
  notMatchSelectedCountry:
    'Dokumen pengenalan yang disediakan tidak sesuai dengan negara yang dipilih.',
  inputMappingError:
    'Berlaku ralat semasa imbasan dokumen, sila cuba sebentar lagi. ',
  errTextMsg:
    'Ralat semasa menghantar teks ... Pastikan nombor telefon diformat dengan betul (+61 ...)',
  daonOcrNotAvailable:
    'Imbasan dokumen tidak tersedia untuk negara yang dipilih',
  scanResultsEmpty: 'Dokumen yang dimuat naik tidak mengembalikan data ...',
  unableToReadScan: 'Tidak dapat mengimbas dan mengenali dokumen',
  networkError: 'Ralat Rangkaian ... Sila cuba sebentar lagi',
  documentType: 'Sila pilih jenis dokumen',
  notSuportingUploadedFileFormat: "Format yang disertakan adalah '.jpg', '.jpeg', '.gif', '.png', '.svg'.",
  fileTooLargeToUpload: 'Saiz fail maksimum ialah 9mb.',
  noRecordsFound: 'Tiada rekod ditemui'
};

export default translateAlertMsg;
