import * as Sentry from "@sentry/browser";
import axios from "axios";
import jsPDF from "jspdf";

// ACTION TYPES
import {
  GET_USER_TRANSACTION_HISTORY,
  FILTER_TRANSACTIONS,
  GET_TOTAL_RECORD,
} from "../types";

// REDUX ACTIONS
import { loadingToggleAXN } from "../loading/loadingAction";
import { setAlertAXN } from "../alert/alertActions";

// AXIOS INSTANCE
import { dzAPI, proxyAPI, iduBucket } from "../../api/init";

// PDF GENERATIONS
import globalPDF from "./pdf/globalPDF/globalPDF";
import argentinaPDF from "./pdf/argentinaPDF/argentinaPDF";
import australiaPDF from "./pdf/australiaPDF/australiaPDF";
import bangladeshPDF from "./pdf/bangladeshPDF/bangladeshPDF";
import brazilPDF from "./pdf/brazilPDF/brazilPDF";
import slovakiaPDF from "./pdf/slovakiaPDF/slovakiaPDF";
import belgiumPDF from "./pdf/belgiumPDF/belgiumPDF";
import chilePDF from "./pdf/chilePDF/chilePDF";
import cambodiaPDF from "./pdf/cambodiaPDF/cambodiaPDF";
import chinaPDF from "./pdf/chinaPDF/chinaPDF";
import hongKongPDF from "./pdf/hongKongPDF/hongKongPDF";
import indiaPDF from "./pdf/indiaPDF/indiaPDF";
import indonesiaPDF from "./pdf/indonesiaPDF/indonesiaPDF";
import malaysiaPDF from "./pdf/malaysiaPDF/malaysiaPDF";
import vietnamPDF from "./pdf/vietnamPDF/vietnamPDF";
import singaporePDF from "./pdf/singaporePDF/singaporePDF";
import thailandPDF from "./pdf/thailandPDF/thailandPDF";
import philippinesPDF from "./pdf/philippinesPDF/philippinesPDF";
import nigeriaPDF from "./pdf/nigeriaPDF/nigeriaPDF";
import mexicoPDF from "./pdf/mexicoPDF/mexicoPDF";
import unitedStatesPDF from "./pdf/unitedStatesPDF/unitedStatesPDF";
import unitedKingdomPDF from "./pdf/unitedKingdomPDF/unitedKingdomPDF";
import newZealandPDF from "./pdf/newZealandPDF/newZealandPDF";
import moroccoPDF from "./pdf/moroccoPDF/moroccoPDF";
import germanyPDF from "./pdf/germanyPDF/germanyPDF";
import francePDF from "./pdf/francePDF/francePDF";
import southAfricaPDF from "./pdf/southAfricaPDF/southAfricaPDF";
import switzerlandPDF from "./pdf/switzerlandPDF/switzerlandPDF";
import polandPDF from "./pdf/polandPDF/polandPDF";
import greecePDF from "./pdf/greecePDF/greecePDF";
import austriaPDF from "./pdf/austriaPDF/austriaPDF";
import peruPDF from "./pdf/peruPDF/peruPDF";
import italyPDF from "./pdf/italyPDF/italyPDF";
import czechRepublicPDF from "./pdf/czechRepublicPDF/czechRepublicPDF";
import kenyaPDF from "./pdf/kenyaPDF/kenyaPDF";
import canadaPDF from "./pdf/canadaPDF/canadaPDF";

// LOGO
import logoBase64 from "./helpers/logoB64";

// VALIDATIONS
import isEmptyVAL from "../../validations/checks/isEmptyVAL";

// UTILITIES
import blobToFileUTL from "../../utils/blobToFileUTL";
import dataURItoBlobUTL from "../../utils/dataURItoBlobUTL";
import { transUTL } from "../../utils/transUTL";
import loggerUTL from "../../utils/loggerUTL";
import verificationResultPDF from "./pdf_template/verificationResultPDF";

import COUNTRIES_NOT_USE_PDF_TEMPLATE from "../../constants/countriesForPDFtemplate";

/* ============================================
          AXIOS REQUEST
   ============================================ */
// GET HISTORY DATA
const getHistoryData = (referenceNumber, type) =>
  dzAPI.get(`/api/v2/transactions/${type}/${referenceNumber}`);
// GET BIOMETRIC SCANS
const getBiometricScans = (referenceNumber, type) =>
  dzAPI.get(`/api/v2/logging/get-images?${type}=${referenceNumber}`);
// GET UNICODE MS FONT
const getUnicodeMSFont = () => iduBucket.get("/arialUnicodeMS.txt");

/* ============================================
          CUSTOM FONT
   ============================================ */
const addCustomFontToPDF = (fontString, jsPDFAPI, attributes) => {
  let font = fontString; // LOAD THE *.ttf FONT FILE AS BINARY STRING

  // ADD THE FONT TO JSPDF
  let callAddFont = function () {
    this.addFileToVFS(attributes.fileName, font);
    this.addFont(attributes.fileName, attributes.name, "normal");
  };

  if (!jsPDFAPI.events.some((e) => e[0] === "addFonts")) {
    jsPDFAPI.events.push(["addFonts", callAddFont]);
  }
};

/* ============================================
          PDF ERROR MESSAGE
   ============================================ */
const pdfErrorMessage = (browserWindow, additionalMsg) => {
  const doc = new jsPDF({
    orientation: "p",
    unit: "px",
    format: "a4",
    compress: true,
  });

  // SET TITLE
  doc.setProperties({
    title: "Report",
  });

  // ERROR MESSAGE CONTENT
  const width = doc.internal.pageSize.getWidth();
  doc.setFontSize(15);
  doc.setTextColor(89, 193, 111);
  doc.setFont(undefined, "bolditalic");
  doc.text(
    width / 12,
    50,
    "An error has occurred during PDF Generation, please try again later."
  );
  doc.text(
    width / 30,
    70,
    "If the problems persist please contact the support at support@datazoo.com..."
  );

  if (!isEmptyVAL(additionalMsg)) {
    doc.text(width / 25, 120, additionalMsg);
  }

  const string = doc.output("datauristring");
  const iframe =
    "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
  browserWindow.document.open();
  browserWindow.document.write(iframe);
  browserWindow.document.close();
};

/* ============================================
          GET USER TRANSACTIONS
   ============================================ */
export const getUserTransactionsAXN =
  (user, initialPos, sorting) => (dispatch) => {
    // GETS A LIST OF USERS TRANSACTION
    dispatch(loadingToggleAXN(true));
    dzAPI
      .get(
        `api/v2/transactions/user/big?pageOrdering=${
          sorting === "kms_user" ? "true" : "false"
        }&dz_ref=0&page=${initialPos}`
      ) //Default call

      .then((res) => {
        dispatch({
          type: GET_USER_TRANSACTION_HISTORY,
          payload: res.data.TransactionRows,
        });
        dispatch(loadingToggleAXN(false));
      })
      .catch((err) => {
        dispatch(loadingToggleAXN(false));
        loggerUTL("ERRORS...", err);
        Sentry.captureException("Unable to fetch user transaction history...");
      });
  };

/* ======================================================
          GET CURRENT USER PII DATA
  ====================================================== */
export const getUserPIIDataAXN = (additionalInfo, authUser) => (dispatch) => {
  dispatch(loadingToggleAXN(true));

  // OPENS UP A TEMPORARY PDF PAGE WITH THE TEXT LOADING
  const browserWindow = preOpenPDF();

  iduBucket
    .get("/arialUnicodeMS.txt") // FETCH FONT FILE TO USE FOR THE PDF (SUPPORTS ALMOST ALL FOREIGN CHARACTERS)
    .then((fontRes) => {
      if (!isEmptyVAL(fontRes)) {
        addCustomFontToPDF(fontRes.data, jsPDF.API, {
          fileName: "arialUnicodeMS.txt",
          name: "arial-unicode-ms",
        });
      }

      return axios.all([
        getHistoryData(additionalInfo.dzReference, "dz_transaction"),
        getBiometricScans(additionalInfo.dzReference, "dzReference"),
      ]);
    })
    .then(
      axios.spread((historyData, ocrB64Img) => {
        generatePDF(
          historyData.data,
          additionalInfo,
          ocrB64Img.data,
          authUser,
          browserWindow
        );
        dispatch(loadingToggleAXN(false));
      })
    )
    .catch((err) => {
      dispatch(loadingToggleAXN(false));
      Sentry.captureException("Unable to fetch user PII...", err);
    });
};

/* ============================================
          GENERATE PDF
   ============================================ */
const generatePDF = (
  data,
  additionalInfo,
  ocrB64Img,
  authUser,
  browserWindow
) => {
  const country = additionalInfo.countryConditional
    .split(" ")
    .join("")
    .toLowerCase();

  try {
    loggerUTL(JSON.stringify(data.Request, undefined, 4));
    loggerUTL(JSON.stringify(data.Response, undefined, 4));
    if (COUNTRIES_NOT_USE_PDF_TEMPLATE.includes(country)) {
      switch (country) {
        case "global":
          globalPDF(data, additionalInfo, browserWindow);
          break;
        case "australia":
        case "watchlistaml":
          australiaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "bangladesh":
          bangladeshPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "brazil":
          brazilPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "slovakia":
          slovakiaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "belgium":
          belgiumPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "chile":
          chilePDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "cambodia":
          cambodiaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "china":
          chinaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "hongkong(specialadministrativeregionofchina)":
          hongKongPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "india":
          indiaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "indonesia":
          indonesiaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "malaysia":
          malaysiaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "singapore":
          singaporePDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "thailand":
          thailandPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "vietnam":
          vietnamPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "philippines":
          philippinesPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "nigeria":
          nigeriaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "mexico":
          mexicoPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "unitedstates":
          unitedStatesPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "unitedkingdom":
          unitedKingdomPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "newzealand":
          newZealandPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "morocco":
          moroccoPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "germany":
          germanyPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "france":
          francePDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "southafrica":
          southAfricaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "switzerland":
          switzerlandPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "greece":
          greecePDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "poland":
          polandPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "austria":
          austriaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "argentina":
          argentinaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "peru":
          peruPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "italy":
          italyPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "czechrepublic":
          czechRepublicPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case "kenya":
          kenyaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        case 'canada':
          canadaPDF(data, additionalInfo, ocrB64Img, browserWindow);
          break;
        default:
          pdfErrorMessage(browserWindow);
          loggerUTL(
            `PDF Generation country was not found... ${country}: ${data.Request.service.join(
              ", "
            )}`
          );
          Sentry.captureException(
            `PDF Generation country was not found... ${country}: ${data.Request.service.join(
              ", "
            )}`
          );
          break;
      }
    } else {
      // pdf template
      verificationResultPDF(data, additionalInfo, ocrB64Img, browserWindow);
    }
  } catch (err) {
    // HANDLE PDF ERROR
    pdfErrorMessage(browserWindow);
    loggerUTL(err);
    // SENTRY ALERT
    Sentry.captureException(
      `PDF was not able to be generated... ${country}: ${data.Request.dataSources.join(
        ", "
      )}`
    );
  }
};

/* ======================================================
            PRE OPEN PDF
  ====================================================== */
const preOpenPDF = () => {
  // THIS IS A TEMPORARY VIEW TILL DATA IS FETCHED AND FILLS OUT THE PDF
  const doc = new jsPDF({
    orientation: "p",
    unit: "px",
    format: "a4",
    compress: true,
  });

  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(22);
  doc.setTextColor(89, 193, 111);
  doc.setFont(undefined, "bolditalic");

  doc.text(width / 2.4, 50, "Loading...");

  let browserWindow = doc.output("dataurlnewwindow", "_blank");

  return browserWindow;
};

/* ======================================================
            GET USER PII DATA V2
  ====================================================== */
export const getUserPIIDataV2AXN = (authUser, ref) => (dispatch) => {
  dispatch(loadingToggleAXN(true));
  const browserWindow = preOpenPDF();
  const additionalInfo = {};

  dzAPI
    .get(`/api/v2/transactions/user/${authUser.name}/0`)
    .then((res) => {
      const { data } = res;

      let foundSearch = "";

      foundSearch = data.find((history) => history.DZReference === ref);

      additionalInfo.username = authUser.name;
      additionalInfo.totalTransactions = foundSearch.TotalTransactions;
      additionalInfo.clientRef = foundSearch.ClientReference;
      additionalInfo.dzReference = foundSearch.DZReference;
      additionalInfo.kmsReference = foundSearch.KMSReference;
      additionalInfo.countryConditional = foundSearch.Country.toLowerCase();
      additionalInfo.country = "";

      dispatch({
        type: GET_USER_TRANSACTION_HISTORY,
        payload: data,
      });

      return axios.all([
        getHistoryData(additionalInfo.dzReference, "dz_transaction"),
        getBiometricScans(additionalInfo.dzReference, "dzReference"),
        getUnicodeMSFont(),
      ]);
    })
    .then(
      axios.spread((historyData, ocrB64Img, fontRes) => {
        addCustomFontToPDF(fontRes.data, jsPDF.API, {
          fileName: "arialUnicodeMS.txt",
          name: "arial-unicode-ms",
        });

        generatePDF(
          historyData.data,
          additionalInfo,
          ocrB64Img.data,
          authUser,
          browserWindow
        );
        dispatch(loadingToggleAXN(false));
      })
    )
    .catch((err) => {
      loggerUTL(err);
      Sentry.captureException("Unable to fetch user PII V2...", err);
    });
};

/* ======================================================
            FILTER REVERENCE 
  ====================================================== */
export const filterReferenceNumberAXN =
  (refNo, sorting, currentPage, dateStart, dateEnd) => (dispatch) => {
    refNo = refNo.split(/\s/).join(""); // TODO: CREATE A SPACE REMOVER UTL's
    const endPoint = `/api/v2/transactions/user/big?pageOrdering=${
      sorting === "kms_user" ? "true" : "false"
    }&dz_ref=${refNo}&page=${
      currentPage - 1
    }&dateStart=${dateStart}&dateEnd=${dateEnd}`;

    dispatch(loadingToggleAXN(true));

    dzAPI
      .get(endPoint)
      .then((res) => {
        const { data } = res;
        if (data.TotalTransactions != 0) {
          dispatch({
            type: GET_TOTAL_RECORD,
            payload: [data.TotalTransactions],
          });
        }
        if (!isEmptyVAL(data.TransactionRows)) {
          dispatch({
            type: FILTER_TRANSACTIONS,
            payload: data.TransactionRows,
          });
          dispatch(
            setAlertAXN(
              transUTL("translateAlertMsg.searchSuccessful"),
              "success"
            )
          );
        } else {
          let errorMsg =
            data.error === "No records found"
              ? transUTL("translateAlertMsg.noRecordsFound")
              : transUTL("translateAlertMsg.searchUnSuccessful");
          dispatch(setAlertAXN(errorMsg, "error"));
          dispatch({
            type: FILTER_TRANSACTIONS,
            payload: [],
          });
        }

        dispatch(loadingToggleAXN(false));
      })
      .catch((err) => {
        loggerUTL("ERROR...", err);
        dispatch(loadingToggleAXN(false));
        Sentry.captureException("Unable to filter by reference number...", err);
      });
  };

/* ======================================================
            UPLOAD BIOMETRIC SCANS 
  ====================================================== */
export const uploadBiometricScansAXN =
  (dzRefNo, biometrics, country) => (dispatch) => {
    const { multiDocs, selfieURI, faceMatchResults, bioTriggers } = biometrics;
    if (!isEmptyVAL(multiDocs) || !isEmptyVAL(selfieURI)) {
      const formData = new FormData();
      const biometricFlags = { selfieIsMatch: "N/A", scannedDataEdited: "N/A" };

      let selfieIsMatch = "N/A"; // DEFAULT IF FACIAL MATCHING WAS NOT PERFORMED
      if (!isEmptyVAL(faceMatchResults)) {
        if (faceMatchResults.FaceMatch) {
          selfieIsMatch = true;
        } else {
          selfieIsMatch = false;
        }
      } else {
        // FACIAL MATCHING WAS UNCLEAR
        selfieIsMatch = false;
      }

      if (!isEmptyVAL(sessionStorage.selfieURI)) {
        const selfieURI = JSON.parse(sessionStorage.selfieURI);
        const blob = dataURItoBlobUTL(selfieURI, "selfie");
        const file = blobToFileUTL(blob);
        formData.append(file.name, file);
      }

      // APPENDS NEW VALUE
      formData.append("dzReference", dzRefNo);

      /*
       * LOOP THROUGH THE ARRAY OF IDENTITY DOCUMENTS
       * CONVERT DATA URI TO FILE
       * APPEND TO FORM DATA
       */
      for (let index in multiDocs) {
        // ! TODO: ADD CORRECT NAME OF DOCUMENTS LATER
        const name = `identityDocument-${index}`;
        const blob = dataURItoBlobUTL(multiDocs[index], name);
        const file = blobToFileUTL(blob);
        formData.append(file.name, file);
      }

      // BIOMETRIC FLAGS
      if (typeof selfieIsMatch === "boolean") {
        biometricFlags.selfieIsMatch = selfieIsMatch;
      }

      if (!isEmptyVAL(sessionStorage.pdfScanDataEdited)) {
        const parsed = JSON.parse(sessionStorage.pdfScanDataEdited);
        biometricFlags.scannedDataEdited = parsed[country];
      }

      // ! TEMPORARY SOLUTION - USING THE BIOMETRIC FLAG AS A KEY NAME - ANI NEEDS TO ATTEND TO THIS ONCE AVAILABLE
      // ! EMBEDDING JSON AS NAME - e.g {"selfieIsMatch":false,"scannedDataEdited":"N/A"}: (binary) <------- REQUIRES ACTUAL IMAGE
      let biometricFlagsToStringKey = JSON.stringify(biometricFlags).replace(
        / /g,
        ""
      );
      let bioKeyTriggers = JSON.stringify(bioTriggers).replace(/ /g, "");

      // IMAGE ATTACHED IS A DUPLICATE
      const file1 = blobToFileUTL(
        dataURItoBlobUTL(logoBase64, biometricFlagsToStringKey)
      );
      const file2 = blobToFileUTL(dataURItoBlobUTL(logoBase64, bioKeyTriggers));

      formData.append(biometricFlagsToStringKey, file1);
      formData.append(bioKeyTriggers, file2);

      dzAPI
        .post("/api/v2/logging/upload-images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          loggerUTL(res);
        })
        .catch((err) => {
          loggerUTL(err);
          Sentry.captureException("Image upload error...", err);
        });
    }
  };
