const dataSourcesMX = {
  nationalID: {
    title: 'Mexico National ID',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    dateOfBirth: 'Date Of Birth',
    gender: 'Gender',
    firstName: 'First Name',
    lastName: 'Last Name',
    curp: 'Curp',
    rfc: 'RFC',
  },

  taxRegistration: {
    title: 'Mexico Tax Registration',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    dateOfBirth: 'Date Of Birth',
    gender: 'Gender',
    firstName: 'First Name',
    lastName: 'Last Name',
    rfc: 'RFC',
    curp: 'Curp',
  },
};

export default dataSourcesMX;
