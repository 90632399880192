import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const BirthCertificateAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    birthRegistrationState,
    birthRegistrationNo,
    birthCertificateNo,
    birthRegistrationDate,
    errors,
  } = formState;

  const birthRegistrationStateOptions = [
    {
      label: transUTL('translateDropdown.select'),
      value: '',
    },
    {
      label: transUTL('translateDropdown.stateAU.queensland'),
      value: 'QLD',
    },
    {
      label: transUTL('translateDropdown.stateAU.newSouthWales'),
      value: 'NSW',
    },
    {
      label: transUTL('translateDropdown.stateAU.southAustralia'),
      value: 'SA',
    },
    {
      label: transUTL('translateDropdown.stateAU.tasmania'),
      value: 'Tas',
    },
    {
      label: transUTL('translateDropdown.stateAU.victoria'),
      value: 'VIC',
    },
    {
      label: transUTL('translateDropdown.stateAU.westernAustralia'),
      value: 'WA',
    },
    {
      label: transUTL('translateDropdown.stateAU.australianCapitalTerritory'),
      value: 'ACT',
    },
    {
      label: transUTL('translateDropdown.stateAU.northernTerritory'),
      value: 'NT',
    },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: ['Australia Birth Certificate'],
      element: (
        <ui key="birthRegistrationNo" id="birthRegistrationNo">
          <h5 style={{'marginTop': '-30px'}}>{transUTL(
            'translateFormOverview.inputLabels.isRequiredCertificateNo')}
          </h5>
          <ui style={{'fontSize': '1.0rem'}}>
            <li>{transUTL(
              'translateFormOverview.inputLabels.isRequiredCertificateNo_NSW')}
            </li> 
            <li>{transUTL(
              'translateFormOverview.inputLabels.isRequiredCertificateNo_SA')}
            </li> 
            <li>{transUTL(
              'translateFormOverview.inputLabels.isRequiredCertificateNo_Others')}
            </li> 
          </ui>         
        </ui>
      ),
    },
    {
      belongsTo: ['Australia Birth Certificate'],
      element: (
        <TextFieldGroup
          key="birthCertificateNo"
          id="birthCertificateNo"
          type="text"
          name="birthCertificateNo"
          dataName="birthCertificateNoAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.birthCertificateNo'
          )}
          value={birthCertificateNo}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.birthCertificateNo'
          )}
          placeholderTrigger={
            isEmptyVAL(birthCertificateNo) ? 'input-empty' : ''
          }
          error={errors.birthCertificateNo}
          errorTrigger={errors.birthCertificateNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Birth Certificate'],
      element: (
        <TextFieldGroup
          key="birthRegistrationNo"
          id="birthRegistrationNo"
          type="text"
          name="birthRegistrationNo"
          dataName="birthRegistrationNoAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.birthRegistrationNo'
          )}
          value={birthRegistrationNo}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.birthRegistrationNo'
          )}
          placeholderTrigger={
            isEmptyVAL(birthRegistrationNo) ? 'input-empty' : ''
          }
          error={errors.birthRegistrationNo}
          errorTrigger={errors.birthRegistrationNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Birth Certificate'],
      element: (
        <SelectListGroup
          key="birthRegistrationState"
          id="birthRegistrationState"
          name="birthRegistrationState"
          dataName="birthRegistrationStateAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.birthRegistrationState'
          )}
          value={birthRegistrationState}
          placeholderTrigger={
            isEmptyVAL(birthRegistrationState) ? 'input-empty' : ''
          }
          options={birthRegistrationStateOptions}
          error={errors.birthRegistrationState}
          errorTrigger={errors.birthRegistrationState ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Birth Certificate'],
      element: (
        <TextFieldGroup
          key="birthRegistrationDate"
          id="birthRegistrationDate"
          type="date"
          name="birthRegistrationDate"
          dataName="birthRegistrationDateAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.birthRegistrationDate'
          )}
          value={birthRegistrationDate}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.birthRegistrationDate'
          )}
          placeholderTrigger={
            isEmptyVAL(birthRegistrationDate) ? 'input-empty' : ''
          }
          max="9999-12-31"
          error={errors.birthRegistrationDate}
          errorTrigger={errors.birthRegistrationDate ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

BirthCertificateAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default BirthCertificateAustralia;
