import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD NIGERIA API ERRORS TO INPUT FORM
const feedbackNG = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    /* ============================================
              IDENTITY DETAILS
       ============================================ */
    if (findErrorUTL(errors, 'nationalIdNo')) {
      inputError.nationalIdNo = `National ID number must be${
        errors.find((item) => item.includes('nationalIdNo')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'bankVerificationNo')) {
      inputError.bankVerificationNo = `Bank verification number ${
        errors.find((item) => item.includes('bankVerificationNo')).split(':')[1]
      }`;
    }

    // /* ============================================
    //           PASSPORT
    //    ============================================ */
    // if (findErrorUTL(errors, 'passportNo')) {
    //   inputError.passportNo = `Passport number ${
    //     errors.find((item) => item.includes('passportNo')).split(':')[1]
    //   }`;
    // }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE NIGERIA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackNG;
