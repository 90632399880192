const adjustImageSize = (height, width, ratioResize) => {

  if (width > 800) {
    width = width / ratioResize;
    height = height / ratioResize;
  }

  if (width > 500) {
    width = width / 1.2;
    height = height / 1.2;
  }

  if (width < 350) {
    width = width + 80;
    height = height + 80;
  }

  return {width, height}
}

export default adjustImageSize;