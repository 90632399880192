import React from 'react';
import PropTypes from 'prop-types';

const SelectListGroup = ({
  id,
  name,
  dataName,
  labelClass,
  label,
  value,
  placeholderTrigger,
  options,
  error,
  errorTrigger,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const selectOptions = options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <div className="form-group">
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      <select
        onBlur={handleOnBlur}
        id={id}
        value={value}
        className={`${errorTrigger} ${placeholderTrigger}`}
        name={name}
        data-name={dataName}
        onChange={handleOnChange}
        onKeyPress={handleEnterKeyTrigger}
      >
        {selectOptions}
      </select>

      {error && (
        <small className="form-group__error-text">
          <span className="material-icons">error</span>
          {error}
        </small>
      )}
    </div>
  );
};

SelectListGroup.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholderTrigger: PropTypes.string,
  options: PropTypes.array,
  error: PropTypes.string,
  errorTrigger: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleEnterKeyTrigger: PropTypes.func,
};

export default SelectListGroup;
