import { SET_REGION, RESET_REGION } from '../../actions/types';

const initialState = '';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REGION:
      return action.payload;
    case RESET_REGION:
      return initialState;
    default:
      return state;
  }
}
