import {
  SET_PRICE_TIER_SELECTED_DATA_SOURCE,
  SET_PRICE_TIER_SELECTED_COUNTRY,
} from '../../actions/types';

import priceTierJSON from './json/Latest price tier at 10-6-2020';

const initialState = {
  selectedCountry: '',
  selectedDataSource: '',
  priceTier: priceTierJSON,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRICE_TIER_SELECTED_DATA_SOURCE:
      return {
        ...state,
        selectedDataSource: action.payload,
      };
    case SET_PRICE_TIER_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    default:
      return state;
  }
}
