import loggerUTL from "./loggerUTL";

const downsizeUploadedImagesUTL = (originalDataURI, width, height) => {
  loggerUTL('origenal datauri: ', originalDataURI);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext("2d");
  const MAX_LENGTH = 1000;
  let ratio = 1;

  const FILE_TYPE = 'image/jpeg'; // jpeg type file can be compressed by default
  const img = new Image();
  img.src = originalDataURI;  
    if (!isNaN(width / height))
      ratio = width / height;

    if (width > height) {
      if (width > MAX_LENGTH) {
        canvas.width = MAX_LENGTH;
        canvas.height = MAX_LENGTH / ratio;
      }
    } else {
      if (height > MAX_LENGTH) {
        canvas.width = MAX_LENGTH * ratio;
        canvas.height = MAX_LENGTH;
      }
    }

    if (canvas.width === 300 && canvas.height === 150) {
      canvas.width = width;
      canvas.height = height;
    }

    loggerUTL(`img width: ${width}, height: ${height}`);
    loggerUTL(`width / height = ${ratio}`);
    loggerUTL(`canvas width: ${canvas.width}, height: ${canvas.height}`);

    // Copy the image contents to the canvas
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const downsizedDataURI = canvas.toDataURL(FILE_TYPE)
    loggerUTL('Downsized DataURL ', downsizedDataURI)

  return downsizedDataURI;
}

export default downsizeUploadedImagesUTL;