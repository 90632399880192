// HELPERS
import title from "../../../helpers/title";
import infoMsg from "../../../helpers/infoMsg";

// TABLE HEAD
const tableHead = (Response, doc, docStyles) => {
  // FIRST TABLE (HEAD)
  const columnsHead = [
    { title: "", dataKey: "col1" },
    {
      title: "",
      dataKey: "col2"
    }
  ];

  const rowsHead = [
    {
      col1: "Safe Harbour",
      col2: `${Response.safeHarbour ? "Pass" : "Fail"}`
    }
  ];

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable(columnsHead, rowsHead, {
    didParseCell: data => {
      data.cell.styles.fillColor = [255, 255, 255];
      if (data.cell.raw === "Pass") {
        data.cell.styles.textColor = docStyles.colorGreen;
        data.cell.styles.fontStyle = "bold";
      } else if (data.cell.raw === "Fail") {
        data.cell.styles.textColor = [185, 45, 0];
        data.cell.styles.fontStyle = "bold";
      }
    },
    showHead: "never",
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize
      }
    },
    startY: finalY
  });
};

// TABLE BODY
const tableBody = (Response, doc, docStyles) => {
  // SECOND TABLE (BODY)
  const columnsBody = [
    { title: "", dataKey: "col1" },
    {
      title: "",
      dataKey: "col2"
    }
  ];
  const rowsBody = [];

  const phone = Response.serviceResponses["Canada Phone"];
  const watchlistAML = Response.serviceResponses["Watchlist AML"];

  if (phone) {
    rowsBody.push({
      col1: "Canada Phone",
      col2: phone.safeHarbourScore
    });
  }
  if (watchlistAML) {
    rowsBody.push({
      col1: "Global Watchlist AML",
      col2: watchlistAML.safeHarbourScore
    });
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.separation - 5;
  doc.autoTable(columnsBody, rowsBody, {
    didParseCell: data => {
      data.cell.styles.fillColor = [255, 255, 255];
      if (data.cell.raw === "Pass") {
        data.cell.styles.textColor = docStyles.color;
        data.cell.styles.fontStyle = "bold";
      } else if (data.cell.raw === "Fail") {
        data.cell.styles.textColor = docStyles.colorRed;
        data.cell.styles.fontStyle = "bold";
      }
    },
    showHead: "never",
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize
      }
    },
    startY: finalY
  });
};

const knowYourCustomers = (doc, data, docStyles) => {
  const { Response } = data;

  tableHead(Response, doc, docStyles);
  tableBody(Response, doc, docStyles);

  infoMsg(
    doc,
    docStyles,
    [{ title: "", dataKey: "col1" }],
    [
      {
        col1:
          "Status: 0 = Identity verified and all fields verified | 1 = Identity verified but not all fields verified | 2 = Identity not verified | 3 = data source error"
      }
    ],
    docStyles.separation
  );

  infoMsg(
    doc,
    docStyles,
    [{ title: "", dataKey: "col1" }],
    [
      {
        col1:
          "Safe Harbour Scoring: M1 = Match on Name, DOB, and Address M2 = Match on Name and DOB N1 = Match on Name and Address None = No Match"
      }
    ],
    4
  );
};

const iduVerificationResults = (doc, data, docStyles) => {
  title(
    doc,
    docStyles,
    "IDU Verification Results",
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );
  title(
    doc,
    docStyles,
    "Know Your Customer",
    docStyles.headers.secondary,
    docStyles.separation - 10
  );

  knowYourCustomers(doc, data, docStyles);
};
export default iduVerificationResults;
