import * as Sentry from '@sentry/browser';
import moment from 'moment';
import axios from 'axios';

// ACTION TYPES
import { SET_CURRENT_USER, USER_LOGOUT } from '../types';

// REDUX ACTIONS
import { loadingToggleAXN } from '../loading/loadingAction';
import { setAlertAXN } from '../alert/alertActions';
// import { loginFeedbackError } from '../errorController/errorControllerActions';

// API INITIALIZE
import { dzAPI } from '../../api/init';

// MODIFY AUTH RESPONSE
import modifiedAuth from './helpers/modifiedAuth/modifiedAuth';
import { activeDataSourcesArray } from './helpers/activeDataSources/activeDataSources';

// EXPIRY TIME
import { EXP } from '../../dev';

// VALIDATION
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import setAuthTokenUTL from '../../utils/setAuthTokenUTL';
import { transUTL } from '../../utils/transUTL';
import loggerUTL from '../../utils/loggerUTL';

// LOGIN USER
// 1.) `/api/v2/auth/sign_in` - CHECKS CREDENTIALS AND PROVIDES SESSION TOKEN
// 2.) `/api/v2/auth/check` - CHECK / PROVIDES USERS PERMISSIONS
export const loginUserAXN = (userDataDZ) => (dispatch) => {
  loggerUTL('LOGIN...', userDataDZ);
  dispatch(loadingToggleAXN(true));

  let dzAuth = {};
  dzAPI
    .post('/api/v2/auth/sign_in', userDataDZ)
    .catch (function (error) { 
      if (error.response) {
        dispatch(loadingToggleAXN(false));
        return dispatch(
          setAlertAXN(
            transUTL('translateAlertMsg.authIncorrectCredentials'),
            'error'
          )
        );
        }})
    .then((res) => {
      const dzRes = res.data; // RESULTS
      // SET AUTH HEADERS TO BE USED ON EVERY AXIOS REQUEST
      setAuthTokenUTL({
        dz: { sessionToken: dzRes.sessionToken },
      });

      // CHECK FOR ERROR
      if (!isEmptyVAL(dzRes.error)) {
        Sentry.captureException(`${dzRes.error}`); // NOTIFIES SENTRY IF REQUEST FAILS
      }

      dzAuth = dzRes;

      return axios.all([
        dzAPI.get('/api/v2/admin/check'),
        dzAPI.get('/api/v2/admin/sources'),
      ]);
    })
    .then(
      axios.spread((check, sources) => {
        const { dataSources } = sources.data;
        let { sessionToken } = dzAuth;
        let { permissions } = check.data;
        if (permissions === null) {
          permissions = [];
        }

        // TODO:
        // Below is hacky parsing to ensure frontend doesn't break. 
        // This is part of a bigger problem of no parsing of the API responses.
        // Parsing NEEDS TO BE IMPLEMENTED in the future to ensure API changes don't
        // result in a broken frontend.
        let user = {
          isAdmin: check.data.userIsAdmin,
          name: check.data.user,
          organisationName: check.data.org,
          organisationId: check.data.orgId,
        }

        const exp = moment().add(EXP, 'hours').format();
        const userPermissions = []; // TODO: FIX TYPE
        let watchlist = false;

        // USER IP TODO: ADD USER IP
        //  const userIP = sessionStorage.userIP;

        // MODIFY USERS PERMISSIONS
        if (permissions.includes('*') && permissions.length === 1) {
          // USERS WITH `*` HAS ACCESS TO ALL DATA SOURCES / COUNTRIES
          activeDataSourcesArray.push('Global OCR');
          userPermissions.push(...modifiedAuth(activeDataSourcesArray));
          watchlist = true;
        } else {
          // USER PERMISSIONS
          userPermissions.push(...modifiedAuth(permissions));
          if (permissions.includes('Watchlist AML')) {
            watchlist = true;
          }
        }

        // SET TOKEN TO SESSION STORAGE
        sessionStorage.setItem(
          'user',
          JSON.stringify({
            ...user,
            sessionToken,
            countries: userPermissions,
            watchlist,
            activeDataSources: activeDataSourcesArray,
            backendDataSources: dataSources,
            exp,
          })
        );

        // SET CURRENT USER
        dispatch(
          setCurrentUserAXN({
            ...user,
            sessionToken,
            countries: userPermissions,
            activeDataSources: activeDataSourcesArray,
            backendDataSources: dataSources,
            exp,
            watchlist,
          })
        );

        dispatch(loadingToggleAXN(false));
      })
    )
    .catch((err) => {
      loggerUTL('ERROR...', err);
    });
};

// SET LOGGED IN USER
export const setCurrentUserAXN = (sessionToken) => {
  loggerUTL('SET CURRENT USER...');
  return {
    type: SET_CURRENT_USER,
    payload: sessionToken,
  };
};

// LOGOUT USER
export const logoutUserAXN = () => (dispatch) => {
  loggerUTL('LOGGING OUT USER...');

  // REMOVE USER INFO
  sessionStorage.clear();
  localStorage.clear();

  // CLEAR AUTH HEADERS
  setAuthTokenUTL(false);

  // CLEAR REDUX TO INITIAL STATE
  dispatch({ type: USER_LOGOUT });
};
