import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import { setAlertAXN } from '../../../../actions/alert/alertActions';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';

// HELPERS
import initialStateMY from './helpers/initialStateMY';
import consentCheckerMY from './helpers/consentCheckerMY';
import whitelistedInputDataMY from './helpers/whitelistedInputDataMY';

// VALIDATIONS
import malaysiaVAL from '../../../../validations/inputFields/malaysiaVAL';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationMalaysia from './inputForms/PersonalInformationMalaysia';
import IdentityDetailsMalaysia from './inputForms/IdentityDetailsMalaysia';
import CurrentResidentialAddressMalaysia from './inputForms/CurrentResidentialAddressMalaysia';
import TermsAndConditionsMalaysia from './inputForms/TermsAndConditionsMalaysia';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import rmSpaceUTL from '../../../../utils/rmSpaceUTL';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import isScannedDataEditedUTL from "../../../../utils/isScannedDataEditedUTL";

const MalaysiaForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateMY);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.malaysiaDataSources
  );

  // LOAD FORM DATA
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.malaysiaFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.malaysiaFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH };

    // VALIDATE INPUT
    const { inputErrors, isValid } = malaysiaVAL(
      inputData,
      selectedDataSources
    );
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // CONSENT CHECKER
    const { isConsent, consentObtained } = consentCheckerMY(
      selectedDataSources,
      inputData
    );

    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // DATE FORMAT
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELIST DATA
    const whitelisted = whitelistedInputDataMY(inputData, selectedDataSources);

    // ADDRESS MANIPULATION
    const addressElement1 = rmSpaceUTL(
      `${inputData.streetNo} ${inputData.streetName}`
    );
    const addressElement3 = rmSpaceUTL(inputData.city);
    const addressElement4 = rmSpaceUTL(inputData.state);
    const addressElement5 = rmSpaceUTL(inputData.postCode);

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    const service = [...selectedDataSources];

    // WATCHLIST AML REQUIRES `firstName` & `lastName`
    // WHERE AS THE OTHER VIETNAM SERVICES REQUIRES `fullName`
    const names = `${inputData.firstName} ${inputData.middleName} ${inputData.lastName}`.trim();
    const fullName = rmSpaceUTL(names);

    // JSON BUILDER
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      fullName,
      consentObtained,
      addressElement1,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const {
      errors,
      nationalIdConsentObtained,
      creditBureauConsentObtained,
      ...filteredFormData
    } = userInputSTH;

    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));

    // Check if scanned data from a doc is edited 
    const { ocrResponseData } = biometricsRXS;
    isScannedDataEditedUTL(countryRXS, ocrResponseData, filteredFormData);

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');
    const { errors, ...reset } = initialStateMY;
    setUserInputSTH(initialStateMY);
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    loggerUTL('CHECKBOX...');
    const {
      nationalIdConsentObtained,
      creditBureauConsentObtained,
    } = userInputSTH;

    let creditBureauConsent = false,
      nationalIdConsent = false;

    if (selectedDataSources.includes('Malaysia Credit Bureau')) {
      creditBureauConsent = !creditBureauConsentObtained;
    }
    if (selectedDataSources.includes('Malaysia National ID')) {
      nationalIdConsent = !nationalIdConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      creditBureauConsentObtained: creditBureauConsent,
      nationalIdConsentObtained: nationalIdConsent,
    });
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        // const blob = dataURItoBlobUTL(result, fileName); // SAVE TO STATE AS A BLOB FILE INSTEAD OF FILE TYPE
        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT (ID CARD)
          saveIdentityDocumentsAXN([result]);
          bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          saveSelfieAXN(result);
          bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (
    selectedDataSources.includes('Malaysia Credit Bureau') ||
    selectedDataSources.includes('Malaysia National ID')
  ) {
  } else {
    conditionalClientRef.push(...['Watchlist AML']);
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'Malaysia National ID',
        'Malaysia Credit Bureau',
        'Watchlist AML',
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationMalaysia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <FormController
          key="identityDetails"
          title={transUTL('translateFormOverview.formHeaders.identityDetails')}
          inputForm={
            <IdentityDetailsMalaysia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressMalaysia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            'translateFormOverview.formHeaders.termsAndConditions'
          )}
          inputForm={
            <TermsAndConditionsMalaysia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

MalaysiaForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(MalaysiaForms));
