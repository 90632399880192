const consentCheckerUS = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // // USER CONSENT CHECK
  // if (dataSources.includes('Singapore Credit Bureau')) {
  //   // CONSENT COLLECTOR
  //   consentObtained['Singapore Credit Bureau'] = true;

  //   if (!inputData.creditBureauConsentObtained) {
  //     return { isConsent: false };
  //   }
  // }

  return { consentObtained, isConsent: true };
};

export default consentCheckerUS;
