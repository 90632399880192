// PRINTS TIME STAMP ON PDF
const timeStamp = (doc, data, docStyles) => {
  const { CreatedAt } = data;

  const date = CreatedAt.split('.')[0].split('T')[0]; // YYYY/MM/DD
  const time = CreatedAt.split('.')[0].split('T')[1]; // HH:MM:SS
  const universalTime = 'UTC';

  const formattedTime = `${date} ${time} ${universalTime}`;

  doc.setFontSize(7.85);
  doc.setTextColor(docStyles.colorText);
  doc.setFont(docStyles.fontFamily.time, 'normal');
  doc.text(formattedTime, docStyles.startX, 25);
};

export default timeStamp;
