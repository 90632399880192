import React from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressIndonesia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { streetName, village, district, city, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['Indonesia Resident Identity Card'],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameID"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetNameIDN')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputID.streetNameIDN')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Indonesia Resident Identity Card'],
      element: (
        <TextFieldGroup
          key="village"
          id="village"
          type="text"
          name="village"
          dataName="villageID"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.villageIDN')}
          value={village}
          placeholder={transUTL('translatePlaceholder.inputID.villageIDN')}
          placeholderTrigger={isEmptyVAL(village) ? 'input-empty' : ''}
          error={errors.village}
          errorTrigger={errors.village ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Indonesia Resident Identity Card'],
      element: (
        <TextFieldGroup
          key="district"
          id="district"
          type="text"
          name="district"
          dataName="districtID"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.districtIDN')}
          value={district}
          placeholder={transUTL('translatePlaceholder.inputID.districtIDN')}
          placeholderTrigger={isEmptyVAL(district) ? 'input-empty' : ''}
          error={errors.district}
          errorTrigger={errors.district ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Indonesia Resident Identity Card'],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityID"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.cityIDN')}
          value={city}
          placeholder={transUTL('translatePlaceholder.inputID.cityIDN')}
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CurrentResidentialAddressIndonesia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressIndonesia;
