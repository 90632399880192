const dataSourcesVN = {
  vietnamConsumerData: {
    title: 'Data Pengguna Vietnam',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    fullName: 'Nama Disahkan',
    email: 'E-mel Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    city: 'Bandar Disahkan',
    addressMatchScore: 'Skor Pertandingan Alamat',
    address: 'Alamat',
  },
  vietnamNationalID: {
    title: 'ID Nasional Vietnam',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    fullName: 'Nama Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    idNo: 'Nombor ID Disahkan',
    addressMatchScore: 'Skor Pertandingan Alamat',
    addressVerified: 'Alamat Disahkan',
    address: 'Alamat',
    gender: 'Jantina Disahkan',
  },
  vietnamSocialSecurity: {
    title: 'Keselamatan Sosial Vietnam',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    fullName: 'Nama Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    idNo: 'Nombor ID Disahkan',
    addressMatchScore: 'Skor Pertandingan Alamat',
    addressVerified: 'Alamat Disahkan',
    address: 'Alamat',
    gender: 'Jantina Disahkan',
  },
};

export default dataSourcesVN;
