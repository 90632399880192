import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackBE = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  
  const inputError = {
      personalInformation: {},
      phoneNo: {}
  };

  try {
    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    /* ============================================
            PHONE NO
      ============================================ */
    if (findErrorUTL(errors, 'phoneNo')) {
      inputError.phoneNo.phoneNo = `Phone number should be ${
        errors.find((item) => item.includes('phoneNo')).split(':')[1]
      }`;
    }

  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE BELGIUM VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackBE;