const translateDocumentLibrary = {
  gdprCompliance: 'Data Zoo GDPR Compliance',
  securityPolicy: 'Data Zoo Information Security Policy',
  maintenance: 'Support & Maintenance',
  lastUpdated: 'Last updated',
  documentLibrary: 'Data Zoo Document Library',
  viewDocuments: 'View Document',
  documents: 'documents',
};

export default translateDocumentLibrary;
