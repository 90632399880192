const translateAlertMsg = {
  correctCardType: '请选择正确的卡类型',
  timeout: '超过超时时间。',
  pleaseSelectCountry: '请选择一个国家',
  pleaseSelectDataSource: '必须选择数据源',
  consent: '您必须同意条款和条件',
  selectCompleteAddress: '请选择自动填写的地址',
  didNotPickUpStreetNo: '抱歉，没有接电话号码，需要手动输入',
  didNotPickUpStreetName: '抱歉，未选择街道名称，需要手动输入',
  didNotPickUpStreetSuburb: '操作，没有接郊区，需要手动输入',
  didNotPickUpPostCode: '抱歉，未提取邮政编码，需要手动输入',
  uploadPhoto: '请拍照或上传文件',
  selectCardType: '必须选择卡类型',
  acceptableFileType: '可接受的文件类型为：jpeg，jpg，bmp和png。',
  thereIsNoImage: '没有上传图片文件',
  providePhoneNumber: '请提供您的电话号码',
  providePinCode: '请提供密码',
  authIncorrectCredentials: '嗯，这不是正确的用户名和密码组合。',
  contactSupport: '搜索过程中发生错误，请稍后重试。',

  error: '错误',
  success: '成功',
  warning: '警告',
  inputErrors: '请正确填写表格',
  emptyInput: '至少需要 3 个输入。需要名字或姓氏',
  addressInput: '请求必须包括街道编号和名称以及郊区，城市或邮政编码',
  passwordAdviceMsg: '请提供信息',
  passwordAdviceReceiver: '请提供接收器',
  msgSent: '密码分配成功',
  searchSuccessful: '搜索成功',
  searchUnSuccessful: '搜索失败',
  sessionTimeout: '会话超时，请重新登录',
  pdfError: '当前无法为选定的数据源生成PDF',
  completeDocumentScanFirst: '您必须扫描身分证明文件',
  max3: '最多3个档案',
  max1: '最多1个档案',
  uploadIdentityDocs: '请上传身份证件',
  compareIDtoSelfie: '请确保采取自拍照并选择您要比较的身份证件',
  takeASelfie: '请自拍照或上传自拍照',
  dateSelection: '请选择日期',
  clientSelection: '请选择客户',
  inputRequired: '搜索所需的输入',
  serverTimeOut: '服务器超时。',
  collectiveSize: '文件的总大小太大',
  uploadError: '文件的总大小太大',
  expiredDocument: '扫描的文档已过期或可能已过期，并可能导致无法验证。',
  notMatchSelectedCountry: '提供的身份证明文件与所选国家/地区不匹配。',
  inputMappingError: '文件扫描期间发生错误，请稍后再试。',
  errTextMsg: '发送文本错误...请确保电话号码格式正确（+61 ...）',
  daonOcrNotAvailable: '所选国家/地区无法使用文件扫描',
  scanResultsEmpty: '上传的文档未返回任何数据...',
  unableToReadScan: '无法扫描和识别文档',
  networkError: '网络错误...请稍后重试',
  documentType: '请选择文件类型',
  notSuportingUploadedFileFormat: "仅支持以下格式 '.jpg'，'.jpeg'，'.gif'，'.png'，'.svg'。",
  fileTooLargeToUpload: '文件需在9MB以内',
  noRecordsFound: '未搜索到任何记录'
};

export default translateAlertMsg;
