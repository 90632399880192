// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import logger from '../../../../../utils/loggerUTL';

const whitelistedInputDataCN = (inputData, dataSources) => {
  let idVerificationData,
    nationalPhoneData,
    bankCardData,
    nationalIDData,
    passportVerificationData,
    watchlistAMLData;

  /* ============================================
              CHINA ID VERIFICATION || CHINA DRIVER LICENSE
   ============================================ */
  if (dataSources.includes('China ID Verification') || dataSources.includes('China Driver License')) {
    if (dataSources.includes('China ID Verification')) logger('WHITELISTING CHINA ID VERIFICATION...');
    if (dataSources.includes('China Driver License')) logger('WHITELISTING CHINA DRIVER LICENSE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'idCardNo',
      'reportingReference',
    ]);

    idVerificationData = { ...whitelisted };
  }

  if (dataSources.includes('China Phone')) {
    logger('WHITELISTING CHINA PHONE...');
    const whitelisted = whitelistKeys(inputData, [
      'reportingReference',
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'idCardNo',
      'phoneNo',
      'nationalPhoneConsentObtained',
    ]);

    nationalPhoneData = { ...whitelisted };
  }

  if (dataSources.includes('China Bank Card')) {
    logger('WHITELISTING CHINA BANK CARD...');
    const whitelisted = whitelistKeys(inputData, [
      'reportingReference',
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'idCardNo',
      'bankCardNo',
      'bankCardConsentObtained',
    ]);

    bankCardData = { ...whitelisted };
  }

  if (dataSources.includes('China Passport Verification')) {
    logger('WHITELISTING CHINESE PASSPORT NUMBER...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'dateOfBirth',
      'passportNo',
      'idCardNo',
      'reportingReference',
    ]);

    passportVerificationData = { ...whitelisted };
  }

  if (dataSources.includes('Watchlist AML')) {
    logger('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...idVerificationData,
    ...nationalPhoneData,
    ...nationalIDData,
    ...bankCardData,
    ...passportVerificationData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataCN;
