import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../components/common/textFieldGroup/TextFieldGroup';
import CheckBoxGroup from "../../../../components/common/checkBoxGroup/CheckBoxGroup";

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../utils/transUTL';
import { outputMasterUTL } from '../../../../utils/outputMasterUTL';

const PersonalInformation = ({
  formState,
  includeWatchList,
  includeSafeguardSearch,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleToggleIncludeWatchlist,
  handleToggleIncludeSafeguardSearch
}) => {
  const {
    firstName,
    middleName,
    lastName,
    yearOfBirth,
    barangay,
    city,
    province,
    postCode,
    orgId,
    emailID,
    hitTarget,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="firstName"
          id="firstName"
          type="text"
          name="firstName"
          dataName="firstName"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.firstName')}
          value={firstName}
          placeholder="Lania"
          placeholderTrigger={isEmptyVAL(firstName) ? 'input-empty' : ''}
          error={errors.firstName}
          errorTrigger={errors.firstName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="middleName"
          id="middleName"
          type="text"
          name="middleName"
          dataName="middleName"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.middleName')}
          value={middleName}
          placeholder="G"
          placeholderTrigger={isEmptyVAL(middleName) ? 'input-empty' : ''}
          error={errors.middleName}
          errorTrigger={errors.middleName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="lastName"
          id="lastName"
          type="text"
          name="lastName"
          dataName="lastName"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.lastName')}
          value={lastName}
          placeholder="Avida"
          placeholderTrigger={isEmptyVAL(lastName) ? 'input-empty' : ''}
          error={errors.lastName}
          errorTrigger={errors.lastName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="yearOfBirth"
          id="yearOfBirth"
          type="text"
          name="yearOfBirth"
          dataName="yearOfBirth"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.yearOfBirth')}
          value={yearOfBirth}
          placeholder='yyyy'
          placeholderTrigger={isEmptyVAL(yearOfBirth) ? 'input-empty' : ''}
          error={errors.yearOfBirth}
          errorTrigger={errors.yearOfBirth ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="barangay"
          id="barangay"
          type="text"
          name="barangay"
          dataName="barangay"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.barangay')}
          value={barangay}
          placeholder='Alicante'
          placeholderTrigger={isEmptyVAL(barangay) ? 'input-empty' : ''}
          error={errors.barangay}
          errorTrigger={errors.barangay ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="city"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.city')}
          value={city}
          placeholder='Enrique B Magalona'
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="province"
          id="province"
          type="text"
          name="province"
          dataName="province"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.province')}
          value={province}
          placeholder='Negros Occidental'
          placeholderTrigger={isEmptyVAL(province) ? 'input-empty' : ''}
          error={errors.province}
          errorTrigger={errors.province ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCode"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder='1234'
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="emailID"
          id="emailID"
          type="text"
          name="emailID"
          dataName="emailID"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.emailID')}
          value={emailID}
          placeholder='jsmith@datazoo.co.nz'
          placeholderTrigger={isEmptyVAL(emailID) ? 'input-empty' : ''}
          error={errors.emailID}
          errorTrigger={errors.emailID ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Philippines Suspicious Activity'
      ],
      element: (
        <TextFieldGroup
          key="hitTarget"
          id="hitTarget"
          type="text"
          name="hitTarget"
          dataName="hitTarget"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.hitTarget')}
          value={hitTarget}
          placeholder='mongoDB'
          placeholderTrigger={isEmptyVAL(hitTarget) ? 'input-empty' : ''}
          error={errors.hitTarget}
          errorTrigger={errors.hitTarget ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {inputElements.map(e => e.element)}

      <div className="form-overview__terms">
        <CheckBoxGroup
          key="includeWatchList"
          checkboxBool={includeWatchList}
          label={transUTL('translateFormOverview.includeWatchList')}
          handleToggleCheckbox={() => handleToggleIncludeWatchlist(includeWatchList)} />

        <CheckBoxGroup
          key="includeSafeguardSearch"
          checkboxBool={includeSafeguardSearch}
          label={transUTL('translateFormOverview.includeSafeguardSearch')}
          handleToggleCheckbox={() => handleToggleIncludeSafeguardSearch(includeSafeguardSearch)} />
      </div>
    </div>
  );
};

PersonalInformation.propTypes = {
  formState: PropTypes.object.isRequired,
  // selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default PersonalInformation;
