import {
  SAVE_AUSTRALIA_DATA_SOURCES,
  SAVE_BRAZIL_DATA_SOURCES,
  SAVE_CHINA_DATA_SOURCES,
  SAVE_MALAYSIA_DATA_SOURCES,
  SAVE_NEW_ZEALAND_DATA_SOURCES,
  SAVE_PHILIPPINES_DATA_SOURCES,
  SAVE_INDIA_DATA_SOURCES,
  SAVE_INDONESIA_DATA_SOURCES,
  SAVE_CAMBODIA_DATA_SOURCES,
  SAVE_VIETNAM_DATA_SOURCES,
  SAVE_NIGERIA_DATA_SOURCES,
  SAVE_HONG_KONG_DATA_SOURCES,
  SAVE_FRANCE_DATA_SOURCES,
  SAVE_SINGAPORE_DATA_SOURCES,
  SAVE_UNITED_STATES_DATA_SOURCES,
  SAVE_MEXICO_DATA_SOURCES,
  SAVE_GERMANY_DATA_SOURCES,
  SAVE_ITALY_DATA_SOURCES,
  SAVE_SPAIN_DATA_SOURCES,
  SAVE_MULTI_SERVICE_DATA_SOURCE,
  SAVE_WATCHLISTAML_DATA_SOURCES,
  SAVE_FIND_IDU_DATA_SOURCE,
  // DIGITAL_ID_SAFEGUARD_DATA_SOURCE,
  RESET_DATA_SOURCES,
} from "../../actions/types";

const initialState = {
  australiaDataSources: {
    "Australia Visa Entitlement": false,
    "Australia Driver Licence": false,
    "Australia Passport": false,
    "Australia Medicare Card": false,
    "Australia Visa": false,
    "Australia Centrelink Card": false,
    "Australia Birth Certificate": false,
    "Australia Residential": false,
    "Australia Residential Lookup": false,
    "Australia Property Ownership": false,
    "Australia Credit Bureau": false,
    "Australia Citizens": false,
    "Australia Payroll": false,
    "Australia Superannuation": false,
    "Australia Death Check": false,
    "Watchlist AML": false,
  },

  brazilDataSources: {
    "Brazil National ID": false,
    "Watchlist AML": false,
  },

  chinaDataSources: {
    "China Phone": false,
    "China ID Verification": false,
    "China ID Verification Alt": false,
    "China ID Verification Alt 2": false,
    "China ID OCR": false,
    "China Bank Card": false,
    "China Phone Alt": false,
    "China Driver License": false,
    "China Passport": false,
    "Watchlist AML": false,
  },

  malaysiaDataSources: {
    "Malaysia Credit Bureau": false,
    "Malaysia National ID": false,
    "Watchlist AML": false,
  },

  globalAllDataSources: {
    // APAC
    japan: {
      Japan: false,
      "Watchlist AML": false,
    },
    hongkong: {
      "Hong Kong": false,
      "Watchlist AML": false,
    },
    thailand: {
      Thailand: false,
      "Watchlist AML": false,
    },

    // AFRICA
    southafrica: {
      "South Africa": false,
      "Watchlist AML": false,
    },
    morocco: {
      morocco: false,
      "Watchlist AML": false,
    },
    kenya: {
      Kenya: false,
      "Watchlist AML": false,
    },

    // EUROPE
    austria: {
      austria: false,
      "Watchlist AML": false,
    },
    belgium: {
      belgium: false,
      "Watchlist AML": false,
    },
    denmark: {
      denmark: false,
      "Watchlist AML": false,
    },
    finland: {
      finland: false,
      "Watchlist AML": false,
    },
    france: {
      france: false,
      "Watchlist AML": false,
    },
    germany: {
      germany: false,
      "Watchlist AML": false,
    },
    unitedkingdom: {
      unitedkingdom: false,
      "Watchlist AML": false,
    },
    ireland: {
      ireland: false,
      "Watchlist AML": false,
    },
    italy: {
      italy: false,
      "Italy Credit Bureau": false,
      "Watchlist AML": false,
    },
    czechrepublic: {
      czechrepublic: false,
      "Czech Republic Residential": false,
      "Watchlist AML": false,
    },
    luxembourg: {
      luxembourg: false,
      "Watchlist AML": false,
    },
    netherlands: {
      netherlands: false,
      "Watchlist AML": false,
    },
    norway: {
      norway: false,
      "Watchlist AML": false,
    },
    portugal: {
      portugal: false,
      "Watchlist AML": false,
    },
    russia: {
      russia: false,
      "Watchlist AML": false,
    },
    spain: {
      spain: false,
      "Watchlist AML": false,
    },
    slovakia: {
      slovakia: false,
      "Watchlist AML": false,
    },
    sweden: {
      sweden: false,
      "Watchlist AML": false,
    },
    switzerland: {
      switzerland: false,
      "Watchlist AML": false,
    },

    // MIDDLE EAST
    bahrain: {
      bahrain: false,
      "Watchlist AML": false,
    },
    jordan: {
      jordan: false,
      "Watchlist AML": false,
    },
    kuwait: {
      kuwait: false,
      "Watchlist AML": false,
    },
    lebanon: {
      lebanon: false,
      "Watchlist AML": false,
    },
    oman: {
      oman: false,
      "Watchlist AML": false,
    },
    saudiarabia: {
      "Saudi Arabia": false,
      "Watchlist AML": false,
    },
    turkey: {
      turkey: false,
      "Watchlist AML": false,
    },
    unitedarabemirates: {
      "United Arab Emirates": false,
      "Watchlist AML": false,
    },

    // THE AMERICAS
    peru: {
      peru: false,
      "Peru National ID": false,
      "Peru Residential": false,
      "Peru Tax Registration": false,
      "Watchlist AML": false,
    },
    argentina: {
      argentina: false,
      "Watchlist AML": false,
    },
    canada: {
      canada: false,
      "Watchlist AML": false,
    },
    mexico: {
      mexico: false,
      "Watchlist AML": false,
    },
    chile: {
      chile: false,
      "Chile National ID": false,
      "Chile Residential": false,
      "Watchlist AML": false,
    },
    unitedstatesofamerica: {
      "United States of America": false,
      "Watchlist AML": false,
    },
  },
  newzealandDataSources: {
    "New Zealand Company Verification": false,
    "New Zealand Credit Bureau": false,
    "New Zealand Driver Licence": false,
    "New Zealand DIA Passport": false,
    "New Zealand DIA Citizenship": false,
    "New Zealand DIA Birth": false,
    "New Zealand Yellow Pages": false,
    "New Zealand LINZ Property": false,
    "New Zealand Companies Office": false,
    "New Zealand Residential": false,
    "Watchlist AML": false,
  },
  philippinesDataSources: {
    "Philippines Credit Bureau": false,
    "Philippines Residential": false,
    "Watchlist AML": false,
  },
  indiaDataSources: {
    "India PAN": false,
    "India EPIC": false,
    "India Drivers Licence": false,
    "India Passport": false,
    "India Aadhaar": false,
    "Watchlist AML": false,
  },
  indonesiaDataSources: {
    "Indonesia Resident Identity Card": false,
    "Watchlist AML": false,
  },
  cambodiaDataSources: {
    "Cambodia National ID": false,
    "Watchlist AML": false,
  },
  vietnamDataSources: {
    "Vietnam Consumer Data": false,
    "Vietnam National ID": false,
    "Vietnam Social Security": false,
    "Watchlist AML": false,
  },
  nigeriaDataSources: {
    "Nigeria National ID": false,
    "Nigeria Bank Verification": false,
    "Watchlist AML": false,
  },
  hongkongDataSources: {
    "Hong Kong Credit Bureau": false,
    "Hong Kong Marketing": false,
    "Hong Kong Residential": false,
    "Watchlist AML": false,
  },
  singaporeDataSources: {
    "Singapore Credit Bureau": false,
    "Singapore Residential": false,
    "Singapore Utility": false,
    "Watchlist AML": false,
  },
  unitedstatesDataSources: {
    "United States Residential": false,
    "Watchlist AML": false,
  },
  mexicoDataSources: {
    "Mexico National ID": false,
    "Mexico Tax Registration": false,
    "Watchlist AML": false,
  },
  franceDataSources: {
    "France Residential": false,
    "France Residential 2": false,
    "France Residential 3": false,
    "Watchlist AML": false,
  },
  germanyDataSources: {
    "Germany Residential": false,
    "Germany Utility": false,
    "Germany Utility 2": false,
    "Watchlist AML": false,
  },
  italyDataSources: {
    "Italy Residential": false,
    "Italy Telco Mobile": false,
    "Watchlist AML": false,
  },
  spainDataSources: {
    "Spain Residential": false,
    "Spain Telco": false,
  },
  watchlistAMLDataSources: {
    "Watchlist AML": true, // DEFAULT
  },
  global: {
    "Global OCR": false,
  },
  findIDUDataSources: {
    "Philippines Suspicious Activity": false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_AUSTRALIA_DATA_SOURCES:
      return {
        ...state,
        australiaDataSources: action.payload,
      };
    case SAVE_BRAZIL_DATA_SOURCES:
      return {
        ...state,
        brazilDataSources: action.payload,
      };
    case SAVE_CHINA_DATA_SOURCES:
      return {
        ...state,
        chinaDataSources: action.payload,
      };
    case SAVE_MALAYSIA_DATA_SOURCES:
      return {
        ...state,
        malaysiaDataSources: action.payload,
      };
    case SAVE_NEW_ZEALAND_DATA_SOURCES:
      return {
        ...state,
        newzealandDataSources: action.payload,
      };
    case SAVE_PHILIPPINES_DATA_SOURCES:
      return {
        ...state,
        philippinesDataSources: action.payload,
      };
    case SAVE_INDIA_DATA_SOURCES:
      return {
        ...state,
        indiaDataSources: action.payload,
      };
    case SAVE_INDONESIA_DATA_SOURCES:
      return {
        ...state,
        indonesiaDataSources: action.payload,
      };
    case SAVE_CAMBODIA_DATA_SOURCES:
      return {
        ...state,
        cambodiaDataSources: action.payload,
      };
    case SAVE_VIETNAM_DATA_SOURCES:
      return {
        ...state,
        vietnamDataSources: action.payload,
      };
    case SAVE_NIGERIA_DATA_SOURCES:
      return {
        ...state,
        nigeriaDataSources: action.payload,
      };
    case SAVE_HONG_KONG_DATA_SOURCES:
      return {
        ...state,
        hongkongDataSources: action.payload,
      };
    case SAVE_FRANCE_DATA_SOURCES:
      return {
        ...state,
        franceDataSources: action.payload,
      };
    case SAVE_SINGAPORE_DATA_SOURCES:
      return {
        ...state,
        singaporeDataSources: action.payload,
      };
    case SAVE_UNITED_STATES_DATA_SOURCES:
      return {
        ...state,
        unitedstatesDataSources: action.payload,
      };
    case SAVE_MEXICO_DATA_SOURCES:
      return {
        ...state,
        mexicoDataSources: action.payload,
      };
    case SAVE_GERMANY_DATA_SOURCES:
      return {
        ...state,
        germanyDataSources: action.payload,
      };
    case SAVE_ITALY_DATA_SOURCES:
      return {
        ...state,
        italyDataSources: action.payload,
      };
    case SAVE_SPAIN_DATA_SOURCES:
      return {
        ...state,
        spainDataSources: action.payload,
      };
    case SAVE_MULTI_SERVICE_DATA_SOURCE:
      return {
        ...state,
        multiServiceDataSources: action.payload,
      };
    case SAVE_WATCHLISTAML_DATA_SOURCES:
      return {
        ...state,
        watchlistAMLDataSources: action.payload,
      };
    case SAVE_FIND_IDU_DATA_SOURCE:
      return {
        ...state,
        findIDUDataSources: action.payload,
      };
    // case DIGITAL_ID_SAFEGUARD_DATA_SOURCE:
    //   return {
    //     ...state,
    //     digitalIdSafeguardDataSources: action.payload
    //   }
    case RESET_DATA_SOURCES:
      return initialState;
    default:
      return state;
  }
}
