import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import ResultCard from "../../../reusableComponent/ResultCard";
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const AustraliaDeathCheck = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    < div className="search-verification__results" >
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesAU.deathCheck.title'
        )}
      />

      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.searchSuccessful'
                    )}
                  </p>
                  <span className="material-icons success">check_circle</span>
                </div>
                <ResultCard
                  translationKey='safeHarbourScore'
                  result={safeHarbourScore}
                />
                <ResultCard
                  translationKey='identityVerified'
                  result={identityVerified}
                />
              </div>

              <div className="search-verification__col">
                <ResultCard
                  translationKey='firstName'
                  result={verifications.firstName}
                />
                <ResultCard
                  translationKey='lastName'
                  result={verifications.lastName}
                />
                <ResultCard
                  translationKey='nameMatchScore'
                  result={nameMatchScore}
                />
              </div>

              <div className="search-verification__col">
                <ResultCard
                  translationKey='dateOfBirth'
                  result={verifications.dateOfBirth}
                />
              </div>
            </>
        }
      </div>
    </div>
  );
};

AustraliaDeathCheck.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default AustraliaDeathCheck;
