import isEmptyVAL from "../../../validations/checks/isEmptyVAL";

const identityDetails = (identityData) => {
  let { identityVariables } = identityData;

  if (isEmptyVAL(identityVariables)) identityVariables = {};

  // ID CARD
  if (!isEmptyVAL(identityVariables.idCardNo)) {
    identityVariables.idCardNo = `ID Card Number: ${identityVariables.idCardNo}`;
  }
  if (!isEmptyVAL(identityVariables.nationalIdNo)) {
    identityVariables.nationalIdNo = `National ID Card: ${identityVariables.nationalIdNo}`;
  }
  if (!isEmptyVAL(identityVariables.nik)) {
    identityVariables.nik = `NIK: ${identityVariables.nik}`;
  }
  if (!isEmptyVAL(identityVariables.bankVerificationNo)) {
    identityVariables.bankVerificationNo = `Bank Verification Number: ${identityVariables.bankVerificationNo}`;
  }
  if (!isEmptyVAL(identityVariables.curp)) {
    identityVariables.curp = `Curp: ${identityVariables.curp}`;
  }
  if (!isEmptyVAL(identityVariables.rfc)) {
    identityVariables.rfc = `RFC: ${identityVariables.rfc}`;
  }
  if (!isEmptyVAL(identityVariables.bankCardNo)) {
    identityVariables.bankCardNo = `Bank Card Number: ${identityVariables.bankCardNo}`;
  }
  if (!isEmptyVAL(identityVariables.voterNo)) {
    identityVariables.voterNo = `Voter Number: ${identityVariables.voterNo}`;
  }

  // ACCOUNT NUMBER
  if (!isEmptyVAL(identityVariables.epic)) {
    identityVariables.epic = `EPIC: ${identityVariables.epic}`;
  }
  if (!isEmptyVAL(identityVariables.pan)) {
    identityVariables.pan = `PAN: ${identityVariables.pan}`;
  }
  if (!isEmptyVAL(identityVariables.aadhaarNo)) {
    identityVariables.aadhaarNo = `Aadhaar Number: ${identityVariables.aadhaarNo}`;
  }
  if (!isEmptyVAL(identityVariables.socialSecurityNo)) {
    identityVariables.socialSecurityNo = `Social Security Number: ${identityVariables.socialSecurityNo}`;
  }

  // CITIZENSHIP
  if (!isEmptyVAL(identityVariables.countryOfBirth)) {
    identityVariables.countryOfBirth = `Country Of Birth: ${identityVariables.countryOfBirth}`;
  }
  if (!isEmptyVAL(identityVariables.citizenshipCertificateNo)) {
    identityVariables.citizenshipCertificateNo = `Citizenship Certificate Number: ${identityVariables.citizenshipCertificateNo}`;
  }

  // BIRTH CERTIFICATE
  if (!isEmptyVAL(identityVariables.birthCertificateNo)) {
    identityVariables.birthCertificateNo = `Birth Certificate Number: ${identityVariables.birthCertificateNo}`;
  }
  if (!isEmptyVAL(identityVariables.birthRegistrationDate)) {
    identityVariables.birthRegistrationDate = `Birth Registration Date: ${identityVariables.birthRegistrationDate}`;
  }
  if (!isEmptyVAL(identityVariables.birthRegistrationNo)) {
    identityVariables.birthRegistrationNo = `Birth Registration: ${identityVariables.birthRegistrationNo}`;
  }
  if (!isEmptyVAL(identityVariables.birthRegistrationState)) {
    identityVariables.birthRegistrationState = `Birth Registration State: ${identityVariables.birthRegistrationState}`;
  }
  if (!isEmptyVAL(identityVariables.placeOfBirth)) {
    identityVariables.placeOfBirth = `Place Of Birth: ${identityVariables.placeOfBirth}`;
  }
  if (!isEmptyVAL(identityVariables.mothersFirstName)) {
    identityVariables.mothersFirstName = `Mothers First Name: ${identityVariables.mothersFirstName}`;
  }
  if (!isEmptyVAL(identityVariables.fathersFirstName)) {
    identityVariables.fathersFirstName = `Fathers First Name: ${identityVariables.fathersFirstName}`;
  }
  if (!isEmptyVAL(identityVariables.fathersLastName)) {
    identityVariables.fathersLastName = `Fathers Last Name: ${identityVariables.fathersLastName}`;
  }

  // CENTRELINK
  if (!isEmptyVAL(identityVariables.centrelinkCardExpiry)) {
    identityVariables.centrelinkCardExpiry = `Centrelink Card Expiry: ${identityVariables.centrelinkCardExpiry}`;
  }
  if (!isEmptyVAL(identityVariables.centrelinkCardType)) {
    identityVariables.centrelinkCardType = `Centrelink Card Type: ${identityVariables.centrelinkCardType}`;
  }
  if (!isEmptyVAL(identityVariables.centrelinkCustomerReferenceNo)) {
    identityVariables.centrelinkCustomerReferenceNo = `Centrelink Customer Reference Number: ${identityVariables.centrelinkCustomerReferenceNo}`;
  }

  // DRIVER LICENCE
  if (!isEmptyVAL(identityVariables.driversLicenceCardNo)) {
    identityVariables.driversLicenceCardNo = `Drivers Licence Card Number: ${identityVariables.driversLicenceCardNo}`;
  }
  if (!isEmptyVAL(identityVariables.driversLicenceNo)) {
    identityVariables.driversLicenceNo = `Drivers Licence Number: ${identityVariables.driversLicenceNo}`;
  }
  if (!isEmptyVAL(identityVariables.driversLicenceNumber)) {
    identityVariables.driversLicenceNo = `Drivers Licence Number: ${identityVariables.driversLicenceNumber}`;
  }

  if (!isEmptyVAL(identityVariables.driversLicenceStateOfIssue)) {
    identityVariables.driversLicenceStateOfIssue = `Drivers Licence State Of Issue: ${identityVariables.driversLicenceStateOfIssue}`;
  }
  if (!isEmptyVAL(identityVariables.driversLicenceVersion)) {
    identityVariables.driversLicenceVersion = `Drivers Version Number: ${identityVariables.driversLicenceVersion}`;
  }
  if (!isEmptyVAL(identityVariables.vehiclePlate)) {
    identityVariables.vehiclePlate = `Vehicle Plate: ${identityVariables.vehiclePlate}`;
  }

  // CONTACT DETAILS
  if (!isEmptyVAL(identityVariables.phoneNo)) {
    identityVariables.phoneNo = `Phone Number: ${identityVariables.phoneNo}`;
  }
  if (!isEmptyVAL(identityVariables.phoneNumber)) {
    identityVariables.phoneNo = `Phone Number: ${identityVariables.phoneNumber}`;
  }
  if (!isEmptyVAL(identityVariables.email)) {
    identityVariables.email = `Email: ${identityVariables.email}`;
  }
  if (!isEmptyVAL(identityVariables.emailAddress)) {
    identityVariables.email = `Email: ${identityVariables.emailAddress}`;
  }
  if (!isEmptyVAL(identityVariables.landlineNo)) {
    identityVariables.landlineNo = `Landline Number: ${identityVariables.landlineNo}`;
  }

  // MEDICARE
  if (!isEmptyVAL(identityVariables.medicareCardNo)) {
    identityVariables.medicareCardNo = `Medicare Card Number: ${identityVariables.medicareCardNo}`;
  }
  if (!isEmptyVAL(identityVariables.medicareCardType)) {
    identityVariables.medicareCardType = `Medicare Card Type: ${identityVariables.medicareCardType}`;
  }
  if (!isEmptyVAL(identityVariables.medicareExpiry)) {
    identityVariables.medicareExpiry = `Medicare Card Expiry Date: ${identityVariables.medicareExpiry}`;
  }
  if (!isEmptyVAL(identityVariables.medicareReferenceNo)) {
    identityVariables.medicareReferenceNo = `Medicare Reference Number: ${identityVariables.medicareReferenceNo}`;
  }

  // PASSPORT
  if (!isEmptyVAL(identityVariables.passportNo)) {
    identityVariables.passportNo = `Passport Number: ${identityVariables.passportNo}`;
  }
  if (!isEmptyVAL(identityVariables.passportIssuerCountry)) {
    identityVariables.passportIssuerCountry = `Country Of Issue: ${identityVariables.passportIssuerCountry}`;
  }
  if (!isEmptyVAL(identityVariables.passportCountry)) {
    identityVariables.passportIssuerCountry = `Country Of Issue: ${identityVariables.passportCountry}`;
  }
  if (!isEmptyVAL(identityVariables.fileNo)) {
    identityVariables.fileNo = `File Number: ${identityVariables.fileNo}`;
  }
  if (!isEmptyVAL(identityVariables.dateOfIssue)) {
    identityVariables.dateOfIssue = `Date Of Issue: ${identityVariables.dateOfIssue}`;
  }
  if (!isEmptyVAL(identityVariables.passportExpiry)) {
    identityVariables.passportExpiry = `Passport Expiry Date: ${identityVariables.passportExpiry}`;
  }

  // TAXATION
  if (!isEmptyVAL(identityVariables.taxIDNo)) {
    identityVariables.taxIDNo = `Tax ID No.: ${identityVariables.taxIDNo}`;
  }
  if (!isEmptyVAL(identityVariables.cpf)) {
    identityVariables.cpf = `CPF: ${identityVariables.cpf}`;
  }

  if (!isEmptyVAL(identityVariables.npwp)) {
    identityVariables.npwp = `NPWP: ${identityVariables.npwp}`;
  }

  // ABN
  if (!isEmptyVAL(identityVariables.ABN)) {
    identityVariables.ABN = `ABN: ${identityVariables.ABN}`;
  }

  return {
    identityDetailsData: [
      // ID CARDS
      identityVariables.idCardNo,
      identityVariables.nationalIdNo,
      identityVariables.nik,
      identityVariables.bankVerificationNo,
      identityVariables.curp,
      identityVariables.rfc,
      identityVariables.bankCardNo,
      identityVariables.voterNo,

      // ACCOUNT NUMBER
      identityVariables.epic,
      identityVariables.pan,
      identityVariables.aadhaarNo,
      identityVariables.socialSecurityNo,

      // CITIZENSHIP
      identityVariables.countryOfBirth,
      identityVariables.citizenshipCertificateNo,

      // BIRTH CERTIFICATE
      identityVariables.birthCertificateNo,
      identityVariables.birthRegistrationDate,
      identityVariables.birthRegistrationNo,
      identityVariables.birthRegistrationState,
      identityVariables.placeOfBirth,
      identityVariables.mothersFirstName,
      identityVariables.mothersLastName,
      identityVariables.fathersFirstName,
      identityVariables.fathersLastName,

      // CENTRELINK
      identityVariables.centrelinkCardExpiry,
      identityVariables.centrelinkCardType,
      identityVariables.centrelinkCustomerReferenceNo,

      // DRIVER LICENCE
      identityVariables.driversLicenceCardNo,
      identityVariables.driversLicenceNo,
      identityVariables.driversLicenceStateOfIssue,
      identityVariables.vehiclePlate,
      identityVariables.driversLicenceVersion,

      // CONTACT DETAILS
      identityVariables.phoneNo,
      identityVariables.email,
      identityVariables.landlineNo,

      // MEDICARE
      identityVariables.medicareCardNo,
      identityVariables.medicareCardType,
      identityVariables.medicareExpiry,
      identityVariables.medicareReferenceNo,

      // PASSPORT
      identityVariables.passportNo,
      identityVariables.passportIssuerCountry,
      identityVariables.fileNo,
      identityVariables.dateOfIssue,
      identityVariables.passportExpiry,

      // TAXATION
      identityVariables.taxIDNo,
      identityVariables.cpf,
      identityVariables.npwp,

      // ABN
      identityVariables.ABN,
    ],
  };
};

export default identityDetails;
