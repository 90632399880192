import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILS
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD AUSTRALIA API ERRORS TO INPUT FORM
const feedbackCrossBorder = (errors, dispatch) => {
  try {
    loggerUTL('ERRORS...', errors);
    const inputError = {
      personalInformation: {},
      driversLicence: {},
      driversLicenceAustralia: {},
      driversLicenceNewZealand: {},
      driversLicenceIndia: {},
      medicare: {},
      chinaID: {},
      passport: {},
      passportAustralia: {},
      passportIndia: {},
      nik: {},
      accountNumber: {},
      currentResidentialAddress: {},
    };

    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name ${errors.find((item) => item.includes('firstName')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${errors.find((item) => item.includes('lastName')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'fullName')) {
      inputError.personalInformation.firstName = `Full name exceeds maximum length`;
      inputError.personalInformation.lastName = `Full name exceeds maximum length`;
    }
    if (findErrorUTL(errors, 'dateOfBirth')) {
      inputError.personalInformation.dateOfBirth = `Birth date ${errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
        }`;
    }

    /* ============================================
                NATIONAL ID
     ============================================ */
    if (findErrorUTL(errors, 'idCardNo')) {
      const idCardNoErrorMsg = errors.find((item) => item.includes('idCardNo')).split(':')
      if (idCardNoErrorMsg.length === 1) {
        inputError.chinaID.idCardNo = idCardNoErrorMsg[0];
      }
      if (idCardNoErrorMsg.length > 1) {
        inputError.chinaID.idCardNo = `ID card number is ${idCardNoErrorMsg[1]}`;
      }
    }

    /* ============================================
              INDIA RESIDENT IDENTITY CARD (NIK)
       ============================================ */
    if (findErrorUTL(errors, 'nik')) {
      inputError.nik.nik = `NIK number ${errors.find((item) => item.includes('nik')).split(':')[1]
        }`;
    }

    /* ============================================
              INDIA ACCOUNT NUMBER
       ============================================ */
       if (findErrorUTL(errors, 'pan')) {
        inputError.pan = `PAN number ${
          errors.find((item) => item.includes('pan')).split(':')[1]
        }`;
      }

    /* ============================================
                DRIVERS LICENCE
     ============================================ */
    if (findErrorUTL(errors, 'driversLicenceNo')) {
      inputError.driversLicenceAustralia.driversLicenceNo =
        `Driver licence number ${errors.find((item) => item.includes('driversLicenceNo')).split(':')[1]}`;
      inputError.driversLicenceNewZealand.driversLicenceNo =
        `Driver licence number ${errors.find((item) => item.includes('driversLicenceNo')).split(':')[1]}`;
      inputError.driversLicenceIndia.driversLicenceNo =
        `Driver licence number ${errors.find((item) => item.includes('driversLicenceNo')).split(':')[1]}`;
      inputError.driversLicence.driversLicenceNo =
        `Driver licence number ${errors.find((item) => item.includes('driversLicenceNo')).split(':')[1]}`;
    }

    /* ============================================
                MEDICARE CARD
     ============================================ */
    if (findErrorUTL(errors, 'medicareCardNo')) {
      inputError.medicare.medicareCardNo = `Medicare card number ${errors.find((item) => item.includes('medicareCardNo')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'expiry')) {
      inputError.medicare.medicareExpiry = `Medicare Expiry date must be in the future`;
    }

    /* ============================================
          CURRENT RESIDENTIAL ADDRESS
     ============================================ */
    if (findErrorUTL(errors, 'addressElement1')) {
      inputError.postCode = `Steet ${errors.find((item) => item.includes('addressElement1')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'addressElement3')) {
      inputError.postCode = `City ${errors.find((item) => item.includes('addressElement3')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'addressElement4')) {
      inputError.postCode = `State ${errors.find((item) => item.includes('addressElement4')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'addressElement5')) {
      inputError.postCode = `Post code ${errors.find((item) => item.includes('addressElement5')).split(':')[1]
        }`;
    }

    /* ============================================
                PASSPORT
     ============================================ */
    if (findErrorUTL(errors, 'passportNo')) {
      inputError.passport.passportNo =
        `Passport number ${errors.find((item) => item.includes('passportNo')).split(':')[1]}`;
      inputError.passportAustralia.passportNo =
        `Passport number ${errors.find((item) => item.includes('passportNo')).split(':')[1]}`;
      inputError.passportIndia.passportNo =
        `Passport number ${errors.find((item) => item.includes('passportNo')).split(':')[1]}`;
    }
    if (findErrorUTL(errors, 'fileNo')) {
      const fileNoErr = errors.find((item) => item.includes('fileNo')).split(':');
      const errKey = fileNoErr[0].trim();
      const errMsg = fileNoErr[1];
      if (errKey === 'Invalid value for fileNo') inputError.passportIndia.fileNo = errMsg
      if (errKey === 'fileNo') inputError.passportIndia.fileNo = `File number ${errMsg}`;
    }

    dispatch({
      type: ERROR_FEEDBACK,
      payload: inputError,
    });
  } catch (err) {
    loggerUTL(err);
    Sentry.captureException(
      'FAILED TO PARSE CROSS-BORDER VALIDATION ERRORS...',
      JSON.stringify
    )(err);
  }
};

export default feedbackCrossBorder;
