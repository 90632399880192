import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const IndiaAadhaar = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL('translateSearchVerification.dataSourcesIN.aadhaarNo.title')}
      />

      <div className="search-verification__verify">
        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIN.aadhaarNo.searchSuccessful'
              )}
            </p>
            <span className="material-icons success">check_circle</span>
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIN.aadhaarNo.safeHarbourScore'
              )}
            </p>
            {!isEmptyVAL(safeHarbourScore) ? (
              safeHarbourScore.toLowerCase() === 'add' ? (
                <span className="material-icons success">add_circle</span>
              ) : (
                <span>{safeHarbourScore}</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIN.aadhaarNo.identityVerified'
              )}
            </p>
            {!isEmptyVAL(identityVerified) ? (
              identityVerified ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                <span className="material-icons error">cancel</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
        </div>

        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIN.aadhaarNo.aadhaarNo'
              )}
            </p>
            {!isEmptyVAL(verifications.aadhaarNo) ? (
              verifications.aadhaarNo ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                <span className="material-icons error">cancel</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL('translateSearchVerification.dataSourcesIN.aadhaarNo.name')}
              
            </p>
            {!isEmptyVAL(verifications.fullName) ? (
              verifications.fullName ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                <span className="material-icons error">cancel</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIN.aadhaarNo.dateOfBirth'
              )}
            </p>
            {!isEmptyVAL(verifications.dateOfBirth) ? (
              verifications.dateOfBirth ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                <span className="material-icons error">cancel</span>
              )
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
        </div>

        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIN.aadhaarNo.nameMatchScore'
              )}
            </p>
            {!isEmptyVAL(nameMatchScore) ? (
              <span>{nameMatchScore}</span>
            ) : (
              <span>{transUTL('translateReuse.na')}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

IndiaAadhaar.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default IndiaAadhaar;
