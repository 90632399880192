import React from 'react';
import PropTypes from 'prop-types';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';

const ResultCard = ({ translationKey, result }) => {
  const type = typeof result;
  let value = <></>
  if (type === 'boolean') {
    value = result ? (
      <span className="material-icons success">check_circle</span>
    ) : (
      <span className="material-icons error">cancel</span>
    );
  } else if (type === 'string' && result.length > 0) {
    value = <span>{result}</span>;
  } else {
    value = <span>{transUTL('translateReuse.na')}</span>;
  }

  return (
    <div className="search-verification__verify-item">
      <p>
        {transUTL(
          `translateSearchVerification.${translationKey}`
        )}
      </p>
      {value}
    </div>
  )
}

ResultCard.propTypes = {
  translationKey: PropTypes.string.isRequired
};

export default ResultCard;
