import title from "../../helpers/title";

import { specialServiceNames } from "../displayingStringsForVerificationDataFields/verificationResultsDataFields";

const errorMessages = (doc, serviceResponses, docStyles) => {

  title(
    doc,
    docStyles,
    "Error Message(s)",
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );

  const columnsBody = [
    { 
      header: "", 
      dataKey: "col1" 
    },
    {
      header: "",
      dataKey: "col2"
    }
  ];
  const rowsBody = [];

  // MAP ERROR MESSAGE
  // ======================================================

  const services = Object.entries(serviceResponses);

  services.forEach(service => {
    const serviceName = service[0];
    const serviceData = service[1];

    const specialServiceNamesArray = Object.keys(specialServiceNames);
    let displayName = serviceName;
    if (specialServiceNamesArray.includes(serviceName)) {
      displayName = specialServiceNames[serviceName];
    }

    if (serviceData) {
      let status;
      if (serviceData.status === 3) {
        status = serviceData.errorMessage
          ? serviceData.errorMessage
          : "Error at Data Source";
      } else {
        status = "N/A";
      }

      rowsBody.push({
        col1: serviceName === 'Watchlist AML' ? "Global Watchlist AML" : displayName,
        col2: status
      });

    } else {
      serviceData = { verifications: {} };
    }
  })

  // ======================================================

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable({
    columns: columnsBody, 
    body: rowsBody,
    didParseCell: data => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === "col1") {
        cell.styles.textColor = docStyles.color;
      }
    },
    showHead: "never",
    styles: {
      textColor: docStyles.colorText,
      font: docStyles.fontFamily.time,
      ...docStyles.tableStyles
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText
      }
    },
    // margin: { bottom: 100 }, // PREVENTS OVERLAP WITH FOOTER
    startY: finalY
  });
};

export default errorMessages;
