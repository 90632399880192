import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const MedicareAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    medicareCardNo,
    medicareCardType,
    medicareReferenceNo,
    medicareExpiry,
    errors,
  } = formState;

  const medicareCardTypeOptions = [
    { label: transUTL('translateDropdown.select'), value: '' },
    {
      label: transUTL('translateDropdown.medicare.medicareAU.green'),
      value: 'G',
    },
    {
      label: transUTL('translateDropdown.medicare.medicareAU.blue'),
      value: 'B',
    },
    {
      label: transUTL('translateDropdown.medicare.medicareAU.yellow'),
      value: 'Y',
    },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: ['Australia Medicare Card'],
      element: (
        <TextFieldGroup
          key="medicareCardNo"
          id="medicareCardNo"
          type="text"
          name="medicareCardNo"
          dataName="medicareCardNoAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.medicareCardNo')}
          value={medicareCardNo}
          placeholder={transUTL('translatePlaceholder.inputAU.medicareCardNo')}
          placeholderTrigger={isEmptyVAL(medicareCardNo) ? 'input-empty' : ''}
          error={errors.medicareCardNo}
          errorTrigger={errors.medicareCardNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Medicare Card'],
      element: (
        <SelectListGroup
          key="medicareCardType"
          id="medicareCardType"
          name="medicareCardType"
          dataName="medicareCardTypeAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.medicareCardType')}
          value={medicareCardType}
          placeholderTrigger={isEmptyVAL(medicareCardType) ? 'input-empty' : ''}
          options={medicareCardTypeOptions}
          error={errors.medicareCardType}
          errorTrigger={errors.medicareCardType ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Medicare Card'],
      element: (
        <TextFieldGroup
          key="medicareReferenceNo"
          id="medicareReferenceNo"
          type="text"
          name="medicareReferenceNo"
          dataName="medicareReferenceNoAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.medicareReferenceNo'
          )}
          value={medicareReferenceNo}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.medicareReferenceNo'
          )}
          placeholderTrigger={
            isEmptyVAL(medicareReferenceNo) ? 'input-empty' : ''
          }
          error={errors.medicareReferenceNo}
          errorTrigger={errors.medicareReferenceNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },

    {
      belongsTo: ['Australia Medicare Card'],
      element: (
        <TextFieldGroup
          key="medicareExpiry"
          id="medicareExpiry"
          type={(medicareCardType=="G") ? 'month' : (medicareCardType=="") ? 'month' : 'date'}
          name="medicareExpiry"
          dataName="medicareExpiryAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.medicareExpiry')}
          value={medicareExpiry}
          placeholder={transUTL('translatePlaceholder.inputAU.medicareExpiry')}
          max="9999-12-31"
          placeholderTrigger={isEmptyVAL(medicareExpiry) ? 'input-empty' : ''}
          error={errors.medicareExpiry}
          errorTrigger={errors.medicareExpiry ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

MedicareAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default MedicareAustralia;
