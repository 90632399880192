const formErrors = {
  // CREDENTIALS
  username: 'username is required',
  password: 'password is required',
  // PERSONAL INFORMATION
  firstName: 'First name is required',
  firstNameTooShort: 'First name should have more than 1 character',
  middleName: 'Middle name is required',
  middelNameTooShort: 'Middle name should have more than 1 character',
  lastName: 'Last name is required',
  lastNameTooShort: 'Last name should have more than 1 character',
  dateOfBirth: 'Date of birth is required',
  yearOfBirth: 'Year of birth is required', 
  fullName: 'Full name is required',
  gender: 'Gender is required',
  paternalName: 'Paternal Name is required',
  maternalName: 'Maternal Name is required',
  firstNameORlastName: 'Either first or last name is required',
  // CURRENT RESIDENTIAL ADDRESS
  block: 'Street/block number is required',
  building: 'Building is required',
  addressLine1: 'Address line 1 is required',
  street: 'Street is required',
  streetNo: 'Street number is required',
  streetName: 'Street name is required',
  streetAddress: 'Street address is required',
  streetNumberAndName: 'Street number and name are required',
  townOrCity: 'Town or city is required',
  suburb: 'Suburb is required',
  state: 'State is required',
  postCode: 'Post code is required',
  postcode: 'Postcode is required',
  zipCode: 'Zip code is required',
  locality: 'Locality is required',
  city: 'City is required',
  district: 'District is required',
  ward: 'Ward is required',
  province: 'Province is required',
  streetNameAndHouseNo: 'Street name and house number are required',
  houseNumber: 'House number is required',
  county: 'County is required',
  postCodeLengthNZ: 'Post code should be 4 digits',
  // BIRTH CERTIFICATE
  birthRegistrationState: 'Birth registration state is required',
  birthRegistrationNo: 'Birth registration number is required',
  birthCertificateNo: 'Birth certificate number is required',
  birthRegistrationDate: 'Birth registration date is required',
  // CENTERLINK
  centrelinkCardType: 'Centerlink card type',
  centrelinkCardExpiry: 'Centerlink card expiry',
  centrelinkCustomerReferenceNo: 'Centerlink card customer reference number',
  // DRIVERS LICENCE
  driversLicenceNo: 'Drivers licence number is required',
  driversLicenceNumber: 'Drivers licence number is required',
  driversLicenceCardNo: 'Drivers licence card number is required',
  driversLicenceStateOfIssue: 'Driver Licence state of issue is required',
  driversLicenceVersion: 'Drivers licence version is required',
  vehiclePlate: 'Vehicle registration is required',
  driversLicenceExpiryDate: 'Drivers licence expiry date is required',
  driversLicenceNoLengthNZ: 'Drivers licence should be 2 letters follow by 6 numbers',
  driversLicenceVersionLengthNZ: 'Drivers licence version should be 3 digits from 0-9',
  // MEDICARE
  medicareCardNo: 'Medicare card number is required',
  medicareCardType: 'Medicare card type is required',
  medicareReferenceNo: 'Medicare individual reference number is required',
  medicareExpiry: 'Medicare expiry date is required',
  // PASSWORD
  passportNo: 'Passport number is required',
  passportIssuerCountry: 'Country of issue is required',
  passportExpiryDate: 'Passport expiry date is required ',
  passportExpiry: 'Passport expiry date is required ',
  passportExpiryDateAU:
    'This document has expired and may not verify. Please ensure the document expiry date is correct before submitting the search.',
  passportNoInvalidM: "Does not accept passport numbers beginning with 'M'",
  fileNo: 'File number is required',
  dateOfIssue: 'Issue date is required',
  // ABN
  ABN: 'ABN is required',

  // GOOGLE
  addressUnparsed: 'Address is required',
  // CHINESE SEARCH
  phoneNo: 'Phone number is required',
  phoneNo1: 'Invalid phone number',
  phoneNo2: 'Phone number length exceeded',
  phoneNo3: '11 digits required',
  phoneNo4: 'Phone number must begin with +86',
  idCardNo: 'ID card number is required',
  bankCardNo: 'Bank card number is required',
  passportNo: 'Passport number is required',
  // IDENTITY DATA
  laserNo: 'Laser number is required',
  idNo: 'ID card number is required',
  idCardNoLength: 'ID card number length should be 12 or 9 characters',
  nationalIDNo: 'National ID card number is required',  
  landlineNo: 'Land Line number is required',
  vin: 'National ID number is required',
  voterNo: 'Voter number is required',
  bankVerificationNo: 'Bank Verification number is required',
  curp: 'Curp is required',
  rfc: 'RFC is required',
  taxCodeNo: 'Tax Code Number is required',
  nationalIdNo: 'National ID card number is required',

  // NATIONAL ID DATA
  idcardNoType: 'ID card type is required',
  searchType: 'Search type is required',
  // ACCOUNT NUMBER
  pan: 'PAN is required',
  aadhaarNo: 'Aadhaar is required',
  epic: 'EPIC is required',
  epicLength: 'Character length should be 10',
  // RESIDENT IDENTITY CARD (NIK)
  nik: 'NIK is required',
  // DATES
  dateOfBirthFormat: 'Incorrect date format', 
  medicareExpiryDateFormat: 'Incorrect date format, hint: (mm/yyyy)',
  medicareExpiryDateFormatExceeded:
    'The entered date is greater then todays date...',
  passportExpiryDateFormat: 'Incorrect date format',
  medicareReferenceNoNumberOnly: 'Only numbers',

  // SOCIAL SECURITY
  socialSecurityNo: 'Social Security Number is required',

  // TAX REGISTRATION
  taxIDNo: 'Tax ID No. is required',
  cpf: 'CPF (Cadastro de Pessoas Físicas) is required',
  npwp: 'NPWP (Nomor Pokok Wajib Pajak - Tax ID Number) is required',

  // CONTACTS
  emailAddress: 'Email address is required',
  email: 'Email address is required',
  emailFormatIncorrect: 'Email format is incorrect',
  emailID: 'Email address is required',
};
export default formErrors;
