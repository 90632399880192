// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataSG = (inputData, dataSources) => {
  let creditBureauData, residentialData, watchlistAMLData;

  /* ============================================
              SINGAPORE CREDIT BUREAU
   ============================================ */
  if (dataSources.includes('Singapore Credit Bureau')) {
    loggerUTL('WHITELISTING SINGAPORE DRIVERS LICENCE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'nationalIDNo',
      'block',
      'streetName',
      'postCode',
      'city',
      'phoneNo',
      'reportingReference',
    ]);

    creditBureauData = { ...whitelisted };
  }

  /* ============================================
              SINGAPORE RESIDENTIAL
   ============================================ */
  if (dataSources.includes('Singapore Residential')) {
    loggerUTL('WHITELISTING SINGAPORE RESIDENTIAL...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'nationalIDNo',
      'block',
      'streetName',
      'postCode',
      'city',
      'phoneNo',
      'reportingReference',
    ]);

    residentialData = { ...whitelisted };
  }

  /* ============================================
              SINGAPORE UTILITY
   ============================================ */
  if (dataSources.includes('Singapore Utility')) {
    loggerUTL('WHITELISTING SINGAPORE UTILITY...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'block',
      'streetName',
      'postCode',
      'city',
      'phoneNo',
      'reportingReference',
    ]);

    residentialData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELIST WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...creditBureauData,
    ...residentialData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataSG;
