import loggerUTL from '../../../utils/loggerUTL';

const tableFilter = (table) =>
  table.filter((rowObj) => {
    let innerCount = 1;
    let outerCounter = 1;
    for (let key in rowObj) {
      loggerUTL(key);
      if (rowObj[`col${outerCounter}`] !== 'N/A') {
        innerCount++;
      }
      outerCounter++;
      if (innerCount > 2) return rowObj;
    }

    return null;
  });

export default tableFilter;
