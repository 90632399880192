import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title }) => {
  return (
    <div>
      <header className="header-secondary">
        <h4 className="header-title-secondary">{title}</h4>
      </header>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
