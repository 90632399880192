// HELPERS
import title from '../../../helpers/title';

// IDENTITY DATA
import personalInformation from '../../../identityData/personalInformation';
import reference from '../../../identityData/reference';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

const consumerDataSupplied = (doc, data, info, docStyles) => {
  title(
    doc,
    docStyles,
    'Consumer Data Supplied',
    docStyles.headers.secondary,
    docStyles.separation
  );

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  const { service, consentObtained, ...identityData } = data.Request;

  // IDENTITY INFORMATION
  const { personalInformationData } = personalInformation(identityData);
  const { referenceData } = reference(identityData);

  let combinedData = [
    ...personalInformationData,
    ...referenceData,
  ];
  // Removing empty slots
  combinedData = combinedData.filter((v) => !isEmptyVAL(v));

  // DATA SOURCE CONSENT
  let dataSourceString = [];
  if (service.includes('watchlist aml')) {
    dataSourceString.push('Global Watchlist AML: Not Required');
  }

  const columns = [
    { title: '', dataKey: 'col1' },
    {
      title: '',
      dataKey: 'col2',
    },
  ];

  const rows = [
    {
      col1: 'Identity Data:',
      col2: combinedData.join('\n'),
    },
    {
      col1: 'Consent Obtained',
      col2: dataSourceString.join('\n'),
    },
  ];

  doc.autoTable(columns, rows, {
    showHead: 'never',
    styles: docStyles.tableStyles,
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
      col2: {
        font: docStyles.fontFamily.arialUnicodeMS,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
    },
    startY: finalY,
  });
};

export default consumerDataSupplied;
