import * as Sentry from "@sentry/browser";

// TYPES
import { ERROR_FEEDBACK } from "../../types";

// UTILITIES
import findErrorUTL from "../../../utils/findErrorUTL";
import loggerUTL from "../../../utils/loggerUTL";

// LOAD PHILIPPINES API ERRORS TO INPUT FORM
const feedbackPH = (errors, dispatch) => {
  loggerUTL("ERRORS...", errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, "firstName")) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes("firstName")).split(":")[1]
      }`;
    }
    if (findErrorUTL(errors, "lastName")) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes("lastName")).split(":")[1]
      }`;
    }

    /* ============================================
              CURRENT RESIDENTAIL ADDRESS
       ============================================ */
    if (findErrorUTL(errors, "addressElement5")) {
      inputError.postCode = `Post code should be ${
        errors.find((item) => item.includes("addressElement5")).split(":")[1]
      }`;
    }

    // /* ============================================
    //           IDENTITY DETAILS
    //    ============================================ */
    // if (findErrorUTL(errors, "nationalIdNo")) {
    //   inputError.nationalIdNo = `National ID number ${
    //     errors.find((item) => item.includes("nationalIdNo")).split(":")[1]
    //   }`;
    // }
  } catch (err) {
    Sentry.captureException(
      "FAILED TO PARES PHILIPPINES VALIDATION ERRORS...",
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackPH;
