const dataSourcesFR = {
  franceResidential: {
    title: 'Perumahan Perancis',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    email: 'E-mel Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    postCode: 'Poskod Disahkan',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
  },
  franceResidential2: {
    title: 'Perumahan Perancis 2',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    emailAddress: 'E-mel Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    state: 'Negeri Disahkan',
    postCode: 'Poskod Disahkan',
  },
  franceResidential3: {
    title: 'Perumahan Perancis 3',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    emailAddress: 'E-mel Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    postCode: 'Poskod Disahkan',
  }
};

export default dataSourcesFR;
