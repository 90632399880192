
import loggerUTL from "./loggerUTL";

const downsizeUploadedImageUTL = (originalDataURI) => {
  loggerUTL('origenal datauri: ', originalDataURI);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext("2d");

  const MAX_LENGTH = 1000
  let ratio = 1

  const FILE_TYPE = 'image/jpeg'; // jpeg type file can be compressed by default

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = originalDataURI
    img.onload = () => {
      const { width, height } = img;
      if (!isNaN(width / height))
        ratio = width / height;

      if (width > height) {
        if (width > MAX_LENGTH) {
          canvas.width = MAX_LENGTH;
          canvas.height = MAX_LENGTH / ratio;
        }
      } else {
        if (height > MAX_LENGTH) {
          canvas.width = MAX_LENGTH * ratio;
          canvas.height = MAX_LENGTH;
        }
      }
      loggerUTL(`img width: ${width}, height: ${height}`);
      loggerUTL(`width / height = ${ratio}`);
      loggerUTL(`canvas width: ${canvas.width}, height: ${canvas.height}`);

      // Copy the image contents to the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const downsizedDataURI = canvas.toDataURL(FILE_TYPE, 0.5)
      loggerUTL('downsized datauri ', downsizedDataURI)

      resolve(downsizedDataURI)
    }
    img.onerror = reject
  })
  
}

export default downsizeUploadedImageUTL;
