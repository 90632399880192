import title from "../../../helpers/title";

const errorMessages = (doc, data, info, docStyles) => {
  const { Response } = data;

  title(
    doc,
    docStyles,
    "Error Message(s)",
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );

  const columnsBody = [
    { title: "", dataKey: "col1" },
    {
      title: "",
      dataKey: "col2"
    }
  ];
  const rowsBody = [];

  // DATA SOURCES
  let nationalID = Response.serviceResponses["Brazil National ID"];
  let taxRegistration = Response.serviceResponses["Brazil Tax Registration"];
  let watchlistAML = Response.serviceResponses["Watchlist AML"];

  // BRAZIL NATIONAL ID
  if (nationalID) {
    let status;
    if (nationalID.status === 3) {
      status = nationalID.errorMessage
        ? nationalID.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "Brazil National ID",
      col2: status
    });
  } else {
    nationalID = { verifications: {} };
  }

  if (taxRegistration) {
    let status;
    if (taxRegistration.status === 3) {
      status = taxRegistration.errorMessage
      ? taxRegistration.errorMessage
      : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "Brazil Tax Registration",
      col2: status
    });
  } else {
    taxRegistration = { verifications: {} };
  }

  // WATCHLIST AML
  if (watchlistAML) {
    let status;
    if (watchlistAML.status === 3) {
      status = watchlistAML.errorMessage
        ? watchlistAML.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "Global Watchlist AML",
      col2: status
    });
  } else {
    watchlistAML = { verifications: {} };
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable(columnsBody, rowsBody, {
    didParseCell: data => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === "col1") {
        cell.styles.textColor = docStyles.color;
      }
    },
    showHead: "never",
    styles: {
      textColor: docStyles.colorText,
      font: docStyles.fontFamily.time,
      ...docStyles.tableStyles
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText
      }
    },
    // margin: { bottom: 100 }, // PREVENTS OVERLAP WITH FOOTER
    startY: finalY
  });
};

export default errorMessages;
