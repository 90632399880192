const initialStateNG = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // IDENTITY NUMBER
  nationalIdNo: '',
  bankVerificationNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetAddress: '',
  town: '',
  lga: '',
  state: '',

  // Contact Details
  phoneNo: '',
  emailAddress: '',

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // TERMS AND CONDITIONS
  nationalIDConsentObtained: false,
  bankVerificationConsentObtained: false,

  // ERROR HANDLING
  errors: {},
};

export default initialStateNG;
