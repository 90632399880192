import dataSourcesAU from './searchResults/dataSourcesAU'; // AUSTRALIA
import dataSourcesBR from './searchResults/dataSourcesBR'; // BRAZIL
import dataSourcesCN from './searchResults/dataSourcesCN'; // CHINA
import dataSourcesIN from './searchResults/dataSourcesIN'; // INDIA
import dataSourcesID from './searchResults/dataSourcesID'; // INDONESIA
import dataSourcesMY from './searchResults/dataSourcesMY'; // MALAYSIA
import dataSourcesNZ from './searchResults/dataSourcesNZ'; // NEW ZEALAND
import dataSourcesPH from './searchResults/dataSourcesPH'; // PHILIPPINES
import dataSourcesKH from './searchResults/dataSourcesKH'; // CAMBODIA
import dataSourcesVN from './searchResults/dataSourcesVN'; // VIETNAM
import dataSourcesSG from './searchResults/dataSourcesSG'; // SINGAPORE
import dataSourcesUS from './searchResults/dataSourcesUS'; // UNITED STATES
import dataSourcesNG from './searchResults/dataSourcesNG'; // NIGERIA
import dataSourcesHK from './searchResults/dataSourcesHK'; // SINGAPORE
import dataSourcesMX from './searchResults/dataSourcesMX'; // MEXICO
import dataSourcesDE from './searchResults/dataSourcesDE'; // GERMANY
import dataSourcesFR from './searchResults/dataSourcesFR'; // FRANCE
import dataSourcesIT from './searchResults/dataSourcesIT'; // ITALY
import dataSourcesES from './searchResults/dataSourcesES'; // SPAIN
import dataSourcesGR from './searchResults/dataSourcesGR'; // GREECE
import watchlistAML from './searchResults/watchlistAML'; // WATCHLIST AML

const translateSearchVerification = {
  /* ============================================
          BASE SEARCH VERIFICATION
   ============================================ */
  title: 'Data Zoo IDU Verification Summary',
  inputData: 'Input Data:',
  reportingReference: 'Reporting Reference:',
  issueDateTime: 'Issue Date and Time:',
  watchlistStatusText: 'International PEPs Watchlist Status:',
  watchlistStatusClear: 'CLEAR',
  watchlistStatusFOUND: 'FOUND',
  watchlistStatusFOUND2: 'FOUND (WHITELIST)',
  safeHarbour: 'Safe Harbour:',
  matchStatus: 'Match Status:',
  errorMessage: 'Error Message',
  apiReqAndRes: 'IDU API Request and Response: ',

  /* ============================================
                  Template
  ============================================ */
  searchSuccessful: 'Search Successful',
  safeHarbourScore: 'Safe Harbour Score',
  nameMatchScore: 'Name Match Score',
  addressMatchScore: 'Address Match Score',
  identityVerified: 'Identity Verified',
  category: 'Category',
  pdfLink: 'Pdf Link',

  // personal info
  otherNames: 'Other Names',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  gender: 'Gender',
  dateOfBirth: 'Date of Birth',
  email: 'Email',
  phoneNo: 'Phone No.',
  mobileNo: 'Mobile No.',
  landlineNo: 'Land Line No.',

  // address
  addressLine1: 'Address Line 1',
  street: 'Street',
  streetNo: 'Street No.',
  streetName: 'Street Name',
  streetNumberAndName: 'Street No. and Name',
  streetNameAndHouseNo: 'Street Name And House No.',
  suburb: "Suburb",
  county: "County",
  city: 'City',
  state: 'State',
  province: 'Province',
  postCode: 'Post Code',
  postcode: 'Postcode',
  zipCode: 'Zip Code',
  regionCode: 'Region Code', // ITALY, CZECH
  houseNumber: 'House No.', // ITALY, CZECH

  // documents
  nationalIDNo: 'National ID No.',
  nationalIdNo: 'National ID No.',
  taxIDNo: 'Tax ID No.',
  LicenceNo: 'Driver Licence No.',
  driversLicenceNo: 'Driver Licence No.',
  documentNumber: 'Document No.',
  documentNo: 'Document No.',
  idCardNo: 'ID Card No.',
  passportNo: 'Passport No.',
  fileNo: 'File No.',
  dateOfIssue: 'Issue Date',
  nik: 'NIK',

  /* ============================================
                  DATA SOURCES
   ============================================ */
  dataSourcesAU,
  dataSourcesBR,
  dataSourcesCN,
  dataSourcesIN,
  dataSourcesID,
  dataSourcesMY,
  dataSourcesNZ,
  dataSourcesPH,
  dataSourcesKH,
  dataSourcesVN,
  dataSourcesSG,
  dataSourcesNG,
  dataSourcesHK,
  dataSourcesUS,
  dataSourcesMX,
  dataSourcesDE,
  dataSourcesFR,
  dataSourcesIT,
  dataSourcesES,
  dataSourcesGR,
  watchlistAML,
};

export default translateSearchVerification;
