const inputNG = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',

  // IDENTITY
  nationalIdNo: '',
  bankVerificationNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetAddress: '',
  town: '',
  lga: '',
  state: '',
  address: '',

  // CONTACT DETAILS
  phoneNo: '',
  emailAddress: '',

  // REFERENCE
  reportingReference: '',

  // TEMPORARY
  message: 'Message...',
};

export default inputNG;
