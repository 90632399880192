const translateDocumentLibrary = {
  gdprCompliance: '数据动物园GDPR合规',
  securityPolicy: '数据动物园信息安全策略',
  maintenance: '支持与维护',
  lastUpdated: '最近更新时间',
  documentLibrary: '数据动物园文档库',
  viewDocuments: '查看文件',
  documents: '单据',
};

export default translateDocumentLibrary;
