import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

// TODO: USE D3.js INSTEAD OF  react-chartjs-2
const BarChart = ({ dataAnalytics }) => {
  // DATA PROCESSOR
  const dataSourceLabels = [];
  const successCount = [];
  const failureCount = [];

  let ogData = [];
  for (let key in dataAnalytics) {
    if (dataAnalytics.hasOwnProperty(key)) {
      let successNum = dataAnalytics[key].code_0 + dataAnalytics[key].code_1;
      let errorNum = dataAnalytics[key].code_2;

      const total = successNum + errorNum;
      const successNumPercentage = Math.floor((successNum / total) * 100);
      const errorNumPercentage = Math.ceil((errorNum / total) * 100);

      ogData[key] = [successNumPercentage, errorNumPercentage];
      let sum = successNum + errorNum;
      let sumLog = 0;
      sumLog = sum === 1 ? 0.2 : Math.log(sum);
      dataSourceLabels.push(key);
      successCount.push(sumLog * (successNum / sum) || 0);
      failureCount.push(sumLog * (errorNum / sum) || 0);
    }
  }

  const graphData = {
    labels: dataSourceLabels,
    datasets: [
      {
        label: 'Successful Searches',
        data: successCount,
        backgroundColor: 'rgba(89, 193, 111)',
        borderColor: 'rgba(89, 193, 111)',
        borderWidth: 1,
      },
      {
        label: 'Unsuccessful Searches',
        data: failureCount,
        backgroundColor: 'rgba(220, 221, 222)',
        borderColor: 'rgba(220, 221, 222)',
        borderWidth: 1,
      },
    ],
  };

  return (
    // https://stackoverflow.com/questions/34720530/chart-js-v2-add-prefix-or-suffix-to-tooltip-label
    <Bar
      data={graphData}
      // width={100}
      // height={300}

      options={{
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              return `${
                ogData[data.labels[tooltipItems.index]][
                  tooltipItems.datasetIndex
                ]
              }%`;
            },
          },
          mode: 'index',
        },
        scales: {
          xAxes: [
            {
              display: false,
              stacked: true,
              ticks: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLineWidth: 5,
              minorTickInterval: null,
              ticks: {
                display: false,
              },
            },
          ],
        },
      }}
    />
  );
};

BarChart.propTypes = {
  dataAnalytics: PropTypes.object.isRequired,
};

export default BarChart;
