const dataSourcesVN = {
  vietnamConsumerData: {
    title: 'Vietnam Consumer Data',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    fullName: 'Name Verified',
    email: 'Email Verified',
    phoneNo: 'Phone Number Verified',
    city: 'City Verified',
    addressMatchScore: 'Address Match Score',
    address: 'Address'
  },
  vietnamNationalID: {
    title: 'Vietnam National ID',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    fullName: 'Name Verified',
    nameMatchScore: 'Name Match Score',
    idNo: 'ID Number Verified',
    addressMatchScore: 'Address Match Score',
    addressVerified: 'Address Verified',
    address: 'Address',
    gender: 'Gender Verified'
  },
  vietnamSocialSecurity: {
    title: 'Vietnam Social Security',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    fullName: 'Name Verified',
    nameMatchScore: 'Name Match Score',
    idNo: 'ID Number Verified',
    addressMatchScore: 'Address Match Score',
    addressVerified: 'Address Verified',
    address: 'Address',
    gender: 'Gender Verified'
  }
};

export default dataSourcesVN;
