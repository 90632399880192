const dataSourcesAU = {

  // General fields for all Au data source
  suburb: 'Suburb',
  postCode: 'Post Code',
  email: 'Email Address',
  phoneNo: 'Phone Number',

  // Specific fields for each data source
  deathCheck: {
    title: 'Australia Death Check',
  },
  deathCheckReturnData: {
    title: 'Australia Death Check Returned Data',
    registrationState: 'Registration State',
    registrationYear: 'Registration Year',
    registrationNumber: 'Registration Number',
    dateOfDeath: 'Date of Death',
    possibleDeceasedRecord: 'Possible Deceased Record',
    possibleDeceasedRecordName: 'Possible Deceased Record Name'
  },
  payroll: {
    title: 'Australia Payroll',
    ABN: 'ABN',
  },
  superannuation: {
    title: 'Australia Superannuation',
    ABN: 'ABN'
  },
  passport: {
    title: 'Australia Passport',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  birthCertificate: {
    title: 'Australia Birth Certificate',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  centerlinkCard: {
    title: 'Australia Centrelink Card',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },

  driverLicence: {
    title: 'Australia Driver Licence',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  propertyOwnership: {
    title: 'Australia Property Ownership',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  medicareCard: {
    title: 'Australia Medicare Card',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  residential: {
    title: 'Australia Residential',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    streetName: 'Street Name',
    suburb: 'Suburb',
    state: 'State',
    postCode: 'Post Code',
    altAddressMatchScore: 'Alt Address Match Score',
    altNameMatchScore: 'Alt Name Match Score',
    dpId: 'DPID',
    source: 'Source',
    email: 'Email Address',
    phoneNo: 'Phone Number',
  },
  residentialLookup: {
    title: 'Australia Residential Lookup',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    streetName: 'Street Name',
    suburb: 'Suburb',
    state: 'State',
    postCode: 'Post Code',
    source: 'Source',
    email: 'Email Address',
    phoneNo: 'Phone Number',
    landlineNumber: 'Land line number',
    mobileNumber: 'Mobile Number',
  },
  visa: {
    title: 'Australia Visa',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  visaEntitlement: {
    title: 'Australia Visa Entitlement',
    searchSuccessful: 'Search Successful',
    documentNo: 'Document Number',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
  },
  creditBureau: {
    title: 'Australia Credit Bureau',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    streetName: 'Street Name',
    suburb: 'Suburb',
    state: 'State',
    postCode: 'Post Code',
  },
  citizens: {
    title: 'Australia Citizens',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    streetName: 'Street Name',
    suburb: 'Suburb',
    state: 'State',
    postCode: 'Post Code',
    altAddressMatchScore: 'Alt Address Match Score',
    altNameMatchScore: 'Alt Name Match Score',
    dpId: 'DPID',
    source: 'Source',
    email: 'Email Address',
    phoneNo: 'Phone Number',
  },
  
};

export default dataSourcesAU;
