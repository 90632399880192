const title = (doc, docStyles, title, fontSize, posY = 33, bool) => {
  const columns = [{ header: '', dataKey: 'col1' }];
  const rows = [{ col1: title }];
  const finalY = doc.previousAutoTable.finalY + posY;
  doc.autoTable({
    columns: columns, 
    body: rows,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = [255, 255, 255];
      cell.styles.textColor = docStyles.colorGreen;
      cell.styles.fontSize = fontSize;
      cell.styles.cellPadding = 0;

      if (bool) {
        // PRIMARY HEADER STYLES
        cell.styles.fontStyle = 'bold';
      }
    },
    showHead: 'never',
    startY: finalY,
  });
};

export default title;
