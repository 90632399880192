import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL'
import { transUTL } from '../../utils/transUTL';
import  formatDateUTL  from '../../utils/formatDateUTL';

const formDataVAL = (formData, formSections) => {
  const inputErrors = {};

  Object.values(formSections).forEach(section => {
    const { key, inputFields } = section;

    inputFields.forEach(field => {
      const {name, isMandatory} = field;

      if(isMandatory) {
        if (isEmptyVAL(formData[key][name])) {
          if(!inputErrors[key]) {
            inputErrors[key] = {};
          }
          inputErrors[key][name] = transUTL(`translateFormOverview.formErrors.${name}`);
        } else {
          switch(name) {
            case 'dateOfBirth':
              const dateofBirthFormatted = formatDateUTL(formData[key][name]);
              if (dateofBirthFormatted > moment().format('YYYY-MM-DD')) {
                if(!inputErrors[key]) {
                  inputErrors[key] = {};
                }
                inputErrors[key][name] = transUTL('translateFormOverview.formErrors.dateOfBirthFormat');
              }
              break;
            case 'email':
              const pattern = /\S+@\S+\.\S+/;
              if (!pattern.test(formData[key][name])) {
                if(!inputErrors[key]) {
                  inputErrors[key] = {};
                }
                inputErrors[key][name] = transUTL('translateFormOverview.formErrors.emailFormatIncorrect');
              }
              break;
            default:
              break;
          }
        }
      }
    })
  });

  return inputErrors;
}

export default formDataVAL;