import * as Sentry from '@sentry/browser';

import { saveFormDataAXN } from '../../formData/formDataActions';
import { setAlertAXN } from '../../alert/alertActions';
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';

const inputMappingCN = (data, dispatch) => {
  try {
    // Set form variables
    let fullName = '',
      firstName = '',
      lastName = '',
      dateOfBirth = '',
      gender = '',
      idCardNo = '';

    if (!isEmptyVAL(data)) {
      if (!isEmptyVAL(data.fullName)) {
        fullName = data.fullName;

        if (fullName.length > 0) {
          lastName = fullName[0];
        }
        if (fullName.length > 1) {
          firstName = fullName.slice(1);
        }
      }

      if (!isEmptyVAL(data.dateOfBirth)) {
        let dateFormatCN = /([0-9]+)年([0-9]+)月([0-9]+)日/; // YYYY年MM月DD日
        let dateFormatYYYYMMDD = /([0-9]+)-([0-9]+)-([0-9]+)/; // YYYY-MM-DD
        let dateFormatDDMMYYYY = /([0-9]+)\/([0-9]+)\/([0-9]+)/; // DD/MM/YYYY
        const matchCN = data.dateOfBirth.match(dateFormatCN);
        const matchYYYYMMDD = data.dateOfBirth.match(dateFormatYYYYMMDD);
        const matchDDMMYYYY = data.dateOfBirth.match(dateFormatDDMMYYYY);
        let year = '';
        let month = '';
        let day = '';
        if (!isEmptyVAL(matchCN)) {
          year = matchCN[1];
          month = matchCN[2] < 10 ? `0${matchCN[2]}` : matchCN[2];
          day = matchCN[3] < 10 ? `0${matchCN[3]}` : matchCN[3];
          dateOfBirth = `${year}-${month}-${day}`;
        }
        if (!isEmptyVAL(matchYYYYMMDD)) {
          dateOfBirth = matchYYYYMMDD[0]
        }
        if (!isEmptyVAL(matchDDMMYYYY)) {
          year = matchDDMMYYYY[3];
          month = matchDDMMYYYY[2];
          day = matchDDMMYYYY[1];
          dateOfBirth = `${year}-${month}-${day}`;
        }
      }

      if (!isEmptyVAL(data.idNumber)) {
        idCardNo = data.idNumber;
      }

      if (data.gender === '男') {
        gender = 'M';
      } else if (gender === '女') {
        gender = 'F';
      }
    }

    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('china', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      lastName,
      dateOfBirth,
      idCardNo
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      saveFormDataAXN(
        {
          fullName,
          firstName,
          lastName,
          dateOfBirth,
          gender,
          idCardNo,
          // ocrResponseData
        },
        'china'
      )
    );
  } catch (err) {
    loggerUTL('ERROR: ', err);
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException(err, data);
  }
};

export default inputMappingCN;
