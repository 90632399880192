import moment from 'moment';

const dataURItoBlobUTL = (dataURI, fileName) => {
  let parts = dataURI.split(','),
    mime = parts[0].match(/:(.*?);/)[1];
  if (parts[0].indexOf('base64') !== -1) {
    let bstr = atob(parts[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([u8arr], { type: mime });

    // blob.lastModified = moment.now();
    blob.lastModifiedDate = new Date();
    blob.webkitRelativePath = '';
    blob.name = fileName;
    return blob;
  } else {
    let raw = decodeURIComponent(parts[1]);
    const blob = new Blob([raw], { type: mime });

    blob.lastModified = moment.now();
    blob.lastModifiedDate = new Date();
    blob.webkitRelativePath = '';
    blob.name = fileName;
    return blob;
  }
};

export default dataURItoBlobUTL;
