const dataSourcesCN = {

  identityVerified: '已验证',
  searchSuccessful: '搜索成功',
  name: '姓名',
  dateOfBirth: '出生日期',
  safeHarbourScore: '安全港评分',
  documentNo: '证件号码',
  nameMatchScore: '姓名匹配分数',
  gender: '性别',
  address: '地址',
  idCardNo: '身份证号码',

  idVerification: {
    title: '中国身份证验证',
  },
  phone: {
    title: '中国电话',
    phoneVerified: '电话号码',
  },
  bankCard: {
    title: '中国银行卡',
    fullName: '全名',
    bankCardNo: '银行卡号码',
    idCardNo: '身份证号码',
  },
  passportVerification: {
    title: '中国护照验证',
    passportNo: '护照号码',
  },
  driverLicense: {
    title: '中国驾照',
  }
};

export default dataSourcesCN;
