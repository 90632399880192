import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import initialStateBR from "./helpers/initialStateBR";
import { saveFormDataAXN } from "../../../../actions/formData/formDataActions";
import { searchVerificationAXN } from "../../../../actions/searchVerification/searchVerificationAction";
import { setAlertAXN } from "../../../../actions/alert/alertActions";

// BUTTON
import Button from "../../../../components/btn/Button";
// VALIDATIONS
import brazilVAL from "../../../../validations/inputFields/brazilVAL";
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL
} from "../../../../utils/outputMasterUTL";
import whitelistedInputDataBR from "./helpers/whitelistedInputDataBR";
import filterServicesUTL from "../../../../utils/filterServicesUTL";
import jsonBuilder from "../../../../utils/jsonBuilder";
import { transUTL } from "../../../../utils/transUTL";
import formatDateUTL from "../../../../utils/formatDateUTL";
import loggerUTL from "../../../../utils/loggerUTL";
import { withRouter } from "react-router-dom";
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// FORM CONTROLLER
import FormController from "../../../../components/formController/FormController";

// FORM INPUT
import PersonalInformationBrazil from "./inputForms/PersonalInformationBrazil";
import CurrentResidentialAddressBrazil from "./inputForms/CurrentResidentialAddressBrazil";
import IdentityDetailsBrazil from "./inputForms/IdentityDetailsBrazil";
import TaxRegistration from "./inputForms/TaxRegistration";
import ClientReference from "../../reusableForms/ClientReference";

const BrazilForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
	saveFormDataAXN,
	setAlertAXN,
  searchVerificationAXN,
  history
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateBR);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.brazilDataSources
  );

  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.brazilFormData,
      errors: {} // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);
  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.brazilFormData,
      errors: { ...errorsRXS } // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL("VALIDATING...");
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = brazilVAL(inputData, selectedDataSources);

    // VALIDATE INPUT
    if (!isValid) {
      loggerUTL("ERRORS...", inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL("translateAlertMsg.inputErrors"), "error");
    }

    // DATE FORMAT
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELIST DAT TO SUBMIT TO API TODO: REFACTOR THE THIRD PARAMETER
    let whitelisted = whitelistedInputDataBR(inputData, selectedDataSources);

    if (selectedDataSources.includes("Brazil Tax Registration")) {
      const {firstName, middleName, lastName} = whitelisted;

      whitelisted = {
        ...whitelisted,
        fullName: !isEmptyVAL(middleName) ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`,
      }
    }

    // ADDRESS MANIPULATION
    const addressElement1 = inputData.streetName;  //CHANGE FROM unitNo TO streetName
    const addressElement3 = inputData.locality;
    const addressElement4 = inputData.district;
    const addressElement5 = inputData.postCode;

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      addressElement1,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));
    // REDUX ACTIONS - TRIGGERS CALL TO API
    searchVerificationAXN(submitData, history);
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL("CLEARING STATE...");

    // OMITS `errors`
    const { errors, ...reset } = initialStateBR;

    // RESETS TO INITIAL STATE
    setUserInputSTH(initialStateBR);

    // RESETS NIGERIA FORM DATA
    saveFormDataAXN(reset, countryRXS);
  };

  const handleOnChange = event => {
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (e) => {
    loggerUTL("ON BLUR...");
    const { errors, ...filteredFormData } = userInputSTH;

    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  const handleEnterKeyTrigger = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL("ON BLUR ENTER KEY TRIGGER");
      handleOnBlur();
    }
  };

  const inputElements = [
    {
      belongsTo: ["Brazil National ID", "Brazil Tax Registration", "Watchlist AML"],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            "translateFormOverview.formHeaders.personalInformation"
          )}
          inputForm={
            <PersonalInformationBrazil
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      )
    },
    {
      belongsTo: ["Brazil National ID"],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            "translateFormOverview.formHeaders.currentResidentialAddress"
          )}
          inputForm={
            <CurrentResidentialAddressBrazil
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      )
    },
    {
      belongsTo: ["Brazil National ID"],
      element: (
        <FormController
          key="identityDetails"
          title={transUTL("translateFormOverview.formHeaders.identityDetails")}
          inputForm={
            <IdentityDetailsBrazil
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      )
    },
    {
      belongsTo: ["Brazil Tax Registration"],
      element: (
        <FormController
          key="taxRegistration"
          title={transUTL("translateFormOverview.formHeaders.taxRegistration")}
          inputForm={
            <TaxRegistration
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }

        />
      )
    },
    {
      belongsTo: ["Watchlist AML", "Brazil National ID", "Brazil Tax Registration"],
      element: (
        <FormController
          key="clientRef"
          title={transUTL("translateFormOverview.formHeaders.clientRef")}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      )
    }
  ];

  return (
    <Fragment>
      {outputMasterUTL(selectedDataSources, inputElements)}
      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL("translateFormOverview.clear")}
      </button>

      {/* BUTTON */}
      <Button
        myClass={"btn-under"}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL("translateBtn.previousPage")}
        btn2Text={transUTL("translateBtn.submit")}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

BrazilForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  authRXS: state.authRXS,
  errorsRXS: state.errorsRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN
})(withRouter(BrazilForms));
