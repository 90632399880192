import React from "react";
import PropTypes from "prop-types";

// INPUT
import TextFieldGroup from "../../../../../components/common/textFieldGroup/TextFieldGroup";
import SelectListGroup from "../../../../../components/common/selectListGroup/SelectListGroup";

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";
// UTILITIES
import { outputMasterUTL } from "../../../../../utils/outputMasterUTL";
import { transUTL } from "../../../../../utils/transUTL";
import sortAlphaUTL from "../../../../../utils/sortAlphaUTL";
import countryCodeUTL from "../../../../../utils/countryCodeUTL";

const PassportAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { passportNo, passportExpiry, passportIssuerCountry, errors } =
    formState;
  const passportIssuerCountryOptions = countryCodeUTL("alpha3").sort((a, b) =>
    sortAlphaUTL(a, b, "label")
  );

  const inputElements = [
    {
      belongsTo: [
        "Australia Passport",
        "Australia Visa",
        "Australia Visa Entitlement",
      ],
      element: (
        <TextFieldGroup
          key="passportNo"
          id="passportNo"
          type="text"
          name="passportNo"
          dataName="passportNoAU"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.passportNo")}
          value={passportNo}
          placeholder={transUTL("translatePlaceholder.inputAU.passportNo")}
          placeholderTrigger={isEmptyVAL(passportNo) ? "input-empty" : ""}
          error={errors.passportNo}
          errorTrigger={errors.passportNo ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ["Australia Passport"],
      element: (
        <TextFieldGroup
          key="passportExpiry"
          id="passportExpiry"
          type="date"
          name="passportExpiry"
          dataName="passportExpiryAU"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.passportExpiry")}
          value={passportExpiry}
          placeholder={transUTL("translatePlaceholder.inputAU.passportExpiry")}
          max="9999-12-31"
          placeholderTrigger={isEmptyVAL(passportExpiry) ? "input-empty" : ""}
          error={errors.passportExpiry}
          errorTrigger={errors.passportExpiry ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ["Australia Visa", "Australia Visa Entitlement"],
      element: (
        <SelectListGroup
          key="passportIssuerCountry"
          id="passportIssuerCountry"
          name="passportIssuerCountry"
          dataName="passportIssuerCountryAU"
          labelClass="form-group__label"
          label={transUTL(
            "translateFormOverview.inputLabels.passportIssuerCountry"
          )}
          value={passportIssuerCountry}
          placeholderTrigger={
            isEmptyVAL(passportIssuerCountry) ? "input-empty" : ""
          }
          options={passportIssuerCountryOptions}
          error={errors.passportIssuerCountry}
          errorTrigger={errors.passportIssuerCountry ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

PassportAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default PassportAustralia;
