const country = {
  aruba: 'Aruba',
  afghanistan: 'Afghanistan',
  angola: 'Angola',
  anguilla: 'Anguilla',
  alandIslands: 'Åland Islands',
  albania: 'Albania',
  andorra: 'Andorra',
  unitedArabEmirates: 'United Arab Emirates',
  argentina: 'Argentina',
  armenia: 'Armenia',
  americanSamoa: 'American Samoa',
  antarctica: 'Antarctica',
  frenchSouthernTerritories: 'French Southern Territories',
  antiguaAndBarbuda: 'Antigua and Barbuda',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijan: 'Azerbaijan',
  burundi: 'Burundi',
  belgium: 'Belgium',
  benin: 'Benin',
  bonaireSintEustatiusAndSaba: 'Bonaire, Sint Eustatius and Saba',
  burkinaFaso: 'Burkina Faso',
  bangladesh: 'Bangladesh',
  bulgaria: 'Bulgaria',
  bahrain: 'Bahrain',
  bahamas: 'Bahamas',
  bosniaAndHerzegovina: 'Bosnia and Herzegovina',
  saintBarthélemy: 'Saint Barthélemy',
  belarus: 'Belarus',
  belize: 'Belize',
  bermuda: 'Bermuda',
  bolivia: 'Bolivia (Plurinational State of)',
  brazil: 'Brazil',
  barbados: 'Barbados',
  bruneiDarussalam: 'Brunei Darussalam',
  bhutan: 'Bhutan',
  bouvetIsland: 'Bouvet Island',
  botswana: 'Botswana',
  centralAfricanRepublic: 'Central African Republic',
  canada: 'Canada',
  cocosKeelingIslands: 'Cocos (Keeling) Islands',
  switzerland: 'Switzerland',
  chile: 'Chile',
  china: 'China',
  côteDIvoire: "Côte d'Ivoire",
  cameroon: 'Cameroon',
  congoDemocraticRepublic: 'Congo, Democratic Republic',
  congo: 'Congo',
  cookIslands: 'Cook Islands',
  colombia: 'Colombia',
  comoros: 'Comoros',
  caboVerde: 'Cabo Verde',
  costaRica: 'Costa Rica',
  cuba: 'Cuba',
  curaçao: 'Curaçao',
  christmasIsland: 'Christmas Island',
  caymanIslands: 'Cayman Islands',
  cyprus: 'Cyprus',
  czechia: 'Czechia',
  germany: 'Germany',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  denmark: 'Denmark',
  dominicanRepublic: 'Dominican Republic',
  algeria: 'Algeria',
  ecuador: 'Ecuador',
  egypt: 'Egypt',
  eritrea: 'Eritrea',
  westernSahara: 'Western Sahara',
  spain: 'Spain',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  finland: 'Finland',
  fiji: 'Fiji',
  falklandIslandsMalvinas: 'Falkland Islands (Malvinas)',
  france: 'France',
  faroeIslands: 'Faroe Islands',
  micronesia: 'Micronesia (Federated States of)',
  gabon: 'Gabon',
  unitedKingdomOfGreatBritainAndNorthernIreland:
    'United Kingdom',
  greatbritain: 'Great Britain',
  georgia: 'Georgia',
  guernsey: 'Guernsey',
  ghana: 'Ghana',
  gibraltar: 'Gibraltar',
  guinea: 'Guinea',
  guadeloupe: 'Guadeloupe',
  gambia: 'Gambia',
  guineaBissau: 'Guinea-Bissau',
  equatorialGuinea: 'Equatorial Guinea',
  greece: 'Greece',
  grenada: 'Grenada',
  greenland: 'Greenland',
  guatemala: 'Guatemala',
  frenchGuiana: 'French Guiana',
  guam: 'Guam',
  guyana: 'Guyana',
  hongKong: 'Hong Kong',
  heardIslandAndMcDonaldIslands: 'Heard Island and McDonald Islands',
  honduras: 'Honduras',
  croatia: 'Croatia',
  haiti: 'Haiti',
  hungary: 'Hungary',
  indonesia: 'Indonesia',
  isleOfMan: 'Isle of Man',
  india: 'India',
  britishIndianOceanTerritory: 'British Indian Ocean Territory',
  ireland: 'Ireland',
  iran: 'Iran (Islamic Republic of Iran)',
  iraq: 'Iraq',
  iceland: 'Iceland',
  israel: 'Israel',
  italy: 'Italy',
  jamaica: 'Jamaica',
  jersey: 'Jersey',
  jordan: 'Jordan',
  japan: 'Japan',
  kazakhstan: 'Kazakhstan',
  kenya: 'Kenya',
  kyrgyzstan: 'Kyrgyzstan',
  cambodia: 'Cambodia',
  kiribati: 'Kiribati',
  saintKittsAndNevis: 'Saint Kitts and Nevis',
  korea: 'Korea',
  kuwait: 'Kuwait',
  laoPeoplesDemocraticRepublic: "Lao People's Democratic Republic",
  lebanon: 'Lebanon',
  liberia: 'Liberia',
  libya: 'Libya',
  saintLucia: 'Saint Lucia',
  liechtenstein: 'Liechtenstein',
  sriLanka: 'Sri Lanka',
  lesotho: 'Lesotho',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  latvia: 'Latvia',
  macao: 'Macao',
  saintMartinFrenchPart: 'Saint Martin (French part)',
  morocco: 'Morocco',
  monaco: 'Monaco',
  moldova: 'Moldova, Republic of',
  madagascar: 'Madagascar',
  maldives: 'Maldives',
  mexico: 'Mexico',
  marshallIslands: 'Marshall Islands',
  macedonia: 'Macedonia, the former Yugoslav Republic of',
  mali: 'Mali',
  malta: 'Malta',
  myanmar: 'Myanmar',
  montenegro: 'Montenegro',
  mongolia: 'Mongolia',
  northernMarianaIslands: 'Northern Mariana Islands',
  mozambique: 'Mozambique',
  mauritania: 'Mauritania',
  montserrat: 'Montserrat',
  martinique: 'Martinique',
  mauritius: 'Mauritius',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  mayotte: 'Mayotte',
  namibia: 'Namibia',
  newCaledonia: 'New Caledonia',
  niger: 'Niger',
  norfolkIsland: 'Norfolk Island',
  nigeria: 'Nigeria',
  nicaragua: 'Nicaragua',
  niue: 'Niue',
  netherlands: 'Netherlands',
  norway: 'Norway',
  nepal: 'Nepal',
  nauru: 'Nauru',
  newZealand: 'New Zealand',
  oman: 'Oman',
  pakistan: 'Pakistan',
  panama: 'Panama',
  pitcairn: 'Pitcairn',
  peru: 'Peru',
  philippines: 'Philippines',
  palau: 'Palau',
  papuaNewGuinea: 'Papua New Guinea',
  poland: 'Poland',
  puertoRico: 'Puerto Rico',
  portugal: 'Portugal',
  paraguay: 'Paraguay',
  palestine: 'Palestine, State of',
  frenchpPolynesia: 'French Polynesia',
  qatar: 'Qatar',
  réunion: 'Réunion',
  romania: 'Romania',
  russianFederation: 'Russian Federation',
  rwanda: 'Rwanda',
  saudiArabia: 'Saudi Arabia',
  sudan: 'Sudan',
  senegal: 'Senegal',
  singapore: 'Singapore',
  southGeorgiaAndTheSouthSandwichIslands:
    'South Georgia and the South Sandwich Islands',
  saintHelenaAscensionAndTristanDaCunha:
    'Saint Helena, Ascension and Tristan da Cunha',
  svalbardAndJanMayen: 'Svalbard and Jan Mayen',
  solomonIslands: 'Solomon Islands',
  sierraLeone: 'Sierra Leone',
  elSalvador: 'El Salvador',
  sanMarino: 'San Marino',
  somalia: 'Somalia',
  saintPierreAndMiquelon: 'Saint Pierre and Miquelon',
  serbia: 'Serbia',
  southSudan: 'South Sudan',
  saoTomeAndPrincipe: 'Sao Tome and Principe',
  suriname: 'Suriname',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  sweden: 'Sweden',
  eswatini: 'Eswatini',
  sintMaartenDutchPart: 'Sint Maarten (Dutch part)',
  seychelles: 'Seychelles',
  syrianArabRepublic: 'Syrian Arab Republic',
  turksAndCaicosIslands: 'Turks and Caicos Islands',
  chad: 'Chad',
  togo: 'Togo',
  thailand: 'Thailand',
  tajikistan: 'Tajikistan',
  tokelau: 'Tokelau',
  turkmenistan: 'Turkmenistan',
  timorLeste: 'Timor-Leste',
  tonga: 'Tonga',
  trinidadAndTobago: 'Trinidad and Tobago',
  tunisia: 'Tunisia',
  turkey: 'Turkey',
  tuvalu: 'Tuvalu',
  taiwanProvinceOfChina: 'Taiwan, Province of China',
  tanzania: 'Tanzania, United Republic of',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  unitedStatesMinorOutlyingIslands: 'United States Minor Outlying Islands',
  uruguay: 'Uruguay',
  unitedStatesOfAmerica: 'United States of America',
  uzbekistan: 'Uzbekistan',
  saintVincentAndTheGrenadines: 'Saint Vincent and the Grenadines',
  venezuela: 'Venezuela (Bolivarian Republic of)',
  virginIslandsBritish: 'Virgin Islands (British)',
  virginIslandsUS: 'Virgin Islands (U.S.)',
  vietNam: 'Vietnam ',
  vanuatu: 'Vanuatu',
  wallisAndFutuna: 'Wallis and Futuna',
  samoa: 'Samoa',
  yemen: 'Yemen',
  southAfrica: 'South Africa',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe',
};

export default country;
