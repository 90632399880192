import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AdminRoute = ({
  component: Component,
  authRXS: {
    isAuthenticated,
    user
  },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      (isAuthenticated && user.organisationId === 1) ?
        <Component {...props} />
        : <Redirect to="/" />
    }
  />
);

AdminRoute.propTypes = {
  authRXS: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
});

export default connect(mapStateToProps)(AdminRoute);