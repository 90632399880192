const dataSourcesHK = {
  creditBureau: {
    title: 'Hong Kong Credit Bureau',
    searchSuccessful: 'Search Successful',
    nationalIDNo: 'National ID',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    street: 'Street',
    district: 'District',
    city: 'City',
    state: 'State',
    postCode: 'Post Code',
  },
  residential: {
    title: 'Hong Kong Residential',
    searchSuccessful: 'Search Successful',
    nationalIDNo: 'National ID',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    street: 'Street',
    district: 'District',
    city: 'City',
    state: 'State',
    postCode: 'Post Code',
  },
};

export default dataSourcesHK;
