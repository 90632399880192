const initialStateAML = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStateAML;
