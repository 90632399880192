const dataSourcesDE = {
  germanyResidential: {
    title: 'Kediaman Jerman',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    gender: 'Jantina Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    state: 'Negeri Disahkan',
    postCode: 'Poskod Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
  },
  germanyUtility: {
    title: 'Utiliti Jerman',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    gender: 'Jantina Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    state: 'Negeri Disahkan',
    postCode: 'Poskod Disahkan',
  },
  germanyCreditBureau: {
    title: 'Biro Kredit Jerman',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    gender: 'Jantina',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    streetNo: 'Jalan Tidak Disahkan',
    streetName: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    state: 'Negeri Disahkan',
    postCode: 'Poskod Disahkan',
  }
};

export default dataSourcesDE;
