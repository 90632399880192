// HELPERS
import returnDataToString from '../../../helpers/returnDataToString';

// UTILS
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';
import title from '../../../helpers/title';
import infoMsg from '../../../helpers/infoMsg';
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

const returnedData = (doc, data, info, docStyles) => {
  const { Response } = data;

  title(
    doc,
    docStyles,
    'Returned Data',
    docStyles.headers.secondary,
    docStyles.separation
  );

  const columns = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];
  const rows = [];

  // DATA SOURCE RESPONSE
  let diaBirth = Response.serviceResponses['New Zealand DIA Birth'];
  let diaCitizenship = Response.serviceResponses['New Zealand DIA Citizenship'];
  let diaPassport = Response.serviceResponses['New Zealand DIA Passport'];
  let driverLicence = Response.serviceResponses['New Zealand Driver Licence'];
  let linzProperty = Response.serviceResponses['New Zealand LINZ Property'];
  let vehicle = Response.serviceResponses['New Zealand Vehicle'];
  let residential = Response.serviceResponses['New Zealand Residential'];
  let companiesOffice =
    Response.serviceResponses['New Zealand Companies Office'];
  let yellowPages = Response.serviceResponses['New Zealand Yellow Pages'];
  let creditBureau = Response.serviceResponses['New Zealand Credit Bureau'];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  // NEW ZEALAND DIA BIRTH
  if (diaBirth) {
    let { returnedData, verifications, ...data } = diaBirth;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand DIA Birth',
      col2: string,
    });
  } else {
    diaBirth = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DIA CITIZENSHIP
  if (diaCitizenship) {
    let { returnedData, verifications, ...data } = diaCitizenship;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand DIA Citizenship',
      col2: string,
    });
  } else {
    diaCitizenship = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DIA PASSPORT
  if (diaPassport) {
    let { returnedData, verifications, ...data } = diaPassport;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand DIA Passport',
      col2: string,
    });
  } else {
    diaPassport = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DRIVER LICENCE
  if (driverLicence) {
    let { returnedData, verifications, ...data } = driverLicence;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand Driver Licence',
      col2: string,
    });
  } else {
    driverLicence = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND LINZ PROPERTY
  if (linzProperty) {
    let { returnedData, verifications, ...data } = linzProperty;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand LINZ Property',
      col2: string,
    });
  } else {
    linzProperty = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND VEHICLE
  if (vehicle) {
    let { returnedData, verifications, ...data } = vehicle;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand Vehicle',
      col2: string,
    });
  } else {
    vehicle = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND RESIDENTIAL
  if (residential) {
    let { returnedData, verifications, ...data } = residential;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand Residential',
      col2: string,
    });
  } else {
    residential = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND COMPANIES OFFICE
  if (companiesOffice) {
    let { returnedData, verifications, ...data } = companiesOffice;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand Companies Office',
      col2: string,
    });
  } else {
    companiesOffice = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND YELLOW PAGES
  if (yellowPages) {
    let { returnedData, verifications, ...data } = yellowPages;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand Yellow Pages',
      col2: string,
    });
  } else {
    yellowPages = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND CREDIT BUREAU
  if (creditBureau) {
    let { returnedData, verifications, ...data } = creditBureau;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });

    rows.push({
      col1: 'New Zealand Credit Bureau',
      col2: string,
    });
  } else {
    creditBureau = { verifications: {}, returnedData: {} };
  }

  // WATCHLIST AML
  let watchlistAMLPaddingTrigger = false;
  if (watchlistAML) {
    let { returnedData, verifications, ...data } = watchlistAML;

    if (isEmptyVAL(returnedData.watchlistResults)) {
      returnedData = { watchlistResults: [{}] };
    }

    const fields = returnDataToString({
      fields: {
        ...data,
        ...verifications,
        ...returnedData.watchlistResults[0],
      },
    });

    rows.push({
      col1: 'Global Watchlist AML',
      col2: fields,
    });
  } else {
    watchlistAML = {
      returnedData: { watchlistResults: [{}] },
      verifications: {},
    };
  }

  rows.sort((a, b) => sortAlphaUTL(a, b, 'col1'));

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  doc.autoTable({columns: columns, body: rows, 
    didParseCell: (data) => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === 'col1') {
        cell.styles.textColor = docStyles.colorGreen;
      }

      if (column.index === 0) {
        cell.styles.cellPadding = { top: 60, bottom: 60, left: 3.75, right: 3.75 }; // This should prevent second column inner contents from overflowing
      }

      if (cell.raw === 'Watchlist AML') {
        if (watchlistAMLPaddingTrigger) {
          cell.styles.cellPadding = { top: 125, bottom: 125 }; // This should prevent second column inner contents from overflowing
        }
      }
    },
    showHead: 'never',
    styles: {
      textColor: docStyles.colorText,
      ...docStyles.tableStyles,
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
      },
      col2: {
        font: docStyles.fontFamily.arialUnicodeMS,
        textColor: docStyles.colorText,
      },
    },
    startY: finalY,
  });

  infoMsg(
    doc,
    docStyles,
    [{ header: '', dataKey: 'col1' }],
    [
      {
        col1:
          'Name Match Score: A=Full Match | A1=Middle Name Mismatched | B=First Initial and Surname Matched | C=Surname Only Match | X=No Match',
      },
      {
        col1:
          'Address Match Score: 0=Full Match | 1=Suburb/Postcode Mismatch | 2=Unit # Mismatch | 2A=House Suf/Prefix Mismatch | 3=Street # Mismatch | Z=No Match',
      },
    ],
    docStyles.separation
  );
};

export default returnedData;
