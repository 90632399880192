const formHeaders = {
  selectedServices: 'PERKHIDMATAN TERPILIH',
  personalInformation: 'Maklumat peribadi',
  personalInformationFindIDU: 'Maklumat peribadi (MASUKKAN SEKURANG-KURANGNYA 3 MEDAN INPUT)',
  personalName: 'Maklumat peribadi',
  personalInformationResidential: 'Maklumat peribad',
  personalInformationSuper: 'Maklumat peribad',
  currentResidentialAddress: 'Alamat Kediaman Semasa',
  currentResidentialAddressSuper: 'Alamat Kediaman Semasa',
  currentResidentialAddressResidential: 'Alamat Kediaman Semasa',
  currentResidentialAddressAutoComplete: 'Alamat Kediaman Semasa',
  driversLicence: 'Lesen pemandu',
  medicare: 'Medicare',
  passport: 'Pasport',
  phone: "Telefon",
  phoneNo: "Telefon",
  email: 'E-mel',
  visaAndPassport: 'Pasport / Visa',
  visa: 'Tunjuk',
  voterIdentificationNumber: 'Nombor Pengenalan Pengundi',
  contactDetails: 'Maklumat Hubungan (Disyorkan)',
  termsAndConditions: 'Persetujuan dan Terma & Syarat',
  laserNumber: 'Nombor Laser',
  chineseSearch: 'Pencarian Bahasa Cina',
  identityDetails: 'Perincian Identiti',
  birthCertificate: 'Sijil kelahiran',
  citizenship: 'Kewarganegaraan',
  landLineNumber: 'Talian Darat No',
  nationalIDData: 'Data ID Negara',
  accountNumber: 'Nombor akaun',
  nik: 'Kad Pengenalan Penduduk (NIK)',
  extraNames: 'Nama Tambahan',
  passportAndVisa: 'Pasport / Visa',
  voterNo: 'ID Pengundi',
  clientRef: 'Rujukan Pelanggan',
  clientReference: 'Rujukan Pelanggan',
  watchlistAML: 'Harapan terhenti',
  nationalIDNumber: 'Nombor ID Kebangsaan',
  phoneNumber: 'Nombor Telefon',
  ocrAndBiometric: 'OUTENTIKASI OCR & BIOMETRIK',
  centerlink: 'Pautan tengah',
  socialSecurity: 'Keselamatan Sosial',
  taxRegistration: 'Pendaftaran Cukai Brazil',
  ABN: 'Nombor perniagaan Australia',
  taxIDNo: 'Nombor ID Cukai', // ITALY
  chinaID: 'ID China',
  passportAustralia: 'Pasport Australia',
  passportIndia: 'Passport India',
  driversLicenceAustralia: 'Lesen memandu Australia',
  driversLicenceNewZealand: 'Lesen memandu New Zealand',
  driversLicenceIndia: 'Lesen memandu India',
};
export default formHeaders;
