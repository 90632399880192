import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressIndia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    streetNo,
    streetName,
    city,
    state,
    postCode,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <TextFieldGroup
          key="streetNo"
          id="streetNo"
          type="text"
          name="streetNo"
          dataName="streetNoIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetNo')}
          value={streetNo}
          placeholder={transUTL('translatePlaceholder.inputIN.streetNo')}
          placeholderTrigger={isEmptyVAL(streetNo) ? 'input-empty' : ''}
          error={errors.streetNo}
          errorTrigger={errors.streetNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetName')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputIN.streetName')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    // {
    //   belongsTo: ['India Drivers Licence'],
    //   element: (
    //     <TextFieldGroup
    //       key="suburb"
    //       id="suburb"
    //       type="text"
    //       name="suburb"
    //       dataName="suburbIN"
    //       labelClass="form-group__label"
    //       label={transUTL('translateFormOverview.inputLabels.suburb')}
    //       value={suburb}
    //       placeholder={transUTL('translatePlaceholder.inputIN.suburb')}
    //       placeholderTrigger={isEmptyVAL(suburb) ? 'input-empty' : ''}
    //       error={errors.suburb}
    //       errorTrigger={errors.suburb ? 'input-error' : ''}
    //       handleOnChange={handleOnChange}
    //       handleOnBlur={handleOnBlur}
    //       handleEnterKeyTrigger={handleEnterKeyTrigger}
    //     />
    //   ),
    // },
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.city')}
          value={city}
          placeholder={transUTL('translatePlaceholder.inputIN.city')}
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <TextFieldGroup
          key="state"
          id="state"
          type="text"
          name="state"
          dataName="stateIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholder={transUTL('translatePlaceholder.inputIN.state')}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India Drivers Licence'],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputIN.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CurrentResidentialAddressIndia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressIndia;
