import { createMuiTheme } from '@material-ui/core/styles';

//Table styling
export const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiIconButton: {
        root: {
          fontSize: '1.3rem',
          width: '5rem',
        },
      },
      MuiSvgIcon: {
        root: {
          width: '1.8rem',
          height: '1.8rem',
        },
      },
      MuiTypography: {
        h6: {
          fontSize: '2.3rem',
          fontWeight: 'bold',
        },
        body2: {
          fontSize: '1rem',
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '1.3rem',
        },
      },
      MuiPaper: {
        root: {
          paddingTop: '2.5rem',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: 'pointer',
        },
      },
      MuiTableCell: {
        head: {
          backgroundColor: '#59c16f',
        },
        body: {
          fontSize: '1.3rem',
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: '#f7f8fa',
          fontSize: '1.3rem',
          fontWeight: 'bold',
        },
        sortActive: {
          color: '#f7f8fa',
        },
        sortAction: {
          '& path': {
            color: '#f7f8fa',
          },
        },
      },
    },
  });
