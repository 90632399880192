const inputMY = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',

  // IDENTITY
  nationalIDNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '',
  city: '',
  postCode: '',

  // REFERENCE
  reportingReference: '',
};

export default inputMY;
