import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const VoterNumberCambodia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleKeyUp,
}) => {
  const { voterNo, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['Cambodia National ID'],
      element: (
        <TextFieldGroup
          key="voterNo"
          id="voterNo"
          type="text"
          name="voterNo"
          dataName="voterNoKH"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.voterNo')}
          value={voterNo}
          placeholder={transUTL('translatePlaceholder.inputKH.voterNo')}
          placeholderTrigger={isEmptyVAL(voterNo) ? 'input-empty' : ''}
          error={errors.voterNo}
          errorTrigger={errors.voterNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
          handleKeyUp={handleKeyUp}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

VoterNumberCambodia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleKeyUp: PropTypes.func.isRequired,
};

export default VoterNumberCambodia;
