import store from '../../../../store/store';
import { setVerificationResultsAXN } from '../../../../actions/searchVerification/searchVerificationAction';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const searchVerificationSessionDataRestore = () => {
  loggerUTL('SEARCH VERIFICATION SESSION DATA RESTORE...');
  if (sessionStorage.searchVerification) {
    store.dispatch(
      setVerificationResultsAXN(JSON.parse(sessionStorage.searchVerification))
    );
  }
};

export default searchVerificationSessionDataRestore;
