import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { saveDataSourcesAXN } from '../../actions/dataSources/dataSourcesActions';
import { setAlertAXN } from '../../actions/alert/alertActions';
import { saveSelectedCountryAXN } from "../../actions/country/countryActions";

// BUTTON
import Button from '../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { dataSourcesIsCheckedUTL } from '../../utils/outputMasterUTL';
import { transUTL } from '../../utils/transUTL';
import loggerUTL from '../../utils/loggerUTL';

//CONSTANTS
import { FIND_IDU_ROUTES, FIND_IDU } from '../../constants/findIDU';

const FindIDUSelection = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  saveSelectedCountryAXN,
  saveDataSourcesAXN,
  setAlertAXN,
  history,
}) => {

  const { FIND_IDU_FORM_OVERVIEW } = FIND_IDU_ROUTES;

  // Temperory for demo, consider to move it to auth level
  let availableDataSources = [
    "Philippines Suspicious Activity",
  ];

  const [dataSourcesSTH, setDataSourcesSTH] = useState({});

  useEffect(() => {
    saveSelectedCountryAXN(FIND_IDU)
    // eslint-disable-next-line
  }, []);

  const initialiseDataSourceSelections = () => {
    let extractedDataSource = dataSourcesRXS['findIDUDataSources'];
    if (extractedDataSource)
      setDataSourcesSTH({ ...extractedDataSource });
  }
  useEffect(initialiseDataSourceSelections, []);

  const updateDataSourceSelections = () => {
    saveDataSourcesAXN(FIND_IDU, dataSourcesSTH);
  }
  useEffect(updateDataSourceSelections, [dataSourcesSTH]);

  const handleOnSubmit = () => {
    const selectedDataSources = dataSourcesIsCheckedUTL(dataSourcesSTH);

    if (isEmptyVAL(selectedDataSources))
      return setAlertAXN(
        transUTL('translateAlertMsg.pleaseSelectDataSource'),
        'error'
      );

    history.push(FIND_IDU_FORM_OVERVIEW);
  };

  const handelCheckbox = (opt) => {
    setDataSourcesSTH({
      ...dataSourcesSTH,
      [opt]: !dataSourcesSTH[opt],
    })
  };

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>Data Source(s) Selection</h2>

        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <p className="common-form__info">
          {transUTL('translateDataSourceSelection.select')}
        </p>

        <div className="data-source-selection__box">
          {availableDataSources.map((item) => {
            const dataSource = item.split(' ').join('').toLowerCase();
            const i18nTitleKey = `translateDataSources.${dataSource}`;

            return (
              <div
                key={item}
                className="data-source-selection__checkbox"
                onClick={() => handelCheckbox(item)}
              >
                <h3 className="data-source-selection__title">
                  {transUTL(i18nTitleKey)}
                </h3>
                {dataSourcesSTH[item] ? (
                  <span className="material-icons data-source-selection__icon-checked">
                    check_box
                  </span>
                ) : (
                  <span className="material-icons data-source-selection__icon-unchecked">
                    check_box_outline_blank
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/dashboard"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

FindIDUSelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  saveDataSourcesAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  saveSelectedCountryAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  dataSourceSelectionsRXS: state.dataSourceSelectionsRXS
});

export default connect(mapStateToProps, { saveSelectedCountryAXN, saveDataSourcesAXN, saveDataSourcesAXN, setAlertAXN })(FindIDUSelection);
