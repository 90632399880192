import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { saveDataSourcesAXN } from '../../actions/dataSources/dataSourcesActions';

// VISUALIZATIONS
import DoughnutChart from '../../components/visualizations/DoughnutChart';
import LineChart from '../../components/visualizations/LineChart';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { saveSelectedClientAXN } from '../../actions/usageReport/usageReportAction';
import windowTopUTL from '../../utils/windowTopUTL';
import { transUTL } from '../../utils/transUTL';

const responseTimeParse = (client, key) => {
  const labels = Object.keys(client[key]);
  const data = Object.values(client[key]);
  const total = data.reduce((partial_sum, a) => partial_sum + a, 0);

  const newLabels = [];
  const labelsLength = labels.length;
  const dataLength = data.length;

  for (let [index, element] of labels.entries()) {
    const quotient = data[index] / total;
    const percentage = quotient * 100;
    newLabels.push(
      `${element} seconds: ${data[index]} (${Math.floor(percentage)}%)`
    );
  }

  return { labels: newLabels, data, labelsLength, dataLength };
};

const allDataCharts = (client, key) => {
  const labels = Object.keys(client[key]);
  const data = Object.values(client[key]);

  const labelsLength = labels.length;
  const dataLength = data.length;

  return { labels, data, labelsLength, dataLength };
};

const topTenClients = (client, key) => {
  const dataCount = [...Object.values(client[key])];
  let dataCountDec = dataCount.sort((a, b) => b - a).slice(0, 8);

  const users = client[key];
  const newUsers = {};

  let index = 0;
  let othersCount = 0;
  for (let property in users) {
    if (users.hasOwnProperty(property)) {
      if (dataCountDec.includes(users[property])) {
        if (index <= dataCountDec.length) {
          newUsers[property] = users[property];
        }
        index += 1;
      } else {
        othersCount += users[property];
      }
    }
  }

  if (othersCount !== 0) {
    newUsers.others = othersCount;
  }

  const labels = Object.keys(newUsers);
  const data = Object.values(newUsers);

  const labelsLength = labels.length;
  const dataLength = data.length;

  return { labels, data, labelsLength, dataLength };
};

const UsageReport = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  usageReportRXS,
  saveDataSourcesAXN,
  saveSelectedClientAXN,
  history,
}) => {
  const { user } = authRXS;
  const { selectedClient, selectedDate, type, chartData } = usageReportRXS;
  useEffect(() => {
    windowTopUTL();
    // eslint-disable-next-line
  }, []);

  const report = chartData.find(
    (item) =>
      item.date.toLowerCase() === selectedDate.toLowerCase() &&
      item.type.toLowerCase() === type.toLowerCase()
  );

  let gbUsageKeys = [];
  let gbUsageValues = [];
  let gbMatchValues = [];

  let client = { ...report.clients[selectedClient] };

  delete client.usage['Global All'];
  delete client.match['Global All'];

  client.usage = { ...client.usage, ...client.globalUsage };
  client.match = { ...client.match, ...client.globalMatch };

  try {
    //TODO: THIS SHOULD ONLY BE APPLIED APRIL 26 2020 OR GREATER
    const clientMatch = client.match['China National ID'];
    const clientMatch2 = client.match['China National ID2'];
    const clientUsage = client.usage['China National ID'];
    const clientUsage2 = client.usage['China National ID2'];

    if (clientUsage > 0) {
      client.match['China National ID'] = clientMatch + clientMatch2;
      delete client.match['China National ID2'];
      client.usage['China National ID'] = clientUsage + clientUsage2;
      delete client.usage['China National ID2'];
    }

    gbUsageKeys = Object.keys(client.usage);
    gbUsageValues = Object.values(client.usage);
    gbMatchValues = Object.values(client.match);
  } catch (err) {}

  return (
    <div className="usage-report">
      <div className="usage-report__data">
        <div className="usage-report__table  usage-report__chart-tile">
          <header className="header-primary">
            <h2 className="usage-report__title">
              {usageReportRXS.selectedClient}
            </h2>

            <span
              className="material-icons"
              title="Edit Client"
              onClick={() =>
                history.push(
                  `/date-selection/client-selection/:${user.name.toLowerCase()}`
                )
              }
            >
              create
          </span>
          </header>
          <div className="common-form__body">
            <table>
              <thead>
                <tr>
                  <th scope="col">Data Source Name</th>
                  <th scope="col">Usage</th>
                  <th scope="col">Match</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {gbUsageKeys.map((item, index) => {
                  try {
                    const quotient = gbMatchValues[index] / gbUsageValues[index];
                    let matchRate = quotient * 100;
                    matchRate = matchRate.toFixed(2);

                    if (gbUsageValues[index] !== 0) {
                      return (
                        <tr key={item}>
                          <td data-label="Data Source Name">{item}</td>
                          <td data-label="Usage">{gbUsageValues[index]}</td>
                          <td data-label="Match Rate">{gbMatchValues[index]}</td>
                          <td data-label="Percentage">{matchRate}%</td>
                        </tr>
                      );
                    } else {
                      if (!Number(matchRate) || !isFinite(matchRate)) {
                        return null;
                      }
                    }
                    return null;
                  } catch (err) {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
          {/* PLACE NEW GRAPHS HERE */}
        </div>

        <div className="usage-report__graphs">
          {!isEmptyVAL(client.user) && (
            <div className="usage-report__chart-tile">
              <header className="header-primary">
                <h2 className="usage-report__title">User(s)</h2>
                <span className="usage-report__title">
                  {usageReportRXS.selectedDate}
                </span>
              </header>
              <div className="common-form__body">
                <DoughnutChart chart={topTenClients(client, 'user')} />
              </div>
            </div>
          )}
          {!isEmptyVAL(client.ip) && (
            <div className="usage-report__chart-tile">
              <header className="header-primary">
                <h2 className="usage-report__title">IP Address</h2>
                <span className="usage-report__title">
                  {usageReportRXS.selectedDate}
                </span>
              </header>
              <div className="common-form__body">
                <DoughnutChart chart={topTenClients(client, 'ip')} />
              </div>
            </div>
          )}
          {!isEmptyVAL(client.performance) && (
            <div className="usage-report__chart-tile">
              <header className="header-primary">
                <h2 className="usage-report__title">Response Times</h2>
                <span className="usage-report__title">
                  {usageReportRXS.selectedDate}
                </span>
              </header>
              <div className="common-form__body">
                <DoughnutChart chart={responseTimeParse(client, 'performance')} />
              </div>
            </div>
          )}
          {!isEmptyVAL(client.transactionTime) && (
            <div className="usage-report__chart-tile">
              <header className="header-primary">
                <h2 className="usage-report__title">Transaction Times</h2>
                <span className="usage-report__title">
                  {usageReportRXS.selectedDate}
                </span>
              </header>
              <div className="common-form__body">
                <LineChart chart={allDataCharts(client, 'transactionTime')} />
              </div>
            </div>
          )}
          {!isEmptyVAL(client.transactionDay) && (
            <div className="usage-report__chart-tile">
              <header className="header-primary">
                <h2 className="usage-report__title">Transaction Days</h2>
                <span className="usage-report__title">
                  {usageReportRXS.selectedDate}
                </span>
              </header>
              <div className="common-form__body">
                <LineChart chart={allDataCharts(client, 'transactionDay')} />
              </div>
            </div>
          )}
          {!isEmptyVAL(client.transactionDate) && (
            <div className="usage-report__chart-tile">
              <header className="header-primary">
                <h2 className="usage-report__title">TRANSACTION DATES</h2>
                <span className="usage-report__title">
                  {usageReportRXS.selectedDate}
                </span>
              </header>
              <div className="common-form__body">
                <LineChart chart={allDataCharts(client, 'transactionDate')} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="single-btn-container">
      <button
          className="btn-primary"
          onClick={() => history.goBack()}
        >
          {transUTL('translateBtn.previousPage')}
        </button>
      </div>

    </div>
  );
};

UsageReport.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  usageReportRXS: PropTypes.object.isRequired,
  saveDataSourcesAXN: PropTypes.func.isRequired,
  saveSelectedClientAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  usageReportRXS: state.usageReportRXS,
});

export default connect(mapStateToProps, {
  saveDataSourcesAXN,
  saveSelectedClientAXN,
})(UsageReport);
