import * as Sentry from '@sentry/browser';
import { retainFormDataAXN } from '../../../actions/formData/formDataActions';
import { setAlertAXN } from '../../alert/alertActions';
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';

const inputMappingTH = (data, documentInfo, dispatch) => {
  
  try {
    //INPUT MAPPING FIELDS
    let firstName = '',
      lastName = '',
      dateOfBirth = '',
      nationalIdNo = '',
      laserNo = ''

    // Name Mapping Logic
    if (!isEmptyVAL(data.firstName)) {
      firstName = data.firstName;
    }
    if (!isEmptyVAL(data.lastName)) {
      lastName = data.lastName;
    }
    
    // DOB Mapping Logic
    let day = isEmptyVAL(data.dateOfBirth.day) ? 0 : data.dateOfBirth.day;
    day = day < 10 ? `0${day}` : day;
    let month = isEmptyVAL(data.dateOfBirth.month) ? 0 : data.dateOfBirth.month;
    month = month < 10 ? `0${month}` : month;
    let year = isEmptyVAL(data.dateOfBirth.year) ? 0 : data.dateOfBirth.year;
    dateOfBirth = `${year}-${month}-${day}`;  

    // National ID Mapping Logic
    if (!isEmptyVAL(documentInfo.DocumentNo)) {
      nationalIdNo = documentInfo.DocumentNo;
    }

    // LaserNo Mapping Logic
    if (!isEmptyVAL(data.idCardInfo.laserNo)) {
      laserNo = data.idCardInfo.laserNo;
    }
    
    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('thailand', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      lastName,
      dateOfBirth,
      laserNo,
      nationalIdNo,
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      retainFormDataAXN(
        { 
          personalInformation: {
            firstName,
            lastName,
            dateOfBirth,
          },
          nationalIDNumber: {
            nationalIdNo,
          },
          laserNumber: {
            laserNo,
          }
        },
        'thailand'
      )
    );
  } catch (err) {
    loggerUTL(data);
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException('Input mapping error...', data, err);
  }
};

export default inputMappingTH;
