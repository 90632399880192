const translateBiometrics = {
  facialMatchingResults: 'Hasil Pemadanan Muka',
  bioAuth: 'KEBENARAN BIOMETRIK',
  docScan: 'Imbasan Dokumen',
  manualSelection: 'pilih gambar secara manual ...',
  takePic: 'Menangkap',
  orTakePhoto: 'atau mengambil gambar',
  identityCard: 'Kad pengenalan diri',
  identityCardFront: 'Depan Kad Pengenalan',
  identityCardBack: 'Kad Pengenalan Kembali',
  noFiles: 'Tiada Cho Terpilih',
  uploaded: 'Dimuat naik!',
  selectOrClick: 'Pilih / KLIK pada gambar yang ingin anda bandingkan',
  documentCaptured: 'Dokumen Dirakam',
  detailsCaptured: 'Butiran Dirakam',
  documentAndSelfie: 'Perlawanan Dokumen & Selfie',
  Passed: 'Lulus',
  failed: 'Gagal',
  docUpload: 'Muat Naik Dokumen',
  selfieUpload: 'Muat Naik Selfie',
  // For New Document Scan Component
  takeAPicture: 'Ambil gambar',
  uploadAFile: 'Muat Naik Fail',
  uploadedDocuments: 'Dokumen yang dimuat naik',
  uploadMessage1: 'Sila muat naik bahagian depan dokumen (Ukuran maksimum: 9MB)',
  uploadMessage2: 'Sila muat naik bahagian belakang dokumen (Ukuran maksimum: 9MB)',
  frontSide: 'Bahagian depan',
  backSide: 'Bahagian Belakang',
  backSide2: 'Bahagian Belakang (Tidak Perlu)',
  memorySizeError: 'Saiz fail maksimum ialah 9MB',
  noFileError: 'Mesti memuat naik bahagian depan dokumen'
};

export default translateBiometrics;
