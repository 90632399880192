import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import loggerUTL from '../../../utils/loggerUTL';
import * as Sentry from '@sentry/browser';

const scanDataEditedTrigger = (country, isEdited) => {
  /*
   * PDF SCAN DATA EDITED
   * scanDataEdited field in PDF will change correspondingly according to pdfScanDataEdited
   */

  try {
    if (isEdited) {
      if (!isEmptyVAL(sessionStorage.pdfScanDataEdited)) {
        loggerUTL('Scanned Data is edited...');
        const parsed = JSON.parse(sessionStorage.pdfScanDataEdited);
        const stringObject = JSON.stringify({ ...parsed, [country]: true });
        sessionStorage.setItem('pdfScanDataEdited', stringObject);
      }
    } else {
      if (!isEmptyVAL(sessionStorage.pdfScanDataEdited)) {
        loggerUTL('Scanned Data is not edited...');
        const parsed = JSON.parse(sessionStorage.pdfScanDataEdited);
        const stringObject = JSON.stringify({ ...parsed, [country]: false });
        sessionStorage.setItem('pdfScanDataEdited', stringObject);
      } else {
        loggerUTL('Initialising pdfScanDataEdited...');
        const stringObject = JSON.stringify({
          [country]: false,
        });
        sessionStorage.setItem('pdfScanDataEdited', stringObject);
      }
    }
  } catch (err) {
    loggerUTL('ERROR: ', err);
    Sentry.captureException(err);
  }
};

export default scanDataEditedTrigger;
