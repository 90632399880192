import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const DigitalIDSafeguard = ({ results, type }) => {

  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesPH.digitalIDSafeguard.title'
        )}
      />
      <div style={{display: 'flex', gap: '150px', fontSize: '13px', marginLeft: '13px'}}>
        <p>Number of Hits:&nbsp; {returnedData.hitCount}</p>
        <p>Last Hit Date:&nbsp; {returnedData.lastHitDate}</p>
      </div>

      <p style={{fontSize: '13px', marginLeft: '13px', marginTop: '15px'}}>Hit Distribution & Forum Risk:</p>

      <div>
        <span id="circle-margin"></span>
        <span id="circle" style = {{backgroundColor: "rgba(255, 0, 0, 1)"}}>{returnedData.hitRiskVeryHigh}</span>
        <span id="circle" style = {{backgroundColor: "rgba(255, 0, 0, 0.75)"}}>{returnedData.hitRiskHigh}</span>
        <span id="circle" style = {{backgroundColor: "rgba(255, 0, 0, 0.5)"}}>{returnedData.hitRiskMedium}</span>
        <span id="circle" style = {{backgroundColor: "rgba(255, 0, 0, 0.2)"}}>{returnedData.hitRiskUnknown}</span>
      </div>

      <div id = "risk">
        <p id="risk-level-veryhigh">Very High Risk</p>
        <p id="risk-level-high">High Risk</p>
        <p id="risk-level-medium">Medium Risk</p>
        <p id="risk-level-unknown">Unknown</p>
      </div>
  
      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesPH.digitalIDSafeguard.isSafe'
                    )}
                  </p>
                  {verifications.isSafe === true ? <span className="material-icons success">check_circle</span> 
                  : <span className="material-icons error">cancel</span> 
                  }
                </div>
                <div style={{display: 'flex', gap: '150px', fontSize: '13px', marginLeft: '5px'}}>
                  <p>No. of Target Hits:&nbsp; {(isEmptyVAL(returnedData.hitTarget)) ? "N/A" : returnedData.hitTarget}</p>
                  <p>Last Hit Target Date:&nbsp; {(isEmptyVAL(returnedData.lastHitTargetDate)) ? "N/A" : returnedData.lastHitTargetDate}</p>
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};

DigitalIDSafeguard.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default DigitalIDSafeguard;
