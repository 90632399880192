const inputIT = {
  // PERSONAL INFORMATION
  firstName: 'John',
  middleName: 'Walker',
  lastName: 'Smith',
  dateOfBirth: '',
  gender: '',

  // IDENTITY VARIABLES
  taxIDNo: 'XYZXYZ00X00X000X',

  // CURRENT RESIDENTIAL ADDRESS
  streetNameAndHouseNo: 'DOMENICO BECCAFUMI 50',
  city: 'ROMA',
  province: 'AB',
  postCode: '00123',

  clientReference: '',
};

export default inputIT;
