// ACTION TYPES
import { SET_REGION, RESET_REGION } from '../types';

/* ============================================
          SAVE SELECTED REGION ACTION
   ============================================ */
export const saveSelectedRegionAXN = (region) => (dispatch) => {
  sessionStorage.setItem('selectedRegion', region);

  dispatch({
    type: SET_REGION,
    payload: region,
  });
};

/* ============================================
          REST SELECTED REGION ACTION
   ============================================ */
export const resetSelectedRegionAXN = () => (dispatch) => {
  dispatch({
    type: RESET_REGION,
  });
};
