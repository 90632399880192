// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataMY = (inputData, dataSources) => {
  let creditBureau;
  let nationalIDData;
  let watchlistAMLData;

  /* ============================================
              MALAYSIA CREDIT BUREAU
   ============================================ */
  if (dataSources.includes('Malaysia Credit Bureau')) {
    loggerUTL('WHITELISTING MALAYSIA CREDIT BUREAU...');
    const whitelisted = whitelistKeys(inputData, [
      'reportingReference',
      'nationalIDNo',
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'streetNo',
      'streetName',
      'city',
      'state',
      'postCode',
    ]);

    creditBureau = { ...whitelisted };
  }

  /* ============================================
              MALAYSIA NATIONAL ID
   ============================================ */
  if (dataSources.includes('Malaysia National ID')) {
    loggerUTL('WHITELISTING MALAYSIA NATIONAL ID...');
    const whitelisted = whitelistKeys(inputData, [
      'reportingReference',
      'nationalIDNo',
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dateOfBirth',
      'streetNo',
      'streetName',
      'city',
      'state',
      'postCode',
    ]);

    nationalIDData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...creditBureau,
    ...nationalIDData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataMY;
