import {
  SET_ORGANISATIONS,
  SET_ORGANISATION_USERS
} from '../../actions/types';

const initialState = {
  organisations: [],
  users: [],
}

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_ORGANISATION_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case SET_ORGANISATIONS:
      return {
        ...state,
        organisations: [...action.payload],
      }
    default:
      return state;
  }
}
