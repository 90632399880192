export const validatePassword = (newPassword, confirmPassword) => {

  // Validtion Rules:	
  // LENGTH >= 10 characters	
  // UNIQUE CHARS >= 8 unique characters	
  // CHARACTER TYPES >= at least 3 of the following: "upper case letters, lower case letters, numbers and symbols."	
  // PASSWORD AND CONFIRM PASSWORD MUST MATCH	

  const validationResult = {
    isValid: true,
    errMessage: '',
  }

  // Run checks	
  if (!checkLength(newPassword)) {
    validationResult.isValid = false;
    validationResult.errMessage = 'Password must contain 10 or more characters.';
  } else if (!checkUniqueChars(newPassword)) {
    validationResult.isValid = false;
    validationResult.errMessage = 'Password must contain 8 unique characters.';
  } else if (!checkCharacterTypes(newPassword)) {
    validationResult.isValid = false;
    validationResult.errMessage = 'Password must have at least 3 of the following: ' +
      'upper case letters, lower case letters, numbers, and symbols.';
  } else if (newPassword !== confirmPassword) {
    validationResult.isValid = false;
    validationResult.errMessage = 'Passwords do not match';
  }

  return validationResult;
}

export const checkLength = (password) => {
  return password.length >= 10;
}

export const checkUniqueChars = (password) => {
  const uniqueChars = [];

  for (let char of password) {
    if (!uniqueChars.includes(char)) {
      uniqueChars.push(char);
    }
  }

  return uniqueChars.length >= 8;
}

export const checkCharacterTypes = (password) => {
  let containsUpperCase = false;
  let containsLowerCase = false;
  let containsNumber = false;
  let containsSymbol = false;

  const specialChars = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const alphaLetters = /[a-zA-Z]/;

  for (let char of password) {
    if (alphaLetters.test(char)) {
      if (char === char.toLowerCase()) {
        containsLowerCase = true;
      }
      if (char === char.toUpperCase()) {
        containsUpperCase = true;
      }
    }
    if (specialChars.test(char)) {
      containsSymbol = true;
    }
    if (!isNaN(char)) {
      containsNumber = true;
    }
  }

  let charTypeCount = 0;
  if (containsLowerCase) charTypeCount++;
  if (containsUpperCase) charTypeCount++;
  if (containsNumber) charTypeCount++;
  if (containsSymbol) charTypeCount++;

  return charTypeCount >= 3;
}