import React from 'react';
import { transUTL } from '../../../utils/transUTL';
import TickBoxGroup from '../checkBoxGroup/TickBoxGroup';

const DisplayApiResults = ({isDisplayApiChecked, handleToggleTickbox}) => {

  return (
    <div className="form-overview__terms">
      {/* <p className="form-overview__terms-label">
        {transUTL('translateFormOverview.consentParagraph')}
      </p> */}
      <TickBoxGroup
        key="displayApi"
        name="displayApi"
        checkboxBool={isDisplayApiChecked}
        handleToggleTickbox={handleToggleTickbox}
        label={transUTL('translateFormOverview.apiResult')}
      />
    </div>
  );
};

export default DisplayApiResults;