import axios from 'axios';

import {
  SET_USAGE_REPORT_SELECTED_DATE,
  SET_USAGE_REPORT_SELECTED_CLIENT,
  SET_USAGE_REPORT_DATA,
} from '../types';

// AXIOS INSTANCE
import { proxyAPI } from '../../api/init';

// REDUX ACTIONS
import { loadingToggleAXN } from '../loading/loadingAction';

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';

// SET USAGE REPORT DATA ANALYTICS
export const setDataAnalytics = (usageData) => (dispatch) => {
  loggerUTL('SET DATA ANALYTICS...', usageData);
  localStorage.setItem('daUsageReport', JSON.stringify(usageData));

  dispatch({
    type: SET_USAGE_REPORT_DATA,
    payload: usageData,
  });
};

// SAVE SELECTED DATE
export const getUsageReportDataAXN = () => (dispatch) => {
  loggerUTL('GET USAGE REPORT...');
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  };

  const links = [
    //April 06 - April 12
    'https://www.dropbox.com/s/87xq5ge7zw7zfd9/Weekly%20April%2006%20-%20April%2012%202020.json?dl=1',
    //April 13 - April 19
    'https://www.dropbox.com/s/kcjw6qtalz22yro/Weekly%20April%2013%20-%20April%2019%202020.json?dl=1',
    //April 20 - April 26
    'https://www.dropbox.com/s/95ot8efd4d7hhb0/Weekly%20April%2020%20-%20April%2026%202020.json?dl=1',
    //April 27 - May 03
    'https://www.dropbox.com/s/wwtxugw9ts5fnsb/Weekly%20April%2027%20-%20May%2003%202020.json?dl=1',
    //May 04 - May 10
    'https://www.dropbox.com/s/swycrma0ke1gr9d/Weekly%20May%2004%20-%20May%2010%202020.json?dl=1',
    //May 11 - May 17
    'https://www.dropbox.com/s/ws92emi8561xvl3/Weekly%20May%2011%20-%20May%2017%202020.json?dl=1',
    //May 18 - May 24
    'https://www.dropbox.com/s/5vvkeddfzgf5k9t/Weekly%20May%2018%20-%20May%2024%202020.json?dl=1',
    //May 25 - May 31
    'https://www.dropbox.com/s/mzbe8cl43gsy1ou/Weekly%20May%2025%20-%20May%2031%202020.json?dl=1',
    //June 01 - June 07
    'https://www.dropbox.com/s/g789b6jcyo08iw2/Weekly%20June%2001%20-%20June%2007%202020.json?dl=1',
    //June 08 - June 14
    'https://www.dropbox.com/s/umcr21crztywlcj/Weekly%20June%2008%20-%20June%2014%202020.json?dl=1',
    //June 15 - June 21
    'https://www.dropbox.com/s/k5h75njdhkz3bdh/Weekly%20June%2015%20-%20June%2021%202020.json?dl=1',
    //June 22 - June 28
    'https://www.dropbox.com/s/suvd3vn89qszc27/Weekly%20June%2022%20-%20June%2028%202020.json?dl=1',
    //June 29 - July 05
    'https://www.dropbox.com/s/15j97xoltfq9urq/Weekly%20June%2029%20-%20July%2005%202020.json?dl=1',
    //July 06 - July 12
    'https://www.dropbox.com/s/72mrcxitt1upnq2/Weekly%20July%2006%20-%20July%2012%202020.json?dl=1',
    //July 13 - July 19
    'https://www.dropbox.com/s/95ns5v569o50j1r/Weekly%20July%2013%20-%20July%2019%202020.json?dl=1',
    //July 20 - July 26
    'https://www.dropbox.com/s/2d2dok71e98xlfm/Weekly%20July%2020%20-%20July%2026%202020.json?dl=1',
    //July 27 - Aug 02
    'https://www.dropbox.com/s/kbrjzqojs64txvb/Weekly%20July%2027%20-%20Aug%2002%202020.json?dl=1',
    //Aug 03 - Aug 09
    'https://www.dropbox.com/s/qdnqufxvdms2vyz/Weekly%20Aug%2003%20-%20Aug%2009%202020.json?dl=1',
    //Aug 10 - Aug 16
    'https://www.dropbox.com/s/5bphc8qgzq2s08i/Weekly%20Aug%2010%20-%20Aug%2016%202020.json?dl=1',
    //Aug 17 - Aug 23
    'https://www.dropbox.com/s/9iglqx6l1l9pp4e/Weekly%20Aug%2017%20-%20Aug%2023%202020.json?dl=1',
    //Aug 24 - Aug 30
    'https://www.dropbox.com/s/9sas3ouvhls1lk6/Weekly%20Aug%2024%20-%20Aug%2030%202020.json?dl=1',
    //April 2020
    'https://www.dropbox.com/s/gc9ubmd498c0crx/Monthly%20April%202020.json?dl=1',
    //May 2020
    'https://www.dropbox.com/s/d8eqhadpno121xi/Monthly%20May%202020.json?dl=1',
    //June 2020
    'https://www.dropbox.com/s/q65cco75juyfinz/Monthly%20June%202020.json?dl=1',
    //July Partial 2020
    'https://www.dropbox.com/s/fjq0vshj6r9r4gc/Monthly%20July%20Partial%202020.json?dl=1',
    //July 2020
    'https://www.dropbox.com/s/vakwxjpldyuoo57/Monthly%20July%202020.json?dl=1',
    //August 2020
    'https://www.dropbox.com/s/gz714s9wb03qloa/Monthly%20August%202020.json?dl=1'
  ];

  const requests = links.map(link => {
    return proxyAPI.get('/proxy', {
      headers: {
        proxy_url: link,
        ...headers
      }
    });
  });

  dispatch(loadingToggleAXN(true));
  axios
    .all(
      requests
    )
    .then(
      axios.spread((...responses) => {
        const usageData = responses.map(response => response.data);
        loggerUTL(usageData);
        dispatch(setDataAnalytics(usageData));
        dispatch(loadingToggleAXN(false));
        // use/access the results
      })
    )
    .catch((err) => {
      // react on errors.
      loggerUTL(err);
      dispatch(loadingToggleAXN(false));
    });
};

// SAVE SELECTED DATE
export const saveSelectedDateAXN = (selectedDate) => (dispatch) => {
  loggerUTL('SETTING SELECTED USAGE REPORT DATE...', selectedDate);
  sessionStorage.setItem('selectedDate', JSON.stringify(selectedDate));

  dispatch({
    type: SET_USAGE_REPORT_SELECTED_DATE,
    payload: selectedDate,
  });
};

// SAVE SELECTED CLIENT
export const saveSelectedClientAXN = (selectedClient) => (dispatch) => {
  loggerUTL('SETTING SELECTED CLIENT...', selectedClient);
  sessionStorage.setItem('selectedClient', JSON.stringify(selectedClient));

  dispatch({
    type: SET_USAGE_REPORT_SELECTED_CLIENT,
    payload: selectedClient,
  });
};
