// HELPERS
import title from './title';

// UTILS
import naUTL from '../../../utils/naUTL';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
// HELPERS

// TABLE HEAD
const tableHead = (Response, doc, docStyles) => {
  // FIRST TABLE (HEAD)
  const columnsHead = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];

  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  if (!watchlistAML) {
    watchlistAML = {
      verifications: {},
      returnedData: { watchlistResults: [{}] },
    };
  }

  let status;
  if (watchlistAML.status === 0) {
    status = 'Match Found';
  } else if (watchlistAML.status === 2) {
    status = 'Clear';
  } else if (watchlistAML.status === 3) {
    status = 'ERROR';
  } else {
    status = 'Not Searched';
  }

  const rowsHead = [
    {
      col1: 'International Watchlist PEP Status',
      col2: status,
    },
  ];

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable({
    columns: columnsHead, 
    body: rowsHead,
    didParseCell: (data) => {
      data.cell.styles.fillColor = docStyles.colorWhite;
      if (data.cell.raw === 'Clear') {
        data.cell.styles.textColor = docStyles.colorGreen;
        data.cell.styles.fontStyle = 'bold';
      } else if (data.cell.raw === 'Match Found' || data.cell.raw === 'ERROR') {
        data.cell.styles.textColor = docStyles.colorRed;
        data.cell.styles.fontStyle = 'bold';
      }
    },
    showHead: 'never',
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize,
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
      },
    },
    startY: finalY,
  });
};

// TABLE BODY
const tableBody = (Response, doc, docStyles) => {
  // SECOND TABLE (BODY)
  const columnsBody = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];
  const rowsBody = [];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  if (!watchlistAML) {
    watchlistAML = {
      verifications: {},
      returnedData: { watchlistResults: [{}] },
    };
  }

  let { returnedData } = watchlistAML;
  if (isEmptyVAL(returnedData.watchlistResults)) {
    returnedData = { watchlistResults: [{}] };
  }

  if (returnedData.watchlistResults) {
    const data = [
      {
        col1: 'Scan ID',
        col2: naUTL(returnedData.watchlistResults[0].scanId),
      },
      {
        col1: 'Data Zoo URL',
        col2: naUTL(returnedData.watchlistResults[0].additionalInfoURL),
      },
      {
        col1: 'Watchlist Category',
        col2: naUTL(returnedData.watchlistResults[0].category),
      },
    ];
    rowsBody.push(...data);
  } else {
    const data = [
      {
        col1: 'Scan ID',
        col2: isEmptyVAL(watchlistAML) ? 'Not Searched' : 'N/A',
      },
      {
        col1: 'Data Zoo URL',
        col2: isEmptyVAL(watchlistAML) ? 'Not Searched' : 'N/A',
      },
      {
        col1: 'Watchlist Category',
        col2: isEmptyVAL(watchlistAML) ? 'Not Searched' : 'N/A',
      },
    ];
    rowsBody.push(...data);
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.separation - 5;
  doc.autoTable({
    columns: columnsBody, 
    body: rowsBody, 
    didParseCell: (data) => {
      data.cell.styles.fillColor = docStyles.colorWhite;
    },
    showHead: 'never',
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
      },
    },
    startY: finalY,
  });
};

const watchListAML = (doc, data, info, docStyles) => {
  const { Response } = data;
  tableHead(Response, doc, docStyles);
  tableBody(Response, doc, docStyles);
};

const pepsAndSanctions = (doc, data, info, docStyles) => {
  title(
    doc,
    docStyles,
    'PEPs and Sanctions',
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );

  watchListAML(doc, data, info, docStyles);
};

export default pepsAndSanctions;
