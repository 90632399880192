const inputLabels = {
  // CREDENTIALS
  username: '用户名',
  password: '密码',
  // PERSONAL INFORMATION
  firstName: '名字',
  middleName: '中间名字',
  lastName: '姓',
  dateOfBirth: '出生日期',
  gender: '性别',
  fullName: '全名',
  yearOfBirth: '出生年份',

  // CURRENT RESIDENTIAL ADDRESS
  building: '建造',
  block: '街号',
  unitNo: '单位编号',
  addressLine1: '地址栏1',
  addressLine2: '地址栏2',
  street: '街',
  streetNo: '街道号',
  streetName: '街道名称',
  streetType: '街道类型',
  streetNumberAndName: '街道号和名称',
  townOrCity: '城镇或城市',
  suburb: '市郊',
  county: '县',
  state: '州',
  postCode: '邮政编码',
  postcode: '邮政编码',
  zipCode: '邮政编码',
  postCodeSG: '单位编号和邮政编码',
  streetNoAndUnitNo: '单位/街道号',
  city: '市',
  ward: '奖杯',
  region: '区域',
  barangay: '巴兰加',
  province: '省',
  locality: '地方性',
  district: '区',
  commune: '共同',
  town: '镇',
  streetNameIDN: '街',
  villageIDN: '村',
  districtIDN: '区（凯卡马丹）',
  cityIDN: '城市/自治区（行政区）',
  addressOCRVN: '地址',
  lga: '洛杉矶', // NIGERIA - Local Government Area
  streetNameAndHouseNo: '街道名称和房屋号码',
  regionCode: '地区代码', // ITALY, CZECH
  houseNumber: '房屋编号', // ITALY, CZECH

  // DRIVERS LICENCE
  driversLicenceNo: '牌照编号',
  driversLicenceNumber: '牌照编号',
  driversLicenceCardNo: '驾照卡号',
  driversLicenceStateOfIssue: '问题状态',
  driversLicenceExpiryDate: '到期日',
  driversLicenceVersion: '驾驶执照版本',
  vehiclePlate: '车辆登记',

  // MEDICARE
  medicareCardNo: '卡号',  
  medicareCardType: '医疗卡类型',
  medicareReferenceNo: '个人参考号',
  medicareExpiry: '到期日',

  // PASSPORT
  passportNo: '护照号',
  passportIssuerCountry: '签发国',
  passportExpiry: '到期日',
  countryCode: '国家代码',
  fileNo: '文件编号',
  dateOfIssue: '发行日期',

  // CONTACT DETAILS
  mobileNo: '手机号码',
  email: '电子邮件',
  emailID: '电子邮件',
  phoneType: '手机类型',
  phoneNumber: '电话号码。',
  telephone: '电话',
  emailAddress: '电子邮件地址',
  orgId: '组织ID',
  hitTarget: '命中目标',

  // CHINESE SEARCH
  idCardNo: '身份证号',
  bankCardNo: '银行卡号',
  phoneNo: '电话号码',
  ocrScan: '扫描和OCR',

  // IDENTITY DATA
  idNo: '身份证号',
  vin: '身份证号码',
  voterNo: '选民编号',
  nationalIDNo: '身份证号码',
  landlineNo: '身份证号码',
  nationalID: '身份证号码',
  nationalIDType: '身份证号码类型',
  curp: 'Curp',
  rfc: 'RFC',
  laserNo: '激光数',
  taxCodeNo: '税号',
  nationalIdNo: '身份证号码',

  // BIRTH CERTIFICATE
  birthRegistrationState: '出生登记州',
  birthRegistrationNo: '出生登记号',
  birthRegistrationDate: '出生登记日期',
  birthCertificateNo: '出生证明编号',
  isRequiredCertificateNo: '必须提供出生证明号码:',
  isRequiredCertificateNo_NSW: '2010年1月7日之后签发的新南威尔士州出生证明',
  isRequiredCertificateNo_SA: '1999年1月11日之后签发的南澳大利亚出生证明',
  isRequiredCertificateNo_Others: '所有澳大利亚首都领地和北领地出生证明', // ACT and NT in Australia
  placeOfBirth: '出生地',
  mothersFirstName: '母亲的名字',
  mothersLastName: '母亲的姓氏',
  fathersFirstName: '运动员名字',
  fathersLastName: '运动员的姓氏',

  // CENTERLINK
  centrelinkCardType: 'CENTERLINK卡类型',
  centrelinkCardExpiry: 'CENTERLINK卡到期',
  centrelinkCustomerReferenceNo: 'CENTERLINK客户参考号',

  // CITIZENSHIP
  countryOfBirth: '出生国家',
  citizenshipCertificateNo: '证书号码',

  // NATIONAL ID DATA
  idcardNoType: '证件类型',
  searchType: '搜索类型',

  // ACCOUNT NUMBER
  pan: '印度PAN',
  epic: '史诗编号',
  aadhaarNo: '印度AADHAAR',

  // RESIDENT IDENTITY CARD (NIK)
  nik: '一世',

  // CARD TYPE
  cardType: '卡的种类',

  // NIGERIA BANK NO
  bankVerificationNo: '银行验证编号',

  // MATERNAL NAMES
  maternalName: '物料名称',
  paternalName: '专有名称',

  // MOBILE VERIFICATION
  pinCode: 'PIN码',
  receiver: '手机号码。',
  sender: '从',

  // SOCIAL SECURITY
  socialSecurityNo: '社会保障号',

  //Consent
  consent: '我同意这条款和条件',

  // REPORTING REFERENCE
  reportingReference: '客户（您的）参考',
  clientReference: '客户（您的）参考',

  // TAX REGISTRATION
  taxIDNo: '税号',
  cpf: 'CPF (Cadastro de Pessoas Físicas)',
  npwp: 'NPWP (Nomor Pokok Wajib Pajak - 印尼登记税号)',

  // ABN
  ABN: '澳洲商家号码',
};
export default inputLabels;
