// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const europe = (dataSources) => {
  const userDataSources = [];
  // Remove when all europe becomes available.
  const currentlySupportedUserDataSources = [];

  // FILTERS
  const austria = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('austria')
  );
  const belgium = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('belgium')
  );
  const bulgaria = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('bulgaria')
  );
  const denmark = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('denmark')
  );
  const finland = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('finland')
  );
  const france = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('france')
  );
  const germany = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('germany')
  );
  const greece = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('greece')
  );
  const hungary = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('hungary')
  );
  const ireland = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('ireland')
  );
  const italy = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('italy')
  );
  const netherlands = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('netherlands')
  );
  const poland = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('poland')
  );
  const portugal = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('portugal')
  );
  const spain = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('spain')
  );
  const slovakia = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('slovakia')
  );
  const sweden = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('sweden')
  );
  const turkey = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('turkey')
  );
  const unitedKingdom = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('united kingdom')
  );
  const switzerland = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('switzerland')
  );
  const czechrepublic = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('czech republic')
  );

  // MODIFIERS
  if (!isEmptyVAL(austria)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'austria',
      dataSources: [...austria],
      belongsTo: 'europe',
      code: { alpha2: 'AT', alpha3: 'AUT' },
    });
  }
  if (!isEmptyVAL(belgium)) {
    userDataSources.push({
      region: 'europe',
      country: 'belgium',
      dataSources: [...belgium],
      belongsTo: 'europe',
      code: { alpha2: 'BE', alpha3: 'BEL' },
    });
  }
  if (!isEmptyVAL(bulgaria)) {
    userDataSources.push({
      region: 'europe',
      country: 'bulgaria',
      dataSources: [...bulgaria],
      belongsTo: 'europe',
      code: { alpha2: 'BG', alpha3: 'BGR' },
    });
  }
  if (!isEmptyVAL(denmark)) {
    userDataSources.push({
      region: 'europe',
      country: 'denmark',
      dataSources: [...denmark],
      belongsTo: 'europe',
      code: { alpha2: 'DK', alpha3: 'DNK' },
    });
  }
  if (!isEmptyVAL(finland)) {
    userDataSources.push({
      region: 'europe',
      country: 'finland',
      dataSources: [...finland],
      belongsTo: 'europe',
      code: { alpha2: 'FI', alpha3: 'FIN' },
    });
  }
  if (!isEmptyVAL(france)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'france',
      dataSources: [...france],
      belongsTo: 'europe',
      code: { alpha2: 'FR', alpha3: 'FRA' },
    });
  }
  if (!isEmptyVAL(germany)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'germany',
      dataSources: [...germany],
      belongsTo: 'europe',
      code: { alpha2: 'DE', alpha3: 'DEU' },
    });
  }
  if (!isEmptyVAL(greece)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'greece',
      dataSources: [...greece],
      belongsTo: 'europe',
      code: { alpha2: 'GR', alpha3: 'GRC' },
    });
  }
  if (!isEmptyVAL(hungary)) {
    userDataSources.push({
      region: 'europe',
      country: 'hungary',
      dataSources: [...hungary],
      belongsTo: 'europe',
      code: { alpha2: 'HU', alpha3: 'HUN' },
    });
  }
  if (!isEmptyVAL(ireland)) {
    userDataSources.push({
      region: 'europe',
      country: 'ireland',
      dataSources: [...ireland],
      belongsTo: 'europe',
      code: { alpha2: 'IE', alpha3: 'IRL' },
    });
  }
  if (!isEmptyVAL(italy)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'italy',
      dataSources: [...italy],
      belongsTo: 'europe',
      code: { alpha2: 'IT', alpha3: 'ITA' },
    });
  }
  if (!isEmptyVAL(netherlands)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'netherlands',
      dataSources: [...netherlands],
      belongsTo: 'europe',
      code: { alpha2: 'NL', alpha3: 'NLD' },
    });
  }
  if (!isEmptyVAL(poland)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'poland',
      dataSources: [...poland],
      belongsTo: 'europe',
      code: { alpha2: 'PL', alpha3: 'POL' },
    });
  }
  if (!isEmptyVAL(portugal)) {
    userDataSources.push({
      region: 'europe',
      country: 'portugal',
      dataSources: [...portugal],
      belongsTo: 'europe',
      code: { alpha2: 'PT', alpha3: 'PRT' },
    });
  }
  if (!isEmptyVAL(spain)) {
    userDataSources.push({
      region: 'europe',
      country: 'spain',
      dataSources: [...spain],
      belongsTo: 'europe',
      code: { alpha2: 'ES', alpha3: 'ESP' },
    });
  }
  if (!isEmptyVAL(slovakia)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'slovakia',
      dataSources: [...slovakia],
      belongsTo: 'europe',
      code: { alpha2: 'SK', alpha3: 'SVK' },
    });
  }
  if (!isEmptyVAL(belgium)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'belgium',
      dataSources: [...belgium],
      belongsTo: 'europe',
      code: { alpha2: 'BE', alpha3: 'BEL' },
    });
  }
  if (!isEmptyVAL(sweden)) {
    userDataSources.push({
      region: 'europe',
      country: 'sweden',
      dataSources: [...sweden],
      belongsTo: 'europe',
      code: { alpha2: 'SE', alpha3: 'SWE' },
    });
  }
  if (!isEmptyVAL(turkey)) {
    userDataSources.push({
      region: 'europe',
      country: 'turkey',
      dataSources: [...turkey],
      belongsTo: 'europe',
      code: { alpha2: 'TR', alpha3: 'TUR' },
    });
  }
  if (!isEmptyVAL(unitedKingdom)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'unitedKingdom',
      dataSources: [...unitedKingdom],
      belongsTo: 'europe',
      code: { alpha2: 'GB', alpha3: 'GBR' },
    });
  }
  if (!isEmptyVAL(switzerland)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'switzerland',
      dataSources: [...switzerland],
      belongsTo: 'europe',
      code: { alpha2: 'CH', alpha3: 'CHE'},
    });
  }
  if (!isEmptyVAL(czechrepublic)) {
    currentlySupportedUserDataSources.push({
      region: 'europe',
      country: 'czechrepublic',
      dataSources: [...czechrepublic],
      belongsTo: 'europe',
      code: { alpha2: 'CZ', alpha3: 'CZE'},
    });
  }

  // return userDataSources;
  return currentlySupportedUserDataSources;
};

export default europe;
