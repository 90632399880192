import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// REDUX ACTION
import {
  saveDataSourcesAXN,
  saveDataSourceSelectionsAXN,
} from "../../actions/dataSources/dataSourcesActions";
import { setAlertAXN } from "../../actions/alert/alertActions";

// BUTTON
import Button from "../../components/btn/Button";

// VALIDATIONS
import isEmptyVAL from "../../validations/checks/isEmptyVAL";

// UTILITIES
import { dataSourcesIsCheckedUTL } from "../../utils/outputMasterUTL";
import { transUTL } from "../../utils/transUTL";
import loggerUTL from "../../utils/loggerUTL";

//CONSTANTS
import COUNTRIES_FOR_TEMPLATE from "../../constants/countriesForTemplate";
import CACHE from "../../constants/cache";
import { AU_DATASOURCES } from "../../constants/dataSources";

const DataSourceSelection = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  dataSourceSelectionsRXS,
  saveDataSourcesAXN,
  saveDataSourceSelectionsAXN,
  setAlertAXN,
  history,
}) => {
  const { user } = authRXS;
  const [dataSourcesSTH, setDataSourcesSTH] = useState([]);
  const [disabledSourcesSTH, setDisabledSourcesSTH] = useState([]); //Aus death Check
  const [disabledSourcesRL, setDisabledSourcesRL] = useState([]); //Aus Residential Lookup

  let userDataSourcesAccess = {};
  let availableDataSources = [];
  if (countryRXS === "watchlistAML") {
  } else {
    userDataSourcesAccess = user.countries.find(
      (item) => item.country.toLowerCase() === countryRXS.toLowerCase()
    );
    if (
      !isEmptyVAL(userDataSourcesAccess) &&
      !isEmptyVAL(userDataSourcesAccess.dataSources)
    ) {
      availableDataSources = [...userDataSourcesAccess.dataSources];
    }
  }

  const { GLOBAL_DATA_SOURCE_SELECTIONS_CACHE } = CACHE;

  if (user.watchlist) {
    availableDataSources.push("Watchlist AML");
  }

  if (
    countryRXS === "canada" &&
    availableDataSources.includes("Canada Consumer")
  ) {
    availableDataSources = availableDataSources.filter(
      (item) => item !== "Canada Consumer"
    );
  }

  if (
    countryRXS === "canada" &&
    availableDataSources.includes("Canada Residential")
  ) {
    availableDataSources = availableDataSources.filter(
      (item) => item !== "Canada Residential"
    );
  }

  const { AU_DEATH_CHECK, AU_RESIDENTIAL_LOOKUP } = AU_DATASOURCES;
  const isTemplateApplied = COUNTRIES_FOR_TEMPLATE.includes(countryRXS);
  let dataSourcesArray = Object.entries(dataSourcesSTH);
  let otherAuSources_DeathCheck = dataSourcesArray.filter(
    (ds) => ds[0] !== AU_DEATH_CHECK
  );
  let otherAuSources_ResidentialLookup = dataSourcesArray.filter(
    (ds) => ds[0] !== AU_RESIDENTIAL_LOOKUP
  );
  let numOfOthersSelected_DeathCheck = otherAuSources_DeathCheck.filter(
    (ds) => ds[1]
  ).length;
  let numOfOthersSelected_ResidentialLookup =
    otherAuSources_ResidentialLookup.filter((ds) => ds[1]).length;

  const initialiseDataSourceSelections = () => {
    let extractedDataSource;
    /*
      This is a temporary solution to separate the template from existing
      services. Once the template is applied to all services, this needs to
      be changed.
    */

    if (isTemplateApplied) {
      //use the template
      extractedDataSource = dataSourceSelectionsRXS[countryRXS];
    } else {
      //use the old solution
      const suffix = "DataSources";
      const target = `${countryRXS}${suffix}`;
      extractedDataSource = dataSourcesRXS[target];
    }

    setDataSourcesSTH({ ...extractedDataSource });
  };
  useEffect(initialiseDataSourceSelections, []);

  // This is to prepare the disabledSources to render the disable/enable services' status
  const populateDisabledAuDataSources = () => {
    if (dataSourcesSTH[AU_DEATH_CHECK]) {
      setDisabledSourcesSTH([...otherAuSources_DeathCheck.map((s) => s[0])]);
      // setDisabledSourcesRL([]);
    } else if (numOfOthersSelected_DeathCheck > 0) {
      setDisabledSourcesSTH([AU_DEATH_CHECK]);
    }
    if (dataSourcesSTH[AU_RESIDENTIAL_LOOKUP]) {
      setDisabledSourcesRL([
        ...otherAuSources_ResidentialLookup.map((s) => s[0]),
      ]);
      // setDisabledSourcesSTH([]);
    } else if (numOfOthersSelected_ResidentialLookup > 0) {
      setDisabledSourcesRL([AU_RESIDENTIAL_LOOKUP]);
    }
  };
  useEffect(populateDisabledAuDataSources, [dataSourcesSTH]);

  const updateDataSourceSelections = () => {
    if (isTemplateApplied) {
      let globalDataSourceSelectionsCache = JSON.parse(
        sessionStorage.getItem(GLOBAL_DATA_SOURCE_SELECTIONS_CACHE)
      );
      if (!globalDataSourceSelectionsCache) {
        globalDataSourceSelectionsCache = {};
      }
      globalDataSourceSelectionsCache[countryRXS] = { ...dataSourcesSTH };
      sessionStorage.setItem(
        GLOBAL_DATA_SOURCE_SELECTIONS_CACHE,
        JSON.stringify(globalDataSourceSelectionsCache)
      );
      saveDataSourceSelectionsAXN(countryRXS, dataSourcesSTH);
    } else {
      saveDataSourcesAXN(countryRXS, dataSourcesSTH);
    }
  };
  useEffect(updateDataSourceSelections, [dataSourcesSTH]);

  const handleOnSubmit = () => {
    const selectedDataSources = dataSourcesIsCheckedUTL(dataSourcesSTH);

    if (isEmptyVAL(selectedDataSources))
      return setAlertAXN(
        transUTL("translateAlertMsg.pleaseSelectDataSource"),
        "error"
      );

    if (isTemplateApplied) {
      history.push("/form-overview-template");
    } else {
      history.push("/form-overview");
    }
  };

  const toggleAuDataSourcesCheckbox = (opt) => {
    if (opt === AU_DEATH_CHECK || opt === AU_RESIDENTIAL_LOOKUP) {
      if (
        numOfOthersSelected_DeathCheck === 0 &&
        opt !== AU_RESIDENTIAL_LOOKUP
      ) {
        // toggle death check
        setDataSourcesSTH({
          ...dataSourcesSTH,
          [opt]: !dataSourcesSTH[opt],
        });
        // disable other sources
        if (!dataSourcesSTH[opt]) {
          setDisabledSourcesSTH([
            ...otherAuSources_DeathCheck.map((s) => s[0]),
          ]);
        }
        // when unchecked death check
        else {
          setDisabledSourcesSTH([]);
          setDisabledSourcesRL([]);
        }
      }

      if (
        numOfOthersSelected_ResidentialLookup === 0 &&
        opt !== AU_DEATH_CHECK
      ) {
        // toggle residential lookup
        setDataSourcesSTH({
          ...dataSourcesSTH,
          [opt]: !dataSourcesSTH[opt],
        });
        // disable other sources
        if (!dataSourcesSTH[opt]) {
          setDisabledSourcesRL([
            ...otherAuSources_ResidentialLookup.map((s) => s[0]),
          ]);
        }
        // when unchecked residential lookup
        else {
          setDisabledSourcesRL([]);
          setDisabledSourcesSTH([]);
        }
      }
    } /* opt is other service */ else {
      // if death check is unselected
      if (
        !dataSourcesSTH[AU_DEATH_CHECK] &&
        !dataSourcesSTH[AU_RESIDENTIAL_LOOKUP]
      ) {
        /* 
          if current service is the only one that is selected, 
          It is going to unselect the current service and to make all services avillable 
        */
        if (dataSourcesSTH[opt] && numOfOthersSelected_DeathCheck === 1) {
          setDisabledSourcesSTH([]);
        }
        if (
          dataSourcesSTH[opt] &&
          numOfOthersSelected_ResidentialLookup === 1
        ) {
          setDisabledSourcesRL([]);
        }
        setDataSourcesSTH({
          ...dataSourcesSTH,
          [opt]: !dataSourcesSTH[opt],
        });
      }
    }
  };

  const handelCheckbox = (opt) => {
    if (countryRXS === "australia") {
      toggleAuDataSourcesCheckbox(opt);
    } else {
      setDataSourcesSTH({
        ...dataSourcesSTH,
        [opt]: !dataSourcesSTH[opt],
      });
    }
  };

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>{transUTL(`translateCountryNames.${countryRXS}`)}</h2>

        <span
          className="material-icons icon-size"
          title={transUTL("translateTitle.nextPage")}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <p className="common-form__info">
          {transUTL("translateDataSourceSelection.select")}
        </p>

        <div className="data-source-selection__box">
          {loggerUTL("userDataSourcesAcess: ", userDataSourcesAccess)}
          {availableDataSources.sort().map((item) => {
            // Could delete this if condition once CB ALT not past in from userDataSources
            if (item === "Hong Kong Credit Bureau alt") return <></>;

            const dataSource = item.split(" ").join("").toLowerCase();

            const i18nTitleKey =
              dataSource === "canadaresidential" ||
              dataSource === "argentinacitizens" ||
              dataSource === "canadaconsumer"
                ? ""
                : `translateDataSources.${dataSource}`;

            return dataSource === "canadaresidential" ||
              dataSource === "argentinacitizens" ||
              dataSource === "canadaconsumer" ? (
              ""
            ) : (
              <div
                key={item}
                className="data-source-selection__checkbox"
                onClick={() => handelCheckbox(item)}
              >
                <h3 className="data-source-selection__title">
                  {transUTL(i18nTitleKey)}
                </h3>
                {disabledSourcesSTH.includes(item) ||
                disabledSourcesRL.includes(item) ? (
                  <span className="material-icons data-source-selection__icon-disabled">
                    check_box_outline_blank
                  </span>
                ) : (
                  <>
                    {dataSourcesSTH[item] ? (
                      <span className="material-icons data-source-selection__icon-checked">
                        check_box
                      </span>
                    ) : (
                      <span className="material-icons data-source-selection__icon-unchecked">
                        check_box_outline_blank
                      </span>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/country-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL("translateBtn.previousPage")}
        btn2Text={transUTL("translateBtn.nextPage")}
      />
    </div>
  );
};

DataSourceSelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  saveDataSourcesAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  dataSourceSelectionsRXS: state.dataSourceSelectionsRXS,
});

export default connect(mapStateToProps, {
  saveDataSourceSelectionsAXN,
  saveDataSourcesAXN,
  setAlertAXN,
})(DataSourceSelection);
