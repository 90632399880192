// import * as Sentry from '@sentry/browser';

// TYPES
import {
  SET_SEARCH_VERIFICATION,
  RESET_SEARCH_VERIFICATION,
} from '../../actions/types';

// REDUX ACTION
import { loadingToggleAXN } from '../../actions/loading/loadingAction';

// AXIOS INSTANCE
import { dzAPI } from '../../api/init';

// HELPERS
import setDateTime from './helpers/setDateTime';

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';
import { inputFormFeedbackErrorController } from '../errorController/errorControllerActions';
import COUNTRIES_FOR_TEMPLATE from '../../constants/countriesForTemplate';
import { ERROR_FEEDBACK } from '../types';

export const setVerificationResultsAXN = (results) => (dispatch) => {
  loggerUTL(results, 'SETTING SEARCH VERIFICATION RESULT...');
  sessionStorage.setItem('searchVerification', JSON.stringify(results));
  dispatch({ type: SET_SEARCH_VERIFICATION, payload: results });
};

export const resetSearchVerificationResultsAXN = () => (dispatch) => {
  dispatch({ type: RESET_SEARCH_VERIFICATION });
};

export const searchVerificationAXN = (submitData, history, country) => (dispatch) => {
  loggerUTL('SUBMITTING...', submitData);
  dispatch(loadingToggleAXN(true));

  dzAPI
    .post('/api/v2/verify', submitData)
    .then((dzRes) => {
      const dzData = dzRes.data;

      dzData.dateTime = setDateTime();

      dispatch(setVerificationResultsAXN(dzData));

      if(COUNTRIES_FOR_TEMPLATE.includes(country)) {
        history.push('/search-verification-template');
      } 
      else if (country === "findIDU") {
        history.push('/find-idu-search-verification');
      }
      else if (country === "digitalIdSafeguard") {
        history.push('/digital-id-safeguard-search-verification');
      }
      else {
        history.push('/search-verification');
      }

      dispatch(loadingToggleAXN(false));

      /*
        When users' input doesn't pass back end validation, errors shows up in the related fields.
        Next time if users' input passes the validation and they are redirected to the result page, 
        then they click the "Previous" button to go back to the form, the errors are still there because
        the errors are not cleared up, which is confusing. That's why this dispatch is added.
      */
      dispatch({
        type: ERROR_FEEDBACK,
        payload: {},
      });
    })
    .catch((err) => {
      loggerUTL('ERROR...', err);
      // TODO: SET UP VALIDATION AND FIX SENTRY ERROR LOGGING

      if (!isEmptyVAL(err.response)) {
        const { status, data } = err.response;
        if (status === 400) {
          // PROVIDE ERROR FEEDBACK TO USER
          dispatch(inputFormFeedbackErrorController(data));
        }
      }
      dispatch(loadingToggleAXN(false));
    });
};
