import * as Sentry from '@sentry/browser';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { setAlertAXN } from '../../alert/alertActions';
import { retainFormDataAXN } from '../../../actions/formData/formDataActions';
import { saveOcrResponseDataAXN } from "../biometricActions";
import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';

const inputMappingMA = (data, dispatch) => {
  try {
    let firstName = '',
      lastName = '',
      dateOfBirth = '',
      gender = '';

    if (!isEmptyVAL(data.firstName)) {
      firstName = data.firstName;
    }
    if (!isEmptyVAL(data.lastName)) {
      lastName = data.lastName;
    }
    if (
      !isEmptyVAL(data.dateOfBirth.day) &&
      !isEmptyVAL(data.dateOfBirth.month) &&
      !isEmptyVAL(data.dateOfBirth.year)
    ) {
      if (data.dateOfBirth.month < 10)
        data.dateOfBirth.month = `0${data.dateOfBirth.month}`;
      if (data.dateOfBirth.day < 10)
        data.dateOfBirth.day = `0${data.dateOfBirth.day}`;
      dateOfBirth = `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.day}`;
    }
    if (!isEmptyVAL(data.sex)) {
      const sex = data.sex.toLowerCase();
      if (sex === 'f' || sex === 'female') {
        gender = 'F';
      }
      if (sex === 'm' || sex === 'male') {
        gender = 'M';
      }
    }

    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('morocco', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      lastName,
      dateOfBirth,
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      retainFormDataAXN(
        {
          personalInformation: {
            firstName,
            lastName,
            dateOfBirth,
            gender
          }
        },
        'morocco'
      )
    );
  } catch (err) {
    loggerUTL(data);
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException('Input mapping error...', data, err);
  }
};

export default inputMappingMA;