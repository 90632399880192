// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { residentIdentityCard, taxRegistration, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(residentIdentityCard.status),
      col3: naUTL(watchlistAML.status),
      col4: naUTL(taxRegistration.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(residentIdentityCard.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
      col4: naUTL(taxRegistration.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(residentIdentityCard.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
      col4: naUTL(taxRegistration.identityVerified),
    },
    {
      col1: 'NIK',
      col2: naUTL(residentIdentityCard.verifications.nik),
      col3: naUTL(watchlistAML.verifications.nik),
      col4: naUTL(taxRegistration.verifications.nik),
    },
    {
      col1: 'NPWP',
      col2: naUTL(residentIdentityCard.verifications.npwp),
      col3: naUTL(watchlistAML.verifications.npwp),
      col4: naUTL(taxRegistration.verifications.npwp),
    },
    {
      col1: 'Full Name Verified',
      col2: naUTL(residentIdentityCard.verifications.fullName),
      col3: naUTL(watchlistAML.verifications.fullName),
      col4: naUTL(taxRegistration.verifications.fullName),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(residentIdentityCard.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
      col4: naUTL(taxRegistration.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(residentIdentityCard.verifications.gender),
      col3: naUTL(watchlistAML.verifications.gender),
      col4: naUTL(taxRegistration.verifications.gender),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(residentIdentityCard.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
      col4: naUTL(taxRegistration.nameMatchScore),
    },
    {
      col1: 'Alamat/Kelurahan Verified',
      col2: naUTL(residentIdentityCard.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1),
      col4: naUTL(taxRegistration.verifications.addressElement1),
    },
    {
      col1: 'Kecamatan Verified',
      col2: naUTL(residentIdentityCard.verifications.addressElement2),
      col3: naUTL(watchlistAML.verifications.addressElement2),
      col4: naUTL(taxRegistration.verifications.addressElement2),
    },
    {
      col1: 'Kabupaten Verified',
      col2: naUTL(residentIdentityCard.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5),
      col4: naUTL(taxRegistration.verifications.addressElement5),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(residentIdentityCard.addressMatchScore),
      col3: naUTL(watchlistAML.addressMatchScore),
      col4: naUTL(taxRegistration.verifications.addressMatchScore),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
