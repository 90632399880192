import isEmptyVAL from '../checks/isEmptyVAL';

import { transUTL } from '../../utils/transUTL';
import loggerUTL from '../../utils/loggerUTL';

const digitalIdSafeguardVAL = (data, dataSources) => {
  const inputErrors = {};

  /* ============================================
          Digital ID Safeguard
  ============================================ */
  if (dataSources.includes('Digital ID Safeguard')) {

    loggerUTL('VALIDATE Digital ID Safeguard...');
    if (isEmptyVAL(data.email)) {
      inputErrors.email = transUTL('translateFormOverview.formErrors.email'); //
    }
  }
  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default digitalIdSafeguardVAL;
