import React from "react";

import Header from "../../../../../components/header/Header";

import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";
import { transUTL } from "../../../../../utils/transUTL";

const PhilippinesCreditBureau = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          "translateSearchVerification.dataSourcesPH.creditBureau.title"
        )}
      />
      {status === 3 ? (
        <div className="search-verification__verify">
          <div className="search-verification__col">
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.searchSuccessful"
                )}
              </p>
              <span className="material-icons error">cancel</span>
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.requestTimedOut"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="search-verification__verify">
          <div className="search-verification__col">
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.searchSuccessful"
                )}
              </p>
              <span className="material-icons success">check_circle</span>
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.safeHarbourScore"
                )}
              </p>
              {!isEmptyVAL(safeHarbourScore) ? (
                <span>{safeHarbourScore}</span>
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.identityVerified"
                )}
              </p>
              {!isEmptyVAL(identityVerified) ? (
                identityVerified ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.addressMatchScore"
                )}
              </p>
              {!isEmptyVAL(addressMatchScore) ? (
                <span>{addressMatchScore}</span>
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.nameMatchScore"
                )}
              </p>
              {!isEmptyVAL(nameMatchScore) ? (
                <span>{nameMatchScore}</span>
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
          </div>
          <div className="search-verification__col">
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.firstName"
                )}
              </p>
              {!isEmptyVAL(verifications.firstName) ? (
                verifications.firstName ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.middleName"
                )}
              </p>
              {!isEmptyVAL(verifications.middleName) ? (
                verifications.middleName ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.lastName"
                )}
              </p>
              {!isEmptyVAL(verifications.lastName) ? (
                verifications.lastName ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.dateOfBirth"
                )}
              </p>
              {!isEmptyVAL(verifications.dateOfBirth) ? (
                verifications.dateOfBirth ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.nationalIDNo"
                )}
              </p>
              {!isEmptyVAL(verifications.nationalIDNo) ? (
                verifications.nationalIDNo ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
          </div>
          <div className="search-verification__col">
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.streetName"
                )}
              </p>
              {!isEmptyVAL(verifications.addressElement1) ? (
                  verifications.addressElement1 ? (
                    <span className="material-icons success">check_circle</span>
                  ) : (
                    <span className="material-icons error">cancel</span>
                  )
                ) : (
                  <span>{transUTL("translateReuse.na")}</span>
                )
              }
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.barangay"
                )}
              </p>
              {!isEmptyVAL(verifications.addressElement2) ? (
                verifications.addressElement2 ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.city"
                )}
              </p>
              {!isEmptyVAL(verifications.addressElement3) ? (
                verifications.addressElement3 ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.residential.province"
                )}
              </p>
              {!isEmptyVAL(verifications.addressElement4) ? (
                verifications.addressElement4 ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.creditBureau.postcode"
                )}
              </p>
              {!isEmptyVAL(verifications.addressElement5) ? (
                verifications.addressElement5 ? (
                  <span className="material-icons success">check_circle</span>
                ) : (
                  <span className="material-icons error">cancel</span>
                )
              ) : (
                <span>{transUTL("translateReuse.na")}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhilippinesCreditBureau;
