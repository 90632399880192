const translateTitle = {
  // NAVIGATION
  chinese: '中文',
  malay: 'Malay',
  english: 'English',
  logo: 'Logo',
  profile: 'Profile',
  dashboard: 'Dashboard',
  history: 'History',
  dataAnalytics: 'Data Analytics',
  documentLibrary: 'Document Library',
  aboutUs: 'About Us',
  logout: 'Logout',
  nextPage: 'Next Page',
  costEstimation: 'Cost Estimation',
  admin: 'Admin'
};

export default translateTitle;
