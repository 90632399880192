import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';
import { saveUserInputAXN } from '../../../../actions/formData/userInputActions';
import { saveApiReqAXN } from '../../../../actions/formData/apiReqActions';

// HELPERS
import initialStateDE from './helpers/initialStateDE';
import whitelistedInputDataDE from './helpers/whitelistedInputDataDE';
import consentCheckerDE from './helpers/consentCheckerDE';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import germanyVAL from '../../../../validations/inputFields/germanyVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';
import DisplayApiController from '../../../../components/formController/DisplayApiController';

// FORM INPUTS
import PersonalInformationGermany from './inputForms/PersonalInformationGermany';
import ContactDetailsGermany from './inputForms/ContactDetailsGermany';
import CurrentResidentialAddressGermany from './inputForms/CurrentResidentialAddressGermany';
import ClientReference from '../../reusableForms/ClientReference';
import TermsAndConditionsGermany from './inputForms/TermsAndConditionsGermany';
import ApiResult from '../../reusableForms/ApiResult';

// BUTTON
import Button from '../../../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import rmSpaceUTL from '../../../../utils/rmSpaceUTL';
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL"; 

const GermanyForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateDE);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.germanyDataSources
  );

  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.germanyFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.germanyFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  const handleToggleCheckbox = () => {
    loggerUTL('CHECKBOX...');
    const {
      creditBureauConsentObtained,
    } = userInputSTH;

    let creditBureauConsent = false;

    if (selectedDataSources.includes('Germany Credit Bureau')) {
      creditBureauConsent = !creditBureauConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      creditBureauConsentObtained: creditBureauConsent,
    });
  };

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = germanyVAL(inputData, selectedDataSources);
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // USER CONSENT CHECK
    const { isConsent, consentObtained } = consentCheckerDE(
      selectedDataSources,
      inputData
    );
    
    loggerUTL(isConsent, consentObtained);
    
    // PROVIDE ERROR FEEDBACK IF CONSENT IS NOT CHECKED
    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    loggerUTL("INPUT DATA: ", inputData);
    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataDE(inputData, selectedDataSources);

    loggerUTL("WHITELISTED DATA: ", whitelisted);
    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // ADDRESS MANIPULATION
    let addressElement1;
    let addressElement2;

    if (service.includes('Germany Credit Bureau')) {
      addressElement1 = rmSpaceUTL(inputData.streetNo);
      addressElement2 = rmSpaceUTL(inputData.streetAddress);

      addressElement1 = addressElement2 + " " + addressElement1;
      addressElement2 = "";
    } else {
      addressElement1 = rmSpaceUTL(inputData.streetAddress); // REMOVES ADDITIONAL WHITE SPACES
    }

    const addressElement3 = rmSpaceUTL(inputData.city);
    const addressElement4 = rmSpaceUTL(inputData.state);
    const addressElement5 = rmSpaceUTL(inputData.postCode);

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      consentObtained,
      addressElement1,
      addressElement2,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    saveApiReqAXN(submitData, countryRXS);
    searchVerificationAXN(submitData, history);
    saveUserInputAXN(inputData.displayApiResultObtained, countryRXS);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // FIRES THE MOMENT THE ELEMENT LOSES FOCUS
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const { 
      errors, 
      creditBureauConsentObtained, 
      displayApiResultObtained,
      ...filteredFormData 
    } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // WHEN USER HITS THE ENTER DURING/AFTER INPUT IT TRIGGERS A STATE SAVE
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');

    // OMITS `errors`
    const { 
      errors,
      displayApiResultObtained,
      ...reset 
    } = initialStateDE;

    // RESETS TO INITIAL STATE
    setUserInputSTH(initialStateDE);

    // RESETS GERMANY FORM DATA
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  const handleToggleTickbox = (opt) => {
    loggerUTL('TICKBOX...');
    const {
      displayApiResultObtained,
    } = userInputSTH;

    let displayApiResult = false;

    displayApiResult = !displayApiResultObtained;

    setUserInputSTH({
      ...userInputSTH,
      displayApiResultObtained: displayApiResult,
    });
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
          };
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded selfie 
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  // ALL THESES DATA SOURCES HERE DOES NOT REQUIRE CONSENT
  // UI - SHOWS (CLIENT REFERENCE) SECTION
  conditionalClientRef.push(...[
    'Germany Residential',
    'Germany Credit Bureau',
    'Germany Utility',
    'Watchlist AML'
  ]);


  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'Germany Residential',
        'Germany Credit Bureau',
        'Germany Utility',
        'Germany Phone',
        'Watchlist AML',
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationGermany
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Germany Residential',
        'Germany Credit Bureau',
        'Germany Utility',
        'Germany Phone',
      ],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressGermany
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Germany Utility',
        'Germany Phone',
      ],
      element: (
        <FormController
          key="contactDetails"
          title={transUTL('translateFormOverview.formHeaders.contactDetails')}
          inputForm={
            <ContactDetailsGermany
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Germany Credit Bureau',
      ],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            'translateFormOverview.formHeaders.termsAndConditions'
          )}
          inputForm={
            <TermsAndConditionsGermany
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Germany Residential',
        'Germany Credit Bureau',
        'Germany Utility',
        'Germany Phone',
        'Watchlist AML',
      ],
      element: (
        <DisplayApiController
          key="displayApi"
          inputForm={ 
            <ApiResult
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleTickbox={handleToggleTickbox}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

GermanyForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  saveUserInputAXN: PropTypes.func.isRequired,
  saveApiReqAXN: PropTypes.func.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  apiReqRXS: state.apiReqRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(GermanyForms));
