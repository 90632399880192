import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackAR = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {
    personalInformation: {
    },
    personalName: {
    },
    personalInformationSuper:{
    },
    nationalIDNumber: {
    },
    phoneNumber: {
    }
  };

  try {
    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
      inputError.personalName.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
      inputError.personalInformationSuper.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
      inputError.personalName.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
      inputError.personalInformationSuper.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
      inputError.personalName.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
      inputError.personalInformationSuper.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }
    
    if (findErrorUTL(errors, 'nationalIDNo')) {
      inputError.nationalIDNumber.nationalIDNo = `National ID Number is ${
        errors.find((item) => item.includes('nationalIDNo')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'phoneNo')) {
      inputError.phoneNumber.phoneNo = `Phone Number is ${
        errors.find((item) => item.includes('phoneNo')).split(':')[1]
      }`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE ARGENTINA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackAR;

