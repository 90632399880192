// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataMX = (inputData, dataSources) => {
  let nationalIDDate, taxRegistrationData, watchlistAMLData;

  /* ============================================
              MEXICO NATIONAL ID
   ============================================ */
  if (dataSources.includes('Mexico National ID')) {
    loggerUTL('WHITELISTING MEXICO NATIONAL ID...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dateOfBirth',
      'rfc',
      'curp',
      'reportingReference',
    ]);

    nationalIDDate = { ...whitelisted };
  }

  /* ============================================
              MEXICO TAX REGISTRATION
   ============================================ */
  if (dataSources.includes('Mexico Tax Registration')) {
    loggerUTL('WHITELISTING MEXICO TAX REGISTRATION...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dateOfBirth',
      'rfc',
      'curp',
      'reportingReference',
    ]);

    taxRegistrationData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...nationalIDDate,
    ...taxRegistrationData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataMX;
