import React from "react";
import Header from "../../../../../components/header/Header";
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";
import { transUTL } from "../../../../../utils/transUTL";

const WatchlistAML = ({ results, type }) => {

  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};
  if (!isEmptyVAL(returnedData.watchlistResults)) {
    for (let i = 0; i < returnedData.watchlistResults.length; i++) {
      delete returnedData.watchlistResults[i].whitelist; // Delete Watchlist colomn as it is not needed to map in FE
    } 
  }

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          "translateSearchVerification.dataSourcesPH.watchlistAml.title"
        )}
      />
      <table id="find-idu-table">
        <tbody>
          <tr>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.additionalInfoURL")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.category")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.deathIndex")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.gender")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.otherNames")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.scanId")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.watchlistAml.yearOfBirth")}</th>
          </tr>
        </tbody>

        <tbody>
          {isEmptyVAL(returnedData) ? 
            <tr>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
            </tr>:
            returnedData.watchlistResults.map((item) => (

            <tr>
              {Object.entries(item).map((val) => { 
              if (val[0] === "additionalInfoURL") {
                return (
                  <td onClick={()=> window.open(val[1], "_blank")} style={{cursor:'pointer'}}>{val[1]}</td>
                )
              }
              if (typeof val[1] === "string") {
                return (
                  <td>{val[1]}</td>
                )
              }
              if (typeof val[1] === "object" && !isEmptyVAL (val[1])) {
                return (
                  <td>{val[1].toString()}</td>
                )
              }
              if (typeof val[1] === "object") {
                return (
                  <td>{"N/A"}</td>
                )
              }
              else {
                return (
                <td>{val[1].toString()}</td>
                )
              }
            })}
            </tr>
              ))
         }
        </tbody>
      </table>

      {status === 3 ? (
        <div className="search-verification__verify">
          <div className="search-verification__col">
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.watchlistAml.searchSuccessful"
                )}
              </p>
              <span className="material-icons error">cancel</span>
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.watchlistAml.requestTimedOut"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="search-verification__verify">
        </div>
      )}
    </div>
  );
};

export default WatchlistAML;
