import * as Sentry from "@sentry/browser";
import moment from "moment";

// ACTION TYPES
import {
  SET_MULTI_DOCS,
  DELETE_MULTI_DOCS,
  FACIAL_RECOGNITION_DATA,
  SET_SELFIE,
  BIO_BOOLEAN_TRIGGERS,
  REST_BIOMETRICS,
  SET_IDENTITY_DOC,
  SAVE_OCR_RESPONSE_DATA,
} from "../types";

// REDUX ACTIONS
import { loadingToggleAXN } from "../loading/loadingAction";
import { saveFormDataAXN } from "../formData/formDataActions";
import { setAlertAXN } from "../alert/alertActions";

// AXIOS INSTANCE
import { dzAPI } from "../../api/init";

// DAON INPUT MAPPINGS
import inputMappingAU from "./inputMapping/inputMappingAU";
import inputMappingCN from "./inputMapping/inputMappingCN";
import inputMappingID from "./inputMapping/inputMappingID";
import inputMappingNZ from "./inputMapping/inputMappingNZ";
import inputMappingPH from "./inputMapping/inputMappingPH";
import inputMappingIN from "./inputMapping/inputMappingIN";
import inputMappingMY from "./inputMapping/inputMappingMY";
import inputMappingMA from "./inputMapping/inputMappingMA";
import inputMappingSG from "./inputMapping/inputMappingSG";
import inputMappingTH from "./inputMapping/inputMappingTH";

// VALIDATIONS
import isEmptyVAL from "../../validations/checks/isEmptyVAL";

// UTILITIES
import { transUTL } from "../../utils/transUTL";
import blobToFileUTL from "../../utils/blobToFileUTL";
import loggerUTL from "../../utils/loggerUTL";
import scanDataEditedTrigger from "../biometrics/helpers/scanDataEditedTrigger";
import splitFullName from "../biometrics/helpers/splitFullName";
import COUNTRIES_FOR_TEMPLATE from "../../constants/countriesForTemplate";

export const saveOcrResponseDataAXN = (ocrData) => (dispatch) => {
  sessionStorage.setItem("ocrResponseData", JSON.stringify(ocrData));
  dispatch({
    type: SAVE_OCR_RESPONSE_DATA,
    payload: ocrData,
  });
};

/* ============================================
      Redux for OCR, BIO, DOC, SELFIE 
   ============================================ */
export const bioTriggerBooleansAXN = (triggerBoolean) => (dispatch) => {
  sessionStorage.setItem("bioTriggers", JSON.stringify(triggerBoolean));
  dispatch({
    type: BIO_BOOLEAN_TRIGGERS,
    payload: triggerBoolean,
  });
};

/* ============================================
      SAVE DOCUMENT SCAN AND DOCUMENT UPLOAD
   ============================================ */
export const saveIdentityDocumentsAXN = (capturedImages) => (dispatch) => {
  sessionStorage.setItem(
    "identityDocumentScans",
    JSON.stringify(capturedImages)
  );

  dispatch({
    type: SET_MULTI_DOCS,
    payload: capturedImages,
  });
};

export const saveIdentityDocumentAXN = (updatedDocument) => (dispatch) => {
  sessionStorage.setItem("identityDocument", JSON.stringify(updatedDocument));

  dispatch({
    type: SET_IDENTITY_DOC,
    payload: updatedDocument,
  });
};

/* ============================================
          SAVE SELFIE UPLOAD
   ============================================ */
export const saveSelfieAXN = (dataURI) => (dispatch) => {
  sessionStorage.setItem("selfieURI", JSON.stringify(dataURI));

  dispatch({
    type: SET_SELFIE,
    payload: dataURI,
  });
};
/* ============================================
        DELETE SELECTED IDENTITY DOCUMENT
   ============================================ */
export const deleteSelectedIdentityDocumentsAXN = (index) => (dispatch) => {
  dispatch({
    type: DELETE_MULTI_DOCS,
    payload: index,
  });
};

/* ============================================
              VIETNAM OCR
   ============================================ */
export const vietnamIdentityDocumentScanAXN =
  (formData, history) => (dispatch) => {
    dispatch(loadingToggleAXN(true));

    dzAPI
      .post("/api/v2/verify-with-attachments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loggerUTL("RESPONSE...", res);
        const { serviceResponses } = res.data;

        if (
          !isEmptyVAL(serviceResponses) &&
          !isEmptyVAL(serviceResponses["Vietnam National ID OCR"])
        ) {
          let { address, dateOfBirth, fullName, documentNo } =
            res.data.serviceResponses["Vietnam National ID OCR"].returnedData;
          let firstName, lastName, middleName;

          if (
            isEmptyVAL(
              res.data.serviceResponses["Vietnam National ID OCR"].returnedData
            )
          ) {
            dispatch(loadingToggleAXN(false));
            dispatch(
              setAlertAXN(
                transUTL("translateAlertMsg.searchUnSuccessful"),
                "error"
              )
            );
            return;
          }

          dateOfBirth = dateOfBirth.split("-");
          if (Number(dateOfBirth[0]) < 10) {
            dateOfBirth[0] = `${dateOfBirth[0]}`;
          }
          if (Number(dateOfBirth[1]) < 10) {
            dateOfBirth[1] = `${dateOfBirth[1]}`;
          }
          dateOfBirth = dateOfBirth.reverse().join("-");

          if (!isEmptyVAL(fullName)) {
            let { FirstName, MiddleName, LastName } = splitFullName(fullName);
            firstName = FirstName;
            middleName = MiddleName;
            lastName = LastName;
          }

          loggerUTL("Triggering inisializing scanDataEdited...");
          scanDataEditedTrigger("vietnam", false);
          // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
          let ocrResponseData = {
            fullName,
            firstName,
            middleName,
            lastName,
            dateOfBirth,
            idCardNo: documentNo,
            address,
          };
          loggerUTL("SAVING SCANNED DATA...", ocrResponseData);
          dispatch(saveOcrResponseDataAXN(ocrResponseData));

          dispatch(
            saveFormDataAXN(
              {
                fullName,
                dateOfBirth,
                idCardNo: documentNo,
                address,
              },
              "vietnam"
            )
          );
        }

        history.push("/form-overview");

        dispatch(loadingToggleAXN(false));
      })
      .catch((err) => {
        loggerUTL("ERROR...", err);
        dispatch(loadingToggleAXN(false));
        Sentry.captureException("Error scanning Vietnam document ID...", err);
      });
  };

/* ============================================
         BLINK ID DOCUMENT VERIFICATION 
   ============================================ */
export const documentVerificationAXN =
  (documentFiles, documentType, country, countryCode, history) =>
  (dispatch) => {
    dispatch(loadingToggleAXN(true));

    // CREATE BODY FOR REQUEST
    const formData = new FormData();
    const ocrDocuments = [];
    // ocrDocuments.length = 2;
    ocrDocuments[0] = documentFiles.frontSide;
    // ocrDocuments[1] = documentFiles.backSide;

    formData.append("ocrDocument", ocrDocuments);
    formData.append("documentType", documentType);
    formData.append("countryCode", countryCode);
    loggerUTL("BLINK FORM DATA: ", formData.getAll("ocrDocument"));
    // AXIOS REQUEST
    dzAPI
      .post("/api/v2/ocr/scan", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(loadingToggleAXN(false));
        loggerUTL("BLINK REQUEST RESPONSE: ", res);
      })
      .catch((err) => {
        dispatch(loadingToggleAXN(false));
        loggerUTL("AN ERROR OCCURRED: ", err);
      });
  };

// TODO: REMOVE THE BELOW
export const daonDocumentVerificationAXN =
  (imageFiles, documentType, country, countryCode, history) => (dispatch) => {
    dispatch(loadingToggleAXN(true));

    // CREATE BODY FOR REQUEST
    const formData = new FormData();
    let imageFileBack = false;

    for (const file of imageFiles) {
      if (!imageFileBack) {
        formData.append(`ocrDocument`, file);
      }
      if (imageFileBack) {
        formData.append(`ocrDocumentBack`, file);
      }
      imageFileBack = true;
    }
    formData.append("documentType", documentType);
    formData.append("countryCode", countryCode);

    // AXIOS REQUEST
    dzAPI
      .post("/api/v2/ocr/scan", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loggerUTL("Get REQUEST", res.data);
        const { ScanResponse, DocumentInformation, ScanError } = res.data;
        if (!isEmptyVAL(ScanError)) {
          dispatch(loadingToggleAXN(false));
          return dispatch(setAlertAXN(ScanError, "error"));
        }

        const { ScanVariables } = ScanResponse;
        // Map result based on country
        switch (country) {
          case "philippines":
            loggerUTL("Philippines OCR...");
            inputMappingPH(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "australia":
            loggerUTL("Australian OCR...");
            inputMappingAU(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "newzealand":
            loggerUTL("New Zeland OCR...");
            inputMappingNZ(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "india":
            loggerUTL("India OCR...");
            inputMappingIN(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "malaysia":
            loggerUTL("Malaysia OCR...");
            inputMappingMY({ ScanVariables, DocumentInformation }, dispatch);
            history.push("/form-overview");
            break;
          case "indonesia":
            loggerUTL("Indonesia OCR...");
            inputMappingID(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "china":
            loggerUTL("CHINA OCR...");
            inputMappingCN(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "morocco":
            loggerUTL("Morocco OCR...");
            inputMappingMA(ScanVariables, dispatch);
            history.push("/form-overview-template");
            break;
          case "singapore":
            loggerUTL("Singapore OCR...");
            inputMappingSG(ScanVariables, dispatch);
            history.push("/form-overview");
            break;
          case "thailand":
            loggerUTL("Thailand OCR...");
            inputMappingTH(ScanVariables, DocumentInformation, dispatch);
            history.push("/form-overview-template");
            break;
          default:
            dispatch(
              setAlertAXN(
                transUTL("translateAlertMsg.daonOcrNotAvailable"),
                "error"
              )
            );
            COUNTRIES_FOR_TEMPLATE.includes(country)
              ? history.push("/form-overview-template")
              : history.push("/form-overview");
            break;
        }
        dispatch(loadingToggleAXN(false));
      })
      .catch((err) => {
        dispatch(loadingToggleAXN(false));
        loggerUTL(err);
        if (err.message.includes("Network Error")) {
          dispatch(
            setAlertAXN(transUTL("translateAlertMsg.collectiveSize"), "error")
          );
        }

        Sentry.captureException("Error scanning document ID...", err);
      });
  };

/* ============================================
        COMPRESS AND UPLOAD
   ============================================ */
export const compressMultipleIdentityDocuments =
  (
    // NOT IN USE...
    // TODO: REFACTOR THIS!!!
    identityDocuments
  ) =>
  (dispatch) => {
    loggerUTL("COMPRESSING...", identityDocuments);
    // ! TODO: SIZE PARAMETER IS CONFUSING... DISCUSS WITH ANI OR JOEL
    const compressedImages = [];
    dispatch(loadingToggleAXN(true));

    for (let [index, doc] of identityDocuments.entries()) {
      const formData = new FormData();
      const size = 350;
      const image = doc;

      formData.append("image", image);
      formData.append("size", size);

      dzAPI
        .post("/api/v2/ocr/resize", formData, {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const blob = res.data;
          blob.name = identityDocuments[index].name;
          blob.lastModified = moment.now();
          blob.lastModifiedDate = new Date();
          blob.webkitRelativePath = "";

          const file = blobToFileUTL(blob, blob.name);

          compressedImages.push(file);

          if (index === identityDocuments.length - 1) {
            // dispatch(
            //   daonDocumentVerification(identityDocuments, country, history, t)
            // );
          }
        })
        .catch((err) => {
          loggerUTL(err);
        });
    }
  };

/* ============================================
         SAVE BIOMETRIC AUTH
   ============================================ */
export const saveBiometricAuthenticationDataAXN = (data) => (dispatch) => {
  loggerUTL("SAVING FACIAL MATCHING RESULTS...");
  dispatch({
    type: FACIAL_RECOGNITION_DATA,
    payload: data,
  });
};

/* ============================================
         BIO METRIC AUTHENTICATION 
   ============================================ */
export const biometricAuthenticationAXN = (formData, history) => (dispatch) => {
  loggerUTL("FACIAL MATCHING GLOBAL...");
  dispatch(loadingToggleAXN(true));

  dzAPI
    .post("/api/v2/ocr/facialRecognition", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      loggerUTL("RESPONSE...", res);
      const { Result } = res.data;

      if (isEmptyVAL(Result)) {
        return dispatch(
          setAlertAXN(transUTL("translateAlertMsg.searchUnSuccessful"), "error")
        );
      }

      // ! TODO: Alter verification: null
      sessionStorage.setItem("facialRecognitionData", JSON.stringify(Result));

      dispatch(saveBiometricAuthenticationDataAXN(Result));

      history.push("/biometric-authentication/results");

      dispatch(loadingToggleAXN(false));
    })
    .catch((err) => {
      loggerUTL("ERROR...", err);
      dispatch(loadingToggleAXN(false));
      Sentry.captureException("Error scanning facial + document id...", err);
    });
};

/* ============================================
          REST BIOMETRICS
   ============================================ */
export const resetBiometricsAXN = () => (dispatch) => {
  // CLEAR STORAGE
  sessionStorage.removeItem("bioTriggers");
  sessionStorage.removeItem("identityDocumentScans");
  sessionStorage.removeItem("selfieURI");
  sessionStorage.removeItem("facialRecognitionData");

  dispatch({
    type: REST_BIOMETRICS,
  });
};
