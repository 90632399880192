import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const PassportIndia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { passportNo, fileNo, dateOfIssue, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['India Passport'],
      element: (
        <TextFieldGroup
          key="passportNo"
          id="passportNo"
          type="text"
          name="passportNo"
          dataName="passportNoIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.passportNo')}
          value={passportNo}
          placeholder={transUTL('translatePlaceholder.inputIN.passportNo')}
          placeholderTrigger={isEmptyVAL(passportNo) ? 'input-empty' : ''}
          error={errors.passportNo}
          errorTrigger={errors.passportNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India Passport'],
      element: (
        <TextFieldGroup
          key="fileNo"
          id="fileNo"
          type="text"
          name="fileNo"
          dataName="fileNoIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.fileNo')}
          value={fileNo}
          placeholder={transUTL('translatePlaceholder.inputIN.fileNo')}
          placeholderTrigger={isEmptyVAL(fileNo) ? 'input-empty' : ''}
          error={errors.fileNo}
          errorTrigger={errors.fileNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India Passport'],
      element: (
        <TextFieldGroup
          key="dateOfIssue"
          id="dateOfIssue"
          type="date"
          name="dateOfIssue"
          dataName="dateOfIssueIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.dateOfIssue')}
          value={dateOfIssue}
          placeholder={transUTL('translatePlaceholder.inputIN.dateOfIssue')}
          placeholderTrigger={isEmptyVAL(dateOfIssue) ? 'input-empty' : ''}
          max="9999-12-31"
          error={errors.dateOfIssue}
          errorTrigger={errors.dateOfIssue ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

PassportIndia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default PassportIndia;
