import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const unitedStatesVAL = (data, dataSource) => {
  const inputErrors = {};

  /* ============================================
              UNITED STATES PHONE
   ============================================ */
   if (dataSource.includes('United States Phone')) {
    loggerUTL('VALIDATING UNITED STATES PHONE...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.suburb = !isEmptyVAL(data.suburb) ? data.suburb : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.suburb)) {
      inputErrors.suburb = transUTL('translateFormOverview.formErrors.suburb');
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL('translateFormOverview.formErrors.state');
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
  }

  /* ============================================
              UNITED STATES RESIDENTIAL
   ============================================ */
  if (dataSource.includes('United States Residential')) {
    loggerUTL('VALIDATING UNITED STATES RESIDENTIAL...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.socialSecurityNo = !isEmptyVAL(data.socialSecurityNo)
      ? data.socialSecurityNo
      : '';
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.suburb = !isEmptyVAL(data.suburb) ? data.suburb : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        'translateFormOverview.formErrors.streetNo'
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.suburb)) {
      inputErrors.suburb = transUTL('translateFormOverview.formErrors.suburb');
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL('translateFormOverview.formErrors.state');
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSource.includes('Watchlist AML')) {
    loggerUTL('VALIDATING WATCHLIST AML AML...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default unitedStatesVAL;
