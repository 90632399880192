import React from 'react';
import { transUTL } from '../../../utils/transUTL';
import CheckBoxGroup from '../checkBoxGroup/CheckBoxGroup';

const TermsAndConditions = ({isConsentChecked, handleToggleCheckbox}) => {

  return (
    <div className="form-overview__terms">
      <p className="form-overview__terms-label">
        {transUTL('translateFormOverview.consentParagraph')}
      </p>
      <CheckBoxGroup
        key="consent"
        name="consent"
        checkboxBool={isConsentChecked}
        handleToggleCheckbox={handleToggleCheckbox}
        label={transUTL('translateFormOverview.inputLabels.consent')}
      />
    </div>
  );
};

export default TermsAndConditions;