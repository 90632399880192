import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import countryCodeUTL from '../../../../../utils/countryCodeUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CitizenshipNewZealand = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { countryOfBirth, citizenshipCertificateNo, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['DIA-Citizenship'],
      element: (
        <SelectListGroup
          key="countryOfBirth"
          id="countryOfBirth"
          name="countryOfBirth"
          dataName="countryOfBirthNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.countryOfBirth')}
          value={countryOfBirth}
          placeholderTrigger={isEmptyVAL(countryOfBirth) ? 'input-empty' : ''}
          options={countryCodeUTL('alpha2')}
          error={errors.countryOfBirth}
          errorTrigger={errors.countryOfBirth ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['DIA-Citizenship'],
      element: (
        <TextFieldGroup
          key="citizenshipCertificateNo"
          id="citizenshipCertificateNo"
          type="text"
          name="citizenshipCertificateNo"
          dataName="citizenshipCertificateNoNZ"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.citizenshipCertificateNo'
          )}
          value={citizenshipCertificateNo}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.citizenshipCertificateNo'
          )}
          placeholderTrigger={
            isEmptyVAL(citizenshipCertificateNo) ? 'input-empty' : ''
          }
          error={errors.citizenshipCertificateNo}
          errorTrigger={errors.citizenshipCertificateNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CitizenshipNewZealand.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CitizenshipNewZealand;
