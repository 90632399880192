import React, { Fragment } from "react";
import PropTypes from "prop-types";

// INPUT
import TextFieldGroup from "../../../../../components/common/textFieldGroup/TextFieldGroup";
// import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

// UTILITIES
import { outputMasterUTL } from "../../../../../utils/outputMasterUTL";
// import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';
import { transUTL } from "../../../../../utils/transUTL";
import AddressAutoComplete from "../../../reusableForms/AddressAutoComplete";

const CurrentResidentialAddressNewZealand = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  manualAddressEntryToggle,
  handleGoggleOnChange,
  handleGoogleSelect,
}) => {
  const {
    streetNo,
    streetName,
    suburb,
    city,
    postCode,
    // GOOGLE
    isGoogle,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
      ],
      element: (
        <TextFieldGroup
          key="streetNo"
          id="streetNo"
          type="text"
          name="streetNo"
          dataName="streetNoNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.streetNo")}
          value={streetNo}
          placeholder={transUTL("translatePlaceholder.inputNZ.streetNo")}
          placeholderTrigger={isEmptyVAL(streetNo) ? "input-empty" : ""}
          error={errors.streetNo}
          errorTrigger={errors.streetNo ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
      ],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.streetName")}
          value={streetName}
          placeholder={transUTL("translatePlaceholder.inputNZ.streetName")}
          placeholderTrigger={isEmptyVAL(streetName) ? "input-empty" : ""}
          error={errors.streetName}
          errorTrigger={errors.streetName ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    // {
    //   belongsTo: [
    //     'New Zealand Companies Office',
    //     'New Zealand LINZ Property',
    //     'New Zealand Yellow Pages',
    //  'New Zealand Residential',
    //   ],
    //   element: (
    //     <SelectListGroup
    //       key="streetType"
    //       id="streetType"
    //       name="streetType"
    //       dataName="streetTypeNZ"
    //       labelClass="form-group__label"
    //       label={transUTL('translateFormOverview.inputLabels.streetType')}
    //       value={streetType}
    //       placeholderTrigger={isEmptyVAL(streetType) ? 'input-empty' : ''}
    //       options={handelOptions('streetType')}
    //       error={errors.streetType}
    //       errorTrigger={errors.streetType ? 'input-error' : ''}
    //       handleOnChange={handleOnChange}
    //       handleOnBlur={handleOnBlur}
    //       handleEnterKeyTrigger={handleEnterKeyTrigger}
    //     />
    //   ),
    // },
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
      ],
      element: (
        <TextFieldGroup
          key="suburb"
          id="suburb"
          type="text"
          name="suburb"
          dataName="suburbNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.suburb")}
          value={suburb}
          placeholder={transUTL("translatePlaceholder.inputNZ.suburb")}
          placeholderTrigger={isEmptyVAL(suburb) ? "input-empty" : ""}
          error={errors.suburb}
          errorTrigger={errors.suburb ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
      ],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.city")}
          value={city}
          placeholder={transUTL("translatePlaceholder.inputNZ.city")}
          placeholderTrigger={isEmptyVAL(city) ? "input-empty" : ""}
          error={errors.city}
          errorTrigger={errors.city ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
      ],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.postCode")}
          value={postCode}
          placeholder={transUTL("translatePlaceholder.inputNZ.postCode")}
          placeholderTrigger={isEmptyVAL(postCode) ? "input-empty" : ""}
          error={errors.postCode}
          errorTrigger={errors.postCode ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <Fragment>
      {isGoogle ? (
        <div className="form-overview__form-inputs">
          {outputMasterUTL(selectedDataSources, inputElements)}
          <span />
        </div>
      ) : (
        <Fragment>
          <AddressAutoComplete
            formState={formState}
            selectedDataSources={selectedDataSources}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            handleEnterKeyTrigger={handleEnterKeyTrigger}
            manualAddressEntryToggle={manualAddressEntryToggle}
            handleGoggleOnChange={handleGoggleOnChange}
            handleGoogleSelect={handleGoogleSelect}
            countryRestriction="nz"
          />
        </Fragment>
      )}

      {isGoogle && (
        <div
          onClick={manualAddressEntryToggle}
          className="form-overview__address-switch"
        >
          {transUTL("translateFormOverview.useAddressAutocomplete")}
        </div>
      )}
    </Fragment>
  );
};

CurrentResidentialAddressNewZealand.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  manualAddressEntryToggle: PropTypes.func.isRequired,
  handleGoggleOnChange: PropTypes.func.isRequired,
  handleGoogleSelect: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressNewZealand;