import React from 'react';
import PropTypes from 'prop-types';

// FOR PURE ARRAYS
// TODO: TRANSLATE...
const DropdownArray = ({
  array,
  handleOnChange,
  target,
  handelRadio,
  name,
  transType,
}) => {
  return (
    <div className="dropdown">
      {array.map((item) => {
        const checked = target === item;
        const title = item;

        return (
          <label
            htmlFor={item}
            key={item}
            className="dropdown__option"
            onClick={() => handelRadio(item)}
          >
            <h4 className="dropdown__title">{title}</h4>

            <div className="dropdown__radio">
              <input
                id={item}
                type="radio"
                name={name}
                value={item}
                onChange={handleOnChange}
                defaultChecked={checked}
              />

              <label htmlFor={item} className="radio-label" name={name} />
            </div>
          </label>
        );
      })}
    </div>
  );
};

DropdownArray.propTypes = {
  array: PropTypes.array.isRequired,
  target: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  transType: PropTypes.string,
  handelRadio: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default DropdownArray;
