/* ============================================
                  PAGE TRANSLATION
   ============================================ */
import translateLogin from '../../translations/ms/login/translateLogin';
import translatePlaceholder from '../../translations/ms/placeholder/translatePlaceholder';
import translateAlt from '../../translations/ms/alt/translateAlt';
import translateTitle from '../../translations/ms/title/translateTitle';
import translateFormOverview from '../../translations/ms/formOverview/translateFormOverview';
import translateDropdown from '../../translations/ms/dropdown/translateDropdown';
import translateDataSources from '../../translations/ms/dataSources/translateDataSources';
import translateCountryNames from '../../translations/ms/countryNames/translateCountryNames';
import translateAlertMsg from '../../translations/ms/alertMsg/translateAlertMsg';
import translateNavigation from '../../translations/ms/navigation/translateNavigation';
import translateDashboard from '../../translations/ms/dashboard/translateDashboard';
import translateCountrySelection from '../../translations/ms/countrySelection/translateCountrySelection';
import translateDataSourceSelection from '../../translations/ms/dataSourceSelection/translateDataSourceSelection';
import translateBtn from '../../translations/ms/btn/translateBtn';
import translateSearchVerification from '../../translations/ms/searchVerification/translateSearchVerification';
import translateReuse from '../../translations/ms/reuse/translateReuse';
import translateTransactionHistory from '../../translations/ms/transactionHistory/translateTransactionHistory';
import translateDocumentLibrary from '../../translations/ms/documentLibrary/translateDocumentLibrary';
import translateBiometrics from '../../translations/ms/biometrics/translateBiometrics';
import translatePasswordDistro from '../../translations/ms/passwordDistro/translatePasswordDistro';

/* ============================================
                     TRANSLATE TO ENGLISH
      ============================================ */
const ms = {
  translateLogin,
  translatePlaceholder,
  translateAlt,
  translateTitle,
  translateFormOverview,
  translateDropdown,
  translateDataSources,
  translateCountryNames,
  translateAlertMsg,
  translateNavigation,
  translateDashboard,
  translateCountrySelection,
  translateDataSourceSelection,
  translateBtn,
  translateSearchVerification,
  translateReuse,
  translateTransactionHistory,
  translateDocumentLibrary,
  translateBiometrics,
  translatePasswordDistro,
};

export default ms;
