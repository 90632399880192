/* ============================================
                  PAGE TRANSLATION
   ============================================ */
import translateLogin from '../../translations/en/login/translateLogin';
import translatePlaceholder from '../../translations/en/placeholder/translatePlaceholder';
import translateAlt from '../../translations/en/alt/translateAlt';
import translateTitle from '../../translations/en/title/translateTitle';
import translateFormOverview from '../../translations/en/formOverview/translateFormOverview';
import translateDropdown from '../../translations/en/dropdown/translateDropdown';
import translateDataSources from '../../translations/en/dataSources/translateDataSources';
import translateCountryNames from '../../translations/en/countryNames/translateCountryNames';
import translateAlertMsg from '../../translations/en/alertMsg/translateAlertMsg';
import translateNavigation from '../../translations/en/navigation/translateNavigation';
import translateDashboard from '../../translations/en/dashboard/translateDashboard';
import translateCountrySelection from '../../translations/en/countrySelection/translateCountrySelection';
import translateDataSourceSelection from '../../translations/en/dataSourceSelection/translateDataSourceSelection';
import translateBtn from '../../translations/en/btn/translateBtn';
import translateSearchVerification from '../../translations/en/searchVerification/translateSearchVerification';
import translateReuse from '../../translations/en/reuse/translateReuse';
import translateTransactionHistory from '../../translations/en/transactionHistory/translateTransactionHistory';
import translateDocumentLibrary from '../../translations/en/documentLibrary/translateDocumentLibrary';
import translateBiometrics from '../../translations/en/biometrics/translateBiometrics';
import translatePasswordDistro from '../../translations/en/passwordDistro/translatePasswordDistro';

/* ============================================
                  TRANSLATE TO ENGLISH
   ============================================ */
const en = {
  translateLogin,
  translatePlaceholder,
  translateAlt,
  translateTitle,
  translateFormOverview,
  translateDropdown,
  translateDataSources,
  translateCountryNames,
  translateAlertMsg,
  translateNavigation,
  translateDashboard,
  translateCountrySelection,
  translateDataSourceSelection,
  translateBtn,
  translateSearchVerification,
  translateReuse,
  translateTransactionHistory,
  translateDocumentLibrary,
  translateBiometrics,
  translatePasswordDistro,
};

export default en;
