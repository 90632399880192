const inputUS = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',
  gender: '',

  // SOCIAL SECURITY
  socialSecurityNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '1 Syrws St',
  suburb: 'Lynden',
  state: 'WA',
  postCode: '12345',

  // CONTACT DETAILS
  phoneNo: '',
  email: '',

  // REFERENCE
  reportingReference: '',
};

export default inputUS;
