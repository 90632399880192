const translateNavigation = {
  individualIdentity: 'Identiti Individu',
  verification: 'Pengesahan',
  profile: 'Profil',
  dashboard: 'Papan Pemuka',
  history: 'Sejarah',
  dataAnalytics: 'Analisis Data',
  documentLibrary: 'Perpustakaan Dokumen',
  aboutUs: 'Tentang kita',
  logout: 'Log keluar',
  costEstimation: 'Anggaran kos',
  admin: 'Pentadbir',
};

export default translateNavigation;
