const inputSG = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',

  // IDENTITY
  idCardNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  block: '',
  streetName: '',
  postCode: '',
  city: '',

  // CONTACT DETAILS
  phoneNo: '',
  emailAddress: '',

  // REFERENCE
  reportingReference: '',
};

export default inputSG;
