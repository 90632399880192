import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const AustraliaSuperannuation = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};

  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesAU.superannuation.title'
        )}
      />

      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.searchSuccessful'
                    )}
                  </p>
                  <span className="material-icons success">check_circle</span>
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.safeHarbourScore'
                    )}
                  </p>
                  {!isEmptyVAL(safeHarbourScore) ? (
                    <span>{safeHarbourScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.identityVerified'
                    )}
                  </p>
                  {!isEmptyVAL(identityVerified) ? (
                    identityVerified ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dateOfBirth'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.dateOfBirth) ? (
                    verifications.dateOfBirth ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.addressMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(addressMatchScore) ? (
                    <span>{addressMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>

                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.streetName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement1) ? (
                    verifications.addressElement1 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesAU.suburb'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement3) ? (
                    verifications.addressElement4 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.state'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement4) ? (
                    verifications.addressElement4 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesAU.postCode'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.addressElement5) ? (
                    verifications.addressElement5 ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">

                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.nameMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(nameMatchScore) ? (
                    <span>{nameMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.firstName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.firstName) ? (
                    verifications.firstName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.lastName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.lastName) ? (
                    verifications.lastName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>

                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.phoneNo'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.phoneNo) ? (
                    verifications.phoneNo ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>

                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.email'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.email) ? (
                    verifications.email ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};

AustraliaSuperannuation.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default AustraliaSuperannuation;
