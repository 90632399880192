const consentCheckerIN = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('India PAN')) {
    // CONSENT COLLECTOR
    consentObtained['India PAN'] = true;

    if (!inputData.indiaPanConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('India Drivers Licence')) {
    // CONSENT COLLECTOR
    consentObtained['India Drivers Licence'] = true;

    if (!inputData.indiaDriversLicenceConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('India EPIC')) {
    // CONSENT COLLECTOR
    consentObtained['India EPIC'] = true;

    if (!inputData.indiaEpicConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('India Passport')) {
    // CONSENT COLLECTOR
    consentObtained['India Passport'] = true;

    if (!inputData.indiaPassportConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('India Aadhaar')) {
    // CONSENT COLLECTOR
    consentObtained['India Aadhaar'] = true;

    if (!inputData.indiaAadhaarConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerIN;
