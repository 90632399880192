import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Tile from '../../components/tile/Tile';
import { transUTL } from '../../utils/transUTL';

const Admin = ({
  authRXS,
  history,
}) => {
  
  const adminOrgId = authRXS.user.organisationId;

  return (
    <div className='admin-panel'>
      <h2 className='admin-panel__title'>Admin Panel</h2>
      <div className='dashboard__tiles'>
        <Tile
          title="Organisations"
          ui={
            <h3 className="dashboard__tile-header">Organisations</h3>
          }
          to={'/admin/organisations'}
          history={history}
        />
        <Tile
          title="Users"
          ui={
            <h3 className="dashboard__tile-header">Users</h3>
          }
          to={
            {
              pathname: '/admin/users/' + adminOrgId,
              state: { orgName: authRXS.user.organisationName }
            }
          }
          history={history}
        />
        <Tile
          title={transUTL('translateDashboard.passwordAdvice')}
          ui={
            <h3 className="dashboard__tile-header">
              {transUTL('translateDashboard.sms')}
            </h3>
          }
          to={'/admin/password-distribution'} 
          history={history}
        />

        {
        /*
          Below is used to add padding so that above tiles don't take
          the entire width of the page.
        */
        }
        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
      </div>
    </div>
  )
};

Admin.propTypes = {
  authRXS: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS
});

export default connect(mapStateToProps)(Admin);