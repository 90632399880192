import React from 'react';
import { transUTL } from '../../../../../utils/transUTL';

import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';

import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

const ContactDetailsFrance = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { phoneNo, emailAddress, errors } = formState;

  const inputElements = [
    {
      belongsTo: [
        'France Residential 2',
        'France Residential 3'
      ],
      element: (
        <TextFieldGroup
          key="phoneNo"
          id="phoneNo"
          type="text"
          name="phoneNo"
          dataName="phoneNoFR"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.phoneNo')}
          value={phoneNo}
          placeholder={transUTL('translatePlaceholder.inputFR.phoneNo')}
          placeholderTrigger={isEmptyVAL(phoneNo) ? 'input-empty' : ''}
          error={errors.phoneNo}
          errorTrigger={errors.phoneNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    },
    {
      belongsTo: [
        'France Residential 2',
        'France Residential 3'
      ],
      element: (
        <TextFieldGroup
          key="emailAddress"
          id="emailAddress"
          type="text"
          name="emailAddress"
          dataName="emailAddressFR"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.emailAddress')}
          value={emailAddress}
          placeholder={transUTL('translatePlaceholder.inputFR.emailAddress')}
          placeholderTrigger={isEmptyVAL(emailAddress) ? 'input-empty' : ''}
          error={errors.emailAddress}
          errorTrigger={errors.emailAddress ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    }
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

export default ContactDetailsFrance;
