import React from "react";

import TextFieldGroup from "../../../../../components/common/textFieldGroup/TextFieldGroup";
import { outputMasterUTL } from "../../../../../utils/outputMasterUTL";
import { transUTL } from "../../../../../utils/transUTL";
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

const TaxRegistration = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger
}) => {
  const { cpf, errors } = formState;

  const inputElements = [
    {
      belongsTo: ["Brazil Tax Registration"],
      element: (
        <TextFieldGroup
          key="cpf"
          id="cpf"
          type="text"
          name="cpf"
          dataName="cpfBR"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.cpf")}
          value={cpf}
          placeholder={transUTL("translatePlaceholder.inputBR.cpf")}
          placeholderTrigger={isEmptyVAL(cpf) ? "input-empty" : ""}
          error={errors.cpf}
          errorTrigger={errors.cpf ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    }
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

export default TaxRegistration;