import React from "react";
import PropTypes from "prop-types";

import TextFieldGroup from "../../../../../components/common/textFieldGroup/TextFieldGroup";
import { outputMasterUTL } from "../../../../../utils/outputMasterUTL";
import { transUTL } from "../../../../../utils/transUTL";

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

const PersonalInformationBrazil = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger
}) => {
  const { firstName, middleName, lastName, dateOfBirth, errors } = formState;

  const inputElements = [
    {
      belongsTo: ["Brazil National ID", "Brazil Tax Registration", "Watchlist AML"],
      element: (
        <TextFieldGroup
          key="firstName"
          id="firstName"
          type="text"
          name="firstName"
          dataName="firstNameBR"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.firstName")}
          value={firstName}
          placeholder={transUTL("translatePlaceholder.inputBR.firstName")}
          placeholderTrigger={isEmptyVAL(firstName) ? "input-empty" : ""}
          error={errors.firstName}
          errorTrigger={errors.firstName ? "input-error" : ""}
          handleOnBlur={handleOnBlur}
          handleOnChange={handleOnChange}
        />
      )
    },
    {
      belongsTo: ["Brazil National ID", "Brazil Tax Registration", "Watchlist AML"],
      element: (
        <TextFieldGroup
          key="middleName"
          id="middleName"
          type="text"
          name="middleName"
          dataName="middleNameBR"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.middleName")}
          value={middleName}
          placeholder={transUTL("translatePlaceholder.inputBR.middleName")}
          placeholderTrigger={isEmptyVAL(middleName) ? "input-empty" : ""}
          error={errors.middleName}
          errorTrigger={errors.middleName ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    },
    {
      belongsTo: ["Brazil National ID", "Brazil Tax Registration", "Watchlist AML"],
      element: (
        <TextFieldGroup
          key="lastName"
          id="lastName"
          type="text"
          name="lastName"
          dataName="lastNameBR"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.lastName")}
          value={lastName}
          placeholder={transUTL("translatePlaceholder.inputBR.lastName")}
          placeholderTrigger={isEmptyVAL(lastName) ? "input-empty" : ""}
          error={errors.lastName}
          errorTrigger={errors.lastName ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    },
    {
      belongsTo: ["Brazil National ID", "Brazil Tax Registration", "Watchlist AML"],
      element: (
        <TextFieldGroup
          key="dateOfBirth"
          id="dateOfBirth"
          type="date"
          name="dateOfBirth"
          dataName="dateOfBirthBR"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.dateOfBirth")}
          value={dateOfBirth}
          placeholder={transUTL("translatePlaceholder.inputBR.dateOfBirth")}
          max="9999-12-31"
          placeholderTrigger={isEmptyVAL(dateOfBirth) ? "input-empty" : ""}
          error={errors.dateOfBirth}
          errorTrigger={errors.dateOfBirth ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    }
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

PersonalInformationBrazil.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired
};

export default PersonalInformationBrazil;
