// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataDE = (inputData, dataSources) => {
  let utilityData, creditBureauData, residentialData, phoneData, watchlistData;

  // Both services have the same request structure. (This may change in the future)
  /* ============================================
            GERMANY RESIDENTIAL & UTILITY 
   ============================================ */
  if (dataSources.includes('Germany Utility')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'streetAddress',
      'city',
      'state',
      'postCode',
      'phoneNo',
      'reportingReference',
    ]);

    utilityData = { ...whitelisted };
  }

  /* ============================================
               GERMANY CREDIT BUREAU
  ============================================= */
  if (dataSources.includes('Germany Credit Bureau')) {
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'gender',
      'dateOfBirth',
      'streetNo',
      'streetAddress',
      'city',
      'postCode',
      'reportingReference',
    ]);

    creditBureauData = { ...whitelisted };
  }

  if (dataSources.includes('Germany Residential')) {
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'streetAddress',
      'city',
      'postCode',
      'reportingReference',
    ]);

    residentialData = { ...whitelisted };
  }

  if (dataSources.includes('Germany Phone')) {
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'phoneNo',
      'emailAddress',
      'streetAddress',
      'city',
      'state',
      'postCode',
    ]);

    phoneData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...creditBureauData,
    ...utilityData,
    ...residentialData,
    ...phoneData,
    ...watchlistData,
  };
};
export default whitelistedInputDataDE;
