const translateDataSources = {
  /* ============================================
                  WATCHLIST AML
   ============================================ */
  watchlistaml: 'Senarai Pemantau Global AML',

  /* ============================================
                  CHINA
   ============================================ */
  chinabankcard: 'Kad Bank China',
  chinaidverification: 'Pengesahan ID China',
  chinaphone: 'Telefon China',
  chinaphonealt: 'Alt Telefon China',
  chinapassportverification: 'Pengesahan Pasport China',
  chinadriverlicense: 'Lesen Memandu China',

  /* ============================================
                  AUSTRALIA
   ============================================ */
  australiavisa: 'Australia Visa ',
  australiamedicarecard: 'Kad Medicare Australia',
  australiabirthcertificate: 'Surat Beranak Australia',
  australiacentrelinkcard: 'Kad Centrelink Australia',
  australiadriverlicence: 'Lesen Memandu Australia',
  australiapassport: 'Pasport Australia',
  australiapropertyownership: 'Pemilikan Harta Australia',
  australiaresidential: 'Kediaman Australia',
  australiaresidentiallookup: 'Pencarian Kediaman Australia',
  australiavisaentitlement: 'Hak Visa Australia',
  australiacreditbureau: 'Biro Kredit Australia',
  australiacitizens: 'Warga Australia',
  australiapayroll: "Gaji Australia",
  australiasuperannuation: "Australia Superannuation",
  australiadeathcheck: "Pemeriksaan Kematian Australia",

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  newzealandcompaniesoffice: 'Pejabat Syarikat New Zealand',
  newzealandcompanyverification: 'Pengesahan Syarikat New Zealand',
  newzealanddiabirth: 'newZealandDiaBirth',
  newzealanddiacitizenship: 'Kewarganegaraan DIA New Zealand',
  newzealanddiapassport: 'Pasport DIA New Zealand',
  newzealanddriverlicence: 'Lesen Memandu New Zealand',
  newzealandlinzproperty: 'Harta LINZ New Zealand',
  newzealandresidential: 'Kediaman New Zealand',
  newzealandvehicle: 'Kenderaan New Zealand',
  newzealandyellowpages: 'Halaman Kuning New Zealand',
  newzealandcreditbureau: 'Biro Kredit New Zealand',

  /* ============================================
                  PHILIPPINES
   ============================================ */
  philippinescreditbureau: 'Biro Kredit Filipina',
  philippinesresidential: 'Kediaman Filipina',
  singaporeresidential: 'Singapore Kediaman',
  singaporeutility: 'Utiliti Singapura',

  /* ============================================
                 THAILAND
  ============================================ */
  thailandnationalid: 'ID Nasional Thailand',

  /* ============================================
                  SLOVAKIA
   ============================================ */
  slovakiaresidential: 'Kediaman Slovakia',

  /* ============================================
                  BELGIUM
   ============================================ */
  belgiumresidential: 'Kediaman Belgium',

  /* ============================================
                  MALAYSIA
   ============================================ */
  malaysiacreditbureau: 'Malaysia Credit Bureau',
  malaysianationalid: 'Malaysia National ID',

  /* ============================================
                  INDONESIA
   ============================================ */
  indonesiaresidentidentitycard: 'Kad Pengenalan Penduduk Indonesia',
  indonesiataxregistration: 'Pendaftaran Cukai Indonesia',

  /* ============================================
                  INDIA
   ============================================ */
  indiapan: 'PAN India',
  indiaepic: 'EPIC India',
  indiadriverslicence: 'Lesen Memandu India',
  indiapassport: 'Pasport India',
  indiaaadhaar: 'Aadhaar India',

  /* ============================================
                  VIETNAM
   ============================================ */
  vietnamconsumerdata: 'Data Pengguna Vietnam',
  vietnamnationalid: 'ID Nasional Vietnam',
  vietnamnationalidocr: 'OCR ID Nasional Vietnam',
  vietnamsocialsecurity: 'Keselamatan Sosial Vietnam',

  /* ============================================
                  SINGAPORE
   ============================================ */
  singaporecreditbureau: 'Biro Kredit Singapura',

  /* ============================================
                  CAMBODIA
   ============================================ */
  cambodianationalid: 'ID Nasional Kemboja', // RENAMED


  /* ============================================
                HONG KONG
 ============================================ */
  hongkongcreditbureau: 'Biro Kredit Hong Kong',
  hongkongmarketing: 'Pemasaran Hong Kong',
  hongkongresidential: 'Kediaman Hong Kong',

  /* ============================================
                FRANCE
 ============================================ */
  franceresidential: 'Perumahan Perancis',
  franceresidential2: 'Perumahan Perancis 2',
  franceresidential3: 'Perumahan Perancis 3',

  /* ============================================
                CHILE
  =========================================== */
  chilenationalid: 'ID Nasional Cili',
  chileresidential: 'Kediaman Cili',


  /* ============================================
                CHILE
  =========================================== */
  chilenationalid: 'ID Nasional Cili',
  chileresidential: 'Kediaman Cili',


  /* ============================================
                  UNITED STATES
   ============================================ */
  unitedstatesresidential: 'Kediaman Amerika Syarikat',
  unitedstatesphone: 'Telefon Amerika Syarikat',

  /* ============================================
                  GREAT BRITAIN / UK
    ============================================ */
  unitedkingdomphone: 'Telefon United Kingdom',
  unitedkingdomcitizens: 'Warga United Kingdom',

  /* ============================================
                  MEXICO
   ============================================ */
  mexiconationalid: 'Mexico ID Nasional',
  mexicotaxregistration: 'MPendaftaran Cukai exico',

  /* ============================================
                  GERMANY
   ============================================ */
  germanyresidential: 'Kediaman Jerman',
  germanycreditbureau: 'Biro Kredit Jerman',
  germanyutility: 'Utiliti Jerman',
  germanyphone: 'Telefon Jerman',

  /* ============================================
                  Switzerland
  ============================================= */
  switzerlandcreditbureau: 'Biro Kredit Switzerland',

  /* ============================================
                  ITALY
   ============================================ */
  italyresidential: 'Kediaman Itali',
  italytelcomobile: 'Itali Telco Mobile',
  italycreditbureau: 'biro kredit italia',

  /* ============================================
              SPAIN
  ============================================ */
  spainresidential: 'Kediaman Sepanyol',
  spaintelco: 'Telco Sepanyol',

  /* ============================================
                GREECE
  ============================================ */
  greececreditbureau: 'Biro Kredit Greece',

  /* ============================================
                POLAND
  ============================================ */
  polandcreditbureau: 'Biro Kredit Poland',

  /* ============================================
                NIGERIA
 ============================================ */
  nigerianationalid: 'ID Nasional Nigeria',
  nigeriabankverification: 'Pengesahan Bank Nigeria',

  /* ============================================
                Morocco
  ============================================ */
  moroccoresidential: 'Kediaman Maghribi',

  /* ============================================
                  BANGLADESH
   ============================================ */
  bangladeshnationalid: 'ID Nasional Bangladesh',

  /* ============================================
                BRAZIL
  ============================================ */
  brazilnationalid: 'ID Nasional Brazil',
  braziltaxregistration: 'Pendaftaran Cukai Brazil',

  /* ============================================
                SOUTH AFRICA
  ============================================ */
  southafricanationalid: 'ID Nasional South Africa',

  /* ============================================
                Argentina
  ============================================ */
  argentinanationalid: 'ID Nasional Argentina',
  argentinacitizens: 'Warganegara Argentina',
  argentinacreditbureau: 'Argentina Kredit Greece',


  /* ============================================
                 PERU
  ============================================ */
  perunationalid: 'ID Nasional Peru',
  peruresidential: 'Kediaman Peru',
  perutaxregistration: 'Pendaftaran Cukai Peru',

  /* ============================================
                 CZECH
  ============================================ */
  czechrepublicresidential: 'Kediaman Republik Czech',

  /* ============================================
              KENYA
  ============================================ */
  kenyanationalid: 'ID Nasional Kenya',

  /* ============================================
                 CANADA
  ============================================ */
  canadaphone: 'Telefon Kanada',

  /* ============================================
                Netherlands
  ============================================ */
  netherlandsresidential: 'Kediaman Belanda',

  /* ============================================
                  OTHER
   ============================================ */
  notApplicable: 'N/A',

  /* ============================================
                FIND IDU
  ============================================ */
  philippinessuspiciousactivity: 'Filipina',

  /* ============================================
                Digital ID Safeguard
  ============================================ */
  digitalIdSafeguard: 'Perlindungan ID Digital',
  digitalidsafeguard: 'Perlindungan ID Digital',
};

export default translateDataSources;
