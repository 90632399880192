import React from 'react';
import PropTypes from 'prop-types';

const TextFieldGroup = ({
  id,
  type,
  name,
  dataName,
  labelClass,
  label,
  value,
  placeholder,
  placeholderTrigger,
  max,
  error,
  errorTrigger,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleKeyUp,
  disabled,
  driverLicenceStateOfIssue,
  register,
  errorHookForm,
  validation
}) => {

  return (
    <fieldset className="form-group">
      <label htmlFor={id}>
        <span className={labelClass}>{label}</span>
        {
          !register
            ? (<input
              id={id}
              type={type}
              className={`${placeholderTrigger} ${errorTrigger}`}
              placeholder={placeholder}
              name={name}
              data-name={dataName}
              value={value}
              max={max}
              onChange={handleOnChange}
              disabled={disabled}
              onBlur={handleOnBlur}
              onKeyPress={handleEnterKeyTrigger}
              onKeyUp={handleKeyUp}
            />)
            : (<input
              {...register(
                name,
                {
                  ...validation,
                  onChange: handleOnChange,
                  onBlur: handleOnBlur
                }
              )}
              id={id}
              type={type}
              className={`${placeholderTrigger} ${errorTrigger}`}
              placeholder={placeholder}
              value={value}
              max={max}
              disabled={disabled}
              onKeyPress={handleEnterKeyTrigger}
              onKeyUp={handleKeyUp}
            />)
        }
      </label>
      {error && driverLicenceStateOfIssue !== "VIC" ? (
        <small className="form-group__error-text">
          <span className="material-icons">error</span>
          {error}
        </small>
      ) :
      (
        <small className="form-group__error-text">
          <span className="material-icons"></span>
          {""}
        </small>
      )}
      {/* Hook Form Error */}
      {errorHookForm && errorHookForm[name] && (
        <small className="form-group__error-text">
          <span className="material-icons">error</span>
          {
            errorHookForm[name].message
          }
        </small>
      )}
    </fieldset>
  );
};

TextFieldGroup.defaultProps = {
  disabled: false,
};

TextFieldGroup.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderTrigger: PropTypes.string,
  error: PropTypes.string,
  errorTrigger: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleEnterKeyTrigger: PropTypes.func,
};

export default TextFieldGroup;
