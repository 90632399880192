import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { generateHex } from '../../utils/generateColorUTL';

const DoughnutChart = ({ chart }) => {
  const colors = [
    '#5977c1',
    '#58c1a3',
    '#59abc1',
    '#59c16f',
    '#6f59c1',
    '#c45850',
    '#ffae42',
    '#b92d00',
    generateHex(),
    generateHex(),
  ];

  const data = {
    labels: chart.labels,
    datasets: [
      {
        label: 'IP Address',
        backgroundColor: colors,
        data: chart.data,
      },
    ],
  };

  return <Doughnut data={data} />;
};

DoughnutChart.propTypes = {
  chart: PropTypes.object.isRequired,
};

export default DoughnutChart;
