const stateMY = {
  johor: 'Johor',
  kedah: 'Kedah',
  kelantan: 'Kelantan',
  kualaLumpur: 'Kuala Lumpur',
  labuan: 'Labuan',
  malacca: 'Malacca',
  negeriSembilan: 'Negeri Sembilan',
  pahang: 'Pahang',
  penang: 'Penang',
  perak: 'Perak',
  perlis: 'Perlis',
  putrajaya: 'Putrajaya',
  sabah: 'Sabah',
  sarawak: 'Sarawak',
  selangor: 'Selangor',
  terengganu: 'Terengganu',
};

export default stateMY;
