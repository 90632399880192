const dataSourcesID = {
  nik: {
    title: '印尼居民身份证',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    nik: '居民身份证',
    name: '名称',
    gender: '性别',
    address: '地址',
    dateOfBirth: '出生日期',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字匹配分数',
    addressElement1: '地址',
    addressElement2: '克鲁拉汉',
    addressElement4: '分地区',
    addressElement5: '地区',
  },
  npwp: {
    title: '印尼税务登记',
    searchSuccessful: '搜索成功',
    safeHarbourScore: '安全港评分',
    identityVerified: '已验证',
    addressMatchScore: '地址匹配分数',
    npwp: '税务登记',
    nameMatchScore: '名字匹配分数',
    fullName: '全名',
    dateOfBirth: '出生日期',
    nik: '居民身份证',
  },
};

export default dataSourcesID;
