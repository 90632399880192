const dataSourcesMY = {
  creditBureau: {
    title: '马来西亚信贷局',
    searchSuccessful: '搜索成功',
    identityVerified: '已验证',
    safeHarbourScore: '安全港评分',
    city: '市',
    dateOfBirth: '出生日期',
    nameMatchScore: '名字匹配分数',
    firstName: '名字',
    lastName: '姓',
    nationalIDNo: '国民身份证',
    postCode: '邮编',
    state: '州',
    street: '街',
    addressMatchScore: '地址匹配分数',
  },
  nationalID: {
    title: '马来西亚国民身份证',
    searchSuccessful: '搜索成功',
    identityVerified: '已验证',
    safeHarbourScore: '安全港评分',
    state: '州',
    name: '名称',
    dateOfBirth: '出生日期',
    nameMatchScore: '名字匹配分数',
    nationalIDNo: '国民身份证',
    postCode: '邮编',
    city: '市',
    street: '街',
    gender: '性别',
    addressMatchScore: '地址匹配分数',
  },
};

export default dataSourcesMY;
