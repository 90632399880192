const dataSourcesKH = {
  consumerData: {
    title: 'ID Kebangsaan Kemboja',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    gender: 'Jantina Disahkan',
    voterNo: 'Nombor Pengundi Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
  },
};

export default dataSourcesKH;
