const drawGreenLine = (doc, docStyles, posY) => {
  const finalY = doc.previousAutoTable.finalY + posY;
  const columns = [{ header: '', dataKey: 'col1' }];
  const rows = [{ col1: '' }];

  doc.autoTable({
    columns: columns, 
    body: rows,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.cellPadPadding = -3.8;
      cell.styles.cellPadding = -3.8;
      cell.styles.fillColor = docStyles.colorGreen;
    },
    showHead: 'never',
    styles: { ...docStyles.tableStyles, lineWidth: 0 },
    startY: finalY,
  });
};

export default drawGreenLine;
