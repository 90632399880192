import isEmptyVAL from '../checks/isEmptyVAL';

import { transUTL } from '../../utils/transUTL';
import loggerUTL from '../../utils/loggerUTL';

const findIDUVAL = (data, dataSources) => {
  const inputErrors = {};

  /* ============================================
           Philippines Suspicious Activity
  ============================================ */
  if (dataSources.includes('Philippines Suspicious Activity')) {
    loggerUTL('VALIDATE Philippines Suspicious Activity...');

    let providedInputs = [];

    if (!isEmptyVAL(data.firstName)) {
      providedInputs.push({ firstName: data.firstName })
    } else {
      data.firstName = ''
    }

    if (!isEmptyVAL(data.lastName)) {
      providedInputs.push({ lastName: data.lastName })
    } else {
      data.lastName = ''
    }

    if (!isEmptyVAL(data.yearOfBirth)) {
      providedInputs.push({ yearOfBirth: data.yearOfBirth })
    } else {
      data.yearOfBirth = ''
    }

    if (!isEmptyVAL(data.barangay)) {
      providedInputs.push({ barangay: data.barangay })
    } else {
      data.barangay = ''
    }

    if (!isEmptyVAL(data.city)) {
      providedInputs.push({ city: data.city })
    } else {
      data.city = ''
    }

    if (!isEmptyVAL(data.province)) {
      providedInputs.push({ province: data.province })
    } else {
      data.province = ''
    }

    if (!isEmptyVAL(data.postCode)) {
      providedInputs.push({ postCode: data.postCode })
    } else {
      data.postCode = ''
    }

    // When only select Philippines suspicious activity
    // by default either first name or last name is mandatory
    // and others are required to provide at least 3 including name(s), except email & hitTarget(belong to DIGITAL ID SAFEGUARD))
    if (providedInputs.length < 3) {

      if (providedInputs.length === 0) {
        if (isEmptyVAL(data.firstName) && !isEmptyVAL(data.lastName)) {
          inputErrors.firstName = transUTL('');
        }
        if (!isEmptyVAL(data.firstName) && isEmptyVAL(data.lastName)) {
          inputErrors.lastName = transUTL('');
        }
        if (isEmptyVAL(data.firstName) && isEmptyVAL(data.lastName)) {
          inputErrors.firstName = transUTL('');
          inputErrors.lastName = transUTL('');
        }
        if (!providedInputs.some(item => item.hasOwnProperty('barangay'))) {
          inputErrors.barangay = transUTL('');
        }
        if (!providedInputs.some(item => item.hasOwnProperty('city'))) {
          inputErrors.city = transUTL('');
        }
        if (!providedInputs.some(item => item.hasOwnProperty('province'))) {
          inputErrors.province = transUTL('');
        }
        if (!providedInputs.some(item => item.hasOwnProperty('postCode'))) {
          inputErrors.postCode = transUTL('');
        }
        if (!providedInputs.some(item => item.hasOwnProperty('yearOfBirth'))) {
          inputErrors.yearOfBirth = transUTL('');
        }
      }

      // Philippines suspicious activity & Watchlist AML
      if (dataSources.includes('Watchlist AML') && !dataSources.includes('Digital ID Safeguard')) {
        // yearOfBirth, firstName, lastName are mandatory when Watchlist is selected
        if (isEmptyVAL(data.yearOfBirth)) {
          inputErrors.yearOfBirth = transUTL('translateFormOverview.formErrors.yearOfBirth');
        }
        if (isEmptyVAL(data.firstName)) {
          inputErrors.firstName = transUTL('translateFormOverview.formErrors.firstName');
        }
        if (isEmptyVAL(data.lastName)) {
          inputErrors.lastName = transUTL('translateFormOverview.formErrors.lastName');
        }
      }
      // Philippines suspicious activity & Digital ID Safeguard
      else if (!dataSources.includes('Watchlist AML') && dataSources.includes('Digital ID Safeguard')) {
        // email is mandatory when Safeguard is selected
        if (isEmptyVAL(data.emailID)) {
          inputErrors.emailID = transUTL('translateFormOverview.formErrors.emailID'); //
        }
      }
      // Philippines suspicious activity & Digital ID Safeguard & Watchlist AML
      else if (dataSources.includes('Watchlist AML') && dataSources.includes('Digital ID Safeguard')) {
        if (isEmptyVAL(data.yearOfBirth)) {
          inputErrors.yearOfBirth = transUTL('translateFormOverview.formErrors.yearOfBirth');
        }
        if (isEmptyVAL(data.emailID)) {
          inputErrors.emailID = transUTL('translateFormOverview.formErrors.emailID');
        }
      }

    } else {

      // if (isEmptyVAL(data.firstName) && isEmptyVAL(data.lastName)) {
      //   inputErrors.firstName = transUTL('translateFormOverview.formErrors.firstNameORlastName');
      //   inputErrors.lastName = transUTL('translateFormOverview.formErrors.firstNameORlastName');
      // }

      // Philippines suspicious activity & Watchlist AML
      if (dataSources.includes('Watchlist AML') && !dataSources.includes('Digital ID Safeguard')) {
        // yearOfBirth, firstName, lastName are mandatory when Watchlist is selected
        if (isEmptyVAL(data.yearOfBirth)) {
          inputErrors.yearOfBirth = transUTL('translateFormOverview.formErrors.yearOfBirth');
        }
        if (isEmptyVAL(data.firstName)) {
          inputErrors.firstName = transUTL('translateFormOverview.formErrors.firstName');
        }
        if (isEmptyVAL(data.lastName)) {
          inputErrors.lastName = transUTL('translateFormOverview.formErrors.lastName');
        }
      }
      // When select Philippines suspicious activity & Digital ID Safeguard
      else if (!dataSources.includes('Watchlist AML') && dataSources.includes('Digital ID Safeguard')) {
        // email is mandatory when Safeguard is selected
        if (isEmptyVAL(data.emailID)) {
          inputErrors.emailID = transUTL('translateFormOverview.formErrors.emailID');
        }
      }
      // Philippines suspicious activity & Digital ID Safeguard & Watchlist AML
      else if (dataSources.includes('Watchlist AML') && dataSources.includes('Digital ID Safeguard')) {
        if (isEmptyVAL(data.yearOfBirth)) {
          inputErrors.yearOfBirth = transUTL('translateFormOverview.formErrors.yearOfBirth');
        }
        if (isEmptyVAL(data.emailID)) {
          inputErrors.emailID = transUTL('translateFormOverview.formErrors.emailID');
        }
      }
    }
  }
  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default findIDUVAL;
