const consentCheckerAU = (dataSources, inputData) => {
  const consentObtained = {};

  // USER CONSENT TRIGGER
  if (dataSources.includes('Australia Superannuation')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Superannuation'] = true;

    if (!inputData.superannuationConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Payroll')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Payroll'] = true;

    if (!inputData.payrollConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Birth Certificate')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Birth Certificate'] = true;

    if (!inputData.birthCertificateConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Centrelink Card')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Centrelink Card'] = true;

    if (!inputData.centerlinkCardConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Driver Licence')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Driver Licence'] = true;

    if (!inputData.driverLicenceConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Medicare Card')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Medicare Card'] = true;

    if (!inputData.medicareCardConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Passport')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Passport'] = true;

    if (!inputData.passportConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Visa')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Visa'] = true;
    if (!inputData.visaConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Visa Entitlement')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Visa Entitlement'] = true;

    if (!inputData.visaEntitlementConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Australia Credit Bureau')) {
    // CONSENT COLLECTOR
    consentObtained['Australia Credit Bureau'] = true;

    if (!inputData.creditBureauConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerAU;
