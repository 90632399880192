const dataSourcesPH = {
  residential: {
    title: '菲律宾住宅',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    streetName: '街道名称',
    province: '省',
    city: '市',
    gender: '性别',
    barangay: '巴兰加',
    postcode: '邮编',
    address: '地址',
  },
  creditBureau: {
    title: '菲律宾信贷局',
    searchSuccessful: '搜索成功',
    identityVerified: '身份已验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
    nationalIDNo: '身份证号码',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓氏',
    dateOfBirth: '生日',
    streetName: '街道名字',
    province: '省',
    city: '城市',
    barangay: '巴兰加',
    postcode: '邮编',
    requestTimedOut: '请求超时',
  },
  suspiciousActivity: {
    // 'FIND (ID)U RESULTS',
    title: '菲律宾的可疑活动',
    FirstName: '名字',
    MiddleName: '中间名字',
    LastName: '姓氏',
    Gender: '性别',
    VoterID: '选民编号',
    DOB: '出生日期',
    DayDOB: '日',
    MonthDOB: '月',
    YearDOB: "年",
    MaritalStatus: '婚姻状况',
    Street: '街道',
    Barangay: '描笼涯',
    Region: '邮政编码',
    City: '城市',
    Province: '省',
    MotherLastName: '母亲姓氏',
    requestTimedOut: '请求超时',
    searchSuccessful: '搜索成功',
  },
  watchlistAml: {
    title: 'PEP 和制裁结果',
    additionalInfoURL: '网址',
    category: '类别',
    deathIndex: '死亡指数',
    gender: '性别',
    otherNames: '其他名称',
    scanId: "扫描身份证",
    whitelist: '白名单',
    yearOfBirth: '年',
    requestTimedOut: '请求超时',
    searchSuccessful: '搜索成功',
  },
  digitalIDSafeguard: {
    title: '数字身份证保护结果',
    searchSuccessful: '搜索成功',
    isSafe: '是安全的',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓氏',
    dateOfBirth: '生日',
    streetName: '街道名称',
    province: '省',
    city: '城市',
    barangay: '描笼涯',
    postcode: '邮政编码',
    requestTimedOut: '请求超时',
    searchSuccessful: '搜索成功',
  }
};

export default dataSourcesPH;
