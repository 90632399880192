const translateCountryNames = {
  // ----------- REGION (Global) -----------
  global: '全球',

  // ----------- REGION (APAC) -----------
  india: '印度',
  indonesia: '印度尼西亚',
  japan: '日本',
  cambodia: '柬埔寨',
  hongkong: '香港',
  'hongkong(specialadministrativeregionofchina)': '香港',
  singapore: '新加坡',
  australia: '澳大利亚',
  china: '中国',
  newzealand: '新西兰',
  malaysia: '马来西亚',
  philippines: '菲律宾',
  vietnam: '越南',

  // ----------- REGION (AFRICA) -----------
  southafrica: '南非',

  // ----------- REGION (EUROPE) -----------
  austria: '奥地利',
  belgium: '比利时',
  denmark: '丹麦',
  finland: '芬兰',
  france: '法国',
  germany: '德国',
  unitedkingdom: '大不列颠',
  ireland: '爱尔兰',
  italy: '意大利',
  luxembourg: '卢森堡',
  netherlands: '荷兰',
  norway: '挪威',
  portugal: '葡萄牙',
  russia: '俄国',
  spain: '西班牙',
  slovakia: '斯洛伐克',
  sweden: '瑞典',
  switzerland: '瑞士',
  bulgaria: '保加利亚',
  greece: '希腊',
  hungary: '匈牙利',
  poland: '波兰',
  czechrepublic: '捷克共和国',

  // ----------- REGION (MIDDLE EAST) -----------
  bahrain: '巴林',
  jordan: '约旦',
  kuwait: '科威特',
  lebanon: '黎巴嫩',
  oman: '阿曼',
  saudiarabia: '沙特阿拉伯',
  turkey: '火鸡',
  unitedarabemirates: '阿拉伯联合酋长国',

  // ----------- REGION (THE AMERICAS) -----------
  peru: '秘鲁',
  argentina: '阿根廷',
  brazil: '巴西',
  canada: '加拿大',
  chile: '智利',
  mexico: '墨西哥',
  unitedstates: '美国',

  // ----------- WATCHLIST AML -----------
  watchlistaml: '澳大利亚',
  watchlistAML: '反洗钱观察名单',

  /* ============================================
    PAKISTAN, NEPAL, THAILAND & PAPUA NEW GUINEA
   ============================================ */
  pakistan: '巴基斯坦',
  nepal: '尼泊尔',
  thailand: '泰国',
  papuanewguinea: '巴布亚新几内亚',
  kenya: '肯尼亚',
  nigeria: '奈及利亚',
  bangladesh: '孟加拉国',
  morocco: '摩洛哥',
};

export default translateCountryNames;
