import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const UserTableToolbar = ({ history }) => {
  const handleClick = () => {
    history.push('/admin/createUser');
  };

  return (
    <Fragment>
      <Tooltip title={'Create User'}>
        <IconButton onClick={() => handleClick()}>
          <PersonAddIcon className="table__create-user-button" />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

export default UserTableToolbar;
