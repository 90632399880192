const biometricsTrigger = (bioData) => {
  const bioKeys = Object.keys(bioData);

  let bioTriggerParsed = {};

  let logoKey = '';

  for (let element of bioKeys) {
    if (element.includes('isDocScanned') || element.includes('isBioAuth')) {
      bioTriggerParsed = element.replace(/%22/g, '"');
      bioTriggerParsed = JSON.parse(bioTriggerParsed);
      logoKey = element;
    }
  }

  delete bioData[logoKey];

  return {
    ...bioTriggerParsed,
    bioData,
    selfie: bioData.selfie,
    identityDoc0: bioData['identityDocument-0'],
  };
};

export default biometricsTrigger;
