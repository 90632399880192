import isEmptyVAL from '../checks/isEmptyVAL';

// COMMON

// UTILITIES
import { transUTL } from '../../utils/transUTL';

const loginVAL = (data) => {
  const errors = {};

  // CHECK IF CREDENTIALS IS NOT EMPTY
  data.username = !isEmptyVAL(data.username) ? data.username : '';
  data.password = !isEmptyVAL(data.password) ? data.password : '';

  // CREDENTIALS FEEDBACK ACCUMULATOR
  if (isEmptyVAL(data.username)) {
    errors.username = transUTL('translateform-verview.formInputErrors.username');
  }
  if (isEmptyVAL(data.password)) {
    errors.password = transUTL('translateform-verview.formInputErrors.password');
  }

  // RETURN ERRORS
  return {
    errors,
    isValid: isEmptyVAL(errors),
  };
};

export default loginVAL;
