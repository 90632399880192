import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const PassportNewZealand = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { passportNo, passportExpiry, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['New Zealand DIA Passport'],
      element: (
        <TextFieldGroup
          key="passportNo"
          id="passportNo"
          type="text"
          name="passportNo"
          dataName="passportNoNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.passportNo')}
          value={passportNo}
          placeholder={transUTL('translatePlaceholder.inputNZ.passportNo')}
          placeholderTrigger={isEmptyVAL(passportNo) ? 'input-empty' : ''}
          error={errors.passportNo}
          errorTrigger={errors.passportNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['New Zealand DIA Passport'],
      element: (
        <TextFieldGroup
          key="passportExpiry"
          id="passportExpiry"
          type="date"
          name="passportExpiry"
          dataName="passportExpiryNZ"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.passportExpiry'
          )}
          value={passportExpiry}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.passportExpiry'
          )}
          placeholderTrigger={
            isEmptyVAL(passportExpiry) ? 'input-empty' : ''
          }
          max="9999-12-31"
          error={errors.passportExpiry}
          errorTrigger={errors.passportExpiry ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

PassportNewZealand.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default PassportNewZealand;