import isEmptyVAL from "../../../validations/checks/isEmptyVAL";

const removeValidationMessage = (event, userInputSTH) => {
  if (!isEmptyVAL(userInputSTH[event?.target?.name])) {
    const errors = userInputSTH.errors ? userInputSTH.errors : {}
    return {
      ...userInputSTH,
      errors: {
        ...errors,
        [event.target.name]: ''
      }
    }
  } else {
    return {...userInputSTH}
  }
}

export default removeValidationMessage;