const dataSourcesAU = {

  // General fields for all Au data source
  suburb: 'Pinggir bandar',
  postCode: 'Poskod',
  email: 'Alamat emel',
  phoneNo: 'Nombor telefon',

  // Specific fields for each data source
  deathCheck: {
    title: 'Pemeriksaan Kematian Australia',
  },
  deathCheckReturnData: {
    title: 'Data Kematian Australia dikembalikan',
    registrationState: 'Negeri Pendaftaran',
    registrationYear: 'Tahun Pendaftaran',
    registrationNumber: 'Nombor pendaftaran',
    dateOfDeath: 'Tarikh kematian',
    possibleDeceasedRecord: 'Rekod Kemungkinan Berkurang',
    possibleDeceasedRecordName: 'Nama Rekod Kemungkinan Berkurang'
  },
  payroll: {
    title: 'Gaji Australia',
    ABN: 'ABN',
  },
  superannuation: {
    title: 'Superannuation Australia',
    ABN: 'ABN',
  },
  passport: {
    title: 'Pasport Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  birthCertificate: {
    title: 'Surat Beranak Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  centerlinkCard: {
    title: 'Kad Pusat Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },

  driverLicence: {
    title: 'Lesen Memandu Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  propertyOwnership: {
    title: 'Pemilikan Harta Australia',  
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  medicareCard: {
    title: 'Kad Medicare Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  residential: {
    title: 'Kediaman Australia',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    streetName: 'Nama jalan',
    suburb: 'Pinggir bandar',
    state: 'Nyatakan',
    postCode: 'Poskod',
    altAddressMatchScore: 'Skor Pertandingan Alamat Alt',
    altNameMatchScore: 'Skor Pertandingan Nama Alt',
    dpId: 'dpid',
    source: 'Sumber',
    email: 'Alamat emel',
    phoneNo: 'Nombor telefon',
  },
  residentialLookup: {
    title: 'Pencarian Kediaman Australia',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    streetName: 'Nama jalan',
    suburb: 'Pinggir bandar',
    state: 'Nyatakan',
    postCode: 'Poskod',
    source: 'Sumber',
    email: 'Alamat emel',
    phoneNo: 'Nombor telefon',
    landlineNumber: 'Nombor garisan darat',
    mobileNumber: 'Nombor telefon',
  },
  visa: {
    title: 'Visa Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  visaEntitlement: {
    title: 'Hak Visa Australia',
    searchSuccessful: 'Carian Berjaya',
    documentNo: 'nombor dokumen',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
  },
  creditBureau: {
    title: 'Biro Kredit Australia',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    streetName: 'Nama jalan',
    suburb: 'Pinggir bandar',
    state: 'Nyatakan',
    postCode: 'Poskod',
  },
  citizens: {
    title: 'Kediaman Australia',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    streetName: 'Nama jalan',
    suburb: 'Pinggir bandar',
    state: 'Nyatakan',
    postCode: 'Poskod',
    altAddressMatchScore: 'Skor Pertandingan Alamat Alt',
    altNameMatchScore: 'Skor Pertandingan Nama Alt',
    dpId: 'dpid',
    source: 'Sumber',
    email: 'Alamat emel',
    phoneNo: 'Nombor telefon',
  },
};

export default dataSourcesAU;
