import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';

// HELPERS
import initialStateHK from './helpers/initialStateHK';
import consentCheckerHK from './helpers/consentCheckerHK';
import whitelistedInputDataHK from './helpers/whitelistedInputDataHK';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import hongKongVAL from '../../../../validations/inputFields/hongKongVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationHongKong from './inputForms/PersonalInformationHongKong';
import IdentityDetailsHongKong from './inputForms/IdentityDetailsHongKong';
import CurrentResidentialAddressHongKong from './inputForms/CurrentResidentialAddressHongKong';
import TermsAndConditionsHongKong from './inputForms/TermsAndConditionsHongKong';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL"; 

const HongKongForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateHK);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.hongkongDataSources
  );

  // LOAD FORM DATA
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.hongkongFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.hongkongFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = hongKongVAL(
      inputData,
      selectedDataSources
    );
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // USER CONSENT CHECK
    const { isConsent, consentObtained } = consentCheckerHK(
      selectedDataSources,
      inputData
    );

    loggerUTL(isConsent, consentObtained);

    // PROVIDE ERROR FEEDBACK IF CONSENT IS NOT CHECKED
    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataHK(inputData, selectedDataSources);

    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      consentObtained,
      addressElement1: whitelisted.building,
      addressElement2: whitelisted.streetName,
      addressElement3: whitelisted.district,
      addressElement4: whitelisted.state,
      addressElement5: whitelisted.postCode,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const {
      errors,
      bankCardConsentObtained,
      ...filteredFormData
    } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');
    const { errors, creditBureauConsentObtained, ...reset } = initialStateHK;

    setUserInputSTH(initialStateHK);
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    loggerUTL('CHECKBOX...');
    const { creditBureauConsentObtained } = userInputSTH;

    let creditBureauConsent = false;

    if (selectedDataSources.includes('Hong Kong Credit Bureau')) {
      creditBureauConsent = !creditBureauConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      creditBureauConsentObtained: creditBureauConsent,
    });
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
          };
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded selfie 
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (selectedDataSources.includes('Hong Kong Credit Bureau')) {
  } else {
    conditionalClientRef.push(
      ...['Hong Kong Residential', 'Hong Kong Marketing', 'Watchlist AML']
    );
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
        'Watchlist AML',
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationHongKong
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Hong Kong Credit Bureau'],
      element: (
        <FormController
          key="identityDetails"
          title={transUTL('translateFormOverview.formHeaders.identityDetails')}
          inputForm={
            <IdentityDetailsHongKong
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
      ],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressHongKong
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Hong Kong Credit Bureau'],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            'translateFormOverview.formHeaders.termsAndConditions'
          )}
          inputForm={
            <TermsAndConditionsHongKong
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

HongKongForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
})(withRouter(HongKongForms));
