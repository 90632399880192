import tableFilter from '../../../../helpers/tableFilter';

import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const {
    residential,
    watchlistAML
  } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(residential.status),
      col3: naUTL(watchlistAML.status)
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(residential.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore)
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(residential.identityVerified),
      col3: naUTL(watchlistAML.identityVerified)
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(residential.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName)
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(residential.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName)
    },
    {
      col1: 'Date of Birth Verified',
      col2: naUTL(residential.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth)
    },
    {
      col1: 'Street No Verified',
      col2: naUTL(residential.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1)
    },
    {
      col1: 'Street Name Verified',
      col2: naUTL(residential.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1)
    },
    {
      col1: 'City Verified',
      col2: naUTL(residential.verifications.addressElement3),
      col3: naUTL(watchlistAML.verifications.addressElement3)
    },
    {
      col1: 'Postcode Verified',
      col2: naUTL(residential.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5)
    }
  ];

  return tableFilter(table);
}

export default jsonTableRow;