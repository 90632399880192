const stateCapitalAU = {
  queensland: 'Queensland',
  newSouthWales: 'New South Wales',
  southAustralia: 'South Australia',
  tasmania: 'Tasmania',
  victoria: 'Victoria',
  westernAustralia: 'Western Australia',
  australianCapitalTerritory: 'Australian Capital Territory',
  northernTerritory: 'Northern Territory',
};

export default stateCapitalAU;
