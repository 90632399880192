import asiaPacific from '../regions/asiaPacific';
import middleEast from '../regions/middleEast';
import europe from '../regions/europe';
import africa from '../regions/africa';
import americas from '../regions/americas';
import global from '../regions/global';

// FILTER DATA SOURCES
const modifiedAuth = (dataSources) => [
  ...asiaPacific(dataSources),
  ...europe(dataSources),
  ...middleEast(dataSources),
  ...africa(dataSources),
  ...americas(dataSources),
  ...global(dataSources)

];

export default modifiedAuth;
