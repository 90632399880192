// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataAML = (inputData, dataSources) => {
  let watchlistData;

  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...watchlistData,
  };
};

export default whitelistedInputDataAML;
