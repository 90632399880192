import jsonTableRow from "./rows";

// HELPERS
import title from "../../../../helpers/title";

// UTILS
import arrayTo2DArrayUTL from "../../../../../../utils/arrayTo2DArrayUTL";

const dataSourceVerificationResults = (doc, data, info, docStyles) => {
  const { Response } = data;

  // TITLE
  title(
    doc,
    docStyles,
    "Summary of Data Source Verifications and Results",
    docStyles.headers.secondary,
    docStyles.separation
  );

  // DEFAULT COLUMN
  const columns = [{ title: "Data Sources", dataKey: "col1" }];

  // DATA SOURCE RESPONSE
  let diaBirth = Response.serviceResponses["New Zealand DIA Birth"];
  let diaCitizenship = Response.serviceResponses["New Zealand DIA Citizenship"];
  let diaPassport = Response.serviceResponses["New Zealand DIA Passport"];
  let driverLicence = Response.serviceResponses["New Zealand Driver Licence"];
  let linzProperty = Response.serviceResponses["New Zealand LINZ Property"];
  let residential = Response.serviceResponses["New Zealand Residential"];
  let companiesOffice =
    Response.serviceResponses["New Zealand Companies Office"];
  let yellowPages = Response.serviceResponses["New Zealand Yellow Pages"];
  let creditBureau = Response.serviceResponses["New Zealand Credit Bureau"];
  let watchlistAML = Response.serviceResponses["Watchlist AML"];

  // NEW ZEALAND DIA BIRTH
  if (diaBirth) {
    columns.push({
      title: "New Zealand DIA Birth",
      dataKey: "col2",
    });
  } else {
    diaBirth = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DIA BIRTH
  if (diaCitizenship) {
    columns.push({
      title: "New Zealand DIA Citizenship",
      dataKey: "col3",
    });
  } else {
    diaCitizenship = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DIA BIRTH
  if (diaPassport) {
    columns.push({
      title: "New Zealand DIA Passport",
      dataKey: "col4",
    });
  } else {
    diaPassport = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DRIVER LICENCE
  if (driverLicence) {
    columns.push({
      title: "New Zealand Driver Licence",
      dataKey: "col5",
    });
  } else {
    driverLicence = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND LINZ PROPERTY
  if (linzProperty) {
    columns.push({
      title: "New Zealand LINZ Property",
      dataKey: "col6",
    });
  } else {
    linzProperty = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND RESIDENTIAL
  if (residential) {
    columns.push({
      title: "New Zealand Residential",
      dataKey: "col8",
    });
  } else {
    residential = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND COMPANIES OFFICE
  if (companiesOffice) {
    columns.push({
      title: "New Zealand Companies Office",
      dataKey: "col9",
    });
  } else {
    companiesOffice = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND COMPANIES OFFICE
  if (yellowPages) {
    columns.push({
      title: "New Zealand Yellow Pages",
      dataKey: "col10",
    });
  } else {
    yellowPages = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND CREDIT BUREAU
  if (creditBureau) {
    columns.push({
      title: "New Zealand Credit Bureau",
      dataKey: "col11",
    });
  } else {
    creditBureau = { verifications: {}, returnedData: {} };
  }

  // GLOBAL WATCHLIST AML
  if (watchlistAML) {
    columns.push({
      title: "Global Watchlist AML",
      dataKey: "col12",
    });
  } else {
    watchlistAML = { verifications: {}, returnedData: {} };
  }

  // TABLE ROW
  const rows = jsonTableRow({
    diaBirth,
    diaCitizenship,
    diaPassport,
    driverLicence,
    linzProperty,
    residential,
    companiesOffice,
    yellowPages,
    creditBureau,
    watchlistAML,
  });

  // TABLE
  let finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  const maxAmountOfColumns = 3;
  const firstColumn = columns.shift();
  const maxColArray = arrayTo2DArrayUTL(columns, maxAmountOfColumns); // 3 COLUMNS ONLY

  maxColArray.map((col, index) => {
    col.unshift(firstColumn);

    if (index > 0) {
      finalY = doc.previousAutoTable.finalY + docStyles.separation;
    }

    let cellWidth = docStyles.fullCellLength / 4;
    if (maxColArray[index].length === 4) {
      cellWidth = docStyles.fullCellLength / 4;
    } else if (maxColArray[index].length === 3) {
      cellWidth = docStyles.fullCellLength / 3;
    } else if (maxColArray[index].length === 2) {
      cellWidth = docStyles.fullCellLength / 2;
    }

    doc.autoTable({columns: col, body: rows, 
      didParseCell: (data) => {
        const { cell } = data;
        cell.styles.fillColor = [255, 255, 255];
        if (cell.section.includes("body") && cell.raw.length > 30) {
          cell.styles.cellPadding = { top: 15, bottom: 15 };
        }
      },
      showHead: "firstPage",
      styles: {
        textColor: docStyles.colorText,
        font: docStyles.fontFamily.time,
        ...docStyles.tableStyles,
      },
      headStyles: {
        fontSize: docStyles.textSize,
        textColor: docStyles.colorGreen,
        fontStyle: "normal",
      },
      columnStyles: {
        col1: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
        col2: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
        col3: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
      },
      startY: finalY,
    });
    return null;
  });
};

export default dataSourceVerificationResults;
