const dataSourcesID = {
  nik: {
    title: 'Kad Pengenalan Penduduk Indonesia',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    nik: 'Saya',
    name: 'Nama',
    gender: 'Jantina',
    address: 'Alamat',
    dateOfBirth: 'Tarikh lahir',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressElement1: 'alamat',
    addressElement2: 'kelurahan',
    addressElement4: 'Mukim',
    addressElement5: 'daerah',
  },
  npwp: {
    title: 'Nombor ID Cukai',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    addressMatchScore: 'Address Match Score',
    npwp: 'Nombor ID Cukai',
    nameMatchScore: 'Skor Pertandingan Nama',
    fullName: 'Nama Penuh',
    dateOfBirth: 'Tarikh lahir',
    nik: 'Saya',
  },
};

export default dataSourcesID;
