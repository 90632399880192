const inputIN = {
  // PERSONAL INFORMATION
  firstName: 'Ram',
  middleName: 'Nath',
  lastName: 'Kovind',
  dateOfBirth: 'yyyy/mm/dd',

  // ACCOUNT NUMBER
  pan: 'AAAPL1234C',
  epic: 'UTC026351',
  aadhaarNo: '123456789123',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '1302',
  streetName: 'BG Kher Rd',
  suburb: 'WORLI NAKA',
  // locality: 'WORLI NAKA',
  city: 'MUMBAI',
  state: 'Maharashtra',
  postCode: '400018',

  // DRIVERS LICENCE
  driversLicenceNumber: 'MH09 20080003369',
  
  // PASSPORT
  passportNo: '',
  fileNo: '',
  dateOfIssue: '',

  // REFERENCE
  reportingReference: '',
};

export default inputIN;
