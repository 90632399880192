import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../components/header/Header';

// VALIDATIONS
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';

// COMPONENTS
const GlobalWatchlistAML = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  let { safeHarbourScore, returnedData, verifications } = results[type];
  if (isEmptyVAL(verifications)) verifications = {};

  let watchlistResults = {};
  if (!isEmptyVAL(returnedData)) {
    if (!isEmptyVAL(returnedData.watchlistResults)) {
      if (returnedData.watchlistResults.length > 0) {
        watchlistResults = returnedData.watchlistResults[0];
      }
    }
  }

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL('translateSearchVerification.watchlistAML.title')}
      />

      <div className="search-verification__verify">
        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.watchlistAML.searchSuccessful'
              )}
            </p>
            <span className="material-icons success">check_circle</span>
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.watchlistAML.safeHarbourScore'
              )}
            </p>
            {!isEmptyVAL(safeHarbourScore) ? (
              safeHarbourScore ? (
                safeHarbourScore
              ) : (
                  <span>{safeHarbourScore}</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          {/* <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.watchlistAML.WatchlistStatusText'
              )}
            </p>
            {watchlistStatus}
          </div> */}
          <div className="search-verification__verify-item">
            <p>
              {transUTL('translateSearchVerification.watchlistAML.category')}
            </p>
            {!isEmptyVAL(watchlistResults.category) ? (
              <span>{watchlistResults.category}</span>
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL('translateSearchVerification.watchlistAML.otherNames')}
            </p>
            {!isEmptyVAL(watchlistResults.otherNames) ? (
              <span>{watchlistResults.otherNames.join(', ')}</span>
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL('translateSearchVerification.watchlistAML.pdfLink')}
            </p>
            {!isEmptyVAL(watchlistResults.additionalInfoURL) ? (
              <span>
                <a
                  href={watchlistResults.additionalInfoURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {watchlistResults.additionalInfoURL}
                </a>
              </span>
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

GlobalWatchlistAML.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default GlobalWatchlistAML;
