import React, { Fragment } from "react";
import { transUTL } from "../../../../../utils/transUTL";
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";
import TextFieldGroup from "../../../../../components/common/textFieldGroup/TextFieldGroup";

const CurrentResidentialAddressBrazil = ({
	formState,
	selectedDataSources,
	handleOnChange,
	handleOnBlur,
	handleEnterKeyTrigger,
}) => {
	const {
		streetName,
		locality,
		district,
		postCode,
		errors,
	} = formState;

	const inputElements = [
		{
			belongsTo: [
				"Brazil National ID"
			],
			element: (
				<TextFieldGroup
					key="streetName"
					id="streetName"
					type="text"
					name="streetName"
					dataName="streetNameBR"
					labelClass="form-group__label"
					label={transUTL(
						"translateFormOverview.inputLabels.streetName"
					)}
					value={streetName}
					placeholder={transUTL(
						"translatePlaceholder.inputBR.streetName"
					)}
					placeholderTrigger={
						isEmptyVAL(streetName) ? "input-empty" : ""
					}
					error={errors.streetName}
					errorTrigger={errors.streetName ? "input-error" : ""}
					handleOnChange={handleOnChange}
					handleOnBlur={handleOnBlur}
					handleEnterKeyTrigger={handleEnterKeyTrigger}
				/>
			)
		},
		{
			belongsTo: ["Brazil National ID"],
			element: (
				<TextFieldGroup
					key="locality"
					id="locality"
					type="text"
					name="locality"
					dataName="localityBR"
					labelClass="form-group__label"
					label={transUTL("translateFormOverview.inputLabels.locality")}
					value={locality}
					placeholder={transUTL("translatePlaceholder.inputBR.locality")}
					placeholderTrigger={isEmptyVAL(locality) ? "input-empty" : ""}
					error={errors.locality}
					errorTrigger={errors.locality ? "input-error" : ""}
					handleOnChange={handleOnChange}
					handleOnBlur={handleOnBlur}
					handleEnterKeyTrigger={handleEnterKeyTrigger}
				/>
			)
		},
		{
			belongsTo: ["Brazil National ID"],
			element: (
				<TextFieldGroup
					key="district"
					id="district"
					type="text"
					name="district"
					dataName="districtBR"
					labelClass="form-group__label"
					label={transUTL(
						"translateFormOverview.inputLabels.district"
					)}
					value={district}
					placeholder={transUTL("translatePlaceholder.inputBR.district")}
					placeholderTrigger={isEmptyVAL(district) ? "input-empty" : ""}
					error={errors.district}
					errorTrigger={errors.district ? "input-error" : ""}
					handleOnChange={handleOnChange}
					handleOnBlur={handleOnBlur}
					handleEnterKeyTrigger={handleEnterKeyTrigger}
				/>
			)
		},
		{
			belongsTo: ["Brazil National ID"],
			element: (
				<TextFieldGroup
					key="postCode"
					id="postCode"
					type="text"
					name="postCode"
					dataName="postCodeBR"
					labelClass="form-group__label"
					label={transUTL(
						"translateFormOverview.inputLabels.postCode"
					)}
					value={postCode}
					placeholder={transUTL('translatePlaceholder.inputBR.postCode')}
					placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
					error={errors.postCode}
					errorTrigger={errors.postCode ? 'input-error' : ''}
					handleOnChange={handleOnChange}
					handleOnBlur={handleOnBlur}
					handleEnterKeyTrigger={handleEnterKeyTrigger}
				/>
			)			
		}
	];

	return (
		<Fragment>
			<div className="form-overview__form-inputs">
				{outputMasterUTL(selectedDataSources, inputElements)}
				<span />
			</div>
		</Fragment>
	);
};

export default CurrentResidentialAddressBrazil;
