import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';
import clearUserDataUTL from '../../utils/clearUserDataUTL';
import { transUTL } from '../../utils/transUTL';
import selectedDataSourceTemplateUTL from '../../utils/selectedDataSourceTemplateUTL';
import Header from '../../components/header/Header';
import { getUserPIIDataV2AXN } from '../../actions/transactionHistory/transactionHistoryActions';
import { retainApiReqAXN } from '../../actions/formData/apiReqActions';
import { loadingToggleAXN } from '../../actions/loading/loadingAction';
import CACHE from '../../constants/cache';
import { CROSS_BORDER_VERIFICATION, CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE } from "../../constants/crossBorderDataSources";
import UnsuccessfulSearchResultCards from "./reusableComponent/UnsuccessfulSearchResultCards";
import { uploadBiometricScansAXN } from '../../actions/transactionHistory/transactionHistoryActions';

const SearchVerificationTemplate = ({
  authRXS,
  countryRXS,
  formDataRXS,
  biometricsRXS,
  searchVerificationRXS,
  getUserPIIDataV2AXN,
  retainApiReqAXN,
  apiReqRXS,
  userInputRXS,
  loadingToggleAXN,
  uploadBiometricScansAXN,
  history
}) => {
  const {
    GLOBAL_VERIFICATION_RESULT_LAYOUT_CACHE,
    GLOBAL_VERIFICATION_FORM_ORDERS_CACHE
  } = CACHE;
  const [sectionColumnsLayout, setSectionColumnsLayout] = useState(
    null
  );
  const [verificationResults, setVerificationResults] = useState(null);
  const isCrossBorder = countryRXS === CROSS_BORDER_VERIFICATION
  const COUNTRY_SELECTION_PAGE = isCrossBorder ? CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE : '/country-selection' // button: New Search

  useEffect(() => {
    // ONCE 'isAuthenticated' CHANGES, THIS WILL FIRE SENDING THE USER TO THE DASHBOARD
    if (!isEmptyVAL(searchVerificationRXS)) {
      uploadBiometricScansAXN(
        searchVerificationRXS.reportingReference,
        biometricsRXS,
        countryRXS
      );
    } else {
      history.push('/data-source-selection');
    }

    // eslint-disable-next-line
  }, []);

  /*
    Create an object verificationResults so that it is easier to get the verification result
    of each input field later on.
  */
  const getCountryFormVerificationResults = (response, sectionLayout) => {
    const {
      addressMatchScore,
      identityVerified,
      nameMatchScore,
      safeHarbourScore,
      verifications
    } = response;

    const { verificationFieldsMapping } = sectionLayout;
    const result = {
      searchSuccessful: true,
      safeHarbourScore,
      addressMatchScore,
      identityVerified,
      nameMatchScore
    };

    for (const [key, value] of Object.entries(verificationFieldsMapping)) {
      result[key] = verifications[value];
    }
    return result;
  };

  const getWatchlistVerificationResults = (response) => {
    const { safeHarbourScore, category, otherNames, pdfLink } = response;

    const watchlistResults = {
      searchSuccessful: true,
      safeHarbourScore,
      category,
      otherNames,
      pdfLink
    };

    return watchlistResults;
  };

  const getVerificationResults = (verificationResultLayout) => {
    let verificationResults = {};

    const { serviceResponses } = searchVerificationRXS; // searchVerificationRXS
    verificationResultLayout.forEach(section => {
      const { selectedLayout } = section;

      if (selectedLayout.key !== 'WatchlistAML') {
        const result = getCountryFormVerificationResults(
          serviceResponses[selectedLayout.name],
          selectedLayout
        );
        verificationResults[selectedLayout.key] = result;
      } else {
        /*
          The Watchlist AML has a different result layout to other services
          so there is another function to return its layout.
        */
        const watchlistResults = getWatchlistVerificationResults(
          serviceResponses['Watchlist AML']
        );
        verificationResults['WatchlistAML'] = watchlistResults;
      }
    });

    return verificationResults;
  };

  const selectedDataSources = selectedDataSourceTemplateUTL();

  /*
    This function returns an object verificationResultLayout. The object tells the front end
    how to organise the verification result page. For example, it specifies what results should
    be shown in Column 1, Column 2 and Column 3 respectively for Morocco Residential.
  */
  const getVerificationResultLayout = async () => {
    /*
      To get overall result layout faster, we first read cached data from session storage to see if the data sources we
      need were already fetched before. If they were, just fetch data from the cache. Otherwise, read data from json files.
    */
    let verificationResultLayoutCache = JSON.parse(
      sessionStorage.getItem(GLOBAL_VERIFICATION_RESULT_LAYOUT_CACHE)
    );

    if (!verificationResultLayoutCache) {
      verificationResultLayoutCache = {};
    }

    if (!verificationResultLayoutCache[countryRXS]) {
      verificationResultLayoutCache[countryRXS] = {};
    }

    let verificationFormOrdersCache = JSON.parse(
      sessionStorage.getItem(GLOBAL_VERIFICATION_FORM_ORDERS_CACHE)
    );

    if (!verificationFormOrdersCache) {
      verificationFormOrdersCache = {};
    }

    /*
      If the order of all the forms is not cached, read it from the json file.
    */
    if (!verificationFormOrdersCache[countryRXS]) {
      const orderModule = await import(
        `../../verificationResultLayout_Json/${countryRXS}/formOrders.json`
      );
      verificationFormOrdersCache[countryRXS] = { ...orderModule.default };
      sessionStorage.setItem(
        GLOBAL_VERIFICATION_FORM_ORDERS_CACHE,
        JSON.stringify(verificationFormOrdersCache)
      );
    }

    const { orders } = verificationFormOrdersCache[countryRXS];
    const verificationResultLayout = [];

    const regex = new RegExp(`${countryRXS}`, 'gi');

    for (let i = 0; i < selectedDataSources.length; i++) {
      const dataSourceName = selectedDataSources[i]
        .replace(/\s/g, '')
        .replace(regex, '');
      let selectedLayout;

      if (verificationResultLayoutCache[countryRXS][dataSourceName]) {
        selectedLayout = {
          ...verificationResultLayoutCache[countryRXS][dataSourceName]
        };
      } else {
        /*
          If the targeted service is not cached, read it from a json file.
        */
        let path;

        if (dataSourceName !== 'WatchlistAML') {
          path = `${countryRXS}/dataSources/${dataSourceName}.json`;
        } else {
          path = 'watchlistAML/dataSources/WatchlistAML.json';
        }
        const layoutModule = await import(
          `../../verificationResultLayout_Json/${path}`
        );
        selectedLayout = { ...layoutModule.default };

        verificationResultLayoutCache[countryRXS][dataSourceName] = {
          ...layoutModule.default
        };
      }

      /*
        sort the forms according to their orders in the formOrders.json
      */
      const index = orders.indexOf(selectedLayout.key);
      verificationResultLayout.push({
        index,
        selectedLayout
      });
    }

    verificationResultLayout.sort((item1, item2) => item1.index - item2.index);

    sessionStorage.setItem(
      GLOBAL_VERIFICATION_RESULT_LAYOUT_CACHE,
      JSON.stringify(verificationResultLayoutCache)
    );

    return verificationResultLayout;
  };

  /*
    This function returns a sectionColumns object in which it specifies how
    the result tags (First Name, Last Name, addressMatchScore ...) should be
    distributed in different columns in the result page.
  */
  const getSectionColumnsLayout = (verificationResultLayout) => {
    const sectionColumnsLayout = {};

    for (let item of verificationResultLayout) {
      const {
        selectedLayout: { key, translationKey, columns, name }
      } = item;

      sectionColumnsLayout[key] = { key, translationKey, columns, name };
    }

    return sectionColumnsLayout;
  };

  const renderResults = (result) => {
    const type = typeof result;
    if (type === 'boolean') {
      return result ? (
        <span className="material-icons success">check_circle</span>
      ) : (
        <span className="material-icons error">cancel</span>
      );
    } else if (type === 'string') {
      return <span>{result}</span>;
    } else {
      return <span>{transUTL('translateReuse.na')}</span>;
    }
  };

  const initialiseResultLayout = () => {
    loadingToggleAXN(true);

    getVerificationResultLayout().then(verificationResultLayout => {
      const sectionColumnsLayout = getSectionColumnsLayout(
        verificationResultLayout
      );
      setSectionColumnsLayout(sectionColumnsLayout);

      const verificationResults = getVerificationResults(verificationResultLayout);
      setVerificationResults(verificationResults);

      loadingToggleAXN(false);
    });
  };

  useEffect(initialiseResultLayout, []);

  const handleClearResults = () => {
    clearUserDataUTL();
    history.push(COUNTRY_SELECTION_PAGE);
  };

  const generatePDF = () => {
    getUserPIIDataV2AXN(authRXS.user, searchVerificationRXS.reportingReference);
  };

  //Display input bar data
  const displayData = () => {
    const sections = Object.values(formDataRXS[countryRXS]);

    const sectionData = sections.map(section => {
      const fields = Object.values(section);
      const nonEmptyFields = fields.filter(field => !isEmptyVAL(field));
      return nonEmptyFields.join(' | ');
    });
    return sectionData.filter(str => !isEmptyVAL(str)).join(' | ');
  };

  let watchlistStatus = <span>{transUTL('translateReuse.na')}</span>;

  if (!isEmptyVAL(searchVerificationRXS.serviceResponses)) {
    if (!isEmptyVAL(searchVerificationRXS.serviceResponses['Watchlist AML'])) {
      if (
        searchVerificationRXS.serviceResponses['Watchlist AML'].identityVerified
      ) {
        watchlistStatus = (
          <span className="search-verification__error">
            {transUTL('translateSearchVerification.watchlistStatusFOUND')}
          </span>
        );
      } else {
        watchlistStatus = (
          <span className="search-verification__success">
            {transUTL('translateSearchVerification.watchlistStatusClear')}
          </span>
        );
      }
    }
  }

  return (
    <div className="search-verification common-form">
      <header className="header-primary">
        <h2>{transUTL('translateSearchVerification.title')}</h2>
        <span></span>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.reportingReference')}
            <span>{searchVerificationRXS.reportingReference}</span>
          </p>
          <p>
            {transUTL('translateSearchVerification.issueDateTime')}{' '}
            <span>{searchVerificationRXS.dateTime}</span>
          </p>
        </div>

        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.watchlistStatusText')}{' '}
            {watchlistStatus}
          </p>
          <p>
            {transUTL('translateSearchVerification.safeHarbour')}
            {searchVerificationRXS.safeHarbour ? (
              <span className="material-icons success">check_circle</span>
            ) : (
              <span className="material-icons error">cancel</span>
            )}
          </p>
        </div>
        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.matchStatus')}
            <span>{searchVerificationRXS.matchStatus}</span>
          </p>
        </div>
        <div>
          <header className="header-secondary search-verification__input-data">
            <h4 className="header-title-secondary">
              <strong>
                {transUTL('translateSearchVerification.inputData')}
              </strong>
              {displayData()}
            </h4>
          </header>
          {sectionColumnsLayout &&
            verificationResults &&
            Object.values(sectionColumnsLayout).map(item => (
              <div key={item.key} className="search-verification__results">
                <Header
                  title={transUTL(
                    `translateDataSources.${item.translationKey}`
                  )}
                />
                <div className="search-verification__verify">
                  {searchVerificationRXS.serviceResponses[item.name] &&
                    searchVerificationRXS.serviceResponses[item.name].status === 3
                    ? (
                      <UnsuccessfulSearchResultCards
                        errorMessage={searchVerificationRXS.serviceResponses[item.name].errorMessage} />)
                    : (
                      Object.entries(item.columns).map(([key, elements]) => (
                        <div key={key} className="search-verification__col">
                          {elements.map(element => (
                            <div
                              key={element}
                              className="search-verification__verify-item"
                            >
                              <p>
                                {transUTL(`translateSearchVerification.${element}`)}
                              </p>
                              {renderResults(
                                verificationResults[item.key][element]
                              )}
                            </div>
                          ))}
                        </div>
                      )))
                  }
                </div>
              </div>
            ))}

          {userInputRXS.isTickedApiDisplay &&
            <div key="displayApi" className="search-verification__results">
              <Header
                title={transUTL('translateSearchVerification.apiReqAndRes')}
              />
              <div className="search-verification__verify">
                
                <div className="search-verification__col">
                  <div className="search-verification__verify-items">
                    <pre>
                      {JSON.stringify(apiReqRXS.apiCall, undefined, 4)}
                    </pre>
                  </div>
                </div>
                <div className="search-verification__col">
                  <div className="search-verification__verify-items">
                    <pre>
                      {JSON.stringify(searchVerificationRXS, undefined, 4)}
                    </pre>
                  </div>
                </div>
              
              </div>
            </div>
            }

          <div className="search-verification__btns">
            <div className="btn-container btn-under">
              <button
                className="btn-primary"
                onClick={() => history.push('/form-overview-template')}
              >
                {transUTL('translateBtn.editSearch')}
              </button>

              <button className="btn-primary" onClick={handleClearResults}>
                {transUTL('translateBtn.newSearch')}
              </button>
            </div>

            <div className="btn-container btn-under">
              <button className="btn-primary" onClick={generatePDF}>
                {transUTL('translateBtn.generatePDF')}
              </button>

              <button className="btn-primary" onClick={() => window.print(history.push('/search-verification-template'))}>
                {transUTL('translateBtn.printSearchResults')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  formDataRXS: state.formDataRXS,
  searchVerificationRXS: state.searchVerificationRXS,
  apiReqRXS: state.apiReqRXS,
  retainApiReqAXN: state.retainApiReqAXN,
  userInputRXS: state.userInputRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  getUserPIIDataV2AXN,
  retainApiReqAXN,
  loadingToggleAXN,
  uploadBiometricScansAXN,
})(SearchVerificationTemplate);
