// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { driversLicence, pan, epic, passport, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(driversLicence.status),
      col3: naUTL(pan.status),
      col4: naUTL(epic.status),
      col5: naUTL(passport.status),
      col6: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(driversLicence.safeHarbourScore),
      col3: naUTL(pan.safeHarbourScore),
      col4: naUTL(epic.safeHarbourScore),
      col5: naUTL(passport.safeHarbourScore),
      col6: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(driversLicence.identityVerified),
      col3: naUTL(pan.identityVerified),
      col4: naUTL(epic.identityVerified),
      col5: naUTL(passport.identityVerified),
      col6: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'Document Number Verified',
      col2: naUTL(driversLicence.verifications.documentNumber),
      col3: naUTL(pan.verifications.documentNumber),
      col4: naUTL(epic.verifications.documentNumber),
      col5: naUTL(passport.verifications.documentNumber),
      col6: naUTL(watchlistAML.verifications.documentNumber),
    },
    {
      col1: 'Name Verified',
      col2: naUTL(driversLicence.verifications.fullName),
      col3: naUTL(pan.verifications.fullName),
      col4: naUTL(epic.verifications.fullName),
      col5: naUTL(passport.verifications.fullName),
      col6: naUTL(watchlistAML.verifications.fullName),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(driversLicence.verifications.firstName),
      col3: naUTL(pan.verifications.firstName),
      col4: naUTL(epic.verifications.firstName),
      col5: naUTL(passport.verifications.firstName),
      col6: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(driversLicence.verifications.lastName),
      col3: naUTL(pan.verifications.lastName),
      col4: naUTL(epic.verifications.lastName),
      col5: naUTL(passport.verifications.lastName),
      col6: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(driversLicence.verifications.dateOfBirth),
      col3: naUTL(pan.verifications.dateOfBirth),
      col4: naUTL(epic.verifications.dateOfBirth),
      col5: naUTL(passport.verifications.dateOfBirth),
      col6: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(driversLicence.verifications.gender),
      col3: naUTL(pan.verifications.gender),
      col4: naUTL(epic.verifications.gender),
      col5: naUTL(passport.verifications.gender),
      col6: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(driversLicence.nameMatchScore),
      col3: naUTL(pan.nameMatchScore),
      col4: naUTL(epic.nameMatchScore),
      col5: naUTL(passport.nameMatchScore),
      col6: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Address Verified',
      col2: naUTL(driversLicence.verifications.address),
      col3: naUTL(pan.verifications.address),
      col4: naUTL(epic.verifications.address),
      col5: naUTL(passport.verifications.address),
      col6: naUTL(watchlistAML.verifications.address),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(driversLicence.addressMatchScore),
      col3: naUTL(pan.addressMatchScore),
      col4: naUTL(epic.addressMatchScore),
      col5: naUTL(passport.addressMatchScore),
      col6: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(driversLicence.addressMatchScore),
      col3: naUTL(pan.addressMatchScore),
      col4: naUTL(epic.addressMatchScore),
      col5: naUTL(passport.addressMatchScore),
      col6: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'Passport Number Verified',
      col2: naUTL(driversLicence.verifications.passportNo),
      col3: naUTL(pan.verifications.passportNo),
      col4: naUTL(epic.verifications.passportNo),
      col5: naUTL(passport.verifications.passportNo),
      col6: naUTL(watchlistAML.verifications.passportNo),
    },
    {
      col1: 'File Number Verified',
      col2: naUTL(driversLicence.verifications.fileNo),
      col3: naUTL(pan.verifications.fileNo),
      col4: naUTL(epic.verifications.fileNo),
      col5: naUTL(passport.verifications.fileNo),
      col6: naUTL(watchlistAML.verifications.fileNo),
    },
    {
      col1: 'Date Of Issue Verified',
      col2: naUTL(driversLicence.verifications.dateOfIssue),
      col3: naUTL(pan.verifications.dateOfIssue),
      col4: naUTL(epic.verifications.dateOfIssue),
      col5: naUTL(passport.verifications.dateOfIssue),
      col6: naUTL(watchlistAML.verifications.dateOfIssue),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
