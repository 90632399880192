// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const {
    creditBureau,
    residential,
    utility,
    watchlistAML,
    phone,
  } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(residential.status),
      col3: naUTL(utility.status),
      col4: naUTL(watchlistAML.status),
      col5: naUTL(creditBureau.status),
      col6: naUTL(phone.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(residential.safeHarbourScore),
      col3: naUTL(utility.safeHarbourScore),
      col4: naUTL(watchlistAML.safeHarbourScore),
      col5: naUTL(creditBureau.safeHarbourScore),
      col6: naUTL(phone.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(residential.identityVerified),
      col3: naUTL(utility.identityVerified),
      col4: naUTL(watchlistAML.identityVerified),
      col5: naUTL(creditBureau.identityVerified),
      col6: naUTL(phone.identityVerified),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(residential.verifications.firstName),
      col3: naUTL(utility.verifications.firstName),
      col4: naUTL(watchlistAML.verifications.firstName),
      col5: naUTL(creditBureau.verifications.firstName),
      col6: naUTL(phone.verifications.firstName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(residential.verifications.lastName),
      col3: naUTL(utility.verifications.lastName),
      col4: naUTL(watchlistAML.verifications.lastName),
      col5: naUTL(creditBureau.verifications.lastName),
      col6: naUTL(phone.verifications.lastName),
    },
    {
      col1: 'Gender',
      col2: naUTL(residential.verifications.gender),
      col3: naUTL(utility.verifications.gender),
      col4: naUTL(watchlistAML.verifications.gender),
      col5: naUTL(creditBureau.verifications.gender),
      col6: naUTL(phone.verifications.gender),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(residential.verifications.dateOfBirth),
      col3: naUTL(utility.verifications.dateOfBirth),
      col4: naUTL(watchlistAML.verifications.dateOfBirth),
      col5: naUTL(creditBureau.verifications.dateOfBirth),
      col6: naUTL(phone.verifications.dateOfBirth),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(residential.nameMatchScore),
      col3: naUTL(utility.nameMatchScore),
      col4: naUTL(watchlistAML.nameMatchScore),
      col5: naUTL(creditBureau.nameMatchScore),
      col6: naUTL(phone.nameMatchScore),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(residential.addressMatchScore),
      col3: naUTL(utility.addressMatchScore),
      col4: naUTL(watchlistAML.addressMatchScore),
      col5: naUTL(creditBureau.addressMatchScore),
      col6: naUTL(phone.addressMatchScore),
    },
    {
      col1: 'Phone No. Verified',
      col2: naUTL(residential.verifications.phoneNo),
      col3: naUTL(utility.verifications.phoneNo),
      col4: naUTL(watchlistAML.verifications.phoneNo),
      col5: naUTL(creditBureau.verifications.phoneNo),
      col6: naUTL(phone.verifications.phoneNo),
    },
    {
      col1: 'Email Address Verified',
      col2: naUTL(residential.verifications.emailAddress),
      col3: naUTL(utility.verifications.emailAddress),
      col4: naUTL(watchlistAML.verifications.emailAddress),
      col5: naUTL(creditBureau.verifications.emailAddress),
      col6: naUTL(phone.verifications.emailAddress),
    },
    {
      col1: 'Street No. Verified',
      col2: naUTL(''),
      col3: naUTL(''),
      col4: naUTL(''),
      col5: naUTL(creditBureau.verifications.addressElement1),
      col6: naUTL(''),
    },
    {
      col1: 'Street Verified',
      col2: naUTL(residential.verifications.addressElement1),
      col3: naUTL(utility.verifications.addressElement1),
      col4: naUTL(watchlistAML.verifications.addressElement1),
      col5: naUTL(creditBureau.verifications.addressElement2),
      col6: naUTL(phone.verifications.addressElement1),
    },
    {
      col1: 'City Verified',
      col2: naUTL(residential.verifications.addressElement3),
      col3: naUTL(utility.verifications.addressElement3),
      col4: naUTL(watchlistAML.verifications.addressElement3),
      col5: naUTL(creditBureau.verifications.addressElement3),
      col6: naUTL(phone.verifications.addressElement3),
    },
    {
      col1: 'State Verified',
      col2: naUTL(residential.verifications.addressElement4),
      col3: naUTL(utility.verifications.addressElement4),
      col4: naUTL(watchlistAML.verifications.addressElement4),
      col5: naUTL(''),
      col6: naUTL(phone.verifications.addressElement4),
    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(residential.verifications.addressElement5),
      col3: naUTL(utility.verifications.addressElement5),
      col4: naUTL(watchlistAML.verifications.addressElement5),
      col5: naUTL(creditBureau.verifications.addressElement5),
      col6: naUTL(phone.verifications.addressElement5),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
