// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataID = (inputData, dataSources) => {
  let nikVerificationData;
  let npwpVerificationData;
  let watchlistAMLData;

  /* ============================================
            INDONESIA RESIDENT IDENTITY CARD
   ============================================ */
  if (dataSources.includes('Indonesia Resident Identity Card')) {
    loggerUTL('WHITELISTING INDONESIA IDENTITY CARD...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'nik',
      'streetName',
      'village',
      'district',
      'city',
      'indonesiaResidentIdentityCardConsentObtained',
      'reportingReference',
    ]);

    nikVerificationData = { ...whitelisted };
  }

  if (dataSources.includes('Indonesia Tax Registration')) {
    loggerUTL('WHITELISTING INDONESIA TAX REGISTRATION...');
    npwpVerificationData = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'nik',
      'npwp',
      'reportingReference',
    ]);
  }

  /* ============================================
            WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WAHTLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...nikVerificationData,
    ...npwpVerificationData,
    ...watchlistAMLData,
  };
};

export default whitelistedInputDataID;
