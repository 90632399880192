import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import CheckBoxGroup from '../../../../../components/common/checkBoxGroup/CheckBoxGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

const TermsAndConditionsAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleToggleCheckbox,
}) => {
  const {
    payrollConsentObtained,
    superannuationConsentObtained,
    birthCertificateConsentObtained,
    centerlinkCardConsentObtained,
    driverLicenceConsentObtained,
    medicareCardConsentObtained,
    passportConsentObtained,
    visaConsentObtained,
    residentialLookupConsentObtained,
    visaEntitlementConsentObtained,
    creditBureauConsentObtained,
    reportingReference,
    errors,
  } = formState;

  const consentChecker = selectedDataSources.some((r) =>
    [
      'Australia Passport',
      'Australia Birth Certificate',
      'Australia Centrelink Card',
      'Australia Driver Licence',
      'Australia Medicare Card',
      'Australia Visa',
      'Australia Visa Entitlement',
    ].includes(r)
  );

  const consentCheckerAus = selectedDataSources.some((r) =>
    [
      'Australia Credit Bureau',
      'Australia Superannuation',
      'Australia Payroll',
    ].includes(r)
  );

  const consentParagraph = () => {
    if (consentChecker && !consentCheckerAus) {
      return <p className="form-overview__terms-label">
        {transUTL('translateFormOverview.consentParagraph')}
      </p>
    } else if (consentCheckerAus && !consentChecker) {
      return <p className="form-overview__terms-label">
        {transUTL('translateFormOverview.consentParagraphAus')}
      </p>
    } else if (consentChecker && consentCheckerAus) {
      return (<>
        <p className="form-overview__terms-label">
          {transUTL('translateFormOverview.consentParagraph')}
        </p>
        <p className="form-overview__terms-label">
          {transUTL('translateFormOverview.consentParagraphAus')}
        </p>
      </>)
    }
  }

  return (
    <div className="form-overview__terms">
      {consentParagraph()}

      {selectedDataSources.includes('Australian Residential') && (
        <p className="form-overview__terms-label">
          {transUTL('translateFormOverview.digitalIDSafeguardMessage')}
        </p>
      )}

      {(consentChecker || consentCheckerAus) && (
        <CheckBoxGroup
          key="consent"
          checkboxBool={
            payrollConsentObtained ||
            superannuationConsentObtained ||
            birthCertificateConsentObtained ||
            centerlinkCardConsentObtained ||
            driverLicenceConsentObtained ||
            medicareCardConsentObtained ||
            passportConsentObtained ||
            visaConsentObtained ||
            residentialLookupConsentObtained ||
            visaEntitlementConsentObtained ||
            creditBureauConsentObtained
          }
          label={transUTL('translateFormOverview.consentParagraph2')}
          handleToggleCheckbox={() => handleToggleCheckbox(false)}
        />
      )}

      <TextFieldGroup
        key="reportingReference"
        id="reportingReference"
        type="text"
        name="reportingReference"
        dataName="reportingReferenceAU"
        labelClass="form-group__label"
        label={transUTL('translateFormOverview.inputLabels.reportingReference')}
        value={reportingReference}
        placeholder={transUTL(
          'translatePlaceholder.inputAU.reportingReference'
        )}
        placeholderTrigger={isEmptyVAL(reportingReference) ? 'input-empty' : ''}
        error={errors.reportingReference}
        errorTrigger={errors.reportingReference ? 'input-error' : ''}
        handleOnChange={handleOnChange}
        handleOnBlur={handleOnBlur}
        handleEnterKeyTrigger={handleEnterKeyTrigger}
      />
      <span />
    </div>
  );
};

TermsAndConditionsAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default TermsAndConditionsAustralia;
