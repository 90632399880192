import {
  SET_USAGE_REPORT_DATA,
  SET_USAGE_REPORT_SELECTED_DATE,
  SET_USAGE_REPORT_SELECTED_CLIENT,
} from '../../actions/types';

const initialState = {
  selectedDate: '',
  type: '',
  selectedClient: '',
  chartData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USAGE_REPORT_DATA:
      return {
        ...state,
        chartData: action.payload,
      };
    case SET_USAGE_REPORT_SELECTED_DATE:
      return {
        ...state,
        type: action.payload.type,
        selectedDate: action.payload.date,
      };
    case SET_USAGE_REPORT_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    default:
      return state;
  }
}
