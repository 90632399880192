const translateDataSources = {
  /* ============================================
                  WATCHLIST AML
   ============================================ */
  watchlistaml: "Global Watchlist AML",

  /* ============================================
                  CHINA
   ============================================ */
  chinabankcard: "China Bank Card",
  chinaidverification: "China ID Verification",
  chinaphone: "China Phone",
  chinaphonealt: "China Phone Alt",
  chinapassportverification: "China Passport Verification",
  chinadriverlicense: "China Driver License",

  /* ============================================
                  AUSTRALIA
   ============================================ */
  australiavisa: "Australia Visa",
  australiamedicarecard: "Australia Medicare Card",
  australiabirthcertificate: "Australia Birth Certificate",
  australiacentrelinkcard: "Australia Centrelink Card",
  australiadriverlicence: "Australia Driver Licence",
  australiapassport: "Australia Passport",
  australiapropertyownership: "Australia Property Ownership",
  australiaresidential: "Australia Residential",
  australiaresidentiallookup: "Australia Residential Lookup",
  australiavisaentitlement: "Australia Visa Entitlement",
  australiacreditbureau: "Australia Credit Bureau",
  australiacitizens: "Australia Citizens",
  australiapayroll: "Australia Payroll",
  australiasuperannuation: "Australia Superannuation",
  australiadeathcheck: "Australia Death Check",

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  newzealandcompaniesoffice: "New Zealand Companies Office",
  newzealandcompanyverification: "New Zealand Company Verification",
  newzealanddiabirth: "New Zealand DIA Birth",
  newzealanddiacitizenship: "New Zealand DIA Citizenship",
  newzealanddiapassport: "New Zealand DIA Passport",
  newzealanddriverlicence: "New Zealand Driver Licence",
  newzealandlinzproperty: "New Zealand LINZ Property",
  newzealandresidential: "New Zealand Residential",
  newzealandyellowpages: "New Zealand Yellow Pages",
  newzealandcreditbureau: "New Zealand Credit Bureau",

  /* ============================================
                  PHILIPPINES
   ============================================ */
  philippinesresidential: "Philippines Residential",
  philippinescreditbureau: "Philippines Credit Bureau",

  /* ============================================
                  SLOVAKIA
   ============================================ */
  slovakiaresidential: "Slovakia Residential",

  /* ============================================
                  BELGIUM
   ============================================ */
  belgiumresidential: "Belgium Residential",

  /* ============================================
                  MALAYSIA
   ============================================ */
  malaysiacreditbureau: "Malaysia Credit Bureau",
  malaysianationalid: "Malaysia National ID",

  /* ============================================
                  INDONESIA
   ============================================ */
  indonesiaresidentidentitycard: "Indonesia Resident Identity Card",
  indonesiataxregistration: "Indonesia Tax Registration",

  /* ============================================
                  INDIA
   ============================================ */
  indiapan: "India PAN",
  indiaepic: "India EPIC",
  indiapassport: "India Passport",
  indiadriverslicence: "India Drivers Licence",
  indiaaadhaar: "India Aadhaar",

  /* ============================================
                  VIETNAM
   ============================================ */
  vietnamconsumerdata: "Vietnam Consumer Data ",
  vietnamnationalid: "Vietnam National ID",
  vietnamnationalidocr: "Vietnam National ID OCR",
  vietnamsocialsecurity: "Vietnam Social Security",

  /* ============================================
                  SINGAPORE
   ============================================ */
  singaporecreditbureau: "Singapore Credit Bureau",
  singaporeresidential: "Singapore Residential",
  singaporeutility: "Singapore Utility",

  /* ============================================
                  THAILAND
   ============================================ */
  thailandnationalid: "Thailand National ID",

  /* ============================================
                  CAMBODIA
   ============================================ */
  cambodianationalid: "Cambodia National ID", // RENAMED

  /* ============================================
                  HONG KONG
   ============================================ */
  hongkongcreditbureau: "Hong Kong Credit Bureau",
  hongkongmarketing: "Hong Kong Marketing",
  hongkongresidential: "Hong Kong Residential",

  /* ============================================
                 FRANCE
  ============================================ */
  franceresidential: "France Residential",
  franceresidential2: "France Residential 2",
  franceresidential3: "France Residential 3",

  /* ============================================
                CHILE
  ============================================ */
  chilenationalid: "Chile National ID",
  chileresidential: "Chile Residential",

  /* ============================================
                CHILE
  ============================================ */
  chilenationalid: 'Chile National ID',
  chileresidential: 'Chile Residential',

  /* ============================================
                  UNITED STATES
   ============================================ */
  unitedstatesresidential: "United States Residential",
  unitedstatesphone: "United States Phone",

  /* ============================================
                  GREAT BRITAIN / UK
    ============================================ */
  unitedkingdomphone: "United Kingdom Phone",
  unitedkingdomcitizens: "United Kingdom Citizens",

  /* ============================================
                  MEXICO
   ============================================ */
  mexiconationalid: "Mexico National ID",
  mexicotaxregistration: "Mexico Tax Registration",

  /* ============================================
                  GERMANY
   ============================================ */
  germanyresidential: "Germany Residential",
  germanycreditbureau: "Germany Credit Bureau",
  germanyutility: "Germany Utility",
  germanyphone: "Germany Phone",

  /* ============================================
                  Switzerland
  ============================================= */
  switzerlandcreditbureau: "Switzerland Credit Bureau",

  /* ============================================
                  ITALY
   ============================================ */
  italyresidential: "Italy Residential",
  italytelcomobile: "Italy Telco Mobile",
  italycreditbureau: "Italy Credit Bureau",

  /* ============================================
                SPAIN
 ============================================ */
  spainresidential: "Spain Residential",
  spaintelco: "Spain Telco",

  /* ============================================
                GREECE
  ============================================ */
  greececreditbureau: "Greece Credit Bureau",

  /* ============================================
                POLAND
  ============================================ */
  polandcreditbureau: "Poland Credit Bureau",

  /* ============================================
                AUSTRIA
  ============================================ */
  austriacreditbureau: "Austria Credit Bureau",

  /* ============================================
                 NIGERIA
  ============================================ */
  nigerianationalid: "Nigeria National ID",
  nigeriabankverification: "Nigeria Bank Verification",

  /* ============================================
                Morocco
  ============================================ */
  moroccoresidential: "Morocco Residential",

  /* ============================================
                CZECH REPUBLIC
  ============================================ */
  czechrepublicresidential: "Czech Republic Residential",

  /* ============================================
                  BANGLADESH
   ============================================ */
  bangladeshnationalid: "Bangladesh National ID",

  /* ===========================================
                South Africa
  ============================================ */
  southafricanationalid: "South Africa National ID",

  /* ============================================
                 BRAZIL
  ============================================ */
  brazilnationalid: "Brazil National ID",
  braziltaxregistration: "Brazil Tax Registration",

  /* ============================================
                  ARGENTINA
   ============================================ */
  argentinanationalid: "Argentina National ID",
  argentinacitizens: "Argentina Citizens",
  argentinacreditbureau: "Argentina Credit Bureau",

  /* ============================================
               PERU
============================================ */
  perunationalid: "Peru National ID",
  peruresidential: "Peru Residential",
  perutaxregistration: "Peru Tax Registration",

  /* ============================================
                 CZECH
  ============================================ */
  czechrepublicresidential: "Czech Republic Residential",

  /* ============================================
                  KENYA
   ============================================ */
  kenyanationalid: "Kenya National ID",

  /* ============================================
                 CANADA
  ============================================ */
  canadaphone: "Canada Phone",
  canadaconsumer: "Canada Consumer",
  canadaresidential: "Canada Residential",

  /* ============================================
                 Netherlands
  ============================================ */
  netherlandsresidential: "Netherlands Residential",

  /* ============================================
                  OTHER
   ============================================ */
  notApplicable: "N/A",

  /* ============================================
                FIND IDU
  ============================================ */
  philippinessuspiciousactivity: "Philippines",

  /* ============================================
            Digital ID Safeguard
  ============================================ */
  digitalidsafeguard: "Digital ID Safeguard",
  digitalIdSafeguard: "Digital ID Safeguard",
};

export default translateDataSources;
