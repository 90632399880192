import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const ContactDetailsAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { phoneNo, email, errors } = formState;

  const inputElements = [
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Residential', 
        'Australia Residential Lookup'
      ],
      element: (
        <TextFieldGroup
          key="phoneNo"
          id="phoneNo"
          type="text"
          name="phoneNo"
          dataName="phoneNoAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.phoneNo')}
          value={phoneNo}
          placeholder={transUTL('translatePlaceholder.inputAU.phoneNo')}
          placeholderTrigger={isEmptyVAL(phoneNo) ? 'input-empty' : ''}
          error={errors.phoneNo}
          errorTrigger={errors.phoneNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens', 
        'Australia Residential'],
      element: (
        <TextFieldGroup
          key="email"
          id="email"
          type="text"
          name="email"
          dataName="emailAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.email')}
          value={email}
          placeholder={transUTL('translatePlaceholder.inputAU.email')}
          placeholderTrigger={isEmptyVAL(email) ? 'input-empty' : ''}
          error={errors.email}
          errorTrigger={errors.email ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

ContactDetailsAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default ContactDetailsAustralia;
