import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// VALIDATIONS
// import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';

// DOCUMENTS
import dzGDPR from '../../assets/documentLibrary/dz-gdpr.pdf';
import dzInformationSecurity from '../../assets/documentLibrary/dz-information-security.pdf';
import dzSupportAndMaintenance from '../../assets/documentLibrary/dz-support-and-maintenance.pdf';

const DocumentLibrary = ({ authRXS, countryRXS }) => {
  const isoDocuments = [
    {
      name: transUTL('translateDocumentLibrary.gdprCompliance'),
      updatedAt: `${transUTL(
        'translateDocumentLibrary.lastUpdated'
      )}: 21st August 2019`,
      pdf: dzGDPR,
    },
    {
      name: transUTL('translateDocumentLibrary.securityPolicy'),
      updatedAt: `${transUTL(
        'translateDocumentLibrary.lastUpdated'
      )}: 12th September 2019`,
      pdf: dzInformationSecurity,
    },
    {
      name: transUTL('translateDocumentLibrary.maintenance'),
      updatedAt: `${transUTL(
        'translateDocumentLibrary.lastUpdated'
      )}: 21st August 2019`,
      pdf: dzSupportAndMaintenance,
    },
  ];

  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2>Document Library</h2>
      </header>
      <div className="common-form__body">
        <p
          className="trans-history__filter-count"
          style={{ marginBottom: '3rem' }}
        >
          3 {transUTL('translateDocumentLibrary.documents')}
        </p>

        {isoDocuments.map((doc) => (
          <div
            key={doc.name}
            className="trans-history__transaction trans-history__border-success"
          >
            <div className="trans-history__availability">
              <span className="material-icons icon-size trans-history__icon-success">
                check_circle
              </span>
            </div>

            <div className="trans-history__body">
              <header className="trans-history__header">
                <h4>{doc.name}</h4>
                <p className="trans-history__transaction-timestamp">
                  {doc.updatedAt}
                </p>
              </header>

              <p style={{ visibility: 'hidden' }}>{doc.updatedAt}</p>
              <p style={{ visibility: 'hidden' }}>{doc.updatedAt}</p>
              {/* <p style={{ visibility: 'hidden' }}>{doc.updatedAt}</p> */}
            </div>

            <a
              className="trans-history__btn trans-history__btn-enabled trans-history__link"
              href={doc.pdf}
              download
            >
              {transUTL('translateDocumentLibrary.viewDocuments')}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

DocumentLibrary.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
});

export default connect(mapStateToProps, {})(DocumentLibrary);
