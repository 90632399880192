
import { transUTL } from "../../../utils/transUTL";
import loggerUTL from "../../../utils/loggerUTL";
import downsizeUploadedImageUTL from "../../../utils/downsizeUploadedImageUTL";

const checkUploadedImage = async (originalDataURI, fileSize) => {
  const result = {
    alertMsg: [],
    dataURI: null
  };
  const MAX_SIZE = 10000000;
  const TARGET_SIZE = 550000;
  // Formats down below are suported by most common browser, meets the <img /> requirment
  const suggestedFileFormat = [
    'jpeg', 'gif', 'png', 'svg+xml'
  ];
  const dataUri_imgType = originalDataURI.split(';')[0].split(':')[1]; // Should be 'image/xxx';
  const uploadedFileFormat = dataUri_imgType.split('/')[1]; // Should be 'xxx';
  loggerUTL(`UPLOADED FILE FORMAT IS ${uploadedFileFormat}`)

  const isSuportedFormat = suggestedFileFormat.includes(uploadedFileFormat);

  if (!isSuportedFormat) {

    loggerUTL(`FORMAT NOT SUPPORTED`)
    result.alertMsg.push(transUTL('translateAlertMsg.notSuportingUploadedFileFormat'));

    if (fileSize > MAX_SIZE) {
      loggerUTL(`UPLOADED FILE IS LARGER THAN ${MAX_SIZE}`)
      result.alertMsg.push(transUTL('translateAlertMsg.fileTooLargeToUpload'));
    }

  } else {

    if (fileSize > MAX_SIZE) {
      loggerUTL(`UPLOADED FILE IS LARGER THAN ${MAX_SIZE}`)
      result.alertMsg.push(transUTL('translateAlertMsg.fileTooLargeToUpload'));

    } else if (fileSize < TARGET_SIZE) {
      result.dataURI = originalDataURI

    } else {
      loggerUTL(`DOWNSIZING... uploaded file size ${fileSize} > ${TARGET_SIZE}`)
      const downsizedDataURI = await downsizeUploadedImageUTL(originalDataURI)
      result.dataURI = downsizedDataURI
    }
  }

  return result
}

export default checkUploadedImage;
