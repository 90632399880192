import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { getUserPIIDataV2AXN } from '../../../../actions/transactionHistory/transactionHistoryActions';

// SEARCH RESULTS
import VietnamNationalID from './results/VietnamNationalID';
import VietnamSocialSecurity from './results/VietnamSocialSecurity';
import GlobalWatchlistAML from '../../reusableSearch/GlobalWatchlistAML';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../utils/transUTL';
import whitelistedInputDataVN from '../../../formOverview/countryForms/vietnam/helpers/whitelistedInputDataVN';
import genderStringUTL from '../../../../utils/genderStringUTL';
import { dataSourcesIsCheckedUTL } from '../../../../utils/outputMasterUTL';

const VietnamResults = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  formDataRXS,
  searchVerificationRXS,
  saveFormDataAXN,
  getUserPIIDataV2AXN,
  watchlistStatus,
  handleClearResults,
  history,
}) => {
  const { vietnamFormData } = formDataRXS;
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.vietnamDataSources
  );

  const whitelistedData = whitelistedInputDataVN(
    vietnamFormData,
    selectedDataSources
  );

  const {
    vietnamConsumerDataConsentObtained,
    nationalIDConsentObtained,
    socialSecurityConsentObtained,
    city,
    ...displayData
  } = whitelistedData;

  displayData.gender = genderStringUTL(displayData);

  const displayDataFiltered = Object.keys(displayData)
    .map((val) => displayData[val])
    .filter((v) => !isEmptyVAL(v));

  // GENERATE PDF
  const generatePDF = () =>
    getUserPIIDataV2AXN(authRXS.user, searchVerificationRXS.reportingReference);

  return (
    <Fragment>
      <header className="header-secondary search-verification__input-data">
        <h4 className="header-title-secondary">
          <strong>{transUTL('translateSearchVerification.inputData')}</strong>{' '}
          {displayDataFiltered.join(' | ')}
        </h4>
      </header>

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <VietnamNationalID
          results={searchVerificationRXS.serviceResponses}
          type="Vietnam National ID"
        />
      )}
      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <VietnamSocialSecurity
          results={searchVerificationRXS.serviceResponses}
          type="Vietnam Social Security"
        />
      )}
      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <GlobalWatchlistAML
          results={searchVerificationRXS.serviceResponses}
          watchlistStatus={watchlistStatus}
          type="Watchlist AML"
        />
      )}

      {/* BUTTONS */}
      <div className="search-verification__btns">
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.push('/form-overview')}
          >
            {transUTL('translateBtn.editSearch')}
          </button>

          <button className="btn-primary" onClick={handleClearResults}>
            {transUTL('translateBtn.newSearch')}
          </button>
        </div>

        <div className="btn-container btn-under">
          <button className="btn-primary" onClick={generatePDF}>
            {transUTL('translateBtn.generatePDF')}
          </button>

          <button className="btn-primary" onClick={() => window.print(history.push('/search-verification'))}>
            {transUTL('translateBtn.printSearchResults')}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

VietnamResults.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  searchVerificationRXS: PropTypes.object.isRequired,
  getUserPIIDataV2AXN: PropTypes.func.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  watchlistStatus: PropTypes.object.isRequired,
  handleClearResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  searchVerificationRXS: state.searchVerificationRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  getUserPIIDataV2AXN,
})(withRouter(VietnamResults));
