import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// REDUX STORE
import store from './store/store';

// ROOT CONTAINER
import Root from './components/root/Root';

// GLOBAL STYLES
import './styles/App.scss';

const App = () => (
  <Provider store={store}>
    <Router>
      <Root />
    </Router>
  </Provider>
);

export default App;
