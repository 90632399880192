// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { nationalID, creditBureau, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(nationalID.status),
      col3: naUTL(creditBureau.status),
      col4: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(nationalID.safeHarbourScore),
      col3: naUTL(creditBureau.safeHarbourScore),
      col4: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(nationalID.identityVerified),
      col3: naUTL(creditBureau.identityVerified),
      col4: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'National ID Verified',
      col2: naUTL(nationalID.verifications.nationalIDNo),
      col3: naUTL(creditBureau.verifications.nationalIDNo),
      col4: naUTL(watchlistAML.verifications.nationalIDNo),
    },
    {
      col1: 'Name Verified',
      col2: naUTL(nationalID.verifications.fullName),
      col3: naUTL(creditBureau.verifications.fullName),
      col4: naUTL(watchlistAML.verifications.fullName),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(nationalID.nameMatchScore),
      col3: naUTL(creditBureau.nameMatchScore),
      col4: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(nationalID.verifications.dateOfBirth),
      col3: naUTL(creditBureau.verifications.dateOfBirth),
      col4: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(nationalID.verifications.gender),
      col3: naUTL(creditBureau.verifications.gender),
      col4: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Street Verified',
      col2: naUTL(nationalID.verifications.addressElement1),
      col3: naUTL(creditBureau.verifications.street),
      col4: naUTL(watchlistAML.verifications.street),
    },
    {
      col1: 'Suburb Verified',
      col2: naUTL(nationalID.verifications.addressElement3),
      col3: naUTL(creditBureau.verifications.city),
      col4: naUTL(watchlistAML.verifications.city),
    },
    {
      col1: 'State Verified',
      col2: naUTL(nationalID.verifications.addressElement4),
      col3: naUTL(creditBureau.verifications.state),
      col4: naUTL(watchlistAML.verifications.state),
    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(nationalID.verifications.addressElement5),
      col3: naUTL(creditBureau.verifications.postCode),
      col4: naUTL(watchlistAML.verifications.postCode),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(nationalID.addressMatchScore),
      col3: naUTL(creditBureau.addressMatchScore),
      col4: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'Error Message',
      col2: naUTL(nationalID.errorMessage),
      col3: naUTL(creditBureau.errorMessage),
      col4: naUTL(watchlistAML.errorMessage),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
