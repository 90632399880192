// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { nationalIDNumber, watchlistAML, residential, taxRegistration } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(nationalIDNumber.status),
      col3: naUTL(residential.status),
      col4: naUTL(taxRegistration.status),
      col5: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(nationalIDNumber.safeHarbourScore),
      col3: naUTL(residential.safeHarbourScore),
      col4: naUTL(taxRegistration.safeHarbourScore),
      col5: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(nationalIDNumber.identityVerified),
      col3: naUTL(residential.identityVerified),
      col4: naUTL(taxRegistration.identityVerified),
      col5: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(nationalIDNumber.verifications.firstName),
      col3: naUTL(residential.verifications.firstName),
      col4: naUTL(taxRegistration.verifications.firstName),
      col5: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(nationalIDNumber.verifications.middleName),
      col3: naUTL(''),
      col4: naUTL(taxRegistration.verifications.middleName),
      col5: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(nationalIDNumber.verifications.lastName),
      col3: naUTL(residential.verifications.lastName),
      col4: naUTL(taxRegistration.verifications.lastName),
      col5: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Date of Birth Verified',
      col2: naUTL(nationalIDNumber.verifications.dateOfBirth),
      col3: naUTL(''),
      col4: naUTL(taxRegistration.verifications.dateOfBirth),
      col5: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(''),
      col3: naUTL(residential.verifications.gender),
      col4: naUTL(''),
      col5: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Street No. Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement1),
      col3: naUTL(residential.status),
      col4: naUTL(''),
      col5: naUTL(''),
    },
    {
      col1: 'Street Name Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement3),
      col3: naUTL(''),
      col4: naUTL(''),
      col5: naUTL(''),
    },
    {
      col1: 'City Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement4),
      col3: naUTL(''),
      col4: naUTL(''),
      col5: naUTL(''),
    },
    {
      col1: 'State Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement4),   
      col3: naUTL(''),
      col4: naUTL(''),
      col5: naUTL(''),
    },
    {
      col1: 'National ID Verified',
      col2: naUTL(nationalIDNumber.verifications.nationalIDNo),
      col3: naUTL(''),
      col4: naUTL(taxRegistration.verifications.nationalIDNo),
      col5: naUTL(''),
    },
  ];

  return tableFilter(table);
}

export default jsonTableRow;