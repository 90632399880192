import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const ABNAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    ABN,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
      ],
      element: (
        <TextFieldGroup
          key="ABN"
          id="ABN"
          type="text"
          name="ABN"
          dataName="ABN_AU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.ABN')}
          value={ABN}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.ABN'
          )}
          placeholderTrigger={isEmptyVAL(ABN) ? 'input-empty' : ''}
          error={errors.ABN}
          errorTrigger={errors.ABN ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

ABNAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default ABNAustralia;
