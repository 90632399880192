const amlChecker = (dataSources, inputData) => {
  const preferenceOverrides = {}; // COLLECTS AML

  // USER CONSENT CHECK
  if (dataSources.includes('Watchlist AML')) {
    // AML COLLECTOR
    preferenceOverrides['AML Return Multiple Records'] = "true";
  }
  return { preferenceOverrides };
};

export default amlChecker;
