const consentCheckerID = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('Indonesia Resident Identity Card')) {
    // CONSENT COLLECTOR
    consentObtained['Indonesia Resident Identity Card'] = true;

    if (!inputData.indonesiaResidentIdentityCardConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerID;
