import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';

import {
  retrieveOrganisationUsersAXN,
  toggleLockAccountAXN,
  resetPasswordAXN,
} from '../../../actions/admin/adminActions';

import Modal from '../../../components/modal/Modal';
import AccountLockForm from '../forms/AccountLockForm';
import PasswordResetForm from '../forms/PasswordResetForm';
import { getMuiTheme } from './tableSettings/tableTheme';
import CustomFooter from './tableSettings/CustomFooter';
import UserTableToolbar from './tableSettings/UserTableToolbar';

import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

const UserTable = ({
  adminRXS,
  retrieveOrganisationUsersAXN,
  toggleLockAccountAXN,
  resetPasswordAXN,
  match,
  location,
  history,
}) => {
  const [users, setUsers] = useState([...adminRXS.users]);
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [modalState, setModalState] = useState({
    accountLockModalShow: false,
    passwordResetModalShow: false,
  });

  useEffect(() => {
    if (!isEmptyVAL(match.params)) {
      const orgId = match.params.id;
      retrieveOrganisationUsersAXN(orgId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUsers([...adminRXS.users]);
  }, [adminRXS.users]);

  useEffect(() => {
    setTableData(
      users.map((user) => {
        return [
          isEmptyVAL(user.userId) ? 'N/A' : user.userId,
          isEmptyVAL(user.username) ? 'N/A' : user.username,
          isEmptyVAL(user.email) ? 'N/A' : user.email,
          isEmptyVAL(user.country) ? 'N/A' : user.country,
          user.isLocked,
        ];
      })
    );
  }, [users]);

  const closeModal = () => {
    setModalState({
      accountLockModalShow: false,
      passwordResetModalShow: false,
    });
  };

  const openModal = (modalType, selectedUser) => {
    setSelectedUser(selectedUser);

    if (modalType === 'account') {
      setModalState({
        ...modalState,
        accountLockModalShow: true,
      });
    } else if (modalType === 'password') {
      setModalState({
        ...modalState,
        passwordResetModalShow: true,
      });
    }
  };

  const organisationName = location.state.orgName;

  // Table Helper Functions

  const retrieveUserInformation = (userId) => {
    return users.find((user) => user.userId === userId);
  };

  const generateLockElement = (lockColor, lockIcon, userId) => {
    const user = retrieveUserInformation(userId);

    return (
      <div style={{ textAlign: 'center' }}>
        <span
          style={{ color: lockColor }}
          className="material-icons"
          onClick={() => openModal('account', user)}
        >
          {lockIcon}
        </span>
      </div>
    );
  };

  const generatePasswordResetButton = (userId) => {
    const user = retrieveUserInformation(userId);
    return (
      <div className={{ textAlign: 'center' }}>
        <button
          className="table__action-button"
          onClick={() => openModal('password', user)}
        >
          Reset
        </button>
      </div>
    );
  };

  // Table Configuration

  const columns = [
    'ID',
    'Username',
    'Email',
    'Country',
    {
      name: 'Locked',
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value, metaData) => {
          const userId = metaData.rowData[0];
          let color = '#59c16f';
          let icon = 'lock_open';

          if (value) {
            color = 'red';
            icon = 'lock';
          }

          return generateLockElement(color, icon, userId);
        },
      },
    },
    {
      name: 'Reset Password',
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (_, metaData) => {
          const userId = metaData.rowData[0];
          return generatePasswordResetButton(userId);
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    responsive: 'vertical',
    fixedHeader: false,
    customToolbar: () => <UserTableToolbar history={history} />,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          history={history}
        />
      );
    },
  };

  return (
    <Fragment>
      <div className="table">
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={organisationName + ' Users'}
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </div>
      {modalState.accountLockModalShow ? (
        <Modal>
          <AccountLockForm
            lockAccountAXN={toggleLockAccountAXN}
            closeModal={closeModal}
            user={selectedUser}
          />
        </Modal>
      ) : null}
      {modalState.passwordResetModalShow ? (
        <Modal>
          <PasswordResetForm
            resetPasswordAXN={resetPasswordAXN}
            closeModal={closeModal}
            user={selectedUser}
          />
        </Modal>
      ) : null}
    </Fragment>
  );
};

UserTable.propTypes = {
  adminRXS: PropTypes.object.isRequired,
  retrieveOrganisationUsersAXN: PropTypes.func.isRequired,
  toggleLockAccountAXN: PropTypes.func.isRequired,
  resetPasswordAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  adminRXS: state.adminRXS,
  authRXS: state.authRXS,
});

export default connect(mapStateToProps, {
  retrieveOrganisationUsersAXN,
  toggleLockAccountAXN,
  resetPasswordAXN,
})(UserTable);
