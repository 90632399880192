const translateBiometrics = {
  facialMatchingResults: '面部匹配结果',
  bioAuth: '生物认证',
  docScan: '文件扫描',
  manualSelection: '手动选择图片...',
  takePic: '捕获',
  orTakePhoto: '或拍照',
  identityCard: '身份证',
  identityCardFront: '身份证正面',
  identityCardBack: '身份证背面',
  noFiles: '没有选择',
  uploaded: '已上传！',
  selectOrClick: '选择/单击您要比较的图像',
  documentCaptured: '捕获的文件',
  detailsCaptured: '捕获的详细信息',
  documentAndSelfie: '文件和自拍照匹配',
  Passed: '已通过',
  failed: '失败的',
  docUpload: '文件上载',
  selfieUpload: '自拍照上传',
  // For New Document Scan Component
  takeAPicture: '拍照',
  uploadAFile: '上传一个文件',
  uploadedDocuments: '上载文件',
  uploadMessage1: '请上传文档的正面（最大大小：9MB）',
  uploadMessage2: '请上传文档的背面（最大大小：9MB）',
  frontSide: '前面',
  backSide: '背面',
  backSide2: '背面（不需要',
  memorySizeError: '档案大小上限为2MB',
  noFileError: '必须上传文档的正面'
};

export default translateBiometrics;
