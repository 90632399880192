import React from "react";
import PropTypes from "prop-types";

// INPUT
import TextFieldGroup from "../../../../../components/common/textFieldGroup/TextFieldGroup";
import SelectListGroup from "../../../../../components/common/selectListGroup/SelectListGroup";

// VALIDATIONS
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";

// UTILITIES
import { outputMasterUTL } from "../../../../../utils/outputMasterUTL";
import { transUTL } from "../../../../../utils/transUTL";
import sortAlphaUTL from "../../../../../utils/sortAlphaUTL";

const PersonalInformationNewZealand = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleOcrFileUpload,
  handleOcrImage,
}) => {
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    gender,
    errors,

    // OCR
    fileNames,
  } = formState;

  // Dynamic style preparation
  const uploadDocImage = handleOcrImage.isDocScanned
    ? {
        color: "rgba(0, 0, 0, 0.1)",
        border: " 0.1rem solid rgba(0, 0, 0, 0.1)",
      }
    : { color: "#59c16f", border: " 0.1rem solid #59c16f" };
  const uploadSelfieImage = handleOcrImage.isBioAuth
    ? {
        color: "rgba(0, 0, 0, 0.1)",
        border: " 0.1rem solid rgba(0, 0, 0, 0.1)",
      }
    : { color: "#59c16f", border: " 0.1rem solid #59c16f" };

  const genderOptions = [
    { label: transUTL("translateDropdown.select"), value: "" },
    { label: transUTL("translateDropdown.gender.female"), value: "Female" },
    { label: transUTL("translateDropdown.gender.male"), value: "Male" },
    {
      label: transUTL("translateDropdown.gender.wontSpecify"),
      value: "X",
    },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <TextFieldGroup
          key="firstName"
          id="firstName"
          type="text"
          name="firstName"
          dataName="firstNameNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.firstName")}
          value={firstName}
          placeholder={transUTL("translatePlaceholder.inputNZ.firstName")}
          placeholderTrigger={isEmptyVAL(firstName) ? "input-empty" : ""}
          error={errors.firstName}
          errorTrigger={errors.firstName ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <TextFieldGroup
          key="middleName"
          id="middleName"
          type="text"
          name="middleName"
          dataName="middleNameNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.middleName")}
          value={middleName}
          placeholder={transUTL("translatePlaceholder.inputNZ.middleName")}
          placeholderTrigger={isEmptyVAL(middleName) ? "input-empty" : ""}
          error={errors.middleName}
          errorTrigger={errors.middleName ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <TextFieldGroup
          key="lastName"
          id="lastName"
          type="text"
          name="lastName"
          dataName="lastNameNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.lastName")}
          value={lastName}
          placeholder={transUTL("translatePlaceholder.inputNZ.lastName")}
          placeholderTrigger={isEmptyVAL(lastName) ? "input-empty" : ""}
          error={errors.lastName}
          errorTrigger={errors.lastName ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <TextFieldGroup
          key="dateOfBirth"
          id="dateOfBirth"
          type="date"
          name="dateOfBirth"
          dataName="dateOfBirthNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.dateOfBirth")}
          value={dateOfBirth}
          placeholder={transUTL("translatePlaceholder.inputNZ.dateOfBirth")}
          placeholderTrigger={isEmptyVAL(dateOfBirth) ? "input-empty" : ""}
          max="9999-12-31"
          error={errors.dateOfBirth}
          errorTrigger={errors.dateOfBirth ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <SelectListGroup
          key="gender"
          id="gender"
          name="gender"
          dataName="genderNZ"
          labelClass="form-group__label"
          label={transUTL("translateFormOverview.inputLabels.gender")}
          value={gender}
          placeholderTrigger={isEmptyVAL(gender) ? "input-empty" : ""}
          options={genderOptions}
          error={errors.gender}
          errorTrigger={errors.gender ? "input-error" : ""}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },

    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <div key="document" className="ocr__form-btn">
          <p>{transUTL("translateBiometrics.docUpload")}</p>
          <div className="fileUpload ocr-btn" style={uploadDocImage}>
            <span>
              {!isEmptyVAL(fileNames.file1)
                ? `${fileNames.file1} ${transUTL(
                    "translateBiometrics.uploaded"
                  )}`
                : `${transUTL("translateBiometrics.noFiles")}`}
            </span>
            {handleOcrImage.isDocScanned ? (
              <input className="file-input-disable" />
            ) : (
              <input
                type="file"
                className="file-input"
                name="documentIMG"
                onChange={handleOcrFileUpload}
                onBlur={handleOnBlur}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <div key="selfie" className="ocr__form-btn">
          <p>{transUTL("translateBiometrics.selfieUpload")}</p>
          <div className="fileUpload ocr-btn" style={uploadSelfieImage}>
            <span>
              {!isEmptyVAL(fileNames.file2)
                ? `${fileNames.file2} ${transUTL(
                    "translateBiometrics.uploaded"
                  )}`
                : `${transUTL("translateBiometrics.noFiles")}`}
            </span>
            {handleOcrImage.isBioAuth ? (
              <input className="file-input-disable" />
            ) : (
              <input
                type="file"
                className="file-input"
                name="selfieIMG"
                onChange={handleOcrFileUpload}
                onBlur={handleOnBlur}
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

PersonalInformationNewZealand.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleOcrFileUpload: PropTypes.func.isRequired,
  handleOcrImage: PropTypes.object.isRequired,
};

export default PersonalInformationNewZealand;