const translateNavigation = {
  individualIdentity: '个人身份',
  verification: '验证',
  profile: '轮廓',
  dashboard: '仪表板',
  history: '历史',
  dataAnalytics: '数据分析',
  documentLibrary: '文件库',
  aboutUs: '关于我们',
  logout: '登出',
  costEstimation: '费用估算',
  admin: '管理员',
};

export default translateNavigation;