const dataSourcesUS = {
  residential: {
    title: 'Kediaman Amerika Syarikat',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    nationalIDNo: 'ID kebangsaan',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    addressElement1: 'Nama jalan',
    addressElement3: 'Pinggir bandar',
    addressElement4: 'Nyatakan',
    addressElement5: 'Poskod',
    streetNo: 'Nombor jalan',
    email: 'Alamat emel',
    phoneNo: 'Nombor telefon',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    dateOfBirth: 'Tarikh lahir',
    streetAddress: 'Nama jalan',
    socialSecurityNo: 'Nombor keselamatan sosial',
  },
  phone: {
    title: 'Telefon Amerika Syarikat',
    addressElement1: 'Nama jalan',
    addressElement2: 'Nombor jalan',
    addressElement3: 'Pinggir bandar',
    addressElement4: 'Nyatakan',
    addressElement5: 'Poskod',
    phoneNo: 'Nombor telefon',
  }
};

export default dataSourcesUS;
