import React from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const NationalIDDataPhilippines = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { nationalIDNo, nationalIDType, errors } = formState;

  const nationalIDTypeOptions = [
    {
      label: transUTL('translateDropdown.select'),
      value: '',
    },
    {
      label: transUTL('translateDropdown.idCardTypePH.socialSecuritySystem'),
      value: 'SSS',
    },
    {
      label: transUTL('translateDropdown.idCardTypePH.taxpayerIdentificationNumber'),
      value: 'TIN'
    },
    {
      label: transUTL('translateDropdown.idCardTypePH.governmentServiceInsuranceSystem'),
      value: 'GSIS'
    }
  ];

  const inputElements = [
    {
      belongsTo: ['Philippines Credit Bureau'],
      element: (
        <TextFieldGroup
          key="nationalIDNo"
          id="nationalIDNo"
          type="text"
          name="nationalIDNo"
          dataName="nationalIDNoPH"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.nationalIDNo')}
          value={nationalIDNo}
          placeholder={transUTL('translatePlaceholder.inputPH.idNumber')}
          error={errors.nationalIDNo}
          errorTrigger={isEmptyVAL(errors.nationalIDNo) ? 'input-empty' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Philippines Credit Bureau'],
      element: (
        <SelectListGroup
          key="nationalIDType"
          id="nationalIDType"
          type="text"
          name="nationalIDType"
          dataName="nationalIDTypePH"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.idcardNoType')}
          value={nationalIDType}
          options={nationalIDTypeOptions}
          placeholder={transUTL('translatePlaceholder.inputPH.idNumberType')}
          placeholderTrigger={isEmptyVAL(nationalIDType) ? 'input-empty' : ''}
          error={errors.nationalIDType}
          errorTrigger={errors.nationalIDType ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    }
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

NationalIDDataPhilippines.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default NationalIDDataPhilippines;
