import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import CheckBoxGroup from '../../../../../components/common/checkBoxGroup/CheckBoxGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

const TermsAndConditionsIndonesia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleToggleCheckbox,
}) => {
  const {
    vietnamConsumerDataConsentObtained,
    nationalIDConsentObtained,
    socialSecurityConsentObtained,
    reportingReference,
    errors,
  } = formState;

  return (
    <div className="form-overview__terms">
      <p className="form-overview__terms-label">
        {transUTL('translateFormOverview.consentParagraph')}
      </p>

      <CheckBoxGroup
        key="consent"
        checkboxBool={
          vietnamConsumerDataConsentObtained ||
          nationalIDConsentObtained ||
          socialSecurityConsentObtained
        }
        label={transUTL('translateFormOverview.consentParagraph2')}
        handleToggleCheckbox={() => handleToggleCheckbox(false)}
      />

      <TextFieldGroup
        key="reportingReference"
        id="reportingReference"
        type="text"
        name="reportingReference"
        dataName="reportingReferenceID"
        labelClass="form-group__label"
        label={transUTL('translateFormOverview.inputLabels.reportingReference')}
        value={reportingReference}
        placeholder={transUTL(
          'translatePlaceholder.inputIN.reportingReference'
        )}
        placeholderTrigger={isEmptyVAL(reportingReference) ? 'input-empty' : ''}
        error={errors.reportingReference}
        errorTrigger={errors.reportingReference ? 'input-error' : ''}
        handleOnChange={handleOnChange}
        handleOnBlur={handleOnBlur}
        handleEnterKeyTrigger={handleEnterKeyTrigger}
      />
      <span />
    </div>
  );
};

TermsAndConditionsIndonesia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default TermsAndConditionsIndonesia;
