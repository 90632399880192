import jsPDF from 'jspdf';
import 'jspdf-autotable';

// CONFIG
import { docStyles } from '../config/config';

import timeStamp from '../helpers/timeStamp';
import primeHeader from '../helpers/primeHeader';
import generatePDF from '../helpers/generatePDF';
import entryDetails from '../helpers/entryDetails';
import biometricsTrigger from '../helpers/biometricsTrigger';
import ocrImages from '../helpers/ocrImages';
import drawGreenLine from '../helpers/drawGreenLine';
import pepsAndSanctions from '../helpers/pepsAndSanctions';
import separator from '../helpers/separator';
import footer from '../helpers/footer';

// COUNTRY UI SPECIFIC
import consumerDataSupplied from './ui/consumerDataSupplied';
import iduVerificationResults from './ui/iduVerificationResults';
import dataSourceVerificationResults from './ui/dataSourceVerificationResults';
import returnedData from './ui/returnedData';
import errorMessages from './ui/errorMessages';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

const verificationResultPDF = async (data, info, ocrImgs, browserWindow) => {
  const doc = new jsPDF({
    compress: true, // COMPRESSES PDF FILE SIZE
    orientation: 'p',
    unit: 'px',
    format: 'a4',
  });

  // GET COUNTRY NAME e.g. 'New Zealand'
  if (isEmptyVAL(data)) data = {}
  const {Response, Request, CreatedAt} = data

  const response = isEmptyVAL(Response) ? {} : Response
  const request = isEmptyVAL(Request) ? {} : Request
  const countryName = isEmptyVAL(response.countryCode) ? '' : response.countryCode
  const serviceResponsesObj = isEmptyVAL(response.serviceResponses) ? {} : response.serviceResponses
  
  /* ============================================
                  HEADER
  ============================================ */
  timeStamp(doc, data, docStyles); // DISPLAY TIME STAMP
  primeHeader(doc, countryName); // DISPLAY PRIMARY HEADER

  /* ============================================
                  BODY 
   ============================================ */
  entryDetails(doc, data, info, docStyles);

  // OCR IMAGES
  let bioData2 = {};
  if (!isEmptyVAL(ocrImgs)) {
    const {
      isDocScanned,
      isBioAuth,
      bioData,
      selfie,
      identityDoc0,
    } = biometricsTrigger(ocrImgs);
  
    if (isDocScanned || isBioAuth) {
      await ocrImages(doc, data, info, docStyles, bioData);
    } else {
      bioData2 = { selfie, identityDoc0 };
    }
  }

  await consumerDataSupplied(doc, request, response, docStyles, bioData2);
  drawGreenLine(doc, docStyles, docStyles.separation);
  iduVerificationResults(doc, response, docStyles);
  dataSourceVerificationResults(doc, response, docStyles);
  returnedData(doc, serviceResponsesObj, docStyles);
  drawGreenLine(doc, docStyles, docStyles.separation);
  pepsAndSanctions(doc, data, info, docStyles);
  drawGreenLine(doc, docStyles, docStyles.separation);
  errorMessages(doc, serviceResponsesObj, docStyles);

  /* ============================================
                  FOOTER
  ============================================ */
  separator(doc);
  await footer(doc, data, info, docStyles, countryName);

  /* ============================================
                  GENERATE PDF
  ============================================ */
  generatePDF(doc, data, browserWindow);
};

export default verificationResultPDF;