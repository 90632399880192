const dataSourcesIN = {
  driverLicence: {
    title: 'India Drivers Licence',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    documentNumber: 'Document Number',
    name: 'Name',
    address: 'Address',
    dateOfBirth: 'Date Of Birth',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
  },
  epic: {
    title: 'India EPIC',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    documentNumber: 'Document Number',
    name: 'Name',
    gender: 'Gender',
    address: 'Address',
    dateOfBirth: 'Date Of Birth',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
  },
  pan: {
    title: 'India PAN',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    documentNumber: 'Document Number',
    name: 'Name',
    gender: 'Gender',
    address: 'Address',
    dateOfBirth: 'Date Of Birth',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
  },
  passport: {
    title: 'India Passport',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    dateOfBirth: 'Date Of Birth',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfIssue: 'Date Of Issue',
    fileNo: 'File No',
    passportNo: 'Passport No',
  },
  aadhaarNo: {
    title: 'India Aadhaar',
    searchSuccessful: 'Search Successful',
    safeHarbourScore: 'Safe Harbour Score',
    identityVerified: 'Verified',
    documentNumber: 'Document Number',
    name: 'Name',
    gender: 'Gender',
    address: 'Address',
    dateOfBirth: 'Date Of Birth',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
    aadhaarNo: 'Aadhaar Number',
  },
};

export default dataSourcesIN;
