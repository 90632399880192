import React from 'react';
import PropTypes from 'prop-types';

const HamburgerSVG = ({
  classContainer,
  colorClass,
  handleToggleSideNavigation,
}) => (
  <div className={classContainer} onClick={handleToggleSideNavigation}>
    <span className={`material-icons icon-size ${colorClass}`}>menu</span>
  </div>
);

HamburgerSVG.defaultProps = {
  colorClass: '',
  classContainer: '',
};

HamburgerSVG.propTypes = {
  colorClass: PropTypes.string,
  classContainer: PropTypes.string,
  handleToggleSideNavigation: PropTypes.func.isRequired,
};

export default HamburgerSVG;
