import {
  SET_IDENTITY_DOC,
  SET_MULTI_DOCS,
  SET_SELFIE,
  DELETE_MULTI_DOCS,
  FACIAL_RECOGNITION_DATA,
  BIO_BOOLEAN_TRIGGERS,
  REST_BIOMETRICS,
  SAVE_OCR_RESPONSE_DATA
} from '../../actions/types';

import isEmptyVAL from '../../validations/checks/isEmptyVAL';

const initialState = {
  ocrResponseData: {},
  multiDocs: [],
  identityDocument: {
    frontSide: undefined,
    backSide: undefined
  },
  selfieURI: '',
  selfieMach: false,
  faceMatchResults: {},
  bioTriggers: {
    isDocScanned: false,
    isBioAuth: false,
    isDocUploaded: false,
    isSelfieUploaded: false,
    // isLiveness: false, ADD WHEN FEATURE IS AVAILABLE
    // isDocAuth: false,  ADD WHEN FEATURE IS AVAILABLE
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_OCR_RESPONSE_DATA:
      return {
        ...state,
        ocrResponseData: {...action.payload}
      };
    case SET_MULTI_DOCS:
      return {
        ...state,
        multiDocs: [...action.payload],
      };
    case SET_IDENTITY_DOC:
      return {
        ...state,
        identityDocument: { ...action.payload },
      };
    case SET_SELFIE:
      return {
        ...state,
        selfieURI: action.payload,
      };
    case DELETE_MULTI_DOCS:
      const filteredDocs = state.multiDocs.filter((img, index) => {
        return index !== action.payload;
      });

      // REMOVES DELETE DOC FROM SESSION STORAGE
      if (!isEmptyVAL(sessionStorage.identityDocumentScans)) {
        sessionStorage.setItem(
          'identityDocumentScans',
          JSON.stringify(filteredDocs)
        );
      }

      return {
        ...state,
        multiDocs: filteredDocs,
      };
    case FACIAL_RECOGNITION_DATA:
      return {
        ...state,
        faceMatchResults: action.payload,
      };
    case BIO_BOOLEAN_TRIGGERS:
      return {
        ...state,
        bioTriggers: action.payload,
      };
    case REST_BIOMETRICS:
      return initialState;
    default:
      return state;
  }
}
