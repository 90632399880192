const initialStateMX = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // IDENTITY DETAIL
  curp: '',
  rfc: '',

  // REFERENCE
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStateMX;
