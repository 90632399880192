import * as Sentry from '@sentry/browser';

// REDUX STORE
import store from '../../store/store';

// TYPES
import { ERROR_FEEDBACK } from '../types';

// CONSTANTS
import { CROSS_BORDER_VERIFICATION } from "../../constants/crossBorderDataSources";

//HELPERS
import modifiedAuth from '../../actions/auth/helpers/modifiedAuth/modifiedAuth';

// REDUX ACTIONS
import { setAlertAXN } from '../alert/alertActions';

// FORM ERROR FEEDBACK
import feedbackAR from './feedback/feedbackAR'; // ARGENTINA
import feedbackAU from './feedback/feedbackAU'; // AUSTRALIA
import feedbackBD from './feedback/feedbackBD'; // BANGLADESH
import feedbackBR from './feedback/feedbackBR'; // Brazil
import feedbackSK from './feedback/feedbackSK'; // SLOVAKIA
import feedbackBE from './feedback/feedbackBE'; // BELGIUM
import feedbackCL from './feedback/feedbackCL'; // CHILE
import feedbackKH from './feedback/feedbackKH'; // CAMBODIA
import feedbackCN from './feedback/feedbackCN'; // CHINA
import feedbackFindIdu from './feedback/feedbackFindIdu'; // Find IDU
import feedbackHK from './feedback/feedbackHK'; // HONG KONG
import feedbackIN from './feedback/feedbackIN'; // INDIA
import feedbackID from './feedback/feedbackID'; // INDONESIA
import feedbackMY from './feedback/feedbackMY'; // MALAYSIA
import feedbackNZ from './feedback/feedbackNZ'; // NEW ZEALAND
import feedbackPH from './feedback/feedbackPH'; // PHILIPPINES
import feedbackSG from './feedback/feedbackSG'; // SINGAPORE
import feedbackTH from './feedback/feedbackTH'; // THAILAND
import feedbackVN from './feedback/feedbackVN'; // VIETNAM
import feedbackNG from './feedback/feedbackNG'; // NIGERIA
import feedbackMX from './feedback/feedbackMX'; // MEXICO
import feedbackUS from './feedback/feedbackUS'; // UNITED STATES
import feedbackGB from './feedback/feedbackGB'; // GREAT BRITAIN
import feedbackZA from './feedback/feedbackZA'; // South Africa
import feedbackMA from './feedback/feedbackMA'; // MOROCCO
import feedbackCH from './feedback/feedbackCH'; // SWITZERLAND
import feedbackFR from './feedback/feedbackFR'; // FRANCE
import feedbackDE from './feedback/feedbackDE'; // GERMANY
import feedbackGR from './feedback/feedbackGR'; // GREECE
import feedbackPL from './feedback/feedbackPL'; // POLAND
import feedbackAT from './feedback/feedbackAT'; // AUSTRIA
import feedbackPE from './feedback/feedbackPE'; // PERU
import feedbackIT from './feedback/feedbackIT'; // ITALY
import feedbackKE from './feedback/feedbackKE'; // KENYA
import feedbackCA from './feedback/feedbackCA'; // CANADA
import feedbackCrossBorder from "./feedback/feedbackCrossBorder";
import feedbackCZ from "./feedback/feedbackCZ"; // CZECH REPUBLIC

// TODO: DATA SOURCE ERROR FEEDBACK


// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';
import loggerUTL from '../../utils/loggerUTL';

// TODO: ADD THIS FEATURE ONCE WE HAVE INTEGRATED TO DZ (IN PROGRESS...)
export const loginFeedbackError = (err) => (dispatch) => {
  // logger('LOGIN IN ERROR...');

  // KMS DOES NOT RETURN USABLE API VALIDATION
  if (!isEmptyVAL(err)) {
    dispatch({
      type: ERROR_FEEDBACK,
      payload: {
        loginErr:
          "Hmm, that's not the right username and password combination. Please try again",
      },
    });
  }
};

// NOTED: THESE INPUT ERRORS FROM THE API BE IN ENGLISH...
export const inputFormFeedbackErrorController = (feedback) => (dispatch) => {
  loggerUTL('FEEDBACK ERROR...', feedback);
  const { validationErrors, searchStatus } = feedback;

  // PROVIDE ALERT MESSAGE
  if (!isEmptyVAL(searchStatus)) {
    dispatch(setAlertAXN(searchStatus, 'error'));
  }

  // PROVIDE FEEDBACK ERROR FOR FORM INPUT FIELDS
  if (!isEmptyVAL(validationErrors)) {
    const country = store.getState().countryRXS
    try {
      switch (country) {
        case 'argentina':
          feedbackAR(validationErrors, dispatch);
          break;
        case 'australia':
          feedbackAU(validationErrors, dispatch);
          break;
        case 'bangladesh':
          feedbackBD(validationErrors, dispatch);
          break;
        case 'brazil':
          feedbackBR(validationErrors, dispatch);
          break;
        case 'slovakia':
          feedbackSK(validationErrors, dispatch);
          break;
        case 'belgium':
          feedbackBE(validationErrors, dispatch);
          break;
        case 'chile':
          feedbackCL(validationErrors, dispatch);
          break;
        case 'cambodia':
          feedbackKH(validationErrors, dispatch);
          break;
        case 'china':
          feedbackCN(validationErrors, dispatch);
          break;
        case 'findIDU':
          feedbackFindIdu(validationErrors, dispatch);
          break;
        case 'hongkong':
          feedbackHK(validationErrors, dispatch);
          break;
        case 'india':
          feedbackIN(validationErrors, dispatch);
          break;
        case 'indonesia':
          feedbackID(validationErrors, dispatch);
          break;
        case 'malaysia':
          feedbackMY(validationErrors, dispatch);
          break;
        case 'newzealand':
          feedbackNZ(validationErrors, dispatch);
          break;
        case 'philippines':
          feedbackPH(validationErrors, dispatch);
          break;
        case 'singapore':
          feedbackSG(validationErrors, dispatch);
          break;
        case 'thailand':
          feedbackTH(validationErrors, dispatch);
          break;
        case 'vietnam':
          feedbackVN(validationErrors, dispatch);
          break;
        case 'nigeria':
          feedbackNG(validationErrors, dispatch);
          break;
        case 'mexico':
          feedbackMX(validationErrors, dispatch);
          break;
        case 'unitedstates':
          feedbackUS(validationErrors, dispatch);
          break;
        case 'unitedkingdom':
          feedbackGB(validationErrors, dispatch);
          break;
        case 'southafrica':
          feedbackZA(validationErrors, dispatch);
          break;
        case 'morocco':
          feedbackMA(validationErrors, dispatch);
          break;
        case 'switzerland':
          feedbackCH(validationErrors, dispatch);
          break;
        case 'france':
          feedbackFR(validationErrors, dispatch);
          break;
        case 'germany':
          feedbackDE(validationErrors, dispatch);
          break;
        case 'greece':
          feedbackGR(validationErrors, dispatch);
          break;
        case 'poland':
          feedbackPL(validationErrors, dispatch);
          break;
        case 'austria':
          feedbackAT(validationErrors, dispatch);
          break;
        case 'peru':
          feedbackPE(validationErrors, dispatch);
          break;
        case 'italy':
          feedbackIT(validationErrors, dispatch);
          break;
        case 'kenya':
          feedbackKE(validationErrors, dispatch);
          break;
        case 'canada':
          feedbackCA(validationErrors, dispatch);
          break;
        case CROSS_BORDER_VERIFICATION:
          feedbackCrossBorder(validationErrors, dispatch);
          break;
        case 'czechrepublic':
          feedbackCZ(validationErrors, dispatch);
          break;
        default:
          break;
      }
    } catch (err) {
      Sentry.captureException(
        'FAILED TO PARES VALIDATION ERRORS...',
        JSON.stringify
      )(feedback);
    }

  }
};
