const consentCheckerPH = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  if (dataSources.includes('Philippines Credit Bureau')) {
    // CONSENT COLLECTOR
    consentObtained['Philippines Credit Bureau'] = true;

    if (!inputData.creditBureauConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('Philippines Residential')) {
    // CONSENT COLLECTOR
    consentObtained['Philippines Residential'] = true;

    if (!inputData.residentialConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerPH;
