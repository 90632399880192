import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { getProdDataThisMonthAXN } from '../../actions/dataAnalytics/dataAnalyticsAction';

// LOGO
import logoSVG from '../../assets/logo/idu-logo.svg';
import centrixLogoBlack from '../../assets/centrix/Centrix_POS_No Strap_Blk.jpg';

// COMPONENTS
import Tile from '../../components/tile/Tile';
import InteractiveMap from '../../components/interactiveMap/InteractiveMap';
import BarChart from '../../components/visualizations/BarChart';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import windowTopUTL from '../../utils/windowTopUTL';
import { centrixENV } from '../../api/init';

import { FIND_IDU_ROUTES } from "../../constants/findIDU";
import { CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE } from "../../constants/crossBorderDataSources";

const Dashboard = ({
  authRXS,
  dataAnalyticsRXS,
  getProdDataThisMonthAXN,
  history,
}) => {
  const { user } = authRXS;

  useEffect(() => {
    windowTopUTL();
    if (user.isAdmin && user.name.toLowerCase() !== 'temp_user_placeholder') {
      // TODO: MIGHT ONLY WANT THIS TO SHOW IN TEST ENVIRONMENT ONLY
      getProdDataThisMonthAXN();
    }
    // eslint-disable-next-line
  }, []);

  let eToro = null;
  let eToroTwo = null;

  if (
    user.name === 'eToro_WebProd' ||
    user.name === 'Mike_Doran_dz' ||
    user.isAdmin
  ) {
    eToro = (
      <Tile
        title={transUTL('translateDashboard.transactions')}
        ui={
          <h3 className="dashboard__tile-header">
            {transUTL('translateDashboard.eToro')}
          </h3>
        }
        to="/eToro-transactions"
        history={history}
      />
    );
  }
  if (
    user.name === 'eToro_WebProd' ||
    user.name === 'Mike_Doran_dz' ||
    user.isAdmin
  ) {
    eToroTwo = (
      <Tile
        title={transUTL('translateDashboard.transactions')}
        ui={
          <h3 className="dashboard__tile-header">
            {transUTL('translateDashboard.eToroTwo')}
          </h3>
        }
        to="/eToro-usage"
        history={history}
      />
    );
  }

  return (
    <div className="dashboard">
      <h2 className="dashboard__intro">
        {transUTL('translateDashboard.hello')} {user.username}
      </h2>
      <div className="dashboard__msg">
        <p title="Coming soon">
          {transUTL('translateDashboard.iduMsg')}{' '}
          {user.isAdmin && (
            <span
              className="dashboard__read-more"
            // onClick={() => history.push('/about-us')}
            >
              {transUTL('translateDashboard.readMore')}
            </span>
          )}
        </p>

        {centrixENV && (
          <img
            className="dashboard__centrix-logo"
            src={centrixLogoBlack}
            alt="Centrix Logo"
          />
        )}
      </div>

      <div className="dashboard__tiles">
        <Tile
          title={transUTL('translateDashboard.identityVerification')}
          ui={
            <figure>
              <img src={logoSVG} alt="Business Logo" style={{width: "8rem"}}/>
            </figure>
          }
          to="/country-selection"
          history={history}
        />

        <Tile
          title={transUTL('translateDashboard.searchForIndividuals')}
          ui={
            <h3 className="dashboard__tile-header">
              {transUTL('translateDashboard.findIdU')}
              <img src={logoSVG} alt="Business Logo" style={{width: "4.5rem"}}/>
            </h3>
          }
          to={FIND_IDU_ROUTES.FIND_IDU_SELECTION}
          history={history}
        />

        <Tile
          title={transUTL('translateDashboard.identityVerification')}
          ui={
            <h3 className="dashboard__tile-header">
              {transUTL('translateDashboard.multiService')}
            </h3>
          }
          to={CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE}
          history={history}
        />

        <Tile
          title={transUTL('translateDashboard.pep')}
          ui={
            <h3 className="dashboard__tile-header">
              {transUTL('translateDashboard.watchlist')}
            </h3>
          }
          myClass={user.watchlist ? "" : "dashboard__disabled"}
          to={user.watchlist ? "/watchlist-aml" : ""}
          history={history}
        />

        <Tile
          title={transUTL('translateDashboard.pdfHistory')}
          ui={
            <h3 className="dashboard__tile-header">
              {transUTL('translateDashboard.history')}
            </h3>
          }
          to="/transaction-history"
          history={history}
        />

        <Tile
          title={transUTL('translateDashboard.globalCoverage')}
          ui={
            <div>
              <InteractiveMap />

              <div className="map__legends">
                <p className="map__subscribed">
                  {transUTL('translateDashboard.subscribedCountryData')}
                </p>
                <p className="map__no-subscription">
                  {transUTL('translateDashboard.noSubscription')}
                </p>
                <p className="map__coming-soon">
                  {transUTL('translateDashboard.comingSoon')}
                </p>
              </div>
            </div>
          }
          myClass="dashboard__desktop dashboard__tile-no-cursor"
          history={history}
        />
        {user.isAdmin && (
          <Tile
            title={transUTL('translateDashboard.dataAnalytics')}
            ui={<BarChart dataAnalytics={dataAnalyticsRXS} />}
            myClass="dashboard__desktop dashboard__tile-no-cursor"
            history={history}
          />
        )}
        {/* || TODO: THIS SERVICE WAS RUSHED DUE TO DEADLINE... CONSIDER REWORKING THIS SERVICE */}
        {/* {user.username === 'Mike_Doran_dz'
          (user.isAdmin && (
            <Tile
              title={transUTL('translateDashboard.identityVerification')}
              ui={
                <h3 className="dashboard__tile-header">
                  {transUTL('translateDashboard.multiService')}
                </h3>
              }
              to="/multi-service-selection"
              history={history}
            />
          ))} */}

        {user.isAdmin && (
          <Tile
            title={transUTL('translateDashboard.usageReport')}
            ui={
              <h3 className="dashboard__tile-header">
                {transUTL('translateDashboard.data')}
              </h3>
            }
            to={`/date-selection/:${user.name.toLowerCase()}`}
            history={history}
          />
        )}
        {user.isAdmin && (
          <Tile
            title={transUTL('translateDashboard.costEstimation')}
            ui={
              <h3 className="dashboard__tile-header">
                {transUTL('translateDashboard.cost')}
              </h3>
            }
            to={`/cost-estimation/country-selection/:${user.name.toLowerCase()}`}
            history={history}
          />
        )}
        {eToro}
        {eToroTwo}

        {user.isAdmin && (
          <Tile
            title={transUTL('translateDashboard.mobileVerification')}
            ui={
              <h3 className="dashboard__tile-header">
                {transUTL('translateDashboard.mobile')}
              </h3>
            }
            // to="/mobile-verification"
            myClass="dashboard__disabled"
            history={history}
          />
        )}
        {user.isAdmin && (
          <Tile
            title={transUTL('translateDashboard.digitalID')}
            ui={
              <h3 className="dashboard__tile-header">
                {transUTL('translateDashboard.digitalID')}
              </h3>
            }
            to="/digital-id-safeguard"
            // myClass="dashboard__disabled"
            history={history}
          />
        )}

        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
        <div className="dashboard__tile dashboard__tile-no-cursor"></div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  authRXS: PropTypes.object.isRequired,
  dataAnalyticsRXS: PropTypes.object.isRequired,
  getProdDataThisMonthAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  dataAnalyticsRXS: state.dataAnalyticsRXS,
});

export default connect(mapStateToProps, { getProdDataThisMonthAXN })(Dashboard);
