const initialStateSG = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // IDENTITY DETAILS
  nationalIDNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  block: '',
  streetName: '',
  postCode: '',
  city: '',

  // CONTACT DETAILS
  emailAddress: '',
  phoneNo: '',

  // TERMS AND CONDITION
  creditBureauConsentObtained: false,

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStateSG;
