const translateTransactionHistory = {
  dataSource: 'Sumber data:',
  reportingRef: 'Rujukan Pelaporan:',
  clientRef: 'Ruj Pelanggan:',
  generatePDF: 'Jana PDF',
  search: 'Cari',
  searches: 'Carian',
  generationAvailable: 'Penjanaan PDF tersedia',
  generationUnavailable: 'Penjanaan PDF hanya dapat dihasilkan dalam 24 jam',
  notApplicable: 'T / A',
  unavailable: 'Tidak ada',
  next: 'Seterusnya',
  back: 'Kembali',
  searchTransactionHistory: 'Sejarah Transaksi Carian',
  filter: 'Cari',
  new: 'Susun mengikut yang terbaru',
  old: 'Susun mengikut tertua',
  to: 'Untuk',
  searchByDate: 'Cari mengikut Tarikh',
  today: 'Hari ini',
  singleDay: 'Satu Hari',
  dateRange: 'Julat Tarikh',
  showDetails: 'Tunjukkan Butiran',
  processingTime: 'Masa Pemprosesan: ',
  organisation : 'Organisasi: ',
  userIp: 'Ip Pengguna: ',
  username: 'Nama pengguna: ',
  DOBYear: 'Tahun Kelahiran: ',
  status: 'Status: ',
  transactionDetials: 'Butiran Transaksi',
  RequestData: 'Minta Data',
  ResponseData: 'Data Respons',
  requiredDateStart: 'Sila sediakan TARIKH MULA',
  requiredDateEnd: 'Sila sediakan TARIKH TAMAT',
  requiredDate: 'Sila sediakan DATE',
  requiredDateOrRef: 'Sila berikan TARIKH ATAU NOMBOR RUJUKAN',
  noFutureDate: 'Tidak dapat memilih tarikh akan datang',
  startDateLaterThanEndDate: 'TARIKH MULA sepatutnya lebih awal daripada TARIKH TAMAT'
};

export default translateTransactionHistory;
