import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const chinaVAL = (data, dataSources) => {
  const inputErrors = {};

  /* ============================================
              CHINA DRIVER LICENSE
   ============================================ */
   if (dataSources.includes('China Driver License')) {
    loggerUTL('VALIDATING CHINA DRIVER LICENSE ...');
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.idCardNo = !isEmptyVAL(data.idCardNo) ? data.idCardNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.idCardNo)) {
      inputErrors.idCardNo = transUTL(
        'translateFormOverview.formErrors.idCardNo'
      );
    }
  }

  /* ============================================
              CHINA PASSPORT VERIFICATION
   ============================================ */
   if (dataSources.includes('China Passport Verification')) {
    loggerUTL('VALIDATING CHINA PASSPORT NNUMBER...');
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.passportNo)) {
      inputErrors.passportNo = transUTL(
        'translateFormOverview.formErrors.passportNo'
      );
    }
  }

  /* ============================================
              CHINA BANK CARD
   =========================================== */
  if (dataSources.includes('China Bank Card')) {
    loggerUTL('VALIDATING CHINA NATIONAL ID...');
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.idCardNo = !isEmptyVAL(data.idCardNo) ? data.idCardNo : '';
    data.bankCardNo = !isEmptyVAL(data.bankCardNo) ? data.bankCardNo : '';

    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.idCardNo)) {
      inputErrors.idCardNo = transUTL(
        'translateFormOverview.formErrors.idCardNo'
      );
    }
    if (isEmptyVAL(data.bankCardNo)) {
      inputErrors.bankCardNo = transUTL(
        'translateFormOverview.formErrors.bankCardNo'
      );
    }
  }

  /* ============================================
              CHINA ID VERIFICATION
   ============================================ */
  if (dataSources.includes('China ID Verification')) {
    loggerUTL('VALIDATING CHINA NATIONAL ID...');
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.idCardNo = !isEmptyVAL(data.idCardNo) ? data.idCardNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.idCardNo)) {
      inputErrors.idCardNo = transUTL(
        'translateFormOverview.formErrors.idCardNo'
      );
    }
  }

  /* ============================================
              CHINA PHONE
   ============================================ */
  if (dataSources.includes('China Phone')) {
    loggerUTL('VALIDATING CHINA PHONE...');
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.idCardNo = !isEmptyVAL(data.idCardNo) ? data.idCardNo : '';
    data.phoneNo = !isEmptyVAL(data.phoneNo) ? data.phoneNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.idCardNo)) {
      inputErrors.idCardNo = transUTL(
        'translateFormOverview.formErrors.idCardNo'
      );
    }
    if (isEmptyVAL(data.phoneNo)) {
      inputErrors.phoneNo = transUTL(
        'translateFormOverview.formErrors.phoneNo'
      );
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('VALIDATING WATCHLIST AML...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

    // ERROR FEEDBACK ACCUMULATOR

    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default chinaVAL;
