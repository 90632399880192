import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import loggerUTL from '../../../../utils/loggerUTL';

//  TODO:
// Styling is in js as we are using the dropzone
// hook to create this functionality and this is easy.
// Should probably move the below to a separate style sheet
// in the future to remain consistent with the rest of the app
// and have cleaner code.
const baseStyle = {
  flex: 1,
  height: '20rem',
  width: '20rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '2rem',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const focusedStyle = {
  borderColor: '#c9c9c9',
  backgroundColor: '#eeeeee'
}

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
  backgroundColor: '#eeeeee'
}

const rejectStyle = {
  borderColor: '#ff1744',
  backgroundColor: '#eeeeee'
};


const UploadBox = ({
  message,
  onUpload
}) => {

  const onDrop = useCallback((uploadedFiles) => {
    loggerUTL("UPLOADED FILES: \n", uploadedFiles);
    onUpload(uploadedFiles[0]);
  }, [onUpload]);

  const {
    getRootProps,
    getInputProps,
    isFileDialogActive,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFileDialogActive || isFocused ? focusedStyle : {}),
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFileDialogActive,
    isFocused,
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <h3>{message}</h3>
        <span
          className="material-icons"
          style={{ marginTop: '1rem' }}
        >
          upload
        </span>
      </div>
    </div>
  );
}

UploadBox.propTypes = {
  message: PropTypes.string,
  onUpload: PropTypes.func.isRequired
};

export default UploadBox;