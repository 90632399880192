// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const americas = (dataSources) => {
  const userDataSources = [];

  // FILTERS
  const unitedStates = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('united states')
  );
  const brazil = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('brazil')
  );
  const chile = dataSources.filter((dataSource) =>
  dataSource.toLowerCase().includes('chile')
);
  const canada = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('canada')
  );
  const mexico = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('mexico')
  );
  const peru = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('peru')
  );
  const argentina = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('argentina')
);

  // MODIFIERS
  if (!isEmptyVAL(unitedStates)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'unitedstates',
      dataSources: [...unitedStates],
      belongsTo: 'americas',
      code: { alpha2: 'US', alpha3: 'USA' },
    });
  }
  if (!isEmptyVAL(brazil)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'brazil',
      dataSources: [...brazil],
      belongsTo: 'americas',
      code: { alpha2: 'BR', alpha3: 'BRA' },
    });
  }
  if (!isEmptyVAL(chile)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'chile',
      dataSources: [...chile],
      belongsTo: 'americas',
      code: { alpha2: 'CL', alpha3: 'CHL' },
    });
  }
  if (!isEmptyVAL(canada)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'canada',
      dataSources: [...canada],
      belongsTo: 'americas',
      code: { alpha2: 'CA', alpha3: 'CAN' },
    });
  }
  if (!isEmptyVAL(mexico)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'mexico',
      dataSources: [...mexico],
      belongsTo: 'americas',
      code: { alpha2: 'MX', alpha3: 'MEX' },
    });
  }
  if (!isEmptyVAL(peru)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'peru',
      dataSources: [...peru],
      belongsTo: 'americas',
      code: { alpha2: 'PE', alpha3: 'PEX' },
    });
  }
  if (!isEmptyVAL(argentina)) {
    userDataSources.push({
      region: 'theamericas',
      country: 'argentina',
      dataSources: [...argentina],
      belongsTo: 'americas',
      code: { alpha2: 'AR', alpha3: 'ARG' },
    });
  }

  return userDataSources;
};

export default americas;
