// ACTIVE DATA SOURCES THAT ARE UP AND RUNNING IN THE BACKEND
// ADD NEW DATA SOURCES HERE WHEN ITS AVAILABLE FOR USE
export const activeDataSourcesArray = [
  // ARGENTINA
  "Argentina National ID",
  "Argentina Citizens",
  "Argentina Credit Bureau",

  // AUSTRALIA
  "Australia Visa Entitlement",
  "Australia Driver Licence",
  "Australia Passport",
  "Australia Medicare Card",
  "Australia Visa",
  "Australia Centrelink Card",
  "Australia Birth Certificate",
  "Australia Residential",
  "Australia Residential Lookup",
  "Australia Property Ownership",
  "Australia Credit Bureau",
  "Australia Citizens",
  "Australia Payroll",
  "Australia Superannuation",
  "Australia Death Check",

  // Brazil
  "Brazil National ID",
  "Brazil Tax Registration",

  // SLOVAKIA
  "Belgium Residential",

  // BELGIUM
  "Slovakia Residential",

  // CAMBODIA
  "Cambodia National ID",

  // CHILE
  "Chile National ID",
  "Chile Residential",

  // CHINA
  "China Phone",
  "China ID Verification",
  "China Bank Card",
  "China Passport Verification",
  "China Driver License",
  // 'China Phone Alt', UNAVAILABLE

  // FRANCE
  "France Residential",
  "France Residential 2",
  "France Residential 3",

  // GERMANY
  "Germany Residential",
  "Germany Credit Bureau",
  "Germany Utility",
  "Germany Phone",

  // HONK KONG
  "Hong Kong Residential",
  "Hong Kong Credit Bureau",
  // 'Hong Kong Marketing', UNAVAILABLE/ERROR

  //INDIA
  "India Drivers Licence",
  "India EPIC",
  "India PAN",
  "India Passport",
  "India Aadhaar",

  // INDONESIA
  "Indonesia Resident Identity Card",
  "Indonesia Tax Registration",

  // ITALY
  // 'Italy Residential',
  // 'Italy Telco Mobile',
  "Italy Credit Bureau",

  //Morocoo
  "Morocco Residential",

  // MALAYSIA
  "Malaysia National ID",
  "Malaysia Credit Bureau",

  // MEXICO
  "Mexico National ID",
  // 'Mexico Tax Registration',

  // NEW ZEALAND
  // 'New Zealand Credit Bureau', // NOT AVAILABLE
  "New Zealand Driver Licence",
  "New Zealand DIA Passport",
  "New Zealand DIA Citizenship",
  "New Zealand DIA Birth",
  "New Zealand Yellow Pages",
  "New Zealand LINZ Property",
  "New Zealand Companies Office",
  "New Zealand Residential",
  "New Zealand Credit Bureau",

  // NIGERIA
  "Nigeria National ID",
  "Nigeria Bank Verification",

  // South Africa
  "South Africa National ID",

  // PHILIPPINES
  "Philippines Residential",
  "Philippines Credit Bureau",

  // SINGAPORE
  "Singapore Credit Bureau",
  "Singapore Utility",
  "Singapore Residential",

  // SPAIN
  "Spain Residential",
  "Spain Telco",

  // VIETNAM
  // 'Vietnam Consumer Data', // errorMessage: "No relevant entry found"
  "Vietnam Social Security",
  "Vietnam National ID",

  // UNITED STATES
  "United States Residential",
  "United States Phone",

  // GREAT BRITAIN / UK
  "United Kingdom Phone",
  "United Kingdom Citizens",

  // SWITZERLAND
  "Switzerland Credit Bureau",

  // THAILAND
  "Thailand National ID",

  // GREECE
  "Greece Credit Bureau",

  // POLAND
  "Poland Credit Bureau",
  // AUSTRIA
  "Austria Credit Bureau",

  // BANGLADESH
  "Bangladesh National ID",

  // PERU
  "Peru National ID",
  "Peru Residential",
  "Peru Tax Registration",

  // Czech Republic
  "Czech Republic Residential",

  // KENYA
  "Kenya National ID",

  // Canada
  "Canada Phone",

  // Netherlands
  "Netherlands Residential",
];
