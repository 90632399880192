// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataAU = (inputData, dataSources) => {
  let passportData,
    birthCertificate,
    centerlinkCardData,
    driverLicenceCardData,
    medicareCardData,
    propertyOwnershipData,
    residentialData,
    residentialLookupData,
    visaData,
    visaEntitlementData,
    creditBureauData,
    ABNData,
    watchlistData,
    deathCheckData;

  /* ============================================
              AUSTRALIA DEATH CHECK
   ============================================ */
   if (dataSources.includes('Australia Death Check')) {
    if (dataSources.includes('Australia Death Check')) loggerUTL('WHITELISTING AUSTRALIA DEATH CHECK...');

    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'reportingReference',
    ]);
    deathCheckData = { ...whitelisted };
  }
  
  /* ============================================
              AUSTRALIA PAYROLL & SUPPERANNUATION
   ============================================ */
   if (dataSources.includes('Australia Payroll') || dataSources.includes('Australia Superannuation')) {
    if (dataSources.includes('Australia Payroll')) loggerUTL('WHITELISTING AUSTRALIA PAYROLL...');
    if(dataSources.includes('Australia Superannuation')) loggerUTL('WHITELISTING AUSTRALIA SUPERANNUATION...');

    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'unitNo',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'email',
      'phoneNo',
      'postCode',
      'reportingReference',
      'ABN'
    ]);
    ABNData = { ...whitelisted };
  }
  
  /* ============================================
            AUSTRALIA PASSPORT
   ============================================ */
  if (dataSources.includes('Australia Passport')) {
    loggerUTL('WHITELISTING AUSTRALIAN PASSPORT...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'passportNo',
      'passportExpiry',
      'gender',
      'reportingReference',
    ]);
    passportData = { ...whitelisted };
  }

  /* ============================================
            AUSTRALIA BIRTH CERTIFICATE
   ============================================ */
  if (dataSources.includes('Australia Birth Certificate')) {
    loggerUTL('WHITELISTING AUSTRALIAN BIRTH CERTIFICATE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'birthRegistrationState',
      'birthRegistrationNo',
      'birthCertificateNo',
      'birthRegistrationDate',
      'reportingReference',
    ]);
    birthCertificate = { ...whitelisted };
  }

  /* ============================================
            Australia Centrelink Card
   ============================================ */
  if (dataSources.includes('Australia Centrelink Card')) {
    loggerUTL('WHITELISTING AUSTRALIAN CENTERLINK CARD...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'centrelinkCardType',
      'centrelinkCardExpiry',
      'centrelinkCustomerReferenceNo',
      'reportingReference',
    ]);
    centerlinkCardData = { ...whitelisted };
  }

  /* ============================================
            AUSTRALIA DRIVER LICENCE
   ============================================ */
  if (dataSources.includes('Australia Driver Licence')) {
    loggerUTL('WHITELISTING AUSTRALIAN DRIVER LICENCE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'driversLicenceNo',
      'driversLicenceCardNo',
      'driversLicenceStateOfIssue',
      'driversLicenceExpiryDate',
      'reportingReference',
    ]);
    driverLicenceCardData = { ...whitelisted };
  }

  /* ============================================
            AUSTRALIA MEDICARE CARD
   ============================================ */
  if (dataSources.includes('Australia Medicare Card')) {
    loggerUTL('WHITELISTING AUSTRALIAN MEDICARE CARD...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'medicareCardNo',
      'medicareCardType',
      'medicareReferenceNo',
      'medicareExpiry',
      'reportingReference',
    ]);
    medicareCardData = { ...whitelisted };
  }
  /* ============================================
            AUSTRALIA PROPERTY OWNERSHIP
   ============================================ */
  if (dataSources.includes('Australia Property Ownership')) {
    loggerUTL('WHITELISTING AUSTRALIA PROPERTY OWNERSHIP...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'unitNo',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'postCode',
      'reportingReference',
    ]);
    propertyOwnershipData = { ...whitelisted };
  }

  /* ============================================
              AUSTRALIA RESIDENTIAL & CITIZENS
   ============================================ */
  if (dataSources.includes('Australia Residential') || dataSources.includes('Australia Citizens')) {
    if (dataSources.includes('Australia Residential')) {
      loggerUTL('WHITELISTING AUSTRALIA RESIDENTIAL...');
    } else {
      loggerUTL('WHITELISTING AUSTRALIA CITIZENS...');
    }
    // loggerUTL('WHITELISTING AUSTRALIA RESIDENTIAL...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'unitNo',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'email',
      'phoneNo',
      'postCode',
      'reportingReference',
    ]);
    residentialData = { ...whitelisted };
  }

  /* ============================================
              AUSTRALIA RESIDENTIAL LOOKUP
   ============================================ */
  if (dataSources.includes('Australia Residential Lookup')) {
    loggerUTL('WHITELISTING AUSTRALIA RESIDENTIAL...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'unitNo',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'phoneNo',
      'postCode',
      'reportingReference',
    ]);
    residentialLookupData = { ...whitelisted };
  }

  /* ============================================
              AUSTRALIA VISA
   ============================================ */
  if (dataSources.includes('Australia Visa')) {
    loggerUTL('WHITELISTING AUSTRALIA VISA...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'passportNo',
      'passportIssuerCountry',
      'reportingReference',
    ]);
    visaData = { ...whitelisted };
  }

  /* ============================================
              AUSTRALIA VISA ENTITLEMENT
   ============================================ */
  if (dataSources.includes('Australia Visa Entitlement')) {
    loggerUTL('WHITELISTING AUSTRALIA VISA ENTITLEMENT...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'gender',
      'passportNo',
      'passportIssuerCountry',
      'reportingReference',
    ]);
    visaEntitlementData = { ...whitelisted };
  }

  /* ============================================
             AUSTRALIA CREDIT BUREAU
  ============================================ */
  if (dataSources.includes('Australia Credit Bureau')) {
    loggerUTL('WHITELISTING AUSTRALIA CREDIT BUREAU...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'lastName',
      'middleName',
      'dateOfBirth',
      'unitNo',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'postCode',
      'reportingReference',
    ]);
    creditBureauData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'fullName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...passportData,
    ...birthCertificate,
    ...centerlinkCardData,
    ...driverLicenceCardData,
    ...medicareCardData,
    ...propertyOwnershipData,
    ...residentialData,
    ...residentialLookupData,
    ...visaData,
    ...visaEntitlementData,
    ...creditBureauData,
    ...watchlistData,
    ...ABNData,
    ...deathCheckData,
  };
};
export default whitelistedInputDataAU;
