const dataSourcesIT = {
  italyResidential: {
    title: 'Kediaman Itali',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    middleName: 'Nama Tengah Disahkan',
    lastName: 'Nama Akhir Disahkan',
    gender: 'Jantina Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    state: 'Negeri Disahkan',
    postCode: 'Poskod Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
  },
  italyTelcoMobile: {
    title: 'Itali Telco Mobile',
    searchSuccessful: 'Carian Berjaya',
    verified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    dateOfBirth: 'Tarikh Lahir Disahkan',
    firstName: 'Nama Pertama Disahkan',
    lastName: 'Nama Akhir Disahkan',
    gender: 'Jantina Disahkan',
    phoneNo: 'Nombor Telefon Disahkan',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    street: 'Jalan Disahkan',
    city: 'Bandar Disahkan',
    state: 'Negeri Disahkan',
    postCode: 'Poskod Disahkan',
  }
};

export default dataSourcesIT;
