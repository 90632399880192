const dataSourcesKH = {
  consumerData: {
    title: 'Cambodia National ID',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    voterNo: 'Voter Number',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
  },
};

export default dataSourcesKH;
