import {
  SAVE_AUSTRALIA_FORM_DATA,
  SAVE_BRAZIL_FORM_DATA,
  SAVE_CHINA_FORM_DATA,
  SAVE_MALAYSIA_FORM_DATA,
  SAVE_NEW_ZEALAND_FORM_DATA,
  SAVE_PHILIPPINES_FORM_DATA,
  SAVE_INDIA_FORM_DATA,
  SAVE_INDONESIA_FORM_DATA,
  SAVE_CAMBODIA_FORM_DATA,
  SAVE_VIETNAM_FORM_DATA,
  SAVE_NIGERIA_FORM_DATA,
  SAVE_HONG_KONG_FORM_DATA,
  SAVE_SINGAPORE_FORM_DATA,
  SAVE_UNITED_STATES_FORM_DATA,
  SAVE_MEXICO_FORM_DATA,
  SAVE_GERMANY_FORM_DATA,
  SAVE_FRANCE_FORM_DATA,
  SAVE_ITALY_FORM_DATA,
  SAVE_SPAIN_FORM_DATA,
  SAVE_MULTI_SERVICE_FORM_DATA,
  SAVE_WATCHLISTAML_FORM_DATA,
  SAVE_FIND_IDU_FORM_DATA,
  SAVE_DIGITAL_ID_SAFEGUARD_FORM_DATA,
  RESET_FORM_DATA,
  SAVE_FORM_DATA,
} from '../../actions/types';

const initialState = {
  australiaFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // CURRENT RESIDENTIAL ADDRESS
    unitNo: '',
    streetNo: '',
    streetName: '',
    streetType: '',
    suburb: '',
    state: '',
    postCode: '',

    // BIRTH CERTIFICATE
    birthRegistrationState: '',
    birthRegistrationNo: '',
    birthCertificateNo: '',
    birthRegistrationDate: '',

    // CENTERLINK
    centrelinkCardType: '',
    centrelinkCardExpiry: '',
    centrelinkCustomerReferenceNo: '',

    // CONTACT DETAILS
    phoneNo: '',
    email: '',

    // DRIVERS LICENCE
    driversLicenceNo: '',
    driversLicenceCardNo:'',
    driversLicenceStateOfIssue: '',
    driversLicenceExpiryDate: '',

    // MEDICARE
    medicareCardNo: '',
    medicareCardType: '',
    medicareReferenceNo: '',
    medicareExpiry: '',

    // PASSPORT
    passportNo: '',
    passportIssuerCountry: '',
    passportCountryAlpha3: '',
    countryCode: '',
    passportExpiry: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',

    //API results
    // displayApiResultObtained: '',
  },
  brazilFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetNo: '',
    streetName: '',
    city: '',
    province: '',
    postCode: '',

    //IDENTITY DETAILS
    nationalIDNo: '',
  },
  chinaFormData: {
    // CHINESE SEARCH
    fullName: '',
    firstName: '',
    lastName: '',

    // CHINESE SEARCH
    idCardNo: '',
    bankCardNo: '',
    phoneNo: '',
    dateOfBirth: '',
    gender: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },

  hongkongFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // IDENTITY DETAILS
    nationalIDNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    building: '',
    streetName: '',
    district: '',
    state: '',
    postCode: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },

  singaporeFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // IDENTITY DETAILS
    nationalIDNo: '',
    phoneNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    block: '',
    streetName: '',
    postCode: '',
    city: '',
  },
  unitedstatesFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // SOCIAL SECURITY
    socialSecurityNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetNo: '',
    streetName: '',
    suburb: '',
    state: '',
    postCode: '',

    // CONTACT DETAILS
    phoneNo: '',
    email: '',

    // REFERENCE
    reportingReference: '',
  },

  mexicoFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // OCR FILE NAMES
    fileNames: {
      file1: '',
      file2: '',
    },

    // IDENTITY DETAIL
    curp: '',
    rfc: '',

    // REFERENCE
    reportingReference: '',
  },

  malaysiaFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    gender: '',

    // IDENTITY DATA
    nationalIDNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetNo: '',
    streetName: '',
    city: '',
    state: '',
    postCode: '',

    //  REPORTING REFERENCE
    reportingReference: '',
  },

  philippinesFormData: {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',

    // IDENTITY
    vin: '',

    // National ID Data
    searchType: '',
    idNumberType: '',
    idNumber: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetNo: '',
    streetName: '',
    barangay: '',
    city: '',
    province: '',
    postCode: '',
    region: '',

    // CONTACT DETAILS
    phoneType: '',
    phoneNumber: '',

    // REPORTING REFERENCE
    reportingReference: '',
  },

  newzealandFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    gender: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetNo: '',
    streetName: '',
    streetType: '',
    suburb: '',
    city: '',
    postCode: '',

    // DRIVERS LICENCE
    driversLicenceNo: '',
    driversLicenceNumber: '',
    driversLicenceVersion: '',
    vehiclePlate: '',

    // Birth Certificate
    birthCertificateNo: '',
    placeOfBirth: '',
    mothersFirstName: '',
    mothersLastName: '',
    fathersFirstName: '',
    fathersLastName: '',

    // CITIZENSHIP
    countryOfBirth: '',
    citizenshipCertificateNo: '',

    // PASSPORT
    passportNo: '',
    passportExpiry: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },

  indiaFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // CURRENT RESIDENTIAL ADDRESS
    city: '',
    streetNo: '',
    streetName: '',
    state: '',
    suburb: '',
    postCode: '',

    // ACCOUNT DETAILS
    pan: '',
    epic: '',
    aadhaarNo: '',

    // DRIVERS LICENCE
    driversLicenceNo: '',

    // PASSPORT
    passportNo: '',
    fileNo: '',
    dateOfIssue: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },

  indonesiaFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // NIK
    nik: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetName: '',
    village: '',
    district: '',
    city: '',
    // alamat: '',
    // kelurahan: '',
    // kecamatan: '',
    // kabupaten: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },
  cambodiaFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    lastName: '',
    dateOfBirth: '',

    // VOTER NUMBER
    voterNo: '',

    // DRIVERS LICENCE
    driversLicenceNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    commune: '',
    district: '',
    province: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },
  vietnamFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // IDENTITY NUMBER
    idCardNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetNo: '',
    streetName: '',
    ward: '',
    district: '',
    city: '',
    address: '',
    addressElement1: '',

    // CONTACT DETAILS
    emailAddress: '',
    phoneNo: '',

    // REPORTING REFERENCE
    reportingReference: '',
  },
  nigeriaFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // IDENTITY NUMBER
    nationalIdNo: '',
    bankVerificationNo: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetAddress: '',
    town: '',
    lga: '',
    state: '',

    // CONTACT DETAILS
    emailAddress: '',
    phoneNo: '',

    // REPORTING REFERENCE
    reportingReference: '',
  },
  franceFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetAddress: '',
    city: '',
    postCode: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },
  germanyFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetAddress: '',
    city: '',
    state: '',
    postCode: '',

    // CONTACT DETAILS
    phoneNo: '',

    // REPORTING REFERENCE NUMBER
    reportingReference: '',
  },
  italyFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetAddress: '',
    city: '',
    state: '',
    postCode: '',

    // CONTACT DETAILS
    phoneNo: '',

    // REFERENCE
    reportingReference: '',
  },
  spainFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',

    // CURRENT RESIDENTIAL ADDRESS
    streetAddress: '',
    city: '',
    state: '',
    postCode: '',

    // CONTACT DETAILS
    phoneNo: '',

    // REFERENCE
    reportingReference: '',
  },
  watchlistAMLFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',

    // REPORTING REFERENCE
    reportingReference: '',
  },
  multiServiceFormData: {
    // PERSONAL INFORMATION
    fullName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    gender: '',

    // CHINESE SEARCH
    idCardNo: '',
    bankCardNo: '',
    phoneNo: '',

    // OCR FILE NAMES
    fileNames: {
      file1: '',
      file2: '',
    },

    // CURRENT RESIDENTIAL ADDRESS
    unitNo: '',
    streetNo: '',
    streetName: '',
    streetType: '',
    suburb: '',
    city: '',
    postCode: '',

    // DRIVERS LICENCE
    driversLicenceNo: '',
    driversLicenceVersion: '',
    vehiclePlate: '',
    driversLicenceState: '',

    // BIRTH CERTIFICATE
    birthCertificateNo: '',
    placeOfBirth: '',
    mothersFirstName: '',
    mothersLastName: '',
    fathersFirstName: '',
    fathersLastName: '',

    // MEDICARE
    medicareCardNo: '',
    medicareCardType: '',
    medicareIndividualRefNo: '',
    medicareExpiryDate: '',

    // CITIZENSHIP
    countryOfBirth: '',
    citizenshipCertificateNo: '',

    // PASSPORT
    passportNo: '',
    passportExpiryDate: '',

    // PASSPORT
    passportCountry: '',
    medicareExpiryDateToggle: false,
    countryCode: '',

    // CONTACT DETAILS
    homePhoneNo: '',
    mobilePhoneNo: '',
    emailAddress: '',

    // REPORTING REFERENCE
    reportingReference: '',
  },
  findIDUFormData: {
    // PERSONAL INFORMATION
    firstName: '',
    lastName: '',
    middleName: '',

    // IDENTITY DETAILS
    emailID: '',
    hitTarget: '',
    yearOfBirth: '',

    // RESIDENTIAL ADDRESS
    barangay: '',
    city: '',
    province: '',
    postCode: '',
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_AUSTRALIA_FORM_DATA:
      return {
        ...state,
        australiaFormData: action.payload,
      };
    case SAVE_BRAZIL_FORM_DATA:
      return {
        ...state,
        brazilFormData: action.payload,
      };
    case SAVE_CHINA_FORM_DATA:
      return {
        ...state,
        chinaFormData: action.payload,
      };
    case SAVE_MALAYSIA_FORM_DATA:
      return {
        ...state,
        malaysiaFormData: action.payload,
      };
    case SAVE_NEW_ZEALAND_FORM_DATA:
      return {
        ...state,
        newzealandFormData: action.payload,
      };
    case SAVE_PHILIPPINES_FORM_DATA:
      return {
        ...state,
        philippinesFormData: action.payload,
      };
    case SAVE_INDIA_FORM_DATA:
      return {
        ...state,
        indiaFormData: action.payload,
      };
    case SAVE_INDONESIA_FORM_DATA:
      return {
        ...state,
        indonesiaFormData: action.payload,
      };
    case SAVE_CAMBODIA_FORM_DATA:
      return {
        ...state,
        cambodiaFormData: action.payload,
      };
    case SAVE_VIETNAM_FORM_DATA:
      return {
        ...state,
        vietnamFormData: action.payload,
      };
    case SAVE_NIGERIA_FORM_DATA:
      return {
        ...state,
        nigeriaFormData: action.payload,
      };
    case SAVE_HONG_KONG_FORM_DATA:
      return {
        ...state,
        hongkongFormData: action.payload,
      };
    case SAVE_SINGAPORE_FORM_DATA:
      return {
        ...state,
        singaporeFormData: action.payload,
      };
    case SAVE_UNITED_STATES_FORM_DATA:
      return {
        ...state,
        unitedstatesFormData: action.payload,
      };
    case SAVE_MEXICO_FORM_DATA:
      return {
        ...state,
        mexicoFormData: action.payload,
      };
    case SAVE_GERMANY_FORM_DATA:
      return {
        ...state,
        germanyFormData: action.payload,
      }
    case SAVE_FRANCE_FORM_DATA:
      return {
        ...state,
        franceFormData: action.payload,
      }
    case SAVE_ITALY_FORM_DATA:
      return {
        ...state,
        italyFormData: action.payload,
      }
    case SAVE_SPAIN_FORM_DATA:
      return {
        ...state,
        spainFormData: action.payload,
      }
    case SAVE_MULTI_SERVICE_FORM_DATA:
      return {
        ...state,
        multiServiceFormData: action.payload,
      };
    case SAVE_WATCHLISTAML_FORM_DATA:
      return {
        ...state,
        watchlistAMLFormData: action.payload,
      };
    case SAVE_FIND_IDU_FORM_DATA:
      return {
        ...state,
        findIDUFormData: action.payload,
      }
    case SAVE_DIGITAL_ID_SAFEGUARD_FORM_DATA:
      return {
        ...state,
        digitalIdSafeguardFormData: action.payload,
      }
    case SAVE_FORM_DATA:
      const newState = {
        ...state,
        [action.payload.country]: action.payload.formData,
      };
      return newState;
    case RESET_FORM_DATA:
      return initialState;
    default:
      return state;
  }
}
