// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const africa = (dataSources) => {
  const userDataSources = [];

  // FILTERS
  const nigeria = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('nigeria')
  );
  const southAfrica = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('south africa')
  );

  const morocco = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('morocco')
  );

  const kenya = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('kenya')
);

  // MODIFIERS
  if (!isEmptyVAL(nigeria)) {
    userDataSources.push({
      region: 'africa',
      country: 'nigeria',
      dataSources: [...nigeria],
      belongsTo: 'africa',
      code: { alpha2: 'NG', alpha3: 'NGA' },
    });
  }
  if (!isEmptyVAL(southAfrica)) {
    userDataSources.push({
      region: 'africa',
      country: 'southAfrica',
      dataSources: [...southAfrica],
      belongsTo: 'africa',
      code: { alpha2: 'ZA', alpha3: 'ZAF' },
    });
  }

  if (!isEmptyVAL(morocco)) {
    userDataSources.push({
      region: 'africa',
      country: 'morocco',
      dataSources: [...morocco],
      belongsTo: 'africa',
      code: { alpha2: 'MA', alpha3: 'MAR' },
    })
  }

  if (!isEmptyVAL(kenya)) {
    userDataSources.push({
      region: 'africa',
      country: 'kenya',
      dataSources: [...kenya],
      belongsTo: 'africa',
      code: { alpha2: 'KE', alpha3: 'KEN' },
    })
  }

  return userDataSources;
};

export default africa;
