import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILS
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';
import { transUTL } from '../../../utils/transUTL';
import { setAlertAXN } from '../../alert/alertActions';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import store from '../../../store/store';


// LOAD NEW ZEALAND API ERRORS TO INPUT FORM
const feedbackNZ = (errors, dispatch) => {
  const selectedService = store.getState().dataSourcesRXS.newzealandDataSources //Obtain the data of the selected service name 
  try {
    loggerUTL('ERRORS...', errors);
    const inputError = {};

    //Check whether the error msg for resi is called 
    let isRequestError = false;
    if (errors[0].substr(0,7) === "Request") {
      isRequestError = true;
    }

    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'dateOfBirth')) {
      inputError.dateOfBirth = `Birth date ${
        errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
      }`;
    }

    /* ================================================
      RESIDENTIAL ADDRESS (RESIDENTIAL, COMPANIES, LINZ)
    =================================================== */
    if (isRequestError) { 
      dispatch(setAlertAXN(transUTL('translateAlertMsg.addressInput'), 'error'));
    }

    if (findErrorUTL(errors, 'for addressElement5') && (selectedService["New Zealand Residential"] || selectedService["New Zealand Yellow Pages"])) {
      inputError.postCode = `Post code should be ${
        errors.find((item) => item.includes('addressElement5')).split(':')[1]
      }`;
    }

    /* ============================================
                PASSPORT
       ============================================ */
    if (findErrorUTL(errors, 'passportNo')) {
      inputError.passportNo = `Passport number ${
        errors.find((item) => item.includes('passportNo')).split(':')[1]
      }`;
    }

    /* ============================================
            CONTACT DETAILS
    ============================================ */
    if (findErrorUTL(errors, 'phoneNo')) {
      inputError.phoneNo = `Phone number ${
        errors.find((item) => item.includes('phoneNo')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'landlineNo')) {
      inputError.landlineNo = `Landline number ${
        errors.find((item) => item.includes('landlineNo')).split(':')[1]
      }`;
    }

    /* ============================================
            DRIVER LICENSE
    ============================================ */
    if (findErrorUTL(errors, 'driversLicenceVersion')) {
      inputError.driversLicenceVersion = `Drivers Licence Version should be ${
        errors.find((item) => item.includes('driversLicenceVersion')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'driversLicenceNo')) {
      inputError.driversLicenceNo = `Drivers Licence number is ${
        errors.find((item) => item.includes('driversLicenceNo')).split(':')[1]
      }`;
    }

    dispatch({
      type: ERROR_FEEDBACK,
      payload: inputError,
    });
  } catch (err) {
    loggerUTL(err);
    Sentry.captureException(
      'FAILED TO PARES NEW ZEALAND VALIDATION ERRORS...',
      JSON.stringify
    )(err);
  }
};

export default feedbackNZ;
