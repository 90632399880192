// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { watchlistAML, residential } = dataSources;

  const table = [
    {
      col1: 'Status',
      col3: naUTL(watchlistAML.status),
      col4: naUTL(residential.status)
    },
    {
      col1: 'Safe Harbour Score',
      col3: naUTL(watchlistAML.safeHarbourScore),
      col4: naUTL(residential.safeHarbourScore)
    },
    {
      col1: 'Identity Verified',
      col3: naUTL(watchlistAML.identityVerified),
      col4: naUTL(residential.identityVerified)
    },
    {
      col1: 'First Name Verified',
      col3: naUTL(watchlistAML.verifications.firstName),
      col4: naUTL(residential.verifications.firstName)
    },
    {
      col1: 'Last Name Verified',
      col3: naUTL(watchlistAML.verifications.lastName),
      col4: naUTL(residential.verifications.lastName)
    },
    {
      col1: 'Street No Verified',
      col3: naUTL(watchlistAML.verifications.addressElement2),
      col4: naUTL(residential.verifications.addressElement2)
    },
    {
      col1: 'Street Name Verified',
      col3: naUTL(watchlistAML.verifications.addressElement1),
      col4: naUTL(residential.verifications.addressElement1)
    },
    {
      col1: 'City Verified',
      col3: naUTL(watchlistAML.verifications.addressElement3),
      col4: naUTL(residential.verifications.addressElement3)
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;

