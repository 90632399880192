import logoBase64 from '../helpers/logoB64';

// DISPLAY PDF LOGO
const dzLogo = (doc) => doc.addImage(logoBase64, 'PNG', 320, 47, 90, 25);

// DISPLAY PRIMARY HEADER
const primeHeader = (doc, country) => {
  const columns = [{ header: '', dataKey: 'col1' }];

  const title = 'Data Zoo Consumer IDU Verification';

  const rows = [{ col1: country }, { col1: title }];

  doc.autoTable({
    columns: columns, 
    body: rows,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = [255, 255, 255];
      cell.styles.fontSize = 17;
      cell.styles.cellPadding = 0;
    },
    showHead: 'never',
    startY: 45,
  });

  dzLogo(doc);
};

export default primeHeader;
