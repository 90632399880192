const stateAU = {
  queensland: 'Queensland',
  newSouthWales: 'New South Wales',
  southAustralia: 'South Australia',
  tasmania: 'Tasmania',
  victoria: 'Victoria',
  westernAustralia: 'Western Australia',
  australianCapitalTerritory: 'Australian Capital Territory',
  northernTerritory: 'Northern Territory',
  pensionConcession: 'Pension Concession',
  healthCare: 'Health Care',
  seniorHealth: 'Senior Health',
};

export default stateAU;
