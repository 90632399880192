const initialStateBR = {
	//PERSONAL INFORMATION
	firstName: '',
	middleName: '',
	lastName: '',
	dateOfBirth: '',

	// CURRENT RESIDENTIAL ADDRESS
	unitNo: '',
	streetName: '',
	locality: '',
	district: '',
	postCode: '',

	//IDENTITY DETAILS
	nationalIDNo: '',

	// REFERENCE NUMBER
	reportingReference: '',

	// TAX REGISTRATION
	cpf: '',

	// ERROR HANDLING
	errors: {},
}

export default initialStateBR;