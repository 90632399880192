const isValidDateVAL = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // INVALID FORMAT

  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NAN VALUE, INVALID DATE

  const year = dateString.split('-')[0];
  if (year < 1900) return false;

  return d.toISOString().slice(0, 10) === dateString;
};

export default isValidDateVAL;
