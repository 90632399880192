import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressVietnam = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    streetNo,
    streetName,
    ward,
    district,
    city,
    address,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['Vietnam National ID', 'Vietnam Social Security', 'Vietnam Consumer Data'],
      element: (
        <TextFieldGroup
          key="streetNo"
          id="streetNo"
          type="text"
          name="streetNo"
          dataName="streetNoVN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetNo')}
          value={streetNo}
          placeholder={transUTL('translatePlaceholder.inputVN.streetNo')}
          placeholderTrigger={isEmptyVAL(streetNo) ? 'input-empty' : ''}
          error={errors.streetNo}
          errorTrigger={errors.streetNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Vietnam National ID', 'Vietnam Social Security', 'Vietnam Consumer Data'],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameVN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetName')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputVN.streetName')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Vietnam National ID', 'Vietnam Social Security', 'Vietnam Consumer Data'],
      element: (
        <TextFieldGroup
          key="ward"
          id="ward"
          type="text"
          name="ward"
          dataName="wardVN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.ward')}
          value={ward}
          placeholder={transUTL('translatePlaceholder.inputVN.ward')}
          placeholderTrigger={isEmptyVAL(ward) ? 'input-empty' : ''}
          error={errors.ward}
          errorTrigger={errors.ward ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Vietnam National ID', 'Vietnam Social Security', 'Vietnam Consumer Data'],
      element: (
        <TextFieldGroup
          key="district"
          id="district"
          type="text"
          name="district"
          dataName="districtVN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.district')}
          value={district}
          placeholder={transUTL('translatePlaceholder.inputVN.district')}
          placeholderTrigger={isEmptyVAL(district) ? 'input-empty' : ''}
          error={errors.district}
          errorTrigger={errors.district ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Vietnam Consumer Data',
        'Vietnam National ID',
        'Vietnam Social Security', 'Vietnam Consumer Data',
      ],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityVN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.city')}
          value={city}
          placeholder={transUTL('translatePlaceholder.inputVN.city')}
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {!isEmptyVAL(address) ? (
        <TextFieldGroup
          key="address"
          id="address"
          type="text"
          name="address"
          dataName="addressVN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.addressOCRVN')}
          value={address}
          placeholder={transUTL('translatePlaceholder.inputVN.address')}
          placeholderTrigger={isEmptyVAL(address) ? 'input-empty' : ''}
          disabled={true}
          error={errors.address}
          errorTrigger={errors.address ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ) : (
        <Fragment>
          {outputMasterUTL(selectedDataSources, inputElements)}
          <span />
        </Fragment>
      )}
    </div>
  );
};

CurrentResidentialAddressVietnam.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressVietnam;
