// HELPERS
import title from "../../helpers/title";
import { setCellHeight, setImagePos } from "../../helpers/didParseCell";

// IDENTITY DATA
import personalInformation from "../../identityData/personalInformation";
import identityDetails from "../../identityData/identityDetails";
import currentResidentialAddress from "../../identityData/currentResidentialAddress";
import reference from "../../identityData/reference";

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

// UTILS
import getImageDimensionsFromBase64UTL from "../../../../utils/getImageDimensionsFromBase64UTL__";
import noSpaceUTL from "../../../../utils/noSpaceUTL";
import { specialServiceNames } from "../displayingStringsForVerificationDataFields/verificationResultsDataFields";

const consumerDataSupplied = async (
  doc,
  request,
  response,
  docStyles,
  bioImgs
) => {
  title(
    doc,
    docStyles,
    "Consumer Data Supplied",
    docStyles.headers.secondary,
    docStyles.separation
  );

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  // MAP IDENTITY DATA & CONSENT STRING
  // ========================================================

  const { service, consentObtained, ...identityData } = request; // service name is lowercase in 'servive'. e.g. 'new zealand'
  const { countryCode, serviceResponses } = response; // service name in 'serviceResponse' starts with upper case. e.g. 'New Zealand'
  const services = isEmptyVAL(service) ? [] : service;
  let country = "";
  if (countryCode) country = noSpaceUTL(countryCode).toLowerCase();

  // Add empty check as not all services include this below
  const getCurrentResidentialAddressData = () => {
    let currentResidentialAddressData = [];

    if (
      !isEmptyVAL(currentResidentialAddress(identityData, country)) &&
      !isEmptyVAL(currentResidentialAddress(identityData, country))
        .currentResidentialAddressData
    )
      currentResidentialAddressData = currentResidentialAddress(
        identityData,
        country
      ).currentResidentialAddressData;
    return currentResidentialAddressData;
  };

  // IDENTITY INFORMATION
  const getIdentityCombinedData = () => {
    const { personalInformationData } = personalInformation(identityData);
    const { identityDetailsData } = identityDetails(identityData);
    const { referenceData } = reference(identityData);
    const currentResidentialAddressData = getCurrentResidentialAddressData();

    let combinedData = [
      ...personalInformationData,
      ...identityDetailsData,
      ...currentResidentialAddressData,
      ...referenceData,
    ];
    // Removing empty slots
    combinedData = combinedData.filter((v) => !isEmptyVAL(v));
    return combinedData;
  };

  // CONSENT STRING
  const getDataSourcesConsentStrings = () => {
    let dataSourceString = [];

    // Map selected services' consent string
    services.forEach((s) => {
      const servicesWithConsent = isEmptyVAL(consentObtained)
        ? []
        : Object.keys(consentObtained);
      const servicesWithConsentLowercase = servicesWithConsent.map((s) =>
        s.toLowerCase()
      );
      let result = [];
      services.forEach((s) => {
        if (!servicesWithConsentLowercase.includes(s)) {
          result.push(s);
        }
      });

      const serviceNames = Object.keys(serviceResponses);
      let displayNames = [];
      serviceNames.forEach((item) => {
        if (specialServiceNames[item]) {
          displayNames.push(specialServiceNames[item]);
        } else {
          displayNames.push(item);
        }
      });

      const serviceName =
        s === "watchlist aml"
          ? "Global Watchlist AML"
          : displayNames.find((item) => item.toLowerCase() === s);

      if (result.includes(s)) {
        dataSourceString.push(`${serviceName}: Not Required`);
      }
      if (
        !isEmptyVAL(consentObtained) &&
        !isEmptyVAL(consentObtained[serviceName])
      ) {
        dataSourceString.push(`${serviceName}: Yes`);
      }
    });

    return dataSourceString;
  };

  const identityCombinedData = getIdentityCombinedData();

  const consentStrings = getDataSourcesConsentStrings();

  const columns = [
    {
      header: "",
      dataKey: "col1",
    },
    {
      header: "",
      dataKey: "col2",
    },
  ];

  const rows = [
    {
      col1: "Identity Data:",
      col2: identityCombinedData.join("\n"),
    },
    {
      col1: "Consent Obtained",
      col2: consentStrings.join("\n"),
    },
  ];

  // ========================================================

  // OCR IMAGES
  if (!isEmptyVAL(bioImgs)) {
    rows.push({
      col1: "",
      col2: "",
    });
  }
  let ratioResize = 3.75;

  let widthDoc, heightDoc, widthSelfie, heightSelfie;

  if (!isEmptyVAL(bioImgs.identityDoc0)) {
    const { imgHeight, imgWidth } = await getImageDimensionsFromBase64UTL(
      bioImgs.identityDoc0
    );

    widthDoc = imgWidth;
    heightDoc = imgHeight;
  }

  if (!isEmptyVAL(bioImgs.selfie)) {
    const { imgHeight, imgWidth } = await getImageDimensionsFromBase64UTL(
      bioImgs.selfie
    );

    widthSelfie = imgWidth;
    heightSelfie = imgHeight;
  }

  doc.autoTable({
    columns: columns,
    body: rows,
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = docStyles.colorWhite;

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> IDENTITY DOCUMENT
      if (
        data.cell.section === "body" &&
        data.column.index === 0 &&
        data.cell.raw === ""
      ) {
        if (!isEmptyVAL(bioImgs.identityDoc0)) {
          data.cell.styles.valign = "center";
          data.cell.styles.halign = "center";

          cell.styles.minCellHeight = setCellHeight({
            height: heightDoc,
            width: widthDoc,
            ratioResize,
          });
        }
      }

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> SELFIE
      if (
        data.cell.section === "body" &&
        data.column.index === 1 &&
        data.cell.raw === ""
      ) {
        data.cell.styles.valign = "center";
        data.cell.styles.halign = "center";

        if (!isEmptyVAL(bioImgs.selfie)) {
          cell.styles.minCellHeight = setCellHeight({
            height: heightSelfie,
            width: widthSelfie,
            ratioResize,
          });
        }
      }
    },
    didDrawCell: (data, index) => {
      const { cell } = data;

      // CENTER X,Y COORDINATES OF  ---> IDENTITY DOCUMENT
      if (
        data.cell.section === "body" &&
        data.column.index === 0 &&
        data.cell.raw === ""
      ) {
        if (!isEmptyVAL(bioImgs.identityDoc0)) {
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width: widthDoc,
            height: heightDoc,
            ratioResize,
          });

          doc.addImage(
            bioImgs.identityDoc0,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }
      }

      // CENTER X,Y COORDINATES OF  ---> SELFIE
      if (
        data.cell.section === "body" &&
        data.column.index === 1 &&
        data.cell.raw === ""
      ) {
        if (!isEmptyVAL(bioImgs.selfie)) {
          const { xPos, yPos, h, w } = setImagePos({
            cellX: cell.x,
            cellY: cell.y,
            cellWidth: cell.width,
            cellheight: cell.height,
            width: widthSelfie,
            height: heightSelfie,
            ratioResize,
          });

          doc.addImage(
            bioImgs.selfie,
            xPos,
            yPos,
            w / ratioResize,
            h / ratioResize
          );
        }
      }
    },
    showHead: "never",
    styles: docStyles.tableStyles,
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
      col2: {
        font: docStyles.fontFamily.arialUnicodeMS,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
    },
    startY: finalY,
  });
};

export default consumerDataSupplied;
