import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const AccountNumberIndia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { pan, epic, aadhaarNo, errors } = formState;

  const inputElements = [
    {
      belongsTo: ['India PAN', 'India Aadhaar'],
      element: (
        <TextFieldGroup
          key="pan"
          id="pan"
          type="text"
          name="pan"
          dataName="panIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.pan')}
          value={pan}
          placeholder={transUTL('translatePlaceholder.inputIN.pan')}
          placeholderTrigger={isEmptyVAL(pan) ? 'input-empty' : ''}
          error={errors.pan}
          errorTrigger={errors.pan ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India EPIC'],
      element: (
        <TextFieldGroup
          key="epic"
          id="epic"
          type="text"
          name="epic"
          dataName="epicIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.epic')}
          value={epic}
          placeholder={transUTL('translatePlaceholder.inputIN.epic')}
          placeholderTrigger={isEmptyVAL(epic) ? 'input-empty' : ''}
          error={errors.epic}
          errorTrigger={errors.epic ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['India Aadhaar'],
      element: (
        <TextFieldGroup
          key="aadhaarNo"
          id="aadhaarNo"
          type="text"
          name="aadhaarNo"
          dataName="aadhaarNoIN"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.aadhaarNo')}
          value={aadhaarNo}
          placeholder={transUTL('translatePlaceholder.inputIN.aadhaarNo')}
          placeholderTrigger={isEmptyVAL(aadhaarNo) ? 'input-empty' : ''}
          error={errors.aadhaarNo}
          errorTrigger={errors.aadhaarNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

AccountNumberIndia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default AccountNumberIndia;
