const inputAU = {
  // PERSONAL INFORMATION
  firstName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',

  // CURRENT RESIDENTIAL ADDRESS
  commune: '',
  district: '',
  province: '',

  // VOTER NUMBER
  voterNo: '',

  // REFERENCE
  reportingReference: '',
};

export default inputAU;
