// ACTION TYPES
import { SET_COUNTRY, RESET_COUNTRY } from '../types';

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';

export const saveSelectedCountryAXN = (country) => (dispatch) => {
  loggerUTL(`SAVING SELECTED COUNTRY: ${country}`);

  // SAVE COUNTRY TO SESSION STORAGE
  sessionStorage.setItem('selectedCountry', country);

  dispatch({
    type: SET_COUNTRY,
    payload: country,
  });
};

/* ============================================
          REST SELECTED COUNTRY ACTION
   ============================================ */
export const resetSelectedCountryAXN = () => (dispatch) => {
  loggerUTL('RESET SELECTED COUNTRY...');
  dispatch({
    type: RESET_COUNTRY,
  });
};
