import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const CenterlinkCardAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    centrelinkCardType,
    centrelinkCardExpiry,
    centrelinkCustomerReferenceNo,
    errors,
  } = formState;

  const centrelinkCardTypeOptions = [
    {
      label: transUTL('translateDropdown.select'),
      value: '',
    },
    {
      label: transUTL('translateDropdown.stateAU.pensionConcession'),
      value: 'PCC',
    },
    {
      label: transUTL('translateDropdown.stateAU.healthCare'),
      value: 'HCC',
    },
    {
      label: transUTL('translateDropdown.stateAU.seniorHealth'),
      value: 'SHC',
    },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: ['Australia Centrelink Card'],
      element: (
        <TextFieldGroup
          key="centrelinkCustomerReferenceNo"
          id="centrelinkCustomerReferenceNo"
          type="text"
          name="centrelinkCustomerReferenceNo"
          dataName="birthCertificateNoAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.centrelinkCustomerReferenceNo'
          )}
          value={centrelinkCustomerReferenceNo}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.centrelinkCustomerReferenceNo'
          )}
          placeholderTrigger={
            isEmptyVAL(centrelinkCustomerReferenceNo) ? 'input-empty' : ''
          }
          error={errors.centrelinkCustomerReferenceNo}
          errorTrigger={
            errors.centrelinkCustomerReferenceNo ? 'input-error' : ''
          }
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Centrelink Card'],
      element: (
        <SelectListGroup
          key="centrelinkCardType"
          id="centrelinkCardType"
          name="centrelinkCardType"
          dataName="centrelinkCardTypeAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.centrelinkCardType'
          )}
          value={centrelinkCardType}
          placeholderTrigger={
            isEmptyVAL(centrelinkCardType) ? 'input-empty' : ''
          }
          options={centrelinkCardTypeOptions}
          error={errors.centrelinkCardType}
          errorTrigger={errors.centrelinkCardType ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Centrelink Card'],
      element: (
        <TextFieldGroup
          key="centrelinkCardExpiry"
          id="centrelinkCardExpiry"
          type="date"
          name="centrelinkCardExpiry"
          dataName="centrelinkCardExpiryAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.centrelinkCardExpiry'
          )}
          value={centrelinkCardExpiry}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.centrelinkCardExpiry'
          )}
          placeholderTrigger={
            isEmptyVAL(centrelinkCardExpiry) ? 'input-empty' : ''
          }
          max="9999-12-31"
          error={errors.centrelinkCardExpiry}
          errorTrigger={errors.centrelinkCardExpiry ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CenterlinkCardAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CenterlinkCardAustralia;
