const dataSourcesAU = {

  // General fields for all Au data source
  suburb: '市郊',
  postCode: '邮编',
  email: '电子邮件地址',
  phoneNo: '电话号码',

  // Specific fields for each data source
  deathCheck: {
    title: '澳大利亚死亡检查',
  },
  deathCheckReturnData: {
    title: '澳大利亚死亡检查返回数据',
    registrationState: '注册州',
    registrationYear: '注册年份',
    registrationNumber: '注册号码',
    dateOfDeath: '死亡日期',
    possibleDeceasedRecord: '可能已故记录',
    possibleDeceasedRecordName: '可能已故记录名称'
  },
  payroll: {
    title: '澳大利亚工资单',
    ABN: 'ABN',
  },
  superannuation: {
    title: '澳大利亚退休金',
    ABN: 'ABN',
  },
  passport: {
    title: '澳大利亚护照',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  birthCertificate: {
    title: '澳大利亚出生证明',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  centerlinkCard: {
    title: '澳大利亚Centerlink卡',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },

  driverLicence: {
    title: '澳大利亚驾驶执照',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  propertyOwnership: {
    title: '澳大利亚财产所有权',  
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  medicareCard: {
    title: '澳大利亚医疗保险卡',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  residential: {
    title: '澳大利亚住宅',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    streetName: '街道名称',
    suburb: '市郊',
    state: '州',
    postCode: '邮编',
    altAddressMatchScore: '替代地址匹配分数',
    altNameMatchScore: '替代名称匹配分数',
    dpId: 'dpid',
    source: '资源',
    email: '电子邮件地址',
    phoneNo: '电话号码',
  },
  residentialLookup: {
    title: '澳大利亚住宅查询',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    streetName: '街道名称',
    suburb: '市郊',
    state: '州',
    postCode: '邮编',
    source: '资源',
    email: '电子邮件地址',
    phoneNo: '电话号码',
    landlineNumber: '座机号码',
    mobileNumber: '手机号码',
  },
  visa: {
    title: '澳大利亚签证',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  visaEntitlement: {
    title: '澳大利亚签证权利',
    searchSuccessful: '搜索成功',
    documentNo: '文件号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  creditBureau: {
    title: '澳大利亚信贷局',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    streetName: '街道名称',
    suburb: '市郊',
    state: '州',
    postCode: '邮编',
  },
  citizens: {
    title: '澳大利亚住宅',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    streetName: '街道名称',
    suburb: '市郊',
    state: '州',
    postCode: '邮编',
    altAddressMatchScore: '替代地址匹配分数',
    altNameMatchScore: '替代名称匹配分数',
    dpId: 'dpid',
    source: '资源',
    email: '电子邮件地址',
    phoneNo: '电话号码',
  },
};

export default dataSourcesAU;
