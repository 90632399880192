import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { saveDataSourcesAXN } from '../../actions/dataSources/dataSourcesActions';
import { setAlertAXN } from '../../actions/alert/alertActions';
import { saveSelectedClientAXN } from '../../actions/usageReport/usageReportAction';

// BUTTON
import Button from '../../components/btn/Button';
import DropdownNon from '../../components/dropdown/DropdownNon';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';

const ClientSelection = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  usageReportRXS,
  saveDataSourcesAXN,
  saveSelectedClientAXN,
  setAlertAXN,
  history,
}) => {
  const { user } = authRXS;
  const [selectedClientSTH, setClientSelectionSTH] = useState('');

  useEffect(() => {
    const { selectedClient } = usageReportRXS;
    setClientSelectionSTH(selectedClient);
    // eslint-disable-next-line
  }, []);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    if (isEmptyVAL(selectedClientSTH))
      return setAlertAXN(
        transUTL('translateAlertMsg.clientSelection'),
        'error'
      );
    saveSelectedClientAXN(selectedClientSTH);
    history.push(
      `/date-selection/client-selection/results/:${user.name.toLowerCase()}`
    );
  };

  // HANDLE ON CHANGE
  const handleOnChange = (e) => setClientSelectionSTH(e.target.value);

  // HANDLE RADIO BUTTON
  const handleRadioButton = (opt) => setClientSelectionSTH(opt);

  // FIND CLIENT
  let foundReport = [];
  let clients = [];
  try {
    foundReport = usageReportRXS.chartData.find(
      (item) => item.date === usageReportRXS.selectedDate
    );
    clients = Object.keys(foundReport.clients).map((item) => {
      return { client: item };
    });
  } catch (err) {}

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>Client selection</h2>
        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <DropdownNon
          handleOnChange={handleOnChange}
          target={usageReportRXS.selectedClient}
          obj={clients}
          objKey="client"
          handelRadio={handleRadioButton}
          name="client"
          transType=""
        />
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to={`/date-selection/${user.username}`}
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

ClientSelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  usageReportRXS: PropTypes.object.isRequired,
  saveDataSourcesAXN: PropTypes.func.isRequired,
  saveSelectedClientAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  usageReportRXS: state.usageReportRXS,
});

export default connect(mapStateToProps, {
  saveDataSourcesAXN,
  saveSelectedClientAXN,
  setAlertAXN,
})(ClientSelection);
