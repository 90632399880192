import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const MalaysiaCreditBureau = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesMY.creditBureau.title'
        )}
      />

      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.searchSuccessful'
                    )}
                  </p>
                  <span className="material-icons success">check_circle</span>
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.safeHarbourScore'
                    )}
                  </p>
                  {!isEmptyVAL(safeHarbourScore) ? (
                    safeHarbourScore.toLowerCase() === 'add' ? (
                      <span className="material-icons success">add_circle</span>
                    ) : (
                      <span>{safeHarbourScore}</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.identityVerified'
                    )}
                  </p>
                  {!isEmptyVAL(identityVerified) ? (
                    identityVerified ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.nationalIDNo'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.nationalIDNo) ? (
                    verifications.nationalIDNo ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.firstName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.firstName) ? (
                    verifications.firstName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.lastName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.lastName) ? (
                    verifications.lastName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>

                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.dateOfBirth'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.dateOfBirth) ? (
                    verifications.dateOfBirth ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.nameMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(nameMatchScore) ? (
                    <span>{nameMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.addressMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(addressMatchScore) ? (
                    addressMatchScore
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.street'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.street) ? (
                    verifications.street ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>

                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.city'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.city) ? (
                    verifications.city ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesMY.creditBureau.postCode'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.postCode) ? (
                    verifications.postCode ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};

MalaysiaCreditBureau.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default MalaysiaCreditBureau;
