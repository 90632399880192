const dataSourcesES = {
  spainResidential: {
    title: 'Spain Residential',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    firstName: 'First Name Verified',
    middleName: 'Middle Name Verified',
    lastName: 'Last Name Verified',
    gender: 'Gender Verified',
    phoneNo: 'Phone Number Verified',
    street: 'Street Verified',
    city: 'City Verified',
    state: 'State Verified',
    postCode: 'Postcode Verified',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
  },
  spainTelco: {
    title: 'Spain Telco',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    gender: 'Gender Verified',
    phoneNo: 'Phone Number Verified',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    street: 'Street Verified',
    city: 'City Verified',
    state: 'State Verified',
    postCode: 'Postcode Verified',
  }
};

export default dataSourcesES;
