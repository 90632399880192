// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { nationalIDNumber, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(nationalIDNumber.status),
      col3: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(nationalIDNumber.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(nationalIDNumber.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'Document Number',
      col2: naUTL(nationalIDNumber.verifications.documentNo),
      col3: naUTL(watchlistAML.verifications.documentNo),
    },
    {
      col1: 'National ID No.',
      col2: naUTL(nationalIDNumber.verifications.nationalIDNo),
      col3: naUTL(watchlistAML.verifications.nationalIDNo),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(nationalIDNumber.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(nationalIDNumber.verifications.middleName),
      col3: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(nationalIDNumber.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
    },

    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(nationalIDNumber.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(nationalIDNumber.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
