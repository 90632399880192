const initialStateDE = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetAddress: '',
  city: '',
  state: '',
  postCode: '',

  // CONTACT DETAILS
  phoneNo: '',
  emailAddress: '',

  //Consent
  creditBureauConsentObtained: false,

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // DISPLAY API
  displayApiResultObtained: false,

  // ERROR HANDLING
  errors: {},
};

export default initialStateDE;
