export const THE_AMERICAS_COUNTRIES = {
  PERU: 'Peru'
}

export const PERU_DATASOURCES = {
  NATIONAL_ID: 'Peru National Id'
}

export const AU_DATASOURCES = {
  AU_DEATH_CHECK: 'Australia Death Check',
  AU_RESIDENTIAL_LOOKUP: 'Australia Residential Lookup'
}