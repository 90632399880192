// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { creditBureau, residential, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(creditBureau.status),
      col3: naUTL(residential.status),
      col4: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(creditBureau.safeHarbourScore),
      col3: naUTL(residential.safeHarbourScore),
      col4: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(creditBureau.identityVerified),
      col3: naUTL(residential.identityVerified),
      col4: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'National ID',
      col2: naUTL(creditBureau.verifications.nationalIdNo),
      col3: naUTL(residential.verifications.nationalIdNo),
      col4: naUTL(watchlistAML.verifications.nationalIdNo),
    },
    {
      col1: 'Full Name Verified',
      col2: naUTL(creditBureau.verifications.fullName),
      col3: naUTL(residential.verifications.fullName),
      col4: naUTL(watchlistAML.verifications.fullName),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(creditBureau.verifications.firstName),
      col3: naUTL(residential.verifications.firstName),
      col4: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(creditBureau.verifications.lastName),
      col3: naUTL(residential.verifications.lastName),
      col4: naUTL(watchlistAML.verifications.lastName),
    },

    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(creditBureau.verifications.dateOfBirth),
      col3: naUTL(residential.verifications.dateOfBirth),
      col4: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(creditBureau.verifications.gender),
      col3: naUTL(residential.verifications.gender),
      col4: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(creditBureau.nameMatchScore),
      col3: naUTL(residential.nameMatchScore),
      col4: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Street Verified',
      col2: naUTL(creditBureau.verifications.street),
      col3: naUTL(residential.verifications.street),
      col4: naUTL(watchlistAML.verifications.street),
    },
    {
      col1: 'District Verified',
      col2: naUTL(creditBureau.verifications.district),
      col3: naUTL(residential.verifications.district),
      col4: naUTL(watchlistAML.verifications.district),
    },
    {
      col1: 'City Verified',
      col2: naUTL(creditBureau.verifications.city),
      col3: naUTL(residential.verifications.city),
      col4: naUTL(watchlistAML.verifications.city),
    },
    {
      col1: 'State Verified',
      col2: naUTL(creditBureau.verifications.state),
      col3: naUTL(residential.verifications.state),
      col4: naUTL(watchlistAML.verifications.state),
    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(creditBureau.verifications.postCode),
      col3: naUTL(residential.verifications.postCode),
      col4: naUTL(watchlistAML.verifications.postCode),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(creditBureau.addressMatchScore),
      col3: naUTL(residential.addressMatchScore),
      col4: naUTL(watchlistAML.addressMatchScore),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
