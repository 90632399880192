import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const italyVAL = (data, dataSources) => {
  const inputErrors = {};

  data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
  data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

  if (isEmptyVAL(data.firstName)) {
    inputErrors.firstName = transUTL(
      'translateFormOverview.formErrors.firstName'
    );
  }
  if (isEmptyVAL(data.lastName)) {
    inputErrors.lastName = transUTL(
      'translateFormOverview.formErrors.lastName'
    );
  }

  /* ============================================
         ITALY RESIDENTIAL && TELCO MOBILE
   ============================================ */
  if (dataSources.includes('Italy Residential') ||
    dataSources.includes('Italy Telco Mobile')) {
    loggerUTL('VALIDATING ITALY DATA...');
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.streetAddress = !isEmptyVAL(data.streetAddress) ? data.streetAddress : '';
    data.city = !isEmptyVAL(data.city) ? data.city : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.streetAddress)) {
      inputErrors.streetAddress = transUTL(
        'translateFormOverview.formErrors.streetAddress'
      );
    }
    if (isEmptyVAL(data.city)) {
      inputErrors.city = transUTL(
        'translateFormOverview.formErrors.city'
      );
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL(
        'translateFormOverview.formErrors.state'
      );
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('VALIDATING WATCHLIST MAL...');

    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';

    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default italyVAL;
