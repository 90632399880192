import dataSourcesAU from './searchResults/dataSourcesAU'; // AUSTRALIA
import dataSourcesBR from './searchResults/dataSourcesBR'; // BRAZIL
import dataSourcesCN from './searchResults/dataSourcesCN'; // CHINA
import dataSourcesIN from './searchResults/dataSourcesIN'; // INDIA
import dataSourcesID from './searchResults/dataSourcesID'; // INDONESIA
import dataSourcesMY from './searchResults/dataSourcesMY'; // MALAYSIA
import dataSourcesNZ from './searchResults/dataSourcesNZ'; // NEW ZEALAND
import dataSourcesPH from './searchResults/dataSourcesPH'; // PHILIPPINES
import dataSourcesKH from './searchResults/dataSourcesKH'; // CAMBODIA
import dataSourcesVN from './searchResults/dataSourcesVN'; // VIETNAM
import dataSourcesSG from './searchResults/dataSourcesSG'; // SINGAPORE
import dataSourcesUS from './searchResults/dataSourcesUS'; // UNITED STATES
import dataSourcesNG from './searchResults/dataSourcesNG'; // NIGERIA
import dataSourcesMX from './searchResults/dataSourcesMX'; // MEXICO
import dataSourcesDE from './searchResults/dataSourcesDE'; // GERMANY
import dataSourcesFR from './searchResults/dataSourcesFR'; // FRANCE
import dataSourcesIT from './searchResults/dataSourcesIT'; // ITALY
import dataSourcesES from './searchResults/dataSourcesES'; // SPAIN
import dataSourcesHK from './searchResults/dataSourcesHK'; // HONG KONG
import watchlistAML from './searchResults/watchlistAML'; // WATCHLIST AML


const translateSearchVerification = {
  /* ============================================
          BASE SEARCH VERIFICATION
   ============================================ */
  title: 'Ringkasan Pengesahan IDU Zoo Data',
  inputData: 'Data Input:',
  reportingReference: 'Rujukan Pelaporan: ',
  issueDateTime: 'Tarikh dan Masa Terbitan:',
  watchlistStatusText: 'Status Senarai Tontonan PEP Antarabangsa:',
  watchlistStatusClear: 'BERSIH',
  watchlistStatusFOUND: 'DITEMUKAN',
  watchlistStatusFOUND2: 'DITEMUKAN (WHITELIST)',
  safeHarbour: 'Pelabuhan Selamat:',
  matchStatus: 'Status Perlawanan:',
  errorMessage: 'Mesej Ralat',
  apiReqAndRes: 'Permintaan dan Respons API IDU: ',

  /* ============================================
                  Template
  ============================================ */
  searchSuccessful: 'Carian Berjaya',
  safeHarbourScore: 'Skor Pelabuhan Selamat',
  nameMatchScore: 'Skor Pertandingan Nama',
  addressMatchScore: 'Markah Padanan Alamat',
  identityVerified: 'Identiti Disahkan',
  category: 'Kategori',
  pdfLink: 'Pautan PDF',

  // personal info
  otherNames: 'Nama lain',
  firstName: 'Nama pertama',
  middleName: 'Nama tengah',
  lastName: 'Nama terakhir',
  fullName: 'Nama Penuh',
  gender: 'Jantina',
  dateOfBirth: 'Tarikh lahir',
  email: 'Alamat emel',
  phoneNo: 'Nombor telefon',
  mobileNo: 'Nombor mudah alih',
  landlineNo: 'No Talian Darat',
  
  // address
  addressLine1: 'Talian alamat 1',
  street: 'Jalan',
  streetNo: 'Nombor Jalan',
  streetName: 'Nama Jalan',
  streetNumberAndName: 'Nombor Dan Nama Jalan',
  streetNameAndHouseNo: 'Nama Jalan Dan Rumah No.',
  suburb: "Pinggir bandar",
  county: "Daerah",
  city: 'Bandar',
  state: 'Negeri',
  province: 'Wilayah',
  postCode: 'Poskod',
  postcode: 'Poskod',
  zipCode: 'Poskod',
  regionCode: 'Kod Wilayah', // ITALY, CZECH
  houseNumber: 'Rumah No.', // ITALY, CZECH

  // documents
  nationalIDNo: 'ID Nasional',
  nationalIdNo: 'ID Kebangsaan No.',
  taxIDNo: 'No Cukai Nombor',
  LicenceNo: 'Nombor Lesen Memandu',
  driversLicenceNo: 'Nombor Lesen Memandu',
  documentNumber: 'Dokumen No.',
  documentNo: 'Dokumen No.',
  idCardNo: 'Kad ID No.',
  passportNo: 'Nombor Pasport',
  fileNo: 'No Fail.',
  dateOfIssue: 'Tarikh Terbitan',
  nik: 'NIK',

  /* ============================================
                  DATA SOURCES
   ============================================ */
  dataSourcesAU,
  dataSourcesBR,
  dataSourcesCN,
  dataSourcesIN,
  dataSourcesID,
  dataSourcesMY,
  dataSourcesNZ,
  dataSourcesPH,
  dataSourcesKH,
  dataSourcesVN,
  dataSourcesSG,
  dataSourcesNG,
  dataSourcesUS,
  dataSourcesMX,
  dataSourcesDE,
  dataSourcesFR,
  dataSourcesIT,
  dataSourcesES,
  dataSourcesHK,
  watchlistAML,
};

export default translateSearchVerification;
