// HELPERS
import title from '../../../helpers/title';

// IDENTITY DATA
import personalInformation from '../../../identityData/personalInformation';
import identityDetails from '../../../identityData/identityDetails';
import currentResidentialAddress from '../../../identityData/currentResidentialAddress';
import reference from '../../../identityData/reference';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILS
import getImageDimensionsFromBase64UTL from '../../../../../utils/getImageDimensionsFromBase64UTL';

const consumerDataSupplied = (doc, data, info, docStyles, bioImgs) => {
  title(
    doc,
    docStyles,
    'Consumer Data Supplied',
    docStyles.headers.secondary,
    docStyles.separation
  );

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  const { service, consentObtained, ...identityData } = data.Request;

  // IDENTITY INFORMATION
  const { personalInformationData } = personalInformation(identityData);
  const { identityDetailsData } = identityDetails(identityData);
  const { currentResidentialAddressData } = currentResidentialAddress(
    identityData,
    'unitedstates'
  );
  const { referenceData } = reference(identityData);

  let combinedData = [
    ...personalInformationData,
    ...identityDetailsData,
    ...currentResidentialAddressData,
    ...referenceData,
  ];
  // Removing empty slots
  combinedData = combinedData.filter((v) => !isEmptyVAL(v));

  // DATA SOURCE CONSENT
  let dataSourceString = [];

  if (service.includes('united states phone')) {
    dataSourceString.push('United States Phone: Not Required');
  }
  if (service.includes('united states residential')) {
    dataSourceString.push('united states residential: Not Required');
  }
  if (service.includes('watchlist aml')) {
    dataSourceString.push('Global Watchlist AML: Not Required');
  }

  const columns = [
    { title: '', dataKey: 'col1' },
    {
      title: '',
      dataKey: 'col2',
    },
  ];

  const rows = [
    {
      col1: 'Identity Data:',
      col2: combinedData.join('\n'),
    },
    {
      col1: 'Consent Obtained',
      col2: dataSourceString.join('\n'),
    },
  ];

  // OCR IMAGES
  if (!isEmptyVAL(bioImgs)) {
    rows.push({
      col1: '',
      col2: '',
    });
  }
  let ratioResize = 3.75;

  doc.autoTable(columns, rows, {
    didParseCell: (data) => {
      const { cell } = data;
      cell.styles.fillColor = docStyles.colorWhite;

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> SELFIE
      if (
        data.cell.section === 'body' &&
        data.column.index === 0 &&
        data.cell.raw === ''
      ) {
        if (!isEmptyVAL(bioImgs.identityDoc0)) {
          data.cell.styles.valign = 'center';
          data.cell.styles.halign = 'center';

          let { height, width } = getImageDimensionsFromBase64UTL(
            bioImgs.identityDoc0
          );

          if (width > 1000) {
            width = width / ratioResize;
            height = height / ratioResize;
          }

          const minCellHeight = height / ratioResize;
          const padding = 40;

          cell.styles.minCellHeight = minCellHeight + padding;
        }
      }

      // SET THE APPROPRIATE HEIGHT FOR THE CURRENT CELL ---> SELFIE
      if (
        data.cell.section === 'body' &&
        data.column.index === 1 &&
        data.cell.raw === ''
      ) {
        data.cell.styles.valign = 'center';
        data.cell.styles.halign = 'center';

        if (!isEmptyVAL(bioImgs.selfie)) {
          let { height, width } = getImageDimensionsFromBase64UTL(
            bioImgs.selfie
          );
          if (width > 1000) {
            width = width / ratioResize;
            height = height / ratioResize;
          }

          const minCellHeight = height / ratioResize;
          const padding = 40;

          cell.styles.minCellHeight = minCellHeight + padding;
        }
      }
    },
    didDrawCell: (data, index) => {
      const textPos = data.cell.textPos;

      // CENTER X,Y COORDINATES OF  ---> IDENTITY DOCUMENT
      if (
        data.cell.section === 'body' &&
        data.column.index === 0 &&
        data.cell.raw === ''
      ) {
        if (!isEmptyVAL(bioImgs.identityDoc0)) {
          let { width, height } = getImageDimensionsFromBase64UTL(
            bioImgs.identityDoc0
          );
          if (width > 1000) {
            width = width / ratioResize;
            height = height / ratioResize;
          }

          if (width > 500) {
            width = width / 1.1;
            height = height / 1.1;
          }

          if (width < 350) {
            width = width + 80;
            height = height + 80;
          }

          const xPos = textPos.x - width / ratioResize / 2; // CENTERS IMAGE X - AXIS
          const yPos = textPos.y - height / ratioResize / 2; // CENTERS IMAGE y - AXIS

          doc.addImage(
            bioImgs.identityDoc0,
            xPos,
            yPos,
            width / ratioResize,
            height / ratioResize
          );
        }
      }

      // CENTER X,Y COORDINATES OF  ---> IDENTITY DOCUMENT
      if (
        data.cell.section === 'body' &&
        data.column.index === 1 &&
        data.cell.raw === ''
      ) {
        if (!isEmptyVAL(bioImgs.selfie)) {
          let { width, height } = getImageDimensionsFromBase64UTL(
            bioImgs.selfie
          );
          if (width > 1000) {
            width = width / ratioResize;
            height = height / ratioResize;
          }

          if (width > 500) {
            width = width / 1.1;
            height = height / 1.1;
          }

          if (width < 350) {
            width = width + 80;
            height = height + 80;
          }

          const xPos = textPos.x - width / ratioResize / 2; // CENTERS IMAGE X - AXIS
          const yPos = textPos.y - height / ratioResize / 2; // CENTERS IMAGE y - AXIS

          doc.addImage(
            bioImgs.selfie,
            xPos,
            yPos,
            width / ratioResize,
            height / ratioResize
          );
        }
      }
    },
    showHead: 'never',
    styles: docStyles.tableStyles,
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
      col2: {
        font: docStyles.fontFamily.arialUnicodeMS,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
        cellWidth: docStyles.fullCellLength / 2,
      },
    },
    startY: finalY,
  });
};

export default consumerDataSupplied;
