const translateDataSources = {
  /* ============================================
                  WATCHLIST AML
   ============================================ */
  watchlistaml: '全球反洗钱观察名单',

  /* ============================================
                  CHINA
   ============================================ */
  chinabankcard: '中国银行卡',
  chinaidverification: '中国身份证验证',
  chinaphone: '中国电话',
  chinaphonealt: '中国电话Alt',
  chinapassportverification: '中国护照验证',
  chinadriverlicense: '中国驾照',

  /* ============================================
                  AUSTRALIA
   ============================================ */
  australiavisa: '澳大利亚签证',
  australiamedicarecard: '澳大利亚医疗保险卡',
  australiabirthcertificate: '澳大利亚出生证明',
  australiacentrelinkcard: '澳大利亚Centrelink卡',
  australiadriverlicence: '澳大利亚驾驶执照',
  australiapassport: '澳大利亚护照',
  australiapropertyownership: '澳大利亚财产所有权',
  australiaresidential: '澳大利亚住宅',
  australiaresidentiallookup: '澳大利亚住宅查询',
  australiavisaentitlement: '澳大利亚签证权利',
  australiacreditbureau: '澳大利亚信贷局',
  australiacitizens: '澳大利亚公民',
  australiapayroll: "澳大利亚工资单",
  australiasuperannuation: "澳大利亚退休金",
  australiadeathcheck: "澳大利亚死亡检查",

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  newzealandcompaniesoffice: '新西兰公司办公室',
  newzealandcompanyverification: '新西兰公司验证',
  newzealanddiabirth: '新西兰DIA出生',
  newzealanddiacitizenship: '新西兰DIA公民身份',
  newzealanddiapassport: '新西兰DIA护照',
  newzealanddriverlicence: '新西兰驾驶执照',
  newzealandlinzproperty: '新西兰LINZ物业',
  newzealandresidential: '新西兰住宅',
  newzealandvehicle: '新西兰车辆',
  newzealandyellowpages: '新西兰黄页',
  newzealandcreditbureau: '新西兰信用局',

  /* ============================================
                  PHILIPPINES
   ============================================ */
  philippinescreditbureau: '菲律宾信贷局',
  philippinesresidential: '菲律宾住宅',
  singaporeresidential: '新加坡住宅',
  singaporeutility: '新加坡公用事业',

  /* ============================================
                  THAILAND
   ============================================ */
  thailandnationalid: '泰国国民身份证',

  /* ============================================
                  MALAYSIA
   ============================================ */
  malaysiacreditbureau: '马来西亚信贷局',
  malaysianationalid: '马来西亚国民身份证',

  /* ============================================
                  INDONESIA
   ============================================ */
  indonesiaresidentidentitycard: '印尼居民身份证',
  indonesiataxregistration: '印尼税务登记',

  /* ============================================
                  INDIA
   ============================================ */
  indiapan: '印度PAN',
  indiaepic: '印度EPIC',
  indiadriverslicence: '印度驾驶执照',
  indiapassport: '印度护照',
  indiaaadhaar: '印度AADHAAR',

  /* ============================================
                  VIETNAM
   ============================================ */
  vietnamconsumerdata: '越南消费者数据',
  vietnamnationalid: '越南国民身份证',
  vietnamnationalidocr: '越南国民身份证OCR',
  vietnamsocialsecurity: '越南社会保障',

  /* ============================================
                  SINGAPORE
   ============================================ */
  singaporecreditbureau: '新加坡征信局',

  /* ============================================
                  CAMBODIA
   ============================================ */
  cambodianationalid: '柬埔寨国民身份证', // RENAMED

  /* ============================================
                HONG KONG
 ============================================ */
  hongkongcreditbureau: '香港信贷局',
  hongkongmarketing: '香港行销',
  hongkongresidential: '香港住宅',

  /* ============================================
                FRANCE
 ============================================ */
  franceresidential: '法国住宅',
  franceresidential2: '法国住宅2',
  franceresidential3: '法国住宅3',

  /* ============================================
                  UNITED STATES
   ============================================ */
  unitedstatesresidential: '美国住宅',
  unitedstatesphone: '美国电话',

  /* ============================================
                  GREAT BRITAIN / UK
   ============================================ */
  unitedkingdomphone: '大不列颠电话',
  unitedkingdomcitizens: '大不列颠居民',

  /* ============================================
                  MEXICO
   ============================================ */
  mexiconationalid: '墨西哥国民身份证',
  mexicotaxregistration: '墨西哥税务登记',

  /* ============================================
                  GERMANY
   ============================================ */
  germanyresidential: '德国住宅',
  germanycreditbureau: '德国信贷局',
  germanyutility: '德国公用事业',
  germanyphone: '德国电话',

  /* ============================================
                  Switzerland
  ============================================= */
  switzerlandcreditbureau: '瑞士信贷局',

  /* ============================================
                  ITALY
   ============================================ */
  italyresidential: '意大利住宅',
  italytelcomobile: '意大利电信移动',
  italycreditbureau: '意大利信用局',

  /* ============================================
              SPAIN
  ============================================ */
  spainresidential: '西班牙住宅',
  spaintelco: '西班牙电信',

  /* ============================================
                GREECE
  ============================================ */
  greececreditbureau: '希腊信贷局',

  /* ============================================
                CHILE
  =========================================== */
  chilenationalid: '智利国民身份证',
  chileresidential: '智利住宅',

  /* ============================================
                AUSTRIA
  ============================================= */
  austriacreditbureau: '奥地利信贷局',

  /* ============================================
                POLAND
  ============================================ */
  polandcreditbureau: '波兰信贷局',

  /* ============================================
                  SLOVAKIA
   ============================================ */
  slovakiaresidential: '斯洛伐克住宅',

  /* ============================================
                  BELGIUM
   ============================================ */
   belgiumresidential: '比利时住宅',

  /* ============================================
                NIGERIA
 ============================================ */
  nigerianationalid: '尼日利亚国民身份证',
  nigeriabankverification: '尼日利亚银行验证',

  /* ============================================
                Morocco
  ============================================ */
  moroccoresidential: '摩洛哥住宅',

  /* ============================================
                  BANGLADESH
  ============================================ */
  bangladeshnationalid: '孟加拉国国民身份证',

  /* ============================================
                BRAZIL
  =========================================== */
  brazilnationalid: '巴西国民身份证',
  braziltaxregistration: '巴西税务登记',

  /* ============================================
                SOUTH AFRICA
  ============================================ */
  southafricanationalid: '南非国民身份证',

  /* ============================================
                Argentina
  ============================================ */
  argentinanationalid: '阿根廷国民身份证',
  argentinacitizens: '阿根廷公民',
  argentinacreditbureau: '奥地利信贷局',


  /* ============================================
                 PERU
  ============================================ */
  perunationalid: '秘鲁国家身份证',
  peruresidential: '秘鲁住宅',
  perutaxregistration: '秘鲁税务登记',

  /* ============================================
                 CZECH
  ============================================ */
  czechrepublicresidential: '捷克共和国住宅',

  /* ============================================
                KENYA
  ============================================ */
  kenyanationalid: '肯尼亚国家身份证',

 /* ============================================
                  FIND IDU
   ============================================ */
   philippinessuspiciousactivity: '菲律宾',

  /* ============================================
                Digital ID Safeguard
  ============================================ */
  digitalidsafeguard: "数字身份保卫战",
  digitalIdSafeguard: '数字身份保卫战', 

  /* ============================================
                 CANADA
  ============================================ */
  canadaphone: '加拿大',

  /* ============================================
                Netherlands
  ============================================ */
  netherlandsresidential: '荷兰住宅',

  /* ============================================
                  OTHER
   ============================================ */
  notApplicable: 'N/A',
};

export default translateDataSources;
