import { transUTL } from "../../../../../utils/transUTL";

const stateCapitalAU = () => {
  return [
    { label: transUTL('translateDropdown.select'), value: '' },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.queensland'),
      value: 'QLD',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.newSouthWales'),
      value: 'NSW',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.southAustralia'),
      value: 'SA',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.tasmania'),
      value: 'TAS',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.victoria'),
      value: 'VIC',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.westernAustralia'),
      value: 'WA',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.australianCapitalTerritory'),
      value: 'ACT',
    },
    {
      label: transUTL('translateDropdown.stateCapital.stateCapitalAU.northernTerritory'),
      value: 'NT',
    }
  ]
};

export default stateCapitalAU;
