// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { creditBureau, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(creditBureau.status),
      col3: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(creditBureau.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(creditBureau.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(creditBureau.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(creditBureau.verifications.middleName),
      col3: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(creditBureau.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Date of Birth Verified',
      col2: naUTL(creditBureau.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Tax ID No.',
      col2: naUTL(creditBureau.verifications.taxIDNo),
      col3: naUTL(watchlistAML.verifications.taxIDNo),
    },
    {
      col1: 'Street Name Verified',
      col2: naUTL(creditBureau.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1),
    },
    {
      col1: 'City Verified',
      col2: naUTL(creditBureau.verifications.addressElement3),
      col3: naUTL(watchlistAML.verifications.addressElement3),
    },
    {
      col1: 'State Verified',
      col2: naUTL(creditBureau.verifications.addressElement4),
      col3: naUTL(watchlistAML.verifications.addressElement4),
    },
    {
      col1: 'Postcode Verified',
      col2: naUTL(creditBureau.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5),
    }
  ];

  return tableFilter(table);
}

export default jsonTableRow;