const translateBtn = {
  previousPage: '上一页',
  nextPage: '下一页',
  submit: '提交',
  printSearchResults: '打印搜索结果',
  newSearch: '新搜寻',
  generatePDF: '产生PDF',
  editSearch: '编辑搜寻',
};

export default translateBtn;
