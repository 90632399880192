// UTILITIES
import whitelistKeys from '../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../utils/loggerUTL';

const whitelistedInputData = (inputData, dataSources) => {
  let philippinesSuspiciousActivityData,
    digitalIDSafeguardData,
    watchlistData;

  if (dataSources.includes('Philippines Suspicious Activity')) {
    loggerUTL('WHITELISTING Philippines Suspicious Activity...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'yearOfBirth',
      'reportingReference',
    ]);
    philippinesSuspiciousActivityData = { ...whitelisted };
  }

  if (dataSources.includes('Digital ID Safeguard')) {
    loggerUTL('WHITELISTING Digital ID Safeguard ...');
    const whitelisted = whitelistKeys(inputData, [
      'emailID',
      'email',
      'hitTarget',
    ]);
    digitalIDSafeguardData = { ...whitelisted };
  }

  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING Watchlist AML ...');
    const whitelisted = whitelistKeys(inputData, [
      'yearOfBirth',
      'orgId',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...philippinesSuspiciousActivityData,
    ...watchlistData,
    ...digitalIDSafeguardData,
  };
};
export default whitelistedInputData;
