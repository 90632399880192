const translateCountrySelection = {
  selectCountry: 'SELECT COUNTRY',
  subscription:
    'Select a Region / Country to view your subscribed data sources:',
  apac: 'APAC',
  africa: 'AFRICA',
  europe: 'EUROPE',
  middleEast: 'MIDDLE EAST',
  theAmericas: 'THE AMERICAS',
};

export default translateCountrySelection;
