// HELPERS
import title from "../../helpers/title";
import infoMsg from "../../helpers/infoMsg";

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

import { specialServiceNames } from "../displayingStringsForVerificationDataFields/verificationResultsDataFields";

// TABLE HEAD
const tableHead = (response, doc, docStyles) => {
  // FIRST TABLE (HEAD)
  const columnsHead = [
    { 
      header: "", 
      dataKey: "col1" 
    },
    {
      header: "",
      dataKey: "col2"
    }
  ];

  const rowsHead = [
    {
      col1: "Safe Harbour",
      col2: `${response.safeHarbour ? "Pass" : "Fail"}`
    }
  ];

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable({
    columns: columnsHead, 
    body: rowsHead,
    didParseCell: data => {
      data.cell.styles.fillColor = [255, 255, 255];
      if (data.cell.raw === "Pass") {
        data.cell.styles.textColor = docStyles.colorGreen;
        data.cell.styles.fontStyle = "bold";
      } else if (data.cell.raw === "Fail") {
        data.cell.styles.textColor = [185, 45, 0];
        data.cell.styles.fontStyle = "bold";
      }
    },
    showHead: "never",
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize
      }
    },
    startY: finalY
  });
};

// TABLE BODY
const tableBody = (response, doc, docStyles) => {
  // SECOND TABLE (BODY)
  const columnsBody = [
    { 
      header: "", 
      dataKey: "col1" 
    },
    {
      header: "",
      dataKey: "col2"
    }
  ];
  const rowsBody = [];

  // MAP SAFE HARBOUR RESULTS IN ROWS
  // ======================================================

  const specialServiceNamesArray = Object.keys(specialServiceNames);
  const serviceResponsesObj = isEmptyVAL(response.serviceResponses) ? {} : response.serviceResponses
  const services = Object.entries(serviceResponsesObj);

  services.forEach(s => {
    const serviceName = s[0];
    const serviceData = s[1];
    let displayName = serviceName;
    if (specialServiceNamesArray.includes(serviceName)) {
      displayName = specialServiceNames[serviceName];
    }

    rowsBody.push({
      col1: serviceName === 'Watchlist AML' ? 'Global Watchlist AML' : displayName,
      col2: serviceData.safeHarbourScore
    })
  })

  // ======================================================

  const finalY = doc.previousAutoTable.finalY + docStyles.separation - 5;
  doc.autoTable({
    columns: columnsBody, 
    body: rowsBody,
    didParseCell: data => {
      data.cell.styles.fillColor = [255, 255, 255];
      if (data.cell.raw === "Pass") {
        data.cell.styles.textColor = docStyles.color;
        data.cell.styles.fontStyle = "bold";
      } else if (data.cell.raw === "Fail") {
        data.cell.styles.textColor = docStyles.colorRed;
        data.cell.styles.fontStyle = "bold";
      }
    },
    showHead: "never",
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize
      }
    },
    startY: finalY
  });
};

const knowYourCustomers = (doc, response, docStyles) => {

  tableHead(response, doc, docStyles);
  tableBody(response, doc, docStyles);

  infoMsg(
    doc,
    docStyles,
    [{ header: "", dataKey: "col1" }],
    [
      {
        col1:
          "Status: 0 = Identity verified and all fields verified | 1 = Identity verified but not all fields verified | 2 = Identity not verified | 3 = data source error"
      }
    ],
    docStyles.separation
  );

  infoMsg(
    doc,
    docStyles,
    [{ header: "", dataKey: "col1" }],
    [
      {
        col1:
          "Safe Harbour Scoring: M1 = Match on Name, DOB, and Address M2 = Match on Name and DOB N1 = Match on Name and Address None = No Match"
      }
    ],
    4
  );
};

const iduVerificationResults = (doc, response, docStyles) => {
  title(
    doc,
    docStyles,
    "IDU Verification Results",
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );
  title(
    doc,
    docStyles,
    "Know Your Customer",
    docStyles.headers.secondary,
    docStyles.separation - 10
  );

  knowYourCustomers(doc, response, docStyles);
};
export default iduVerificationResults;
