import { GET_PROD_DATA_SOURCE_THIS_MONTH } from '../types';

import { loadingToggleAXN } from '../loading/loadingAction';

import { dzAPI } from '../../api/init';

export const getProdDataThisMonthAXN = () => (dispatch) => {
  dispatch(loadingToggleAXN(true));
  const user = JSON.parse(sessionStorage.user);

  const config = {
    headers: { Authorization: user.dzSessionToken },
  };

  dzAPI
    .get('api/v2/monitoring/prod_data_sources_this_month', config)
    .then((res) => {
      const { data } = res;
      const { error, ...filteredData } = data;
      dispatch({
        type: GET_PROD_DATA_SOURCE_THIS_MONTH,
        payload: filteredData,
      });
      dispatch(loadingToggleAXN(false));
    })
    .catch((err) => {
      dispatch(loadingToggleAXN(false));
    });
};
