// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const asiaPacific = (dataSources) => {
  const userDataSources = [];

  // FILTERS
  const australia = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('australia')
  );
  const bangladesh = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('bangladesh')
  );
  const china = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('china')
  );
  const cambodia = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('cambodia')
  );
  const hongKong = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('hong kong')
  );
  const india = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('india')
  );
  const indonesia = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('indonesia')
  );
  const malaysia = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('malaysia')
  );
  const newzealand = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('new zealand')
  );
  const philippines = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('philippines')
  );
  const singapore = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('singapore')
  );
  const thailand = dataSources.filter((dataSource) =>
  dataSource.toLowerCase().includes('thailand')
);
  const vietnam = dataSources.filter((dataSource) =>
    dataSource.toLowerCase().includes('vietnam')
  );

  // MODIFIERS
  if (!isEmptyVAL(australia)) {
    // let index = australia.indexOf('Australia Passport');
    // if (index !== -1) {
    //   australia.splice(index, 1);
    // }

    let index = australia.indexOf('Australia Government Identity Documents 1');
    if (index !== -1) {
      australia.splice(index, 1);
      australia.push('Australia Driver Licence');
    }

    index = australia.indexOf('Australia Government Identity Documents 2');
    if (index !== -1) {
      australia.splice(index, 1);
      australia.push('Australia Passport');
    }

    index = australia.indexOf('Australia Government Identity Documents 3');
    if (index !== -1) {
      australia.splice(index, 1);
      australia.push('Australia Medicare Card');
    }

    index = australia.indexOf('Australia Government Identity Documents 4');
    if (index !== -1) {
      australia.splice(index, 1);
      australia.push('Australia Visa');
    }

    index = australia.indexOf('Australia Government Identity Documents 5');
    if (index !== -1) {
      australia.splice(index, 1);
      australia.push('Australia Centrelink Card');
    }

    index = australia.indexOf('Australia Government Identity Documents 6');
    if (index !== -1) {
      australia.splice(index, 1);
      australia.push('Australia Birth Certificate');
    }

    const uniqueAustraliaDataSources = Array.from(new Set(australia));

    userDataSources.push({
      region: 'apac',
      country: 'australia',
      dataSources: [...uniqueAustraliaDataSources],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'AU', alpha3: 'AUS' },
    });
  }
  if (!isEmptyVAL(bangladesh)) {
    userDataSources.push({
      region: 'apac',
      country: 'bangladesh',
      dataSources: [...bangladesh],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'BD', alpha3: 'BGD' },
    });
  }
  if (!isEmptyVAL(china)) {
    userDataSources.push({
      region: 'apac',
      country: 'china',
      dataSources: [...china],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'CN', alpha3: 'CHN' },
    });
  }
  if (!isEmptyVAL(vietnam)) {
    let index = vietnam.indexOf('Vietnam National ID OCR');
    if (index !== -1) {
      vietnam.splice(index, 1);
    }

    userDataSources.push({
      region: 'apac',
      country: 'vietnam',
      dataSources: [...vietnam],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'VN', alpha3: 'VNM' },
    });
  }
  if (!isEmptyVAL(cambodia)) {
    userDataSources.push({
      region: 'apac',
      country: 'cambodia',
      dataSources: [...cambodia],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'KH', alpha3: 'KHM' },
    });
  }
  if (!isEmptyVAL(hongKong)) {
    userDataSources.push({
      region: 'apac',
      country: 'hongkong',
      dataSources: [...hongKong],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'HK', alpha3: 'HKG' },
    });
  }
  if (!isEmptyVAL(india)) {
    userDataSources.push({
      region: 'apac',
      country: 'india',
      dataSources: [...india],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'IN', alpha3: 'IND' },
    });
  }
  if (!isEmptyVAL(indonesia)) {
    userDataSources.push({
      region: 'apac',
      country: 'indonesia',
      dataSources: [...indonesia],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'ID', alpha3: 'IDN' },
    });
  }
  if (!isEmptyVAL(newzealand)) {
    userDataSources.push({
      region: 'apac',
      country: 'newzealand',
      dataSources: [...newzealand],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'NZ', alpha3: 'NZL' },
    });
  }
  if (!isEmptyVAL(malaysia)) {
    userDataSources.push({
      region: 'apac',
      country: 'malaysia',
      dataSources: [...malaysia],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'MY', alpha3: 'MYS' },
    });
  }
  if (!isEmptyVAL(philippines)) {
    userDataSources.push({
      region: 'apac',
      country: 'philippines',
      dataSources: [...philippines],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'PH', alpha3: 'PHL' },
    });
  }
  if (!isEmptyVAL(singapore)) {
    userDataSources.push({
      region: 'apac',
      country: 'singapore',
      dataSources: [...singapore],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'SG', alpha3: 'SGP' },
    });
  }
  if (!isEmptyVAL(thailand)) {
    userDataSources.push({
      region: 'apac',
      country: 'thailand',
      dataSources: [...thailand],
      belongsTo: 'asiaPacific',
      code: { alpha2: 'TH', alpha3: 'THA' },
    });
  }

  return userDataSources;
};

export default asiaPacific;
