const initialStateCN = {
  // PERSONAL INFORMATION
  fullName: '',
  firstName: '',
  lastName: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // CHINESE SEARCH
  idCardNo: '',
  bankCardNo: '',
  phoneNo: '',
  dateOfBirth: '',
  gender: '',
  passportNo: '',
  driversLicenceNo: '',

  // CONTACT DETAILS
  homePhoneNo: '',
  mobilePhoneNo: '',
  emailAddress: '',

  // TERMS AND CONDITIONS
  chinaCrimeSearch: 'N',
  idVerificationConsentObtained: false,
  nationalPhoneConsentObtained: false,
  bankCardConsentObtained: false,
  passportVerificationConsentObtained: false,
  driversLicenseConsentObtained: false,

  // IF DOT INCLUDED IN FULL NAME
  isDotInFullName: false,

  // REFERENCE NUMBER
  reportingReference: '',

  // DISPLAY API
  displayApiResultObtained: false,

  // ERROR HANDLING
  errors: {},
};

export default initialStateCN;
