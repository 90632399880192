const streetType = {
  access: 'Access',
  accessway: 'Accessway',
  alley: 'Alley',
  anchorage: 'Anchorage',
  annex: 'Annex',
  approach: 'Approach',
  arcade: 'Arcade',
  avenue: 'Avenue',
  beach: 'Beach',
  building: 'Building',
  bluff: 'Bluff',
  boulevard: 'Boulevard',
  branch: 'Branch',
  bridge: 'Bridge',
  bypass: 'Bypass',
  captain: 'Captain',
  chase: 'Chase',
  circle: 'Circle',
  close: 'Close',
  claim: 'Claim',
  common: 'Common',
  circus: 'Circus',
  crescent: 'Crescent',
  creek: 'Creek',
  crest: 'Crest',
  court: 'Court',
  courts: 'Courts',
  central: 'Central',
  cove: 'Cove',
  dell: 'Dell',
  dale: 'Dale',
  drive: 'Drive',
  deviation: 'Deviation',
  entrance: 'Entrance',
  esplanade: 'Esplanade',
  estate: 'Estate',
  extension: 'Extension',
  fairway: 'Fairway',
  floor: 'Floor',
  flat: 'Flat',
  flats: 'Flats',
  farms: 'Farms',
  garden: 'Garden',
  gardens: 'Gardens',
  glade: 'Glade',
  glen: 'Glen',
  gully: 'Gully',
  grange: 'Grange',
  green: 'Green',
  great: 'Great',
  grove: 'Grove',
  gate: 'Gate',
  highway: 'Highway',
  hill: 'Hill',
  house: 'House',
  heights: 'Heights',
  haven: 'Haven',
  island: 'Island',
  junction: 'Junction',
  kiosk: 'Kiosk',
  ladder: 'Ladder',
  leader: 'Leader',
  leigh: 'Leigh',
  lookout: 'Lookout',
  lane: 'Lane',
  landing: 'Landing',
  level: 'Level',
  lower: 'Lower',
  meadows: 'Meadows',
  mount: 'Mount',
  mountain: 'Mountain',
  motorway: 'Motorway',
  northeast: 'Northeast',
  number: 'Number',
  neaves: 'Neaves',
  northwest: 'Northwest',
  newZealand: 'New Zealand',
  orchard: 'Orchard',
  paddock: 'Paddock',
  parade: 'Parade',
  park: 'Park',
  parkway: 'Parkway',
  place: 'Place',
  plaza: 'Plaza',
  promenade: 'Promenade',
  passage: 'Passage',
  point: 'Point',
  path: 'Path',
  quadrant: 'Quadrant',
  quay: 'Quay',
  road: 'Road',
  Ridge: 'ridge',
  roads: 'Roads',
  roadway: 'Roadway',
  reserve: 'Reserve',
  room: 'Room',
  route: 'Route',
  retreat: 'Retreat',
  southeast: 'Southeast',
  shop: 'Shop',
  slope: 'Slope',
  square: 'Square',
  saint: 'Saint',
  street: 'Street',
  station: 'Station',
  suite: 'Suite',
  straight: 'Straight',
  steps: 'Steps',
  strand: 'Strand',
  serviceLane: 'Service Lane',
  southwest: 'Southwest',
  terrace: 'Terrace',
  tramway: 'Tramway',
  track: 'Track',
  trail: 'Trail',
  trees: 'Trees',
  towers: 'Towers',
  upper: 'Upper',
  vista: 'Vista',
  village: 'Village',
  villas: 'Villas',
  valley: 'Valley',
  view: 'View',
  views: 'Views',
  wharf: 'Wharf',
  walk: 'Walk',
};

export default streetType;
