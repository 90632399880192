import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import CheckBoxGroup from '../../../../../components/common/checkBoxGroup/CheckBoxGroup';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

const TermsAndConditionsGermany = ({
  formState,
  selectedDataSources,
  handleToggleCheckbox,
}) => {
  const {
    creditBureauConsentObtained,
  } = formState;

  const found = selectedDataSources.some((r) =>
    [
      'Germany Credit Bureau',
    ].includes(r)
  );

  return (
    <div className="form-overview__terms">
      {found && (
        <p className="form-overview__terms-label">
          {transUTL('translateFormOverview.consentParagraph')}
        </p>
      )}

      {found && (
        <CheckBoxGroup
          key="consent"
          checkboxBool={
            creditBureauConsentObtained
          }
          label={transUTL('translateFormOverview.consentParagraph2')}
          handleToggleCheckbox={() => handleToggleCheckbox(false)}
        />
      )}
      <span />
    </div>
  );
};

TermsAndConditionsGermany.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default TermsAndConditionsGermany;
