const translateBiometrics = {
  facialMatchingResults: 'Facial Matching Results',
  bioAuth: 'BIOMETRIC AUTHENTICATION',
  docScan: 'Document Scan',
  manualSelection: 'manually select image(s)...',
  takePic: 'Capture',
  orTakePhoto: 'or take photo',
  identityCard: 'Identity Card',
  identityCardFront: 'Identity Card Front',
  identityCardBack: 'Identity Card Back',
  noFiles: 'No file chosen',
  uploaded: 'Uploaded!',
  selectOrClick: 'Select / CLICK on the image you would like to compare to',
  documentCaptured: 'Document Captured',
  detailsCaptured: 'Details Captured',
  documentAndSelfie: 'Document & Selfie Match',
  Passed: 'Passed',
  failed: 'Failed',
  docUpload: 'Document Upload',
  selfieUpload: 'Selfie Upload',
  // For New Document Scan Component
  takeAPicture: 'Take A Picture',
  uploadAFile: 'Upload A File',
  uploadedDocuments: 'Uploaded Documents',
  uploadMessage1: 'Please upload the front side of the document (Maximum size: 9MB)',
  uploadMessage2: 'Please upload the back side of the document (Maximum size: 9MB)',
  frontSide: 'Front Side',
  backSide: 'Back Side',
  backSide2: 'Back Side (Not Required)',
  memorySizeError: 'Maximum file size is 9MB',
  noFileError: 'Must upload the front side of the document'
};

export default translateBiometrics;
