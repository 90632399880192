const inputCN = {
  // PERSONAL INFORMATION
  firstName: '龙',
  middleName: '俊',
  lastName: '黄',
  // firstName: '黄',
  // middleName: '俊',
  // lastName: '俊龙',
  dateOfBirth: 'yyyy/mm/dd',

  // CHINESE SEARCH
  idCardNo: '422822198807221014',
  bankCardNo: '6221885200057396436',
  // phoneNo: '+86 12423523235',
  phoneNo: '12423523235',
  passportNo: 'E12345678',

  // REFERENCE
  reportingReference: '',
};

export default inputCN;
