// CHECK IF DATA SOURCE IS CHECKED
const dataSourcesIsCheckedUTL = (dataSource) => {
  const selectedDataSource = [];

  for (let key in dataSource) {
    if (dataSource.hasOwnProperty(key)) {
      if (dataSource[key]) {
        selectedDataSource.push(key);
      }
    }
  }

  return selectedDataSource;
};

// PUTS THE SELECTED DATA SOURCE(S) TO AN ARRAY OF STRINGS
// FROM => {'Vietnam National ID': true, 'Watchlist AML': true, 'Vietnam Consumer Data': false}
// To => ['Vietnam National ID', 'Watchlist AML]
const dataSourcesPlainUTL = (dataSource) => {
  const dataSources = [];
  for (let key in dataSource) {
    if (dataSource.hasOwnProperty(key)) {
      dataSources.push(key);
    }
  }
  return dataSources;
};

// DISPLAYS RELEVANT UI DEPENDING ON THE SELECTED DATA SOURCE(S)
const outputMasterUTL = (dataSources, outputData) =>
  dataSources.length !== 0
    ? outputData.map((item) => {
        const intersection = item.belongsTo.filter(function (e) {
          return dataSources.indexOf(e) > -1;
        });

        if (intersection.length > 0) {
          return item.element;
        } else {
          return null;
        }
      })
    : null;

export { outputMasterUTL, dataSourcesPlainUTL, dataSourcesIsCheckedUTL };
