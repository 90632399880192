const inputNZ = {
  // PERSONAL INFORMATION
  fullName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',

  // CURRENT RESIDENTIAL ADDRESS
  unitNo: '',
  streetNo: '',
  streetName: '',
  suburb: '',
  postCode: '',
  city: '',
  addressUnparsed: 'Search your address...',

  // DRIVERS LICENCE
  driversLicenceNo: '',
  driversLicenceVersion: '',
  vehiclePlate: '',

  // BIRTH CERTIFICATE
  birthCertificateNo: '',
  placeOfBirth: '',
  mothersFirstName: '',
  mothersLastName: '',
  fathersFirstName: '',
  fathersLastName: '',

  // CITIZENSHIP
  citizenshipCertificateNo: '',

  // PASSPORT
  passportNo: '',
  passportExpiryDate: '',

  // CONTACT DETAILS
  phoneNo: '',
  landlineNo: '',

  // REFERENCE
  reportingReference: '',
};

export default inputNZ;
