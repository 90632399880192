import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { saveUserInputAXN } from '../../../../actions/formData/userInputActions';
import { getUserPIIDataV2AXN } from '../../../../actions/transactionHistory/transactionHistoryActions';

// HELPERS


// SEARCH RESULT
import AustraliaDriverLicence from './results/AustraliaDriverLicence';
import AustraliaPassport from './results/AustraliaPassport';
import AustraliaMedicareCard from './results/AustraliaMedicareCard';
import AustraliaBirthCertificate from './results/AustraliaBirthCertificate';
import AustraliaCenterlink from './results/AustraliaCenterlink';
import AustraliaPropertyOwnership from './results/AustraliaPropertyOwnership';
import AustraliaResidential from './results/AustraliaResidential';
import AustraliaResidentialLookup from './results/AustraliaResidentialLookup';
import AustraliaVisa from './results/AustraliaVisa';
import AustraliaVisaEntitlement from './results/AustraliaVisaEntitlement';
import AustraliaCreditBureau from './results/AustraliaCreditBureau';
import GlobalWatchlistAML from '../../reusableSearch/GlobalWatchlistAML';
import AustraliaCitizens from './results/AustraliaCitizens';
import AustraliaPayroll from './results/AustraliaPayroll';
import AustraliaSuperannuation from './results/AustraliaSuperannuation';
import AustraliaDeathCheck from './results/AustraliaDeathCheck';
import AustraliaDeathCheckReturnedData from "./results/AustraliaDeathCheckReturnedData";
import ApiResults from "../../reusableComponent/ApiResults";

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILS
import { transUTL } from '../../../../utils/transUTL';
import whitelistedInputDataAU from '../../../formOverview/countryForms/australia/helpers/whitelistedInputDataAU';
import genderStringUTL from '../../../../utils/genderStringUTL';
import { dataSourcesIsCheckedUTL } from '../../../../utils/outputMasterUTL';

//CONSTANTS
import { AU_DATASOURCES } from "../../../../constants/dataSources";

const AustraliaResults = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  formDataRXS,
  userInputRXS,
  apiReqRXS,
  searchVerificationRXS,
  saveFormDataAXN,
  getUserPIIDataV2AXN,
  watchlistStatus,
  handleClearResults,
  history,
}) => {
  const { australiaFormData } = formDataRXS;
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.australiaDataSources
  );

  let { serviceResponses } = searchVerificationRXS;

  if (isEmptyVAL(serviceResponses)) {
    serviceResponses = {};
  }

  let deathCheckReturnedData = {};
  const { AU_DEATH_CHECK, AU_RESIDENTIAL_LOOKUP} = AU_DATASOURCES;
  let isAuDeathCheck = selectedDataSources.includes(AU_DEATH_CHECK);
  let isAuResidentialLookup = selectedDataSources.includes( AU_RESIDENTIAL_LOOKUP);

  if (isAuDeathCheck) {
    deathCheckReturnedData = serviceResponses[AU_DEATH_CHECK].returnedData;
  } 
  else if (isAuResidentialLookup) {
    deathCheckReturnedData = serviceResponses[AU_RESIDENTIAL_LOOKUP].returnedData;
  } 
  else {
    let returnedData = Object.entries(serviceResponses)
      .map(service => service[1].returnedData)
      .find(rData => rData.hasOwnProperty('possibleDeceasedRecord') &&
        rData.hasOwnProperty('possibleDeceasedRecordName'))
    if (!isEmptyVAL(returnedData)) {
      deathCheckReturnedData = {
      possibleDeceasedRecord: returnedData.possibleDeceasedRecord,
      possibleDeceasedRecordName: returnedData.possibleDeceasedRecordName
    }}
  }

  const whitelistedData = whitelistedInputDataAU(
    australiaFormData,
    selectedDataSources
  );

  const {
    birthCertificateConsentObtained,
    centerlinkCardConsentObtained,
    driverLicenceConsentObtained,
    medicareCardConsentObtained,
    passportConsentObtained,
    visaConsentObtained,
    residentialLookupConsentObtained,
    visaEntitlementConsentObtained,
    creditBureauConsentObtained,
    ...displayData
  } = whitelistedData;

  displayData.gender = genderStringUTL(displayData);

  const displayDataFiltered = Object.keys(displayData)
    .map((val) => displayData[val])
    .filter((v) => !isEmptyVAL(v));

  // GENERATE PDF
  const generatePDF = () =>
    getUserPIIDataV2AXN(authRXS.user, searchVerificationRXS.reportingReference);

  return (
    <Fragment>
      <header className="header-secondary search-verification__input-data">
        <h4 className="header-title-secondary">
          <strong>{transUTL('translateSearchVerification.inputData')}</strong>{' '}
          {displayDataFiltered.join(' | ')}
        </h4>
      </header>

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaDeathCheck
          results={searchVerificationRXS.serviceResponses}
          type="Australia Death Check" // AUSTRALIA DEATH CHECK
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaSuperannuation
          results={searchVerificationRXS.serviceResponses}
          type="Australia Superannuation" // AUSTRALIA SUPERANNUATION
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaPayroll
          results={searchVerificationRXS.serviceResponses}
          type="Australia Payroll" // AUSTRALIA PAYROLL
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaCitizens
          results={searchVerificationRXS.serviceResponses}
          type="Australia Citizens" // AUSTRALIA CITIZENS
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaDriverLicence
          results={searchVerificationRXS.serviceResponses}
          type="Australia Government Identity Documents 1" // AUSTRALIA DRIVER LICENCE
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaPassport
          results={searchVerificationRXS.serviceResponses}
          type="Australia Government Identity Documents 2" // AUSTRALIA PASSPORT
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaMedicareCard
          results={searchVerificationRXS.serviceResponses}
          type="Australia Government Identity Documents 3" // AUSTRALIA MEDICARE CARD
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaVisa
          results={searchVerificationRXS.serviceResponses}
          type="Australia Government Identity Documents 4" // AUSTRALIA VISA
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaCenterlink
          results={searchVerificationRXS.serviceResponses}
          type="Australia Government Identity Documents 5" // Australia Centrelink Card
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaBirthCertificate
          results={searchVerificationRXS.serviceResponses}
          type="Australia Government Identity Documents 6" // AUSTRALIA BIRTH CERTIFICATE
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaPropertyOwnership
          results={searchVerificationRXS.serviceResponses}
          type="Australia Property Ownership"
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaResidential
          results={searchVerificationRXS.serviceResponses}
          type="Australia Residential"
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaResidentialLookup
          results={searchVerificationRXS.serviceResponses}
          type="Australia Residential Lookup"
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaVisaEntitlement
          results={searchVerificationRXS.serviceResponses}
          type="Australia Visa Entitlement"
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <AustraliaCreditBureau
          results={searchVerificationRXS.serviceResponses}
          type="Australia Credit Bureau"
        />
      )}

      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <GlobalWatchlistAML
          results={searchVerificationRXS.serviceResponses}
          type="Watchlist AML"
        />
      )}

      {!isEmptyVAL(deathCheckReturnedData) && (
        <AustraliaDeathCheckReturnedData
          returnedData={deathCheckReturnedData}
          type={isAuDeathCheck}
        />
      )}

      {(userInputRXS.isTickedApiDisplay) && (
        <ApiResults
          results={searchVerificationRXS}
          apiReq={apiReqRXS.apiCall}
          userInputRXS={userInputRXS}
          // type={isAuDeathCheck}
        />
      )}

      {/* BUTTONS */}
      <div className="search-verification__btns">
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.push('/form-overview')}
          >
            {transUTL('translateBtn.editSearch')}
          </button>

          <button className="btn-primary" onClick={handleClearResults}>
            {transUTL('translateBtn.newSearch')}
          </button>
        </div>

        <div className="btn-container btn-under">
          <button className="btn-primary" onClick={generatePDF}>
            {transUTL('translateBtn.generatePDF')}
          </button>

          <button className="btn-primary" onClick={() => window.print(history.push('/search-verification'))}>
            {transUTL('translateBtn.printSearchResults')}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

AustraliaResults.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  apiReqRXS: PropTypes.object.isRequired,
  searchVerificationRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  saveUserInputAXN: PropTypes.func.isRequired,
  getUserPIIDataV2AXN: PropTypes.func.isRequired,
  watchlistStatus: PropTypes.object.isRequired,
  handleClearResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  apiReqRXS: state.apiReqRXS,
  searchVerificationRXS: state.searchVerificationRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  saveUserInputAXN,
  getUserPIIDataV2AXN,
})(withRouter(AustraliaResults));
