const dataSourcesES = {
  spainResidential: {
    title: '西班牙住宅',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    middleName: '中间名已验证',
    lastName: '姓氏已验证',
    gender: '性别验证',
    phoneNo: '电话号码已验证',
    street: '街道验证',
    city: '城市认证',
    state: '状态已验证',
    postCode: '邮编已验证',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
  },
  spainTelco: {
    title: '西班牙电信',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    gender: '性别验证',
    phoneNo: '电话号码已验证',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    street: '街道验证',
    city: '城市认证',
    state: '状态已验证',
    postCode: '邮编已验证',
  }
};

export default dataSourcesES;
