import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const PersonalInformationAML = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    gender,
    errors,
  } = formState;

  const genderOptions = [
    { label: transUTL('translateDropdown.select'), value: '' },
    { label: transUTL('translateDropdown.gender.female'), value: 'Female' },
    { label: transUTL('translateDropdown.gender.male'), value: 'Male' },
    {
      label: transUTL('translateDropdown.gender.wontSpecify'),
      value: "X",
    },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: [
        'Australian Third Party Datasets',
        "Australian Driver's Licence",
        'Australian Medicare Card',
        'Australian Passport',
        'Australian Visa',
        'Australian Visa Entitlement',
        'Australian Residential',
        'Australian Credit Bureau',
        'Watchlist AML',
      ],
      element: (
        <TextFieldGroup
          key="firstName"
          id="firstName"
          type="text"
          name="firstName"
          dataName="firstNameAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.firstName')}
          value={firstName}
          placeholder={transUTL('translatePlaceholder.inputAU.firstName')}
          placeholderTrigger={isEmptyVAL(firstName) ? 'input-empty' : ''}
          error={errors.firstName}
          errorTrigger={errors.firstName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australian Third Party Datasets',
        "Australian Driver's Licence",
        'Australian Medicare Card',
        'Australian Passport',
        'Australian Visa',
        'Australian Visa Entitlement',
        'Australian Credit Bureau',
        'Australian Residential',
        'Watchlist AML',
      ],
      element: (
        <TextFieldGroup
          key="middleName"
          id="middleName"
          type="text"
          name="middleName"
          dataName="middleNameAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.middleName')}
          value={middleName}
          placeholder={transUTL('translatePlaceholder.inputAU.middleName')}
          placeholderTrigger={isEmptyVAL(middleName) ? 'input-empty' : ''}
          error={errors.middleName}
          errorTrigger={errors.middleName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australian Third Party Datasets',
        "Australian Driver's Licence",
        'Australian Medicare Card',
        'Australian Passport',
        'Australian Visa',
        'Australian Visa Entitlement',
        'Australian Residential',
        'Australian Credit Bureau',
        'Watchlist AML',
      ],
      element: (
        <TextFieldGroup
          key="lastName"
          id="lastName"
          type="text"
          name="lastName"
          dataName="lastNameAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.lastName')}
          value={lastName}
          placeholder={transUTL('translatePlaceholder.inputAU.lastName')}
          placeholderTrigger={isEmptyVAL(lastName) ? 'input-empty' : ''}
          error={errors.lastName}
          errorTrigger={errors.lastName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australian Third Party Datasets',
        "Australian Driver's Licence",
        'Australian Medicare Card',
        'Australian Passport',
        'Australian Visa',
        'Australian Visa Entitlement',
        'Australian Residential',
        'Australian Credit Bureau',
        'Watchlist AML',
      ],
      element: (
        <TextFieldGroup
          key="dateOfBirth"
          id="dateOfBirth"
          type="date"
          name="dateOfBirth"
          dataName="dateOfBirthAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.dateOfBirth')}
          value={dateOfBirth}
          placeholder={transUTL('translatePlaceholder.inputAU.dateOfBirth')}
          placeholderTrigger={isEmptyVAL(dateOfBirth) ? 'input-empty' : ''}
          error={errors.dateOfBirth}
          errorTrigger={errors.dateOfBirth ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Watchlist AML'],
      element: (
        <SelectListGroup
          key="gender"
          id="gender"
          name="gender"
          dataName="genderAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.gender')}
          value={gender}
          placeholderTrigger={isEmptyVAL(gender) ? 'input-empty' : ''}
          options={genderOptions}
          error={errors.gender}
          errorTrigger={errors.gender ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

PersonalInformationAML.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default PersonalInformationAML;
