// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataUS = (inputData, dataSources) => {
  let residentialData, watchlistAMLData, phoneData;

  /* ============================================
              UNITED STATES RESIDENTIAL
   ============================================ */
  if (dataSources.includes('United States Residential')) {
    loggerUTL('WHITELISTING UNITED STATES RESIDENTIAL...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'socialSecurityNo',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'postCode',
      'phoneNo',
      'email',
      'reportingReference',
    ]);

    residentialData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELIST WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  /* ============================================
              UNITED STATES PHONE
   ============================================ */
   if (dataSources.includes('United States Phone')) {
    loggerUTL('WHITELISTING UNITED STATES PHONE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'streetNo',
      'streetName',
      'suburb',
      'state',
      'postCode',
      'phoneNo',
      'reportingReference',
    ]);

    phoneData = { ...whitelisted };
  }

  return {
    ...residentialData,
    ...watchlistAMLData,
    ...phoneData,
  };
};
export default whitelistedInputDataUS;
