const initialStateKH = {
  // PERSONAL INFORMATION
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // VOTER NUMBER
  voterNo: '',

  // DRIVERS LICENCE
  driversLicenceNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  commune: '',
  district: '',
  province: '',

  // TERMS & CONDITION
  consumerDataConsentObtained: false,

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStateKH;
