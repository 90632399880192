const dataSourcesNG = {
  nigeriaNationalID: {
    title: '尼日利亚国民身份证',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    middleName: '中间名已验证',
    lastName: '姓氏已验证',
    nameMatchScore: '名字比赛分数',
    nationalIdNo: '身份证号码已验证',
    addressMatchScore: '地址匹配分数',
    street: '街道地址已验证',
    town: '城镇认证',
    lga: '地方政府区域已验证',
    state: '状态已验证',
    gender: '性别验证',
    phoneNo: '电话号码已验证',
    email: '电子邮件已验证'
  },
  nigeriaBankVerification: {
    title: '尼日利亚银行验证',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    middleName: '中间名已验证',
    lastName: '姓氏已验证',
    nameMatchScore: '名字比赛分数',
    bankVerificationNo: '银行验证码已验证',
    addressMatchScore: '地址匹配分数',
    street: '街道地址已验证',
    town: '城镇认证',
    lga: '地方政府区域已验证',
    state: '状态已验证',
    gender: '性别验证',
    phoneNo: '电话号码已验证',
    email: '电子邮件已验证'
  }
};

export default dataSourcesNG;
