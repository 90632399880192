
export const specialServiceNames = {
  // Assign display name to the service has special name from response
  'Australia Government Identity Documents 1': 'Australia Driver Licence',
  'Australia Government Identity Documents 2': 'Australia Passport',
  'Australia Government Identity Documents 3': 'Australia Medicare Card',
  'Australia Government Identity Documents 4': 'Australia Visa',
  'Australia Government Identity Documents 5': 'Australia Centrelink Card',
  'Australia Government Identity Documents 6': 'Australia Birth Certificate',
};

export const verificationResultsDataFields = {
  // GENERAL FIELDS
  generalFields: {
    status: 'Status',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    identityVerified: 'Identity Verified',

    // personal info
    fullName: 'Full Name Verified',
    firstName: 'First Name Verified',
    middleName: 'Middle Name Verified',
    lastName: 'Last Name Verified',
    dateOfBirth: 'Date Of Birth Verified',
    gender: 'Gender Verified',
    email: "Email Verified",
    emailAddress: 'Email Address Verified',
    phoneNo: "Phone No. Verified",
    phoneVerified: "Phone Verified",

    // document no.
    bankCardNo: "Bank Card No. Verified",
    idCardNo: "Id Card No. Verified",
    nationalIDNo: 'National ID No. Verified',
    passportNo: "Passport No. Verified",
    documentNo: "Document No. Verified",
    documentNumber: "Document Number Verified",
    fileNo: 'File No. Verified',
    licenceNo: 'Driver Licence No. Verified',
    nik: 'NIK verified',
    bankVerificationNo: 'Bank Verification No. Verified',
    curp: 'CURP Verified',
    rfc: 'RFC Verified',
    socialSecurityNo: 'Social Security No. Verified',
    taxIDNo: 'Tax ID Verified',
    // landLineNo: 'Land Line No. Verified',
    landlineNo:  'Land Line No. Verified',
    voterNo: 'Voter No. Verified'
  },

  // SPECIFIC FIELDS FOR EACH COUNTRY
  // Country name should be same as data.Response.countryCode
  'Australia': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'Suburb Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Postcode Verified',
    // ABN
    ABN: "ABN Verified",
  },
  'New Zealand': {
    // address
    addressElement1: 'Street No. Verified',
    addressElement2: 'Street Name Verified',
    addressElement3: 'Suburb Verified',
    addressElement4: 'City Verified',
    addressElement5: 'Postcode Verified',
  },
  'Singapore': {
    // address
    addressElement1: 'Street Verified',
    addressElement2: 'Block Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Postcode Verified',
  },
  'Vietnam': {
    // address
    address: 'Address Verified',
  },
  'Philippines': {
    // address
    addressElement1: 'Street Verified',
    addressElement2: 'Barangay Verified',
    addressElement3: 'City Verified',
    addressElement4: 'Province Verified',
    addressElement5: 'Postcode Verified',
  },
  'Thailand': {
    // address
    addressElement1: 'Street Name Verified',
    addressElement2: 'Street No. Verified',
    addressElement3: 'Suburb Verified',
    addressElement4: 'County Verified',
  },
  'Czech Republic': {
    // address
    addressElement1: 'Street',
    addressElement2: 'House number',
    addressElement3: 'City',
  },
  'Hong Kong (Special Administrative Region of China)': {
    // address
    addressElement1: 'Building Verified',
    addressElement2: 'Street Verified',
    addressElement3: 'District Verified',
    addressElement4: 'City Verified',
    addressElement5: 'Post Code Verified',
  },
  'India': {
    // address
    address: 'Address Verified',
  },
  'Malaysia': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Post Code Verified',
  },
  'Nigeria': {
    // address
    streetAddress: 'Street Verified',
    town: 'Town Verified',
    lga: 'LGA Verified',
    state: 'State Verified',
  },
  'United States': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'Suburb Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Post Code Verified',
  },
  'Morocco': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Post Code Verified',
  },
  'Germany': {
    // address
    addressElement1: 'Street Verified',
    addressElement2: 'Street Name Verified',
    addressElement3: 'City Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Post Code Verified',
  },
  'France': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Post Code Verified',
  },
  'Switzerland': {
    // address
    addressElement1: 'Street No. Verified',
    addressElement2: 'Street Name Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Zip Code Verified',
  },
  'Greece': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Post Code Verified',
  },
  'Poland': {
    // address
    addressElement1: 'Street No. Verified',
    addressElement2: 'Street Name Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Post Code Verified',
  },
  'Austria': {
    // address
    addressElement1: 'Street No. Verified',
    addressElement2: 'Street Name Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Post Code Verified',
  },
  'Argentina': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement4: 'State Verified',
    addressElement5: 'Post Code Verified',
  },
  'Peru': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement4: 'State Verified',
  },
  'Slovakia': {
    // address
    addressElement1: 'Street Name Verified',
    addressElement2: 'Street No. Verified',
    addressElement3: 'City Verified',
  },
  'Belgium': {
    // address
    addressElement1: 'Street Name Verified',
    addressElement2: 'House No. Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Post Code Verified',
  },
  'Italy': {
    // address
    addressElement1: 'Street Verified',
    addressElement3: 'City Verified',
    addressElement4: 'Province Verified',
    addressElement5: 'Post Code Verified',
  },
  'United Kingdom': {
    // address
    addressElement1: 'Street / Address Line 1',
    addressElement2: 'Address Line 2 Verified',
    addressElement3: 'Town Verified',
    addressElement4: 'City Verified',
    addressElement5: 'Post Code Verified',
  },
  'Canada': {
    // address
    addressElement1: 'Address Line 1 Verified',
    addressElement2: 'Address Line 2 Verified',
    addressElement3: 'City Verified',
    addressElement4: 'Province Verified',
    addressElement5: 'Post Code Verified',
  },
  'Netherlands': {
    // address
    addressElement1: 'Street Name Verified',
    addressElement2: 'Street No. Verified',
    addressElement3: 'City Verified',
    addressElement5: 'Post Code Verified',
  }
}
