import isEmptyVAL from "../../../validations/checks/isEmptyVAL";

const currentResidentialAddress = (identityData, country) => {
  // ADDRESS STRUCTURE FOR EACH COUNTRY ARE DIFFERENT
  let {
    addressElement1,
    addressElement2,
    addressElement3,
    addressElement4,
    addressElement5,
  } = identityData;

  /* ============================================
                  AUSTRALIA
   ============================================ */
  if (country === "australia") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Suburb: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  BRAZIL
   ============================================ */
  if (country === "brazil") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Unit No: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `Locality: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `District: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }
    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  CHILE
   ============================================ */
  if (country === "chile") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    return {
      currentResidentialAddressData: [addressElement1, addressElement3],
    };
  }

  /* ============================================
                  CAMBODIA
   ============================================ */
  if (country === "cambodia") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Address: ${addressElement1}`;
    }

    return {
      currentResidentialAddressData: [addressElement1],
    };
  }

  /* ============================================
                  HONG KONG
   ============================================ */
  if (country === "hongkong") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Building: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Name: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `District: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
      hongkong(specialadministrativeregionofchina)
   ============================================ */
  if (country === "hongkong(specialadministrativeregionofchina)") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Building: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Name: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `District: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  INDONESIA
   ============================================ */
  if (country === "indonesia") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Alamat/Kelurahan: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Kecamatan: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Kabupaten: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  MALAYSIA
   ============================================ */
  if (country === "malaysia") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  SINGAPORE
   ============================================ */
  if (country === "singapore") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Block: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  PHILIPPINES
   ============================================ */
  if (country === "philippines") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Barangay: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `Province: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  NIGERIA
   ============================================ */
  if (country === "nigeria") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Town: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `LGA: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
      ],
    };
  }

  /* ============================================
                  UNITED STATES
   ============================================ */
  if (country === "unitedstates") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Suburb: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  GREAT BRITAIN / UK
   ============================================ */
  if (country === "unitedkingdom") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Address Line 1: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Address Line 2: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `Town: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `City: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  if (country === "newzealand") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Number: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Name: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `Suburb: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `City: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  GERMANY
   ============================================ */
  if (country === "germany") {
    /*  Germany Credit Bureau */
    if (!isEmptyVAL(addressElement1) && !isEmptyVAL(addressElement2)) {
      addressElement1 = `Street No.: ${addressElement1}`;
      addressElement2 = `Street: ${addressElement2}`;
    } else {
      /* Other Germany Services */
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  VIETNAM
   ============================================ */
  if (country === "vietnam") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Address: ${addressElement1}`;
    }

    return {
      currentResidentialAddressData: [addressElement1],
    };
  }

  /* ============================================
                  Morocco
   ============================================= */
  if (country === "morocco") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Name: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement5,
      ],
    };
  }

  /* =========================================
                Switzerland
  =========================================== */
  if (country === "switzerland") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street No.: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Name: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Zip Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement5,
      ],
    };
  }

  /* =========================================
                France
  =========================================== */
  if (country === "france") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }

    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }

    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  if (country === "greece") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Name: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* =========================================
                Poland
  =========================================== */
  if (country === "poland") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street No.: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Name: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement5,
      ],
    };
  }

  /* =========================================
                SLOVAKIA
  =========================================== */
  if (country === "slovakia") {
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street No.: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Name: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement2,
        addressElement1,
        addressElement3,
      ],
    };
  }

  /* =========================================
                BELGIUM
  =========================================== */
  if (country === "belgium") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Name: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street No.: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `PostCode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  Thailand
   ============================================= */
  if (country === "thailand") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Name: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street No.: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `Suburb: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `County: ${addressElement4}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement4,
      ],
    };
  }

  /* =========================================
                Austria
  =========================================== */
  if (country === "austria") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street No.: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Name: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  ARGENTINA
   ============================================ */
  if (country === "argentina") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Legal Address: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* =========================================
                PERU
  =========================================== */
  if (country === "peru") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }

    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `State: ${addressElement4}`;
    }

    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* =========================================
                ITALY
  =========================================== */
  if (country === "italy") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `Province: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Post Code: ${addressElement5}`;
    }
    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  CZECH REPUBLIC   czechia
   ============================================ */
  if (country === "czechia") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street: ${addressElement1}`;
    }
    // if (!isEmptyVAL(addressElement2)) {
    //   addressElement2 = `House number: ${addressElement2}`;
    // }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        // addressElement2,
        addressElement3,
      ],
    };
  }

  /* ============================================
                  CANADA
   ============================================ */
  if (country === "canada") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Address Line 1: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement4)) {
      addressElement4 = `Province: ${addressElement4}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Postcode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement3,
        addressElement4,
        addressElement5,
      ],
    };
  }

  /* ============================================
                  NETHERLANDS
   ============================================ */
  if (country === "netherlands") {
    if (!isEmptyVAL(addressElement1)) {
      addressElement1 = `Street Name: ${addressElement1}`;
    }
    if (!isEmptyVAL(addressElement2)) {
      addressElement2 = `Street Number: ${addressElement2}`;
    }
    if (!isEmptyVAL(addressElement3)) {
      addressElement3 = `City: ${addressElement3}`;
    }
    if (!isEmptyVAL(addressElement5)) {
      addressElement5 = `Posecode: ${addressElement5}`;
    }

    return {
      currentResidentialAddressData: [
        addressElement1,
        addressElement2,
        addressElement3,
        addressElement5,
      ],
    };
  }
};

export default currentResidentialAddress;
