const dataSourcesVN = {
  vietnamConsumerData: {
    title: '越南消费者数据',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    fullName: '名称已验证',
    email: '电子邮件已验证',
    phoneNo: '电话号码已验证',
    city: '城市认证',
    addressMatchScore: '地址匹配分数',
    address: '地址',
  },
  vietnamNationalID: {
    title: '越南国民身份证',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    fullName: '名称已验证',
    nameMatchScore: '名字比赛分数',
    idNo: '身份证号码已验证',
    addressMatchScore: '地址匹配分数',
    addressVerified: '地址已验证',
    address: '地址',
    gender: '性别验证',
  },
  vietnamSocialSecurity: {
    title: '越南社会保障',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    fullName: '名称已验证',
    nameMatchScore: '名字比赛分数',
    idNo: '身份证号码已验证',
    addressMatchScore: '地址匹配分数',
    addressVerified: '地址已验证',
    address: '地址',
    gender: '性别验证',
  },
};

export default dataSourcesVN;
