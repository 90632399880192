import title from "../../../helpers/title";

const errorMessages = (doc, data, info, docStyles) => {
  const { Response } = data;

  title(
    doc,
    docStyles,
    "Error Message(s)",
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );

  const columnsBody = [
    { title: "", dataKey: "col1" },
    {
      title: "",
      dataKey: "col2",
    },
  ];
  const rowsBody = [];

  // DATA SOURCE RESPONSE
  let diaBirth = Response.serviceResponses["New Zealand DIA Birth"];
  let diaCitizenship = Response.serviceResponses["New Zealand DIA Citizenship"];
  let diaPassport = Response.serviceResponses["New Zealand DIA Passport"];
  let driverLicence = Response.serviceResponses["New Zealand Driver Licence"];
  let linzProperty = Response.serviceResponses["New Zealand LINZ Property"];
  let residential = Response.serviceResponses["New Zealand Residential"];
  let companiesOffice =
    Response.serviceResponses["New Zealand Companies Office"];
  let yellowPages = Response.serviceResponses["New Zealand Yellow Pages"];
  let creditBureau = Response.serviceResponses["New Zealand Credit Bureau"];
  let watchlistAML = Response.serviceResponses["Watchlist AML"];

  // NEW ZEALAND DIA BIRTH ERRORS
  if (diaBirth) {
    let status;
    if (diaBirth.status === 3) {
      status = diaBirth.errorMessage
        ? diaBirth.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand DIA Birth",
      col2: status,
    });
  } else {
    diaBirth = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DIA CITIZENSHIP ERRORS
  if (diaCitizenship) {
    let status;
    if (diaCitizenship.status === 3) {
      status = diaCitizenship.errorMessage
        ? diaCitizenship.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand DIA Citizenship",
      col2: status,
    });
  } else {
    diaCitizenship = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DIA PASSPORT ERRORS
  if (diaPassport) {
    let status;
    if (diaPassport.status === 3) {
      status = diaPassport.errorMessage
        ? diaPassport.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand DIA Passport",
      col2: status,
    });
  } else {
    diaPassport = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DRIVER LICENCE ERRORS
  if (driverLicence) {
    let status;
    if (driverLicence.status === 3) {
      status = driverLicence.errorMessage
        ? driverLicence.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand Driver Licence",
      col2: status,
    });
  } else {
    driverLicence = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND DRIVER LICENCE ERRORS
  if (linzProperty) {
    let status;
    if (linzProperty.status === 3) {
      status = linzProperty.errorMessage
        ? linzProperty.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand LINZ Property",
      col2: status,
    });
  } else {
    linzProperty = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND RESIDENTIAL ERRORS
  if (residential) {
    let status;
    if (residential.status === 3) {
      status = residential.errorMessage
        ? residential.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand Residential",
      col2: status,
    });
  } else {
    residential = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND COMPANIES OFFICE ERRORS
  if (companiesOffice) {
    let status;
    if (companiesOffice.status === 3) {
      status = companiesOffice.errorMessage
        ? companiesOffice.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand Companies Office",
      col2: status,
    });
  } else {
    companiesOffice = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND YELLOW PAGES ERRORS
  if (yellowPages) {
    let status;
    if (yellowPages.status === 3) {
      status = yellowPages.errorMessage
        ? yellowPages.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand Yellow Pages",
      col2: status,
    });
  } else {
    yellowPages = { verifications: {}, returnedData: {} };
  }

  // NEW ZEALAND CREDIT BUREAU ERRORS
  if (creditBureau) {
    let status;
    if (creditBureau.status === 3) {
      status = creditBureau.errorMessage
        ? creditBureau.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "New Zealand Credit Bureau",
      col2: status,
    });
  } else {
    creditBureau = { verifications: {}, returnedData: {} };
  }

  // WATCHLIST AML
  if (watchlistAML) {
    let status;
    if (watchlistAML.status === 3) {
      status = watchlistAML.errorMessage
        ? watchlistAML.errorMessage
        : "Error at Data Source";
    } else {
      status = "N/A";
    }
    rowsBody.push({
      col1: "Global Watchlist AML",
      col2: status,
    });
  } else {
    watchlistAML = { verifications: {}, returnedData: {} };
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable({columns: columnsBody, body: rowsBody, 
    didParseCell: (data) => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === "col1") {
        cell.styles.textColor = docStyles.color;
      }
    },
    showHead: "never",
    styles: {
      textColor: docStyles.colorText,
      font: docStyles.fontFamily.time,
      ...docStyles.tableStyles,
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
      },
    },
    // margin: { bottom: 100 }, // PREVENTS OVERLAP WITH FOOTER
    startY: finalY,
  });
};

export default errorMessages;
