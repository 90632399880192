const translateLogin = {
  loginBtn: '登录',
  forgotPassword: '忘记密码了吗？',
  contact: '或者，与动物园联系',
  termsAndConditions: '条款及细则',
  privacy: '隐私政策',
  copyright: '数据动物园',
  poweredBy: '由数据动物园提供支持',
};

export default translateLogin;
