import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION

// BUTTON
import Button from '../../components/btn/Button';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// TODO CHANGE FROM STYLE MODULE TO NORMAL
const BioResults = ({ authRXS, countryRXS, biometricsRXS, history }) => {
  const { faceMatchResults, identityDocument } = biometricsRXS;
  if (isEmptyVAL(faceMatchResults)) {
    faceMatchResults = {};
  }

  return (
    <div className="common-form">
      <header className="header-primary">
        <h2>{transUTL('translateBiometrics.facialMatchingResults')}</h2>
      </header>
      <div className="common-form__body ocr">
        <div className="ocr__facial-matching-results">
          <div className="ocr__facial-matching-item">
            <p>{transUTL('translateBiometrics.documentCaptured')}</p>
            <p
              className={
                !isEmptyVAL(identityDocument)
                  ? 'ocr__facial-matching-success'
                  : 'ocr__facial-matching-error'
              }
            >
              <span>
                {!isEmptyVAL(identityDocument)
                  ? transUTL('translateBiometrics.detailsCaptured')
                  : transUTL('translateBiometrics.failed')}
              </span>
              {!isEmptyVAL(identityDocument) ? (
                <span className="material-icons no-cursor white">
                  check_circle
                </span>
              ) : (
                  <span className="material-icons no-cursor white">cancel</span>
                )}
            </p>
          </div>

          <div className="ocr__facial-matching-item">
            <p>{transUTL('translateBiometrics.documentAndSelfie')}</p>
            <p
              className={
                faceMatchResults.FaceMatch
                  ? 'ocr__facial-matching-success'
                  : 'ocr__facial-matching-error'
              }
            >
              <span>{faceMatchResults.FaceMatch ? 'Passed' : 'Failed'}</span>
              {faceMatchResults.FaceMatch ? (
                <span className="material-icons no-cursor white">
                  check_circle
                </span>
              ) : (
                  <span className="material-icons no-cursor white">cancel</span>
                )}
            </p>
          </div>
        </div>
      </div>

      {/* BUTTON */}
      <Button
        history={history}
        to="/biometric-auth"
        handleOnSubmit={() => history.push('/form-overview')}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

BioResults.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  biometricsRXS: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {})(BioResults);
