// DEFAULT STYLES
const docStyles = {
  fontFamily: {
    times: 'times',
    helvetica: 'helvetica',
    arialUnicodeMS: 'arial-unicode-ms',
  },
  headers: {
    primary: 12,
    secondary: 9.55,
  },
  colorGreen: [89, 193, 111], // DATA ZOO PRIMARY COLOR
  colorRed: [185, 45, 0], // DATA ZOO ERROR COLOR
  colorWhite: [255, 255, 255], // DATA ZOO ERROR COLOR
  colorText: 95,
  tableStyles: {
    lineWidth: 0.01,
    lineColor: 20,
    fontSize: 8.7,
    cellPadPadding: 3.5,
    fillColor: [255, 255, 255],
    valign: 'middle',
    halign: 'left',
  },
  textSize: 8.7,
  startX: 30,
  tableYFromTitle: 3,
  cellWidth: 130,
  separation: 15,
  fullCellLength: 386, // LENGTH OF TABLE 386px
};

export { docStyles };
