import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const nigeriaVAL = (data, dataSources) => {
  const inputErrors = {};

  /* ============================================
          Required for all services
  ============================================ */
  loggerUTL('VALIDATING NIGERIA ALL...');
  data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
  data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
  data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';

  // ERROR FEEDBACK ACCUMULATOR

  if (isEmptyVAL(data.firstName)) {
    inputErrors.firstName = transUTL(
      'translateFormOverview.formErrors.firstName'
    );
  }
  if (isEmptyVAL(data.lastName)) {
    inputErrors.lastName = transUTL(
      'translateFormOverview.formErrors.lastName'
    );
  }

  /* ============================================
                  Nigeria National ID
   ============================================ */
  if (dataSources.includes('Nigeria National ID')) {
    loggerUTL('VALIDATING NIGERIA NATIONAL ID...');
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.nationalIdNo = !isEmptyVAL(data.nationalIdNo) ? data.nationalIdNo : '';

    if (isEmptyVAL(data.nationalIdNo)) {
      inputErrors.nationalIdNo = transUTL(
        'translateFormOverview.formErrors.vin'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
  }

  /* ============================================
              Nigeria Bank Verification
   ============================================ */
  if (dataSources.includes('Nigeria Bank Verification')) {
    loggerUTL('VALIDATING NIGERIA BANK VERIFICATION...');

    data.bankVerificationNo = !isEmptyVAL(data.bankVerificationNo) ? data.bankVerificationNo : '';

    if (isEmptyVAL(data.bankVerificationNo)) {
      inputErrors.bankVerificationNo = transUTL(
        'translateFormOverview.formErrors.bankVerificationNo'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default nigeriaVAL;
