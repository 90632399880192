// HELPERS
import title from '../../../helpers/title';
import infoMsg from '../../../helpers/infoMsg';

// TABLE HEAD
const tableHead = (Response, doc, docStyles) => {
  // FIRST TABLE (HEAD)
  const columnsHead = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];

  const rowsHead = [
    {
      col1: 'Safe Harbour',
      col2: `${Response.safeHarbour ? 'Pass' : 'Fail'}`,
    },
  ];

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable({columns: columnsHead, body: rowsHead, 
    didParseCell: (data) => {
      data.cell.styles.fillColor = [255, 255, 255];
      if (data.cell.raw === 'Pass') {
        data.cell.styles.textColor = docStyles.colorGreen;
        data.cell.styles.fontStyle = 'bold';
      } else if (data.cell.raw === 'Fail') {
        data.cell.styles.textColor = [185, 45, 0];
        data.cell.styles.fontStyle = 'bold';
      }
    },
    showHead: 'never',
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize,
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
      },
    },
    startY: finalY,
  });
};

// TABLE BODY
const tableBody = (Response, doc, docStyles) => {
  // SECOND TABLE (BODY)
  const columnsBody = [
    { header: '', dataKey: 'col1' },
    {
      header: '',
      dataKey: 'col2',
    },
  ];
  const rowsBody = [];

  // DATA SOURCE RESULTS
  const visaEntitlement =
    Response.serviceResponses['Australia Visa Entitlement'];
  const residentialLookup =
    Response.serviceResponses['Australia Residential Lookup'];
  const visa =
    Response.serviceResponses['Australia Government Identity Documents 4'];
  const residential = Response.serviceResponses['Australia Residential'];
  const propertyOwnership =
    Response.serviceResponses['Australia Property Ownership'];
  const medicareCard =
    Response.serviceResponses['Australia Government Identity Documents 3'];
  const driverLicence =
    Response.serviceResponses['Australia Government Identity Documents 1'];
  const passport =
    Response.serviceResponses['Australia Government Identity Documents 2'];
  const centerlinkCard =
    Response.serviceResponses['Australia Government Identity Documents 5'];
  const birthCertificate =
    Response.serviceResponses['Australia Government Identity Documents 6'];
  const creditBureau = Response.serviceResponses['Australia Credit Bureau'];
  const citizens = Response.serviceResponses['Australia Citizens'];
  const payroll = Response.serviceResponses['Australia Payroll'];
  const superannuation = Response.serviceResponses['Australia Superannuation'];
  const deathCheck = Response.serviceResponses['Australia Death Check'];
  const watchlistAML = Response.serviceResponses['Watchlist AML'];

  // AUSTRALIA VISA ENTITLEMENT
  if (visaEntitlement) {
    rowsBody.push({
      col1: 'Australia Visa Entitlement',
      col2: visaEntitlement.safeHarbourScore,
    });
  }

  // AUSTRALIA RESIDENTIAL LOOKUP
  if (residentialLookup) {
    rowsBody.push({
      col1: 'Australia Residential Lookup',
      col2: residentialLookup.safeHarbourScore,
    });
  }

  // AUSTRALIA VISA
  if (visa) {
    rowsBody.push({
      col1: 'Australia Visa',
      col2: visa.safeHarbourScore,
    });
  }

  // AUSTRALIA RESIDENTIAL
  if (residential) {
    rowsBody.push({
      col1: 'Australia Residential',
      col2: residential.safeHarbourScore,
    });
  }

  // AUSTRALIA PROPERTY OWNERSHIP
  if (propertyOwnership) {
    rowsBody.push({
      col1: 'Australia Property Ownership',
      col2: propertyOwnership.safeHarbourScore,
    });
  }

  // AUSTRALIA MEDICARE CARD
  if (medicareCard) {
    rowsBody.push({
      col1: 'Australia Medicare Card',
      col2: medicareCard.safeHarbourScore,
    });
  }

  // AUSTRALIA DRIVER LICENCE
  if (driverLicence) {
    rowsBody.push({
      col1: 'Australia Driver Licence',
      col2: driverLicence.safeHarbourScore,
    });
  }

  // AUSTRALIA CENTRELINK CARD
  if (centerlinkCard) {
    rowsBody.push({
      col1: 'Australia Centrelink Card',
      col2: centerlinkCard.safeHarbourScore,
    });
  }

  // AUSTRALIA BIRTH CERTIFICATE
  if (birthCertificate) {
    rowsBody.push({
      col1: 'Australia Birth Certificate',
      col2: birthCertificate.safeHarbourScore,
    });
  }

  // AUSTRALIA PASSPORT
  if (passport) {
    rowsBody.push({
      col1: 'Australia Passport',
      col2: passport.safeHarbourScore,
    });
  }

  // AUSTRALIA CREDIT BUREAU
  if (creditBureau) {
    rowsBody.push({
      col1: 'Australia Credit Bureau',
      col2: creditBureau.safeHarbourScore,
    });
  }

  // AUSTRALIA CITIZENS
  if (citizens) {
    rowsBody.push({
      col1: 'Australia Citizens',
      col2: citizens.safeHarbourScore,
    });
  }

  // AUSTRALIA PAYROLL
  if (payroll) {
    rowsBody.push({
      col1: 'Australia Payroll',
      col2: payroll.safeHarbourScore,
    });
  }

  // AUSTRALIA SUPERANNUATION
  if (superannuation) {
    rowsBody.push({
      col1: 'Australia Superannuation',
      col2: superannuation.safeHarbourScore,
    });
  }

  if (deathCheck) {
    rowsBody.push({
      col1: 'Australia Death Check',
      col2: deathCheck.safeHarbourScore,
    });
  }

  // GLOBAL WATCHLIST AML
  if (watchlistAML) {
    rowsBody.push({
      col1: 'Global Watchlist AML',
      col2: watchlistAML.safeHarbourScore,
    });
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.separation - 5;
  doc.autoTable({columns: columnsBody, body: rowsBody, 
    didParseCell: (data) => {
      data.cell.styles.fillColor = [255, 255, 255];
      if (data.cell.raw === 'Pass') {
        data.cell.styles.textColor = docStyles.color;
        data.cell.styles.fontStyle = 'bold';
      } else if (data.cell.raw === 'Fail') {
        data.cell.styles.textColor = docStyles.colorRed;
        data.cell.styles.fontStyle = 'bold';
      }
    },
    showHead: 'never',
    styles: { ...docStyles.tableStyles },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
        fontSize: docStyles.textSize,
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        fontSize: docStyles.textSize,
      },
    },
    startY: finalY,
  });
};

const knowYourCustomers = (doc, data, docStyles) => {
  const { Response } = data;

  tableHead(Response, doc, docStyles);
  tableBody(Response, doc, docStyles);

  infoMsg(
    doc,
    docStyles,
    [{ header: '', dataKey: 'col1' }],
    [
      {
        col1:
          'Status: 0 = Identity verified and all fields verified | 1 = Identity verified but not all fields verified | 2 = Identity not verified | 3 = data source error',
      },
    ],
    docStyles.separation
  );

  infoMsg(
    doc,
    docStyles,
    [{ header: '', dataKey: 'col1' }],
    [
      {
        col1:
          'Safe Harbour Scoring: M1 = Match on Name, DOB, and Address M2 = Match on Name and DOB N1 = Match on Name and Address None = No Match',
      },
    ],
    4
  );
};

const iduVerificationResults = (doc, data, docStyles) => {
  title(
    doc,
    docStyles,
    'IDU Verification Results',
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );
  title(
    doc,
    docStyles,
    'Know Your Customer',
    docStyles.headers.secondary,
    docStyles.separation - 10
  );

  knowYourCustomers(doc, data, docStyles);
};
export default iduVerificationResults;
