import formErrors from './formErrors/formErrors';
import formHeaders from './formHeaders/formHeaders';
import inputLabels from './inputLabels/inputLabels';

const translateFormOverview = {
  consentParagraph:
    'I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or office record holder via third party systems for the purpose of confirming my identity.',
  consentParagraphAadhaar: 
    'I authorize Data Zoo to access my Aadhaar number and help me fetch my details. I understand that Data Zoo will not be storing or sharing the same in any manner.',
  enterInfo: 'Enter required information:',
  selectedServices: 'Selected Services',
  scanAndOCR: 'SCAN & OCR',
  consentParagraph2: 'I agree to the terms and conditions',
  chinaCrime: 'Police Check ?',
  digitalIDSafeguard: 'Digital ID Safeguard™',
  digitalIDSafeguardMessage:
    'Use Digital ID Safeguard™ as an additional service in this verification request',
  previousPage: 'Previous Page',
  submit: 'Submit',
  cantLocateAddress: "Can't find what you're looking for?",
  reportingReference: 'CLIENT (YOUR) REFERENCE',
  cantFindAddress: "Can't find what you're looking for?",
  manualAddress: 'Enter address manually',
  useAddressAutocomplete: 'Use address autocomplete',
  changeCountrySelected: 'Change country selected',
  changeDataSourceSelected: 'Change data source(s) selected',
  watchlistAML: 'PEP & Sanctions | GLOBAL WATCHLIST',
  notApplicable: 'N/A',
  updateAddress: 'Update Address',
  clear: 'Clear',
  indiaFullNameField: 'Must be entered exactly as appears on identity document',
  australiaDriverLicenseCardNo: 'Card no. must be entered if the Driver Licence displays a card number',
  document: 'document',
  scan: 'scan',
  biometric: 'biometric',
  authentication: 'authentication',
  liveness: 'liveness',
  detection: 'detection',
  authenticity: 'authenticity',
  consentParagraphAus:
    'I consent to my details being disclosed to a credit reporting agency or any approved third-party for the purpose of verifying my details, and, for that purpose, I also authorise the third party to act as my intermediary under the Privacy Act to access information held by any other third party.',
  // FORM ERRORS
  formErrors,
  // FORM HEADERS
  formHeaders,
  // INPUT LABELS
  inputLabels,
  isDotInFullName: "Full Name on Passport includes a ' · ' between names eg. 'XXXX·XXXX'",
  //FIND IDU
  includeWatchList: "Include a PEP & Sanctions search?",
  includeSafeguardSearch: "Include a Digital ID Safeguard search?",
  apiResult : "Display API Request and Response",
};

export default translateFormOverview;
