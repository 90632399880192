import moment from "moment";
import isEmptyVAL from "../checks/isEmptyVAL";
import isValidDateVAL from "../checks/isValidDateVAL";

import { transUTL } from "../../utils/transUTL";
import formatDateUTL from "../../utils/formatDateUTL";
import loggerUTL from "../../utils/loggerUTL";

const newzealandVAL = (data, dataSources) => {
  const inputErrors = {};

  // CHECK IF CREDENTIALS IS NOT EMPTY
  data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
  data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
  data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : "";

  /* ============================================
            NEW ZEALAND DIA CITIZENSHIP
   ============================================ */
  if (dataSources.includes("New Zealand DIA Citizenship")) {
    loggerUTL("VALIDATING NEW ZEALAND DIA CITIZENSHIP...");

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      );
    }
  }

  /* ============================================
            NEW ZEALAND DIA PASSPORT
   ============================================ */
  if (dataSources.includes("New Zealand DIA Passport")) {
    loggerUTL("VALIDATING NEW ZEALAND DIA PASSPORT...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : "";
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : "";
    data.passportExpiry = !isEmptyVAL(data.passportExpiry)
      ? data.passportExpiry
      : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      );
    }
    if (isEmptyVAL(data.passportNo)) {
      inputErrors.passportNo = transUTL(
        "translateFormOverview.formErrors.passportNo"
      );
    }
    if (isEmptyVAL(data.passportExpiry)) {
      inputErrors.passportExpiry = transUTL(
        "translateFormOverview.formErrors.passportExpiry"
      );
    }
  }

  /* ============================================
            NEW ZEALAND DRIVERS LICENCE
   ============================================ */
  if (dataSources.includes("New Zealand Driver Licence")) {
    loggerUTL("VALIDATING NEW ZEALAND Driver Licence...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : "";
    data.driversLicenceNo = !isEmptyVAL(data.driversLicenceNo)
      ? data.driversLicenceNo
      : "";
    data.driversLicenceVersion = !isEmptyVAL(data.driversLicenceVersion)
      ? data.driversLicenceVersion
      : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      );
    }
    if (isEmptyVAL(data.driversLicenceNo)) {
      inputErrors.driversLicenceNo = transUTL(
        "translateFormOverview.formErrors.driversLicenceNo"
      );
    }
    if (isEmptyVAL(data.driversLicenceVersion)) {
      inputErrors.driversLicenceVersion = transUTL(
        "translateFormOverview.formErrors.driversLicenceVersion"
      );
    }
  }

  /* ============================================
            NEW ZEALAND COMPANIES OFFICE
   ============================================ */
  if (dataSources.includes("New Zealand Companies Office")) {
    loggerUTL("VALIDATING NEW ZEALAND COMPANIES OFFICE...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : "";
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        "translateFormOverview.formErrors.streetNo"
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        "translateFormOverview.formErrors.streetName"
      );
    }
  }

  /* ============================================
            NEW ZEALAND DIA BIRTH
   ============================================ */
  if (dataSources.includes("New Zealand DIA Birth")) {
    loggerUTL("VALIDATING NEW ZEALAND DIA BIRTH...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      );
    }
  }

  /* ============================================
            NEW ZEALAND LINZ PROPERTY
   ============================================ */
  if (dataSources.includes("New Zealand LINZ Property")) {
    loggerUTL("VALIDATING NEW ZEALAND LINZ PROPERTY...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : "";
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        "translateFormOverview.formErrors.streetNo"
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        "translateFormOverview.formErrors.streetName"
      );
    }
  }
  /* ============================================
            NEW ZEALAND YELLOW PAGES
   ============================================ */
  if (dataSources.includes("New Zealand Yellow Pages")) {
    loggerUTL("VALIDATING NEW ZEALAND YELLOW PAGES...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
  }

  /* ============================================
            NEW ZEALAND RESIDENTIAL
   ============================================ */
  if (dataSources.includes("New Zealand Residential")) {
    loggerUTL("VALIDATING NEW ZEALAND RESIDENTIAL...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : "";
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : "";
    // data.city = !isEmptyVAL(data.city) ? data.city : '';
    // data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        "translateFormOverview.formErrors.streetNo"
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        "translateFormOverview.formErrors.streetName"
      );
    }
    // if (isEmptyVAL(data.city)) {
    //   inputErrors.city = transUTL('translateFormOverview.formErrors.city');
    // }
    // if (isEmptyVAL(data.postCode)) {
    //   inputErrors.postCode = transUTL(
    //     'translateFormOverview.formErrors.postCode'
    //   );
    // }
  }

  /* ============================================
          NEW ZEALAND CREDIT BUREAU
 ============================================ */
  if (dataSources.includes("New Zealand Credit Bureau")) {
    loggerUTL("VALIDATING NEW ZEALAND CREDIT BUREAU...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : "";
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        "translateFormOverview.formErrors.streetNo"
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        "translateFormOverview.formErrors.streetName"
      );
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes("Watchlist AML")) {
    loggerUTL("VALIDATING WATCHLIST AML...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";

    // ERROR FEEDBACK ACCUMULATOR

    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirthFormat"
      );
    }

    if (dateFormatted > moment().format("YYYY-MM-DD")) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirthFormat"
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default newzealandVAL;
