import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../actions/searchVerification/searchVerificationAction';
import { setAlertAXN } from '../../../actions/alert/alertActions';

// HELPERS
import initialState from './helpers/initialState';
import consentChecker from './helpers/consentChecker';
import amlChecker from './helpers/amlChecker';
import whitelistedInputData from "./helpers/whitelistedInputData";

// VALIDATIONS
import findIDUVAL from "../../../validations/inputFields/findIDUVAL";
import isEmptyVAL from "../../../validations/checks/isEmptyVAL";

// FORM COMPONENTS
import FormController from '../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformation from './inputForms/PersonalInformation';
import ClientReference from '../reusableForms/ClientReference';

// BUTTON
import Button from '../../../components/btn/Button';

// UTILITIES
import { dataSourcesIsCheckedUTL } from '../../../utils/outputMasterUTL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';
import noSpaceUTL from "../../../utils/noSpaceUTL";
import jsonBuilder from "../../../utils/jsonBuilder";

import { FIND_IDU_ROUTES, FIND_IDU } from "../../../constants/findIDU";

const FindIDUForms = ({
  authRXS,
  countryRXS,
  dataSourcesRXS,
  formDataRXS,
  errorsRXS,
  saveFormDataAXN,
  setAlertAXN,
  searchVerificationAXN,
  history,
}) => {
  const { FIND_IDU_SELECTION } = FIND_IDU_ROUTES;

  const [userInputSTH, setUserInputSTH] = useState(initialState);
  const [includeSafeguardSearch, setIncludeSafeguardSearch] = useState(false);
  const [includeWatchList, setIncludeWatchList] = useState(false);
  let selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.findIDUDataSources
  );

  if (selectedDataSources[0] === "Philippines") {
    selectedDataSources[0] = "Philippines Suspicious Activity"
  }
  if (includeSafeguardSearch) {
    selectedDataSources.push('Digital ID Safeguard')
  }
  if (includeWatchList) {
    selectedDataSources.push('Watchlist AML')
  }

  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.findIDUFormData,

      errors: {...errorsRXS}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH };

    // VALIDATE INPUT
    const { inputErrors, isValid } = findIDUVAL(
      inputData,
      selectedDataSources
    );
    
    //Minimum 3 inputs are required. Either firstname or lastname should be one of the 3 inputs
    if (!isValid &&  Object.keys(inputErrors).length === 7) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.emptyInput'), 'error');
    } 

    //Please fill in the forms correctly
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // CONSENT CHECKER
    const { isConsent, consentObtained } = consentChecker(
      selectedDataSources,
      inputData
    );

    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // AML CHECKER
    const { preferenceOverrides } = amlChecker(
      selectedDataSources,
      inputData
    );


    // REMOVE ANY SPACES
    if (!isEmptyVAL(userInputSTH.emailID))
      inputData.emailID = noSpaceUTL(inputData.emailID);
    if (!isEmptyVAL(userInputSTH.yearOfBirth))
      inputData.yearOfBirth = noSpaceUTL(inputData.yearOfBirth);

    // WHITELIST DATA
    const whitelisted = whitelistedInputData(inputData, selectedDataSources);

    // ADDRESS MANIPULATION
    const addressElement2 = inputData.barangay;
    const addressElement3 = inputData.city;
    const addressElement4 = inputData.province;
    const addressElement5 = inputData.postCode;

    // FILTER COUNTRY
    // const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    const service = [...selectedDataSources];

    // JSON BUILDER
    const submitData = jsonBuilder({
      // Consider to restructure of the countryCode in case there are more services adding in
      countryCode: 'PH',
      service,
      consentObtained,
      addressElement2,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted,
      preferenceOverrides,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // searchVerificationAXN(submitData, history, FIND_IDU);
    searchVerificationAXN(submitData, history, FIND_IDU);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = () => {
    loggerUTL('ON BLUR...');
    const {
      errors,
      ...filteredFormData
    } = userInputSTH;
    saveFormDataAXN(filteredFormData, FIND_IDU);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');
    const {
      errors,
      ...reset
    } = consentChecker;
    setUserInputSTH(initialState);
    saveFormDataAXN(reset, FIND_IDU);
  };

  // HANDEL TOGGLE TERMS & CONS
  const handleToggleCheckbox = (opt) => {
    loggerUTL('TOGGLE TERMS & CONS...');
    const {
      philippinesSuspiciousConsentObtained,
      digitalIDSafeguardConsentObtained
    } = userInputSTH;

    let philippinesSuspiciousConsent = false;
    let digitalIDSafeguardConsent = false;

    if (selectedDataSources.includes("Philippines Suspicious Activity")) {
      philippinesSuspiciousConsent = !philippinesSuspiciousConsentObtained;
    }
    if (includeSafeguardSearch) {
      digitalIDSafeguardConsent = !digitalIDSafeguardConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      philippinesSuspiciousConsentObtained: philippinesSuspiciousConsent,
      digitalIDSafeguardConsentObtained: digitalIDSafeguardConsent
    });
  };

  const handleToggleIncludeWatchlist = (includeWatchList) => {
    setIncludeWatchList(!includeWatchList);
  }
  const handleToggleIncludeSafeguardSearch = (includeSafeguardSearch) => {
    const {
      philippinesSuspiciousConsentObtained,
      digitalIDSafeguardConsentObtained
    } = userInputSTH;
    if (philippinesSuspiciousConsentObtained) {
      if (!digitalIDSafeguardConsentObtained && !includeSafeguardSearch) {
        setUserInputSTH({
          ...userInputSTH,
          digitalIDSafeguardConsentObtained: true
        });
      } else if (digitalIDSafeguardConsentObtained && includeSafeguardSearch) {
        setUserInputSTH({
          ...userInputSTH,
          digitalIDSafeguardConsentObtained: false
        });
      }
    }

    setIncludeSafeguardSearch(!includeSafeguardSearch);
  }

  return (
    <Fragment>
      {/* INPUT FORMS */}
      <FormController
        key="personalInformation"
        title={transUTL(
          'translateFormOverview.formHeaders.personalInformationFindIDU'
        )}
        inputForm={
          <PersonalInformation
            formState={userInputSTH}
            includeWatchList={includeWatchList}
            includeSafeguardSearch={includeSafeguardSearch}
            selectedDataSources={selectedDataSources}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            handleEnterKeyTrigger={handleEnterKeyTrigger}
            handleToggleIncludeWatchlist={handleToggleIncludeWatchlist}
            handleToggleIncludeSafeguardSearch={handleToggleIncludeSafeguardSearch}
          />
        }
      />

      <FormController
        key="clientRef"
        title={transUTL('translateFormOverview.formHeaders.clientRef')}
        inputForm={
          <ClientReference
            formState={userInputSTH}
            selectedDataSources={selectedDataSources}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            handleEnterKeyTrigger={handleEnterKeyTrigger}
          />
        }
      />

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to='/find-idu'
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

FindIDUForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
})(withRouter(FindIDUForms));
