import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD MALAYSIA API ERRORS TO INPUT FORM
const feedbackMY = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    /* ============================================
              IDENTITY DETAILS
       ============================================ */
    if (findErrorUTL(errors, 'nationalIDNo')) {
      inputError.nationalIDNo = `National ID number ${
        errors.find((item) => item.includes('nationalIDNo')).split(':')[1]
      }`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES MALAYSIA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackMY;
