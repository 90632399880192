// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { nationalIDNumber, citizens, creditBureau, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(nationalIDNumber.status),
      col4: naUTL(citizens.status),
      col5: naUTL(creditBureau.status),
      col3: naUTL(watchlistAML.status),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(nationalIDNumber.identityVerified),
      col4: naUTL(citizens.identityVerified),
      col5: naUTL(creditBureau.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(nationalIDNumber.safeHarbourScore),
      col4: naUTL(citizens.safeHarbourScore),
      col5: naUTL(creditBureau.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Document Number',
      col2: naUTL(nationalIDNumber.verifications.documentNo),
      col4: naUTL(citizens.verifications.documentNo),
      col5: naUTL(creditBureau.verifications.documentNo),
      col3: naUTL(watchlistAML.verifications.documentNo),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(nationalIDNumber.nameMatchScore),
      col4: naUTL(citizens.nameMatchScore),
      col5: naUTL(creditBureau.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(nationalIDNumber.addressMatchScore),
      col4: naUTL(citizens.addressMatchScore),
      col5: naUTL(creditBureau.addressMatchScore),
      col3: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(nationalIDNumber.verifications.firstName),
      col4: naUTL(citizens.verifications.firstName),
      col5: naUTL(creditBureau.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(nationalIDNumber.verifications.middleName),
      col4: naUTL(citizens.verifications.middleName),
      col5: naUTL(creditBureau.verifications.middleName),
      col3: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(nationalIDNumber.verifications.lastName),
      col4: naUTL(citizens.verifications.lastName),
      col5: naUTL(creditBureau.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(nationalIDNumber.verifications.dateOfBirth),
      col4: naUTL(citizens.verifications.dateOfBirth),
      col5: naUTL(creditBureau.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(nationalIDNumber.verifications.gender),
      col4: naUTL(citizens.verifications.gender),
      col5: naUTL(creditBureau.verifications.gender),
      col3: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Street No. / Name Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement1),
      col4: naUTL(''),
      col5: naUTL(''),
      col3: naUTL(''),
    },
    {
      col1: 'Street No. Verified',
      col2: naUTL(''),
      col4: naUTL(citizens.verifications.addressElement1),
      col5: naUTL(creditBureau.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1),
    },
    {
      col1: 'City Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement3),
      col4: naUTL(citizens.verifications.addressElement3),
      col5: naUTL(creditBureau.verifications.addressElement3),
      col3: naUTL(watchlistAML.verifications.addressElement3),
    },
    {
      col1: 'State Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement4),
      col4: naUTL(citizens.verifications.addressElement4),
      col5: naUTL(creditBureau.verifications.addressElement4),
      col3: naUTL(watchlistAML.verifications.addressElement4),
    },
    {
      col1: 'Postcode Verified',
      col2: naUTL(nationalIDNumber.verifications.addressElement5),
      col4: naUTL(citizens.verifications.addressElement5),
      col5: naUTL(creditBureau.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5),
    },
    {
      col1: 'National ID No.',
      col2: naUTL(nationalIDNumber.verifications.nationalIDNo),
      col4: naUTL(citizens.verifications.nationalIDNo),
      col5: naUTL(creditBureau.verifications.nationalIDNo),
      col3: naUTL(watchlistAML.verifications.nationalIDNo),
    },
    {
      col1: 'Phone No.',
      col2: naUTL(nationalIDNumber.verifications.phoneNo),
      col4: naUTL(citizens.verifications.phoneNo),
      col5: naUTL(creditBureau.verifications.documentNo),
      col3: naUTL(watchlistAML.verifications.documentNo),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
