import * as Sentry from '@sentry/browser';

import { saveFormDataAXN } from '../../formData/formDataActions';
import { setAlertAXN } from '../../alert/alertActions';
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';
import splitFullName from '../helpers/splitFullName';

const inputMappingMY = (data, dispatch) => {
  try {
    const { ScanVariables, DocumentInformation } = data;
    // INPUT MAPPING
    let firstName = '',
      middleName = '',
      lastName = '',
      gender = '',
      dateOfBirth = '',
      idNo = '';

    if (!isEmptyVAL(ScanVariables.firstName)) {
      firstName = ScanVariables.firstName;
    }
    if (!isEmptyVAL(ScanVariables.lastName)) {
      lastName = ScanVariables.lastName;
    }
    if (
      isEmptyVAL(ScanVariables.firstName) ||
      isEmptyVAL(ScanVariables.lastName) &&
      !isEmptyVAL(DocumentInformation.FullName)
    ) {
      let {FirstName, MiddleName, LastName} = splitFullName(DocumentInformation.FullName);
      firstName = FirstName;
      middleName = MiddleName;
      lastName = LastName;
    }

    if (!isEmptyVAL(ScanVariables.sex)) {
      const sex = ScanVariables.sex.toLowerCase();
      if (sex === 'm' || sex === 'male' || 'LELAKI') {
        gender = 'M';
      } else if (!isEmptyVAL(sex)) {
        // TODO: FIX THIS - CHECK TRANSLATION FOR FEMALE
        gender = 'F';
      }
    }

    if (
      !isEmptyVAL(ScanVariables.dateOfBirth.day) &&
      !isEmptyVAL(ScanVariables.dateOfBirth.month) &&
      !isEmptyVAL(ScanVariables.dateOfBirth.year)
    ) {
      if (ScanVariables.dateOfBirth.month < 10)
        ScanVariables.dateOfBirth.month = `0${ScanVariables.dateOfBirth.month}`;
      if (ScanVariables.dateOfBirth.day < 10)
        ScanVariables.dateOfBirth.day = `0${ScanVariables.dateOfBirth.day}`;
      dateOfBirth = `${ScanVariables.dateOfBirth.year}-${ScanVariables.dateOfBirth.month}-${ScanVariables.dateOfBirth.day}`;
    }

    if (!isEmptyVAL(ScanVariables.documentNumber)) {
      idNo = ScanVariables.documentNumber;
    }

    // PDF SCANNED DATA TRIGGER
    // scanDataEditedTrigger('malaysia');
    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('malaysia', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      saveFormDataAXN(
        {
          firstName,
          middleName,
          lastName,
          gender,
          dateOfBirth,
          idNo,
        },
        'malaysia'
      )
    );
  } catch (err) {
    loggerUTL('ERROR...', err);
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException('Input mapping error...', data, err);
  }
};

export default inputMappingMY;
