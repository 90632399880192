const translateDashboard = {
  hello: '你好',
  iduMsg: 'IDU，一种简单且安全的数字身份验证服务。',
  readMore: '阅读更多',

  // TILES
  identityVerification: '身份验证',
  pdfHistory: '搜索PDF历史',
  history: '历史',
  pep: 'PEP和制裁|',
  watchlist: '收看清单',
  mobileVerification: '移动验证',
  mobile: '移动',
  digitalID: '数字身份证安全',
  passwordAdvice: '密码建议',
  sms: '短信',
  usageReport: '使用报告',
  data: '数据',
  dataAnalytics: '数据源分析',
  globalCoverage: '全球覆盖',
  comingSoon: '快来了',
  noSubscription: '没有订阅',
  subscribedCountryData: '订阅国家/地区数据',
  costEstimation: '费用估算',
  cost: '成本',
  eToro: 'e投睿',
  eToroTwo: 'e投睿用法',
  transactions: '交易次数',
  multiService: '跨境',
  findIdU: 'Find ',
  searchForIndividuals: '搜索个人'
};

export default translateDashboard;
