import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../components/header/Header';

// VALIDATIONS
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';

// COMPONENTS
const ApiResults = ({ results, apiReq }) => {

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.apiReqAndRes'
        )}
      />

      <div className="search-verification__verify">
        <div className="search-verification__col">
          <div className="search-verification__verify-items">
            <pre>
              {JSON.stringify(apiReq, undefined, 4)}
            </pre>
          </div>
        </div>
        <div className="search-verification__col">
          <div className="search-verification__verify-items">
            <pre>
              {JSON.stringify(results, undefined, 4)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

ApiResults.propTypes = {
  results: PropTypes.object.isRequired,
  apiReq: PropTypes.object.isRequired,
};

export default ApiResults;
