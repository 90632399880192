import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

/* 
  This logic applies:  
  when all the address inputs can be optional/empty
  but be mandatory/reqired once one of them entered
*/
const validateAddressFields = (data, inputErrors) => {
  // all address elemments can be null
  if (isEmptyVAL(data.streetNo) 
  && isEmptyVAL(data.streetName) 
  && isEmptyVAL(data.suburb) 
  && isEmptyVAL(data.state)
  && isEmptyVAL(data.postCode)
  ) {
    return;
  }
  // all addresses are provided
  if (!isEmptyVAL(data.streetNo) 
  && !isEmptyVAL(data.streetName) 
  && !isEmptyVAL(data.suburb) 
  && !isEmptyVAL(data.state)
  && !isEmptyVAL(data.postCode)
  ) {
    return;
  }

  if (isEmptyVAL(data.streetNo)) {
    inputErrors.streetNo = transUTL(
      'translateFormOverview.formErrors.streetNo'
    );
  }
  if (isEmptyVAL(data.streetName)) {
    inputErrors.streetName = transUTL(
      'translateFormOverview.formErrors.streetName'
    );
  }
  if (isEmptyVAL(data.state)) {
    inputErrors.state = transUTL('translateFormOverview.formErrors.state');
  }
  if (isEmptyVAL(data.postCode)) {
    inputErrors.postCode = transUTL(
      'translateFormOverview.formErrors.postCode'
    );
  }
}

const australiaVAL = (data, dataSources) => {
  const inputErrors = {};

  /* ============================================
                AUSTRALIA DEATH CHECK
   ============================================ */
   if (dataSources.includes('Australia Death Check')) {
    loggerUTL('VALIDATE DEATH CHECK...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
  }

  /* ============================================
            AUSTRALIA PAYROLL & SUPERANNUATION
   ============================================ */
   if (dataSources.includes('Australia Payroll') || dataSources.includes('Australia Superannuation')) {
    if (dataSources.includes('Australia Payroll')) loggerUTL('VALIDATE AUSTRALIA PAYROLL...');
    if(dataSources.includes('Australia Superannuation')) loggerUTL('VALIDATE AUSTRALIA SUPERANNUATION...');
    
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.suburb = !isEmptyVAL(data.suburb) ? data.suburb : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    // data.ABN = !isEmptyVAL(data.ABN) ? data.ABN : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }

    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }

    validateAddressFields(data, inputErrors);
    // if (isEmptyVAL(data.ABN)) {
    //   inputErrors.ABN = transUTL(
    //     'translateFormOverview.formErrors.ABN'
    //   );
    // }
  }

  /* ============================================
                AUSTRALIA PASSPORT
   ============================================ */
  if (dataSources.includes('Australia Passport')) {
    loggerUTL('VALIDATE AUSTRALIA PASSPORT...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.gender = !isEmptyVAL(data.gender) ? data.gender : '';
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.gender)) {
      inputErrors.gender = transUTL('translateFormOverview.formErrors.gender');
    }
    if (isEmptyVAL(data.passportNo)) {
      inputErrors.passportNo = transUTL(
        'translateFormOverview.formErrors.passportNo'
      );
    }
  }

  /* ============================================
            AUSTRALIA BIRTH CERTIFICATE
   ============================================ */
  if (dataSources.includes('Australia Birth Certificate')) {
    loggerUTL('VALIDATE AUSTRALIA BIRTH CERTIFICATE...');

    const isRequiredBC =  (data.birthRegistrationState === "ACT" || data.birthRegistrationState === "NT" || 
                          (data.birthRegistrationDate > '2010-07-01' && data.birthRegistrationState === "NSW") || 
                          (data.birthRegistrationDate > '1999-11-01' && data.birthRegistrationState === "SA")); 

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.birthRegistrationState)) {
      inputErrors.birthRegistrationState = transUTL(
        'translateFormOverview.formErrors.birthRegistrationState'
      );
    }
    if (isEmptyVAL(data.birthRegistrationNo)) {
      inputErrors.birthRegistrationNo = transUTL(
        'translateFormOverview.formErrors.birthRegistrationNo'
      );
    }
    if (isEmptyVAL(data.birthCertificateNo) && isRequiredBC) {
      inputErrors.birthCertificateNo = transUTL(
        'translateFormOverview.formErrors.birthCertificateNo'
      );
    }
    if (isEmptyVAL(data.birthRegistrationDate)) {
      inputErrors.birthRegistrationDate = transUTL(
        'translateFormOverview.formErrors.birthRegistrationDate'
      );
    }
  }

  /* ============================================
            Australia Centrelink Card
   ============================================ */
  if (dataSources.includes('Australia Centrelink Card')) {
    loggerUTL('VALIDATE Australia Centrelink Card...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.centrelinkCardType)) {
      inputErrors.centrelinkCardType = transUTL(
        'translateFormOverview.formErrors.centrelinkCardType'
      );
    }
    if (isEmptyVAL(data.centrelinkCardExpiry)) {
      inputErrors.centrelinkCardExpiry = transUTL(
        'translateFormOverview.formErrors.centrelinkCardExpiry'
      );
    }
    if (isEmptyVAL(data.centrelinkCustomerReferenceNo)) {
      inputErrors.centrelinkCustomerReferenceNo = transUTL(
        'translateFormOverview.formErrors.centrelinkCustomerReferenceNo'
      );
    }
  }

  /* ============================================
            AUSTRALIA DRIVER LICENCE
   ============================================ */
  if (dataSources.includes('Australia Driver Licence')) {
    loggerUTL('VALIDATE AUSTRALIA DRIVER LICENCE...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.driversLicenceNo)) {
      inputErrors.driversLicenceNo = transUTL(
        'translateFormOverview.formErrors.driversLicenceNo'
      );
    }
    if (isEmptyVAL(data.driversLicenceStateOfIssue)) {
      inputErrors.driversLicenceStateOfIssue = transUTL(
        'translateFormOverview.formErrors.driversLicenceStateOfIssue'
      );
    }
    // to be mandatory field from September
    if (isEmptyVAL(data.driversLicenceCardNo) && (data.driversLicenceStateOfIssue !== "VIC")) {
      inputErrors.driversLicenceCardNo = transUTL(
        'translateFormOverview.formErrors.driversLicenceCardNo'
      );
    }
  }

  /* ============================================
            AUSTRALIA MEDICARE CARD
   ============================================ */
  if (dataSources.includes('Australia Medicare Card')) {
    loggerUTL('VALIDATE AUSTRALIA MEDICARE CARD...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.medicareCardNo)) {
      inputErrors.medicareCardNo = transUTL(
        'translateFormOverview.formErrors.medicareCardNo'
      );
    }
    if (isEmptyVAL(data.medicareCardType)) {
      inputErrors.medicareCardType = transUTL(
        'translateFormOverview.formErrors.medicareCardType'
      );
    }
    if (isEmptyVAL(data.medicareReferenceNo)) {
      inputErrors.medicareReferenceNo = transUTL(
        'translateFormOverview.formErrors.medicareReferenceNo'
      );
    }
    if (!isEmptyVAL(data.medicareReferenceNo)) {
      if (isNaN(data.medicareReferenceNo)) {
        inputErrors.medicareReferenceNo = transUTL(
          'translateFormOverview.formErrors.medicareReferenceNoNumberOnly'
        );
      }
    }
    if (isEmptyVAL(data.medicareExpiry)) {
      inputErrors.medicareExpiry = transUTL(
        'translateFormOverview.formErrors.medicareExpiry'
      );
    }
  }

  /* ============================================
            AUSTRALIA PROPERTY OWNERSHIP
   ============================================ */
  if (dataSources.includes('Australia Property Ownership')) {
    loggerUTL('VALIDATE AUSTRALIA PROPERTY OWNERSHIP...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.suburb = !isEmptyVAL(data.suburb) ? data.suburb : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        'translateFormOverview.formErrors.streetNo'
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.suburb)) {
      inputErrors.suburb = transUTL('translateFormOverview.formErrors.suburb');
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL('translateFormOverview.formErrors.state');
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
  }

  /* ============================================
            AUSTRALIA RESIDENTIAL & CITIZENS
   ============================================ */
  if (dataSources.includes('Australia Residential') || dataSources.includes('Australia Citizens')) {
    if (dataSources.includes('Australia Residential')) {
      loggerUTL('VALIDATE AUSTRALIA RESIDENTIAL...');
    } else {
      loggerUTL('VALIDATE AUSTRALIA CITIZENS...');
    }

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.suburb = !isEmptyVAL(data.suburb) ? data.suburb : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        'translateFormOverview.formErrors.streetNo'
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.suburb)) {
      inputErrors.suburb = transUTL('translateFormOverview.formErrors.suburb');
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL('translateFormOverview.formErrors.state');
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
  }

  /* ============================================
            AUSTRALIA RESIDENTIAL LOOK UP
   ============================================ */
  if (dataSources.includes('Australia Residential Lookup')) {
    loggerUTL('VALIDATE AUSTRALIA RESIDENTIAL LOOKUP...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.phoneNo = !isEmptyVAL(data.phoneNo) ? data.phoneNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.phoneNo)) {
      inputErrors.phoneNo = transUTL(
        'translateFormOverview.formErrors.phoneNo'
      );
    }
  }

  /* ============================================
            AUSTRALIA VISA
   ============================================ */
  if (dataSources.includes('Australia Visa')) {
    loggerUTL('VALIDATE AUSTRALIA VISA...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : '';
    data.passportIssuerCountry = !isEmptyVAL(data.passportIssuerCountry)
      ? data.passportIssuerCountry
      : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.passportNo)) {
      inputErrors.passportNo = transUTL(
        'translateFormOverview.formErrors.passportNo'
      );
    }
    if (isEmptyVAL(data.passportIssuerCountry)) {
      inputErrors.passportIssuerCountry = transUTL(
        'translateFormOverview.formErrors.passportIssuerCountry'
      );
    }
  }

  /* ============================================
            AUSTRALIA VISA ENTITLEMENT
   ============================================ */
  if (dataSources.includes('Australia Visa Entitlement')) {
    loggerUTL('VALIDATE AUSTRALIA VISA ENTITLEMENT...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.passportNo = !isEmptyVAL(data.passportNo) ? data.passportNo : '';
    data.passportIssuerCountry = !isEmptyVAL(data.passportIssuerCountry)
      ? data.passportIssuerCountry
      : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.passportNo)) {
      inputErrors.passportNo = transUTL(
        'translateFormOverview.formErrors.passportNo'
      );
    }
    if (isEmptyVAL(data.passportIssuerCountry)) {
      inputErrors.passportIssuerCountry = transUTL(
        'translateFormOverview.formErrors.passportIssuerCountry'
      );
    }
  }

  /* ============================================
           AUSTRALIA CREDIT BUREAU
  ============================================ */
  if (dataSources.includes('Australia Credit Bureau')) {
    loggerUTL('VALIDATE AUSTRALIA CREDIT BUREAU...');

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.streetNo = !isEmptyVAL(data.streetNo) ? data.streetNo : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.suburb = !isEmptyVAL(data.suburb) ? data.suburb : '';
    data.state = !isEmptyVAL(data.state) ? data.state : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.streetNo)) {
      inputErrors.streetNo = transUTL(
        'translateFormOverview.formErrors.streetNo'
      );
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.suburb)) {
      inputErrors.suburb = transUTL('translateFormOverview.formErrors.suburb');
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL('translateFormOverview.formErrors.state');
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
  }

  // /* ============================================
  //             WATCHLIST AML
  //  ============================================ */
  // if (dataSources['Watchlist AML']) {
  //   loggerUTL('VALIDATING WATCHLIST AML...');
  //   // CHECK IF CREDENTIALS IS NOT EMPTY
  //   data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
  //   data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
  //   data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';

  //   // ERROR FEEDBACK ACCUMULATOR
  //   if (isEmptyVAL(data.firstName)) {
  //     inputErrors.firstName = transUTL(
  //       'translateFormOverview.formErrors.firstName'
  //     );
  //   }
  //   if (isEmptyVAL(data.lastName)) {
  //     inputErrors.lastName = transUTL(
  //       'translateFormOverview.formErrors.lastName'
  //     );
  //   }
  //   if (isEmptyVAL(data.dateOfBirth)) {
  //     inputErrors.dateOfBirth = transUTL(
  //       'translateFormOverview.formErrors.dateOfBirth'
  //     );
  //   }
  // }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default australiaVAL;
