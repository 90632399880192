import React from 'react';
import PropTypes from 'prop-types';

const DisplayApiController = ({ inputForm }) => (
  <div className="form-overview__form-section">
    {inputForm}
  </div>
);

DisplayApiController.propTypes = {
  inputForm: PropTypes.object.isRequired,
};

export default DisplayApiController;
