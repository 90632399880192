import jsonTableRow from './rows';

// HELPERS
import title from '../../../../helpers/title';

// UTILS
import arrayTo2DArrayUTL from '../../../../../../utils/arrayTo2DArrayUTL';

const dataSourceVerificationResults = (doc, data, info, docStyles) => {
  const { Response } = data;

  // TITLE
  title(
    doc,
    docStyles,
    'Summary of Data Source Verifications and Results',
    docStyles.headers.secondary,
    docStyles.separation
  );

  // DEFAULT COLUMN
  const columns = [{ header: 'Data Sources', dataKey: 'col1' }];

  // DATA SOURCES RESULTS
  let visaEntitlement = Response.serviceResponses['Australia Visa Entitlement'];
  let residentialLookup =
    Response.serviceResponses['Australia Residential Lookup'];
  let visa =
    Response.serviceResponses['Australia Government Identity Documents 4'];
  let residential = Response.serviceResponses['Australia Residential'];
  let propertyOwnership =
    Response.serviceResponses['Australia Property Ownership'];
  let medicareCard =
    Response.serviceResponses['Australia Government Identity Documents 3'];
  let driverLicence =
    Response.serviceResponses['Australia Government Identity Documents 1'];
  let passport =
    Response.serviceResponses['Australia Government Identity Documents 2'];
  let centerlinkCard =
    Response.serviceResponses['Australia Government Identity Documents 5'];
  let birthCertificate =
    Response.serviceResponses['Australia Government Identity Documents 6'];
  let creditBureau = Response.serviceResponses['Australia Credit Bureau'];
  let citizens = Response.serviceResponses['Australia Citizens'];
  let payroll = Response.serviceResponses['Australia Payroll'];
  let superannuation = Response.serviceResponses['Australia Superannuation'];
  let deathCheck = Response.serviceResponses['Australia Death Check'];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  // AUSTRALIA VISA ENTITLEMENT
  if (visaEntitlement) {
    columns.push({
      header: 'Australia Visa Entitlement',
      dataKey: 'col2',
    });
  } else {
    visaEntitlement = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA RESIDENTIAL LOOKUP
  if (residentialLookup) {
    columns.push({
      header: 'Australia Residential Lookup',
      dataKey: 'col3',
    });
  } else {
    residentialLookup = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA VISA
  if (visa) {
    columns.push({
      header: 'Australia Visa',
      dataKey: 'col4',
    });
  } else {
    visa = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA RESIDENTIAL
  if (residential) {
    columns.push({
      header: 'Australia Residential',
      dataKey: 'col5',
    });
  } else {
    residential = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA PROPERTY OWNERSHIP
  if (propertyOwnership) {
    columns.push({
      header: 'Australia Property Ownership',
      dataKey: 'col6',
    });
  } else {
    propertyOwnership = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA MEDICARE CARD
  if (medicareCard) {
    columns.push({
      header: 'Australia Medicare Card',
      dataKey: 'col7',
    });
  } else {
    medicareCard = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA DRIVER LICENCE
  if (driverLicence) {
    columns.push({
      header: 'Australia Driver Licence',
      dataKey: 'col8',
    });
  } else {
    driverLicence = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA PASSPORT
  if (passport) {
    columns.push({
      header: 'Australia Passport',
      dataKey: 'col9',
    });
  } else {
    passport = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA CENTRELINK CARD
  if (centerlinkCard) {
    columns.push({
      header: 'Australia Centrelink Card',
      dataKey: 'col10',
    });
  } else {
    centerlinkCard = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA BIRTH CERTIFICATE
  if (birthCertificate) {
    columns.push({
      header: 'Australia Birth Certificate',
      dataKey: 'col11',
    });
  } else {
    birthCertificate = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA CREDIT BUREAU
  if (creditBureau) {
    columns.push({
      header: 'Australia Credit Bureau',
      dataKey: 'col12',
    });
  } else {
    creditBureau = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA Citizens
  if (citizens) {
    columns.push({
      header: 'Australia Citizens',
      dataKey: 'col14',
    });
  } else {
    citizens = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA Payroll
  if (payroll) {
    columns.push({
      header: 'Australia Payroll',
      dataKey: 'col15',
    });
  } else {
    payroll = { verifications: {}, returnedData: {} };
  }

  // AUSTRALIA Superannuation
  if (superannuation) {
    columns.push({
      header: 'Australia Superannuation',
      dataKey: 'col16',
    });
  } else {
    superannuation = { verifications: {}, returnedData: {} };
  }

  if (deathCheck) {
    columns.push({
      header: 'Australia Death Check',
      dataKey: 'col17',
    });
  } else {
    deathCheck = { verifications: {}, returnedData: {} };
  }

  // GLOBAL WATCHLIST AML
  if (watchlistAML) {
    columns.push({
      header: 'Global Watchlist AML',
      dataKey: 'col13',
    });
  } else {
    watchlistAML = { verifications: {}, returnedData: {} };
  }

  // TABLE ROW
  const rows = jsonTableRow({
    visaEntitlement,
    residentialLookup,
    visa,
    residential,
    propertyOwnership,
    medicareCard,
    driverLicence,
    passport,
    centerlinkCard,
    birthCertificate,
    creditBureau,
    citizens,
    payroll,
    superannuation,
    deathCheck,
    watchlistAML,
  });

  // TABLE
  let finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  const maxAmountOfColumns = 3;
  const firstColumn = columns.shift();
  const maxColArray = arrayTo2DArrayUTL(columns, maxAmountOfColumns); // 3 COLUMNS ONLY

  maxColArray.map((col, index) => {
    col.unshift(firstColumn);

    if (index > 0) {
      finalY = doc.previousAutoTable.finalY + docStyles.separation;
    }

    let cellWidth = docStyles.fullCellLength / 4;
    if (maxColArray[index].length === 4) {
      cellWidth = docStyles.fullCellLength / 4;
    } else if (maxColArray[index].length === 3) {
      cellWidth = docStyles.fullCellLength / 3;
    } else if (maxColArray[index].length === 2) {
      cellWidth = docStyles.fullCellLength / 2;
    }

    doc.autoTable({columns: col, body: rows,
      didParseCell: (data) => {
        const { cell } = data;
        cell.styles.fillColor = [255, 255, 255];
        if (cell.section.includes('body') && cell.raw.length > 30) {
          cell.styles.cellPadding = { top: 15, bottom: 15 };
        }
      },
      showHead: 'firstPage',
      styles: {
        textColor: docStyles.colorText,
        font: docStyles.fontFamily.time,
        ...docStyles.tableStyles,
      },
      headStyles: {
        fontSize: docStyles.textSize,
        textColor: docStyles.colorGreen,
        fontStyle: 'normal',
      },
      columnStyles: {
        col1: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
        col2: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
        col3: {
          fontStyle: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth,
        },
      },
      startY: finalY,
    });
    return null;
  });
};

export default dataSourceVerificationResults;
