const inputID = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',

  // RESIDENT IDENTITY CARD
  nik: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNameIDN: '',
  villageIDN: '',
  districtIDN: '',
  cityIDN: '',

  // TAX REGISTRATION
  npwp: '',

  // DRIVERS LICENCE
  driversLicenceNo: 'MH09 20080003369',

  // REFERENCE
  reportingReference: '',
};

export default inputID;
