import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import ResultCard from "../../../reusableComponent/ResultCard";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const UnitedStatesPhone = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};

  const translationSource = 'dataSourcesUS.phone'

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesUS.phone.title'
        )}
      />

      <div className="search-verification__verify">
        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.searchSuccessful'
              )}
            </p>
            <span className="material-icons success">check_circle</span>
          </div>
          <ResultCard
            translationKey='safeHarbourScore'
            result={safeHarbourScore}
          />
          <ResultCard
            translationKey='identityVerified'
            result={identityVerified}
          />
          <ResultCard
            translationKey='nameMatchScore'
            result={nameMatchScore}
          />
          <ResultCard
            translationKey='addressMatchScore'
            result={addressMatchScore}
          />
        </div>

        <div className="search-verification__col">
          <ResultCard
            translationKey='firstName'
            result={verifications.firstName}
          />
          <ResultCard
            translationKey='middleName'
            result={verifications.middleName}
          />
          <ResultCard
            translationKey='lastName'
            result={verifications.lastName}
          />
          <ResultCard
            translationKey='phoneNo'
            result={verifications.phoneNo}
          />
        </div>

        <div className="search-verification__col">
          <ResultCard
            translationKey = {`${translationSource}.addressElement1`}
            result={verifications.addressElement1}
          />
          <ResultCard
            translationKey={`${translationSource}.addressElement2`}
            result={verifications.addressElement2}
          />
          <ResultCard
            translationKey={`${translationSource}.addressElement3`}
            result={verifications.addressElement3}
          />
          <ResultCard
            translationKey={`${translationSource}.addressElement4`}
            result={verifications.addressElement4}
          />
          <ResultCard
            translationKey={`${translationSource}.addressElement5`}
            result={verifications.addressElement5}
          />
        </div>
      </div>
    </div>
  );
};

UnitedStatesPhone.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default UnitedStatesPhone;
