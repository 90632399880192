import store from '../../../../store/store';
import { saveSelectedLanguageAXN } from '../../../../actions/language/languageActions';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const languageSessionDataRestore = () => {
  loggerUTL('LANGUAGE SESSION DATA RESTORE...');
  if (sessionStorage.selectedLanguage) {
    loggerUTL(sessionStorage.selectedLanguage)
      store.dispatch(saveSelectedLanguageAXN(sessionStorage.selectedLanguage));
  }
};

export default languageSessionDataRestore;
