import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackCA = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);  
  const inputError = {
    personalInformation: {},
    currentResidentialAddress: {}
  };

  try {

    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name is ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name is ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'addressElement4')) {
      inputError.currentResidentialAddress.province = `Province is ${
        errors.find((item) => item.includes('addressElement4')).split(':')[1]
      }`;
    }
    
    if (findErrorUTL(errors, 'addressElement5')) {
      inputError.currentResidentialAddress.postcode = `Postcode is ${
        errors.find((item) => item.includes('addressElement5')).split(':')[1]
      }`;
    }
    
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE CANANDA VALIDATION ERRORS.....',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackCA;