// UTILITIES
import whitelistKeys from "../../../../../utils/whitelistKeysUTL";
import loggerUTL from "../../../../../utils/loggerUTL";

const whitelistedInputDataNZ = (inputData, dataSources) => {
  let companiesOfficeData,
    driverLicenceData,
    companyOfficeData,
    diaBirthData,
    diaCitizenshipData,
    linzPropertyData,
    vehicleData,
    residentialData,
    creditBureauData,
    watchlistAMLData;

  /* ============================================
            NEW ZEALAND DIA PASSPORT
   ============================================ */
  if (dataSources.includes("New Zealand DIA Passport")) {
    loggerUTL("WHITELISTING NEW ZEALAND DIA PASSPORT...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "passportNo",
      "passportExpiry",
      "reportingReference",
    ]);
    companiesOfficeData = { ...whitelisted };
  }

  /* ============================================
            NEW ZEALAND DRIVERS LICENCE
   ============================================ */
  if (dataSources.includes("New Zealand Driver Licence")) {
    loggerUTL("WHITELISTING NEW ZEALAND DRIVER LICENCE...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "driversLicenceNo",
      "driversLicenceVersion",
      "reportingReference",
    ]);
    driverLicenceData = { ...whitelisted };
  }

  /* ============================================
            NEW ZEALAND COMPANIES OFFICE
   ============================================ */
  if (dataSources.includes("New Zealand Companies Office")) {
    loggerUTL("WHITELISTING NEW ZEALAND COMPANIES OFFICE...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "phoneNo",
      "landlineNo",
      "reportingReference",
    ]);
    companyOfficeData = { ...whitelisted };
  }

  /* ============================================
            NEW ZEALAND DIA BIRTH
   ============================================ */
  if (dataSources.includes("New Zealand DIA Birth")) {
    loggerUTL("WHITELISTING NEW ZEALAND DIA BIRTH...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "reportingReference",
    ]);
    diaBirthData = { ...whitelisted };
  }
  /* ============================================
            NEW ZEALAND DIA BIRTH
   ============================================ */
  if (dataSources.includes("New Zealand DIA Citizenship")) {
    loggerUTL("WHITELISTING NEW ZEALAND DIA CITIZENSHIP...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "reportingReference",
    ]);
    diaCitizenshipData = { ...whitelisted };
  }

  /* ============================================
            New Zealand LINZ Property
   ============================================ */
  if (dataSources.includes("New Zealand LINZ Property")) {
    loggerUTL("WHITELISTING New Zealand LINZ PROPERTY...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "streetNo",
      "streetName",
      "suburb",
      "city",
      "postCode",
      "phoneNo",
      "landlineNo",
      "reportingReference",
    ]);
    linzPropertyData = { ...whitelisted };
  }

  /* ============================================
            NEW ZEALAND YELLOW PAGES
   ============================================ */
  if (dataSources.includes("New Zealand Yellow Pages")) {
    loggerUTL("WHITELISTING NEW ZEALAND YELLOW PAGES...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "streetNo",
      "streetName",
      "suburb",
      "city",
      "postCode",
      "reportingReference",
    ]);
    linzPropertyData = { ...whitelisted };
  }

  /* ============================================
            NEW ZEALAND RESIDENTIAL
   ============================================ */
  if (dataSources.includes("New Zealand Residential")) {
    loggerUTL("WHITELISTING NEW ZEALAND RESIDENTIAL...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "streetNo",
      "streetName",
      "suburb",
      "city",
      "postCode",
      "phoneNo",
      "landlineNo",
      "reportingReference",
    ]);
    residentialData = { ...whitelisted };
  }

  /* ============================================
          NEW ZEALAND CREDIT BUREAU
 ============================================ */
  if (dataSources.includes("New Zealand Credit Bureau")) {
    loggerUTL("WHITELISTING NEW ZEALAND RESIDENTIAL...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "streetNo",
      "streetName",
      "suburb",
      "city",
      "postCode",
      "reportingReference",
    ]);
    creditBureauData = { ...whitelisted };
  }

  /* ============================================
            WATCHLIST AML
   ============================================ */
  if (dataSources.includes("Watchlist AML")) {
    loggerUTL("WATCHLIST AML...");
    const whitelisted = whitelistKeys(inputData, [
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "gender",
      "reportingReference",
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...companiesOfficeData,
    ...driverLicenceData,
    ...companyOfficeData,
    ...diaBirthData,
    ...diaCitizenshipData,
    ...linzPropertyData,
    ...vehicleData,
    ...residentialData,
    ...creditBureauData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataNZ;
