// Set logged in user
import {
  SAVE_AUSTRALIA_DATA_SOURCES,
  SAVE_BRAZIL_DATA_SOURCES,
  SAVE_CHINA_DATA_SOURCES,
  SAVE_MALAYSIA_DATA_SOURCES,
  SAVE_NEW_ZEALAND_DATA_SOURCES,
  SAVE_PHILIPPINES_DATA_SOURCES,
  SAVE_INDIA_DATA_SOURCES,
  SAVE_INDONESIA_DATA_SOURCES,
  SAVE_CAMBODIA_DATA_SOURCES,
  SAVE_VIETNAM_DATA_SOURCES,
  SAVE_NIGERIA_DATA_SOURCES,
  SAVE_HONG_KONG_DATA_SOURCES,
  SAVE_FRANCE_DATA_SOURCES,
  SAVE_SINGAPORE_DATA_SOURCES,
  SAVE_UNITED_STATES_DATA_SOURCES,
  SAVE_MEXICO_DATA_SOURCES,
  SAVE_GERMANY_DATA_SOURCES,
  SAVE_ITALY_DATA_SOURCES,
  SAVE_SPAIN_DATA_SOURCES,
  SAVE_MULTI_SERVICE_DATA_SOURCE,
  SAVE_FIND_IDU_DATA_SOURCE,
  SAVE_DATA_SOURCE_SELECTIONS,
  RESET_DATA_SOURCES,
  INITIALISE_DATA_SOURCE_SELECTIONS
} from '../types';

import CACHE from '../../constants/cache';
import { FIND_IDU } from "../../constants/findIDU";

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';

export const initialiseGlobalDataSourceSelections = () => (dispatch) => {
  const { GLOBAL_DATA_SOURCE_SELECTIONS_CACHE } = CACHE;

  const globalDataSourceSelections = JSON.parse(sessionStorage.getItem(GLOBAL_DATA_SOURCE_SELECTIONS_CACHE));

  dispatch({
    type: INITIALISE_DATA_SOURCE_SELECTIONS,
    payload: globalDataSourceSelections
  });
}

export const saveDataSourceSelectionsAXN = (country, dataSources) => (dispatch) => {

  dispatch({
    type: SAVE_DATA_SOURCE_SELECTIONS,
    payload: {
      country,
      dataSources
    }
  });
}

// SAVE DATA SOURCE ACTION
export const saveDataSourcesAXN = (country, dataSources) => (dispatch) => {
  /* ============================================
                  AUSTRALIA
   ============================================ */
   
  if (country === 'australia') {
    loggerUTL('SAVING AUSTRALIA DATA SOURCES...');

    sessionStorage.setItem('australiaDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_AUSTRALIA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                   BRAZIL
   ============================================= */
  if (country === 'brazil') {
    loggerUTL('SAVING BRAZIL DATA SOURCES...');
    sessionStorage.setItem('brazilDataSources', JSON.stringify(dataSources));
    dispatch({
      type: SAVE_BRAZIL_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  CAMBODIA
   ============================================ */
  if (country === 'cambodia') {
    loggerUTL('SAVING CAMBODIA DATA SOURCES...');

    sessionStorage.setItem('cambodiaDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_CAMBODIA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  CHINA
   ============================================ */
  if (country === 'china') {
    loggerUTL('SAVING CHINA DATA SOURCES...');

    sessionStorage.setItem('chinaDataSources', JSON.stringify(dataSources));
    dispatch({
      type: SAVE_CHINA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  MALAYSIA
   ============================================ */
  if (country === 'malaysia') {
    loggerUTL('SAVING MALAYSIA DATA SOURCES...');

    sessionStorage.setItem('malaysiaDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_MALAYSIA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  if (country === 'newzealand') {
    loggerUTL('SAVING NEW ZEALAND DATA SOURCES...');

    sessionStorage.setItem(
      'newzealandDataSources',
      JSON.stringify(dataSources)
    );

    dispatch({
      type: SAVE_NEW_ZEALAND_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  PHILIPPINES
   ============================================ */
  if (country === 'philippines') {
    loggerUTL('SAVING PHILIPPINES DATA SOURCES...');

    sessionStorage.setItem(
      'philippinesDataSources',
      JSON.stringify(dataSources)
    );

    dispatch({
      type: SAVE_PHILIPPINES_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  INDIA
   ============================================ */
  if (country === 'india') {
    loggerUTL('SAVING INDIA DATA SOURCES...');

    sessionStorage.setItem('indiaDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_INDIA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  INDONESIA
   ============================================ */
  if (country === 'indonesia') {
    loggerUTL('SAVING INDONESIA DATA SOURCES...');

    sessionStorage.setItem('indonesiaDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_INDONESIA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  VIETNAM
   ============================================ */
  if (country === 'vietnam') {
    loggerUTL('SAVING VIETNAM DATA SOURCES...', dataSources);
    sessionStorage.setItem('vietnamDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_VIETNAM_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  Nigeria
   ============================================ */
  if (country === 'nigeria') {
    loggerUTL('SAVING NIGERIA DATA SOURCES...', dataSources);
    sessionStorage.setItem('nigeriaDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_NIGERIA_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 HONK KONG
  ============================================ */
  if (country === 'hongkong') {
    loggerUTL('SAVING VIETNAM DATA SOURCES...', dataSources);
    sessionStorage.setItem('hongkongDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_HONG_KONG_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                FRANCE
 ============================================ */
  if (country === 'france') {
    loggerUTL('SAVING FRANCE DATA SOURCES...', dataSources);
    sessionStorage.setItem('franceDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_FRANCE_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 SINGAPORE
  ============================================ */
  if (country === 'singapore') {
    loggerUTL('SAVING SINGAPORE DATA SOURCES...', dataSources);
    sessionStorage.setItem('singaporeDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_SINGAPORE_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 SINGAPORE
  ============================================ */
  if (country === 'unitedstates') {
    loggerUTL('SAVING SINGAPORE DATA SOURCES...', dataSources);
    sessionStorage.setItem('unitedstatesDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_UNITED_STATES_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 MEXICO
  ============================================ */
  if (country === 'mexico') {
    loggerUTL('SAVING MEXICO DATA SOURCES...', dataSources);
    sessionStorage.setItem('mexicoDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_MEXICO_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 GERMANY
  ============================================ */
  if (country === 'germany') {
    loggerUTL('SAVING GERMANY DATA SOURCES...', dataSources);
    sessionStorage.setItem('germanyDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_GERMANY_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 ITALY
  ============================================ */
  if (country === 'italy') {
    loggerUTL('SAVING ITALY DATA SOURCES...', dataSources);
    sessionStorage.setItem('italyDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_ITALY_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                 SPAIN
  ============================================ */
  if (country === 'spain') {
    loggerUTL('SAVING SPAIN DATA SOURCES...', dataSources);
    sessionStorage.setItem('spainDataSources', JSON.stringify(dataSources));

    dispatch({
      type: SAVE_SPAIN_DATA_SOURCES,
      payload: dataSources,
    });
  }

  /* ============================================
                  MULTI SERVICE
   ============================================ */
  if (country === 'multiService') {
    loggerUTL('SAVING MULTI SERVICE DATA SOURCES...', dataSources);

    sessionStorage.setItem(
      'multiServiceDataSources',
      JSON.stringify(dataSources)
    );

    dispatch({
      type: SAVE_MULTI_SERVICE_DATA_SOURCE,
      payload: dataSources,
    });
  }

  /* ============================================
                  FIND IDU
   ============================================ */
   if (country === FIND_IDU) {
    loggerUTL('SAVING FIND IDU DATA SOURCES...', dataSources);

    sessionStorage.setItem(
      'findIDUDataSources',
      JSON.stringify(dataSources)
    );

    dispatch({
      type: SAVE_FIND_IDU_DATA_SOURCE,
      payload: dataSources,
    });
  }
};

export const resetDataSourcesAXN = () => (dispatch) => {
  dispatch({
    type: RESET_DATA_SOURCES,
  });
};
