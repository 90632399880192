import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  history,
  to,
  handleOnSubmit,
  btn1Text,
  btn2Text,
  myClass,
}) => (
  <div className={`btn-container ${myClass}`}>
    <button className="btn-primary" onClick={() => history.push(to)}>
      {btn1Text}
    </button>

    <button className="btn-primary" onClick={handleOnSubmit}>
      {btn2Text}
    </button>
  </div>
);

Button.propTypes = {
  history: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  btn1Text: PropTypes.string.isRequired,
  btn2Text: PropTypes.string.isRequired,
};

export default Button;
