import * as Sentry from '@sentry/browser';

import { saveFormDataAXN } from '../../formData/formDataActions';
import { setAlertAXN } from '../../alert/alertActions';
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';

const inputMappingID = (data, dispatch) => {
  try {
    // MAP VARIABLES
    let fullName = '',
      firstName = '',
      middleName = '',
      lastName = '',
      dateOfBirth = '',
      gender = '',
      nik = '',
      streetName = '',
      village = '',
      district = '',
      city = '';

    if (!isEmptyVAL(data.fullName)) {
      fullName = data.fullName;

      const splitName = fullName.split(' ');
      firstName = splitName.length > 0 ? splitName[0] : '';
      lastName = splitName.length > 1 ? splitName[splitName.length - 1] : '';
      middleName = splitName.length > 2 ?
        splitName.slice(1, (splitName.length - 1)).join(' ')
        : '';
    }

    if (!isEmptyVAL(data.sex)) {
      const sex = data.sex.toLowerCase();
      if (sex === 'perempuan') {
        gender = 'Female';
      }
      if (sex === 'laki-laki') {
        gender = 'Male';
      }
      loggerUTL('DETECTED GENDER: ', gender);
    }

    if (
      !isEmptyVAL(data.dateOfBirth.day) &&
      !isEmptyVAL(data.dateOfBirth.month) &&
      !isEmptyVAL(data.dateOfBirth.year)
    ) {
      if (data.dateOfBirth.month < 10)
        data.dateOfBirth.month = `0${data.dateOfBirth.month}`;
      if (data.dateOfBirth.day < 10)
        data.dateOfBirth.day = `0${data.dateOfBirth.day}`;
      dateOfBirth = `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.day}`;
    }
    if (!isEmptyVAL(data.documentNumber)) {
      nik = data.documentNumber;
    }

    // Address Parsing
    if (data.address) streetName = data.address.replace('\n', ' ');
    if (data.additionalAddressInformation) {
      let splitAddress = data.additionalAddressInformation.split('\n');
      if (splitAddress.length > 1) {
        city = splitAddress[1].replace('KABUPATEN', '');
      }
    }

    // docEXP(summary, dispatch);

    // PDF SCANNED DATA TRIGGER
    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('indonesia', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      fullName,
      dateOfBirth,
      nik,
      streetName, // data.address
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      saveFormDataAXN(
        {
          fullName,
          firstName,
          middleName,
          lastName,
          dateOfBirth,
          gender,
          nik,
          streetName,
          village,
          district,
          city,
        },
        'indonesia'
      )
    );
  } catch (err) {
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException('Input mapping error...', data, err);
  }
};

export default inputMappingID;
