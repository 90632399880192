const dataSourcesSG = {
  creditBureau: {
    title: 'Biro Kredit Singapura',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    nationalIDNo: 'ID kebangsaan',
    name: 'Nama',
    address: 'Alamat',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    dateOfBirth: 'Tarikh lahir',
  },
  residential: {
    title: 'Utiliti Singapura',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    nationalIDNo: 'ID kebangsaan',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    city: 'Bandar',
    state: 'Nyatakan',
    streetNo: 'Nombor jalan',
    phoneNo: 'Nombor telefon',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    dateOfBirth: 'Tarikh lahir',
    streetAddress: 'Nama jalan',
  },
  utility: {
    title: 'Utiliti Singapura',
    searchSuccessful: 'Carian Berjaya',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    identityVerified: 'Disahkan',
    nationalIDNo: 'ID kebangsaan',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    city: 'Bandar',
    state: 'Nyatakan',
    streetNo: 'Nombor jalan',
    phoneNo: 'Nombor telefon',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    dateOfBirth: 'Tarikh lahir',
    streetAddress: 'Nama jalan',
  },
};

export default dataSourcesSG;
