import React from 'react';

export default () => {
  return (
    <div className="loading-backdrop">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

// export default () => {
//   return (
//     <div className="loading-backdrop">
//       <div class="multi-ripple">
//         <div></div>
//         <div></div>
//       </div>
//     </div>
//   );
// };
