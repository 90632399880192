// ACTION TYPES
import { SET_LANGUAGE } from '../types';

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';

export const saveSelectedLanguageAXN = (language) => (dispatch) => {
  loggerUTL(`SAVING SELECTED LANGUAGE: ${language}`);

  // SAVE LANGUAGE TO SESSION STORAGE
  sessionStorage.setItem('selectedLanguage', language);

  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};
