// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataPH = (data, dataSources, searchType) => {
  let residentialData;
  let watchlistAMLData;
  let suspiciousActivityData;
  let digitalIDSafeguardData;
  let digitalIdSafeguardData;
  let nationalIDData;

  /* ============================================
            PHILIPPINES CREDIT BUREAU
   ============================================ */
  if (dataSources.includes('Philippines Credit Bureau')) {
    loggerUTL('WHITELISTING PHILIPPINES Credit Bureau ...');
    const whitelisted = whitelistKeys(data, [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'nationalIDNo',
      'nationalIDType',
      'dateOfBirth',
      'streetNo',
      'streetName',
      'barangay',
      'city',
      'province',
      'postCode',
    ]);
    nationalIDData = {...whitelisted};
  }

  /* ============================================
            PHILIPPINES RESIDENTIAL
   ============================================ */
  if (dataSources.includes('Philippines Residential')) {
    loggerUTL('WHITELISTING PHILIPPINES NATIONAL ID...');
    const whitelisted = whitelistKeys(data, [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dateOfBirth',
      'streetNo',
      'streetName',
      'barangay',
      'city',
      'province',
      'postCode',
    ]);
    residentialData = { ...whitelisted };
  }

    /* ============================================
            PHILIPPINES SUSPICIOUS ACTIVITY
   ============================================ */
   if (dataSources.includes('Philippines Suspicious Activity')) {
    loggerUTL('WHITELISTING Philippines Suspicious Activity...');
    const whitelisted = whitelistKeys(data, [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dateOfBirth',
      'streetNo',
      'streetName',
      'barangay',
      'city',
      'province',
      'postCode',
      'yearOfBirth',
      'emailID',
      'orgId',
      'hitTarget'
    ]);
    suspiciousActivityData = { ...whitelisted };
  }

    /* ============================================
            PHILIPPINES DIGITAL ID SAFEGUARD
   ============================================ */
   if (dataSources.includes('Digital ID Safeguard')) {
    loggerUTL('WHITELISTING DIGITAL ID SAFEGUARD...');
    const whitelisted = whitelistKeys(data, [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dateOfBirth',
      'streetNo',
      'streetName',
      'barangay',
      'city',
      'province',
      'postCode',
      'yearOfBirth',
      'emailID',
      'orgId',
      'hitTarget'
    ]);
    digitalIDSafeguardData = { ...whitelisted };
  }

   /* ============================================
           DIGITAL ID SAFEGUARD
   ============================================ */
   if (dataSources.includes('Digital Id Safeguard')) {
    loggerUTL('WHITELISTING DIGITAL ID SAFEGUARD...');
    const whitelisted = whitelistKeys(data, [
      'email',
    ]);
    digitalIdSafeguardData = { ...whitelisted };
  }

  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(data, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
      'streetNo',
      'streetName',
      'barangay',
      'city',
      'province',
      'postCode',
      'yearOfBirth',
      'emailID',
      'orgId',
      'hitTarget'
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...nationalIDData,
    ...residentialData,
    ...suspiciousActivityData,
    ...digitalIDSafeguardData,
    ...digitalIdSafeguardData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataPH;
