import { SET_COUNTRY, RESET_COUNTRY } from '../../actions/types';

const initialState = '';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRY:
      return action.payload;
    case RESET_COUNTRY:
      return initialState;
    default:
      return state;
  }
}
