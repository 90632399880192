const dataSourcesFR = {
  franceResidential: {
    title: 'France Residential',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    email: 'Email Verified',
    phoneNo: 'Phone Number Verified',
    street: 'Street Verified',
    city: 'City Verified',
    postCode: 'Postcode Verified',
    addressMatchScore: 'Address Match Score',
    nameMatchScore: 'Name Match Score',
  },
  franceResidential2: {
    title: 'France Residential 2',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    emailAddress: 'Email Verified',
    phoneNo: 'Phone No. Verified',
    street: 'Street Verified',
    city: 'City Verified',
    state: 'State Verified',
    postCode: 'Postcode Verified',
  },
  franceResidential3: {
    title: 'France Residential 3',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    dateOfBirth: 'Date Of Birth Verified',
    emailAddress: 'Email Verified',
    phoneNo: 'Phone No. Verified',
    street: 'Street Verified',
    city: 'City Verified',
    postCode: 'Postcode Verified',
  }
};

export default dataSourcesFR;
