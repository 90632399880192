const inputBR = {
	firstName: '',
	middleName: '',
	lastName: '',
	dateOfBirth: '',
	gender: '',

	//ADDRESS
	unitNo: '',
	streetName: '',
	locality: '',
	district: '',
	postCode: '',

	// IDENTITY NUMBER
	nationalIdNo: '',
	phoneNo: '',

	// Tax Registration
	cpf: '',
}

export default inputBR;