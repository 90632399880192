import objRemoveEmpty from './objRemoveEmpty';
import isEmptyVAL from '../validations/checks/isEmptyVAL';

// STRUCTURES API BODY REQUEST
const jsonBuilder = (data) => {
  const reqBody = {
    countryCode: data.countryCode,
    service: data.service,
    clientReference: data.reportingReference,
    fullName: data.fullName, // SOME SERVICES REQUEST `fullName`
    firstName: data.firstName, // WHEREAS OTHERS REQUIRES `firstName` & `lastName`
    middleName: data.middleName,
    lastName: data.lastName,
    dateOfBirth: data.dateOfBirth,
    gender: data.gender,
    addressElement1: data.addressElement1,
    addressElement2: data.addressElement2,
    addressElement3: data.addressElement3,
    addressElement4: data.addressElement4,
    addressElement5: data.addressElement5,
    consentObtained: data.consentObtained,
    preferenceOverrides: data.preferenceOverrides,
  };

  const identityVariables = {
    yearOfBirth: data.yearOfBirth,
    emailID: data.emailID,
    email: data.email,
    orgId: (data.service[0] !== 'Philippines Suspicious Activity') ? data.orgId : "DzNoDOB", //DzNoDOB as a default for ORGANIZATION ID for FIND IDU
    hitTarget: data.hitTarget,
    
    ABN: data.ABN,
    
    nationalIDNo: data.nationalIDNo,
    nationalIDType: data.nationalIDType,

    nik: data.nik,
    npwp: data.npwp,

    epic: data.epic,
    pan: data.pan,
    aadhaarNo: data.aadhaarNo,

    passportNo: data.passportNo,
    passportExpiry: data.passportExpiry,
    fileNo: data.fileNo,
    dateOfIssue: data.dateOfIssue,

    birthRegistrationState: data.birthRegistrationState,
    birthRegistrationNo: data.birthRegistrationNo,
    birthCertificateNo: data.birthCertificateNo,
    birthRegistrationDate: data.birthRegistrationDate,

    centrelinkCardType: data.centrelinkCardType,
    centrelinkCardExpiry: data.centrelinkCardExpiry,
    centrelinkCustomerReferenceNo: data.centrelinkCustomerReferenceNo,

    driversLicenceVersion: data.driversLicenceVersion, 
    driversLicenceCardNo: data.driversLicenceCardNo,
    driversLicenceNumber: data.driversLicenceNumber, // SAME (INTENTIONAL)
    driversLicenceNo: data.driversLicenceNo, // SAME (INTENTIONAL)
    driversLicenceStateOfIssue: data.driversLicenceStateOfIssue,
    driversLicenceExpiryDate: data.driversLicenceExpiryDate,

    medicareCardNo: data.medicareCardNo,
    medicareCardType: data.medicareCardType,
    medicareReferenceNo: Number(data.medicareReferenceNo),
    medicareExpiry: isEmptyVAL(data.medicareExpiry) ? data.medicareExpiry : data.medicareExpiry.length > 7 ? data.medicareExpiry : data.medicareExpiry + "-01", 


    idCardNo: data.idCardNo,
    nationalIdNo: data.nationalIdNo,
    bankVerificationNo: data.bankVerificationNo,
    voterNo: data.voterNo,
    bankCardNo: data.bankCardNo,
    curp: data.curp,
    rfc: data.rfc,
    socialSecurityNo: data.socialSecurityNo,
    cpf: data.cpf,

    phoneNo: data.phoneNo, // SAME (INTENTIONAL)
    phoneNumber: data.phoneNo, // SAME (INTENTIONAL)

    landlineNo: data.landlineNo,

    emailAddress: data.emailAddress,

    passportIssuerCountry: data.passportIssuerCountry, // SAME (INTENTIONAL)
    passportCountry: data.passportIssuerCountry, // SAME (INTENTIONAL)
  };

  // FILTERS EMPTY VARIABLES FROM REQUEST
  const reqBodyCleaned = objRemoveEmpty(reqBody);
  const identityVariablesCleaned = objRemoveEmpty(identityVariables);

  return {
    ...reqBodyCleaned,
    identityVariables: { ...identityVariablesCleaned },
  };
};

export default jsonBuilder;
