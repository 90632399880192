const generatePDF = (doc, data, browserWindow) => {
  // SET TITLE
  doc.setProperties({
    title: data.reportingReference,
  });
  // OPEN THE PDF IN A WINDOW TO VIEW THE PDF OUTPUT...

  // doc.output(
  //   'datauristring'
  // );
  /*
                                ! IMPORTANT: NEEDS TO BE CALLED TWICE FOR SOME REASON... for the added custom font to to work when compression is applied...
                                ! TEMPORARY SOLUTION... MOST LIKELY NOT GUARANTEED TO WORK
                              */
  const string = doc.output('datauristring');
  const iframe =
    "<iframe width='100%' height='100%' src='" + string + "'></iframe>";

  browserWindow.document.title = 'Data Zoo PDF'; // TODO: RENAME NOT WORKING
  browserWindow.document.open();
  browserWindow.document.write(iframe);
  browserWindow.document.close();
  doc.save(`${data.Response.reportingReference}.pdf`);
};

export default generatePDF;
