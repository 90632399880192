/* THIS IS A UNIVERSAL DATE FORMAT FUNCTION
 * DIFFERENT KEYBOARD METHODS, SUCH AS UNITED STATES HAS THEIRS AS MM-DD-YYYY
 * THIS FUNCTION CONVERTS ALL DATE FORMATS TO YYYY-MM-DD BEFORE SUBMITTING TO API
 */
const formatDateUTL = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export default formatDateUTL;
