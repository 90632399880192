import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const cambodiaVAL = (data, dataSource) => {
  const inputErrors = {};

  /* ============================================
              CAMBODIA NATIONAL ID
   ============================================ */
  if (dataSource.includes('Cambodia National ID')) {
    loggerUTL('VALIDATING CAMBODIA National ID...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.voterNo = !isEmptyVAL(data.voterNo) ? data.voterNo : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }

    if (isEmptyVAL(data.voterNo)) {
      inputErrors.voterNo = transUTL(
        'translateFormOverview.formErrors.voterNo'
      );
    }
  }

  /* ============================================
                  WATCHLIST AML
   ============================================ */
  if (dataSource.includes('Watchlist AML')) {
    loggerUTL('VALIDATING WATCHLIST AML...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
  }

  /* ============================================
                  CLEAR ERRORS
   ============================================ */

  if (!isEmptyVAL(data.firstName)) {
    delete inputErrors.firstName;
  }
  if (!isEmptyVAL(data.lastName)) {
    delete inputErrors.lastName;
  }
  if (!isEmptyVAL(data.dateOfBirth)) {
    delete inputErrors.dateOfBirth;
  }
  if (!isEmptyVAL(data.gender)) {
    delete inputErrors.gender;
  }
  if (!isEmptyVAL(data.voterNo)) {
    delete inputErrors.voterNo;
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default cambodiaVAL;
