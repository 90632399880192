const dataSourcesHK = {
  creditBureau: {
    title: '香港信贷局',
    searchSuccessful: '搜索成功',
    nationalIDNo: '国民身份证',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    street: '街',
    district: '区',
    city: '市',
    state: '州',
    postCode: '邮编',
  },
  residential: {
    title: '香港住宅',
    searchSuccessful: '搜索成功',
    nationalIDNo: '国民身份证',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    street: '街',
    district: '区',
    city: '市',
    state: '州',
    postCode: '邮编',
  },
};

export default dataSourcesHK;
