// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { nationalID, bankVerification, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(nationalID.status),
      col3: naUTL(bankVerification.status),
      col4: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(nationalID.safeHarbourScore),
      col3: naUTL(bankVerification.safeHarbourScore),
      col4: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(nationalID.identityVerified),
      col3: naUTL(bankVerification.identityVerified),
      col4: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'National ID Verified',
      col2: naUTL(nationalID.verifications.nationalIdNo),
      col3: naUTL(bankVerification.verifications.nationalIdNo),
      col4: naUTL(watchlistAML.verifications.nationalIdNo),
    },
    {
      col1: 'Bank Verification Number Verified',
      col2: naUTL(nationalID.verifications.bankVerificationNo),
      col3: naUTL(bankVerification.verifications.bankVerificationNo),
      col4: naUTL(watchlistAML.verifications.bankVerificationNo),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(nationalID.verifications.firstName),
      col3: naUTL(bankVerification.verifications.firstName),
      col4: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(nationalID.verifications.middleName),
      col3: naUTL(bankVerification.verifications.middleName),
      col4: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(nationalID.verifications.last),
      col3: naUTL(bankVerification.verifications.last),
      col4: naUTL(watchlistAML.verifications.last),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(nationalID.nameMatchScore),
      col3: naUTL(bankVerification.nameMatchScore),
      col4: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(nationalID.verifications.dateOfBirth),
      col3: naUTL(bankVerification.verifications.dateOfBirth),
      col4: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(nationalID.verifications.gender),
      col3: naUTL(bankVerification.verifications.gender),
      col4: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Phone Number Verified',
      col2: naUTL(nationalID.verifications.phoneNo),
      col3: naUTL(bankVerification.verifications.phoneNo),
      col4: naUTL(watchlistAML.verifications.phoneNo),
    },
    {
      col1: 'Email Address Verified',
      col2: naUTL(nationalID.verifications.emailAddress),
      col3: naUTL(bankVerification.verifications.emailAddress),
      col4: naUTL(watchlistAML.verifications.emailAddress),
    },
    {
      col1: 'Street Verified',
      col2: naUTL(nationalID.verifications.streetAddress),
      col3: naUTL(bankVerification.verifications.streetAddress),
      col4: naUTL(watchlistAML.verifications.streetAddress),
    },
    {
      col1: 'Town Verified',
      col2: naUTL(nationalID.verifications.town),
      col3: naUTL(bankVerification.verifications.town),
      col4: naUTL(watchlistAML.verifications.town),
    },
    {
      col1: 'LGA Verified',
      col2: naUTL(nationalID.verifications.lga),
      col3: naUTL(bankVerification.verifications.lga),
      col4: naUTL(watchlistAML.verifications.lga),
    },
    {
      col1: 'State Verified',
      col2: naUTL(nationalID.verifications.state),
      col3: naUTL(bankVerification.verifications.state),
      col4: naUTL(watchlistAML.verifications.state),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(nationalID.addressMatchScore),
      col3: naUTL(bankVerification.addressMatchScore),
      col4: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'Error Message',
      col2: naUTL(nationalID.errorMessage),
      col3: naUTL(bankVerification.errorMessage),
      col4: naUTL(watchlistAML.errorMessage),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
