import dataSourcesAU from './searchResults/dataSourcesAU'; // AUSTRALIA
import dataSourcesBR from './searchResults/dataSourcesBR'; // BRAZIL
import dataSourcesCN from './searchResults/dataSourcesCN'; // CHINA
import dataSourcesIN from './searchResults/dataSourcesIN'; // INDIA
import dataSourcesID from './searchResults/dataSourcesID'; // INDONESIA
import dataSourcesMY from './searchResults/dataSourcesMY'; // MALAYSIA
import dataSourcesNZ from './searchResults/dataSourcesNZ'; // NEW ZEALAND
import dataSourcesPH from './searchResults/dataSourcesPH'; // PHILIPPINES
import dataSourcesKH from './searchResults/dataSourcesKH'; // CAMBODIA
import dataSourcesVN from './searchResults/dataSourcesVN'; // VIETNAM
import dataSourcesSG from './searchResults/dataSourcesSG'; // SINGAPORE
import dataSourcesUS from './searchResults/dataSourcesUS'; // UNITED STATES
import dataSourcesNG from './searchResults/dataSourcesNG'; // NIGERIA
import dataSourcesHK from './searchResults/dataSourcesHK'; // HONG KONG
import dataSourcesMX from './searchResults/dataSourcesMX'; // MEXICO
import dataSourcesDE from './searchResults/dataSourcesDE'; // GERMANY
import dataSourcesFR from './searchResults/dataSourcesFR'; // FRANCE
import dataSourcesIT from './searchResults/dataSourcesIT'; // ITALY
import dataSourcesES from './searchResults/dataSourcesES'; // SPAIN
import watchlistAML from './searchResults/watchlistAML'; // WATCHLIST AML


const translateSearchVerification = {
  /* ============================================
          BASE SEARCH VERIFICATION
   ============================================ */
  title: '数据动物园Eido验证摘要',
  inputData: '输入数据：',
  reportingReference: '报告参考: ',
  issueDateTime: '发行日期和时间：',
  watchlistStatusText: '国际PEP监视列表状态：',
  watchlistStatusClear: '明确',
  watchlistStatusFOUND: '发现',
  watchlistStatusFOUND2: '发现（白名单）',
  safeHarbour: '安全港：',
  matchStatus: '比赛状态：',
  errorMessage: '错误信息',
  apiReqAndRes: 'IDU API 请求和响应: ',

  /* ============================================
                  Template
  ============================================ */
  searchSuccessful: '搜索成功',
  safeHarbourScore: '安全港评分',
  nameMatchScore: '名字匹配分数',
  addressMatchScore: '地址匹配分数',
  identityVerified: '身份已验证',
  category: '类别',
  pdfLink: 'Pdf 链接',

  // personal info
  otherNames: '其他名字',
  firstName: '名',
  middleName: '中间名',
  lastName: '姓',
  fullName: '姓名',
  gender: '性别',
  dateOfBirth: '出生日期',
  email: '电子邮件',
  phoneNo: '电话号码',
  mobileNo: '手机号码',
  landlineNo: '座机号码',
  
  // address
  addressLine1: '地址栏1', 
  street: '街道',
  streetNo: '门牌号',
  streetName: '街道名',
  streetNumberAndName: '街道号和名称',
  streetNameAndHouseNo: '街道名称和门牌号',
  suburb: "市郊",
  county: "县",
  city: '城市',
  state: '州',
  province: '省', 
  postCode: '邮政编码',
  postcode: '邮政编码',
  zipCode: '邮政编码',
  regionCode: '地区代码', // ITALY, CZECH
  houseNumber: '房屋编号', // ITALY, CZECH

  // documents
  nationalIDNo: '身份证号码已验证',
  nationalIdNo: '身份证号码已验证',
  taxIDNo: '税号',
  LicenceNo: '驾驶执照号',
  driversLicenceNo: '驾驶执照号',
  documentNumber: '文档号',
  documentNo: '文档号',
  idCardNo: '身份证号',
  passportNo: '护照号',
  fileNo: '文件号',
  dateOfIssue: '发行日期',
  nik: 'NIK',

  /* ============================================
                  DATA SOURCES
   ============================================ */
  dataSourcesAU,
  dataSourcesBR,
  dataSourcesCN,
  dataSourcesIN,
  dataSourcesID,
  dataSourcesMY,
  dataSourcesNZ,
  dataSourcesPH,
  dataSourcesKH,
  dataSourcesVN,
  dataSourcesSG,
  dataSourcesNG,
  dataSourcesHK,
  dataSourcesUS,
  dataSourcesMX,
  dataSourcesDE,
  dataSourcesFR,
  dataSourcesIT,
  dataSourcesES,
  watchlistAML,
};

export default translateSearchVerification;
