// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataES = (inputData, dataSources) => {
  let spainData, watchlistData;


  // Both services have the same request structure. (This may change in the future)
  /* ============================================
          SPAIN RESIDENTIAL & TELCO 
   ============================================ */
  if (dataSources.includes('Spain Residential') ||
    dataSources.includes('Spain Telco')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'streetAddress',
      'city',
      'state',
      'postCode',
      'phoneNo',
      'reportingReference',
    ]);

    spainData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...spainData,
    ...watchlistData,
  };
};
export default whitelistedInputDataES;
