import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressItaly = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    streetAddress,
    city,
    state,
    postCode,
    address,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: [
        'Italy Residential',
        'Italy Telco Mobile',
      ],
      element: (
        <TextFieldGroup
          key="streetAddress"
          id="streetAddress"
          type="text"
          name="streetAddress"
          dataName="streetNameIT"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.street')}
          value={streetAddress}
          placeholder={transUTL('translatePlaceholder.inputIT.streetAddress')}
          placeholderTrigger={isEmptyVAL(streetAddress) ? 'input-empty' : ''}
          error={errors.streetAddress}
          errorTrigger={errors.streetAddress ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Italy Residential',
        'Italy Telco Mobile',
      ],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityIT"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.city')}
          value={city}
          placeholder={transUTL('translatePlaceholder.inputIT.city')}
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Italy Residential',
        'Italy Telco Mobile',
      ],
      element: (
        <TextFieldGroup
          key="state"
          id="state"
          type="text"
          name="state"
          dataName="stateIT"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholder={transUTL('translatePlaceholder.inputIT.state')}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Italy Residential',
        'Italy Telco Mobile',
      ],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeIT"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputIT.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {!isEmptyVAL(address) ? (
        <TextFieldGroup
          key="address"
          id="address"
          type="text"
          name="address"
          dataName="addressIT"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.addressOCRVN')}
          value={address}
          placeholder={transUTL('translatePlaceholder.inputVN.address')}
          placeholderTrigger={isEmptyVAL(address) ? 'input-empty' : ''}
          disabled={true}
          error={errors.address}
          errorTrigger={errors.address ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ) : (
          <Fragment>
            {outputMasterUTL(selectedDataSources, inputElements)}
            <span />
          </Fragment>
        )}
    </div>
  );
};

CurrentResidentialAddressItaly.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressItaly;
