const initialStateNZ = {
  // PERSONAL INFORMATION
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '',
  suburb: '',
  city: '',
  postCode: '',

  // GOOGLE ADDRESS AUTO COMPLETE
  isGoogle: true,
  isGoogleParsed: true,
  addressUnparsed: '',

  // DRIVERS LICENCE
  driversLicenceNo: '',
  driversLicenceVersion: '',
  vehiclePlate: '',

  // BIRTH CERTIFICATE
  birthCertificateNo: '',
  placeOfBirth: '',
  mothersFirstName: '',
  mothersLastName: '',
  fathersFirstName: '',
  fathersLastName: '',

  // CITIZENSHIP
  countryOfBirth: '',
  citizenshipCertificateNo: '',

  // PASSPORT
  passportNo: '',
  passportExpiry: '',

  // CONTACT DETAILS
  phoneNo: '',
  landlineNo: '',

  // TERMS AND CONDITIONS
  diaPassportConsentObtained: false,
  driverLicenceConsentObtained: false,
  diaBirthConsentObtained: false,
  creditBureauConsentObtained: false,

  // REPORTING REFERENCE
  reportingReference: '',

  // DISPLAY API
  displayApiResultObtained: false,

  // ERROR HANDLING
  errors: {},
};

export default initialStateNZ;
