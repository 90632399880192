import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';
import { saveUserInputAXN } from '../../../../actions/formData/userInputActions';
import { saveApiReqAXN } from '../../../../actions/formData/apiReqActions';

// HELPERS
import initialStateCN from './helpers/initialStateCN';
import consentCheckerCN from './helpers/consentCheckerCN';
import whitelistedInputDataCN from './helpers/whitelistedInputDataCN';
import scanDataEditedTrigger from '../../../../actions/biometrics/helpers/scanDataEditedTrigger';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import chinaVAL from '../../../../validations/inputFields/chinaVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';
import DisplayApiController from '../../../../components/formController/DisplayApiController';

// FORM INPUTS
import PersonalInformationChina from './inputForms/PersonalInformationChina';
import SearchChina from './inputForms/SearchChina';
import TermsAndConditionsChina from './inputForms/TermsAndConditionsChina';
import ClientReference from '../../reusableForms/ClientReference';
import ApiResult from '../../reusableForms/ApiResult';

// BUTTON
import Button from '../../../../components/btn/Button';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import noSpaceUTL from '../../../../utils/noSpaceUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL";

const ChinaForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateCN);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.chinaDataSources
  );

  // LOAD FORM DATA
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.chinaFormData,

      chinaCrimeSearch: 'N',

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.chinaFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // TOGGLE IS DOT IN FULL NAME CHECKBOX
  const handleToggleDotInNameCheckbox = () => {

    const { isDotInFullName } = userInputSTH;
    setUserInputSTH({
      ...userInputSTH,
      isDotInFullName: !isDotInFullName
    })
  }

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = chinaVAL(inputData, selectedDataSources);
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // USER CONSENT CHECK
    const { isConsent, consentObtained } = consentCheckerCN(
      selectedDataSources,
      inputData
    );

    loggerUTL(isConsent, consentObtained);

    // PROVIDE ERROR FEEDBACK IF CONSENT IS NOT CHECKED
    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataCN(inputData, selectedDataSources);

    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // CHINA PHONE
    if (!isEmptyVAL(whitelisted.phoneNo)) {
      const body = whitelisted.phoneNo.slice(2, whitelisted.phoneNo.length);
      whitelisted.phoneNo = body;
    }

    // WATCHLIST AML REQUIRES `firstName` & `lastName`
    // WHERE AS THE OTHER VIETNAM SERVICES REQUIRES `fullName`
    let names = `${inputData.lastName}${inputData.firstName}`.trim();
    if (userInputSTH.isDotInFullName) {
      const fullName = inputData.lastName + '·' + inputData.firstName;
      names = fullName.trim()
    };
    const fullName = noSpaceUTL(names);

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha3,
      service,
      consentObtained,
      fullName,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    saveApiReqAXN(submitData, countryRXS);
    searchVerificationAXN(submitData, history);
    saveUserInputAXN(inputData.displayApiResultObtained, countryRXS);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  const handleChinaPhone = (phoneNo) =>
    setUserInputSTH({
      ...userInputSTH,
      phoneNo,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const { errors,
      chinaCrimeSearch,
      idVerificationConsentObtained,
      nationalPhoneConsentObtained,
      bankCardConsentObtained,
      passportVerificationConsentObtained,
      driversLicenseConsentObtained,
      displayApiResultObtained,
      ...filteredFormData } = userInputSTH;

    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));

    // Check if scanned data from an ID doc is edited 
    loggerUTL('CHECKING IF SCANNED DATA IS EDITED...');
    let isEdited = false;
    let changedData = [];
    const { ocrResponseData } = biometricsRXS;
    const identityDataKeys = Object.keys(ocrResponseData);
    identityDataKeys.forEach(key => {
      if (filteredFormData[key] !== ocrResponseData[key]) {
        changedData.push({ [key]: filteredFormData[key] })
      }
      if (changedData.length > 0) {
        isEdited = true
      } else {
        isEdited = false
      }
    })

    scanDataEditedTrigger('china', isEdited);

    // Reset fullName to nothing, so it doesn't effect the edited values
    // of first and last name.
    // A better solution is probably possible for this.
    filteredFormData.fullName = '';

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');
    const {
      errors,

      bankCardConsentObtained,
      nationalIDConsentObtained,
      nationalIDAndChinaCrimeConsentObtained,
      nationalPhoneConsentObtained,
      passportVerificationConsentObtained,
      driversLicenseConsentObtained,
      displayApiResultObtained,

      isDotInFullName,

      ...reset
    } = initialStateCN;
    setUserInputSTH(initialStateCN);
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    loggerUTL('CHECKBOX...');
    const {
      idVerificationConsentObtained,
      nationalPhoneConsentObtained,
      bankCardConsentObtained,
      passportVerificationConsentObtained,
      driversLicenseConsentObtained,
    } = userInputSTH;

    let idVerificationConsent = false,
      nationalPhoneConsent = false,
      bankCardConsent = false,
      passportVerificationConsent = false,
      driversLicenseConsent = false;

    if (selectedDataSources.includes('China ID Verification')) {
      idVerificationConsent = !idVerificationConsentObtained;
    }
    if (selectedDataSources.includes('China Phone')) {
      nationalPhoneConsent = !nationalPhoneConsentObtained;
    }
    if (selectedDataSources.includes('China Bank Card')) {
      bankCardConsent = !bankCardConsentObtained;
    }
    if (selectedDataSources.includes('China Passport Verification')) {
      passportVerificationConsent = !passportVerificationConsentObtained;
    }
    if (selectedDataSources.includes('China Driver License')) {
      driversLicenseConsent = !driversLicenseConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      idVerificationConsentObtained: idVerificationConsent,
      nationalPhoneConsentObtained: nationalPhoneConsent,
      bankCardConsentObtained: bankCardConsent,
      passportVerificationConsentObtained: passportVerificationConsent,
      driversLicenseConsentObtained: driversLicenseConsent
    });
  };

  const handleToggleTickbox = (opt) => {
    loggerUTL('TICKBOX...');
    const {
      displayApiResultObtained,
    } = userInputSTH;

    let displayApiResult = false;

    displayApiResult = !displayApiResultObtained;

    setUserInputSTH({
      ...userInputSTH,
      displayApiResultObtained: displayApiResult,
    });
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
          };
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded selfie 
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (
    selectedDataSources.includes('China ID Verification') ||
    selectedDataSources.includes('China Bank Card') ||
    selectedDataSources.includes('China Phone Alt') ||
    selectedDataSources.includes('China Phone') ||
    selectedDataSources.includes('China Passport Verification') ||
    selectedDataSources.includes('China Driver License')
  ) {
  } else {
    conditionalClientRef.push(...['Watchlist AML']);
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'China ID Verification',
        'China Phone Alt',
        'China Phone',
        'Watchlist AML',
        'China Bank Card',
        'China Passport Verification',
        'China Driver License'
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationChina
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
              handleToggleDotInNameCheckbox={handleToggleDotInNameCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'China ID Verification',
        // 'China Phone Alt',
        'China Bank Card',
        'China Phone',
        'China Passport Verification',
        'China Driver License'
      ],
      element: (
        <FormController
          key="chineseSearch"
          title={transUTL('translateFormOverview.formHeaders.chineseSearch')}
          inputForm={
            <SearchChina
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleChinaPhone={handleChinaPhone}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'China ID Verification',
        'China Phone',
        'China Phone Alt',
        'China Bank Card',
        'China Passport Verification',
        'China Driver License'
      ],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            'translateFormOverview.formHeaders.termsAndConditions'
          )}
          inputForm={
            <TermsAndConditionsChina
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'China ID Verification',
        'China Phone Alt',
        'China Phone',
        'Watchlist AML',
        'China Bank Card',
        'China Passport Verification',
        'China Driver License'
      ],
      element: (
        <DisplayApiController
          key="displayApi"
          inputForm={ 
            <ApiResult
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleTickbox={handleToggleTickbox}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

ChinaForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  saveUserInputAXN: PropTypes.func.isRequired,
  saveApiReqAXN: PropTypes.func.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  apiReqRXS: state.apiReqRXS,
  biometricsRXS: state.biometricsRXS
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
})(withRouter(ChinaForms));
