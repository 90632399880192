import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressUnitedStates = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    streetNo,
    streetName,
    suburb,
    state,
    postCode,

    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['United States Phone', 'United States Residential'],
      element: (
        <TextFieldGroup
          key="streetNo"
          id="streetNo"
          type="text"
          name="streetNo"
          dataName="streetNoUS"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetNo')}
          value={streetNo}
          placeholder={transUTL('translatePlaceholder.inputUS.streetNo')}
          placeholderTrigger={isEmptyVAL(streetNo) ? 'input-empty' : ''}
          error={errors.streetNo}
          errorTrigger={errors.streetNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['United States Phone', 'United States Residential'],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameUS"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetName')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputUS.streetName')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['United States Phone', 'United States Residential'],
      element: (
        <TextFieldGroup
          key="suburb"
          id="suburb"
          type="text"
          name="suburb"
          dataName="suburbUS"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.suburb')}
          value={suburb}
          placeholder={transUTL('translatePlaceholder.inputUS.suburb')}
          placeholderTrigger={isEmptyVAL(suburb) ? 'input-empty' : ''}
          error={errors.suburb}
          errorTrigger={errors.suburb ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['United States Phone', 'United States Residential'],
      element: (
        <TextFieldGroup
          key="state"
          id="state"
          type="text"
          name="state"
          dataName="stateUS"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholder={transUTL('translatePlaceholder.inputUS.state')}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['United States Phone', 'United States Residential'],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeUS"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputUS.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CurrentResidentialAddressUnitedStates.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressUnitedStates;
