// AXIOS INSTANCE
import { dzAPI } from '../api/init';

// SET AUTH TOKEN TO ALL REQUEST
const setAuthTokenUTL = (token) => {
  /*
   * API: KMS ROUTES <||> TESTAPI: DATA ZOO ROUTES
   * APPLY TO EVERY REQUEST
   */

  if (token) {
    // DATA ZOO HEADERS
    dzAPI.defaults.headers['Authorization'] = token.dz.sessionToken;
  } else {
    // DELETE AUTH HEADERS
    delete dzAPI.defaults.headers['Authorization'];
  }
};

export default setAuthTokenUTL;
