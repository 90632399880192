import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { retrieveOrganisationsAXN } from '../../../actions/admin/adminActions';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

import { getMuiTheme } from './tableSettings/tableTheme';
import CustomFooter from './tableSettings/CustomFooter';

const OrganisationTable = ({ adminRXS, retrieveOrganisationsAXN, history }) => {
  const [organisations, setOrganisations] = useState([
    ...adminRXS.organisations,
  ]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    retrieveOrganisationsAXN();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOrganisations([...adminRXS.organisations]);
  }, [adminRXS.organisations]);

  useEffect(() => {
    setTableData(
      organisations.map((org) => {
        return [
          isEmptyVAL(org.ID) ? 'N/A' : org.ID,
          isEmptyVAL(org.Name) ? 'N/A' : org.Name,
          isEmptyVAL(org.Email) ? 'N/A' : org.Email,
          isEmptyVAL(org.Address) ? 'N/A' : org.Address,
          isEmptyVAL(org.Country) ? 'N/A' : org.Country,
        ];
      })
    );
  }, [organisations]);

  const handleSelectOrganisation = (orgId, orgName) => {
    history.push({
      pathname: '/admin/users/' + orgId,
      state: { orgName },
    });
  };

  // Table configuration
  const columns = ['ID', 'Organisation Name', 'Email', 'Address', 'Country'];

  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    responsive: 'vertical',
    fixedHeader: false,
    onRowClick: (rowData) => {
      handleSelectOrganisation(rowData[0], rowData[1]);
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          history={history}
        />
      );
    },
  };

  return (
    <div className="table">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={'Organisations'}
          data={tableData}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

OrganisationTable.propTypes = {
  adminRXS: PropTypes.object.isRequired,
  retrieveOrganisationsAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  adminRXS: state.adminRXS,
});

export default connect(mapStateToProps, {
  retrieveOrganisationsAXN,
})(OrganisationTable);
