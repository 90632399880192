// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataIT = (inputData, dataSources) => {
  let italyData, watchlistData;


  // Both services have the same request structure. (This may change in the future)
  /* ============================================
          ITALY RESIDENTIAL & TELCO MOBILE 
   ============================================ */
  if (dataSources.includes('Italy Residential') ||
    dataSources.includes('Italy Telco Mobile')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'streetAddress',
      'city',
      'state',
      'postCode',
      'phoneNo',
      'reportingReference',
    ]);

    italyData = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...italyData,
    ...watchlistData,
  };
};
export default whitelistedInputDataIT;
