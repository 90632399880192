import * as Sentry from "@sentry/browser";

import { saveFormDataAXN } from "../../formData/formDataActions";
import { setAlertAXN } from "../../alert/alertActions";
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from "../helpers/scanDataEditedTrigger";
import isEmptyVAL from "../../../validations/checks/isEmptyVAL";
import { transUTL } from "../../../utils/transUTL";
import loggerUTL from "../../../utils/loggerUTL";

const inputMappingAU = (data, dispatch) => {
  try {
    // INPUT MAPPING FIELDS
    let firstName = "",
      middleName = "",
      lastName = "",
      dateOfBirth = "",
      gender = "",
      passportNo = "",
      passportExpiryDate = "",
      driversLicenceNo = "",
      driversLicenceCardNo = "",
      driversLicenceExpiryDate = "",
      driversLicenceStateOfIssue = "",
      medicareCardNo = "",
      medicareCardType = "",
      medicareIndividualRefNo = "",
      medicareExpiryDate = "";

    const { dateOfExpiry } = data;

    if (!isEmptyVAL(data.firstName)) {
      const firstNameArr = data.firstName.split(" ");
      firstName = firstNameArr.shift(); // Get first element as first name
      middleName = firstNameArr.join(" "); // Rest elements as middle name
    }
    if (!isEmptyVAL(data.lastName)) {
      lastName = data.lastName;
    }
    if (isEmptyVAL(data.firstName) && isEmptyVAL(data.lastName)) {
      const fullNameSplit = data.fullName.split(" ");
      firstName = fullNameSplit.shift(); // Get first element as first name
      lastName = fullNameSplit.pop(); // Get last element as last name

      // If there is any element left, take it/them as middle name
      if (fullNameSplit.length > 1) {
        middleName = fullNameSplit.join(" ");
      }
      if (fullNameSplit.length === 1) {
        middleName = fullNameSplit[0];
      }
    }

    if (!isEmptyVAL(data.sex)) {
      const sex = data.sex.toLowerCase();
      if (sex === "f" || sex === "female") {
        gender = "F";
      }
      if (sex === "m" || sex === "male") {
        gender = "M";
      }
    }

    if (
      !isEmptyVAL(data.dateOfBirth.day) &&
      !isEmptyVAL(data.dateOfBirth.month) &&
      !isEmptyVAL(data.dateOfBirth.year)
    ) {
      if (data.dateOfBirth.month < 10)
        data.dateOfBirth.month = `0${data.dateOfBirth.month}`;
      if (data.dateOfBirth.day < 10)
        data.dateOfBirth.day = `0${data.dateOfBirth.day}`;
      dateOfBirth = `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.day}`;
    }
    if (!isEmptyVAL(data.documentNumber)) {
      driversLicenceNo = data.documentNumber;
      passportNo = data.documentNumber;
    }
    if (!isEmptyVAL(data.classInfo)) {
      if (data.classInfo.region === "REGION_NEW_SOUTH_WALES") {
        driversLicenceStateOfIssue = "NSW";
      } else if (data.classInfo.region === "REGION_QUEENSLAND") {
        driversLicenceStateOfIssue = "QLD";
      } else if (data.classInfo.region === "REGION_SOUTH_AUSTRALIA") {
        driversLicenceStateOfIssue = "SA";
      } else if (data.classInfo.region === "REGION_TASMANIA") {
        driversLicenceStateOfIssue = "TAS";
      } else if (data.classInfo.region === "REGION_VICTORIA") {
        driversLicenceStateOfIssue = "VIC";
      } else if (data.classInfo.region === "REGION_WESTERN_AUSTRALIA") {
        driversLicenceStateOfIssue = "WA";
      } else if (
        data.classInfo.region === "REGION_AUSTRALIAN_CAPITAL_TERRITORY"
      ) {
        driversLicenceStateOfIssue = "ACT";
      } else if (data.classInfo.region === "REGION_NORTHERN_TERRITORY") {
        driversLicenceStateOfIssue = "NT";
      }
    }

    // Mapping Drivers Licence Expiry Date
    if (
      !isEmptyVAL(dateOfExpiry.day) &&
      !isEmptyVAL(dateOfExpiry.month) &&
      !isEmptyVAL(dateOfExpiry.year)
    ) {
      if (dateOfExpiry.month < 10)
        dateOfExpiry.month = `0${dateOfExpiry.month}`;
      if (dateOfExpiry.day < 10) dateOfExpiry.day = `0${dateOfExpiry.day}`;
      driversLicenceExpiryDate = `${dateOfExpiry.year}-${dateOfExpiry.month}-${dateOfExpiry.day}`;
    }

    // CHECKS IF DOCUMENT IS EXPIRED
    // docEXP(summary, dispatch);

    loggerUTL("Triggering inisializing scanDataEdited...");
    scanDataEditedTrigger("australia", false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      lastName,
      dateOfBirth,
      passportNo,
      passportExpiryDate,
      driversLicenceNo,
      driversLicenceCardNo,
      driversLicenceExpiryDate,
      driversLicenceStateOfIssue,
      medicareCardNo,
      medicareCardType,
      medicareIndividualRefNo,
      medicareExpiryDate,
    };
    loggerUTL("SAVING SCANNED DATA...", ocrResponseData);
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      saveFormDataAXN(
        {
          firstName,
          middleName,
          lastName,
          dateOfBirth,
          gender,
          passportNo,
          passportExpiryDate,
          driversLicenceNo,
          driversLicenceCardNo,
          driversLicenceExpiryDate,
          driversLicenceStateOfIssue,
          medicareCardNo,
          medicareCardType,
          medicareIndividualRefNo,
          medicareExpiryDate,
        },
        "australia"
      )
    );
  } catch (err) {
    loggerUTL(data);
    dispatch(
      setAlertAXN(transUTL("translateAlertMsg.inputMappingError"), "error")
    );
    Sentry.captureException("Input mapping error...", data, err);
  }
};

export default inputMappingAU;
