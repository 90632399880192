const inputPH = {
  // PERSONAL INFORMATION
  firstName: 'Lanie',
  middleName: 'G',
  lastName: 'Avida',
  dateOfBirth: 'yyyy/mm/dd',

  // IDENTITY
  vin: '',

  // National ID Data
  searchType: '',
  idNumberType: '',
  idNumber: '',
  idNo: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '',
  barangay: '',
  city: '',
  province: '',
  postCode: '',
  region: '',

  // CONTACT DETAILS
  phoneType: '',
  phoneNumber: '',

  // REFERENCE
  reportingReference: '',
};

export default inputPH;
