import React from "react";
import Header from "../../../../../components/header/Header";
import isEmptyVAL from "../../../../../validations/checks/isEmptyVAL";
import { transUTL } from "../../../../../utils/transUTL";

const PhilippinesSuspiciousActivity = ({ results, type }) => {

  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    errorMessage,
    verifications,
    returnedData,
    status
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};  
  if (!isEmptyVAL(returnedData.records)) {
    for (let i = 0; i < returnedData.records.length; i++) {
      delete returnedData.records[i].URN; // Delete URN as it is not needed to map in FE
    } 
  }

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          "translateSearchVerification.dataSourcesPH.suspiciousActivity.title"
        )}
      />
      <table id="find-idu-table">
        <tbody>
          <tr>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.FirstName")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.MiddleName")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.MotherLastName")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.LastName")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.Gender")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.VoterID")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.DOB")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.MaritalStatus")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.Street")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.Barangay")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.Region")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.City")}</th>
            <th>{transUTL("translateSearchVerification.dataSourcesPH.suspiciousActivity.Province")}</th>
          </tr>
        </tbody>

        <tbody>
          {isEmptyVAL(returnedData.records) ? 
            <tr>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
              <td>            
                {transUTL("translateReuse.na")}
              </td>
            </tr>:
            returnedData.records.map((item) => (
            <tr>
              {Object.entries(item).map((val) => ( 
                (val[1] === '' ?  <td>{'N/A'}</td> : val[0] !== 'DayDOB' && val[0] !== 'MonthDOB' && val[0] !== 'YearDOB' ? <td>{val[1]}</td> : '' )   
              ))}
            </tr>
              ))
          } 
        </tbody>
      </table>

      {status === 3 ? (
        <div className="search-verification__verify">
          <div className="search-verification__col">
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.suspiciousActivity.searchSuccessful"
                )}
              </p>
              <span className="material-icons error">cancel</span>
            </div>
            <div className="search-verification__verify-item">
              <p>
                {transUTL(
                  "translateSearchVerification.dataSourcesPH.suspiciousActivity.requestTimedOut"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="search-verification__verify">
        </div>
      )}
    </div>
  );
};

export default PhilippinesSuspiciousActivity;
