const formErrors = {
  // CREDENTIALS
  username: '用户名为必填项',
  password: '密码是必需的',
  // PERSONAL INFORMATION
  firstName: '名字为必填项',
  firstNameTooShort: '名字长度必须包含多于1个字符',
  middleName: '中间名为必填项',
  middleNameTooShort: '中间名长度必须包含多于1个字符',
  lastName: '必须填写姓氏',
  lastNameTooShort: '姓氏长度必须包含多于1个字符',
  dateOfBirth: '出生日期为必填项',
  fullName: '必须填写全名',
  gender: '性别必填',
  paternalName: '家长姓名为必填项',
  maternalName: '必须填写母亲姓名',
  yearOfBirth: '出生年份为必填项', 
  firstNameORlastName: '需要名字或姓氏',

  // CURRENT RESIDENTIAL ADDRESS
  block: '街道/街区号为必填项',
  building: '需要建造',
  addressLine1: '地址行1为必填项',  
  street: '街道为必填项',
  streetNo: '街道号为必填项',
  streetName: '街道名称为必填项',
  streetAddress: '街道地址为必填项',
  streetNumberAndName: '街道号和名称为必填项',
  townOrCity: '城镇或城市为必填项',
  suburb: '地区为必填项',
  state: '国家为必填项',
  postCode: '邮政编码为必填项',
  postcode: '邮政编码为必填项',
  zipCode: '邮政编码为必填项',
  locality: '郊区为必填项',
  city: '城市为必填项',
  district: '地区为必填项',
  ward: '病房为必填项',
  province: '省份为必填项',
  streetNameAndHouseNo: '街道名称和门牌号为必填项',
  houseNumber: '门牌号为必填项', // ITALY, CZECH
  county: '国家为必填项',
  postCodeLengthNZ: '邮政编码应为4位数字',
  // BIRTH CERTIFICATE
  birthRegistrationState: '需要出生登记状态',
  birthRegistrationNo: '需要出生登记号',
  birthCertificateNo: '需要出生证明号码',
  birthRegistrationDate: '需要出生登记日期',
  // CENTERLINK
  centrelinkCardType: 'Centerlink卡类型',
  centrelinkCardExpiry: 'Centerlink卡到期',
  centrelinkCustomerReferenceNo: 'Centerlink卡客户参考号',
  // DRIVERS LICENCE
  driversLicenceNo: '必须提供驾驶执照号码',
  driversLicenceCardNo: '驾驶执照签发状态为必填项',
  driversLicenceStateOfIssue: '驾驶执照状态为必填项',
  driversLicenceVersion: '需要驾驶执照版本',
  vehiclePlate: '需要车辆登记',
  driversLicenceExpiryDate: '驾驶执照到期日为必填项',
  driversLicenceNoLengthNZ: '驾驶执照应为2个字母，后跟6个数字',
  driversLicenceVersionLengthNZ: '驾驶执照版本应为0-9之间的3位数字',
  // MEDICARE
  medicareCardNo: '需要医疗保险卡号',
  medicareCardType: '需要医疗保险卡类型',
  medicareReferenceNo: '需要Medicare个人参考号',
  medicareExpiry: '需要Medicare到期日期',
  // PASSWORD
  passportNo: '护照号码为必填项',
  passportIssuerCountry: '发行国家为必填项',
  passportExpiryDate: '护照有效期为必填项',
  passportExpiry: '护照有效期为必填项',
  passportExpiryDateAU: '本文档已过期，可能无法验证。',
  passportNoInvalidM: '不接受以“ M”开头的护照号码',
  fileNo: '文件号为必填项',
  dateOfIssue: '签发日期为必填项',
  // GOOGLE
  addressUnparsed: '地址为必填项',
  // CHINESE SEARCH
  phoneNo: '电话号码为必填项',
  phoneNo1: '无效的电话号码',
  phoneNo2: '超出电话号码长度',
  phoneNo3: '11 digits required',
  phoneNo4: '电话号码必须以+86开头',
  idCardNo: '身份证号码为必填项',
  bankCardNo: '银行卡号为必填项',
  passportNo: '护照号码为必填项',
  // IDENTITY DATA
  laserNo: '激光数为必填项',
  idNo: '身份证号码为必填项',
  idCardNoLength: '身份证号码长度应为12或9个字符',
  nationalIDNo: '身份证号码为必填项',
  landlineNo: '固定电话号码为必填项',  
  vin: '身份证号为必填项',
  voterNo: '必须提供选民编号',
  bankVerificationNo: '必须提供银行验证码',
  curp: '需要',
  taxCodeNo: '必须输入税码',
  nationalIdNo: '身份证号码为必填项',
  // NATIONAL ID DATA
  idcardNoType: '身份证类型为必填项',
  searchType: '搜索类型为必填项',
  // ACCOUNT NUMBER
  pan: '需要PAN',
  aadhaarNo: '需要Aadhaar',
  epic: '需要EPIC',
  epicLength: '字符长度应为10',
  // RESIDENT IDENTITY CARD (NIK)
  nik: '需要NIK',
  // DATES
  dateOfBirthFormat: '日期格式不正确',
  medicareExpiryDateFormat: '日期格式不正确，提示：（mm / yyyy）',
  medicareExpiryDateFormatExceeded: '输入的日期大于今天的日期...',
  passportExpiryDateFormat: '日期格式不正确',
  medicareReferenceNoNumberOnly: '仅数字',

  // SOCIAL SECURITY
  socialSecurityNo: '必须提供社会安全号码',

  // TAX REGISTRATION
  taxIDNo: '税号为必填项',
  cpf: 'CPF (CADASTRO DE PESSOAS FÍSICAS) 为必填项',
  rfc: 'RFC 为必填项',
  npwp: 'NPWP (Nomor Pokok Wajib Pajak - Tax ID Number) 为必填项',

  // CONTACTS
  emailAddress: '电子邮件地址为必填项',
  email: '电子邮件地址为必填项',
  emailFormatIncorrect: '电子邮件地址格式不正确',
  emailID: '电子邮件地址为必填项',

  // ABN
  ABN: '澳洲商家号码',
};
export default formErrors;
