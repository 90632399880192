import moment from "moment";
import isEmptyVAL from "../checks/isEmptyVAL";
import isValidDateVAL from "../checks/isValidDateVAL";

import { transUTL } from "../../utils/transUTL";
import formatDateUTL from "../../utils/formatDateUTL";
import loggerUTL from "../../utils/loggerUTL";

const hongKongVAL = (data, dataSource) => {
  const inputErrors = {};

  /* ============================================
            HONG KONG CREDIT BUREAU
   ============================================ */
  if (dataSource.includes("Hong Kong Credit Bureau")) {
    loggerUTL("VALIDATING HONG KONG CREDIT BUREAU...");
    // CHECK IF CREDENTIALS IS NOT EMPTY

    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : "";
    data.nationalIDNo = !isEmptyVAL(data.nationalIDNo) ? data.nationalIDNo : "";
    data.building = !isEmptyVAL(data.building) ? data.building : "";
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : "";
    data.district = !isEmptyVAL(data.district) ? data.district : "";
    data.state = !isEmptyVAL(data.state) ? data.state : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      );
    }
    if (isEmptyVAL(data.nationalIDNo)) {
      inputErrors.nationalIDNo = transUTL(
        "translateFormOverview.formErrors.nationalIDNo"
      );
    }
    if (isEmptyVAL(data.building)) {
      inputErrors.building = transUTL(
        "translateFormOverview.formErrors.building"
      );
    }
    // if (isEmptyVAL(data.district)) {
    //   inputErrors.district = transUTL(
    //     'translateFormOverview.formErrors.district'
    //   );
    // }
    // if (isEmptyVAL(data.state)) {
    //   inputErrors.state = transUTL(
    //     'translateFormOverview.formErrors.state'
    //   );
    // }
  }
  /* ============================================
            HONG KONG RESIDENTIAL
   ============================================ */
  if (dataSource.includes("Hong Kong Residential")) {
    loggerUTL("VALIDATING HONG KONG RESIDENTIAL...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.building = !isEmptyVAL(data.building) ? data.building : "";
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : "";
    data.district = !isEmptyVAL(data.district) ? data.district : "";
    data.state = !isEmptyVAL(data.state) ? data.state : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.building)) {
      inputErrors.building = transUTL(
        "translateFormOverview.formErrors.building"
      );
    }
    if (isEmptyVAL(data.district)) {
      inputErrors.district = transUTL(
        "translateFormOverview.formErrors.district"
      );
    }
    if (isEmptyVAL(data.state)) {
      inputErrors.state = transUTL("translateFormOverview.formErrors.state");
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        "translateFormOverview.formErrors.streetName"
      );
    }
  }

  /* ============================================
            HONG KONG MARKETING
   ============================================ */
  if (dataSource.includes("Hong Kong Marketing")) {
    loggerUTL("VALIDATING HONG KONG MARKETING...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";
    data.building = !isEmptyVAL(data.building) ? data.building : "";

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.building)) {
      inputErrors.building = transUTL(
        "translateFormOverview.formErrors.building"
      );
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSource.includes("Watchlist AML")) {
    loggerUTL("VALIDATING WATCHLIST AML...");
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : "";
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : "";

    // ERROR FEEDBACK ACCUMULATOR

    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirthFormat"
      );
    }

    if (dateFormatted > moment().format("YYYY-MM-DD")) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirthFormat"
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default hongKongVAL;
