import * as Sentry from "@sentry/browser";

// TYPES
import { ERROR_FEEDBACK } from "../../types";

// UTILITIES
import findErrorUTL from "../../../utils/findErrorUTL";
import loggerUTL from "../../../utils/loggerUTL";

const feedbackGR = (errors, dispatch) => {
  loggerUTL("ERRORS...", errors);
  const inputError = {
    personalInformation: {},
    currentResidentialAddress: {},
    taxRegistration: {},
  };

  try {
    /* ============================================
                PERSONAL INFORMATION
    ============================================= */
    if (findErrorUTL(errors, "firstName")) {
      inputError.personalInformation.firstName = `First name ${
        errors.find((item) => item.includes("firstName")).split(":")[1]
      }`;
    }
    if (findErrorUTL(errors, "lastName")) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes("lastName")).split(":")[1]
      }`;
    }

    if (findErrorUTL(errors, "dateOfBirth")) {
      inputError.personalInformation.dateOfBirth = `Date of Birth ${
        errors.find((item) => item.includes("dateOfBirth")).split(":")[1]
      }`;
    }

    /* ============================================
                    ADDRESS
    ============================================ */
    if (findErrorUTL(errors, "addressElement5")) {
      inputError.currentResidentialAddress.postcode = `Postcode should be ${
        errors.find((item) => item.includes("addressElement5")).split(":")[1]
      }`;
    }

    /* ============================================
                    TAX ID NO
    ============================================ */
    if (findErrorUTL(errors, "taxIDNo")) {
      inputError.taxRegistration.taxIDNo = `Tax id no. should be ${
        errors.find((item) => item.includes("taxIDNo")).split(":")[1]
      }`;
    }
  } catch (err) {
    Sentry.captureException(
      "FAILED TO PARES GREECE VALIDATION ERRORS...",
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackGR;
