import axios from "axios";

// PRODUCTION REQUEST TRIGGER - SWITCH BETWEEN TEST AND PRODUCTION ENVIRONMENTS
// IDU-TEST - TEST ENVIRONMENT
// IDU - PRODUCTION ENVIRONMENT

// CENTRIX
const centrixENV = window.location.href.includes(
  "https://idu-centrix.datazoo.com"
);

// BASE URL's

/*
idu uses https://idu.datazoo.com 
localhost, idu-demo and idu-test use https://idu-test.datazoo.com  
europe uses https://europe.datazoo.com 
*/

const host = window.location.hostname; //XXXX.datazoo.com or localhost (for the local env)
let dzProductionENV = host.split(".", 1);

switch (dzProductionENV[0]) {
  case "idu":
    dzProductionENV = "idu";
    break;
  case "europe":
    dzProductionENV = "europe";
    break;
  default:
    dzProductionENV = "idu-test";
}

const dzURL = `https://${dzProductionENV}.datazoo.com`;
const proxyURL = "https://idu-proxy.datazoo.com";
const iduBucketURL = "https://idufont.datazoo.com";

// AXIOS INSTANCE: BASE URL FOR DATA ZOO
const dzAPI = axios.create({
  baseURL: dzURL,
});

// AXIOS INSTANCE: BASE URL
const proxyAPI = axios.create({
  baseURL: proxyURL,
});

// AXIOS INSTANCE: BASE URL
const iduBucket = axios.create({
  baseURL: iduBucketURL,
});

const proxyEndpoint = (to, endPoint) => {
  return {
    headers: {
      proxy_url: `${dzURL}${endPoint}`,
    },
  };
};

const configHeader = (proxy_url) => {
  return {
    headers: {
      proxy_url,
    },
  };
};

export {
  iduBucket,
  proxyAPI,
  dzAPI,
  dzURL,
  centrixENV,
  proxyEndpoint,
  configHeader,
};
