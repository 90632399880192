import * as Sentry from '@sentry/browser';

import { saveFormDataAXN } from '../../formData/formDataActions';
import { setAlertAXN } from '../../alert/alertActions';
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';

const inputMappingPH = (data, dispatch) => {
  try {
    // MAPPING FIELDS
    let firstName = '',
      middleName = '',
      lastName = '',
      dateOfBirth = '',
      gender = '',
      vin = '',
      idNo = '',
      idcardNoType = '';

    if (!isEmptyVAL(data.firstName)) {
      firstName = data.firstName;
    }
    if (!isEmptyVAL(data.lastName)) {
      lastName = data.lastName;
    }
    if (isEmptyVAL(data.firstName) && isEmptyVAL(data.lastName)) {
      const fullNameSplit = data.fullName.split(' ');
      if (fullNameSplit.length === 4) {
        firstName = data.fullName.split(' ')[0];
        middleName = `${data.fullName.split(' ')[1]} ${
          data.fullName.split(' ')[2]
        }`;
        lastName = data.fullName.split(' ')[3];
      }
      if (fullNameSplit.length === 3) {
        firstName = data.fullName.split(' ')[0];
        middleName = data.fullName.split(' ')[1];
        lastName = data.fullName.split(' ')[2];
      }
      if (fullNameSplit.length === 2) {
        firstName = data.fullName.split(' ')[0];
        lastName = data.fullName.split(' ')[1];
      }
    }

    if (!isEmptyVAL(data.sex)) {
      const sex = data.sex.toLowerCase();
      if (sex === 'f' || sex === 'female') {
        gender = 'F';
      }
      if (sex === 'm' || sex === 'male') {
        gender = 'M';
      }
    }

    if (
      !isEmptyVAL(data.dateOfBirth.day) &&
      !isEmptyVAL(data.dateOfBirth.month) &&
      !isEmptyVAL(data.dateOfBirth.year)
    ) {
      if (data.dateOfBirth.month < 10)
        data.dateOfBirth.month = `0${data.dateOfBirth.month}`;
      if (data.dateOfBirth.day < 10)
        data.dateOfBirth.day = `0${data.dateOfBirth.day}`;
      dateOfBirth = `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.day}`;
    }
    if (!isEmptyVAL(data.documentNumber)) {
      vin = data.documentNumber;
    }

    // CHECKS IF DOCUMENT IS EXPIRED
    // docEXP(summary, dispatch);

    // PDF SCANNED DATA TRIGGER
    // scanDataEditedTrigger('philippines');
    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('philippines', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      lastName,
      dateOfBirth,
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      saveFormDataAXN(
        {
          firstName,
          middleName,
          lastName,
          dateOfBirth,
          gender,
          idcardNoType,
          idNo,
          vin,
        },
        'philippines'
      )
    );
  } catch (err) {
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException('Input mapping error...', data, err);
  }
};

export default inputMappingPH;
