import {
  SAVE_USER_INPUT
} from '../../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_INPUT:
      return {
        ...state,
        isTickedApiDisplay: action.payload,
      };
  
    // case RESET_FORM_DATA:
    //   return initialState;
    default:
      return state;
  }
}
