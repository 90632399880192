const initialStateID = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // NIK
  nik: '',

  // NPWP
  npwp: '',

  // DRIVERS LICENCE
  driversLicenceNumber: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetName: '',
  village: '',
  district: '',
  city: '',

  // TERMS & CONDITION
  indonesiaResidentIdentityCardConsentObtained: false,

  // REPORTING REFERENCE NUMBER
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStateID;
