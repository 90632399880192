const stateCapitalAU = {
  queensland: 'Queensland',
  newSouthWales: 'New South Wales',
  southAustralia: 'Australia Selatan',
  tasmania: 'Tasmania',
  victoria: 'Victoria',
  westernAustralia: 'Australia Barat',
  australianCapitalTerritory: 'Wilayah Ibu Kota Australia',
  northernTerritory: 'Wilayah Utara',
};

export default stateCapitalAU;
