import loggerUTL from "../../utils/loggerUTL";
import { transUTL } from "../../utils/transUTL";
import isEmptyVAL from "../checks/isEmptyVAL";

const brazilVAL = (data, dataSource) => {
  const inputErrors = {};

  /* ============================================
              Brazil National ID
  ============================================ */
  if (dataSource.includes("Brazil National ID")) {
    loggerUTL("VALIDATE Brazil National ID...");

    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      );
    }
    if (isEmptyVAL(data.locality)) {
      inputErrors.locality = transUTL(
        "translateFormOverview.formErrors.locality"
      );
    }
    if (isEmptyVAL(data.cpf)) {
      inputErrors.cpf = transUTL(
        "translateFormOverview.formErrors.cpf"
      );
    }
  }
  /* ===============================================
            Brazil Tax Registration
  ================================================ */
  if (dataSource.includes("Brazil Tax Registration")) {
    loggerUTL("VALIDATE Brazil Tax Registration...");

    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        "translateFormOverview.formErrors.firstName"
      );
    }

    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        "translateFormOverview.formErrors.lastName"
      );
    }

    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        "translateFormOverview.formErrors.dateOfBirth"
      )
    }

    if (isEmptyVAL(data.cpf)) {
      inputErrors.cpf = transUTL(
        "translateFormOverview.formErrors.cpf"
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors)
  };
};

export default brazilVAL;
