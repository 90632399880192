import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const ItalyResidential = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesIT.italyResidential.title'
        )}
      />

      <div className="search-verification__verify">
        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.searchSuccessful'
              )}
            </p>
            <span className="material-icons success">check_circle</span>
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.safeHarbourScore'
              )}
            </p>
            {!isEmptyVAL(safeHarbourScore) ? (
              safeHarbourScore.toLowerCase() === 'add' ? (
                <span className="material-icons success">add_circle</span>
              ) : (
                  <span>{safeHarbourScore}</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.verified'
              )}
            </p>
            {!isEmptyVAL(identityVerified) ? (
              identityVerified ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
        </div>

        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.firstName'
              )}
            </p>
            {!isEmptyVAL(verifications.firstName) ? (
              verifications.firstName ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.middleName'
              )}
            </p>
            {!isEmptyVAL(verifications.middleName) ? (
              verifications.middleName ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.lastName'
              )}
            </p>
            {!isEmptyVAL(verifications.lastName) ? (
              verifications.lastName ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.gender'
              )}
            </p>
            {!isEmptyVAL(verifications.gender) ? (
              verifications.gender ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.dateOfBirth'
              )}
            </p>
            {!isEmptyVAL(verifications.dateOfBirth) ? (
              verifications.dateOfBirth ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.phoneNo'
              )}
            </p>
            {!isEmptyVAL(verifications.phoneNo) ? (
              verifications.phoneNo ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
        </div>

        <div className="search-verification__col">
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.street'
              )}
            </p>
            {!isEmptyVAL(verifications.street) ? (
              verifications.street ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.city'
              )}
            </p>
            {!isEmptyVAL(verifications.city) ? (
              verifications.city ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.state'
              )}
            </p>
            {!isEmptyVAL(verifications.state) ? (
              verifications.state ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.postCode'
              )}
            </p>
            {!isEmptyVAL(verifications.postCode) ? (
              verifications.postCode ? (
                <span className="material-icons success">check_circle</span>
              ) : (
                  <span className="material-icons error">cancel</span>
                )
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>

          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.nameMatchScore'
              )}
            </p>
            {!isEmptyVAL(nameMatchScore) ? (
              <span>{nameMatchScore}</span>
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
          <div className="search-verification__verify-item">
            <p>
              {transUTL(
                'translateSearchVerification.dataSourcesIT.italyResidential.addressMatchScore'
              )}
            </p>
            {!isEmptyVAL(addressMatchScore) ? (
              <span>{addressMatchScore}</span>
            ) : (
                <span>{transUTL('translateReuse.na')}</span>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

ItalyResidential.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default ItalyResidential;
