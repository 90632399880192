import React from 'react';
import PropTypes from 'prop-types';

import { transUTL } from '../../utils/transUTL';

const DropdownNon = ({
  handleOnChange,
  target,
  obj,
  objKey,
  handelRadio,
  name,
  transType,
}) => {
  return (
    <div className="dropdown">
      {obj.map((item) => {
        const checked = target === item[objKey];
        const title = item[objKey];

        return (
          <label
            htmlFor={item[objKey]}
            key={item[objKey]}
            className="dropdown__option"
            onClick={() => handelRadio(item[objKey])}
          >
            <h4 className="dropdown__title">
              {transUTL(`${transType}.${title}`)}
            </h4>

            <div className="dropdown__radio">
              <input
                id={item[objKey]}
                type="radio"
                name={name}
                value={item[objKey]}
                onChange={handleOnChange}
                defaultChecked={checked}
              />

              <label
                htmlFor={item[objKey]}
                className="radio-label"
                name={name}
              />
            </div>
          </label>
        );
      })}
    </div>
  );
};

DropdownNon.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  obj: PropTypes.array.isRequired,
  objKey: PropTypes.string.isRequired,
  handelRadio: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  transType: PropTypes.string.isRequired,
};

export default DropdownNon;
