import {
  GET_USER_TRANSACTION_HISTORY,
  FILTER_TRANSACTIONS,
} from '../../actions/types';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_TRANSACTION_HISTORY:
      return action.payload;
    case FILTER_TRANSACTIONS:
      return action.payload;
    default:
      return state;
  }
}
