const dataSourcesDE = {
  germanyResidential: {
    title: 'Germany Residential',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    gender: 'Gender Verified',
    phoneNo: 'Phone Number Verified',
    street: 'Street Verified',
    city: 'City Verified',
    state: 'State Verified',
    postCode: 'Postcode Verified',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
  },
  germanyUtility: {
    title: 'Germany Utility',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    gender: 'Gender Verified',
    phoneNo: 'Phone Number Verified',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    street: 'Street Verified',
    city: 'City Verified',
    state: 'State Verified',
    postCode: 'Postcode Verified',
  },
  germanyPhone: {
    title: 'Germany Phone',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    phoneNo: 'Phone No. Verified',
    emailAddress: 'Email Address Verified',
    street: 'Street Verified',
    city: 'City Verified',
    state: 'State Verified',
    postCode: 'Postcode Verified',
  },
  germanyCreditBureau: {
    title: 'Germany Credit Bureau',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name Verified',
    lastName: 'Last Name Verified',
    gender: 'Gender Verified',
    dateOfBirth: 'Date Of Birth Verified',
    streetNo: 'Street No. Verified',
    streetName: 'Street Verified',
    city: 'City Verified',
    postCode: 'Postcode Verified'
  }
};

export default dataSourcesDE;
