import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD HONG KONG API ERRORS TO INPUT FORM
const feedbackHK = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${errors.find((item) => item.includes('firstName')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${errors.find((item) => item.includes('lastName')).split(':')[1]
        }`;
    }

    /* ============================================
              ADDRESS
       ============================================ */
    if (findErrorUTL(errors, 'addressElement1')) {
      inputError.building = `Building ${errors.find((item) => item.includes('addressElement1')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'addressElement2')) {
      inputError.streetName = `StreetName ${errors.find((item) => item.includes('addressElement2')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'addressElement3')) {
      inputError.district = `District ${errors.find((item) => item.includes('addressElement3')).split(':')[1]
        }`;
    }
    if (findErrorUTL(errors, 'addressElement4')) {
      inputError.state = `State ${errors.find((item) => item.includes('addressElement4')).split(':')[1]
        }`;
    }

    /* ============================================
              IDENTITY DETAILS
       ============================================ */
    // NOTE: NOT SURE WHY THE VALIDATION API RETURNS `nationalIdNo` INSTEAD OF `nationalIDNo`
    // THE BODY REQUEST OF HONG KONG REQUEST REQUIRES `nationalIDNo` (NOTIFY THE BACKEND DUDES TO FIX THIS)
    if (findErrorUTL(errors, 'nationalIdNo')) {
      inputError.nationalIDNo = `National ID number ${errors.find((item) => item.includes('nationalIdNo')).split(':')[1]
        }`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES HONG KONG VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackHK;
