import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTION
import { saveDataSourcesAXN } from '../../actions/dataSources/dataSourcesActions';
import { setAlertAXN } from '../../actions/alert/alertActions';

// UTILITIES
import { dataSourcesIsCheckedUTL } from '../../utils/outputMasterUTL';
import { transUTL } from '../../utils/transUTL';

import DigitalIdSafeguardForms from "./digitalIdSafeguard/DigitalIdSafeguardForms";

const DigitalIdSafeguardFormOverview = ({
  dataSourcesRXS,
  history,
}) => {
  
  // const selectedDataSources = dataSourcesIsCheckedUTL(
  //   dataSourcesRXS.findIDUDataSources
  // );
  const selectedDataSources = ['Digital ID Safeguard'];

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>Digital ID Safeguard</h2>

        <span
          className="material-icons icon-size"
          title="Edit Data Source"
          onClick={() => history.push('digital-id-safeguard')}
        >
          create
        </span>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        {/* selected dataSources */}
        <div className="form-overview__top">
          <header className="header-secondary">
            <h4 className="header-title-secondary">
              {transUTL('translateFormOverview.formHeaders.selectedServices')}
            </h4>
            <span
              className="material-icons icon-size"
              title="Edit Data Source"
              onClick={() => history.push('digital-id-safeguard')}
            >
              create
            </span>
          </header>
          <p className="data-source-selection__selected-data">
            {selectedDataSources
              .sort()
              .map((item, index) => {
                const dataSource ='digitalIdSafeguard';
                // const dataSource = item.split(' ').join('').toLowerCase();
                const i18nKeyDataSourceString = `translateDataSources.${dataSource}`;

                return (
                  <span key={index}>{transUTL(i18nKeyDataSourceString)}</span>
                );
              })
              .reduce((prev, curr) => [
                prev,
                <span key={prev}>, &nbsp;</span>,
                curr,
              ])}
          </p>
        </div>

        {/* form */}
        <DigitalIdSafeguardForms />
      </div>
    </div>
  );
};

DigitalIdSafeguardFormOverview.propTypes = {
  dataSourcesRXS: PropTypes.object.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
  saveDataSourcesAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dataSourcesRXS: state.dataSourcesRXS,
});

export default connect(mapStateToProps, { saveDataSourcesAXN, setAlertAXN })(
  DigitalIdSafeguardFormOverview
);
