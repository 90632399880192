import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import CheckBoxGroup from '../../../../../components/common/checkBoxGroup/CheckBoxGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

const TermsAndConditionsIndonesia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleToggleCheckbox,
}) => {
  const {
    indonesiaResidentIdentityCardConsentObtained,
    reportingReference,
    errors,
  } = formState;

  return (
    <div className="form-overview__terms">
      <p className="form-overview__terms-label">
        {transUTL('translateFormOverview.consentParagraph')}
      </p>

      <CheckBoxGroup
        key="consent"
        checkboxBool={indonesiaResidentIdentityCardConsentObtained}
        label={transUTL('translateFormOverview.consentParagraph2')}
        handleToggleCheckbox={() => handleToggleCheckbox(false)}
      />
    </div>
  );
};

TermsAndConditionsIndonesia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
};

export default TermsAndConditionsIndonesia;
