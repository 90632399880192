import store from '../../../../store/store';
import { saveSelectedCountryAXN } from '../../../../actions/country/countryActions';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const countrySessionDataRestore = () => {
  loggerUTL('COUNTRY SESSION DATA RESTORE...');
  if (sessionStorage.selectedCountry) {
    store.dispatch(saveSelectedCountryAXN(sessionStorage.selectedCountry));
  }
};

export default countrySessionDataRestore;
