import title from '../../../helpers/title';

const errorMessages = (doc, data, info, docStyles) => {
  const { Response } = data;

  title(
    doc,
    docStyles,
    'Error Message(s)',
    docStyles.headers.primary,
    docStyles.separation,
    true // PRIMARY HEADER STYLES
  );

  const columnsBody = [
    { title: '', dataKey: 'col1' },
    {
      title: '',
      dataKey: 'col2',
    },
  ];
  const rowsBody = [];

  // DATA SOURCE RESPONSE
  let driversLicence = Response.serviceResponses['India Drivers Licence'];
  let pan = Response.serviceResponses['India PAN'];
  let epic = Response.serviceResponses['India EPIC'];
  let passport = Response.serviceResponses['India Passport'];
  let aadhaarNo = Response.serviceResponses['India Aadhaar'];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  // INDIA DRIVERS LICENCE ERRORS
  if (driversLicence) {
    let status;
    if (driversLicence.status === 3) {
      status = driversLicence.errorMessage
        ? driversLicence.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'India Drivers Licence',
      col2: status,
    });
  } else {
    driversLicence = { verifications: {}, returnedData: {} };
  }

  // INDIA PAN ERRORS
  if (pan) {
    let status;
    if (pan.status === 3) {
      status = pan.errorMessage ? pan.errorMessage : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'India PAN',
      col2: status,
    });
  } else {
    pan = { verifications: {}, returnedData: {} };
  }

  // INDIA EPIC ERRORS
  if (epic) {
    let status;
    if (epic.status === 3) {
      status = epic.errorMessage ? epic.errorMessage : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'India EPIC',
      col2: status,
    });
  } else {
    epic = { verifications: {}, returnedData: {} };
  }

  // INDIA Passport ERRORS
  if (passport) {
    let status;
    if (passport.status === 3) {
      status = passport.errorMessage
        ? passport.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'India Passport',
      col2: status,
    });
  } else {
    passport = { verifications: {}, returnedData: {} };
  }

  // INDIA Aadhaar ERRORS
  if (aadhaarNo) {
    let status;
    if (aadhaarNo.status === 3) {
      status = aadhaarNo.errorMessage
        ? aadhaarNo.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'India Aadhaar',
      col2: status,
    });
  } else {
    aadhaarNo = { verifications: {}, returnedData: {} };
  }

  // WATCHLIST AML
  if (watchlistAML) {
    let status;
    if (watchlistAML.status === 3) {
      status = watchlistAML.errorMessage
        ? watchlistAML.errorMessage
        : 'Error at Data Source';
    } else {
      status = 'N/A';
    }
    rowsBody.push({
      col1: 'Global Watchlist AML',
      col2: status,
    });
  } else {
    watchlistAML = { verifications: {}, returnedData: {} };
  }

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  doc.autoTable(columnsBody, rowsBody, {
    didParseCell: (data) => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === 'col1') {
        cell.styles.textColor = docStyles.color;
      }
    },
    showHead: 'never',
    styles: {
      textColor: docStyles.colorText,
      font: docStyles.fontFamily.time,
      ...docStyles.tableStyles,
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth,
      },
      col2: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
      },
    },
    // margin: { bottom: 100 }, // PREVENTS OVERLAP WITH FOOTER
    startY: finalY,
  });
};

export default errorMessages;
