// HELPERS
import returnDataToString from "../../helpers/returnDataToString";

// UTILS
import sortAlphaUTL from "../../../../utils/sortAlphaUTL";
import title from "../../helpers/title";
import infoMsg from "../../helpers/infoMsg";
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

import { specialServiceNames } from "../displayingStringsForVerificationDataFields/verificationResultsDataFields";

const returnedData = (doc, serviceResponses, docStyles) => {

  title(
    doc,
    docStyles,
    "Returned Data",
    docStyles.headers.secondary,
    docStyles.separation
  );

  const columns = [
    { 
      header: "", 
      dataKey: "col1" },
    {
      header: "",
      dataKey: "col2"
    }
  ];
  const rows = [];

  // MAP RESPONSE DATA
  // ======================================================

  const services = Object.entries(serviceResponses);
  let watchlistAMLPaddingTrigger = false;

  services.forEach(service => {
    const serviceName = service[0]
    const serviceData = service[1]

    const specialServiceNamesArray = Object.keys(specialServiceNames);
    let displayName = serviceName;
    if (specialServiceNamesArray.includes(serviceName)) {
      displayName = specialServiceNames[serviceName];
    }

    if (serviceData) {
      let { returnedData, verifications, ...data } = serviceData;

      if (serviceName === 'Watchlist AML') {
        if (isEmptyVAL(returnedData.watchlistResults)) {
          returnedData = { watchlistResults: [{}] };
        }
        const fields = returnDataToString({
          fields: {
            ...data,
            ...verifications,
            ...returnedData.watchlistResults[0]
          }
        });
        rows.push({
          col1: "Global Watchlist AML",
          col2: fields
        });
      } else {
        const string = returnDataToString({
          fields: { ...data, ...verifications, ...returnedData },
        });
        rows.push({
          col1: displayName,
          col2: string,
        });
      }

    } else {
      const returnedData = service[0] === 'Watchlist AML' ? { watchlistResults: [{}] } : {};
      service[1] = {
        returnedData,
        verifications: {}
      };
    }
  })
  // ======================================================

  rows.sort((a, b) => sortAlphaUTL(a, b, "col1"));

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  doc.autoTable({ 
    columns: columns, 
    body: rows,
    didParseCell: data => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];
      if (column.raw.dataKey === "col1") {
        cell.styles.textColor = docStyles.colorGreen;
      }

      if (column.index === 0) {
        cell.styles.cellPadding = { top: 60, bottom: 60, left: 3.75, right: 3.75 }; // This should prevent second column inner contents from overflowing
      }

      if (cell.raw === "Watchlist AML") {
        if (watchlistAMLPaddingTrigger) {
          cell.styles.cellPadding = { top: 125, bottom: 125 }; // This should prevent second column inner contents from overflowing
        }
      }
    },
    showHead: "never",
    styles: {
      textColor: docStyles.colorText,
      ...docStyles.tableStyles
    },
    columnStyles: {
      col1: {
        font: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth
      },
      col2: {
        font: docStyles.fontFamily.arialUnicodeMS,
        textColor: docStyles.colorText
      }
    },
    startY: finalY
  });

  infoMsg(
    doc,
    docStyles,
    [{ header: "", dataKey: "col1" }],
    [
      {
        col1:
          "Name Match Score: A=Full Match | A1=Middle Name Mismatched | B=First Initial and Surname Matched | C=Surname Only Match | X=No Match"
      },
      {
        col1:
          "Address Match Score: 0=Full Match | 1=Suburb/Postcode Mismatch | 2=Unit # Mismatch | 2A=House Suf/Prefix Mismatch | 3=Street # Mismatch | Z=No Match"
      }
    ],
    docStyles.separation
  );
};

export default returnedData;
