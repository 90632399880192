import {
  SAVE_AUSTRALIA_FORM_DATA,
  SAVE_BRAZIL_FORM_DATA,
  SAVE_CAMBODIA_FORM_DATA,
  SAVE_CHINA_FORM_DATA,
  SAVE_MALAYSIA_FORM_DATA,
  SAVE_NEW_ZEALAND_FORM_DATA,
  SAVE_PHILIPPINES_FORM_DATA,
  SAVE_INDIA_FORM_DATA,
  SAVE_INDONESIA_FORM_DATA,
  SAVE_VIETNAM_FORM_DATA,
  SAVE_NIGERIA_FORM_DATA,
  SAVE_HONG_KONG_FORM_DATA,
  SAVE_SINGAPORE_FORM_DATA,
  SAVE_UNITED_STATES_FORM_DATA,
  SAVE_MEXICO_FORM_DATA,
  SAVE_GERMANY_FORM_DATA,
  SAVE_FRANCE_FORM_DATA,
  SAVE_ITALY_FORM_DATA,
  SAVE_SPAIN_FORM_DATA,
  RESET_FORM_DATA,
  SAVE_MULTI_SERVICE_FORM_DATA,
  SAVE_WATCHLISTAML_FORM_DATA,
  SAVE_FORM_DATA,
  SAVE_FIND_IDU_FORM_DATA,
  SAVE_DIGITAL_ID_SAFEGUARD_FORM_DATA,
} from '../types';

import { FIND_IDU } from "../../constants/findIDU";

import loggerUTL from '../../utils/loggerUTL';

// New form template
export const retainFormDataAXN = (formData, country) => (dispatch) => {
  sessionStorage.setItem(`${country}FormData_Template`, JSON.stringify(formData));
  
  dispatch({
    type: SAVE_FORM_DATA,
    payload: {
      country,
      formData,
    }
  });
}

// Old form template
export const saveFormDataAXN = (formData, country) => (dispatch) => {
  loggerUTL('RUNNING FORM DATA ACTION');
  /* ============================================
                  AUSTRALIA
   ============================================ */
  if (country === 'australia') {
    loggerUTL('SAVING AUSTRALIA FORM DATA...');

    sessionStorage.setItem('australiaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_AUSTRALIA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                 BRAZIL
   ============================================== */
  if (country === 'brazil') {
	loggerUTL('SAVING BRAZIL FORM DATA...');
	   
	sessionStorage.setItem('brazilFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_BRAZIL_FORM_DATA,
      payload: formData,
	});
  }

  /* ============================================
                  CHINA
   ============================================ */
  if (country === 'china') {
    loggerUTL('SAVING CHINA FORM DATA...');

    sessionStorage.setItem('chinaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_CHINA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  INDIA
   ============================================ */
  if (country === 'india') {
    loggerUTL('SAVING INDIA FORM DATA...');

    sessionStorage.setItem('indiaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_INDIA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  INDONESIA
   ============================================ */
  if (country === 'indonesia') {
    loggerUTL('SAVING INDONESIA FORM DATA...');

    sessionStorage.setItem('indonesiaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_INDONESIA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  MALAYSIA
   ============================================ */
  if (country === 'malaysia') {
    loggerUTL('SAVING MALAYSIA FORM DATA...');

    sessionStorage.setItem('malaysiaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_MALAYSIA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  VIETNAM
   ============================================ */
  if (country === 'vietnam') {
    loggerUTL('SAVING VIETNAM FORM DATA...');

    sessionStorage.setItem('vietnamFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_VIETNAM_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  PHILIPPINES
   ============================================ */
  if (country === 'philippines') {
    loggerUTL('SAVING PHILIPPINES FORM DATA...');

    sessionStorage.setItem('philippinesFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_PHILIPPINES_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  CAMBODIA
   ============================================ */
  if (country === 'cambodia') {
    loggerUTL('SAVING CAMBODIA FORM DATA...');

    sessionStorage.setItem('cambodiaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_CAMBODIA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  NEW ZEALAND
   ============================================ */
  if (country === 'newzealand') {
    loggerUTL('SAVING NEW ZEALAND FORM DATA...');

    sessionStorage.setItem('newzealandFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_NEW_ZEALAND_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  NIGERIA
   ============================================ */
  if (country === 'nigeria') {
    loggerUTL('SAVING NIGERIA FORM DATA...');

    sessionStorage.setItem('nigeriaFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_NIGERIA_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  HONG KONG
   ============================================ */
  if (country === 'hongkong') {
    loggerUTL('SAVING HONG KONG FORM DATA...');

    sessionStorage.setItem('hongkongFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_HONG_KONG_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  SINGAPORE
   ============================================ */
  if (country === 'singapore') {
    loggerUTL('SAVING SINGAPORE FORM DATA...');

    sessionStorage.setItem('singaporeFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_SINGAPORE_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  UNITED STATES
   ============================================ */
  if (country === 'unitedstates') {
    loggerUTL('SAVING UNITED STATES FORM DATA...');

    sessionStorage.setItem('unitedstatesFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_UNITED_STATES_FORM_DATA,
      payload: formData,
    });
  }
  /* ============================================
                  UNITED STATES
   ============================================ */
  if (country === 'mexico') {
    loggerUTL('SAVING MEXICO FORM DATA...');

    sessionStorage.setItem('mexicoFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_MEXICO_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                 GERMANY
   ============================================ */
  if (country === 'germany') {
    loggerUTL('SAVING GERMANY FORM DATA...');

    sessionStorage.setItem('germanyFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_GERMANY_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                 FRANCE
  ============================================ */
  if (country === 'france') {
    loggerUTL('SAVING FRANCE FORM DATA...');

    sessionStorage.setItem('franceFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_FRANCE_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                 ITALY
  ============================================ */
  if (country === 'italy') {
    loggerUTL('SAVING ITALY FORM DATA...');

    sessionStorage.setItem('italyFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_ITALY_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                SPAIN
 ============================================ */
  if (country === 'spain') {
    loggerUTL('SAVING SPAIN FORM DATA...');

    sessionStorage.setItem('spainFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_SPAIN_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  MULTI SERVUCE
   ============================================ */
  if (country === 'multiService') {
    loggerUTL('SAVING MULTI SERVICE FORM DATA...');

    sessionStorage.setItem('multiServiceFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_MULTI_SERVICE_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  WATCHLIST AML
   ============================================ */
  if (country === 'watchlistAML') {
    loggerUTL('SAVING WATCHLIST AML FORM DATA...');
    sessionStorage.setItem('watchlistAMLFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_WATCHLISTAML_FORM_DATA,
      payload: formData,
    });
  }

  /* ============================================
                  FIND IDU
   ============================================ */
   if (country === FIND_IDU) {
    loggerUTL('SAVING FIND IDU SERVICES FORM DATA...');
    sessionStorage.setItem('findIDUFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_FIND_IDU_FORM_DATA,
      payload: formData,
    });
  }

    /* ============================================
                  Digital ID Safeguard
   ============================================ */
   if (country === 'digitalIdSafeguard') {
    loggerUTL('SAVING Digital ID Safeguard FORM DATA...');
    sessionStorage.setItem('digitalIdSafeguardFormData', JSON.stringify(formData));

    dispatch({
      type: SAVE_DIGITAL_ID_SAFEGUARD_FORM_DATA,
      payload: formData,
    });
  }
};

/* ============================================
                  RESET FORM DATA
   ============================================ */
export const resetFormDataAXN = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_DATA,
  });
};
