const translateTransactionHistory = {
  dataSource: '数据源：',
  reportingRef: '报告参考：',
  clientRef: '客户编号：',
  generatePDF: '产生PDF',
  search: '搜索',
  searches: '搜索次数',
  generationAvailable: '可生成PDF',
  generationUnavailable: 'PDF生成只能在24小时内生成',
  notApplicable: '不适用',
  unavailable: '不可用',
  next: '下一个',
  back: '背部',
  searchTransactionHistory: '搜索交易记录',
  filter: '搜索',
  new: '按最新排序',
  old: '依旧排序',
  to: '至',
  searchByDate: '日期搜索',
  today: '今天',
  singleDay: '单日搜索',
  dateRange: '日期区间搜索',
  showDetails: '显示详细信息',
  processingTime: '搜索时长: ',
  organisation : '公司: ',
  userIp: '用户Ip: ',
  username: '用户名: ',
  DOBYear: '出生年份: ',
  status: '状态: ',
  transactionDetials: '搜索交易详情',
  RequestData: '请求数据',
  ResponseData: '响应数据',
  requiredDateStart: '请提供开始时间',
  requiredDateEnd: '请提供结束时间',
  requiredDate: '请提供时间进行搜索',
  requiredDateOrRef: '请提供时间或编号进行搜索',
  noFutureDate: '无法选择将来的日期',
  startDateLaterThanEndDate: '开始日期应早于结束日期'
};

export default translateTransactionHistory;
