const translateCountrySelection = {
  selectCountry: '选择国家',
  subscription:
    '选择一个地区/国家以查看您订阅的数据源：',
  apac: '亚太地区',
  africa: '非洲',
  europe: '欧洲',
  middleEast: '中东',
  theAmericas: '美洲',
};

export default translateCountrySelection;
