import React from 'react';
import PropTypes from 'prop-types';

import { transUTL } from "../../utils/transUTL";
import isEmptyVAL from "../../validations/checks/isEmptyVAL";

const TransactionDetails = ({
  data,
  handleToggleShowDetails,
}) => {

  const country = data.Country.split(' ').join('').toLowerCase();

  let dataSources = data.DataSources.map((item) => {
    const dataSource = item.split(' ').join('').toLowerCase(); // JOIN TO A STRING WITH NO SPACE, ALL LOWERCASE
    return transUTL(`translateDataSources.${dataSource}`); // TRANSLATE DATA SOURCES
  });

  // REMOVE EMPTY SLOT
  dataSources = dataSources.filter(
    (dataSource) => !isEmptyVAL(dataSource)
  );

  const { ErrorMessage, ReturnedData, Verifications, ...responses } = data.NonPIIResponse;

  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2>
          {transUTL('translateTransactionHistory.transactionDetials')}
        </h2>
      </header>
      <div className="common-form__body">
        <div className='trans-history__transaction trans-history__border-success'>

          <div className="trans-history__body">
            <header className="trans-history__header">
              <h4>{transUTL(`translateCountryNames.${country}`)}</h4>
              <p className="trans-history__transaction-timestamp">
                {data.CreatedAt.split('.')[0].split('T')[0]}
                &nbsp;&nbsp;
                {data.CreatedAt.split('.')[0].split('T')[1]}
                &nbsp;&nbsp;UTC
              </p>
            </header>

            <p>
              {transUTL(`translateTransactionHistory.dataSource`)}{' '}
              {dataSources.join(', ')}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.reportingRef`)}{' '}
              {!isEmptyVAL(data.KMSReference) && data.KMSReference}
              {!isEmptyVAL(data.DZReference) && data.DZReference}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.processingTime`)}{' '}
              {isEmptyVAL(data.ProcessingTime)
                ? transUTL(`translateReuse.na`)
                : data.ProcessingTime}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.organisation`)}{' '}
              {isEmptyVAL(data.Organisation)
                ? transUTL(`translateReuse.na`)
                : data.Organisation}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.userIp`)}{' '}
              {isEmptyVAL(data.UserIp)
                ? transUTL(`translateReuse.na`)
                : data.UserIp}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.username`)}{' '}
              {isEmptyVAL(data.Username)
                ? transUTL(`translateReuse.na`)
                : data.Username}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.DOBYear`)}{' '}
              {isEmptyVAL(data.DOBYear)
                ? transUTL(`translateReuse.na`)
                : data.DOBYear}
            </p>

            <p>
              {transUTL(`translateTransactionHistory.status`)}{' '}
              {isEmptyVAL(data.Status)
                ? transUTL(`translateReuse.na`)
                : data.Status}
            </p>

            <div className="trans-history__filter-input">
              <div className="data-list">
                <h4>{transUTL('translateTransactionHistory.RequestData')}</h4>
                {
                  Object.entries(data.RequestData).map((item, index) => <p key={index}>{`${item[0]}: ${item[1]}`}</p>)
                }
              </div>
              <div className="data-list">
                <h4>{transUTL('translateTransactionHistory.ResponseData')}</h4>
                <div>
                  {
                    Object.entries(responses).map((item, index) => <p key={index}>{`${item[0]}: ${isEmptyVAL(item[1]) ? 'N/A' : item[1]}`}</p>)
                  }
                </div>
                <div>
                  <p>{`Verifications: ${isEmptyVAL(Verifications) ? 'N/A' : ''}`}</p>
                  {!isEmptyVAL(Verifications) && (
                    <div style={{ marginLeft: '1.5rem' }}>
                      {Object.entries(Verifications).map((item, index) => <p key={index}>{`${item[0]}: ${item[1]}`}</p>)}
                    </div>
                  )}
                </div>
                <div>
                  <p>{`Returned Data: ${isEmptyVAL(ReturnedData) ? 'N/A' : ''}`}</p>
                  {!isEmptyVAL(ReturnedData) && (
                    <div style={{ marginLeft: '1.5rem' }}>
                      {Object.entries(ReturnedData).map((item, index) => <p key={index}>{`${item[0]}: ${item[1]}`}</p>)}
                    </div>
                  )}
                </div>
                <p>{`Error Message(s): ${isEmptyVAL(ErrorMessage) ? 'N/A' : Object.values(ErrorMessage)}`}</p>
              </div>
            </div>
          </div>
          
        </div>

        <div className={'btn-container btn-under'}>
          <button className="btn-primary" onClick={() => handleToggleShowDetails(data.DZReference)}>
            {transUTL('translateBtn.previousPage')}
          </button>

          <button className="btn-primary" onClick={() => window.print()}>
            {transUTL('translateBtn.printResults')}
          </button>
        </div>
      </div>
    </div>
  );
}

TransactionDetails.propTypes = {
};

export default TransactionDetails;
