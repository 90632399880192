

// HELPERS
import title from "../../helpers/title";

// IDENTITY DATA
import { verificationResultsDataFields, specialServiceNames } from "../displayingStringsForVerificationDataFields/verificationResultsDataFields";

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

// UTILS
import arrayTo2DArrayUTL from "../../../../utils/arrayTo2DArrayUTL";

const dataSourceVerificationResults = (doc, response, docStyles) => {
  // TITLE
  title(
    doc,
    docStyles,
    "Summary of Data Source Verifications and Results",
    docStyles.headers.secondary,
    docStyles.separation
  );

  // MAP VERIFICATION RESULTS
  // =================================================================

  const { countryCode, serviceResponses } = response;
  const { generalFields } = verificationResultsDataFields;
  const countryName = isEmptyVAL(countryCode) ? '' : countryCode
  const serviceResponsesObj = isEmptyVAL(serviceResponses) ? {} : serviceResponses
  const specificDisplayedDataFields =
    isEmptyVAL(verificationResultsDataFields[countryName])
      ? {}
      : verificationResultsDataFields[countryName];
  // 'resultDisplayedDataFields is for displaying selected datasources' data fields in PDF verification result section
  const resultDisplayedDataFields = { ...generalFields, ...specificDisplayedDataFields };

  // DEFAULT COLUMN / ROW
  const columns = [{ header: "Data Sources", dataKey: "col1" }];
  let rows = []

  /* 
  let sample = {
    rows: [
      {
        col1: 'Status',
        'China ID': '0',
        'China Phone': '2'
      },
      {
        col1: 'First Name',
        'China ID': 'Jack',
        'China Phone': 'Rose'
      }
    ],
    columns: [
      { header: 'Data Source', dataKey: 'col1' },
      { header: 'China ID', dataKey: 'China ID' },
      { header: 'China Phone', dataKey: 'China Phone' }
    ]
  }
  */

  // step 1: Get all selected services' response data and put them in a flat Object 'flatResponseDataObj'
  let services = Object.entries(serviceResponsesObj);
  let flatResponseDataObj = {}
  services.forEach(service => {
    let { returnedData, verifications, errorMessage, sourceStatus, ...identityData } = service[1]
    flatResponseDataObj = { ...flatResponseDataObj, ...verifications, ...identityData }
  })

  // step 2: Display rows in col1: data field names
  let responseDataFields = Object.keys(flatResponseDataObj)
  const dataFields = Object.keys(resultDisplayedDataFields)
  responseDataFields = responseDataFields.filter(dataField => dataFields.includes(dataField))
  if (!isEmptyVAL(responseDataFields)) {
    responseDataFields.forEach(key => {
      /*
        Please add expected displaying field name in resultDisplayedDataFields object if there isn't any.
        e.g. : driverLicenseNumber : 'Driver License No. Verified'
      */
      let displayingFieldName = isEmptyVAL(resultDisplayedDataFields[key]) ? key : resultDisplayedDataFields[key];
      rows.push({ col1: displayingFieldName });
    })
  }

  services.forEach(service => {
    const serviceName = service[0];
    const serviceData = service[1];
    const { errorMessage, returnedData, verifications, ...restVerifications } = serviceData;
    const flatResponseDataObj = { ...verifications, ...restVerifications };

    // step 3: Display headers: selected data source names
    /*
      Some service's name in response is not the expecting name shown in the PDF. e.g. 'Australia Government Identity Documents 1'.
      Please add expected displaying data source name in specialServiceNames object if there isn't any.
    */
    const specialServiceNamesArray = Object.keys(specialServiceNames);
    let displayName = serviceName;
    if (specialServiceNamesArray.includes(serviceName)) {
      displayName = specialServiceNames[serviceName];
    }
    columns.push({
      header: serviceName === 'Watchlist AML' ? "Global Watchlist AML" : displayName,
      dataKey: serviceName,
    })

    // step 4: Map value for each service's each data field in each body cell
    rows = rows.map(row => {
      const dataFieldsArr = Object.entries(resultDisplayedDataFields);
      const keyArrays = dataFieldsArr.filter(fieldArr => fieldArr[1] === row.col1)
      const key = !isEmptyVAL(keyArrays) ? keyArrays[0][0] : ''
      const value = !isEmptyVAL(flatResponseDataObj[key]) ? flatResponseDataObj[key] : 'N/A'
      row = { ...row, [serviceName]: value };
      return row;
    })
  })

  // ==========================================================

  // TABLE
  let finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;
  const maxAmountOfColumns = 3;
  const firstColumn = columns.shift();
  const maxColArray = arrayTo2DArrayUTL(columns, maxAmountOfColumns); // 3 COLUMNS ONLY

  maxColArray.map((col, index) => {
    col.unshift(firstColumn);

    if (index > 0) {
      finalY = doc.previousAutoTable.finalY + docStyles.separation;
    }

    let cellWidth = docStyles.fullCellLength / 4;
    if (maxColArray[index].length === 4) {
      cellWidth = docStyles.fullCellLength / 4;
    } else if (maxColArray[index].length === 3) {
      cellWidth = docStyles.fullCellLength / 3;
    } else if (maxColArray[index].length === 2) {
      cellWidth = docStyles.fullCellLength / 2;
    }

    doc.autoTable({
      columns: col,
      body: rows,
      didParseCell: data => {
        const { cell } = data;
        cell.styles.fillColor = [255, 255, 255];
        if (cell.section.includes("body") && cell.raw.length > 30) {
          cell.styles.cellPadding = { top: 15, bottom: 15 };
        }
      },
      showHead: "firstPage",
      styles: {
        textColor: docStyles.colorText,
        font: docStyles.fontFamily.time,
        ...docStyles.tableStyles
      },
      headStyles: {
        fontSize: docStyles.textSize,
        textColor: docStyles.colorGreen,
        fontStyle: "normal"
      },
      columnStyles: {
        col1: {
          font: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth
        },
        col2: {
          font: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth
        },
        col3: {
          font: docStyles.fontFamily.time,
          textColor: docStyles.colorText,
          cellWidth: cellWidth
        }
      },
      startY: finalY
    });
    return null;
  });
};

export default dataSourceVerificationResults;
