import { SET_NORMAL_ALERT, REMOVE_ALERT } from '../types';
import { v4 as uuidv4 } from 'uuid';

// SET ALERT
export const setAlertAXN = (msg, type = 'normal', timeout = 8000) => (
  dispatch
) => {
  const id = uuidv4();
  dispatch({
    type: SET_NORMAL_ALERT,
    payload: { msg, type, id },
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

// CLEAR CURRENT
export const clearCurrentAlertAXN = (alert) => {
  return { type: REMOVE_ALERT, payload: alert.id };
};
