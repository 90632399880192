const stateMY = {
  johor: '柔佛州',
  kedah: '吉打州',
  kelantan: '吉兰丹州',
  kualaLumpur: '吉隆坡',
  labuan: '纳闽',
  malacca: '马六甲',
  negeriSembilan: '森美兰',
  pahang: '彭亨',
  penang: '槟城',
  perak: '霹雳州',
  perlis: '玻璃市',
  putrajaya: '布城',
  sabah: '沙巴州',
  sarawak: '砂拉越',
  selangor: '雪兰莪州',
  terengganu: '登嘉楼',
};

export default stateMY;
