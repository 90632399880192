import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { uploadBiometricScansAXN } from '../../actions/transactionHistory/transactionHistoryActions';

// COUNTRY FORMS
import DigitalIdSafeguardResults from './DigitalIdSafeguardResults/digitalIdSafeguard/DigitalIdSafeguardResults';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import clearUserDataUTL from '../../utils/clearUserDataUTL';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

const DigitalIdSafeguardSearchVerification = ({
  authRXS,
  countryRXS,
  regionRXS,
  dataSourcesRXS,
  biometricsRXS,
  searchVerificationRXS,
  uploadBiometricScansAXN,
  history,
}) => {

  useEffect(() => {
    // ONCE 'isAuthenticated' CHANGES, THIS WILL FIRE SENDING THE USER TO THE DASHBOARD
    if (!isEmptyVAL(searchVerificationRXS)) {
      uploadBiometricScansAXN(
        // TODO: PLACE THIS AT AXIOS REQUEST (SEARCH VERIFICATIONS ACTIONS) TO AVOID RESUBMITTING ON PAGE REFRESH
        searchVerificationRXS.reportingReference,
        biometricsRXS,
        countryRXS
      );
    } else {
      history.push('/digital-id-safeguard');
    }

    // eslint-disable-next-line
  }, []);

  let watchlistStatus = <span>{transUTL('translateReuse.na')}</span>;
  // KMS
  if (searchVerificationRXS.watchlistAML) {
    if (searchVerificationRXS.watchlistAML[0].verified) {
      watchlistStatus = (
        <span className="search-verification__error">
          {transUTL('translateSearchVerification.watchlistStatusFOUND')}
        </span>
      );
    } else {
      watchlistStatus = (
        <span className="search-verification__success">
          {transUTL('translateSearchVerification.watchlistStatusClear')}
        </span>
      );
    }
  }
  // DZ
  if (!isEmptyVAL(searchVerificationRXS.serviceResponses)) {
    if (!isEmptyVAL(searchVerificationRXS.serviceResponses['Watchlist AML'])) {
      const { identityVerified, returnedData } = searchVerificationRXS.serviceResponses['Watchlist AML'];
      if (identityVerified) {
        // C6 whitelist:true --> status: FOUND (WHITELIST)
        if (returnedData.watchlistResults
          && returnedData.watchlistResults.length > 0
          && returnedData.watchlistResults[0].whitelist) {
          watchlistStatus = (
            <span className="search-verification__error">
              {transUTL('translateSearchVerification.watchlistStatusFOUND2')}
            </span>
          );
        } else {
          watchlistStatus = (
            <span className="search-verification__error">
              {transUTL('translateSearchVerification.watchlistStatusFOUND')}
            </span>
          );
        }
      } else {
        watchlistStatus = (
          <span className="search-verification__success">
            {transUTL('translateSearchVerification.watchlistStatusClear')}
          </span>
        );
      }
    }
  }
  const handleClearResults = () => {
    clearUserDataUTL();
    history.push('/digital-id-safeguard');
  };

  const handleCountryFormDisplay = () => {
    switch (countryRXS) {
      case 'digitalIdSafeguard':
        return (
          <DigitalIdSafeguardResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="search-verification common-form">
      <header className="header-primary">
        <h2>{transUTL('translateSearchVerification.title')}</h2>
        <span></span>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.reportingReference')}
            <span>{searchVerificationRXS.reportingReference}</span>
          </p>
          <p>
            {transUTL('translateSearchVerification.issueDateTime')}{' '}
            <span>{searchVerificationRXS.dateTime}</span>
          </p>
        </div>

        {handleCountryFormDisplay()}
      </div>
    </div>
  );
};

DigitalIdSafeguardSearchVerification.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  regionRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  searchVerificationRXS: PropTypes.object.isRequired,
  biometricsRXS: PropTypes.object.isRequired,
  uploadBiometricScansAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  regionRXS: state.regionRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  searchVerificationRXS: state.searchVerificationRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, { uploadBiometricScansAXN })(
  DigitalIdSafeguardSearchVerification
);
