import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const BirthCertificateNewZealand = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    birthCertificateNo,
    placeOfBirth,
    mothersFirstName,
    mothersLastName,
    fathersFirstName,
    fathersLastName,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['DIA-Birth'],
      element: (
        <TextFieldGroup
          key="birthCertificateNo"
          id="birthCertificateNo"
          type="text"
          name="birthCertificateNo"
          dataName="birthCertificateNoNZ"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.birthCertificateNo'
          )}
          value={birthCertificateNo}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.birthCertificateNo'
          )}
          placeholderTrigger={
            isEmptyVAL(birthCertificateNo) ? 'input-empty' : ''
          }
          error={errors.birthCertificateNo}
          errorTrigger={errors.birthCertificateNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['DIA-Birth'],
      element: (
        <TextFieldGroup
          key="placeOfBirth"
          id="placeOfBirth"
          type="text"
          name="placeOfBirth"
          dataName="placeOfBirthNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.placeOfBirth')}
          value={placeOfBirth}
          placeholder={transUTL('translatePlaceholder.inputNZ.placeOfBirth')}
          placeholderTrigger={isEmptyVAL(placeOfBirth) ? 'input-empty' : ''}
          error={errors.placeOfBirth}
          errorTrigger={errors.placeOfBirth ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['DIA-Birth'],
      element: (
        <TextFieldGroup
          key="mothersFirstName"
          id="mothersFirstName"
          type="text"
          name="mothersFirstName"
          dataName="mothersFirstNameNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.mothersFirstName')}
          value={mothersFirstName}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.mothersFirstName'
          )}
          placeholderTrigger={isEmptyVAL(mothersFirstName) ? 'input-empty' : ''}
          error={errors.mothersFirstName}
          errorTrigger={errors.mothersFirstName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['DIA-Birth'],
      element: (
        <TextFieldGroup
          key="mothersLastName"
          id="mothersLastName"
          type="text"
          name="mothersLastName"
          dataName="mothersLastNameNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.mothersLastName')}
          value={mothersLastName}
          placeholder={transUTL('translatePlaceholder.inputNZ.mothersLastName')}
          placeholderTrigger={isEmptyVAL(mothersLastName) ? 'input-empty' : ''}
          error={errors.mothersLastName}
          errorTrigger={errors.mothersLastName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['DIA-Birth'],
      element: (
        <TextFieldGroup
          key="fathersFirstName"
          id="fathersFirstName"
          type="text"
          name="fathersFirstName"
          dataName="fathersFirstNameNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.fathersFirstName')}
          value={fathersFirstName}
          placeholder={transUTL(
            'translatePlaceholder.inputNZ.fathersFirstName'
          )}
          placeholderTrigger={isEmptyVAL(fathersFirstName) ? 'input-empty' : ''}
          error={errors.fathersFirstName}
          errorTrigger={errors.fathersFirstName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['DIA-Birth'],
      element: (
        <TextFieldGroup
          key="fathersLastName"
          id="fathersLastName"
          type="text"
          name="fathersLastName"
          dataName="fathersLastNameNZ"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.fathersLastName')}
          value={fathersLastName}
          placeholder={transUTL('translatePlaceholder.inputNZ.fathersLastName')}
          placeholderTrigger={isEmptyVAL(fathersLastName) ? 'input-empty' : ''}
          error={errors.fathersLastName}
          errorTrigger={errors.fathersLastName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

BirthCertificateNewZealand.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default BirthCertificateNewZealand;
