import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD CHINA API ERRORS TO INPUT FORM
const feedbackCN = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'dateOfBirth')) {
      inputError.dateOfBirth = `Date of Birth ${errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
        }`;
    }

    /* ============================================
              CHINESE SEARCH
       ============================================ */
    if (findErrorUTL(errors, 'idCardNo')) { 
      const idCardNoErrorMsg = errors.find((item) => item.includes('idCardNo')).split(':')
      if (idCardNoErrorMsg.length === 1) {
        inputError.idCardNo = idCardNoErrorMsg[0];
      }
      if (idCardNoErrorMsg.length > 1) {
        inputError.idCardNo = `ID card number is ${idCardNoErrorMsg[1]}`;
      }
    }
    if (findErrorUTL(errors, 'Invalid National ID')) {
      inputError.idCardNo = errors.find((item) => item.includes('Invalid National ID'))
    }
    if (findErrorUTL(errors, 'passportNo')) {
      const passportErrorMsg = errors.find((item) => item.includes('passportNo')).split(':')
      if (passportErrorMsg.length = 1) {
        inputError.passportNo = passportErrorMsg[0];
      }
      if (passportErrorMsg.length > 1) {
        inputError.passportNo = `Passport number ${passportErrorMsg[1]}`;
      }
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES CHINA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackCN;
