import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const DriversLicenceAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    driversLicenceNo,
    driversLicenceCardNo,
    driversLicenceStateOfIssue,
    driversLicenceState,
    driversLicenceExpiryDate,
    errors,
  } = formState;

  const driversLicenceStateOfIssueOptions = [
    {
      label: transUTL('translateDropdown.select'),
      value: '',
    },
    {
      label: transUTL('translateDropdown.stateAU.queensland'),
      value: 'QLD',
    },
    {
      label: transUTL('translateDropdown.stateAU.newSouthWales'),
      value: 'NSW',
    },
    {
      label: transUTL('translateDropdown.stateAU.southAustralia'),
      value: 'SA',
    },
    {
      label: transUTL('translateDropdown.stateAU.tasmania'),
      value: 'TAS',
    },
    {
      label: transUTL('translateDropdown.stateAU.victoria'),
      value: 'VIC',
    },
    {
      label: transUTL('translateDropdown.stateAU.westernAustralia'),
      value: 'WA',
    },
    {
      label: transUTL('translateDropdown.stateAU.australianCapitalTerritory'),
      value: 'ACT',
    },
    {
      label: transUTL('translateDropdown.stateAU.northernTerritory'),
      value: 'NT',
    },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: ['Australia Driver Licence'],
      element: (
        <TextFieldGroup
          key="driversLicenceNo"
          id="driversLicenceNo"
          type="text"
          name="driversLicenceNo"
          dataName="driversLicenceNoAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.driversLicenceNo')}
          value={driversLicenceNo}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.driversLicenceNo'
          )}
          placeholderTrigger={isEmptyVAL(driversLicenceNo) ? 'input-empty' : ''}
          error={errors.driversLicenceNo}
          errorTrigger={errors.driversLicenceNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },

    {
      belongsTo: ['Australia Driver Licence'],
      element: (
        <SelectListGroup
          key="driversLicenceStateOfIssue"
          id="driversLicenceStateOfIssue"
          name="driversLicenceStateOfIssue"
          dataName="driversLicenceStateOfIssueAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.driversLicenceStateOfIssue'
          )}
          value={driversLicenceStateOfIssue}
          placeholderTrigger={
            isEmptyVAL(driversLicenceStateOfIssue) ? 'input-empty' : ''
          }
          options={driversLicenceStateOfIssueOptions}
          error={errors.driversLicenceStateOfIssue}
          errorTrigger={errors.driversLicenceStateOfIssue ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Driver Licence'],
      element: (
        <TextFieldGroup
          key="driversLicenceExpiryDate"
          id="driversLicenceExpiryDate"
          type="date"
          name="driversLicenceExpiryDate"
          dataName="driversLicenceExpiryDateAU"
          labelClass="form-group__label"
          label={transUTL(
            'translateFormOverview.inputLabels.driversLicenceExpiryDate'
          )}
          value={driversLicenceExpiryDate}
          placeholder={transUTL(
            'translatePlaceholder.inputAU.driversLicenceExpiryDate'
          )}
          placeholderTrigger={
            isEmptyVAL(driversLicenceExpiryDate) ? 'input-empty' : ''
          }
          max="9999-12-31"
          error={errors.driversLicenceExpiryDate}
          errorTrigger={errors.driversLicenceExpiryDate ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Australia Driver Licence'],
      element: (
        <div className="form-overview__additional-text" key="driversLicenceCardNo">
          <TextFieldGroup
            key="driversLicenceCardNo"
            id="driversLicenceCardNo"
            type="text"
            name="driversLicenceCardNo"
            dataName="driversLicenceCardNoAU"
            labelClass="form-group__label"
            label={transUTL('translateFormOverview.inputLabels.driversLicenceCardNo')}
            value={driversLicenceCardNo}
            placeholder={transUTL(
              'translatePlaceholder.inputAU.driversLicenceCardNo'
            )}
            placeholderTrigger={isEmptyVAL(driversLicenceCardNo) ? 'input-empty' : ''}
            error={errors.driversLicenceCardNo}
            errorTrigger={errors.driversLicenceCardNo && driversLicenceStateOfIssue !== "VIC" ? 'input-error' : ''}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            handleEnterKeyTrigger={handleEnterKeyTrigger}
            driverLicenceStateOfIssue={driversLicenceStateOfIssue}
          />
          {(driversLicenceStateOfIssue === "VIC") && (
            <p>{transUTL('translateFormOverview.australiaDriverLicenseCardNo')}</p>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

DriversLicenceAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default DriversLicenceAustralia;
