const dataSourcesMX = {
  nationalID: {
    title: '墨西哥国民身份证',
    searchSuccessful: '搜索成功',
    identityVerified: '已验证',
    safeHarbourScore: '安全港评分',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    dateOfBirth: '出生日期',
    gender: '性别',
    firstName: '名字',
    lastName: '姓',
    curp: 'curp',
    rfc: '射频控制',
  },

  taxRegistration: {
    title: '墨西哥税务登记',
    searchSuccessful: '搜索成功',
    identityVerified: '已验证',
    safeHarbourScore: '安全港评分',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字比赛分数',
    dateOfBirth: '出生日期',
    gender: '性别',
    firstName: '名字',
    lastName: '姓',
    rfc: '射频控制',
    curp: 'curp',
  },
};

export default dataSourcesMX;
