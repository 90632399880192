const dataSourcesCN = {

  identityVerified: 'Disahkan',
  searchSuccessful: 'Carian Berjaya',
  name: 'Nama',
  dateOfBirth: 'Tarikh lahir',
  safeHarbourScore: 'Skor Pelabuhan Selamat',
  documentNo: 'nombor dokumen',
  nameMatchScore: 'Markah Padanan Nama',
  gender: 'Jantina',
  address: 'Alamat',
  idCardNo: 'Kad ID Nombor',

  idVerification: {
    title: 'Pengesahan ID China',
  },
  phone: {
    title: 'Telefon China',
    phoneVerified: 'Nombor telefon',
  },
  bankCard: {
    title: 'Kad Bank China',
    fullName: 'Nama Penuh',
    bankCardNo: 'No Kad Bank',
    idCardNo: 'kad id no',
  },
  passportVerification: {
    title: 'Pengesahan Pasport China',
    passportNo: 'Nombor pasport',
  },
  driverLicense: {
    title: 'Lesen Memandu China',
  }
};

export default dataSourcesCN;
