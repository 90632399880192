const arrayTo2DArrayUTL = (list, howMany) => {
  let idx = 0;
  const result = [];

  while (idx < list.length) {
    if (idx % howMany === 0) result.push([]);
    result[result.length - 1].push(list[idx++]);
  }

  return result;
};

export default arrayTo2DArrayUTL;
