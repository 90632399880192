const consentCheckerMY = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('Malaysia Credit Bureau')) {
    // CONSENT COLLECTOR
    consentObtained['Malaysia Credit Bureau'] = true;

    if (!inputData.creditBureauConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Malaysia National ID')) {
    // CONSENT COLLECTOR
    consentObtained['Malaysia National ID'] = true;

    if (!inputData.nationalIdConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerMY;
