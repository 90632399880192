const inputFR = {
  // PERSONAL INFORMATION
  firstName: '',
  lastName: '',
  dateOfBirth: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetAddress: '',
  city: '',
  state: '',
  postCode: '',

  // CONTACT DETAILS
  phoneNo: '',
  emailAddress: '',

  // REFERENCE
  reportingReference: '',

  // TEMPORARY
  message: 'Message...',
};

export default inputFR;
