const translateDocumentLibrary = {
  gdprCompliance: 'Pematuhan Data Zoo GDPR',
  securityPolicy: 'Dasar Keselamatan Maklumat Zoo Data',
  maintenance: 'Sokongan & Penyelenggaraan',
  lastUpdated: 'Terakhir dikemas kini',
  documentLibrary: 'Perpustakaan Dokumen Zoo Data',
  viewDocuments: 'Lihat Dokumen',
  documents: 'dokumen',
};

export default translateDocumentLibrary;
