const dataSourcesMY = {
  creditBureau: {
    title: 'Malaysia Credit Bureau',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    city: 'City',
    dateOfBirth: 'Date Of Birth',
    nameMatchScore: 'Name Match Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    nationalIDNo: 'National ID',
    postCode: 'Post Code',
    state: 'State',
    street: 'Street',
    addressMatchScore: 'Address Match Score',
  },
  nationalID: {
    title: 'Malaysia National ID',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    state: 'State',
    name: 'Name',
    dateOfBirth: 'Date Of Birth',
    nameMatchScore: 'Name Match Score',
    nationalIDNo: 'National ID',
    postCode: 'Post Code',
    city: 'City',
    street: 'Street',
    gender: 'Gender',
    addressMatchScore: 'Address Match Score',
  },
};

export default dataSourcesMY;
