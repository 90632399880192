const consentCheckerDE = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  if (dataSources.includes('Germany Credit Bureau')) {
    consentObtained['Germany Credit Bureau'] = true;

    if (!inputData.creditBureauConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
}

export default consentCheckerDE;