/*
  This array is being used temporarily.  It will be deleted after the template 
  has been applied to all services of all countries.
*/
const FIELDS_FOR_TEMPLATE = [
  'Peru Residential',
  // 'Chile Residential',
  'Argentina National ID',
  // 'Canada Phone'
];

export default FIELDS_FOR_TEMPLATE;