import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD INDIA API ERRORS TO INPUT FORM
const feedbackIN = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    /* ============================================
          CURRENT RESIDENTIAL ADDRESS
     ============================================ */
     if (findErrorUTL(errors, 'addressElement5')) {
      inputError.postCode = `Post code should be ${
        errors.find((item) => item.includes('addressElement5')).split(':')[1]
      }`;
    }
    
    /* ============================================
              ACCOUNT NUMBER
       ============================================ */
    if (findErrorUTL(errors, 'pan')) {
      inputError.pan = `PAN number ${
        errors.find((item) => item.includes('pan')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'epic')) {
      inputError.epic = `EPIC number ${
        errors.find((item) => item.includes('epic')).split(':')[1]
      }`;
    }    
    if (findErrorUTL(errors, 'aadhaarNo')) {
      inputError.aadhaarNo = `Aadhaar number should be ${
        errors.find((item) => item.includes('aadhaarNo')).split(':')[1]
      }`;
    }
    /* ============================================
              DRIVERS LICENCE
       ============================================ */
    if (findErrorUTL(errors, 'driversLicenceNumber')) {
      inputError.driversLicenceNumber = `Driver licence number should be${
        errors
          .find((item) => item.includes('driversLicenceNumber'))
          .split(':')[1]
      }`;
    }

    /* ============================================
              PASSPORT
       ============================================ */
    if (findErrorUTL(errors, 'passportNo')) {
      inputError.passportNo = `Passport number ${
        errors.find((item) => item.includes('passportNo')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'fileNo')) {
      const fileNoErr = errors.find((item) => item.includes('fileNo')).split(':');
      const errKey = fileNoErr[0].trim();
      const errMsg = fileNoErr[1];
      if (errKey === 'Invalid value for fileNo') inputError.fileNo = errMsg
      if (errKey === 'fileNo') inputError.fileNo = `File number ${errMsg}`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE INDIA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackIN;
