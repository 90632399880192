import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// COUNTRY FORMS
import AustraliaForms from './countryForms/australia/AustraliaForms';
import BrazilForms from './countryForms/brazil/BrazilForms';
import ChinaForms from './countryForms/china/ChinaForms';
import IndiaForms from './countryForms/india/IndiaForms';
import IndonesiaForms from './countryForms/indonesia/IndonesiaForms';
import MalaysiaForms from './countryForms/malaysia/MalaysiaForms';
import VietnamForms from './countryForms/vietnam/VietnamForms';
import PhilippinesForms from './countryForms/philippines/PhilippinesForms';
import CambodiaForms from './countryForms/cambodia/CambodiaForms';
import NewZealandForms from './countryForms/newzealand/NewZealandForms';
import NigeriaForms from './countryForms/nigeria/NigeriaForms';
import HongKongForms from './countryForms/hongKong/HongKongForms';
import SingaporeForms from './countryForms/singapore/SingaporeForms';
import UnitedStatesForms from './countryForms/unitedState/UnitedStatesForms';
import GermanyForms from './countryForms/germany/GermanyForms';
import MexicoForms from './countryForms/mexico/MexicoForms';
import FranceForms from './countryForms/france/FranceForms';
import ItalyForms from './countryForms/italy/ItalyForms';
import SpainForms from './countryForms/spain/SpainForms';
// import GlobalAllForms from './countryForms/globalAll/GlobalAllForms'; // TODO: DELETE THIS WHEN READY

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import selectedDataSourceUTL from '../../utils/selectedDataSourceUTL';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';
import { setAlertAXN } from '../../actions/alert/alertActions';

const formOverview = ({
  authRXS: { user },
  countryRXS,
  regionRXS,
  dataSourcesRXS,
  biometricsRXS,
  setAlertAXN,
  history,
}) => {
  const selectedDataSources = selectedDataSourceUTL();
  if (isEmptyVAL(selectedDataSources)) {
    history.push('/data-source-selection');

    return null;
  }

  const handleCountryFormDisplay = () => {
    // ADD NEW COUNTRY FORMS HERE
    // COUNTRIES NAMES ARE SAVE IN `countryRXS` - ALL LOWERCASE WITH NO SPACE e.g New Zealand = newzealand
    switch (countryRXS) {
      case 'australia':
        return <AustraliaForms />;
      case 'brazil':
        return <BrazilForms />;	
      case 'china':
        return <ChinaForms />;
      case 'hongkong':
        return <HongKongForms />;
      case 'india':
        return <IndiaForms />;
      case 'indonesia':
        return <IndonesiaForms />;
      case 'malaysia':
        return <MalaysiaForms />;
      case 'vietnam':
        return <VietnamForms />;
      case 'philippines':
        return <PhilippinesForms />;
      case 'cambodia':
        return <CambodiaForms />;
      case 'newzealand':
        return <NewZealandForms />;
      case 'nigeria':
        return <NigeriaForms />;
      case 'singapore':
        return <SingaporeForms />;
      case 'unitedstates':
        return <UnitedStatesForms />;
      case 'germany':
        return <GermanyForms />;
      case 'mexico':
        return <MexicoForms />;
      case 'france':
        return <FranceForms />;
      case 'italy':
        return <ItalyForms />;
      case 'spain':
        return <SpainForms />;
      default:
        return null;
    }
  };

  // TODO: temporary fix. This should be handled in Auth in the future.
  // Always returning true
  let ocrAccess = false;
  user.countries.forEach((item) => {
    if (item.region.toLowerCase() === 'global') {
      if (item.dataSources.includes('Global OCR')) {
        ocrAccess = true;
      }
    }
  })

  const {
    isDocScanned,
    isBioAuth,
    isDocUploaded,
    isSelfieUploaded,
    // isLiveness, ADD WHEN FEATURE IS AVAILABLE
    // isDocAuth, ADD WHEN FEATURE IS AVAILABLE
  } = biometricsRXS.bioTriggers;

  return (
    <div className="data-source-selection common-form">
      <header className="header-primary">
        <h2>{transUTL(`translateCountryNames.${countryRXS.toLowerCase()}`)}</h2>
        <span
          className="material-icons icon-size"
          title="Edit Country"
          onClick={() => history.push('/country-selection')}
        >
          create
        </span>
      </header>
      <div className="common-form__body common-form__margin-bottom">
      {/* selectedServices */}
        <div className="form-overview__top">
          <header className="header-secondary">
            <h4 className="header-title-secondary">
              {transUTL('translateFormOverview.formHeaders.selectedServices')}
            </h4>
            <span
              className="material-icons icon-size"
              title="Edit Data Source"
              onClick={() => history.push('/data-source-selection')}
            >
              create
            </span>
          </header>
          <p className="data-source-selection__selected-data">
            {selectedDataSources
              .sort()
              .map((item, index) => {
                const dataSource = item.split(' ').join('').toLowerCase();
                const i18nKeyDataSourceString = `translateDataSources.${dataSource}`;

                return (
                  <span key={index}>{transUTL(i18nKeyDataSourceString)}</span>
                );
              })
              .reduce((prev, curr) => [
                prev,
                <span key={prev}>, &nbsp;</span>,
                curr,
              ])}
          </p>
        </div>
        {/* ocrAndBiometric */}
        <div className="form-overview__top">
          <header className="header-secondary">
            <h4 className="header-title-secondary">
              {transUTL('translateFormOverview.formHeaders.ocrAndBiometric')}
            </h4>
          </header>

          <div className="form-overview__ocr-icons">
            {/* DOCUMENT UPLOAD */}
            <div
              className={
                isDocUploaded
                  ? 'form-overview__ocr-item form-overview__ocr-item-disabled' 
                  : 'form-overview__ocr-item'
              }
              onClick={() => {
                if (!isDocUploaded) {
                  if (countryRXS === 'china') {
                    history.push('/document-scan/china');
                  } else if (countryRXS === 'vietnam') {
                    history.push('/document-scan/vietnam');
                  } else {
                    history.push(`/document-scan`);
                  }
                }
              }}
            >
              <span
                className={
                  isDocUploaded
                    ? 'material-icons not-allowed'  
                    : 'material-icons form-overview__ocr-item-no-cursor'
                }
              >
                payment
              </span>
              <p>
                {transUTL('translateFormOverview.document')}{' '}
                {transUTL('translateFormOverview.scan')}
              </p>

              {isDocScanned ? (
                <span className="material-icons form-overview__ocr-error no-cursor success">
                  check_circle
                </span>
              ) : (
                  <span
                    className={'material-icons form-overview__ocr-error no-cursor error'} //Without word "error", switch from red to gray cross check
                  >
                    cancel
                  </span>
                )}
            </div>
            <span className="material-icons no-cursor form-overview__arrows ">
              double_arrow
            </span>

            {/* SELFIE UPLOAD */}
            <div
              className={
                (isDocUploaded || isSelfieUploaded)
                  ? 'form-overview__ocr-item form-overview__ocr-item-disabled'
                  : 'form-overview__ocr-item'
              }
              onClick={() => {
                if (!isDocUploaded && !isSelfieUploaded) {
                  if (isDocScanned) {
                    history.push('/biometric-auth');
                  } else {
                    setAlertAXN(
                      transUTL('translateAlertMsg.completeDocumentScanFirst'),
                      'error'
                    );
                  }
                }
              }}
            >
              <span
                className={
                  (isDocUploaded || isSelfieUploaded)
                    ? 'material-icons not-allowed' 
                    : 'material-icons form-overview__ocr-item-no-cursor'
                }
              >
                face
              </span>
              <p>
                {transUTL('translateFormOverview.biometric')}{' '}
                {transUTL('translateFormOverview.authentication')}
              </p>
              {isBioAuth ? (
                <span className="material-icons form-overview__ocr-error no-cursor success">
                  check_circle
                </span>
              ) : (
                  <span
                    className={'material-icons form-overview__ocr-error no-cursor error'}
                  >
                    cancel
                  </span>
                )}
            </div>
            <span className="material-icons no-cursor form-overview__arrows">
              double_arrow
            </span>
            <div className="form-overview__ocr-item form-overview__ocr-item-disabled">
              <span className="material-icons not-allowed">how_to_reg</span>
              <p>
                {transUTL('translateFormOverview.liveness')}{' '}
                {transUTL('translateFormOverview.detection')}
              </p>
              <span className="material-icons form-overview__ocr-error no-cursor">
                cancel
              </span>
            </div>
            <span className="material-icons no-cursor form-overview__arrows">
              double_arrow
            </span>
            <div className="form-overview__ocr-item form-overview__ocr-item-disabled">
              <span className="material-icons not-allowed">security</span>
              <p>
                {transUTL('translateFormOverview.document')}{' '}
                {transUTL('translateFormOverview.authenticity')}
              </p>
              <span className="material-icons form-overview__ocr-error no-cursor">
                cancel
              </span>
            </div>
          </div>
        </div>
        {handleCountryFormDisplay()}
      </div>
    </div>
  );
};

formOverview.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  regionRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  biometricsRXS: PropTypes.object.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  regionRXS: state.regionRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, { setAlertAXN })(formOverview);
