import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dataSourcesIsCheckedUTL } from "../../../../utils/outputMasterUTL";
import whitelistedInputDataBR from "../../../formOverview/countryForms/brazil/helpers/whitelistedInputDataBR";
import { withRouter } from "react-router-dom";

import { saveFormDataAXN } from "../../../../actions/formData/formDataActions";
import { getUserPIIDataV2AXN } from "../../../../actions/transactionHistory/transactionHistoryActions";
import GlobalWatchlistAML from '../../reusableSearch/GlobalWatchlistAML';

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

//UTILITIES
import { transUTL } from "../../../../utils/transUTL";

// SEARCH RESULTS
import BrazilNationalID from "./results/BrazilNationalID";
import BrazilTaxRegistration from "./results/BrazilTaxRegistration";

const BrazilResults = ({
  authRXS,
  formDataRXS,
  dataSourcesRXS,
  searchVerificationRXS,
  getUserPIIDataV2AXN,
  handleClearResults,
  history
}) => {
  const { brazilFormData } = formDataRXS;
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.brazilDataSources
  );

  const whitelistedData = whitelistedInputDataBR(
    brazilFormData,
    selectedDataSources
  );

  const { ...displayData } = whitelistedData;

  const displayDataFiltered = Object.keys(displayData)
    .map(val => displayData[val])
    .filter(v => !isEmptyVAL(v));

  // GENERATE PDF
  const generatePDF = () =>
    getUserPIIDataV2AXN(authRXS.user, searchVerificationRXS.reportingReference);

  return (
    <Fragment>
      <header className="header-secondary search-verification__input-data">
        <h4 className="header-title-secondary">
          <strong>{transUTL("translateSearchVerification.inputData")}</strong>{" "}
          {displayDataFiltered.join(" | ")}
        </h4>
      </header>
      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <BrazilNationalID
          results={searchVerificationRXS.serviceResponses}
          type="Brazil National ID"
        />
      )}
      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <BrazilTaxRegistration
          results={searchVerificationRXS.serviceResponses}
          type="Brazil Tax Registration"
        />
      )}
      {!isEmptyVAL(searchVerificationRXS.serviceResponses) && (
        <GlobalWatchlistAML
          results={searchVerificationRXS.serviceResponses}
          type="Watchlist AML"
        />
      )}
      <div className="search-verification__btns">
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.push("/form-overview")}
          >
            {transUTL("translateBtn.editSearch")}
          </button>

          <button className="btn-primary" onClick={handleClearResults}>
            {transUTL("translateBtn.newSearch")}
          </button>
        </div>

        <div className="btn-container btn-under">
          <button className="btn-primary" onClick={generatePDF}>
            {transUTL("translateBtn.generatePDF")}
          </button>

          <button className="btn-primary" onClick={() => window.print(history.push('/search-verification'))}>
            {transUTL("translateBtn.printSearchResults")}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

BrazilResults.prototype = {
  saveFormDataAXN: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  searchVerificationRXS: state.searchVerificationRXS
});
export default connect(mapStateToProps, {
  saveFormDataAXN,
  getUserPIIDataV2AXN
})(withRouter(BrazilResults));
