import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// ADDRESS OPTIONS TODO: COME BACK AND ADD A FILTER INPUT / SELECTION
// import communeOptions from './addressOptions/communeOptions';
// import districtOptions from './addressOptions/districtOptions';
// import provinceOptions from './addressOptions/provinceOptions';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import sortAlphaUTL from '../../../../../utils/sortAlphaUTL';

const CurrentResidentialAddressHongKong = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { building, streetName, district, state, postCode, errors } = formState;

  const stateOptions = [
    { label: transUTL('translateDropdown.select'), value: '' },
    {
      label: transUTL('translateDropdown.stateHK.hongKong'),
      value: 'Hong Kong',
    },
    {
      label: transUTL('translateDropdown.stateHK.newTerritories'),
      value: 'New Territories',
    },
    { label: transUTL('translateDropdown.stateHK.kowloon'), value: 'Kowloon' },
  ].sort(sortAlphaUTL);

  const inputElements = [
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
      ],
      element: (
        <TextFieldGroup
          key="building"
          id="building"
          type="text"
          name="building"
          dataName="buildingHK"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.building')}
          value={building}
          placeholder={transUTL('translatePlaceholder.inputHK.building')}
          placeholderTrigger={isEmptyVAL(building) ? 'input-empty' : ''}
          error={errors.building}
          errorTrigger={errors.building ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
      ],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameHK"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetName')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputHK.streetName')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
      ],
      element: (
        <TextFieldGroup
          key="district"
          id="district"
          type="text"
          name="district"
          dataName="districtHK"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.district')}
          value={district}
          placeholder={transUTL('translatePlaceholder.inputHK.district')}
          placeholderTrigger={isEmptyVAL(district) ? 'input-empty' : ''}
          error={errors.district}
          errorTrigger={errors.district ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
      ],
      element: (
        <SelectListGroup
          key="state"
          id="state"
          name="state"
          dataName="stateHK"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          options={stateOptions}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Hong Kong Credit Bureau',
        'Hong Kong Residential',
        'Hong Kong Marketing',
      ],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeHK"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputHK.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CurrentResidentialAddressHongKong.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressHongKong;
