import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import ResultCard from "../../../reusableComponent/ResultCard";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const AustraliaDeathCheckReturnedData = ({ returnedData, type }) => {
  if (isEmptyVAL(returnedData)) returnedData = {};

  const { possibleDeceasedRecord,
    possibleDeceasedRecordName,
    dateOfDeath,
    registrationNumber,
    registrationState } = returnedData;

  const translationSource = 'dataSourcesAU.deathCheckReturnData'

  // When Au death check service is the only selected
  const allFieldsResult = (
    <>
      {/* Deceased and Name */}
      <div className="search-verification__col">
        <ResultCard
          translationKey = {`${translationSource}.possibleDeceasedRecord`}
          result={possibleDeceasedRecord}
        />
        <ResultCard
          translationKey={`${translationSource}.possibleDeceasedRecordName`}
          result={possibleDeceasedRecordName}
        />
      </div>

      {/* State and Number */}
      <div className="search-verification__col">
        <ResultCard
          translationKey={`${translationSource}.registrationState`}
          result={registrationState}
        />
        <ResultCard
          translationKey={`${translationSource}.registrationNumber`}
          result={registrationNumber}
        />
      </div>

      {/* Date */}
      <div className="search-verification__col">
        <ResultCard
          translationKey={`${translationSource}.dateOfDeath`}
          result={dateOfDeath}
        />
      </div>
    </>
  )

  // When other Au services except Au death check are selected
  const twoFieldsResult = (
    /* Deceased and Name */
    <>
      <div className="search-verification__col">
        <ResultCard
          translationKey={`${translationSource}.possibleDeceasedRecord`}
          result={possibleDeceasedRecord}
        />
      </div>
      <div className="search-verification__col">
        <ResultCard
          translationKey={`${translationSource}.possibleDeceasedRecordName`}
          result={possibleDeceasedRecordName}
        />
      </div>
    </>
  )

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          `translateSearchVerification.${translationSource}.title`
        )}
      />
      <div className="search-verification__verify">
        {type ? allFieldsResult : twoFieldsResult}
      </div>
    </div>
  );
};

AustraliaDeathCheckReturnedData.propTypes = {
  returnedData: PropTypes.object.isRequired,
  type: PropTypes.bool.isRequired
};

export default AustraliaDeathCheckReturnedData;
