import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

const feedbackZA = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {
    nationalIDNumber: {},
    personalInformation: {}
  };

  try {
    if(findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name is ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if(findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name is ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }
    if(findErrorUTL(errors, 'nationalIdNo')) {
      inputError.nationalIDNumber.nationalIDNo = `National ID No. ${
        errors.find((item) => item.includes('nationalIdNo')).split(':')[1]
      }. Please double check if you have input correct date of birth and correct national ID`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARSE SOUTH AFRICA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackZA;