import loggerUTL from "./loggerUTL";
import scanDataEditedTrigger from "../../src/actions/biometrics/helpers/scanDataEditedTrigger";
import isEmptyVAL from "../validations/checks/isEmptyVAL";

const isScannedDataEditedUTL = (country, ocrResponseData, filteredFormData) => {
  loggerUTL('CHECKING IF SCANNED DATA IS EDITED...');
  let isEdited = false;
  let changedData = [];
  const identityDataKeys = Object.keys(ocrResponseData);
  identityDataKeys.forEach(key => {
    if (filteredFormData[key] !== ocrResponseData[key]) {
      changedData.push({ [key]: filteredFormData[key] })
    }
  })
  // Sometimes, ocrResponseData is empty when scanResponse is null, the input mapping will not be successful, 
  // then user must fill the input, in this case, consider as user edited the scanned data
  if (changedData.length > 0 || isEmptyVAL(ocrResponseData)) {
    isEdited = true
  }

  scanDataEditedTrigger(country, isEdited);
}

export default isScannedDataEditedUTL;
