const inputMX = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: 'yyyy/mm/dd',

  // IDENTITY DETAIL
  curp: '',
  rfc: '',

  // REFERENCE
  reportingReference: '',
};

export default inputMX;
