// VALIDATIONS
// import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const middleEast = (dataSources) => {
  const userDataSources = [];

  // FILTERS
  // const australia = dataSources.filter((dataSource) =>
  //   dataSource.toLowerCase().includes('australia')
  // );

  // MODIFIERS
  // if (!isEmptyVAL(australia)) {
  //   userDataSources.push({
  //     region: 'apac',
  //     country: 'australia',
  //     dataSources:  [...australia, 'Watchlist AML'], // ALL USERS HAS ACCESS WATCHLIST AML
  //     belongsTo: 'middleEast',
  //     code: { alpha2: 'AU', alpha3: 'AUS' },
  //   });
  // }

  return userDataSources;
};

export default middleEast;
