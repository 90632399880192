import React from 'react';
import PropTypes from 'prop-types';

// Child Components
import UploadBox from './UploadBox';
import DocumentPreview from '../common/DocumentPreview';
import { transUTL } from '../../../../utils/transUTL';
import checkUploadedImage from "../../helpers/checkUploadedImage";
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const FileUpload = ({
  uploadedDocuments,
  onUpload,
  onClear,
  alertAXN
}) => {



  const checkFileSize = (file) => {
    if ((file.size) <= 2000000) return true;
    alertAXN(transUTL('translateBiometrics.memorySizeError'), 'error');
    return false;
  }

  const fileToDataURI = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  }

  const convertFiletoDocumentObject = async (file) => {
    let document = {};
    const { name, size } = file;
    const originalURI = await fileToDataURI(file);
    const {alertMsg, dataURI} = await checkUploadedImage(originalURI, size);

    if(dataURI) {
      document.dataURI = dataURI
      if(name) document.name = name
    } else {
      document = undefined
    }
    
    return {document, alertMsg};
  }

  const handleUpload = async (file, side) => {
    const { document, alertMsg } = await convertFiletoDocumentObject(file);
    if (alertMsg.length > 0) {
      const alertMsgStr = alertMsg.join(' ')
      alertAXN(alertMsgStr, 'error')
    }
    onUpload(document, side);
  }

  return (
    <div className="ocr__file-upload-container">
      <div className="ocr__file-upload-container__dropzone-container">
        <h2>{transUTL('translateBiometrics.frontSide')}</h2>
        {
          uploadedDocuments.front ?
            <DocumentPreview
              image={uploadedDocuments.front.dataURI}
              altTag={uploadedDocuments.front.name}
              onClear={() => onClear('front')}
            />
            :
            <UploadBox
              message={transUTL('translateBiometrics.uploadMessage1')}
              onUpload={(file) => handleUpload(file, 'front')}
            />
        }
      </div>
      <div className="ocr__file-upload-container__dropzone-container">
        <h2>{transUTL('translateBiometrics.backSide2')}</h2>
        {
          uploadedDocuments.back ?
            <DocumentPreview
              image={uploadedDocuments.back.dataURI}
              altTag={uploadedDocuments.back.name}
              onClear={() => onClear('back')}
            />
            :
            <UploadBox
              message={transUTL('translateBiometrics.uploadMessage2')}
              onUpload={(file) => handleUpload(file, 'back')}
            />
        }
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  uploadedDocuments: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  alertAXN: PropTypes.func.isRequired
};

export default FileUpload;