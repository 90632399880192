import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { loginUserAXN } from '../../actions/auth/authActions';
import { saveSelectedLanguageAXN } from '../../actions/language/languageActions';

// INIT
import { centrixENV } from '../../api/init';

// ASSET - LOGO
import logoSVG from '../../assets/logo/idu-logo.svg';

import chinaFlagPNG from '../../assets/flags/china.png';
import malaysiaFlagPNG from '../../assets/flags/malaysia.png';
import britainFlagPNG from '../../assets/flags/britain.png';

// VALIDATIONS
import loginVAL from '../../validations/inputFields/loginVAL';
import { transUTL, langUTL } from '../../utils/transUTL';

const Login = ({
  authRXS: { isAuthenticated },
  languageRXS,
  loginUserAXN,
  saveSelectedLanguageAXN,
  history,
}) => {
  const [userSTH, setUserSTH] = useState({
    username: '',
    password: '',
    errors: {},
  });

  useEffect(() => {
    langUTL(languageRXS); // INITIALIZE LANGUAGE
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // ONCE 'isAuthenticated' CHANGES, THIS WILL FIRE SENDING THE USER TO THE DASHBOARD
    if (isAuthenticated) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  // ON SUBMIT - VALIDATES FORM BEFORE SUBMITTING
  const handleOnSubmit = (event) => {
    event.preventDefault(); // STOPS THE DEFUALT ACTION OF AN ELEMENT FROM HAPPENING

    const { errors, isValid } = loginVAL(userSTH); // VALIDATES INPUT

    if (!isValid) {
      setUserSTH({ ...userSTH, errors });
      return; // CHECK VALIDATION - CURRENT DESIGN DOES NOT ALLOW VALIDATION FEEDBACK (#TODO: DISCUSS DESIGN WITH @MIKE)
    }

    loginUserAXN({
      username: userSTH.username,
      password: userSTH.password,
    });
  };

  // FORM CONTROL
  const handleOnChange = (event) =>
    setUserSTH({ ...userSTH, [event.target.name]: event.target.value });

  // CHANGE / SAVE LANGUAGE
  const handleLanguageSelection = (event, opt) => {
    langUTL(opt);
    saveSelectedLanguageAXN(opt);
  };

  return (
    <div className="login">
      <form className="login__form" onSubmit={handleOnSubmit}>
        <figure className="login__logo">
          <img src={logoSVG} alt="Business Logo" />
        </figure>

        <div className="login__form-group">
          <input
            type="text"
            name="username"
            placeholder={`${transUTL(
              'translatePlaceholder.inputLogin.username'
            )}`}
            value={userSTH.username}
            onChange={handleOnChange}
          />
        </div>

        <div className="login__form-group">
          <input
            type="password"
            name="password"
            placeholder={`${transUTL(
              'translatePlaceholder.inputLogin.password'
            )}`}
            value={userSTH.password}
            onChange={handleOnChange}
          />
        </div>

        <button>{transUTL('translateLogin.loginBtn')}</button>

        <p className="login__password">
          <a
            href={
              centrixENV
                ? 'mailto:admin@centrix.co.nz'
                : 'https://datazoo.freshdesk.com/support/tickets/new'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {transUTL('translateLogin.forgotPassword')}
          </a>
        </p>

        <div className="login__language-flags">
          <ul>
            <li
              className={languageRXS !== 'zh' ? 'login__flag-mask' : ''}
              onClick={(event) => handleLanguageSelection(event, 'zh')}
              title={transUTL('translateTitle.chinese')}
            >
              <img
                src={chinaFlagPNG}
                alt={transUTL('translateAlt.chinaFlag')}
              />
            </li>
            <li
              className={languageRXS !== 'en' ? 'login__flag-mask' : ''}
              onClick={(event) => handleLanguageSelection(event, 'en')}
              title={transUTL('translateTitle.english')}
            >
              <img
                src={britainFlagPNG}
                alt={transUTL('translateAlt.britainFlag')}
              />
            </li>
            <li
              className={languageRXS !== 'ms' ? 'login__flag-mask' : ''}
              onClick={(event) => handleLanguageSelection(event, 'ms')}
              title={transUTL('translateTitle.malay')}
            >
              <img
                src={malaysiaFlagPNG}
                alt={transUTL('translateAlt.malaysiaFlag')}
              />
            </li>
          </ul>
        </div>

        <p className="login__terms-and-condition">
          <a
            href={
              centrixENV
                ? 'https://www.centrix.co.nz/terms-of-use/'
                : 'https://www.datazoo.com/terms-conditions/'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {transUTL('translateLogin.termsAndConditions')}
          </a>{' '}
          |{' '}
          <a
            href={
              centrixENV
                ? 'https://www.centrix.co.nz/privacy-policy/'
                : 'https://www.datazoo.com/privacy-policy/'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {transUTL('translateLogin.privacy')}
          </a>{' '}
          |{' '}
          <a
            href={
              centrixENV
                ? 'https://www.centrix.co.nz/'
                : 'https://www.datazoo.com/contact/'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {transUTL('translateLogin.contact')}
          </a>
        </p>
        <p className="login__copyright">
          {centrixENV
            ? transUTL('translateLogin.poweredBy')
            : `${transUTL('translateLogin.copyright')} © ${moment().year()}`}
        </p>
      </form>
    </div>
  );
};

Login.propTypes = {
  authRXS: PropTypes.object.isRequired,
  languageRXS: PropTypes.string.isRequired,
  loginUserAXN: PropTypes.func.isRequired,
  saveSelectedLanguageAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  languageRXS: state.languageRXS,
});

export default connect(mapStateToProps, {
  loginUserAXN,
  saveSelectedLanguageAXN,
})(Login);
