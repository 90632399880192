import {
  SET_PRICE_TIER_SELECTED_DATA_SOURCE,
  SET_PRICE_TIER_SELECTED_COUNTRY,
} from '../types';

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';

// SAVE COUNTRY
export const saveCostEstCountryAXN = (country) => (dispatch) => {
  loggerUTL('SETTING SELECTED USAGE REPORT DATE...', country);
  sessionStorage.setItem('costEstCountry', JSON.stringify(country));

  dispatch({
    type: SET_PRICE_TIER_SELECTED_COUNTRY,
    payload: country,
  });
};

// SAVE DATA SOURCE
export const saveCostEstDataSourceAXN = (dataSource) => (dispatch) => {
  loggerUTL('SETTING SELECTED CLIENT...', dataSource);
  sessionStorage.setItem('costEstDataSource', JSON.stringify(dataSource));

  dispatch({
    type: SET_PRICE_TIER_SELECTED_DATA_SOURCE,
    payload: dataSource,
  });
};
