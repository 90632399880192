import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { geocodeByAddress } from "react-places-autocomplete";
// import moment from 'moment';

// REDUX ACTIONS
import { saveFormDataAXN } from "../../../../actions/formData/formDataActions";
import { saveUserInputAXN } from "../../../../actions/formData/userInputActions";
import { saveApiReqAXN } from "../../../../actions/formData/apiReqActions";

import { searchVerificationAXN } from "../../../../actions/searchVerification/searchVerificationAction";
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from "../../../../actions/biometrics/biometricActions";
import { setAlertAXN } from "../../../../actions/alert/alertActions";

// HELPERS
import initialStateAU from "./helpers/initialStateAU";
import consentCheckerAU from "./helpers/consentCheckerAU";
import whitelistedInputDataAU from "./helpers/whitelistedInputDataAU";
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL"; //Downsize for uploading document and selfie not OCR
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import australiaVAL from "../../../../validations/inputFields/australiaVAL";

// FORM CONTROLLER
import FormController from "../../../../components/formController/FormController";
import DisplayApiController from "../../../../components/formController/DisplayApiController";

// FORM INPUTS
import PersonalInformationAustralia from "./inputForms/PersonalInformationAustralia";
import CurrentResidentialAddressAustralia from "./inputForms/CurrentResidentialAddressAustralia";
import DriversLicenceAustralia from "./inputForms/DriversLicenceAustralia";
import MedicareAustralia from "./inputForms/MedicareAustralia";
import PassportAustralia from "./inputForms/PassportAustralia";
import BirthCertificateAustralia from "./inputForms/BirthCertificateAustralia";
import CenterlinkCardAustralia from "./inputForms/CenterlinkCardAustralia";
import ContactDetailsAustralia from "./inputForms/ContactDetailsAustralia";
import ABNAustralia from "./inputForms/ABNAustralia";
import TermsAndConditionsAustralia from "./inputForms/TermsAndConditionsAustralia";
import ClientReference from "../../reusableForms/ClientReference";
import ApiResult from "../../reusableForms/ApiResult";

// BUTTON
import Button from "../../../../components/btn/Button";

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from "../../../../utils/outputMasterUTL";
import { transUTL } from "../../../../utils/transUTL";
import loggerUTL from "../../../../utils/loggerUTL";
import formatDateUTL from "../../../../utils/formatDateUTL";
import filterServicesUTL from "../../../../utils/filterServicesUTL";
import jsonBuilder from "../../../../utils/jsonBuilder";
import rmSpaceUTL from "../../../../utils/rmSpaceUTL";
import noSpaceUTL from "../../../../utils/noSpaceUTL";
import isScannedDataEditedUTL from "../../../../utils/isScannedDataEditedUTL";

const AustraliaForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  userInputRXS,
  biometricsRXS,
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateAU);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.australiaDataSources
  );

  // LOAD FORM
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.australiaFormData,
      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.australiaFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL("VALIDATING...");
    const inputData = { ...userInputSTH };

    // VALIDATE INPUT
    const { inputErrors, isValid } = australiaVAL(
      inputData,
      selectedDataSources
    );
    if (!isValid) {
      loggerUTL("ERRORS...", inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL("translateAlertMsg.inputErrors"), "error");
    }

    // CONSENT CHECKER
    const { isConsent, consentObtained } = consentCheckerAU(
      selectedDataSources,
      inputData
    );

    if (!isConsent) {
      return setAlertAXN(transUTL("translateAlertMsg.consent"), "error");
    }

    // DATE FORMAT
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // REMOVE ANY SPACES
    if (!isEmptyVAL(userInputSTH.email))
      inputData.email = noSpaceUTL(inputData.email);

    if (!isEmptyVAL(userInputSTH.phoneNo)) inputData.phoneNo.trim();

    // WHITELIST DATA
    const whitelisted = whitelistedInputDataAU(inputData, selectedDataSources);

    // ADDRESS MANIPULATION
    const address =
      `${inputData.unitNo} ${inputData.streetNo} ${inputData.streetName}`.trim();
    const addressElement1 = rmSpaceUTL(address);
    const addressElement3 = inputData.suburb;
    const addressElement4 = inputData.state;
    const addressElement5 = inputData.postCode;

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    const service = [...selectedDataSources];

    // JSON BUILDER
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      consentObtained,
      addressElement1,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));
    saveApiReqAXN(submitData, countryRXS);
    searchVerificationAXN(submitData, history);
    saveUserInputAXN(inputData.displayApiResultObtained, countryRXS);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL("ON BLUR...");
    const {
      errors,
      birthCertificateConsentObtained,
      centerlinkCardConsentObtained,
      driverLicenceConsentObtained,
      medicareCardConsentObtained,
      passportConsentObtained,
      visaConsentObtained,
      residentialLookupConsentObtained,
      visaEntitlementConsentObtained,
      creditBureauConsentObtained,
      medicareExpiryDateToggle,
      manualAddressInput,
      displayApiResultObtained,
      addressUnparsed,
      isGoogle,
      isGoogleParsed,
      ...filteredFormData
    } = userInputSTH;

    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));

    // Check if scanned data from a doc is edited
    const { ocrResponseData } = biometricsRXS;
    isScannedDataEditedUTL(countryRXS, ocrResponseData, filteredFormData);

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL("ON BLUR ENTER KEY TRIGGER");
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL("CLEARING STATE...");
    const {
      errors,
      birthCertificateConsentObtained,
      centerlinkCardConsentObtained,
      driverLicenceConsentObtained,
      medicareCardConsentObtained,
      passportConsentObtained,
      visaConsentObtained,
      residentialLookupConsentObtained,
      visaEntitlementConsentObtained,
      creditBureauConsentObtained,
      medicareExpiryDateToggle,
      manualAddressInput,
      displayApiResultObtained,
      addressUnparsed,
      isGoogle,
      isGoogleParsed,
      ...reset
    } = initialStateAU;
    setUserInputSTH(initialStateAU);
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN(); // RESET
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    loggerUTL("CHECKBOX...");
    const {
      payrollConsentObtained,
      superannuationConsentObtained,
      birthCertificateConsentObtained,
      centerlinkCardConsentObtained,
      driverLicenceConsentObtained,
      medicareCardConsentObtained,
      passportConsentObtained,
      visaConsentObtained,
      residentialLookupConsentObtained,
      visaEntitlementConsentObtained,
      creditBureauConsentObtained,
    } = userInputSTH;

    let birthCertificateConsent = false,
      cenerlinkCardConsent = false,
      driverLicenceConsent = false,
      medicareCardConsent = false,
      passportCardConsent = false,
      visaConsent = false,
      residentialLookupConsent = false,
      visaEntitlementConsent = false,
      creditBureauConsent = false,
      superannuationConsent = false,
      payrollConsent = false;

    if (selectedDataSources.includes("Australia Superannuation")) {
      superannuationConsent = !superannuationConsentObtained;
    }
    if (selectedDataSources.includes("Australia Payroll")) {
      payrollConsent = !payrollConsentObtained;
    }
    if (selectedDataSources.includes("Australia Birth Certificate")) {
      birthCertificateConsent = !birthCertificateConsentObtained;
    }
    if (selectedDataSources.includes("Australia Centrelink Card")) {
      cenerlinkCardConsent = !centerlinkCardConsentObtained;
    }
    if (selectedDataSources.includes("Australia Driver Licence")) {
      driverLicenceConsent = !driverLicenceConsentObtained;
    }
    if (selectedDataSources.includes("Australia Medicare Card")) {
      medicareCardConsent = !medicareCardConsentObtained;
    }
    if (selectedDataSources.includes("Australia Passport")) {
      passportCardConsent = !passportConsentObtained;
    }
    if (selectedDataSources.includes("Australia Visa")) {
      visaConsent = !visaConsentObtained;
    }
    if (selectedDataSources.includes("Australia Residential Lookup")) {
      residentialLookupConsent = !residentialLookupConsentObtained;
    }
    if (selectedDataSources.includes("Australia Visa Entitlement")) {
      visaEntitlementConsent = !visaEntitlementConsentObtained;
    }
    if (selectedDataSources.includes("Australia Credit Bureau")) {
      creditBureauConsent = !creditBureauConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      birthCertificateConsentObtained: birthCertificateConsent,
      centerlinkCardConsentObtained: cenerlinkCardConsent,
      driverLicenceConsentObtained: driverLicenceConsent,
      medicareCardConsentObtained: medicareCardConsent,
      passportConsentObtained: passportCardConsent,
      visaConsentObtained: visaConsent,
      residentialLookupConsentObtained: residentialLookupConsent,
      visaEntitlementConsentObtained: visaEntitlementConsent,
      creditBureauConsentObtained: creditBureauConsent,
      payrollConsentObtained: payrollConsent,
      superannuationConsentObtained: superannuationConsent,
    });
  };

  const handleToggleTickbox = (opt) => {
    loggerUTL("TICKBOX...");
    const { displayApiResultObtained } = userInputSTH;

    let displayApiResult = false;
    displayApiResult = !displayApiResultObtained;

    setUserInputSTH({
      ...userInputSTH,
      displayApiResultObtained: displayApiResult,
    });
  };

  // DOCUMENT UPLOAD OR SELFIE UPLOAD OR DOCUMENT SCAN (OCR)
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || "identityDocument";
      const file = event.target.files[0];
      let fileType = "";

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === "documentIMG") {
          fileType = "file1"; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(
              reader.result,
              width,
              height
            ); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({
              ...biometricsRXS.bioTriggers,
              isDocScanned: false,
              isDocUploaded: true,
            });
          };
        } else if (targetName === "selfieIMG") {
          fileType = "file2"; // SELFIE
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(
              reader.result,
              width,
              height
            ); //Downsize a uploaded selfie
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({
              ...biometricsRXS.bioTriggers,
              isBioAuth: false,
              isSelfieUploaded: true,
            });
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          // [targetName]: blob,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // MANUAL ADDRESS INPUT
  const manualAddressEntryToggle = () => {
    setUserInputSTH({
      ...userInputSTH,
      isGoogle: !userInputSTH.isGoogle,
    });

    const {
      errors,
      birthCertificateConsentObtained,
      centerlinkCardConsentObtained,
      driverLicenceConsentObtained,
      medicareCardConsentObtained,
      passportConsentObtained,
      visaConsentObtained,
      residentialLookupConsentObtained,
      visaEntitlementConsentObtained,
      creditBureauConsentObtained,
      medicareExpiryDateToggle,
      manualAddressInput,
      displayApiResultObtained,
      addressUnparsed,
      isGoogle,
      isGoogleParsed,
      ...filteredFormData
    } = userInputSTH;
    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // GOOGLE AUTO COMPLETE
  const handleGoggleOnChange = (addressUnparsed) => {
    setUserInputSTH({
      ...userInputSTH,
      addressUnparsed,
      isGoogleParsed: false,
    });
  };

  // HANDLE GOOGLE SELECT
  const handleGoogleSelect = (addressUnparsed, selectedWithoutSelect) => {
    geocodeByAddress(addressUnparsed)
      .then((results) => {
        const addressComponents = {
          unitNo: "",
          streetNo: "",
          streetName: "",
          // streetType: '',
          suburb: "",
          state: "",
          postCode: "",
        };

        results[0].address_components.map((addressItem) => {
          if (addressItem.types.includes("subpremise")) {
            addressComponents.unitNo = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (addressItem.types.includes("street_number")) {
            addressComponents.streetNo = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (addressItem.types.includes("route")) {
            addressComponents.streetName = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (addressItem.types.includes("locality")) {
            addressComponents.suburb = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (
            addressItem.types.includes("administrative_area_level_1")
          ) {
            addressComponents.state = addressItem.short_name
              ? addressItem.short_name
              : "";
          } else if (addressItem.types.includes("postal_code")) {
            addressComponents.postCode = addressItem.long_name
              ? addressItem.long_name
              : "";
          }

          return null;
        });

        const newAddress = selectedWithoutSelect
          ? addressUnparsed
          : results[0].formatted_address;

        setUserInputSTH({
          ...userInputSTH,
          isGoogle: true, // update to true in order to show input elements
          isGoogleParsed: true,
          addressUnparsed: newAddress,
          ...addressComponents,
        });
      })
      .then((latLng) => {
        loggerUTL("SUCCESS...", latLng);
      })
      .catch((error) => loggerUTL("ERROR...", error));
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (
    selectedDataSources.includes("Australia Superannuation") ||
    selectedDataSources.includes("Australia Payroll") ||
    selectedDataSources.includes("Australia Birth Certificate") ||
    selectedDataSources.includes("Australia Passport") ||
    selectedDataSources.includes("Australia Centrelink Card") ||
    selectedDataSources.includes("Australia Driver Licence") ||
    selectedDataSources.includes("Australia Medicare Card") ||
    selectedDataSources.includes("Australia Visa")
  ) {
  } else {
    conditionalClientRef.push(
      ...[
        "Australia Death Check",
        "Australia Citizens",
        "Australia Property Ownership",
        "Australia Residential",
        "Australia Residential Lookup",
        "Watchlist AML",
      ]
    );
  }

  let passportHeader = "translateFormOverview.formHeaders.passport";
  if (selectedDataSources.includes("Australia Visa")) {
    passportHeader = "translateFormOverview.formHeaders.visa";
  }
  if (
    selectedDataSources.includes("Australia Visa") &&
    selectedDataSources.includes("Australia Passport")
  ) {
    passportHeader = "translateFormOverview.formHeaders.visaAndPassport";
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        "Australia Death Check",
        "Australia Superannuation",
        "Australia Payroll",
        "Australia Citizens",
        "Australia Birth Certificate",
        "Australia Passport",
        "Australia Centrelink Card",
        "Australia Driver Licence",
        "Australia Medicare Card",
        "Australia Property Ownership",
        "Australia Residential",
        "Australia Residential Lookup",
        "Australia Visa",
        "Australia Visa Entitlement",
        "Australia Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            "translateFormOverview.formHeaders.personalInformation"
          )}
          inputForm={
            <PersonalInformationAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "Australia Superannuation",
        "Australia Payroll",
        "Australia Citizens",
        "Australia Property Ownership",
        "Australia Residential",
        "Australia Residential Lookup",
        "Australia Credit Bureau",
      ],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            "translateFormOverview.formHeaders.currentResidentialAddress"
          )}
          inputForm={
            <CurrentResidentialAddressAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              manualAddressEntryToggle={manualAddressEntryToggle}
              handleGoggleOnChange={handleGoggleOnChange}
              handleGoogleSelect={handleGoogleSelect}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["Australia Superannuation", "Australia Payroll"],
      element: (
        <FormController
          key="ABN"
          title={transUTL("translateFormOverview.formHeaders.ABN")}
          inputForm={
            <ABNAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["Australia Driver Licence"],
      element: (
        <FormController
          key="driversLicence"
          title={transUTL("translateFormOverview.formHeaders.driversLicence")}
          inputForm={
            <DriversLicenceAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["Australia Medicare Card"],
      element: (
        <FormController
          key="medicare"
          title={transUTL("translateFormOverview.formHeaders.medicare")}
          inputForm={
            <MedicareAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "Australia Passport",
        "Australia Visa",
        "Australia Visa Entitlement",
      ],
      element: (
        <FormController
          key="passport"
          title={transUTL(passportHeader)}
          inputForm={
            <PassportAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["Australia Birth Certificate"],
      element: (
        <FormController
          key="birthCertificate"
          title={transUTL("translateFormOverview.formHeaders.birthCertificate")}
          inputForm={
            <BirthCertificateAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["Australia Centrelink Card"],
      element: (
        <FormController
          key="centerlink"
          title={transUTL("translateFormOverview.formHeaders.centerlink")}
          inputForm={
            <CenterlinkCardAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "Australia Superannuation",
        "Australia Payroll",
        "Australia Citizens",
        "Australia Residential",
        "Australia Residential Lookup",
      ],
      element: (
        <FormController
          key="contactDetails"
          title={transUTL("translateFormOverview.formHeaders.contactDetails")}
          inputForm={
            <ContactDetailsAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "Australia Superannuation",
        "Australia Payroll",
        "Australia Birth Certificate",
        "Australia Passport",
        "Australia Centrelink Card",
        "Australia Driver Licence",
        "Australia Medicare Card",
        "Australia Visa",
        "Australia Visa Entitlement",
        "Australia Credit Bureau",
      ],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            "translateFormOverview.formHeaders.termsAndConditions"
          )}
          inputForm={
            <TermsAndConditionsAustralia
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL("translateFormOverview.formHeaders.clientRef")}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "Australia Death Check",
        "Australia Superannuation",
        "Australia Payroll",
        "Australia Citizens",
        "Australia Birth Certificate",
        "Australia Passport",
        "Australia Centrelink Card",
        "Australia Driver Licence",
        "Australia Medicare Card",
        "Australia Property Ownership",
        "Australia Residential",
        "Australia Residential Lookup",
        "Australia Visa",
        "Australia Visa Entitlement",
        "Australia Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <DisplayApiController
          key="displayApi"
          // title={transUTL('translateFormOverview.formHeaders.displayApi')}
          inputForm={
            <ApiResult
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleTickbox={handleToggleTickbox}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL("translateFormOverview.clear")}
      </button>

      {/* BUTTON */}
      <Button
        myClass={"btn-under"}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL("translateBtn.previousPage")}
        btn2Text={transUTL("translateBtn.submit")}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

AustraliaForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  saveUserInputAXN: PropTypes.func.isRequired,
  saveApiReqAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  saveIdentityDocumentsAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  saveSelfieAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  errorsRXS: state.errorsRXS,
  countryRXS: state.countryRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  apiReqRXS: state.apiReqRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  setAlertAXN,
})(withRouter(AustraliaForms));
