import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

const reference = (identityData) => {
  let { clientReference } = identityData;

  if (!isEmptyVAL(clientReference)) {
    clientReference = `Reporting Reference: ${clientReference}`;
  }

  return {
    referenceData: [clientReference],
  };
};

export default reference;
