import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const singaporeVAL = (data, dataSource) => {
  const inputErrors = {};

  /* ============================================
              SINGAPORE CREDIT BUREAU
   ============================================ */
  if (dataSource.includes('Singapore Credit Bureau')) {
    loggerUTL('VALIDATING SINGAPORE CREDIT BUREAU...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.nationalIDNo = !isEmptyVAL(data.nationalIDNo) ? data.nationalIDNo : '';
    data.block = !isEmptyVAL(data.block) ? data.block : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';
    data.city = !isEmptyVAL(data.city) ? data.city : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.nationalIDNo)) {
      inputErrors.nationalIDNo = transUTL(
        'translateFormOverview.formErrors.nationalIDNo'
      );
    }
    if (isEmptyVAL(data.block)) {
      inputErrors.block = transUTL('translateFormOverview.formErrors.block');
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
    if (isEmptyVAL(data.city)) {
      inputErrors.city = transUTL('translateFormOverview.formErrors.city');
    }
  }

  /* ============================================
              SINGAPORE RESIDENTIAL
   ============================================ */
  if (dataSource.includes('Singapore Residential')) {
    loggerUTL('VALIDATING SINGAPORE RESIDENTIAL...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.nationalIDNo = !isEmptyVAL(data.nationalIDNo) ? data.nationalIDNo : '';
    data.block = !isEmptyVAL(data.block) ? data.block : '';
    data.streetName = !isEmptyVAL(data.streetName) ? data.streetName : '';
    data.postCode = !isEmptyVAL(data.postCode) ? data.postCode : '';
    data.city = !isEmptyVAL(data.city) ? data.city : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.block)) {
      inputErrors.block = transUTL('translateFormOverview.formErrors.block');
    }
    if (isEmptyVAL(data.streetName)) {
      inputErrors.streetName = transUTL(
        'translateFormOverview.formErrors.streetName'
      );
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      );
    }
    if (isEmptyVAL(data.city)) {
      inputErrors.city = transUTL('translateFormOverview.formErrors.city');
    }
  }

  /* ============================================
              SINGAPORE UTILITY
   ============================================ */
  if (dataSource.includes('Singapore Utility')) {
    loggerUTL('VALIDATING SINGAPORE UTILITY...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';
    data.dateOfBirth = !isEmptyVAL(data.dateOfBirth) ? data.dateOfBirth : '';
    data.block = !isEmptyVAL(data.block) ? data.block : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
    if (isEmptyVAL(data.dateOfBirth)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirth'
      );
    }
    if (isEmptyVAL(data.block)) {
      inputErrors.block = transUTL('translateFormOverview.formErrors.block');
    }
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSource.includes('Watchlist AML')) {
    loggerUTL('VALIDATING WATCHLIST AML AML...');
    // CHECK IF CREDENTIALS IS NOT EMPTY
    data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
    data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

    // ERROR FEEDBACK ACCUMULATOR
    if (isEmptyVAL(data.firstName)) {
      inputErrors.firstName = transUTL(
        'translateFormOverview.formErrors.firstName'
      );
    }
    if (isEmptyVAL(data.lastName)) {
      inputErrors.lastName = transUTL(
        'translateFormOverview.formErrors.lastName'
      );
    }
  }

  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default singaporeVAL;
