import store from '../../../../store/store';
import {
  saveIdentityDocumentsAXN,
  saveIdentityDocumentAXN,
  saveBiometricAuthenticationDataAXN,
  saveOcrResponseDataAXN,
  bioTriggerBooleansAXN,
} from '../../../../actions/biometrics/biometricActions';

const biometricsSessionDataRestore = () => {
  if (sessionStorage.bioTriggers) {
    store.dispatch(
      bioTriggerBooleansAXN(JSON.parse(sessionStorage.bioTriggers))
    );
  }

  // TODO: Will need to remove this.
  if (sessionStorage.identityDocumentScans) {
    store.dispatch(
      saveIdentityDocumentsAXN(JSON.parse(sessionStorage.identityDocumentScans))
    );
  }

  if (sessionStorage.identityDocument) {
    store.dispatch(
      saveIdentityDocumentAXN(JSON.parse(sessionStorage.identityDocument))
    );
  }

  if (sessionStorage.facialRecognitionData) {
    store.dispatch(
      saveBiometricAuthenticationDataAXN(
        JSON.parse(sessionStorage.facialRecognitionData)
      )
    );
  }

  if (sessionStorage.ocrResponseData) {
    store.dispatch(
      saveOcrResponseDataAXN(JSON.parse(sessionStorage.ocrResponseData))
    );
  }
};

export default biometricsSessionDataRestore;
