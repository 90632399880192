const translateBtn = {
  previousPage: 'Previous Page',
  nextPage: 'Next Page',
  submit: 'Submit',
  printSearchResults: 'Print Search Results',
  newSearch: 'New Search',
  generatePDF: 'Generate PDF',
  editSearch: 'Edit Search',
  printResults: 'Print Results',
};

export default translateBtn;
