import jsPDF from 'jspdf';
import 'jspdf-autotable';

// CONFIG
import { docStyles } from '../../config/config';

import timeStamp from '../../helpers/timeStamp';
import primeHeader from '../../helpers/primeHeader';
import generatePDF from '../../helpers/generatePDF';
import entryDetails from '../../helpers/entryDetails';
import biometricsTrigger from '../../helpers/biometricsTrigger';
import ocrImages from '../../helpers/ocrImages';
import drawGreenLine from '../../helpers/drawGreenLine';
import pepsAndSanctions from '../../helpers/pepsAndSanctions';
import separator from '../../helpers/separator';
import footer from '../../helpers/footer';

// COUNTRY UI SPECIFIC
import consumerDataSupplied from './ui/consumerDataSupplied';
import iduVerificationResults from './ui/iduVerificationResults';
import dataSourceVerificationResults from './ui/dataSourceVerificationResults/dataSourceVerificationResults';
import returnedData from './ui/returnedData';
import errorMessages from './ui/errorMessages';
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

const kenyaPDF = (data, info, ocrImgs, browserWindow) => {
  const doc = new jsPDF({
    compress: true, // COMPRESSES PDF FILE SIZE
    orientation: 'p',
    unit: 'px',
    format: 'a4',
  });

  // SOME TIMES THE RESPONSE OBJECT IS EMPTY
  // THIS PREVENTS ANY PDF ERRORS
  if (isEmptyVAL(data.Response)) {
    data.Response.service = [];
  }

  /* ============================================
                  HEADER
  ============================================ */
  timeStamp(doc, data, docStyles); // DISPLAY TIME STAMP
  primeHeader(doc, 'Kenya'); // DISPLAY PRIMARY HEADER

  /* ============================================
                  BODY 
   ============================================ */
  entryDetails(doc, data, info, docStyles);

  // OCR IMAGES
  let bioData2 = {};
  if (!isEmptyVAL(ocrImgs)) {
    const {
      isDocScanned,
      isBioAuth,
      bioData,
      selfie,
      identityDoc0,
    } = biometricsTrigger(ocrImgs);
  
    if (isDocScanned || isBioAuth) {
      ocrImages(doc, data, info, docStyles, bioData);
    } else {
      bioData2 = { selfie, identityDoc0 };
    }
  }

  consumerDataSupplied(doc, data, info, docStyles, bioData2);
  drawGreenLine(doc, docStyles, docStyles.separation);
  iduVerificationResults(doc, data, docStyles);
  dataSourceVerificationResults(doc, data, info, docStyles);
  returnedData(doc, data, info, docStyles);
  drawGreenLine(doc, docStyles, docStyles.separation);
  pepsAndSanctions(doc, data, info, docStyles);
  drawGreenLine(doc, docStyles, docStyles.separation);
  errorMessages(doc, data, info, docStyles);

  /* ============================================
                  FOOTER
  ============================================ */
  separator(doc);
  footer(doc, data, info, docStyles, 'Kenya');

  /* ============================================
                  GENERATE PDF
  ============================================ */
  generatePDF(doc, data, browserWindow);
};

export default kenyaPDF;