import { transUTL } from "../../../../../utils/transUTL";

const medicareCardTypeOptions = () => {
  return [
    { label: transUTL('translateDropdown.select'), value: '' },
    {
      label: transUTL('translateDropdown.medicare.medicareAU.green'),
      value: 'G',
    },
    {
      label: transUTL('translateDropdown.medicare.medicareAU.blue'),
      value: 'B',
    },
    {
      label: transUTL('translateDropdown.medicare.medicareAU.yellow'),
      value: 'Y',
    }
  ]
};
export default medicareCardTypeOptions;
