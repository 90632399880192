import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

const newUserFormVAL = (formData) => {
  const inputErrors = {};
  const emailExpression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  if (isEmptyVAL(formData.username)) {
    inputErrors.username = 'Please enter a username';
  }
  if (isEmptyVAL(formData.country)) {
    inputErrors.country = 'Please select a country';
  }
  if (isEmptyVAL(formData.orgID)) {
    inputErrors.orgID = 'Please select an organisation';
  }

  // Email validation
  if (isEmptyVAL(formData.email)) {
    inputErrors.email = 'Please enter an email address';
  } else if (!emailExpression.test(formData.email)) {
    inputErrors.email = 'Please enter a valid email';
  }

  return {
    inputErrors: inputErrors,
    isValid: isEmptyVAL(inputErrors)
  };
};

export default newUserFormVAL;