import adjustImageSize from "./adjustImageSize";

export const setCellHeight = ({ height, width, ratioResize }) => {
  let h = adjustImageSize(height, width, ratioResize).height

  const minCellHeight = h / ratioResize;
  const padding = 40;

  return minCellHeight + padding;
}

export const setImagePos = ({ cellX, cellY, cellWidth, cellheight, width, height, ratioResize }) => {
  let w = adjustImageSize(height, width, ratioResize).width
  let h = adjustImageSize(height, width, ratioResize).height

  const xPos = cellX + (cellWidth - w / ratioResize) / 2; // CENTERS IMAGE X - AXIS
  const yPos = cellY + (cellheight - h / ratioResize) / 2; // CENTERS IMAGE y - AXIS

  return {xPos, yPos, h, w}
}
