const dataSourcesCN = {

  identityVerified: 'Verified',
  searchSuccessful: 'Search Successful',
  name: 'Name',
  dateOfBirth: 'Date Of Birth',
  safeHarbourScore: 'Safe Harbour Score',
  documentNo: 'Document Number',
  nameMatchScore: 'Name Match Score',
  gender: 'Gender',
  address: 'Address',
  idCardNo: 'ID Card Number',

  idVerification: {
    title: 'China ID Verification',
  },
  phone: {
    title: 'China Phone',
    phoneVerified: 'Phone Number',
  },
  bankCard: {
    title: 'China Bank Card',
    fullName: 'Full Name',
    bankCardNo: 'Bank Card No.',
    idCardNo: 'ID Card No.',
  },
  passportVerification: {
    title: 'China Passport Verification',
    passportNo: 'Passport Number',
  },
  driverLicense: {
    title: 'China Driver License',
  }
};

export default dataSourcesCN;
