const dataSourcesMX = {
  nationalID: {
    title: 'ID Kebangsaan Mexico',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    dateOfBirth: 'Tarikh lahir',
    gender: 'Jantina',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    curp: 'Mengekang',
    rfc: 'rfc',
  },

  taxRegistration: {
    title: 'Pendaftaran Cukai Mexico',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    addressMatchScore: 'Markah Padanan Alamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    dateOfBirth: 'Tarikh lahir',
    gender: 'Jantina',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    rfc: 'rfc',
    curp: 'Mengekang',
  },
};

export default dataSourcesMX;
