import store from '../store/store';

import { dataSourcesIsCheckedUTL } from './outputMasterUTL';

const selectedDataSourceUTL = () => {
  const dataSources = store.getState().dataSourcesRXS;
  const country = store.getState().countryRXS;

  const suffix = 'DataSources';
  const target = `${country}${suffix}`;
  let extractedDataSource = dataSources[target];

  // RETURNS THE SELECTED DATA SOURCES
  /*
   * e.g {'Malaysia Credit Bureau': true, 'Malaysia National ID': false, 'Watchlist AML': true}
   * returns =+ ['Malaysia Credit Bureau', 'Watchlist AML']
   */

  return dataSourcesIsCheckedUTL(extractedDataSource);
};

export default selectedDataSourceUTL;
