import React from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const ContactDetailsItaly = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { phoneNo, errors } = formState;

  const inputElements = [
    {
      belongsTo: [
        'Italy Residential',
        'Italy Telco Mobile',
      ],
      element: (
        <TextFieldGroup
          key="phoneNo"
          id="phoneNo"
          type="text"
          name="phoneNo"
          dataName="phoneNoIT"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.phoneNo')}
          value={phoneNo}
          placeholder={transUTL('translatePlaceholder.inputIT.phoneNo')}
          placeholderTrigger={isEmptyVAL(phoneNo) ? 'input-empty' : ''}
          error={errors.phoneNo}
          errorTrigger={errors.phoneNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

ContactDetailsItaly.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default ContactDetailsItaly;
