import formErrors from './formErrors/formErrors';
import formHeaders from './formHeaders/formHeaders';
import inputLabels from './inputLabels/inputLabels';

const translateFormOverview = {
  consentParagraph:
    'Saya mengesahkan bahawa saya diberi kuasa untuk memberikan butir-butir peribadi yang dikemukakan dan saya bersetuju agar maklumat saya diperiksa dengan pengeluar dokumen atau pemegang rekod pejabat melalui sistem pihak ketiga untuk tujuan mengesahkan identiti saya.',
  consentParagraphAadhaar: 
    'Saya membenarkan Data Zoo untuk mengakses nombor Aadhaar saya dan membantu saya mengambil maklumat saya. Saya faham bahawa Zoo Data tidak akan menyimpan atau berkongsi perkara yang sama.',
  enterInfo: 'Masukkan maklumat yang diperlukan:',
  selectedServices: 'Perkhidmatan Terpilih',
  scanAndOCR: 'SCAN & OCR',
  consentParagraph2: 'Saya bersetuju dengan Terma dan Syarat',
  chinaCrime: 'Pemeriksaan Polis?',
  digitalIDSafeguard: 'ID Digital Safeguard ™',
  digitalIDSafeguardMessage:
    'Gunakan Digital ID Safeguard ™ sebagai perkhidmatan tambahan dalam permintaan pengesahan ini',
  previousPage: 'Halaman sebelumnya',
  submit: 'Hantar',
  cantLocateAddress: "Tidak menemui apa yang anda cari?",
  reportingReference: 'RUJUKAN PELANGGAN (ANDA)',
  cantFindAddress: "Tidak menemui apa yang anda cari?",
  manualAddress: 'Masukkan alamat secara manual',
  useAddressAutocomplete: 'Gunakan pelengkap automatik alamat',
  changeCountrySelected: 'Tukar negara yang dipilih',
  changeDataSourceSelected: 'Tukar sumber data yang dipilih',
  watchlistAML: 'PEP & Sekatan | ',
  notApplicable: 'N / A',
  updateAddress: 'Kemas kini Alamat',
  clear: 'Jelas',
  indiaFullNameField: 'Hendaklah dimasukkan sama seperti yang tertera pada dokumen pengenalan diri',
  document: 'dokumen',
  australiaDriverLicenseCardNo: 'Nombor kad. mesti dimasukkan jika Lesen Pemandu memaparkan nombor kad',
  scan: 'imbas',
  biometric: 'biometrik',
  authentication: 'pengesahan',
  liveness: 'semangat hidup',
  detection: 'pengesanan',
  authenticity: 'kesahihan',
  consentParagraphAus:
    'Saya membenarkan butiran saya didedahkan kepada agensi pelaporan kredit atau mana-mana pihak ketiga yang diluluskan untuk tujuan mengesahkan butiran saya, dan, untuk tujuan itu, saya juga membenarkan pihak ketiga untuk bertindak sebagai perantara saya di bawah Akta Privasi untuk mengakses maklumat dipegang oleh mana-mana pihak ketiga yang lain.',
  // FORM ERRORS
  formErrors,
  // FORM HEADERS
  formHeaders,
  // INPUT LABELS
  inputLabels,
  isDotInFullName: "Nama Penuh di Pasport merangkumi '·' antara nama-nama misalnya. 'XXXX · XXXX'",
  includeWatchList: 'Sertakan carian PEP & Sanctions?',
  includeSafeguardSearch: 'Sertakan carian Perlindungan ID Digital?',
  apiResult : "Paparkan Permintaan dan Respons API",
};

export default translateFormOverview;
