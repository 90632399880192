import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveFormDataAXN } from '../../../../actions/formData/formDataActions';
import { searchVerificationAXN } from '../../../../actions/searchVerification/searchVerificationAction';
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from '../../../../actions/biometrics/biometricActions';
import { setAlertAXN } from '../../../../actions/alert/alertActions';

// HELPERS
import initialStateVN from './helpers/initialStateVN';
import consentCheckerVN from './helpers/consentCheckerVN';
import whitelistedInputDataVN from './helpers/whitelistedInputDataVN';
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import vietnamVAL from '../../../../validations/inputFields/vietnamVAL';

// FORM CONTROLLER
import FormController from '../../../../components/formController/FormController';

// FORM INPUTS
import PersonalInformationVietnam from './inputForms/PersonalInformationVietnam';
import IdentityDetailsVietnam from './inputForms/IdentityDetailsVietnam';
import ContactDetailsVietnam from './inputForms/ContactDetailsVietnam';
import CurrentResidentialAddressVietnam from './inputForms/CurrentResidentialAddressVietnam';
import TermsAndConditionsVietnam from './inputForms/TermsAndConditionsVietnam';
import ClientReference from '../../reusableForms/ClientReference';

// BUTTON
import Button from '../../../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from '../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../utils/transUTL';
import formatDateUTL from '../../../../utils/formatDateUTL';
import loggerUTL from '../../../../utils/loggerUTL';
import jsonBuilder from '../../../../utils/jsonBuilder';
import filterServicesUTL from '../../../../utils/filterServicesUTL';
import rmSpaceUTL from '../../../../utils/rmSpaceUTL';
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL"; 
import isScannedDataEditedUTL from "../../../../utils/isScannedDataEditedUTL";

const VietnamForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateVN);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.vietnamDataSources
  );

  // LOAD FORM
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.vietnamFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.vietnamFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);


  // OCR SCAN RETURNS FULL NAME AS A SINGLE STRING.
  // THIS NEEDS TO BE SEPARATED OUT FOR THE VIETNAM FORM.
  useEffect(() => {
    if (!isEmptyVAL(userInputSTH.fullName)) {
      let splitName = userInputSTH.fullName.split(" ");
      let firstName, middleName, lastName;

      if (isEmptyVAL(userInputSTH.firstName) && splitName.length > 0) {
        firstName = splitName[0];
      } else {
        firstName = userInputSTH.firstName;
      }

      if (isEmptyVAL(userInputSTH.middleName) && splitName.length > 2) {
        middleName = splitName.slice(1, splitName.length - 1).join(" ");
      } else {
        middleName = userInputSTH.middleName;
      }

      if (isEmptyVAL(userInputSTH.lastName) && splitName.length > 1) {
        lastName = splitName[splitName.length - 1];
      } else {
        lastName = userInputSTH.lastName;
      }

      loggerUTL("NAME USE EFFECT RAN\n", splitName, firstName, middleName, lastName);
      setUserInputSTH({
        ...userInputSTH,
        ...formDataRXS.vietnamFormData,
        firstName,
        middleName,
        lastName,
        errors: {}
      });
    }
    // eslint-disable-next-line
  }, [userInputSTH.fullName])

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL('VALIDATING...');
    const inputData = { ...userInputSTH }; // COPY OF STATE

    // VALIDATE INPUT
    const { inputErrors, isValid } = vietnamVAL(inputData, selectedDataSources);
    if (!isValid) {
      loggerUTL('ERRORS...', inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL('translateAlertMsg.inputErrors'), 'error');
    }

    // USER CONSENT CHECK
    const { isConsent, consentObtained } = consentCheckerVN(
      selectedDataSources,
      inputData
    );

    // PROVIDE ERROR FEEDBACK IF CONSENT IS NOT CHECKED
    if (!isConsent) {
      return setAlertAXN(transUTL('translateAlertMsg.consent'), 'error');
    }

    // UNIVERSAL DATA FORMATTER
    // if (!isEmptyVAL(userInputSTH.dateOfBirth)) {
    //   inputData.dateOfBirth = formatDateUTL(inputData.dateOfBirth);
    // }

    // WHITELISTS  DATA DEPENDING ON THE SELECTED DATA SOURCE
    const whitelisted = whitelistedInputDataVN(inputData, selectedDataSources);

    const service = [...selectedDataSources]; // ALL THE SELECTED DATA SOURCES

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);

    // ADDRESS MANIPULATION
    let addressElement1 = '';
    if (isEmptyVAL(inputData.address)) {
      const address = `${inputData.streetNo} ${inputData.streetName} ${inputData.ward} ${inputData.district} ${inputData.city}`;
      addressElement1 = rmSpaceUTL(address); // REMOVES ADDITIONAL WHITE SPACES
    } else {
      addressElement1 = rmSpaceUTL(inputData.address);
    }

    // WATCHLIST AML REQUIRES `firstName` & `lastName`
    // WHERE AS THE OTHER VIETNAM SERVICES REQUIRES `fullName`
    const names = `${inputData.firstName} ${inputData.middleName} ${inputData.lastName}`.trim();
    const fullName = rmSpaceUTL(names);

    // FORMATS SUBMIT DATA ACCORDANCE TO API REQUEST BODY STRUCTURE
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      consentObtained,
      fullName,
      addressElement1,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    // REDUX ACTIONS - TRIGGERS CALL TO API
    searchVerificationAXN(submitData, history);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // FIRES THE MOMENT THE ELEMENT LOSES FOCUS
  const handleOnBlur = (e) => {
    loggerUTL('ON BLUR...');
    const {
      errors,

      vietnamConsumerDataConsentObtained,
      nationalIDConsentObtained,
      socialSecurityConsentObtained,

      ...filteredFormData
    } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    // Check if scanned data from a doc is edited 
    const { ocrResponseData } = biometricsRXS;
    isScannedDataEditedUTL(countryRXS, ocrResponseData, filteredFormData);

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // WHEN USER HITS THE ENTER DURING/AFTER INPUT IT TRIGGERS A STATE SAVE
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL('ON BLUR ENTER KEY TRIGGER');
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL('CLEARING STATE...');

    // OMITS `errors`
    const { errors, ...reset } = initialStateVN;

    // RESETS TO INITIAL STATE
    setUserInputSTH(initialStateVN);

    // RESETS VIETNAM FORM DATA
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    loggerUTL('CONSENT TOGGLE...');
    const {
      vietnamConsumerDataConsentObtained,
      nationalIDConsentObtained,
      socialSecurityConsentObtained,
    } = userInputSTH;

    let vietnamConsumerConsent = false,
      nationalIDConsent = false,
      socialSecurityConsent = false;

    if (selectedDataSources.includes('Vietnam Consumer Data')) {
      vietnamConsumerConsent = !vietnamConsumerDataConsentObtained;
    }
    if (selectedDataSources.includes('Vietnam National ID')) {
      nationalIDConsent = !nationalIDConsentObtained;
    }
    if (selectedDataSources.includes('Vietnam Social Security')) {
      socialSecurityConsent = !socialSecurityConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      vietnamConsumerDataConsentObtained: vietnamConsumerConsent,
      nationalIDConsentObtained: nationalIDConsent,
      socialSecurityConsentObtained: socialSecurityConsent,
    });
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || 'identityDocument';
      const file = event.target.files[0];
      let fileType = '';

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === 'documentIMG') {
          fileType = 'file1'; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isDocScanned: false, isDocUploaded: true});
          };
        } else if (targetName === 'selfieIMG') {
          fileType = 'file2'; // SELFIE
          img.src = reader.result;
          img.onload = function() {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(reader.result, width, height); //Downsize a uploaded selfie 
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({ ...biometricsRXS.bioTriggers, isBioAuth: false, isSelfieUploaded: true});
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (
    // ALL THESES DATA SOURCES HERE REQUIRES CONSENT
    // UI - SHOWS (CONSENT AND TERMS & CONDITIONS) SECTION
    selectedDataSources.includes('Vietnam Consumer Data') ||
    selectedDataSources.includes('Vietnam National ID') ||
    selectedDataSources.includes('Vietnam Social Security')
  ) {
  } else {
    // ALL THESES DATA SOURCES HERE DOES NOT REQUIRE CONSENT
    // UI - SHOWS (CLIENT REFERENCE) SECTION
    conditionalClientRef.push(...['Watchlist AML']);
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        'Vietnam Consumer Data',
        'Vietnam National ID',
        'Vietnam Social Security',
        'Watchlist AML',
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            'translateFormOverview.formHeaders.personalInformation'
          )}
          inputForm={
            <PersonalInformationVietnam
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Vietnam Consumer Data',
        'Vietnam National ID',
        'Vietnam Social Security',
      ],
      element: (
        <FormController
          key="identityDetails"
          title={transUTL('translateFormOverview.formHeaders.identityDetails')}
          inputForm={
            <IdentityDetailsVietnam
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Vietnam Consumer Data',
        'Vietnam National ID',
        'Vietnam Social Security',
      ],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            'translateFormOverview.formHeaders.currentResidentialAddress'
          )}
          inputForm={
            <CurrentResidentialAddressVietnam
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ['Vietnam Consumer Data'],
      element: (
        <FormController
          key="contactDetails"
          title={transUTL('translateFormOverview.formHeaders.contactDetails')}
          inputForm={
            <ContactDetailsVietnam
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        'Vietnam Consumer Data',
        'Vietnam National ID',
        'Vietnam Social Security',
      ],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            'translateFormOverview.formHeaders.termsAndConditions'
          )}
          inputForm={
            <TermsAndConditionsVietnam
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL('translateFormOverview.formHeaders.clientRef')}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL('translateFormOverview.clear')}
      </button>

      {/* BUTTON */}
      <Button
        myClass={'btn-under'}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.submit')}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

VietnamForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  resetBiometricsAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(VietnamForms));
