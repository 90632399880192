import React, { useEffect, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Route, withRouter, Switch } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

// REDUX STORE
// import store from '../../store/store';

// REDUX ACTIONS
import { logoutUserAXN } from '../../actions/auth/authActions';
import { setAlertAXN } from '../../actions/alert/alertActions';

// PRODUCTION CHECK
import { isProd } from '../../dev';

// PAGES / COMPONENTS
import AlertMsg from '../../components/common/alertMsg/AlertMsg';
import Login from '../../pages/login/Login';
import Navigation from '../layout/navigation/Navigation';
import Dashboard from '../../pages/dashboard/Dashboard';
import CountrySelection from '../../pages/countrySelection/CountrySelection';
import DataSourceSelection from '../../pages/dataSourceSelection/DataSourceSelection';
import FormOverview from '../../pages/formOverview/FormOverview';
import FormOverviewTemplate from '../../pages/formOverview/FormOverviewTemplate';
import WatchlistAMLForms from '../../pages/formOverview/countryForms/watchlistAML/WatchlistAMLForms';
import SearchVerification from '../../pages/searchVerification/SearchVerification';
import SearchVerificationTemplate from '../../pages/searchVerification/SearchVerificationTemplate';
import TransactionHistory from '../../pages/transactionHistory/TransactionHistory';
import DateSelection from '../../pages/usageReport/DateSelection';
import ClientSelection from '../../pages/usageReport/ClientSelection';
import UsageReport from '../../pages/usageReport/UsageReport';
import DocumentScan from '../../pages/ocr/documentScan/DocumentScan';
import DocumentScanChina from '../../pages/ocr/DocumentScanChina';
import DocumentScanVietnam from '../../pages/ocr/DocumentScanVietnam';
import BiometricAuth from '../../pages/ocr/BiometricAuth';
import BioResults from '../../pages/ocr/BioResults';
import DocumentLibrary from '../../pages/documentLibrary/DocumentLibrary';
import AboutUs from '../../pages/aboutUs/AboutUs';
import PasswordDistro from '../../pages/passwordDistro/PasswordDistro';
import CostEstCountrySelection from '../../pages/costEstimation/CostEstCountrySelection';
import CostEstDataSourceSelection from '../../pages/costEstimation/CostEstDataSourceSelection';
import CostEstimation from '../../pages/costEstimation/CostEstimation';
import EToro from '../../pages/eToro/EToro';
import EToroTwo from '../../pages/eToro/EToroTwo';
import Admin from '../../pages/admin/Admin';
import UserTable from '../../pages/admin/tables/UserTable';
import OrganisationTable from '../../pages/admin/tables/OrganisationTable';
import CreateUserForm from '../../pages/admin/forms/CreateUserForm';
import CrossBorderSelection from "../../pages/dataSourceSelection/CrossBorderSelection";
import { CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE } from "../../constants/crossBorderDataSources";

import FindIDUSelection from "../../pages/dataSourceSelection/FindIDUSelection";
import DigitalIdSafeguardSelection from "../../pages/dataSourceSelection/DigitalIdSafeguardSelection";

import FindIDUFormOverview from "../../pages/formOverview/FindIDUFormOverview";
import DigitalIdSafeguardFormOverview from "../../pages/formOverview/DigitalIdSafeguardFormOverview";

import FindIDUSearchVerification from "../../pages/searchVerification/FindIDUSearchVerification";
import DigitalIdSafeguardSearchVerification from "../../pages/searchVerification/DigitalIdSafeguardSearchVerification";

// import MultiSearchVerification from '../../pages/multiService/SearchVerification';
// import MultiService from '../../pages/multiService/MultiService';
// import MultiServiceFormOverview from '../../pages/multiService/MultiServiceFormOverview';

// PRIVATE ROUTES
import PrivateRoute from '../common/privateRoute/PrivateRoute';
import AdminRoute from '../common/adminRoute/AdminRoute';

// LOADING ANIMATION
import Spinner from '../common/spinner/Spinner';

// RESTORE DATA
import authSessionRestore from './helpers/auth/authSessionDataRestore';
import languageSessionDataRestore from './helpers/language/languageSessionDataRestore';
import countrySessionDataRestore from './helpers/country/countrySessionDataRestore';
import regionSessionDataRestore from './helpers/region/regionSessionDataRestore';
import dataSourcesSessionDataRestore from './helpers/dataSources/dataSourcesSessionDataRestore';
import formDataSessionDataRestore from './helpers/formData/formDataSessionDataRestore';
import searchVerificationSessionDataRestore from './helpers/searchVerification/searchVerificationSessionDataRestore';
import usageReportSessionDataRestore from './helpers/usageReport/usageReportSessionDataRestore';
import costEstimationSessionDataRestore from './helpers/costEstimation/costEstimationSessionDataRestore';
import biometricsSessionDataRestore from './helpers/biometrics/biometricsSessionDataRestore';

// VALIDATION
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import loggerUTL from '../../utils/loggerUTL';

// RESTORE DATA ON PAGE REFRESH
authSessionRestore();
languageSessionDataRestore();
countrySessionDataRestore();
regionSessionDataRestore();
dataSourcesSessionDataRestore();
formDataSessionDataRestore();
searchVerificationSessionDataRestore();
usageReportSessionDataRestore();
costEstimationSessionDataRestore();
biometricsSessionDataRestore();

// ROUTES
const routes = [{ path: '/', name: 'Home', Component: Login }];
const routesAuth = [
  { path: '/dashboard', name: 'Dashboard', Component: Dashboard },
  {
    path: '/country-selection',
    name: 'Country Selection',
    Component: CountrySelection,
  },
  {
    path: '/data-source-selection',
    name: 'Data Source Selection',
    Component: DataSourceSelection,
  },
  {
    path: '/find-idu',
    name: 'Find IDU',
    Component: FindIDUSelection,
  },
  {
    path: '/digital-id-safeguard',
    name: 'Digital ID Safeguard',
    Component: DigitalIdSafeguardSelection,
  },
  {
    path: CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE,
    name: 'Cross Border Data Source Selection',
    Component: CrossBorderSelection,
  },
  {
    path: '/form-overview',
    name: 'Form Overview',
    Component: FormOverview,
  },
  /*
    This path is temporarily used for template. Should be combined with the path
    form-overview once the template is applied to all services
  */
  {
    path: '/form-overview-template',
    name: 'Form Overview Template',
    Component: FormOverviewTemplate,
  },
  {
    path: '/watchlist-aml',
    name: 'Watchlist AML',
    Component: WatchlistAMLForms,
  },
  {
    path: '/find-idu-form-overview',
    name: 'Find IDU Form Overview',
    Component: FindIDUFormOverview, 
  },
  {
    path: '/digital-id-safeguard-form-overview',
    name: 'Digital Id Safeguard Form Overview',
    Component: DigitalIdSafeguardFormOverview, 
  },
  {
    path: '/search-verification',
    name: 'Search Verification',
    Component: SearchVerification,
  },
  /*
    This path is temporarily used for template. Should be combined with the path
    search-verification once the template is applied to all services
  */
  {
    path: '/search-verification-template',
    name: 'Search Verification Template',
    Component: SearchVerificationTemplate,
  },
  {
    path: '/find-idu-search-verification',
    name: 'Find IDU Search Verification',
    Component: FindIDUSearchVerification,
  },
  {
    path: '/digital-id-safeguard-search-verification',
    name: 'Digital ID Safeguard Search Verification',
    Component: DigitalIdSafeguardSearchVerification
  },
  {
    path: '/transaction-history',
    name: 'Transaction History',
    Component: TransactionHistory,
  },
  {
    path: '/date-selection/:username',
    name: 'Date Selection',
    Component: DateSelection,
  },
  {
    path: '/date-selection/client-selection/:username',
    name: 'Client Selection',
    Component: ClientSelection,
  },
  {
    path: '/date-selection/client-selection/results/:username',
    name: 'Usage Report',
    Component: UsageReport,
  },
  // ==== COUNTRY OCR ====
  {
    path: '/document-scan',
    name: 'Document Scan',
    Component: DocumentScan,
  },
  {
    path: '/document-scan/china',
    name: 'Document Scan',
    Component: DocumentScanChina,
  },
  {
    path: '/document-scan/vietnam',
    name: 'Document Scan',
    Component: DocumentScanVietnam,
  },
  {
    path: '/biometric-auth',
    name: 'Biometric Auth',
    Component: BiometricAuth,
  },
  {
    path: '/biometric-authentication/results',
    name: 'Biometric Results',
    Component: BioResults,
  },
  {
    path: '/document-library',
    name: 'Document Library',
    Component: DocumentLibrary,
  },
  {
    path: '/about-us',
    name: 'About Us',
    Component: AboutUs,
  },
  {
    path: '/cost-estimation/country-selection/:username',
    name: 'Cost Est Country Selection',
    Component: CostEstCountrySelection,
  },
  {
    path: '/cost-estimation/country-selection/data-source-selection/:username',
    name: 'Cost Est Data Source Selection',
    Component: CostEstDataSourceSelection,
  },
  {
    path:
      '/cost-estimation/country-selection/data-source-selection/pricing/:username',
    name: 'Cost Est Data Source Selection',
    Component: CostEstimation,
  },
  {
    path: '/eToro-transactions',
    name: 'eToro Transactions',
    Component: EToro,
  },
  {
    path: '/eToro-usage',
    name: 'eToro Transactions Two',
    Component: EToroTwo,
  },
  // {
  //   path: '/multi-service-selection', TODO: THIS SERVICE WAS RUSHED DUE TO DEADLINE... CONSIDER REWORKING THIS SERVICE
  //   name: 'Multi Service Selection',
  //   Component: MultiService,
  // },
  // {
  //   path: '/multi-service-selection/form-overview', TODO: THIS SERVICE WAS RUSHED DUE TO DEADLINE... CONSIDER REWORKING THIS SERVICE
  //   name: 'Multi Services Form Overview',
  //   Component: MultiServiceFormOverview,
  // },
];
const routesAdmin = [
  {
    path: '/admin',
    name: 'Admin',
    Component: Admin,
  },
  {
    path: '/admin/users/:id',
    name: 'User Table',
    Component: UserTable
  },
  {
    path: '/admin/organisations',
    name: 'Organisation Table',
    Component: OrganisationTable
  },
  {
    path: '/admin/createUser',
    name: 'Create User Form',
    Component: CreateUserForm
  },
  {
    path: '/admin/password-distribution',
    name: 'Password Distribution',
    Component: PasswordDistro
  }
];

const Root = ({
  authRXS: { isAuthenticated, user },
  loadingRXS,
  alertRXS,
  logoutUserAXN,
  setAlertAXN,
  history,
}) => {
  useEffect(() => {
    // INITIALIZE
    if (isProd) {
      Sentry.init({
        dsn: 'https://4eec47c9fcbc4e1ea67b0ab6ade910de@sentry.io/3400034',
      });
    }

    // eslint-disable-next-line
  }, []);

  const onIdle = () => {
    loggerUTL('CHECK SESSION TOKEN VALIDITY...');
    const currentTime = moment().format();

    if (user.exp < currentTime) {
      // LOGOUT USER
      logoutUserAXN();

      // CLEAR USER
      sessionStorage.clear();

      // REDIRECT USER
      window.location.path = '/';

      setTimeout(() => {
        setAlertAXN('Your session has expired...', 'error');
      }, 1000);
    }
  };

  return (
    <Fragment>
      {/* ----------- IDLE TIMER  ----------- */}
      <IdleTimer
        ref={(ref) => {
          window.idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={0}
        timeout={1000 * 0.9 * 15}
      />

      {/* ----------- SPINNER  ----------- */}
      {loadingRXS && <Spinner />}

      {/* ----------- ALERT MSG  ----------- */}
      {!isEmptyVAL(alertRXS) && <AlertMsg />}

      {/* ----------- NAVIGATION  ----------- */}
      {isAuthenticated && <Navigation history={history} />}

      {/* ----------- ROUTES  ----------- */}
      <Switch>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path} component={Component} />
        ))}

        {routesAuth.map(({ path, Component }) => (
          <PrivateRoute key={path} exact path={path} component={Component} />
        ))}

        {routesAdmin.map(({ path, Component }) => (
          <AdminRoute key={path} exact path={path} component={Component} />
        ))}
      </Switch>
    </Fragment>
  );
};

Root.propTypes = {
  authRXS: PropTypes.object.isRequired,
  loadingRXS: PropTypes.bool.isRequired,
  alertRXS: PropTypes.array.isRequired,
  logoutUserAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  loadingRXS: state.loadingRXS,
  alertRXS: state.alertRXS,
});

export default connect(mapStateToProps, { logoutUserAXN, setAlertAXN })(
  withRouter(Root)
);
