// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { creditBureau, residential, utility, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(creditBureau.status),
      col3: naUTL(residential.status),
      col4: naUTL(utility.status),
      col5: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(creditBureau.safeHarbourScore),
      col3: naUTL(residential.safeHarbourScore),
      col4: naUTL(utility.safeHarbourScore),
      col5: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(creditBureau.identityVerified),
      col3: naUTL(residential.identityVerified),
      col4: naUTL(utility.identityVerified),
      col5: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'Document Number',
      col2: naUTL(creditBureau.verifications.documentNo),
      col3: naUTL(residential.verifications.documentNo),
      col4: naUTL(utility.verifications.documentNo),
      col5: naUTL(watchlistAML.verifications.documentNo),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(creditBureau.verifications.firstName),
      col3: naUTL(residential.verifications.firstName),
      col4: naUTL(utility.verifications.firstName),
      col5: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(creditBureau.verifications.middleName),
      col3: naUTL(residential.verifications.middleName),
      col4: naUTL(utility.verifications.middleName),
      col5: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(creditBureau.verifications.lastName),
      col3: naUTL(residential.verifications.lastName),
      col4: naUTL(utility.verifications.lastName),
      col5: naUTL(watchlistAML.verifications.lastName),
    },

    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(creditBureau.verifications.dateOfBirth),
      col3: naUTL(residential.verifications.dateOfBirth),
      col4: naUTL(utility.verifications.dateOfBirth),
      col5: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(creditBureau.verifications.gender),
      col3: naUTL(residential.verifications.gender),
      col4: naUTL(utility.verifications.gender),
      col5: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Phone Number Verified',
      col2: naUTL(creditBureau.verifications.phoneNo),
      col3: naUTL(residential.verifications.phoneNo),
      col4: naUTL(utility.verifications.phoneNo),
      col5: naUTL(watchlistAML.verifications.phoneNo),
    },
    {
      col1: 'Email Address Verified',
      col2: naUTL(creditBureau.verifications.emailAddress),
      col3: naUTL(residential.verifications.emailAddress),
      col4: naUTL(utility.verifications.emailAddress),
      col5: naUTL(watchlistAML.verifications.emailAddress),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(creditBureau.nameMatchScore),
      col3: naUTL(residential.nameMatchScore),
      col4: naUTL(utility.nameMatchScore),
      col5: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Street Number Verified',
      col2: naUTL(creditBureau.verifications.streetNo),
      col3: naUTL(residential.verifications.streetNo),
      col4: naUTL(utility.verifications.streetNo),
      col5: naUTL(watchlistAML.verifications.streetNo),
    },
    {
      col1: 'Street Address Verified',
      col2: naUTL(creditBureau.verifications.streetAddress),
      col3: naUTL(residential.verifications.streetAddress),
      col4: naUTL(utility.verifications.streetAddress),
      col5: naUTL(watchlistAML.verifications.streetAddress),
    },
    {
      col1: 'City Verified',
      col2: naUTL(creditBureau.verifications.city),
      col3: naUTL(residential.verifications.city),
      col4: naUTL(utility.verifications.city),
      col5: naUTL(watchlistAML.verifications.city),
    },
    {
      col1: 'Post Code Verified',
      col2: naUTL(creditBureau.verifications.postCode),
      col3: naUTL(residential.verifications.postCode),
      col4: naUTL(utility.verifications.postCode),
      col5: naUTL(watchlistAML.verifications.postCode),
    },
    {
      col1: 'Address Verified',
      col2: naUTL(creditBureau.verifications.address),
      col3: naUTL(residential.verifications.address),
      col4: naUTL(utility.verifications.address),
      col5: naUTL(watchlistAML.verifications.address),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(creditBureau.addressMatchScore),
      col3: naUTL(residential.addressMatchScore),
      col4: naUTL(utility.addressMatchScore),
      col5: naUTL(watchlistAML.addressMatchScore),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
