// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataIN = (inputData, dataSources) => {
  let driversLicenceData;
  let padNumberData;
  let epicNumberData;
  let passportData;
  let aadhaarNumberData;
  let watchlistAMLData;

  /* ============================================
              INDIA DRIVERS LICENCE
   ============================================ */
  if (dataSources.includes('India Drivers Licence')) {
    loggerUTL('WHITELISTING INDIA DRIVERS LICENCE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'dateOfBirth',
      'gender',
      'streetNo',
      'streetName',
      'suburb',
      'city',
      'state',
      'postCode',
      'driversLicenceNumber',
      'reportingReference',
      'indiaDriversLicenceConsentObtained',
    ]);

    driversLicenceData = { ...whitelisted };
  }

  /* ============================================
              INDIA PAN
   ============================================ */
  if (dataSources.includes('India PAN')) {
    loggerUTL('WHITELISTING INDIA DRIVERS LICENCE...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'pan',
      'reportingReference',
    ]);

    padNumberData = { ...whitelisted };
  }

  /* ============================================
              INDIA EPIC
   ============================================ */
  if (dataSources.includes('India EPIC')) {
    loggerUTL('WHITELISTING INDIA EPIC...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'epic',
      'reportingReference',
    ]);

    epicNumberData = { ...whitelisted };
  }

  /* ============================================
              INDIA PASSPORT
   ============================================ */
  if (dataSources.includes('India Passport')) {
    loggerUTL('WHITELISTING INDIA PASSPORT...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'fileNo',
      'passportNo',
      'dateOfIssue',
      'reportingReference',
    ]);

    passportData = { ...whitelisted };
  }


  /* ============================================
              INDIA Aadhaar
   ============================================ */
  if (dataSources.includes('India Aadhaar')) {
    loggerUTL('WHITELISTING INDIA Aadhaar...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'pan',
      'aadhaarNo',
      'reportingReference',
    ]);
    aadhaarNumberData = { ...whitelisted };
  }

  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELIST WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistAMLData = { ...whitelisted };
  }

  return {
    ...driversLicenceData,
    ...padNumberData,
    ...epicNumberData,
    ...passportData,
    ...aadhaarNumberData,
    ...watchlistAMLData,
  };
};
export default whitelistedInputDataIN;
