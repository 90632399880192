
const getImageDimensionsFromBase64__ = (base64) => {

  return new Promise ((resolved, rejected) => {
    var i = new Image()
    i.src = `data:image/png;base64,${base64}`
    i.onload = function(){
      resolved({imgWidth: i.naturalWidth, imgHeight: i.naturalHeight})
    };
  })

};

export default getImageDimensionsFromBase64__;
