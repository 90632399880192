const dataSourcesNZ = {
  passport: {
    title: '新西兰DIA护照',
    searchSuccessful: '搜索成功',
    passportNo: '护照号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    firstName: '名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  driverLicence: {
    title: '新西兰驾驶执照',
    searchSuccessful: '搜索成功',
    licenceNo: '执照号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
  },
  companiesOffice: {
    title: '新西兰公司办公室',
    searchSuccessful: '搜索成功',
    licenceNo: '执照号码',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    address: '地址',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    phoneNo: '电话号码',
    landlineNo: '座机号码',
    address1: '街',
    postCode: '邮编',
    city: '市',
    suburb: '市郊',
  },
  diaBirth: {
    title: '新西兰DIA出生',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    firstName: '名字',
    lastName: '姓',
    middleName: '中间名字',
    dateOfBirth: '出生日期',
  },
  diaCitizen: {
    title: '新西兰DIA国籍',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    firstName: '名字',
    lastName: '姓',
    middleName: '中间名字',
    dateOfBirth: '出生日期',
  },
  linzProperty: {
    title: '新西兰LINZ物业',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    otherOwners: '其他业主',
    address1: '街道编号和名称',
    postCode: '邮编',
    firstName: '名字',
    lastName: '姓',
    middleName: '中间名字',
    dateOfBirth: '出生日期',
    city: '市',
    suburb: '市郊',
    phoneNo: '电话号码',
    landlineNo: '座机号码',
  },
  yellowPages: {
    title: '新西兰黄页',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    address1: '街',
    firstName: '名字',
    lastName: '姓',
    postCode: '邮编',
    state: '州',
    suburb: '市郊',
  },
  vehicle: {
    title: '新西兰车辆',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    addressElement1: '街号',
    addressElement2: '街道名称',
    addressElement3: '市郊',
    addressElement4: '市',
    addressElement5: '邮编',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    postCode: '邮编',
    state: '州',
    suburb: '市郊',
  },
  residential: {
    title: '新西兰住宅',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    addressElement1: '街号',
    addressElement2: '街道名称',
    addressElement3: '市郊',
    addressElement4: '市',
    addressElement5: '邮编',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    postCode: '邮编',
    address1: '街道编号和名称',
    city: '城市',
    state: '州',
    suburb: '市郊',
    phoneNo: '电话号码',
    landlineNo: '座机号码',
  },
  creditBureau: {
    title: '新西兰信用局',
    searchSuccessful: '搜索成功',
    identityVerified: '身份验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字比赛分数',
    addressMatchScore: '地址匹配分数',
    addressElement1: '街号',
    addressElement2: '街道名称',
    addressElement3: '市郊',
    addressElement4: '市',
    addressElement5: '邮编',
    firstName: '名字',
    middleName: '中间名字',
    lastName: '姓',
    dateOfBirth: '出生日期',
    postCode: '邮编',
    state: '州',
    suburb: '市郊',
  },
};

export default dataSourcesNZ;
