import store from '../../../../store/store';
import { saveSelectedRegionAXN } from '../../../../actions/region/regionAction';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const regionSessionDataRestore = () => {
  loggerUTL('REGION SESSION DATA RESTORE...');
  if (sessionStorage.selectedRegion) {
    store.dispatch(saveSelectedRegionAXN(sessionStorage.selectedRegion));
  }
};

export default regionSessionDataRestore;
