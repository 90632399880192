import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';
import AddressAutoComplete from '../../../reusableForms/AddressAutoComplete';

const CurrentResidentialAddressAustralia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  manualAddressEntryToggle,
  handleGoggleOnChange,
  handleGoogleSelect,
}) => {
  const {
    unitNo,
    streetNo,
    streetName,
    suburb,
    state,
    postCode,
    // GOOGLE
    isGoogle,
    errors,
  } = formState;
  const stateOptions = [
    { label: transUTL('translateDropdown.select'), value: '' },
    {
      label: transUTL('translateDropdown.stateAU.queensland'),
      value: 'QLD',
    },
    {
      label: transUTL('translateDropdown.stateAU.newSouthWales'),
      value: 'NSW',
    },
    {
      label: transUTL('translateDropdown.stateAU.southAustralia'),
      value: 'SA',
    },
    {
      label: transUTL('translateDropdown.stateAU.tasmania'),
      value: 'TAS',
    },
    {
      label: transUTL('translateDropdown.stateAU.victoria'),
      value: 'VIC',
    },
    {
      label: transUTL('translateDropdown.stateAU.westernAustralia'),
      value: 'WA',
    },
    {
      label: transUTL('translateDropdown.stateAU.australianCapitalTerritory'),
      value: 'ACT',
    },
    {
      label: transUTL('translateDropdown.stateAU.northernTerritory'),
      value: 'NT',
    },
  ];

  const inputElements = [
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Property Ownership',
        'Australia Residential',
        'Australia Residential Lookup',
        'Australia Credit Bureau',
      ],
      element: (
        <TextFieldGroup
          key="unitNo"
          id="unitNo"
          type="text"
          name="unitNo"
          dataName="unitNoAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.unitNo')}
          value={unitNo}
          placeholder={transUTL('translatePlaceholder.inputAU.unitNo')}
          placeholderTrigger={isEmptyVAL(unitNo) ? 'input-empty' : ''}
          error={errors.unitNo}
          errorTrigger={errors.unitNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Property Ownership',
        'Australia Residential',
        'Australia Residential Lookup',
        'Australia Credit Bureau',
      ],
      element: (
        <TextFieldGroup
          key="streetNo"
          id="streetNo"
          type="text"
          name="streetNo"
          dataName="streetNoAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetNo')}
          value={streetNo}
          placeholder={transUTL('translatePlaceholder.inputAU.streetNo')}
          placeholderTrigger={isEmptyVAL(streetNo) ? 'input-empty' : ''}
          error={errors.streetNo}
          errorTrigger={errors.streetNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Property Ownership',
        'Australia Residential',
        'Australia Residential Lookup',
        'Australia Credit Bureau',
      ],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetName')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputAU.streetName')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Property Ownership',
        'Australia Residential',
        'Australia Residential Lookup',
        'Australia Credit Bureau',
      ],
      element: (
        <TextFieldGroup
          key="suburb"
          id="suburb"
          type="text"
          name="suburb"
          dataName="suburbAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.suburb')}
          value={suburb}
          placeholder={transUTL('translatePlaceholder.inputAU.suburb')}
          placeholderTrigger={isEmptyVAL(suburb) ? 'input-empty' : ''}
          error={errors.suburb}
          errorTrigger={errors.suburb ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },

    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Property Ownership',
        'Australia Residential',
        'Australia Residential Lookup',
        'Australia Credit Bureau',
      ],
      element: (
        <SelectListGroup
          key="state"
          id="state"
          name="state"
          dataName="stateAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          options={stateOptions}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },

    {
      belongsTo: [
        'Australia Superannuation',
        'Australia Payroll',
        'Australia Citizens',
        'Australia Property Ownership',
        'Australia Residential',
        'Australia Residential Lookup',
        'Australia Credit Bureau',
      ],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeAU"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputAU.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <Fragment>
      {isGoogle ? (
        <div className="form-overview__form-inputs">
          {outputMasterUTL(selectedDataSources, inputElements)}
          <span />
        </div>
      ) : (
          <Fragment>
            <AddressAutoComplete
              formState={formState}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              manualAddressEntryToggle={manualAddressEntryToggle}
              handleGoggleOnChange={handleGoggleOnChange}
              handleGoogleSelect={handleGoogleSelect}
              countryRestriction="au"
            />
          </Fragment>
        )}

      {isGoogle && (
        <div
          onClick={manualAddressEntryToggle}
          className="form-overview__address-switch"
        >
          {transUTL('translateFormOverview.useAddressAutocomplete')}
        </div>
      )}
    </Fragment>
  );
};

CurrentResidentialAddressAustralia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  manualAddressEntryToggle: PropTypes.func.isRequired,
  handleGoggleOnChange: PropTypes.func.isRequired,
  handleGoogleSelect: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressAustralia;
