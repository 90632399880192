import { transUTL } from "../../../../../utils/transUTL";

const countryOptions = () => {
  return [
    { value: '', label: transUTL('translateDropdown.select') },
    { value: 'ABW', label: transUTL('translateDropdown.country.aruba') },
    {
      value: 'AFG',
      label: transUTL('translateDropdown.country.afghanistan'),
    },
    { value: 'AGO', label: transUTL('translateDropdown.country.angola') },
    { value: 'AIA', label: transUTL('translateDropdown.country.anguilla') },
    {
      value: 'ALA',
      label: transUTL('translateDropdown.country.alandIslands'),
    },
    { value: 'ALB', label: transUTL('translateDropdown.country.albania') },
    { value: 'AND', label: transUTL('translateDropdown.country.andorra') },
    {
      value: 'ARE',
      label: transUTL('translateDropdown.country.unitedArabEmirates'),
    },
    {
      value: 'ARG',
      label: transUTL('translateDropdown.country.argentina'),
    },
    { value: 'ARM', label: transUTL('translateDropdown.country.armenia') },
    {
      value: 'ASM',
      label: transUTL('translateDropdown.country.americanSamoa'),
    },
    {
      value: 'ATA',
      label: transUTL('translateDropdown.country.antarctica'),
    },
    {
      value: 'ATF',
      label: transUTL('translateDropdown.country.frenchSouthernTerritories'),
    },
    {
      value: 'ATG',
      label: transUTL('translateDropdown.country.antiguaAndBarbuda'),
    },
    {
      value: 'AUS',
      label: transUTL('translateDropdown.country.australia'),
    },
    { value: 'AUT', label: transUTL('translateDropdown.country.austria') },
    {
      value: 'AZE',
      label: transUTL('translateDropdown.country.azerbaijan'),
    },
    { value: 'BDI', label: transUTL('translateDropdown.country.burundi') },
    { value: 'BEL', label: transUTL('translateDropdown.country.belgium') },
    { value: 'BEN', label: transUTL('translateDropdown.country.benin') },
    {
      value: 'BES',
      label: transUTL(
        'translateDropdown.country.bonaireSintEustatiusAndSaba'
      ),
    },
    {
      value: 'BFA',
      label: transUTL('translateDropdown.country.burkinaFaso'),
    },
    {
      value: 'BGD',
      label: transUTL('translateDropdown.country.bangladesh'),
    },
    { value: 'BGR', label: transUTL('translateDropdown.country.bulgaria') },
    { value: 'BHR', label: transUTL('translateDropdown.country.bahrain') },
    { value: 'BHS', label: transUTL('translateDropdown.country.bahamas') },
    {
      value: 'BIH',
      label: transUTL('translateDropdown.country.bosniaAndHerzegovina'),
    },
    {
      value: 'BLM',
      label: transUTL('translateDropdown.country.saintBarthélemy'),
    },
    { value: 'BLR', label: transUTL('translateDropdown.country.belarus') },
    { value: 'BLZ', label: transUTL('translateDropdown.country.belize') },
    { value: 'BMU', label: transUTL('translateDropdown.country.bermuda') },
    { value: 'BOL', label: transUTL('translateDropdown.country.bolivia') },
    { value: 'BRA', label: transUTL('translateDropdown.country.brazil') },
    { value: 'BRB', label: transUTL('translateDropdown.country.barbados') },
    {
      value: 'BRN',
      label: transUTL('translateDropdown.country.bruneiDarussalam'),
    },
    { value: 'BTN', label: transUTL('translateDropdown.country.bhutan') },
    {
      value: 'BVT',
      label: transUTL('translateDropdown.country.bouvetIsland'),
    },
    { value: 'BWA', label: transUTL('translateDropdown.country.botswana') },
    {
      value: 'CAF',
      label: transUTL('translateDropdown.country.centralAfricanRepublic'),
    },
    { value: 'CAN', label: transUTL('translateDropdown.country.canada') },
    {
      value: 'CCK',
      label: transUTL('translateDropdown.country.cocosKeelingIslands'),
    },
    {
      value: 'CHE',
      label: transUTL('translateDropdown.country.switzerland'),
    },
    { value: 'CHL', label: transUTL('translateDropdown.country.chile') },
    { value: 'CHN', label: transUTL('translateDropdown.country.china') },
    {
      value: 'CIV',
      label: transUTL('translateDropdown.country.côteDIvoire'),
    },
    { value: 'CMR', label: transUTL('translateDropdown.country.cameroon') },
    {
      value: 'COD',
      label: transUTL('translateDropdown.country.congoDemocraticRepublic'),
    },
    { value: 'COG', label: transUTL('translateDropdown.country.congo') },
    {
      value: 'COK',
      label: transUTL('translateDropdown.country.cookIslands'),
    },
    { value: 'COL', label: transUTL('translateDropdown.country.colombia') },
    { value: 'COM', label: transUTL('translateDropdown.country.comoros') },
    {
      value: 'CPV',
      label: transUTL('translateDropdown.country.caboVerde'),
    },
    {
      value: 'CRI',
      label: transUTL('translateDropdown.country.costaRica'),
    },
    { value: 'CUB', label: transUTL('translateDropdown.country.cuba') },
    { value: 'CUW', label: transUTL('translateDropdown.country.curaçao') },
    {
      value: 'CXR',
      label: transUTL('translateDropdown.country.christmasIsland'),
    },
    {
      value: 'CYM',
      label: transUTL('translateDropdown.country.caymanIslands'),
    },
    { value: 'CYP', label: transUTL('translateDropdown.country.cyprus') },
    { value: 'CZE', label: transUTL('translateDropdown.country.czechia') },
    { value: 'DEU', label: transUTL('translateDropdown.country.germany') },
    { value: 'DJI', label: transUTL('translateDropdown.country.djibouti') },
    { value: 'DMA', label: transUTL('translateDropdown.country.dominica') },
    { value: 'DNK', label: transUTL('translateDropdown.country.denmark') },
    {
      value: 'DOM',
      label: transUTL('translateDropdown.country.dominicanRepublic'),
    },
    { value: 'DZA', label: transUTL('translateDropdown.country.algeria') },
    { value: 'ECU', label: transUTL('translateDropdown.country.ecuador') },
    { value: 'EGY', label: transUTL('translateDropdown.country.egypt') },
    { value: 'ERI', label: transUTL('translateDropdown.country.eritrea') },
    {
      value: 'ESH',
      label: transUTL('translateDropdown.country.westernSahara'),
    },
    { value: 'ESP', label: transUTL('translateDropdown.country.spain') },
    { value: 'EST', label: transUTL('translateDropdown.country.estonia') },
    { value: 'ETH', label: transUTL('translateDropdown.country.ethiopia') },
    { value: 'FIN', label: transUTL('translateDropdown.country.finland') },
    { value: 'FJI', label: transUTL('translateDropdown.country.fiji') },
    {
      value: 'FLK',
      label: transUTL('translateDropdown.country.falklandIslandsMalvinas'),
    },
    { value: 'FRA', label: transUTL('translateDropdown.country.france') },
    {
      value: 'FRO',
      label: transUTL('translateDropdown.country.faroeIslands'),
    },
    {
      value: 'FSM',
      label: transUTL('translateDropdown.country.micronesia'),
    },
    { value: 'GAB', label: transUTL('translateDropdown.country.gabon') },
    {
      value: 'GBR',
      label: transUTL(
        'translateDropdown.country.greatbritain'
      ),
    },
    { value: 'GEO', label: transUTL('translateDropdown.country.georgia') },
    { value: 'GGY', label: transUTL('translateDropdown.country.guernsey') },
    { value: 'GHA', label: transUTL('translateDropdown.country.ghana') },
    {
      value: 'GIB',
      label: transUTL('translateDropdown.country.gibraltar'),
    },
    { value: 'GIN', label: transUTL('translateDropdown.country.guinea') },
    {
      value: 'GLP',
      label: transUTL('translateDropdown.country.guadeloupe'),
    },
    { value: 'GMB', label: transUTL('translateDropdown.country.gambia') },
    {
      value: 'GNB',
      label: transUTL('translateDropdown.country.guineaBissau'),
    },
    {
      value: 'GNQ',
      label: transUTL('translateDropdown.country.equatorialGuinea'),
    },
    { value: 'GRC', label: transUTL('translateDropdown.country.greece') },
    { value: 'GRD', label: transUTL('translateDropdown.country.grenada') },
    {
      value: 'GRL',
      label: transUTL('translateDropdown.country.greenland'),
    },
    {
      value: 'GTM',
      label: transUTL('translateDropdown.country.guatemala'),
    },
    {
      value: 'GUF',
      label: transUTL('translateDropdown.country.frenchGuiana'),
    },
    { value: 'GUM', label: transUTL('translateDropdown.country.guam') },
    { value: 'GUY', label: transUTL('translateDropdown.country.guyana') },
    { value: 'HKG', label: transUTL('translateDropdown.country.hongKong') },
    {
      value: 'HMD',
      label: transUTL(
        'translateDropdown.country.heardIslandAndMcDonaldIslands'
      ),
    },
    { value: 'HND', label: transUTL('translateDropdown.country.honduras') },
    { value: 'HRV', label: transUTL('translateDropdown.country.croatia') },
    { value: 'HTI', label: transUTL('translateDropdown.country.haiti') },
    { value: 'HUN', label: transUTL('translateDropdown.country.hungary') },
    {
      value: 'IDN',
      label: transUTL('translateDropdown.country.indonesia'),
    },
    {
      value: 'IMN',
      label: transUTL('translateDropdown.country.isleOfMan'),
    },
    { value: 'IND', label: transUTL('translateDropdown.country.india') },
    {
      value: 'IOT',
      label: transUTL(
        'translateDropdown.country.britishIndianOceanTerritory'
      ),
    },
    { value: 'IRL', label: transUTL('translateDropdown.country.ireland') },
    { value: 'IRN', label: transUTL('translateDropdown.country.iran') },
    { value: 'IRQ', label: transUTL('translateDropdown.country.iraq') },
    { value: 'ISL', label: transUTL('translateDropdown.country.iceland') },
    { value: 'ISR', label: transUTL('translateDropdown.country.israel') },
    { value: 'ITA', label: transUTL('translateDropdown.country.italy') },
    { value: 'JAM', label: transUTL('translateDropdown.country.jamaica') },
    { value: 'JEY', label: transUTL('translateDropdown.country.jersey') },
    { value: 'JOR', label: transUTL('translateDropdown.country.jordan') },
    { value: 'JPN', label: transUTL('translateDropdown.country.japan') },
    {
      value: 'KAZ',
      label: transUTL('translateDropdown.country.kazakhstan'),
    },
    { value: 'KEN', label: transUTL('translateDropdown.country.kenya') },
    {
      value: 'KGZ',
      label: transUTL('translateDropdown.country.kyrgyzstan'),
    },
    { value: 'KHM', label: transUTL('translateDropdown.country.cambodia') },
    { value: 'KIR', label: transUTL('translateDropdown.country.kiribati') },
    {
      value: 'KNA',
      label: transUTL('translateDropdown.country.saintKittsAndNevis'),
    },
    { value: 'KOR', label: transUTL('translateDropdown.country.korea') },
    { value: 'KWT', label: transUTL('translateDropdown.country.kuwait') },
    {
      value: 'LAO',
      label: transUTL(
        'translateDropdown.country.laoPeoplesDemocraticRepublic'
      ),
    },
    { value: 'LBN', label: transUTL('translateDropdown.country.lebanon') },
    { value: 'LBR', label: transUTL('translateDropdown.country.liberia') },
    { value: 'LBY', label: transUTL('translateDropdown.country.libya') },
    {
      value: 'LCA',
      label: transUTL('translateDropdown.country.saintLucia'),
    },
    {
      value: 'LIE',
      label: transUTL('translateDropdown.country.liechtenstein'),
    },
    { value: 'LKA', label: transUTL('translateDropdown.country.sriLanka') },
    { value: 'LSO', label: transUTL('translateDropdown.country.lesotho') },
    {
      value: 'LTU',
      label: transUTL('translateDropdown.country.lithuania'),
    },
    {
      value: 'LUX',
      label: transUTL('translateDropdown.country.luxembourg'),
    },
    { value: 'LVA', label: transUTL('translateDropdown.country.latvia') },
    { value: 'MAC', label: transUTL('translateDropdown.country.macao') },
    {
      value: 'MAF',
      label: transUTL('translateDropdown.country.saintMartinFrenchPart'),
    },
    { value: 'MAR', label: transUTL('translateDropdown.country.morocco') },
    { value: 'MCO', label: transUTL('translateDropdown.country.monaco') },
    { value: 'MDA', label: transUTL('translateDropdown.country.moldova') },
    {
      value: 'MDG',
      label: transUTL('translateDropdown.country.madagascar'),
    },
    { value: 'MDV', label: transUTL('translateDropdown.country.maldives') },
    { value: 'MEX', label: transUTL('translateDropdown.country.mexico') },
    {
      value: 'MHL',
      label: transUTL('translateDropdown.country.marshallIslands'),
    },
    {
      value: 'MKD',
      label: transUTL('translateDropdown.country.macedonia'),
    },
    { value: 'MLI', label: transUTL('translateDropdown.country.mali') },
    { value: 'MLT', label: transUTL('translateDropdown.country.malta') },
    { value: 'MMR', label: transUTL('translateDropdown.country.myanmar') },
    {
      value: 'MNE',
      label: transUTL('translateDropdown.country.montenegro'),
    },
    { value: 'MNG', label: transUTL('translateDropdown.country.mongolia') },
    {
      value: 'MNP',
      label: transUTL('translateDropdown.country.northernMarianaIslands'),
    },
    {
      value: 'MOZ',
      label: transUTL('translateDropdown.country.mozambique'),
    },
    {
      value: 'MRT',
      label: transUTL('translateDropdown.country.mauritania'),
    },
    {
      value: 'MSR',
      label: transUTL('translateDropdown.country.montserrat'),
    },
    {
      value: 'MTQ',
      label: transUTL('translateDropdown.country.martinique'),
    },
    {
      value: 'MUS',
      label: transUTL('translateDropdown.country.mauritius'),
    },
    { value: 'MWI', label: transUTL('translateDropdown.country.malawi') },
    { value: 'MYS', label: transUTL('translateDropdown.country.malaysia') },
    { value: 'MYT', label: transUTL('translateDropdown.country.mayotte') },
    { value: 'NAM', label: transUTL('translateDropdown.country.namibia') },
    {
      value: 'NCL',
      label: transUTL('translateDropdown.country.newCaledonia'),
    },
    { value: 'NER', label: transUTL('translateDropdown.country.niger') },
    {
      value: 'NFK',
      label: transUTL('translateDropdown.country.norfolkIsland'),
    },
    { value: 'NGA', label: transUTL('translateDropdown.country.nigeria') },
    {
      value: 'NIC',
      label: transUTL('translateDropdown.country.nicaragua'),
    },
    { value: 'NIU', label: transUTL('translateDropdown.country.niue') },
    {
      value: 'NLD',
      label: transUTL('translateDropdown.country.netherlands'),
    },
    { value: 'NOR', label: transUTL('translateDropdown.country.norway') },
    { value: 'NPL', label: transUTL('translateDropdown.country.nepal') },
    { value: 'NRU', label: transUTL('translateDropdown.country.nauru') },
    {
      value: 'NZL',
      label: transUTL('translateDropdown.country.newZealand'),
    },
    { value: 'OMN', label: transUTL('translateDropdown.country.oman') },
    { value: 'PAK', label: transUTL('translateDropdown.country.pakistan') },
    { value: 'PAN', label: transUTL('translateDropdown.country.panama') },
    { value: 'PCN', label: transUTL('translateDropdown.country.pitcairn') },
    { value: 'PER', label: transUTL('translateDropdown.country.peru') },
    {
      value: 'PHL',
      label: transUTL('translateDropdown.country.philippines'),
    },
    { value: 'PLW', label: transUTL('translateDropdown.country.palau') },
    {
      value: 'PNG',
      label: transUTL('translateDropdown.country.papuaNewGuinea'),
    },
    { value: 'POL', label: transUTL('translateDropdown.country.poland') },
    {
      value: 'PRI',
      label: transUTL('translateDropdown.country.puertoRico'),
    },
    { value: 'PRT', label: transUTL('translateDropdown.country.portugal') },
    { value: 'PRY', label: transUTL('translateDropdown.country.paraguay') },
    {
      value: 'PSE',
      label: transUTL('translateDropdown.country.palestine'),
    },
    {
      value: 'PYF',
      label: transUTL('translateDropdown.country.frenchpPolynesia'),
    },
    { value: 'QAT', label: transUTL('translateDropdown.country.qatar') },
    { value: 'REU', label: transUTL('translateDropdown.country.réunion') },
    { value: 'ROU', label: transUTL('translateDropdown.country.romania') },
    {
      value: 'RUS',
      label: transUTL('translateDropdown.country.russianFederation'),
    },
    { value: 'RWA', label: transUTL('translateDropdown.country.rwanda') },
    {
      value: 'SAU',
      label: transUTL('translateDropdown.country.saudiArabia'),
    },
    { value: 'SDN', label: transUTL('translateDropdown.country.sudan') },
    { value: 'SEN', label: transUTL('translateDropdown.country.senegal') },
    {
      value: 'SGP',
      label: transUTL('translateDropdown.country.singapore'),
    },
    {
      value: 'SGS',
      label: transUTL(
        'translateDropdown.country.southGeorgiaAndTheSouthSandwichIslands'
      ),
    },
    {
      value: 'SHN',
      label: transUTL(
        'translateDropdown.country.saintHelenaAscensionAndTristanDaCunha'
      ),
    },
    {
      value: 'SJM',
      label: transUTL('translateDropdown.country.svalbardAndJanMayen'),
    },
    {
      value: 'SLB',
      label: transUTL('translateDropdown.country.solomonIslands'),
    },
    {
      value: 'SLE',
      label: transUTL('translateDropdown.country.sierraLeone'),
    },
    {
      value: 'SLV',
      label: transUTL('translateDropdown.country.elSalvador'),
    },
    {
      value: 'SMR',
      label: transUTL('translateDropdown.country.sanMarino'),
    },
    { value: 'SOM', label: transUTL('translateDropdown.country.somalia') },
    {
      value: 'SPM',
      label: transUTL('translateDropdown.country.saintPierreAndMiquelon'),
    },
    { value: 'SRB', label: transUTL('translateDropdown.country.serbia') },
    {
      value: 'SSD',
      label: transUTL('translateDropdown.country.southSudan'),
    },
    {
      value: 'STP',
      label: transUTL('translateDropdown.country.saoTomeAndPrincipe'),
    },
    { value: 'SUR', label: transUTL('translateDropdown.country.suriname') },
    { value: 'SVK', label: transUTL('translateDropdown.country.slovakia') },
    { value: 'SVN', label: transUTL('translateDropdown.country.slovenia') },
    { value: 'SWE', label: transUTL('translateDropdown.country.sweden') },
    { value: 'SWZ', label: transUTL('translateDropdown.country.eswatini') },
    {
      value: 'SXM',
      label: transUTL('translateDropdown.country.sintMaartenDutchPart'),
    },
    {
      value: 'SYC',
      label: transUTL('translateDropdown.country.seychelles'),
    },
    {
      value: 'SYR',
      label: transUTL('translateDropdown.country.syrianArabRepublic'),
    },
    {
      value: 'TCA',
      label: transUTL('translateDropdown.country.turksAndCaicosIslands'),
    },
    { value: 'TCD', label: transUTL('translateDropdown.country.chad') },
    { value: 'TGO', label: transUTL('translateDropdown.country.togo') },
    { value: 'THA', label: transUTL('translateDropdown.country.thailand') },
    {
      value: 'TJK',
      label: transUTL('translateDropdown.country.tajikistan'),
    },
    { value: 'TKL', label: transUTL('translateDropdown.country.tokelau') },
    {
      value: 'TKM',
      label: transUTL('translateDropdown.country.turkmenistan'),
    },
    {
      value: 'TLS',
      label: transUTL('translateDropdown.country.timorLeste'),
    },
    { value: 'TON', label: transUTL('translateDropdown.country.tonga') },
    {
      value: 'TTO',
      label: transUTL('translateDropdown.country.trinidadAndTobago'),
    },
    { value: 'TUN', label: transUTL('translateDropdown.country.tunisia') },
    { value: 'TUR', label: transUTL('translateDropdown.country.turkey') },
    { value: 'TUV', label: transUTL('translateDropdown.country.tuvalu') },
    {
      value: 'TWN',
      label: transUTL('translateDropdown.country.taiwanProvinceOfChina'),
    },
    { value: 'TZA', label: transUTL('translateDropdown.country.tanzania') },
    { value: 'UGA', label: transUTL('translateDropdown.country.uganda') },
    { value: 'UKR', label: transUTL('translateDropdown.country.ukraine') },
    {
      value: 'UMI',
      label: transUTL(
        'translateDropdown.country.unitedStatesMinorOutlyingIslands'
      ),
    },
    { value: 'URY', label: transUTL('translateDropdown.country.uruguay') },
    {
      value: 'USA',
      label: transUTL('translateDropdown.country.unitedStatesOfAmerica'),
    },
    {
      value: 'UZB',
      label: transUTL('translateDropdown.country.uzbekistan'),
    },
    {
      value: 'VCT',
      label: transUTL(
        'translateDropdown.country.saintVincentAndTheGrenadines'
      ),
    },
    {
      value: 'VEN',
      label: transUTL('translateDropdown.country.venezuela'),
    },
    {
      value: 'VGB',
      label: transUTL('translateDropdown.country.virginIslandsBritish'),
    },
    {
      value: 'VIR',
      label: transUTL('translateDropdown.country.virginIslandsUS'),
    },
    { value: 'VNM', label: transUTL('translateDropdown.country.vietNam') },
    { value: 'VUT', label: transUTL('translateDropdown.country.vanuatu') },
    {
      value: 'WLF',
      label: transUTL('translateDropdown.country.wallisAndFutuna'),
    },
    { value: 'WSM', label: transUTL('translateDropdown.country.samoa') },
    { value: 'YEM', label: transUTL('translateDropdown.country.yemen') },
    {
      value: 'ZAF',
      label: transUTL('translateDropdown.country.southAfrica'),
    },
    { value: 'ZMB', label: transUTL('translateDropdown.country.zambia') },
    { value: 'ZWE', label: transUTL('translateDropdown.country.zimbabwe') },
  ]
};
export default countryOptions;
