import { combineReducers } from 'redux';

// REDUCERS
import errorControllerReducer from './errorController/errorControllerReducer';
import authReducer from './auth/authReducer';
import loadingReducer from './loading/loadingReducer';
import alertReducer from './alert/alertReducer';
import languageReducer from './language/languageReducer';
import countryReducer from './country/countryReducer';
import regionReducer from './region/regionReducer';
import dataSourcesReducer from './dataSources/dataSourcesReducer';
import dataSourceSelectionsReducer from './dataSources/dataSourceSelectionsReducer';
import formDataReducer from './formData/formDataReducer';
import userInputReducer from './formData/userInputReducer';
import apiReqReducer from './formData/apiReqReducer';
import searchVerificationReducer from './searchVerification/searchVerificationReducer';
import transactionHistoryReducer from './transactionHistory/transactionHistoryReducer';
import totalRecordReducer from './transactionHistory/totalRecordReducer';
import dataAnalyticsReducer from './dataAnalytics/dataAnalyticsReducer';
import usageReportReducer from './usageReport/usageReportReducer';
import costEstimationReducer from './costEstimation/costEstimationReducer';
import biometricsReducer from './biometrics/biometricsReducer';
import adminReducer from './admin/adminReducer';

// ACTIONS
import { USER_LOGOUT } from '../actions/types';

const rootReducer = combineReducers({
  errorsRXS: errorControllerReducer,
  authRXS: authReducer,
  loadingRXS: loadingReducer,
  alertRXS: alertReducer,
  languageRXS: languageReducer,
  countryRXS: countryReducer,
  regionRXS: regionReducer,
  dataSourcesRXS: dataSourcesReducer,
  dataSourceSelectionsRXS: dataSourceSelectionsReducer,
  formDataRXS: formDataReducer,
  userInputRXS: userInputReducer,
  apiReqRXS: apiReqReducer,
  searchVerificationRXS: searchVerificationReducer,
  transHistoryRXS: transactionHistoryReducer,
  totalRecordRXS: totalRecordReducer, 
  dataAnalyticsRXS: dataAnalyticsReducer,
  usageReportRXS: usageReportReducer,
  costEstRXS: costEstimationReducer,
  biometricsRXS: biometricsReducer,
  adminRXS: adminReducer,
});

export default (state, action) =>
  action.type === USER_LOGOUT
    ? rootReducer(undefined, action)
    : rootReducer(state, action);
