// HELPERS
import tableFilter from "../../../../helpers/tableFilter";

// UTILS
import naUTL from "../../../../../../utils/naUTL";

const jsonTableRow = dataSources => {
  const { nationalID, taxRegistration, watchlistAML } = dataSources;

  const table = [
    {
      col1: "Status",
      col2: naUTL(nationalID.status),
      col3: naUTL(watchlistAML.status),
      col4: naUTL(taxRegistration.status)
    },
    {
      col1: "Safe Harbour Score",
      col2: naUTL(nationalID.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
      col4: naUTL(taxRegistration.safeHarbourScore)
    },
    {
      col1: "Identity Verified",
      col2: naUTL(nationalID.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
      col4: naUTL(taxRegistration.identityVerified)
    },
    {
      col1: "Name Match Score",
      col2: naUTL(nationalID.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
      col4: naUTL(taxRegistration.nameMatchScore)
    },
    {
      col1: "First Name Verified",
      col2: naUTL(nationalID.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName),
      col4: naUTL(taxRegistration.verifications.firstName)
    },
    {
      col1: "Last Name Verified",
      col2: naUTL(nationalID.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
      col4: naUTL(taxRegistration.verifications.lastName)
    },
    {
      col1: "Full Name Verified",
      col2: naUTL(nationalID.verifications.fullName),
      col3: naUTL(watchlistAML.verifications.fullName),
      col4: naUTL(taxRegistration.verifications.fullName)
    },
    {
      col1: "Date Of Birth Verified",
      col2: naUTL(nationalID.verifications.dateOfBirth),
      col3: naUTL(watchlistAML.verifications.dateOfBirth),
      col4: naUTL(taxRegistration.verifications.dateOfBirth)
    },
    {
      col1: "National ID Verified",
      col2: naUTL(nationalID.verifications.nationalIDNo),
      col3: naUTL(watchlistAML.verifications.nationalIDNo),
      col4: naUTL(taxRegistration.verifications.nationalIDNo),
    },
    {
      col1: "CPF Verified",
      col2: naUTL(nationalID.verifications.cpf),
      col3: naUTL(watchlistAML.verifications.cpf),
      col4: naUTL(taxRegistration.verifications.cpf)
    },
    {
      col1: "Unit No Verified",
      col2: naUTL(nationalID.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1),
      col4: naUTL(taxRegistration.verifications.addressElement1)
    },
    {
      col1: "Street Verified",
      col2: naUTL(nationalID.verifications.addressElement2),
      col3: naUTL(watchlistAML.verifications.addressElement2),
      col4: naUTL(taxRegistration.verifications.addressElement2)
    },
    {
      col1: "Locality Verified",
      col2: naUTL(nationalID.verifications.addressElement3),
      col3: naUTL(watchlistAML.verifications.addressElement3),
      col4: naUTL(taxRegistration.verifications.addressElement3)
    },
    {
      col1: "District Verified",
      col2: naUTL(nationalID.verifications.addressElement4),
      col3: naUTL(watchlistAML.verifications.addressElement4),
      col4: naUTL(taxRegistration.verifications.addressElement4)
    },
    {
      col1: "Postcode Verified",
      col2: naUTL(nationalID.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5),
      col4: naUTL(taxRegistration.verifications.addressElement5)
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
