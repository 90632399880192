import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { countryList } from '../../../utils/countryListUTL';

import TextFieldGroup from '../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../components/common/selectListGroup/SelectListGroup';

import {
  retrieveOrganisationsAXN,
  createUserAXN
} from '../../../actions/admin/adminActions';
import { validatePassword } from './validations/passwordValidation';
import newUserFormVAL from './validations/newUserFormValidation';
import windowTopUTL from '../../../utils/windowTopUTL';
import loggerUTL from '../../../utils/loggerUTL';
import MultipleSelectGroup from '../../../components/common/mutipleSelectGroup/MultipleSelectGroup';
import CheckBoxGroup from '../../../components/common/checkBoxGroup/CheckBoxGroup';

const CreateUserForm = ({
  adminRXS,
  dataSourcesRXS,
  retrieveOrganisationsAXN,
  createUserAXN,
  history
}) => {

  // TODO: can probably structure the below state better.
  const [newUserFormSTH, setNewUserFormSTH] = useState({
    username: '',
    email: '',
    country: '',
    orgID: '',
    password: '',
    passwordConfirm: '',
    passwordTouched: false,
    confirmTouched: false,
    passwordValid: {
      isValid: false,
      errMessage: "",
    },
    formValid: {
      isValid: false,
      inputErrors: {},
      userNameTouched: false,
      emailTouched: false,
      countryTouched: false,
      orgIDTouched: false,
    },
  });
  const [dataSourceSTH, setDataSourceSTH] = useState({
    dataSources: {},
    countries: [],
    selectedDataSources: [],
    selectedCountry: '',
    allSelected: false,
  });
  const [organisationList, setOrganisationList] = useState([]);

  const countries = countryList.map((country) => {
    return ({
      value: country,
      label: country
    });
  });

  countries.unshift({
    value: '',
    label: 'Please select a country'
  });

  useEffect(() => {
    windowTopUTL();
  }, [])

  // Initialize DataSources State
  useEffect(() => {
    let dataSources = {};
    let countries = [];
    dataSources = initializeDataSources(dataSourcesRXS);

    for (const country in dataSources) {

      // This is why we need to re-look into how data is managed,
      // So that code like this is not necessary.
      if (country === 'GlobalAll' || country === 'multiService') continue;

      countries.push({
        value: country,
        label: country,
      });
    }

    setDataSourceSTH({
      ...dataSourceSTH,
      dataSources: {
        ...dataSources
      },
      countries: [...countries],
      selectedCountry: countries[0].value,
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    retrieveOrganisationsAXN();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const list = adminRXS.organisations.map((org) => {
      return ({
        value: org.ID,
        label: org.Name
      });
    });

    list.unshift({
      value: '',
      label: 'Please select an organisation'
    });

    setOrganisationList([
      ...list
    ]);
  }, [adminRXS.organisations]);

  // TODO: Really need to revisit how state and data is managed on the frontend.
  // Parsing the datasources in the state so that they can be used in the form.
  const initializeDataSources = (dataSources) => {
    let parsedDataSources = {};

    for (const country in dataSources) {
      let countryName = country.replace('DataSources', '');
      countryName = countryName.charAt(0).toUpperCase() + countryName.substring(1);

      if (countryName === 'GlobalAll' || country === 'multiService') continue;

      parsedDataSources = {
        ...parsedDataSources,
        [countryName]: [],
      }

      for (const dataSource in dataSources[country]) {
        parsedDataSources[countryName].push(dataSource);
      }
    }

    return parsedDataSources;
  }

  const handleOnSubmit = () => {
    const newUserData = {
      username: newUserFormSTH.username,
      email: newUserFormSTH.email,
      country: newUserFormSTH.country,
      organisation_id: Number(newUserFormSTH.orgID),
      password: newUserFormSTH.password,
      data_sources_permitted: []
    };

    if (dataSourceSTH.allSelected) {
      newUserData.data_sources_permitted = ['*'];
    } else {
      newUserData.data_sources_permitted = [...dataSourceSTH.selectedDataSources];
    }

    loggerUTL('New User Data: ', newUserData);

    createUserAXN(newUserData);
  }

  const handleOnChange = (event) => {
    const currentFormState = {
      ...newUserFormSTH,
      [event.target.name]: event.target.value,
    }
    const formValidationResult = newUserFormVAL(currentFormState);
    const touchedKeyName = event.target.name + 'Touched';

    setNewUserFormSTH({
      ...newUserFormSTH,
      [event.target.name]: event.target.value,
      formValid: {
        ...newUserFormSTH.formValid,
        ...formValidationResult,
        [touchedKeyName]: true
      }
    });
  };

  const handlePasswordOnChange = (event) => {
    let validatePasswordResult = {};
    let passwordTouched = newUserFormSTH.passwordTouched;
    let confirmTouched = newUserFormSTH.confirmTouched;

    if (event.target.name === 'password') {
      validatePasswordResult = validatePassword(event.target.value, newUserFormSTH.passwordConfirm);
      passwordTouched = true;
    }

    if (event.target.name === 'passwordConfirm') {
      validatePasswordResult = validatePassword(newUserFormSTH.password, event.target.value);
      confirmTouched = true;
    }

    setNewUserFormSTH({
      ...newUserFormSTH,
      [event.target.name]: event.target.value,
      passwordTouched: passwordTouched,
      confirmTouched: confirmTouched,
      passwordValid: {
        ...validatePasswordResult
      }
    });
  };

  const handleDataSourceCountryChange = (event) => {
    setDataSourceSTH({
      ...dataSourceSTH,
      selectedCountry: event.target.value
    })
  }

  const handleDataSourceToggle = (dataSource) => {
    let updatedSelectedDataSources = [...dataSourceSTH.selectedDataSources];

    if (updatedSelectedDataSources.includes(dataSource)) {
      updatedSelectedDataSources.splice(updatedSelectedDataSources.indexOf(dataSource), 1);
    } else {
      updatedSelectedDataSources.push(dataSource);
    }

    setDataSourceSTH({
      ...dataSourceSTH,
      selectedDataSources: [...updatedSelectedDataSources]
    })
  }

  const handleAllCountryDataSourcesToggle = (dataSources, allSelected) => {
    let updatedSelectedDataSources = [...dataSourceSTH.selectedDataSources];

    if (allSelected) {
      dataSources.forEach((dataSource) => {
        if (updatedSelectedDataSources.includes(dataSource)) {
          updatedSelectedDataSources.splice(updatedSelectedDataSources.indexOf(dataSource), 1);
        }
      })
    } else {
      dataSources.forEach((dataSource) => {
        if (!updatedSelectedDataSources.includes(dataSource)) {
          updatedSelectedDataSources.push(dataSource);
        }
      })
    }

    setDataSourceSTH({
      ...dataSourceSTH,
      selectedDataSources: [...updatedSelectedDataSources]
    })
  }

  const handleAllDataSourcesToggle = (allSelected) => {
    let updatedSelectedDataSources = [...dataSourceSTH.selectedDataSources];

    if (allSelected) {
      for (const country in dataSourceSTH.dataSources) {
        dataSourceSTH.dataSources[country].forEach((dataSource) => {
          if (!updatedSelectedDataSources.includes(dataSource)) {
            updatedSelectedDataSources.push(dataSource);
          }
        });
      }
    } else {
      for (const country in dataSourceSTH.dataSources) {
        dataSourceSTH.dataSources[country].forEach((dataSource) => {
          if (updatedSelectedDataSources.includes(dataSource)) {
            updatedSelectedDataSources.splice(updatedSelectedDataSources.indexOf(dataSource), 1);
          }
        });
      }
    }

    setDataSourceSTH({
      ...dataSourceSTH,
      selectedDataSources: [...updatedSelectedDataSources],
      allSelected: allSelected
    })
  }

  return (
    <div className="common-form">
      <header className="header-primary">
        <h2>Create New User</h2>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        <div className="form-overview__form-section">
          <div className="form-overview__top">
            <header className="header-secondary">
              <h4 className="header-title-secondary">
                User Details
              </h4>
            </header>
          </div>
          <div className="form-overview__form-inputs">
            <TextFieldGroup
              key="username"
              id="username"
              type="text"
              name="username"
              dataName="username"
              labelClass="form-group__label"
              label="username"
              value={newUserFormSTH.username}
              handleOnChange={handleOnChange}
              errorTrigger={
                (newUserFormSTH.formValid.inputErrors.username && newUserFormSTH.formValid.usernameTouched) ?
                  'input-error'
                  : ''
              }
              error={
                newUserFormSTH.formValid.usernameTouched ?
                  newUserFormSTH.formValid.inputErrors.username
                  : null
              }
            />
            <TextFieldGroup
              key="email"
              id="email"
              type="text"
              name="email"
              dataName="email"
              labelClass="form-group__label"
              label="email"
              value={newUserFormSTH.email}
              handleOnChange={handleOnChange}
              errorTrigger={
                (newUserFormSTH.formValid.inputErrors.email && newUserFormSTH.formValid.emailTouched) ?
                  'input-error'
                  : ''
              }
              error={
                newUserFormSTH.formValid.emailTouched ?
                  newUserFormSTH.formValid.inputErrors.email
                  : null
              }
            />
            <SelectListGroup
              key="country"
              id="country"
              name="country"
              dataName="country"
              labelClass="form-group__label"
              label="country"
              value={newUserFormSTH.country}
              options={countries}
              handleOnChange={handleOnChange}
              errorTrigger={
                (newUserFormSTH.formValid.inputErrors.country && newUserFormSTH.formValid.countryTouched) ?
                  'input-error'
                  : ''
              }
              error={
                newUserFormSTH.formValid.countryTouched ?
                  newUserFormSTH.formValid.inputErrors.country
                  : null
              }
            />
            <SelectListGroup
              key="orgID"
              id="orgID"
              name="orgID"
              dataName="orgID"
              labelClass="form-group__label"
              label="Organisation"
              value={newUserFormSTH.orgID}
              options={organisationList}
              handleOnChange={handleOnChange}
              errorTrigger={
                (newUserFormSTH.formValid.inputErrors.orgID && newUserFormSTH.formValid.orgIDTouched) ?
                  'input-error'
                  : ''
              }
              error={
                newUserFormSTH.formValid.orgIDTouched ?
                  newUserFormSTH.formValid.inputErrors.orgID
                  : null
              }
            />
            <TextFieldGroup
              key="password"
              id="password"
              type="password"
              name="password"
              dataName="password"
              labelClass="form-group__label"
              label="password"
              value={newUserFormSTH.password}
              handleOnChange={handlePasswordOnChange}
              errorTrigger={
                (newUserFormSTH.passwordTouched && !newUserFormSTH.passwordValid.isValid) ?
                  'input-error'
                  : ''
              }
              error={
                (newUserFormSTH.passwordTouched && !newUserFormSTH.passwordValid.isValid) ?
                  newUserFormSTH.passwordValid.errMessage
                  : null
              }
            />
            <TextFieldGroup
              key="passwordConfirm"
              id="passwordConfirm"
              type="password"
              name="passwordConfirm"
              dataName="passwordConfirm"
              labelClass="form-group__label"
              label="confirm password"
              value={newUserFormSTH.passwordConfirm}
              handleOnChange={handlePasswordOnChange}
              errorTrigger={
                (newUserFormSTH.confirmTouched && !newUserFormSTH.passwordValid.isValid) ?
                  'input-error'
                  : ''
              }
              error={
                (newUserFormSTH.confirmTouched && !newUserFormSTH.passwordValid.isValid) ?
                  newUserFormSTH.passwordValid.errMessage
                  : null
              }
            />
          </div>
        </div>
        <div className="form-overview__form-section">
          <div className="form-overview__top">
            <header className="header-secondary">
              <h4 className="header-title-secondary">
                Data Sources
              </h4>
            </header>
          </div>
          <div className="form-overview__form-inputs">
            <SelectListGroup
              key="dataSourceCountry"
              id="dataSourceCountry"
              name="dataSourceCountry"
              dataName="dataSourceCountry"
              labelClass="form-group__label"
              label="Country"
              value={dataSourceSTH.selectedCountry}
              options={dataSourceSTH.countries}
              handleOnChange={handleDataSourceCountryChange}
            />
            {/* Used to format width of above element */}
            <div />
          </div>
          <div className="form-overview__form-inputs" style={{ padding: '0rem 1rem' }}>
            <CheckBoxGroup
              label='Select All Countries & All Services'
              checkboxBool={dataSourceSTH.allSelected}
              handleToggleCheckbox={() => handleAllDataSourcesToggle(!dataSourceSTH.allSelected)}
            />
            {/* Used to format width of above element */}
            <div />
          </div>
          <div className="form-overview__form-inputs">
            <MultipleSelectGroup
              selectItems={
                dataSourceSTH.dataSources[dataSourceSTH.selectedCountry]
                  ? dataSourceSTH.dataSources[dataSourceSTH.selectedCountry]
                  : []
              }
              selectedItems={dataSourceSTH.selectedDataSources}
              handleToggle={handleDataSourceToggle}
              handleToggleAll={handleAllCountryDataSourcesToggle}
            />
          </div>
        </div>
        <div className="btn-container btn-under">
          <button
            className="btn-primary"
            onClick={() => history.goBack()}
          >
            Previous Page
          </button>
          <button
            className="btn-primary-green"
            disabled={(!newUserFormSTH.formValid.isValid || !newUserFormSTH.passwordValid.isValid)}
            onClick={() => handleOnSubmit()}
          >
            <span
              style={{ verticalAlign: "middle" }}
            >
              Create User
            </span>
            <span
              className="material-icons icon-size"
              style={{ marginLeft: "0.5rem", verticalAlign: "middle" }}
            >
              create
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

CreateUserForm.propTypes = {
  adminRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  retrieveOrganisationsAXN: PropTypes.func.isRequired,
  createUserAXN: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  adminRXS: state.adminRXS,
  dataSourcesRXS: state.dataSourcesRXS,
});

export default connect(mapStateToProps, {
  retrieveOrganisationsAXN,
  createUserAXN
})(CreateUserForm);