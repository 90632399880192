import { SET_LANGUAGE } from '../../actions/types';

const initialState = 'en';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}
