import React from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../../../../components/common/selectListGroup/SelectListGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressMalaysia = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const { streetNo, streetName, city, state, postCode, errors } = formState;

  const stateOptions = [
    { label: transUTL('translateDropdown.select'), value: '' },
    {
      label: transUTL('translateDropdown.stateMY.johor'),
      value: "Johor",
    },
    {
      label: transUTL('translateDropdown.stateMY.kedah'),
      value: 'Kedah',
    },
    {
      label: transUTL('translateDropdown.stateMY.kelantan'),
      value: 'Kelantan',
    },
    {
      label: transUTL('translateDropdown.stateMY.kualaLumpur'),
      value: 'Kuala Lumpur',
    },
    { label: transUTL('translateDropdown.stateMY.labuan'), value: 'Labuan' },
    { label: transUTL('translateDropdown.stateMY.malacca'), value: 'Malacca' },
    {
      label: transUTL('translateDropdown.stateMY.negeriSembilan'),
      value: 'Negeri Sembilan',
    },
    {
      label: transUTL('translateDropdown.stateMY.pahang'),
      value: 'Pahang',
    },
    { label: transUTL('translateDropdown.stateMY.penang'), value: 'Penang' },
    {
      label: transUTL('translateDropdown.stateMY.perak'),
      value: 'Perak',
    },
    {
      label: transUTL('translateDropdown.stateMY.perlis'),
      value: 'Perlis',
    },
    {
      label: transUTL('translateDropdown.stateMY.putrajaya'),
      value: 'Putrajaya',
    },
    { label: transUTL('translateDropdown.stateMY.sabah'), value: 'Sabah' },
    {
      label: transUTL('translateDropdown.stateMY.sarawak'),
      value: 'Sarawak',
    },
    {
      label: transUTL('translateDropdown.stateMY.selangor'),
      value: 'Selangor',
    },
    {
      label: transUTL('translateDropdown.stateMY.terengganu'),
      value: 'Terengganu',
    },
  ];

  const inputElements = [
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <TextFieldGroup
          key="streetNo"
          id="streetNo"
          type="text"
          name="streetNo"
          dataName="streetNoMY"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetNo')}
          value={streetNo}
          placeholder={transUTL('translatePlaceholder.inputMY.streetNo')}
          placeholderTrigger={isEmptyVAL(streetNo) ? 'input-empty' : ''}
          error={errors.streetNo}
          errorTrigger={errors.streetNo ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <TextFieldGroup
          key="streetName"
          id="streetName"
          type="text"
          name="streetName"
          dataName="streetNameMY"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.streetName')}
          value={streetName}
          placeholder={transUTL('translatePlaceholder.inputMY.streetName')}
          placeholderTrigger={isEmptyVAL(streetName) ? 'input-empty' : ''}
          error={errors.streetName}
          errorTrigger={errors.streetName ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityMY"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.city')}
          value={city}
          placeholder={transUTL('translatePlaceholder.inputMY.city')}
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <SelectListGroup
          key="state"
          id="state"
          name="state"
          dataName="stateMY"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholderTrigger={isEmptyVAL(state) ? 'input-empty' : ''}
          options={stateOptions}
          error={errors.state}
          errorTrigger={errors.state ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },

    {
      belongsTo: ['Malaysia National ID', 'Malaysia Credit Bureau'],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="streetNoMY"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputMY.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {outputMasterUTL(selectedDataSources, inputElements)}
      <span />
    </div>
  );
};

CurrentResidentialAddressMalaysia.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressMalaysia;
