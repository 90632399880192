// HELPERS
import tableFilter from "../../../../helpers/tableFilter";

// UTILS
import naUTL from "../../../../../../utils/naUTL";

const jsonTableRow = dataSources => {
  const { phone, watchlistAML } = dataSources;

  const table = [
    {
      col1: "Status",
      col2: naUTL(phone.status),
      col3: naUTL(watchlistAML.status),
    },
    {
      col1: "Safe Harbour Score",
      col2: naUTL(phone.safeHarbourScore),
      col3: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: "Identity Verified",
      col2: naUTL(phone.identityVerified),
      col3: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: "Name Match Score",
      col2: naUTL(phone.nameMatchScore),
      col3: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: "Address Match Score",
      col2: naUTL(phone.nameMatchScore),
      col3: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: "First Name Verified",
      col2: naUTL(phone.verifications.firstName),
      col3: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: "Last Name Verified",
      col2: naUTL(phone.verifications.lastName),
      col3: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: "Middle Name Verified",
      col2: naUTL(phone.verifications.middleName),
      col3: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: "Address Line 1 Verified",
      col2: naUTL(phone.verifications.addressElement1),
      col3: naUTL(watchlistAML.verifications.addressElement1),
    },
    {
      col1: "City Verified",
      col2: naUTL(phone.verifications.addressElement3),
      col3: naUTL(watchlistAML.verifications.addressElement3),
    },
    {
      col1: "Province Verified",
      col2: naUTL(phone.verifications.addressElement4),
      col3: naUTL(watchlistAML.verifications.addressElement4),
    },
    {
      col1: "Postcode Verified",
      col2: naUTL(phone.verifications.addressElement5),
      col3: naUTL(watchlistAML.verifications.addressElement5),
    },
    {
      col1: "Phone No. Verified",
      col2: naUTL(phone.verifications.phoneNo),
      col3: naUTL(watchlistAML.verifications.phoneNo),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
