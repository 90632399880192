import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { uploadBiometricScansAXN } from '../../actions/transactionHistory/transactionHistoryActions';

// COUNTRY FORMS
import AustraliaResults from './countrySearchResults/australia/AustraliaResults';
import BrazilResults from './countrySearchResults/brazil/BrazilResults';
import ChinaResults from './countrySearchResults/china/ChinaResults';
import IndiaResults from './countrySearchResults/india/IndiaResults';
import IndonesiaResults from './countrySearchResults/indonesia/IndonesiaResults';
import MalaysiaResults from './countrySearchResults/malaysia/MalaysiaResults';
import VietnamResults from './countrySearchResults/vietnam/VietnamResults';
import PhilippinesResults from './countrySearchResults/philippines/PhilippinesResults';
import CambodiaResults from './countrySearchResults/cambodia/CambodiaResults';
import NewZealandResults from './countrySearchResults/newzealand/NewZealandResults';
import NigeriaResults from './countrySearchResults/nigeria/NigeriaResults';
import HonKongResults from './countrySearchResults/hongKong/HonKongResults';
import SingaporeResults from './countrySearchResults/singapore/SingaporeResults';
import UnitedStatesResults from './countrySearchResults/unitedStates/UnitedStatesResults';
import MexicoResults from './countrySearchResults/mexico/MexicoResults';
import GermanyResults from './countrySearchResults/germany/GermanyResults';
import FranceResults from './countrySearchResults/france/FranceResults';
import ItalyResults from './countrySearchResults/italy/ItalyResults';
import SpainResults from './countrySearchResults/spain/SpainResults';
import WatchlistAMLResults from './countrySearchResults/watchlistAML/WatchlistAMLResults';

// UTILITIES
import { transUTL } from '../../utils/transUTL';
import clearUserDataUTL from '../../utils/clearUserDataUTL';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

const SearchVerification = ({
  authRXS,
  countryRXS,
  regionRXS,
  dataSourcesRXS,
  biometricsRXS,
  searchVerificationRXS,
  uploadBiometricScansAXN,
  history,
}) => {
  useEffect(() => {
    // ONCE 'isAuthenticated' CHANGES, THIS WILL FIRE SENDING THE USER TO THE DASHBOARD
    if (!isEmptyVAL(searchVerificationRXS)) {
      uploadBiometricScansAXN(
        // TODO: PLACE THIS AT AXIOS REQUEST (SEARCH VERIFICATIONS ACTIONS) TO AVOID RESUBMITTING ON PAGE REFRESH
        searchVerificationRXS.reportingReference,
        biometricsRXS,
        countryRXS
      );
    } else {
      history.push('/country-selection');
    }

    // eslint-disable-next-line
  }, []);

  let watchlistStatus = <span>{transUTL('translateReuse.na')}</span>;
  // KMS
  if (searchVerificationRXS.watchlistAML) {
    if (searchVerificationRXS.watchlistAML[0].verified) {
      watchlistStatus = (
        <span className="search-verification__error">
          {transUTL('translateSearchVerification.watchlistStatusFOUND')}
        </span>
      );
    } else {
      watchlistStatus = (
        <span className="search-verification__success">
          {transUTL('translateSearchVerification.watchlistStatusClear')}
        </span>
      );
    }
  }
  // DZ
  if (!isEmptyVAL(searchVerificationRXS.serviceResponses)) {
    if (!isEmptyVAL(searchVerificationRXS.serviceResponses['Watchlist AML'])) {
      const { identityVerified, returnedData } = searchVerificationRXS.serviceResponses['Watchlist AML'];
      if (identityVerified) {
        // C6 whitelist:true --> status: FOUND (WHITELIST)
        if (returnedData.watchlistResults
          && returnedData.watchlistResults.length > 0
          && returnedData.watchlistResults[0].whitelist) {
          watchlistStatus = (
            <span className="search-verification__error">
              {transUTL('translateSearchVerification.watchlistStatusFOUND2')}
            </span>
          );
        } else {
          watchlistStatus = (
            <span className="search-verification__error">
              {transUTL('translateSearchVerification.watchlistStatusFOUND')}
            </span>
          );
        }
      } else {
        watchlistStatus = (
          <span className="search-verification__success">
            {transUTL('translateSearchVerification.watchlistStatusClear')}
          </span>
        );
      }
    }
  }

  const handleClearResults = () => {
    clearUserDataUTL();
    history.push('/country-selection');
  };

  const handleCountryFormDisplay = () => {
    switch (countryRXS) {
      case 'australia':
        return (
          <AustraliaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'brazil':
        return (
          <BrazilResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'china':
        return (
          <ChinaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'india':
        return (
          <IndiaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'indonesia':
        return (
          <IndonesiaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'malaysia':
        return (
          <MalaysiaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'vietnam':
        return (
          <VietnamResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'philippines':
        return (
          <PhilippinesResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'cambodia':
        return (
          <CambodiaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'newzealand':
        return (
          <NewZealandResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'nigeria':
        return (
          <NigeriaResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'hongkong':
        return (
          <HonKongResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'singapore':
        return (
          <SingaporeResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'unitedstates':
        return (
          <UnitedStatesResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'mexico':
        return (
          <MexicoResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'germany':
        return (
          <GermanyResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'france':
        return (
          <FranceResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'italy':
        return (
          <ItalyResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      case 'spain':
        return (
          <SpainResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );

      // ADD NEW COUNTRIES RESULTS PAGE HERE

      case 'watchlistAML':
        return (
          <WatchlistAMLResults
            watchlistStatus={watchlistStatus}
            handleClearResults={handleClearResults}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="search-verification common-form">
      <header className="header-primary">
        <h2>{transUTL('translateSearchVerification.title')}</h2>
        <span></span>
      </header>

      <div className="common-form__body common-form__margin-bottom">
        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.reportingReference')}
            <span>{searchVerificationRXS.reportingReference}</span>
          </p>
          <p>
            {transUTL('translateSearchVerification.issueDateTime')}{' '}
            <span>{searchVerificationRXS.dateTime}</span>
          </p>
        </div>

        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.watchlistStatusText')}{' '}
            {watchlistStatus}
          </p>
          <p>
            {transUTL('translateSearchVerification.safeHarbour')}
            {searchVerificationRXS.safeHarbour ? (
              <span className="material-icons success">check_circle</span>
            ) : (
              <span className="material-icons error">cancel</span>
            )}
          </p>
        </div>
        <div className="search-verification__info">
          <p>
            {transUTL('translateSearchVerification.matchStatus')}
            <span>{searchVerificationRXS.matchStatus}</span>
          </p>
        </div>

        {handleCountryFormDisplay()}
      </div>
    </div>
  );
};

SearchVerification.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  regionRXS: PropTypes.string.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  searchVerificationRXS: PropTypes.object.isRequired,
  biometricsRXS: PropTypes.object.isRequired,
  uploadBiometricScansAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  regionRXS: state.regionRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  searchVerificationRXS: state.searchVerificationRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, { uploadBiometricScansAXN })(
  SearchVerification
);
