import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { geocodeByAddress } from "react-places-autocomplete";

// REDUX ACTIONS
import { saveFormDataAXN } from "../../../../actions/formData/formDataActions";
import { searchVerificationAXN } from "../../../../actions/searchVerification/searchVerificationAction";
import { setAlertAXN } from "../../../../actions/alert/alertActions";
import {
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
} from "../../../../actions/biometrics/biometricActions";
import { saveUserInputAXN } from "../../../../actions/formData/userInputActions";
import { saveApiReqAXN } from "../../../../actions/formData/apiReqActions";

// HELPERS
import initialStateNZ from "./helpers/initialStateNZ";
import consentCheckerNZ from "./helpers/consentCheckerNZ";
import whitelistedInputDataNZ from "./helpers/whitelistedInputDataNZ";
import removeValidationMessage from "../../helpersForForms/removeValidationMessage";

// VALIDATIONS
import newzealandVAL from "../../../../validations/inputFields/newzealandVAL";

// FORM CONTROLLER
import FormController from "../../../../components/formController/FormController";
import DisplayApiController from "../../../../components/formController/DisplayApiController";

// FORM INPUTS
import PersonalInformationNewZealand from "./inputForms/PersonalInformationNewZealand";
import PassportNewZealand from "./inputForms/PassportNewZealand";
import DriversLicenceNewZealand from "./inputForms/DriversLicenceNewZealand";
import CurrentResidentialAddressNewZealand from "./inputForms/CurrentResidentialAddressNewZealand";
import BirthCertificateNewZealand from "./inputForms/BirthCertificateNewZealand";
import CitizenshipNewZealand from "./inputForms/CitizenshipNewZealand";
import ContactDetailsNewZealand from "./inputForms/ContactDetailsNewZealand";
import TermsAndConditionsNewZealand from "./inputForms/TermsAndConditionsNewZealand";
import ClientReference from "../../reusableForms/ClientReference";
import ApiResult from "../../reusableForms/ApiResult";

// BUTTON
import Button from "../../../../components/btn/Button";

// VALIDATIONS
import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

// UTILITIES
import {
  dataSourcesIsCheckedUTL,
  outputMasterUTL,
} from "../../../../utils/outputMasterUTL";
import { transUTL } from "../../../../utils/transUTL";
import formatDateUTL from "../../../../utils/formatDateUTL";
import loggerUTL from "../../../../utils/loggerUTL";
import filterServicesUTL from "../../../../utils/filterServicesUTL";
import jsonBuilder from "../../../../utils/jsonBuilder";
import rmSpaceUTL from "../../../../utils/rmSpaceUTL";
import noSpaceUTL from "../../../../utils/noSpaceUTL";
import downsizeUploadedImagesUTL from "../../../../utils/downsizeUploadedImagesUTL";
import isScannedDataEditedUTL from "../../../../utils/isScannedDataEditedUTL";

const NewZealandForms = ({
  authRXS,
  countryRXS,
  errorsRXS,
  dataSourcesRXS,
  formDataRXS,
  biometricsRXS,
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  bioTriggerBooleansAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
  history,
}) => {
  const [userInputSTH, setUserInputSTH] = useState(initialStateNZ);
  const selectedDataSources = dataSourcesIsCheckedUTL(
    dataSourcesRXS.newzealandDataSources
  );

  // LOAD FORM DATA
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.newzealandFormData,

      errors: {}, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, []);

  // PROVIDE USER FEEDBACK ERRORS TODO: REFACTOR THIS - CREATE NEW STATE PURELY FOR ERRORS
  useEffect(() => {
    setUserInputSTH({
      ...userInputSTH,
      ...formDataRXS.newzealandFormData,
      errors: { ...errorsRXS }, // RESET ERRORS
    });
    // eslint-disable-next-line
  }, [errorsRXS]);

  // HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    loggerUTL("VALIDATING...");
    const inputData = { ...userInputSTH };

    // VALIDATE INPUT
    const { inputErrors, isValid } = newzealandVAL(
      inputData,
      selectedDataSources
    );

    // ERROR MSG --- "Please fill in the forms correctly"
    if (!isValid) {
      loggerUTL("ERRORS...", inputErrors);
      setUserInputSTH({ ...userInputSTH, errors: inputErrors });
      return setAlertAXN(transUTL("translateAlertMsg.inputErrors"), "error");
    }

    // CONSENT CHECKER
    const { isConsent, consentObtained } = consentCheckerNZ(
      selectedDataSources,
      inputData
    );

    // ERROR MSG --- "You must agree to the terms and conditions"
    if (!isConsent) {
      return setAlertAXN(transUTL("translateAlertMsg.consent"), "error");
    }

    // REMOVE ANY SPACES
    if (!isEmptyVAL(userInputSTH.phoneNo))
      inputData.email = noSpaceUTL(inputData.phoneNo);

    if (!isEmptyVAL(userInputSTH.landlineNo))
      inputData.landlineNo = noSpaceUTL(inputData.landlineNo);

    // WHITELIST DATA
    const whitelisted = whitelistedInputDataNZ(inputData, selectedDataSources);

    // ADDRESS MANIPULATION
    let isRequiredStreetNoAndName = false; //addressElement 1 and 2 are not combined as a default
    const combinedStreetNoAndName = [
      "New Zealand LINZ Property",
      "New Zealand Companies Office",
      "New Zealand Residential",
    ]; //These 3 servises are required to combine addressElement1 and 2
    for (let i = 0; i < selectedDataSources.length; i++) {
      if (combinedStreetNoAndName.includes(selectedDataSources[i])) {
        isRequiredStreetNoAndName = true;
        break;
      }
    }

    const addressElement1 = isRequiredStreetNoAndName
      ? inputData.streetNo + " " + inputData.streetName
      : inputData.streetNo;
    const addressElement2 = isRequiredStreetNoAndName
      ? ""
      : inputData.streetName;
    const addressElement3 = inputData.suburb;
    const addressElement4 = inputData.city;
    const addressElement5 = inputData.postCode;

    // FILTER COUNTRY
    const country = filterServicesUTL(countryRXS, authRXS.user.countries);
    const service = [...selectedDataSources];

    // JSON BUILDER
    const submitData = jsonBuilder({
      countryCode: country.code.alpha2,
      service,
      consentObtained,
      addressElement1,
      addressElement2,
      addressElement3,
      addressElement4,
      addressElement5,
      ...whitelisted,
    });

    loggerUTL(JSON.stringify(submitData, undefined, 4));

    saveApiReqAXN(submitData, countryRXS);
    searchVerificationAXN(submitData, history);
    saveUserInputAXN(inputData.displayApiResultObtained, countryRXS);
  };

  // HANDLE ON CHANGE
  const handleOnChange = (event) =>
    setUserInputSTH({
      ...userInputSTH,
      [event.target.name]: event.target.value,
    });

  // HANDEL ON BLUR
  const handleOnBlur = (e) => {
    loggerUTL("ON BLUR...");
    const {
      errors,
      diaPassportConsentObtained,
      driverLicenceConsentObtained,
      diaBirthConsentObtained,
      linzPropertyConsentObtained,
      diaCitizenshipConsentObtained,
      creditBureauConsentObtained,
      displayApiResultObtained,
      isGoogleParsed,
      isGoogle,
      addressUnparsed,
      ...filteredFormData
    } = userInputSTH;
    // Clear validation message for mandatory field once user fill in that field and switch to another field
    setUserInputSTH(removeValidationMessage(e, userInputSTH));
    // Check if scanned data from a doc is edited
    const { ocrResponseData } = biometricsRXS;
    isScannedDataEditedUTL(countryRXS, ocrResponseData, filteredFormData);

    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // HANDLE ENTER KEY TRIGGER
  const handleEnterKeyTrigger = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      loggerUTL("ON BLUR ENTER KEY TRIGGER");
      handleOnBlur();
    }
  };

  // HANDLE CLEAR INPUT DATA
  const handleReset = () => {
    loggerUTL("CLEARING STATE...");
    const {
      errors,
      isGoogleParsed,
      isGoogle,
      addressUnparsed,
      displayApiResultObtained,
      ...reset
    } = initialStateNZ;
    setUserInputSTH(initialStateNZ);
    saveFormDataAXN(reset, countryRXS);

    // OCR RESET
    resetBiometricsAXN();
  };

  // HANDEL TOGGLE CHECKBOX
  const handleToggleCheckbox = (opt) => {
    const {
      diaPassportConsentObtained,
      driverLicenceConsentObtained,
      diaBirthConsentObtained,
      diaCitizenshipConsentObtained,
      creditBureauConsentObtained,
    } = userInputSTH;

    let passportConsent = false,
      driverLicenceConsent = false,
      diaCitizenshipConsent = false,
      diaBirthConsent = false,
      linzPropertyConsent = false,
      creditBureauConsent = false;

    if (selectedDataSources.includes("New Zealand DIA Passport")) {
      passportConsent = !diaPassportConsentObtained;
    }
    if (selectedDataSources.includes("New Zealand DIA Citizenship")) {
      diaCitizenshipConsent = !diaCitizenshipConsentObtained;
    }
    if (selectedDataSources.includes("New Zealand Driver Licence")) {
      driverLicenceConsent = !driverLicenceConsentObtained;
    }
    if (selectedDataSources.includes("New Zealand DIA Birth")) {
      diaBirthConsent = !diaBirthConsentObtained;
    }
    if (selectedDataSources.includes("New Zealand Credit Bureau")) {
      creditBureauConsent = !creditBureauConsentObtained;
    }

    setUserInputSTH({
      ...userInputSTH,
      diaCitizenshipConsentObtained: diaCitizenshipConsent,
      diaPassportConsentObtained: passportConsent,
      driverLicenceConsentObtained: driverLicenceConsent,
      diaBirthConsentObtained: diaBirthConsent,
      linzPropertyConsentObtained: linzPropertyConsent,
      creditBureauConsentObtained: creditBureauConsent,
    });
  };

  const handleToggleTickbox = (opt) => {
    loggerUTL("TICKBOX...");
    const { displayApiResultObtained } = userInputSTH;

    let displayApiResult = false;

    displayApiResult = !displayApiResultObtained;

    setUserInputSTH({
      ...userInputSTH,
      displayApiResultObtained: displayApiResult,
    });
  };

  // MANUAL ADDRESS INPUT
  const manualAddressEntryToggle = () => {
    setUserInputSTH({
      ...userInputSTH,
      isGoogle: !userInputSTH.isGoogle,
    });

    const {
      errors,
      birthCertificateConsentObtained,
      centerlinkCardConsentObtained,
      driverLicenceConsentObtained,
      medicareCardConsentObtained,
      passportConsentObtained,
      visaConsentObtained,
      residentialLookupConsentObtained,
      visaEntitlementConsentObtained,
      creditBureauConsentObtained,
      medicareExpiryDateToggle,
      manualAddressInput,
      displayApiResultObtained,
      addressUnparsed,
      isGoogle,
      isGoogleParsed,
      ...filteredFormData
    } = userInputSTH;
    saveFormDataAXN(filteredFormData, countryRXS);
  };

  // GOOGLE AUTO COMPLETE
  const handleGoggleOnChange = (addressUnparsed) => {
    setUserInputSTH({
      ...userInputSTH,
      addressUnparsed,
      isGoogleParsed: false,
    });
  };

  // HANDLE GOOGLE SELECT
  const handleGoogleSelect = (addressUnparsed, selectedWithoutSelect) => {
    geocodeByAddress(addressUnparsed)
      .then((results) => {
        const addressComponents = {
          unitNo: "",
          streetNo: "",
          streetName: "",
          suburb: "",
          city: "",
          postCode: "",
        };

        results[0].address_components.map((addressItem) => {
          if (addressItem.types.includes("subpremise")) {
            addressComponents.unitNo = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (addressItem.types.includes("street_number")) {
            addressComponents.streetNo = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (addressItem.types.includes("route")) {
            addressComponents.streetName = addressItem.long_name || "";
          } else if (addressItem.types.includes("sublocality")) {
            addressComponents.suburb = addressItem.long_name
              ? addressItem.long_name
              : "";
          } else if (addressItem.types.includes("locality")) {
            addressComponents.city = addressItem.short_name
              ? addressItem.short_name
              : "";
          } else if (addressItem.types.includes("postal_code")) {
            addressComponents.postCode = addressItem.long_name
              ? addressItem.long_name
              : "";
          }

          return null;
        });

        const newAddress = selectedWithoutSelect
          ? addressUnparsed
          : results[0].formatted_address;

        setUserInputSTH({
          ...userInputSTH,
          isGoogle: true, // update to true in order to show input elements
          isGoogleParsed: true,
          addressUnparsed: newAddress,
          ...addressComponents,
        });
      })
      .then((latLng) => {
        loggerUTL("SUCCESS...", latLng);
      })
      .catch((error) => loggerUTL("ERROR...", error));
  };

  // OCR IMAGE UPLOADS
  const handleOcrFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const targetName = event.target.name;
      const fileName = event.target.files[0].name || "identityDocument";
      const file = event.target.files[0];
      let fileType = "";

      // CONVERT FILE TO DATA URI
      const reader = new FileReader();
      const img = new Image();

      reader.readAsDataURL(file);

      reader.onload = (innerEvent) => {
        const { result } = innerEvent.target; // DATA URI
        let downsizeUploadedDocument;
        let downsizeUploadedSelfie;

        if (targetName === "documentIMG") {
          fileType = "file1"; // DOUCMENT --- ID CARD
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedDocument = downsizeUploadedImagesUTL(
              reader.result,
              width,
              height
            ); //Downsize a uploaded document image
            saveIdentityDocumentsAXN([downsizeUploadedDocument]);
            bioTriggerBooleansAXN({
              ...biometricsRXS.bioTriggers,
              isDocScanned: false,
              isDocUploaded: true,
            });
          };
        } else if (targetName === "selfieIMG") {
          fileType = "file2"; // SELFIE
          img.src = reader.result;
          img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            downsizeUploadedSelfie = downsizeUploadedImagesUTL(
              reader.result,
              width,
              height
            ); //Downsize a uploaded selfie
            saveSelfieAXN(downsizeUploadedSelfie);
            bioTriggerBooleansAXN({
              ...biometricsRXS.bioTriggers,
              isBioAuth: false,
              isSelfieUploaded: true,
            });
          };
        }

        setUserInputSTH({
          ...userInputSTH,
          fileNames: {
            ...userInputSTH.fileNames,
            [fileType]: fileName,
          },
        });
      };
    }
  };

  // CONDITIONAL DATA SOURCES
  const conditionalClientRef = [];
  if (
    selectedDataSources.includes("New Zealand DIA Passport") ||
    selectedDataSources.includes("New Zealand Driver Licence") ||
    selectedDataSources.includes("New Zealand Company Verification") ||
    selectedDataSources.includes("New Zealand DIA Birth") ||
    selectedDataSources.includes("New Zealand DIA Citizenship") ||
    selectedDataSources.includes("New Zealand Credit Bureau")
  ) {
  } else {
    conditionalClientRef.push(
      ...[
        "Watchlist AML",
        "New Zealand Companies Office",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand LINZ Property",
      ]
    );
  }

  // INPUT ELEMENTS
  const inputElements = [
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <FormController
          key="personalInformation"
          title={transUTL(
            "translateFormOverview.formHeaders.personalInformation"
          )}
          inputForm={
            <PersonalInformationNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleOcrFileUpload={handleOcrFileUpload}
              handleOcrImage={biometricsRXS.bioTriggers}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
      ],
      element: (
        <FormController
          key="currentResidentialAddress"
          title={transUTL(
            "translateFormOverview.formHeaders.currentResidentialAddress"
          )}
          inputForm={
            <CurrentResidentialAddressNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              manualAddressEntryToggle={manualAddressEntryToggle}
              handleGoggleOnChange={handleGoggleOnChange}
              handleGoogleSelect={handleGoogleSelect}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["DIA-Birth"],
      element: (
        <FormController
          key="birthCertificate"
          title={transUTL("translateFormOverview.formHeaders.birthCertificate")}
          inputForm={
            <BirthCertificateNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["DIA-Citizenship"],
      element: (
        <FormController
          key="citizenship"
          title={transUTL("translateFormOverview.formHeaders.citizenship")}
          inputForm={
            <CitizenshipNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["New Zealand Driver Licence"],
      element: (
        <FormController
          key="driversLicence"
          title={transUTL("translateFormOverview.formHeaders.driversLicence")}
          inputForm={
            <DriversLicenceNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: ["New Zealand DIA Passport"],
      element: (
        <FormController
          key="passport"
          title={transUTL("translateFormOverview.formHeaders.passport")}
          inputForm={
            <PassportNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand Companies Office",
        "New Zealand Residential",
        "New Zealand LINZ Property",
      ],
      element: (
        <FormController
          key="contactDetails"
          title={transUTL("translateFormOverview.formHeaders.contactDetails")}
          inputForm={
            <ContactDetailsNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand Credit Bureau",
      ],
      element: (
        <FormController
          key="termsAndConditions"
          title={transUTL(
            "translateFormOverview.formHeaders.termsAndConditions"
          )}
          inputForm={
            <TermsAndConditionsNewZealand
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          }
        />
      ),
    },
    {
      belongsTo: conditionalClientRef,
      element: (
        <FormController
          key="clientRef"
          title={transUTL("translateFormOverview.formHeaders.clientRef")}
          inputForm={
            <ClientReference
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
            />
          }
        />
      ),
    },
    {
      belongsTo: [
        "New Zealand DIA Passport",
        "New Zealand Driver Licence",
        "New Zealand Companies Office",
        "New Zealand DIA Birth",
        "New Zealand DIA Citizenship",
        "New Zealand LINZ Property",
        "New Zealand Yellow Pages",
        "New Zealand Residential",
        "New Zealand Credit Bureau",
        "Watchlist AML",
      ],
      element: (
        <DisplayApiController
          key="displayApi"
          inputForm={
            <ApiResult
              formState={userInputSTH}
              selectedDataSources={selectedDataSources}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              handleEnterKeyTrigger={handleEnterKeyTrigger}
              handleToggleTickbox={handleToggleTickbox}
            />
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      {/* INPUT FORMS */}
      {outputMasterUTL(selectedDataSources, inputElements)}

      {/* CLEAR BUTTON */}
      <button onClick={handleReset} className="btn-secondary">
        {transUTL("translateFormOverview.clear")}
      </button>

      {/* BUTTON */}
      <Button
        myClass={"btn-under"}
        history={history}
        to="/data-source-selection"
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL("translateBtn.previousPage")}
        btn2Text={transUTL("translateBtn.submit")}
        additionalClass="form-overview__form-btn"
      />
    </Fragment>
  );
};

NewZealandForms.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
  errorsRXS: PropTypes.object.isRequired,
  dataSourcesRXS: PropTypes.object.isRequired,
  formDataRXS: PropTypes.object.isRequired,
  userInputRXS: PropTypes.object.isRequired,
  saveUserInputAXN: PropTypes.func.isRequired,
  saveApiReqAXN: PropTypes.func.isRequired,
  saveFormDataAXN: PropTypes.func.isRequired,
  bioTriggerBooleansAXN: PropTypes.func.isRequired,
  searchVerificationAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
  errorsRXS: state.errorsRXS,
  dataSourcesRXS: state.dataSourcesRXS,
  formDataRXS: state.formDataRXS,
  userInputRXS: state.userInputRXS,
  apiReqRXS: state.apiReqRXS,
  biometricsRXS: state.biometricsRXS,
});

export default connect(mapStateToProps, {
  saveFormDataAXN,
  saveUserInputAXN,
  saveApiReqAXN,
  searchVerificationAXN,
  setAlertAXN,
  saveIdentityDocumentsAXN,
  bioTriggerBooleansAXN,
  saveSelfieAXN,
  resetBiometricsAXN,
})(withRouter(NewZealandForms));