const translateBtn = {
  previousPage: 'Halaman sebelumnya',
  nextPage: 'Muka surat seterusnya',
  submit: 'Hantar',
  printSearchResults: 'Cetak Hasil Carian',
  newSearch: 'Pencarian baharu',
  generatePDF: 'Hasilkan PDF',
  editSearch: 'Edit Carian',
};

export default translateBtn;
