const dataSourcesPH = {
  residential: {
    title: 'Philippines Residential',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    streetName: 'Street Name',
    province: 'Province',
    city: 'City',
    gender: 'Gender',
    barangay: 'Barangay',
    postcode: 'Post Code',
    address: 'Address',
  },
  creditBureau: {
    title: 'Philippines Credit Bureau',
    searchSuccessful: 'Search Successful',
    identityVerified: 'Identity Verified',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    nationalIDNo: "National ID number",
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    streetName: 'Street Name',
    province: 'Province',
    city: 'City',
    barangay: 'Barangay',
    postcode: 'Post Code',
    requestTimedOut: 'Request Timed Out',
  },
  suspiciousActivity: {
    // 'FIND (ID)U RESULTS',
    title: 'PHILIPPINES SUSPICIOUS ACTIVITY',
    FirstName: 'First Name',
    MiddleName: 'Middle Name',
    LastName: 'Last Name',
    Gender: 'Gender',
    URN: 'URN',
    VoterID: 'Voter ID',
    DOB: 'DOB',
    DayDOB: 'Day',
    MonthDOB: 'Month',
    YearDOB: "Year",
    MaritalStatus: 'Marital Status',
    Street: 'Street',
    Barangay: 'Barangay',
    Region: 'Postcode',
    City: 'City',
    Province: 'Province',
    MotherLastName: 'Mother Last Name',
    requestTimedOut: 'Request Timed Out',
    searchSuccessful: 'Search Successful',
  },
  watchlistAml: {
    title: 'PEP & SANCTION RESULTS',
    additionalInfoURL: 'URL',
    category: 'Category',
    identityVerified: 'Identity Verified',
    deathIndex: 'Death Index',
    gender: 'Gender',
    otherNames: 'Other Names',
    scanId: "Scan ID",
    whitelist: 'Whitelist',
    yearOfBirth: 'Year of Birth',
    requestTimedOut: 'Request Timed Out',
    searchSuccessful: 'Search Successful',
  },
  digitalIDSafeguard: {
    title: 'Digital ID Safeguard Results',
    searchSuccessful: 'Search Successful',
    isSafe: 'isSafe',
    safeHarbourScore: 'Safe Harbour Score',
    nameMatchScore: 'Name Match Score',
    addressMatchScore: 'Address Match Score',
    nationalIDNo: "National ID number",
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    streetName: 'Street Name',
    province: 'Province',
    city: 'City',
    barangay: 'Barangay',
    postcode: 'Post Code',
    requestTimedOut: 'Request Timed Out',
  }
};

export default dataSourcesPH;
