import store from '../store/store';

// REDUX ACTIONS
import { resetSelectedCountryAXN } from '../actions/country/countryActions';
import { resetDataSourcesAXN } from '../actions/dataSources/dataSourcesActions';
import { resetFormDataAXN } from '../actions/formData/formDataActions';
import { resetSelectedRegionAXN } from '../actions/region/regionAction';
import { resetSearchVerificationResultsAXN } from '../actions/searchVerification/searchVerificationAction';
import { resetBiometricsAXN } from '../actions/biometrics/biometricActions';

const clearUserDataUTL = () => {
  if (
    window.location.pathname === '/search-verification' ||
    window.location.pathname === '/multi-search-verification'
  ) {
    // CLEAR AUSTRALIA DATA
    sessionStorage.removeItem('australiaDataSources');
    sessionStorage.removeItem('australiaFormData');

    sessionStorage.removeItem('brazilDataSources');
    sessionStorage.removeItem('brazilFormData');

    // CLEAR CHINA DATA
    sessionStorage.removeItem('chinaDataSources');
    sessionStorage.removeItem('chinaFormData');
    sessionStorage.removeItem('ocrImageData');
    sessionStorage.removeItem('facialRecognitionData');
    sessionStorage.removeItem('selfieImageData');
    sessionStorage.removeItem('selfieURI');
    sessionStorage.removeItem('identityDocumentScans');
    sessionStorage.removeItem('pdfScanDataEdited');

    // CLEAR MALAYSIA DATA DATA
    sessionStorage.removeItem('malaysiaDataSources');
    sessionStorage.removeItem('malaysiaFormData');

    // CLEAR NEW ZEALAND DATA
    sessionStorage.removeItem('newzealandDataSources');
    sessionStorage.removeItem('newzealandFormData');

    // CLEAR PHILIPPINES DATA
    sessionStorage.removeItem('philippinesDataSources');
    sessionStorage.removeItem('philippinesFormData');

    // CLEAR INDIA DATA
    sessionStorage.removeItem('indiaDataSources');
    sessionStorage.removeItem('indiaFormData');

    // CLEAR INDONESIA DATA
    sessionStorage.removeItem('indonesiaDataSources');
    sessionStorage.removeItem('indonesiaFormData');

    // CLEAR CAMBODIA DATA
    sessionStorage.removeItem('cambodiaDataSources');
    sessionStorage.removeItem('cambodiaFormData');

    // CLEAR VIETNAM DATA
    sessionStorage.removeItem('vietnamDataSources');
    sessionStorage.removeItem('vietnamFormData');

    // CLEAR GERMANY DATA
    sessionStorage.removeItem('germanyDataSources');
    sessionStorage.removeItem('germanyFormData');

    // CLEAR SEARCH VERIFICATION DATA
    sessionStorage.removeItem('searchVerification');

    // CLEAR SELECTED COUNTRY
    sessionStorage.removeItem('selectedCountry');
    sessionStorage.removeItem('selectedRegion');

    // CLEAR WATCHLIST AML
    sessionStorage.removeItem('watchlistAMLFormData');

    // CLEAR RESET TO INITIAL STATE
    store.dispatch(resetSelectedCountryAXN());
    store.dispatch(resetSelectedRegionAXN());
    store.dispatch(resetDataSourcesAXN());
    store.dispatch(resetFormDataAXN());
    store.dispatch(resetSearchVerificationResultsAXN());
    store.dispatch(resetBiometricsAXN());
  }
};

export default clearUserDataUTL;
