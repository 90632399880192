import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Child Components
import Camera from '../camera/Camera';
import DocumentPreview from './common/DocumentPreview';
import { transUTL } from '../../../utils/transUTL';

const selectionStyle = {
  cursor: 'pointer'
};

const selectedStyle = {
  color: '#59c16f'
};

const CameraUpload = ({
  uploadedDocuments,
  onUpload,
  clearDocument
}) => {

  const [frontSideSelected, toggleFrontSideSelected] = useState(true);

  // Need to have this as the camera component requires it.
  const handleInitialize = () => true;

  const hanldeOnCapture = (dataURI) => {
    const side = frontSideSelected ? 'front' : 'back';
    const document = {
      name: 'Document - ' + side + 'side',
      size: 0,
      dataURI: dataURI 
    }

    onUpload(document, side);
  }

  // Need to have this as the camera component requires it.
  // It does absolutely nothing :) 
  const handleOnClear = () => null;

  return (
    <div>
      <h2 style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
        <span
          style={frontSideSelected ?
            { ...selectionStyle, ...selectedStyle }
            :
            selectionStyle
          }
          onClick={() => toggleFrontSideSelected(!frontSideSelected)}
        >
          {transUTL('translateBiometrics.frontSide')}
        </span>
        <span> | </span>
        <span
          style={!frontSideSelected ?
            { ...selectionStyle, ...selectedStyle }
            :
            selectionStyle
          }
          onClick={() => toggleFrontSideSelected(!frontSideSelected)}
        >
          {transUTL('translateBiometrics.backSide')}
        </span>
      </h2>
      <Camera
        handleInitialize={handleInitialize}
        onCapture={hanldeOnCapture}
        onClear={handleOnClear}
      />

      {(uploadedDocuments.front || uploadedDocuments.back) &&
        <div className="ocr__camera-preview-container">
          <h2 style={{ fontWeight: 'bold' }}>
          {transUTL('translateBiometrics.uploadedDocuments')}
          </h2>
          <div className="ocr__camera-preview-container__previews">
          {uploadedDocuments.front &&
            <div className="ocr__camera-preview-container__previews__preview">
            <h3>{transUTL('translateBiometrics.frontSide')}</h3>
            <DocumentPreview
              image={uploadedDocuments.front.dataURI}
              altTag={uploadedDocuments.front.name}
              onClear={() => clearDocument('front')}
            />
            </div>
          }
          {uploadedDocuments.back &&
            <div className="ocr__camera-preview-container__previews__preview">
            <h3>{transUTL('translateBiometrics.backSide')}</h3>
            <DocumentPreview
              image={uploadedDocuments.back.dataURI}
              altTag={uploadedDocuments.back.name}
              onClear={() => clearDocument('back')}
            />
            </div>
          }
          </div>
        </div>
      }
    </div>
  );
}

CameraUpload.propType = {
  uploadedDocuments: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
  clearDocument: PropTypes.func.isRequired
};

export default CameraUpload;