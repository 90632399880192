import React from 'react';
import PropTypes from 'prop-types';
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

const Tile = ({ title, ui, to, myClass, history }) => (
  <div
    className={`dashboard__tile card ${myClass}`}
    onClick={() => {
      if (!isEmptyVAL(to)) history.push(to);
    }}
  >
    <p className="dashboard__tile-title">{title}</p>
    <div className="dashboard__tile-content">{ui}</div>
  </div>
);

Tile.defaultProps = {
  myClass: '',
};

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  ui: PropTypes.object.isRequired,
  myClass: PropTypes.string,
  history: PropTypes.object,
};

export default Tile;
