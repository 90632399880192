import formErrors from './formErrors/formErrors';
import formHeaders from './formHeaders/formHeaders';
import inputLabels from './inputLabels/inputLabels';

const translateFormOverview = {
  consentParagraph:
    '我确认我已被授权提供所提供的个人详细信息，并同意我通过第三方系统与文件发行人或办公室记录持有人核对我的信息，以确认我的身份。',
  consentParagraphAadhaar: 
    '我授权 Data Zoo 访问我的 Aadhaar 号码并帮助我获取我的详细信息。我了解 Data Zoo 不会以任何方式存储或共享相同的内容。',
  enterInfo: '输入所需的信息：',
  selectedServices: '精选服务',
  scanAndOCR: '扫描和OCR',
  consentParagraph2: '我同意这条款和条件',
  chinaCrime: '警察检查？',
  digitalIDSafeguard: 'Digital ID Safeguard™',
  digitalIDSafeguardMessage:
    '在此验证请求中，将Digital ID Safeguard™用作附加服务',
  previousPage: '上一页',
  submit: '提交',
  cantLocateAddress: "找不到您想要的东西？",
  reportingReference: '客户（您的）参考',
  cantFindAddress: "找不到您想要的东西？",
  manualAddress: '手动输入地址',
  useAddressAutocomplete: '使用地址自动完成',
  changeCountrySelected: '更改所选国家',
  changeDataSourceSelected: '更改选定的数据源',
  watchlistAML: 'PEP和制裁|',
  notApplicable: '不适用',
  updateAddress: '更新地址',
  clear: '清空输入',
  indiaFullNameField: '必须输入与身份证件上显示的完全相同的名称',
  document: '文献',
  australiaDriverLicenseCardNo: '卡号。如果驾驶执照显示卡号，则必须输入',
  scan: '扫描',
  biometric: '生物识别',
  authentication: '认证方式',
  liveness: '活泼',
  detection: '检测',
  authenticity: '真实性',
  consentParagraphAus:
    '我同意将我的详细信息披露给信用报告机构或任何经批准的第三方，以验证我的详细信息，并且为此，我还授权第三方作为我的中间人，根据《隐私法》访问信息由任何其他第三方持有。',
  // FORM ERRORS
  formErrors,
  // FORM HEADERS
  formHeaders,
  // INPUT LABELS
  inputLabels,
  isDotInFullName: '护照中名字和姓氏之间有“·”, 如买买提·吐尔逊',
    //FIND IDU
  includeWatchList: '包括PEP和制裁搜索?',
  includeSafeguardSearch: '包括数字身份证保护搜索？',
  apiResult : "显示 API 请求和响应",
};

export default translateFormOverview;
