import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// IDPUT
import TextFieldGroup from '../../../../../components/common/textFieldGroup/TextFieldGroup';

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { outputMasterUTL } from '../../../../../utils/outputMasterUTL';
import { transUTL } from '../../../../../utils/transUTL';

const CurrentResidentialAddressFrance = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
}) => {
  const {
    streetAddress,
    city,
    state,
    postCode,
    errors,
  } = formState;

  const inputElements = [
    {
      belongsTo: ['France Residential', 'France Residential 2', 'France Residential 3'],
      element: (
        <TextFieldGroup
          key="streetAddress"
          id="streetAddress"
          type="text"
          name="streetAddress"
          dataName="streetAddressFR"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.street')}
          value={streetAddress}
          placeholder={transUTL('translatePlaceholder.inputFR.streetAddress')}
          placeholderTrigger={isEmptyVAL(streetAddress) ? 'input-empty' : ''}
          error={errors.streetAddress}
          errorTrigger={errors.streetAddress ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['France Residential', 'France Residential 2', 'France Residential 3'],
      element: (
        <TextFieldGroup
          key="city"
          id="city"
          type="text"
          name="city"
          dataName="cityFR"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.city')}
          value={city}
          placeholder={transUTL('translatePlaceholder.inputFR.city')}
          placeholderTrigger={isEmptyVAL(city) ? 'input-empty' : ''}
          error={errors.city}
          errorTrigger={errors.city ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
    {
      belongsTo: ['France Residential 2'],
      element: (
        <TextFieldGroup
          key="state"
          id="state"
          type="text"
          name="state"
          dataName="stateFR"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.state')}
          value={state}
          placeholder={transUTL('translatePlaceholder.inputFR.state')}
          placeholderTrigger={isEmptyVAL(state) ? '' : ''}
          error={errors.state}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      )
    },
    {
      belongsTo: ['France Residential', 'France Residential 2', 'France Residential 3'],
      element: (
        <TextFieldGroup
          key="postCode"
          id="postCode"
          type="text"
          name="postCode"
          dataName="postCodeFR"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.postCode')}
          value={postCode}
          placeholder={transUTL('translatePlaceholder.inputFR.postCode')}
          placeholderTrigger={isEmptyVAL(postCode) ? 'input-empty' : ''}
          error={errors.postCode}
          errorTrigger={errors.postCode ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      <Fragment>
        {outputMasterUTL(selectedDataSources, inputElements)}
        <span />
      </Fragment>
    </div>
  );
};

CurrentResidentialAddressFrance.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default CurrentResidentialAddressFrance;
