import store from '../../../../store/store';
import {
  saveSelectedDateAXN,
  saveSelectedClientAXN,
  setDataAnalytics,
} from '../../../../actions/usageReport/usageReportAction';

// UTILITIES
import loggerUTL from '../../../../utils/loggerUTL';

const usageReportSessionDataRestore = () => {
  loggerUTL('USAGE REPORT SESSION DATA RESTORE...');
  if (localStorage.daUsageReport) {
    store.dispatch(setDataAnalytics(JSON.parse(localStorage.daUsageReport)));
  }
  if (sessionStorage.selectedDate) {
    store.dispatch(
      saveSelectedDateAXN(JSON.parse(sessionStorage.selectedDate))
    );
  }

  if (sessionStorage.selectedClient) {
    store.dispatch(
      saveSelectedClientAXN(JSON.parse(sessionStorage.selectedClient))
    );
  }
};

export default usageReportSessionDataRestore;
