import * as Sentry from '@sentry/browser';

import { saveFormDataAXN } from '../../formData/formDataActions';
import { setAlertAXN } from '../../alert/alertActions';
import { saveOcrResponseDataAXN } from "../biometricActions";

import scanDataEditedTrigger from '../helpers/scanDataEditedTrigger';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import loggerUTL from '../../../utils/loggerUTL';
import splitFullName from '../helpers/splitFullName';

const inputMappingSG = (data, dispatch) => {
  try {
    //INPUT MAPPING FIELDS
    let firstName = '',
      middleName = '',
      lastName = '',
      dateOfBirth = '',
      gender = '',
      nationalIDNo = '';

    // Name Mapping Logic
    if (!isEmptyVAL(data.firstName)) {
      firstName = data.firstName;
    }
    if (!isEmptyVAL(data.lastName)) {
      lastName = data.lastName;
    }

    if (
      isEmptyVAL(data.firstName) ||
      isEmptyVAL(data.lastName) &&
      !isEmptyVAL(data.fullName)
    ) {
      let { FirstName, MiddleName, LastName } = splitFullName(data.fullName);
      firstName = FirstName;
      middleName = MiddleName;
      lastName = LastName;
    }

    //DOB Mapping Logic
    let day = isEmptyVAL(data.dateOfBirth.day) ? 0 : data.dateOfBirth.day;
    day = day < 10 ? `0${day}` : day;
    let month = isEmptyVAL(data.dateOfBirth.month) ? 0 : data.dateOfBirth.month;
    month = month < 10 ? `0${month}` : month;
    let year = isEmptyVAL(data.dateOfBirth.year) ? 0 : data.dateOfBirth.year;
    dateOfBirth = `${year}-${month}-${day}`;

    //Gender Mapping Logic
    if (!isEmptyVAL(data.sex)) {
      const sex = data.sex.toLowerCase();
      if (sex === 'f' || sex === 'female') {
        gender = 'Female';
      }
      if (sex === 'm' || sex === 'male') {
        gender = 'Male';
      }
    }

    //NationalID Mapping Logic
    //With passports we need to extract the nationalIDNo from the machine read data.
    //*** NOTE: Current SG OCR api response doesn't contain mrzData and documentType
    if (!isEmptyVAL(data.mrzData) && !isEmptyVAL(data.mrzData.documentType)) {
      if (data.mrzData.documentType.toLowerCase() === 'passport') {
        nationalIDNo = data.mrzData.opt1;
        // Remove the arrow characters found on passports
        nationalIDNo = nationalIDNo.replaceAll('<', '');
        nationalIDNo = nationalIDNo.replaceAll('>', '');
      } else if (!isEmptyVAL(data.documentNumber)) {
        nationalIDNo = data.documentNumber;
      }
    }

    loggerUTL('Triggering inisializing scanDataEdited...')
    scanDataEditedTrigger('singapore', false);
    // To store the data(only add in the fields that are showing in FORM page) from Doc scan response
    let ocrResponseData = {
      firstName,
      lastName,
      dateOfBirth,
    }
    loggerUTL('SAVING SCANNED DATA...', ocrResponseData)
    dispatch(saveOcrResponseDataAXN(ocrResponseData));

    dispatch(
      saveFormDataAXN(
        {
          firstName,
          middleName,
          lastName,
          dateOfBirth,
          gender,
          nationalIDNo,
        },
        'singapore'
      )
    );
  } catch (err) {
    loggerUTL(data);
    dispatch(
      setAlertAXN(transUTL('translateAlertMsg.inputMappingError'), 'error')
    );
    Sentry.captureException('Input mapping error...', data, err);
  }
};

export default inputMappingSG;
