const consentCheckerCN = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('China ID Verification')) {
    // CONSENT COLLECTOR
    consentObtained['China ID Verification'] = true;

    if (!inputData.idVerificationConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('China Phone')) {
    // CONSENT COLLECTOR
    consentObtained['China Phone'] = true;

    if (!inputData.nationalPhoneConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('China Phone Alt')) {
    // CONSENT COLLECTOR
    consentObtained['China Phone Alt'] = true;

    if (!inputData.phoneAltConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('China Bank Card')) {
    // CONSENT COLLECTOR
    consentObtained['China Bank Card'] = true;

    if (!inputData.bankCardConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('China Passport Verification')) {
    // CONSENT COLLECTOR
    consentObtained['China Passport Verification'] = true;

    if (!inputData.passportVerificationConsentObtained) {
      return { isConsent: false };
    }
  }

  if (dataSources.includes('China Driver License')) {
    // CONSENT COLLECTOR
    consentObtained['China Driver License'] = true;

    if (!inputData.driversLicenseConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerCN;
