const initialStateAU = {
  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // CURRENT RESIDENTIAL ADDRESS
  unitNo: '',
  streetNo: '',
  streetName: '',
  suburb: '',
  state: '',
  postCode: '',

  // BIRTH CERTIFICATE
  birthRegistrationState: '',
  birthRegistrationNo: '',
  birthCertificateNo: '',
  birthRegistrationDate: '',

  // CENTERLINK
  centrelinkCardType: '',
  centrelinkCardExpiry: '',
  centrelinkCustomerReferenceNo: '',

  // GOOGLE ADDRESS AUTO COMPLETE
  isGoogle: true,
  isGoogleParsed: true,
  addressUnparsed: '',

  // CONTACT DETAILS
  phoneNo: '',
  email: '',

  // DRIVERS LICENCE
  driversLicenceNo: '',
  driversLicenceCardNo:'',
  driversLicenceStateOfIssue: '',
  driversLicenceExpiryDate: '',

  // MEDICARE
  medicareCardNo: '',
  medicareCardType: '',
  medicareReferenceNo: '',
  medicareExpiry: '',

  // PASSPORT
  passportNo: '',
  passportIssuerCountry: '',
  passportExpiry: '',
  medicareExpiryDateToggle: false,
  countryCode: '',

  // ABN
  ABN: '',

  // TERMS AND CONDITIONS
  payrollConsentObtained: false,
  superannuationConsentObtained: false, 
  birthCertificateConsentObtained: false,
  centerlinkCardConsentObtained: false,
  driverLicenceConsentObtained: false,
  medicareCardConsentObtained: false,
  passportConsentObtained: false,
  visaConsentObtained: false,
  residentialLookupConsentObtained: false,
  visaEntitlementConsentObtained: false,
  creditBureauConsentObtained: false,

  // REFERENCE NUMBER
  reportingReference: '',

  // GOOGLE
  manualAddressInput: true,

  // DISPLAY API
  displayApiResultObtained: false,

  // ERROR HANDLING
  errors: {},
};

export default initialStateAU;
