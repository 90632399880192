import isEmptyVAL from "../../../../validations/checks/isEmptyVAL";

const globalDataSources = (dataSources) => {
  const userDataSources = [];

  const global = dataSources.filter((dataSources) =>
    dataSources.toLowerCase().includes('global')
  );

  if (!isEmptyVAL(global)) {
    userDataSources.push({
      region: 'global',
      country: 'global',
      dataSources: [...global],
      belongsTo: 'global',
      code: null
    });
  }

  return userDataSources;
}

export default globalDataSources;