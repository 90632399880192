import { SET_NORMAL_ALERT, REMOVE_ALERT } from '../../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case SET_NORMAL_ALERT:
      return [...state, action.payload];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== action.payload);
    default:
      return state;
  }
}
