// UTILITIES
import whitelistKeys from '../../../../../utils/whitelistKeysUTL';
import loggerUTL from '../../../../../utils/loggerUTL';

const whitelistedInputDataVN = (inputData, dataSources) => {
  let consumerData, vietnamNationalID, vietnamSocialSecurity, watchlistData;

  /* ============================================
              VIETNAM CONSUMER DATA
   ============================================ */
  if (dataSources.includes('Vietnam Consumer Data')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'idCardNo',
      'address',
      'reportingReference',
      'nationalIDConsentObtained',
    ]);

    consumerData = { ...whitelisted };
  }

  /* ============================================
              VIETNAM NATIONAL ID
   ============================================ */
  if (dataSources.includes('Vietnam National ID')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'idCardNo',
      'address',
      'reportingReference',
      'nationalIDConsentObtained',
    ]);

    vietnamNationalID = { ...whitelisted };
  }

  /* ============================================
              VIETNAM SOCIAL SECURITY
   ============================================ */
  if (dataSources.includes('Vietnam Social Security')) {
    loggerUTL('WHITELISTING...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'idCardNo',
      'address',
      'reportingReference',
      'socialSecurityConsentObtained',
    ]);

    vietnamSocialSecurity = { ...whitelisted };
  }

  /* ============================================
              WATCHLIST AML
   ============================================ */
  if (dataSources.includes('Watchlist AML')) {
    loggerUTL('WHITELISTING WATCHLIST AML...');
    const whitelisted = whitelistKeys(inputData, [
      'firstName',
      'middleName',
      'lastName',
      'dateOfBirth',
      'gender',
      'reportingReference',
    ]);
    watchlistData = { ...whitelisted };
  }

  return {
    ...consumerData,
    ...vietnamNationalID,
    ...vietnamSocialSecurity,
    ...watchlistData,
  };
};
export default whitelistedInputDataVN;
