const formErrors = {
  // CREDENTIALS
  username: 'nama pengguna diperlukan',
  password: 'kata laluan diperlukan',
  // PERSONAL INFORMATION
  firstName: 'Nama pertama diperlukan',
  firstNameTooShort: 'Nama depan terlalu pendek',
  middleName: 'Nama tengah diperlukan',
  middleNameTooShort: 'Nama tengah terlalu pendek',
  lastName: 'Nama belakang diperlukan',
  lastNameTooShort: 'Nama belakang terlalu pendek',
  dateOfBirth: 'Tarikh lahir Wajib',
  fullName: 'Nama penuh diperlukan',
  gender: 'Jantina diperlukan',
  paternalName: 'Nama Ibu diperlukan',
  maternalName: 'Nama Ibu diperlukan',
  yearOfBirth: 'Tahun lahir diperlukan', 
  firstNameORlastName: 'Sama ada nama pertama atau nama keluarga diperlukan',

  // CURRENT RESIDENTIAL ADDRESS
  block: 'Nombor jalan / blok diperlukan',
  building: 'Bangunan diperlukan',
  addressLine1: 'Talian alamat 1 diperlukan',  
  street: 'Jalan diperlukan',
  streetNo: 'Nombor jalan diperlukan',
  streetName: 'Nama jalan diperlukan',
  streetAddress: 'Alamat jalan diperlukan',
  streetNumberAndName: 'Nombor dan nama jalan diperlukan',
  townOrCity: 'Bandar atau bandar diperlukan',
  suburb: 'Pinggir bandar diperlukan',
  state: 'Negeri diperlukan',
  postCode: 'Poskod diperlukan',
  postcode: 'Postkod diperlukan',
  zipCode: 'Poskod diperlukan',
  locality: 'Pinggir bandar diperlukan',
  city: 'Bandar diperlukan',
  district: 'Daerah diperlukan',
  ward: 'Wad diperlukan',
  province: 'Wilayah diperlukan',
  streetNameAndHouseNo: 'Nama jalan dan nombor rumah diperlukan',
  houseNumber: 'Nombor rumah diperlukan', // ITALY, CZECH
  county: 'Daerah diperlukan',
  postCodeLengthNZ: 'Poskod hendaklah 4 digit',
  // BIRTH CERTIFICATE
  birthRegistrationState: 'Negeri pendaftaran kelahiran diperlukan',
  birthRegistrationNo: 'Nombor pendaftaran kelahiran diperlukan',
  birthCertificateNo: 'Nombor sijil kelahiran diperlukan',
  birthRegistrationDate: 'Tarikh pendaftaran kelahiran diperlukan',
  // CENTERLINK
  centrelinkCardType: 'Jenis kad pautan tengah',
  centrelinkCardExpiry: 'Tamat kad kad pautan tengah',
  centrelinkCustomerReferenceNo: 'Nombor rujukan pelanggan kad pautan tengah',
  // DRIVERS LICENCE
  driversLicenceNo: 'Nombor lesen memandu diperlukan',
  driversLicenceCardNo: 'Keadaan keluaran Lesen Pemandu diperlukan',
  driversLicenceStateOfIssue: 'Perkara pengeluaran lesen memandu diperlukan',
  driversLicenceVersion: 'Versi lesen memandu diperlukan',
  vehiclePlate: 'Pendaftaran kenderaan diperlukan',
  driversLicenceExpiryDate: 'Tarikh luput lesen memandu diperlukan',
  driversLicenceNoLengthNZ: 'Lesen memandu hendaklah 2 huruf diikuti dengan 6 nombor',
  driversLicenceVersionLengthNZ: 'Versi lesen memandu hendaklah 3 digit dari 0-9',
  // MEDICARE
  medicareCardNo: 'Nombor kad Medicare diperlukan',
  medicareCardType: 'Jenis kad Medicare diperlukan',
  medicareReferenceNo: 'Nombor rujukan individu Medicare diperlukan',
  medicareExpiry: 'Tarikh luput Medicare diperlukan',

  // PASSPORT
  passportNo: 'Nombor pasport diperlukan',
  passportIssuerCountry: 'Negara terbitan diperlukan',
  passportExpiryDate: 'Tarikh luput pasport diperlukan',
  passportExpiry: 'Tarikh luput pasport diperlukan',
  passportExpiryDateAU:
    'Dokumen ini telah tamat dan mungkin tidak dapat disahkan. ',
  passportNoInvalidM: "Tidak menerima nombor pasport yang diawali dengan 'M'",
  fileNo: 'Nombor fail diperlukan',
  dateOfIssue: 'Tarikh pengeluaran diperlukan',
  // GOOGLE
  addressUnparsed: 'Alamat diperlukan',
  // CHINESE SEARCH
  phoneNo: 'Nombor telefon diperlukan',
  phoneNo1: 'Nombor telefon tidak sah',
  phoneNo2: 'Panjang nombor telefon melebihi',
  phoneNo3: '11 digit diperlukan',
  phoneNo4: 'Nombor telefon mesti bermula dengan +86',
  idCardNo: 'Nombor kad pengenalan diperlukan',
  bankCardNo: 'Nombor kad bank diperlukan',
  passportNo: 'Nombor pasport diperlukan',
  // IDENTITY DATA
  laserNo: 'Nombor laser pengenalan diperlukan',
  idNo: 'Nombor kad pengenalan diperlukan',
  idCardNoLength: 'Panjang nombor kad pengenalan hendaklah 12 atau 9 aksara',
  nationalIDNo: 'Nombor kad pengenalan diperlukan',
  landlineNo: 'Nombor talian darat diperlukan',  
  vin: 'Nombor ID nasional diperlukan',
  voterNo: 'Nombor pengundi diperlukan',
  bankVerificationNo: 'Nombor Pengesahan Bank diperlukan',
  curp: 'Curp diperlukan',
  taxCodeNo: 'Nombor Kod Cukai diperlukan',
  nationalIdNo: 'Nombor kad pengenalan diperlukan',
  // NATIONAL ID DATA
  idcardNoType: 'Jenis kad pengenalan diperlukan',
  searchType: 'Jenis carian diperlukan',
  // ACCOUNT NUMBER
  pan: 'PAN diperlukan',
  aadhaarNo: 'Aadhaar diperlukan',
  epic: 'EPIC diperlukan',
  epicLength: 'Panjang watak hendaklah 10',
  // RESIDENT IDENTITY CARD (NIK)
  nik: 'NIK diperlukan',
  // DATES
  dateOfBirthFormat: 'Format tarikh yang salah',
  medicareExpiryDateFormat:
    'Format tarikh yang tidak betul, petunjuk: (mm / yyyy)',
  medicareExpiryDateFormatExceeded:
    'Tarikh yang dimasukkan lebih besar daripada tarikh hari ini ...',
  passportExpiryDateFormat: 'Format tarikh yang salah',
  medicareReferenceNoNumberOnly: 'Hanya nombor',

  // SOCIAL SECURITY
  socialSecurityNo: 'Nombor Jaminan Sosial diperlukan',

  // TAX REGISTRATION
  taxIDNo: 'No ID Cukai Diperlukan',
  cpf: 'CPF (CADASTRO DE PESSOAS FÍSICAS) diperlukan',
  rfc: 'RFC diperlukan',
  npwp: 'NPWP (Nomor Pokok Wajib Pajak - Tax ID Number) diperlukan',

  // CONTACTS
  emailAddress: 'Alamat e-mel diperlukan',
  email: 'Alamat e-mel diperlukan',
  emailFormatIncorrect: 'Format e-mel tidak betul',
  emailID: 'Alamat e-mel diperlukan',


  // ABN
  ABN: 'Nombor perniagaan Australia',
};
export default formErrors;
