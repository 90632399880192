const dataSourcesBR = {
	brazilNationalID: {
		title: 'ID Nasional Brazil',
		safeHarbourScore: 'Skor Pelabuhan Selamat',
		verified: 'Disahkan',
		addressMatchScore: "Markah Padanan Alamat",
		nameMatchScore: "Skor Pertandingan Nama",
		nationalIdNo: 'Pertandingan ID Nasional',
		firstName: 'Nama pertama',
		middleName: 'Nama Tengah Disahkan',
		lastName: 'Nama terakhir',
		unitNo: 'Unit No.',
		streetName: 'Alamat Jalan Disahkan',
		locality: 'Lokaliti',
		district: 'Daerah',
		postCode: 'Post Code',
		dateOfBirth: 'Tarikh Lahir Disahkan',
	},
	taxRegistration: {
		title: 'Pendaftaran Cukai Brazil',
		safeHarbourScore: 'Skor Pelabuhan Selamat',
		verified: 'Disahkan',
		addressMatchScore: "Markah Padanan Alamat",
		nameMatchScore: "Skor Pertandingan Nama",
		fullName: 'Nama Penuh',
		dateOfBirth: 'Tarikh Lahir Disahkan',
		cpf: 'CPF'
	}
};
export default dataSourcesBR;