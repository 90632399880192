import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILS
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';
import { setAlertAXN } from '../../alert/alertActions';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';


// LOAD AUSTRALIA API ERRORS TO INPUT FORM
const feedbackFindIdu = (errors, dispatch) => {
  try {
    loggerUTL('ERRORS...', errors);
    const errorMsg = "Request must include at least any of 3 field values from First Name, Middle Name, Last Name, Birth Year, Barangay, City, Province, Post Code"
    const inputError = {};
    let isRequestError = false;

    //Check whether user types three inputs 
    if (!isEmptyVAL(errors)) {
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].substr(0,7) === "Request") {
          isRequestError = true;
        }
      }
    }
    
    /* ============================================
                PERSONAL INFORMATION
     ============================================ */

    //PROVIDE ALERT MESSAGE for findIDU
    if (!isEmptyVAL(errors) && isRequestError) {
      dispatch(setAlertAXN(errorMsg, 'error'));
    }

    //First name or last name is too short
    if (findErrorUTL(errors, 'firstName') && !isRequestError) {
      if (errors[0].includes("short")) {
        inputError.firstName = `First name ${
          errors.find((item) => item.includes('firstName')).split(':')[1]
        }`;
      }
      if (errors[0].includes("provided")) {
        inputError.firstName = `First name is required`;
      }
    }

    if (findErrorUTL(errors, 'lastName') && !isRequestError) {
      if (errors[0].includes("short")) {
        inputError.lastName = `Last name ${
          errors.find((item) => item.includes('lastName')).split(':')[1]
        }`;
      }
      if (errors[0].includes("provided")) {
        inputError.lastName = `Last name is required`;
      }
    }

    // Philippines Suspicious Activity and Digital ID Safeguard only are required either firstName or lastName
    // Watchlist AML needs both firstName and lastName that the user is asked to type both fields
    if (!isEmptyVAL(errors[0])) {
      if (errors[0].substr(0,6) === "Either" && !isRequestError) {
        inputError.firstName = `${
          errors.find((item) => item.includes('Either'))
        }`;
        inputError.lastName = `${
          errors.find((item) => item.includes('Either'))
        }`;
      }
    }

    if (!isEmptyVAL(errors[1])) {
      if (errors[1].substr(0,6) === "Either" && !isRequestError) {
        inputError.firstName = `${
          errors.find((item) => item.includes('Either'))
        }`;
        inputError.lastName = `${
          errors.find((item) => item.includes('Either'))
        }`;
      }
    }

    if (findErrorUTL(errors, 'emailID') && !isRequestError) {
      inputError.emailID = `Email${
        errors.find((item) => item.includes('emailID')).split(':')[1]
      }`;
    }

    dispatch({
      type: ERROR_FEEDBACK,
      payload: inputError,
    });
  } catch (err) {
    loggerUTL(err);
    Sentry.captureException(
      'FAILED TO PARSE FIND (ID)U VALIDATION ERRORS...',
      JSON.stringify
    )(err);
  }
};

export default feedbackFindIdu;
