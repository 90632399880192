const dataSourcesFR = {
  franceResidential: {
    title: '法国住宅',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    dateOfBirth: '出生日期已验证',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    email: '电子邮件已验证',
    phoneNo: '电话号码已验证',
    street: '街道已验证',
    city: '城市已认证',
    postCode: '邮编已验证',
    addressMatchScore: '地址匹配分数',
    nameMatchScore: '名字匹配分数',
  },
  franceResidential2: {
    title: '法国住宅 2',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    emailAddress: '电子邮件已验证',
    phoneNo: '电话号码已验证',
    street: '街道已验证',
    city: '城市已认证',
    state: '州已认证',
    postCode: '邮编已验证',
  },
  franceResidential3: {
    title: '法国住宅 3',
    searchSuccessful: '搜索成功',
    verified: '已验证',
    safeHarbourScore: '安全港评分',
    nameMatchScore: '名字匹配分数',
    addressMatchScore: '地址匹配分数',
    firstName: '名已验证',
    lastName: '姓氏已验证',
    dateOfBirth: '出生日期已验证',
    emailAddress: '电子邮件已验证',
    phoneNo: '电话号码已验证',
    street: '街道已验证',
    city: '城市已认证',
    postCode: '邮编已验证',
  }
};

export default dataSourcesFR;
