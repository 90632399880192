const dataSourcesNG = {
  nigeriaNationalID: {
    title: 'Nigeria National ID',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    firstName: 'First Name Verified',
    middleName: 'Middle Name Verified',
    lastName: 'Last Name Verified',
    nameMatchScore: 'Name Match Score',
    nationalIdNo: 'National ID Number Verified',
    addressMatchScore: 'Address Match Score',
    street: 'Street Address Verified',
    town: 'Town Verified',
    lga: 'Local Government Area Verified',
    state: 'State Verified',
    gender: 'Gender Verified',
    phoneNo: 'Phone Number Verified',
    email: 'Email Verified'
  },
  nigeriaBankVerification: {
    title: 'Nigeria Bank Verification',
    searchSuccessful: 'Search Successful',
    verified: 'Verified',
    safeHarbourScore: 'Safe Harbour Score',
    dateOfBirth: 'Date Of Birth Verified',
    firstName: 'First Name Verified',
    middleName: 'Middle Name Verified',
    lastName: 'Last Name Verified',
    nameMatchScore: 'Name Match Score',
    bankVerificationNo: 'Bank Verification Number Verified',
    addressMatchScore: 'Address Match Score',
    street: 'Street Address Verified',
    town: 'Town Verified',
    lga: 'Local Government Area Verified',
    state: 'State Verified',
    gender: 'Gender Verified',
    phoneNo: 'Phone Number Verified',
    email: 'Email Verified'
  }
};

export default dataSourcesNG;
