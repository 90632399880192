import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AccountLockForm = ({ lockAccountAXN, closeModal, user }) => {
  const handleOnSubmit = () => {
    lockAccountAXN(user.userId, user.orgId, user.isLocked);
    closeModal();
  };

  return (
    <Fragment>
      <div className="modal__dialogue__body">
        <div className="modal__dialogue__body__content">
          <div className="account-lock">
            {user.isLocked ? (
              <p>Are you sure you want to unlock this account?</p>
            ) : (
              <p>Are you sure you want to lock this account?</p>
            )}
            <p>
              <span>{user.username}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="modal__dialogue__footer">
        <button
          className="modal__dialogue__footer__btn submit"
          onClick={() => handleOnSubmit()}
        >
          Yes
        </button>
        <button
          className="modal__dialogue__footer__btn cancel"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
      </div>
    </Fragment>
  );
};

AccountLockForm.propTypes = {
  lockAccountAXN: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default AccountLockForm;

