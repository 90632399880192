const inputAU = {
  // PERSONAL INFORMATION
  firstName: 'John',
  middleName: 'Allen',
  lastName: 'Smith',
  dateOfBirth: 'yyyy/mm/dd',

  // CURRENT RESIDENTIAL ADDRESS
  unitNo: '1A',
  streetNo: '77',
  streetName: 'McMaster Street',
  suburb: 'North Sydney',
  postCode: '2060',
  addressUnparsed: 'Search your address...',

  // BIRTH CERTIFICATE
  birthRegistrationState: '',
  birthRegistrationNo: '',
  birthCertificateNo: '',
  birthRegistrationDate: '',

  // CENTERLINK
  centrelinkCardType: '',
  centrelinkCardExpiry: '',
  centrelinkCustomerReferenceNo: '',

  // DRIVERS LICENCE
  driversLicenceNo: '94977000',
  driversLicenceCardNo: '',
  driversLicenceStateOfIssue: '',
  driversLicenceExpiryDate: '',

  // MEDICARE
  medicareCardNo: '3512743581',
  medicareReferenceNo: '1',
  medicareExpiry: 'mm/yyyy',
  medicareCardType: 'G',

  // PASSPORT
  passportNo: 'C5100511',
  passportExpiry: '',

  // CONTACT DETAILS
  phoneNo: '02 9966 5544',
  // mobileNo: '0404 040 404',
  email: 'jsmith@datazoo.co.nz',
  
  // REFERENCE
  reportingReference: '',

  // ABN
  ABN: '51 824 753 556',
};

export default inputAU;
