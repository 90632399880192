const dataSourcesMY = {
  creditBureau: {
    title: 'Biro Kredit Malaysia',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    city: 'Bandar',
    dateOfBirth: 'Tarikh lahir',
    nameMatchScore: 'Skor Pertandingan Nama',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    nationalIDNo: 'Id Nasional No',
    postCode: 'Poskod Disahkan',
    state: 'Negeri',
    street: 'Jalan',
    addressMatchScore: 'Markah Padanan Alamat',
  },
  nationalID: {
    title: 'Kad Pengenalan Nasional Malaysia',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    state: 'Negeri',
    name: 'Nama',
    dateOfBirth: 'Tarikh lahir',
    nameMatchScore: 'Skor Pertandingan Nama',
    nationalIDNo: 'Id Nasional No',
    postCode: 'Poskod Disahkan',
    city: 'Bandar',
    street: 'Jalan',
    gender: 'Jantina',
    addressMatchScore: 'Markah Padanan Alamat',
  },
};

export default dataSourcesMY;
