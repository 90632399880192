const initialState = {
  // PERSONAL INFORMATION
  firstName: '',
  lastName: '',
  middleName: '',

  // IDENTITY DETAILS
  emailID: '',
  hitTarget: '',
  yearOfBirth: '',

  // RESIDENTIAL ADDRESS
  barangay: '',
  city: '',
  province: '',
  postCode: '',

  // TERMS AND CONDITIONS
  philippinesSuspiciousConsentObtained: false,
  digitalIDSafeguardConsentObtained: false,

  // DATA SOURCES
  countryDataSources: {},

  // ERROR HANDLING
  errors: {},
};

export default initialState;
