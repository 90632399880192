import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loggerUTL from '../../utils/loggerUTL';

// VALIDATIONS
// import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
// import { transUTL } from '../../utils/transUTL';

const CostEstimation = ({ authRXS, costEstRXS, history }) => {
  const [selectTypeSTH, setSelectTypeSTH] = useState({
    volType: 'monthly',
    volPrice: '0, 0',
  });
  const { selectedCountry, selectedDataSource, priceTier } = costEstRXS;

  const priceTire = { ...priceTier[selectedCountry][selectedDataSource] };
  const { currency, dataProvider } = priceTire;
  const volumeType = { ...priceTire[`${selectTypeSTH.volType}PriceTier`] };
  const volumeRanges = [...Object.keys(volumeType)];
  const volumePrices = [...Object.values(volumeType)];
  const price = selectTypeSTH.volPrice.split(', ')[0]; // PRICE PER SEARCH
  const range = selectTypeSTH.volPrice.split(', ')[1]; // SEARCH VOLUME eg. 0 - 80
  const total = range * price;

  const volumeRangesOptions = volumeRanges.map((option, index) => (
    // COMMA SEPARATION eg([price], [searchVol]), 0.4, 89
    <option key={option} value={`${volumePrices[index]}, ${option}`}>
      {option}
    </option>
  ));

  const handleOnSelectChange = (e) => {
    loggerUTL(e.target.value, 'what it this');

    setSelectTypeSTH({
      ...selectTypeSTH,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="usage-report cost-est">
      <div className="usage-report__table usage-report__chart-tile">
        <header className="header-primary">
          <h2 className="usage-report__title">
            {selectedDataSource} - {dataProvider}
          </h2>

          <span
            className="material-icons"
            title="Edit Client"
            onClick={() =>
              history.push(
                `/cost-estimation/country-selection/data-source-selection/:${authRXS.user.name.toLowerCase()}`
              )
            }
          >
            create
          </span>
        </header>
        <div className="common-form__body">
          <div className="form-group">
            <label htmlFor="volType" className="form-group__label">
              Volume Type
            </label>
            <select id="volType" name="volType" onChange={handleOnSelectChange}>
              <option value="monthly">Monthly</option>
              <option value="annual">Annually</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="volPrice" className="form-group__label">
              Search Volume
            </label>
            <select
              id="volPrice"
              name="volPrice"
              onChange={handleOnSelectChange}
            >
              <option value="0, 0">Select</option>
              {volumeRangesOptions}
            </select>
          </div>
        </div>
      </div>

      <div className="usage-report__graphs">
        <div className="usage-report__chart-tile">
          <header className="header-primary">
            <h2 className="usage-report__title">Projected Cost</h2>
            <span className="usage-report__title">({currency})</span>
          </header>
          <div className="common-form__body">
            <div style={{ fontSize: '1.3rem' }} className="flex-between">
              <p>
                Per Search:{' '}
                <span style={{ color: '#59c16f', fontWeight: '700' }}>
                  ${price}c
                </span>
              </p>
              <p>
                Total:{' '}
                <span style={{ color: '#59c16f', fontWeight: '700' }}>
                  ${total.toFixed(2)}
                </span>{' '}
                {selectTypeSTH.volType === 'annual' ? 'Annually' : 'Monthly'}
              </p>
            </div>
          </div>
        </div>
        <div
          className="usage-report__chart-tile"
          style={{ visibility: 'hidden' }}
        >
          <header className="header-primary">
            <h2 className="usage-report__title">Projected Cost</h2>
            <span className="usage-report__title">{currency}</span>
          </header>
          <div className="common-form__body">
            <div className="form-group">
              {/* <select onChange={handleOnSelectChange} name="volType">
                {volumeRangesOptions}
              </select> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CostEstimation.propTypes = {
  authRXS: PropTypes.object.isRequired,
  costEstRXS: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  costEstRXS: state.costEstRXS,
});

export default connect(mapStateToProps, {})(CostEstimation);
