import {
  SAVE_API_CALL
} from '../../actions/types';

const initialState = {
  // australiaFormData: {

  //   //API results
  //   displayApiResultObtained: false,
  // },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_API_CALL:
      return {
        ...state,
        apiCall: action.payload,
      };
  
    // case RESET_FORM_DATA:
    //   return initialState;
    default:
      return state;
  }
}
