import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';

// LOAD INDONESIA API ERRORS TO INPUT FORM
const feedbackID = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);
  const inputError = {};

  try {
    /* ============================================
              PERSONAL INFORMATION
       ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'lastName')) {
      inputError.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    /* ============================================
              RESIDENT IDENTITY CARD (NIK)
       ============================================ */
    if (findErrorUTL(errors, 'nik')) {
      inputError.nik = `NIK number ${
        errors.find((item) => item.includes('nik')).split(':')[1]
      }`;
    }
    if (findErrorUTL(errors, 'epic')) {
      inputError.epic = `EPIC number ${
        errors.find((item) => item.includes('epic')).split(':')[1]
      }`;
    }

    /* ===========================================
              TAX REGISTRATION (NPWP)
      ============================================ */
    if (findErrorUTL(errors, 'npwp')) {
      inputError.npwp = `NPWP number ${
        errors.find((item) => item.includes('npwp')).split(':')[1]
      }`;
    }
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES INDONESIA VALIDATION ERRORS...',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackID;
