import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX ACTIONS
import { saveCostEstDataSourceAXN } from '../../actions/costEstimation/costEstimationAction';
import { setAlertAXN } from '../../actions/alert/alertActions';

// COMPONENTS
import DropdownArray from '../../components/dropdown/DropdownArray';
import Button from '../../components/btn/Button';

// VALIDATIONS
import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../utils/transUTL';

const CostEstDataSourceSelection = ({
  authRXS,
  costEstRXS,
  saveCostEstDataSourceAXN,
  setAlertAXN,
  history,
}) => {
  const [dataSourceSTH, setDataSourceSTH] = useState(
    costEstRXS.selectedDataSource
  );

  useEffect(() => {
    setDataSourceSTH(costEstRXS.selectedDataSource);
    // eslint-disable-next-line
  }, []);

  const dataSources = [
    ...Object.keys(costEstRXS.priceTier[costEstRXS.selectedCountry]),
  ];

  //  HANDLE ON SUBMIT
  const handleOnSubmit = () => {
    if (isEmptyVAL(dataSourceSTH))
      return setAlertAXN(
        transUTL('translateAlertMsg.pleaseSelectDataSource'),
        'error'
      );

    saveCostEstDataSourceAXN(dataSourceSTH);
    history.push(
      `/cost-estimation/country-selection/data-source-selection/pricing/:${authRXS.user.name.toLowerCase()}`
    );
  };
  const handleOnChange = (e) => setDataSourceSTH(e.target.value);
  const handelRadio = (opt) => setDataSourceSTH(opt);

  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2> Data Source Selection </h2>
        <span
          className="material-icons icon-size"
          title={transUTL('translateTitle.nextPage')}
          onClick={handleOnSubmit}
        >
          double_arrow
        </span>
      </header>
      <div className="common-form__body">
        <DropdownArray
          array={dataSources}
          target={dataSourceSTH}
          name="country"
          // transType={}
          handleOnChange={handleOnChange}
          handelRadio={handelRadio}
        />
      </div>
      {/* BUTTON */}
      <Button
        history={history}
        to={`/cost-estimation/country-selection/:${authRXS.user.name.toLowerCase()}`}
        handleOnSubmit={handleOnSubmit}
        btn1Text={transUTL('translateBtn.previousPage')}
        btn2Text={transUTL('translateBtn.nextPage')}
      />
    </div>
  );
};

CostEstDataSourceSelection.propTypes = {
  authRXS: PropTypes.object.isRequired,
  costEstRXS: PropTypes.object.isRequired,
  saveCostEstDataSourceAXN: PropTypes.func.isRequired,
  setAlertAXN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  costEstRXS: state.costEstRXS,
});

export default connect(mapStateToProps, {
  saveCostEstDataSourceAXN,
  setAlertAXN,
})(CostEstDataSourceSelection);
