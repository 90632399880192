import isEmptyVAL from "../../../validations/checks/isEmptyVAL";

const personalInformation = (identityData) => {
  let { fullName, firstName, middleName, lastName, dateOfBirth, gender } =
    identityData;

  if (!isEmptyVAL(fullName)) {
    fullName = `Full Name: ${fullName}`;
    firstName = "";
    middleName = "";
    lastName = "";
  } else {
    if (!isEmptyVAL(firstName)) {
      firstName = `First Name: ${firstName}`;
    }
    if (!isEmptyVAL(middleName)) {
      middleName = `Middle Name: ${middleName}`;
    }
    if (!isEmptyVAL(lastName)) {
      lastName = `Last Name: ${lastName}`;
    }
  }

  if (!isEmptyVAL(dateOfBirth)) {
    dateOfBirth = `Date of Birth: ${dateOfBirth}`;
  }

  if (!isEmptyVAL(gender)) {
    if (gender === "X" || gender === "Won't specify") {
      gender = "Gender: Prefer not to say";
    } else if (gender === "Female" || gender === "F") {
      gender = "Gender: Female";
    } else if (gender === "Male" || gender === "M") {
      gender = "Gender: Male";
    }
  }

  return {
    personalInformationData: [
      fullName,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      gender,
    ],
  };
};

export default personalInformation;
