import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';

// UTILITIES
import { transUTL } from '../../../utils/transUTL';

const AddressAutoComplete = ({
  formState,
  selectedDataSources,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  manualAddressEntryToggle,
  handleGoggleOnChange,
  handleGoogleSelect,
  countryRestriction,
}) => {
  const { addressUnparsed, errors } = formState;
  const searchOptions = {
    componentRestrictions: { country: [countryRestriction] }, 
    language: 'en',
  };

  const errorClass = errors.addressUnparsed ? 'input-error' : '';

  return (
    <div className="form-overview__auto-address form-group">
      <PlacesAutocomplete
        key="validateAddress"
        searchOptions={searchOptions}
        value={addressUnparsed}
        onChange={handleGoggleOnChange}
        onSelect={handleGoogleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: `${transUTL(
                  'translatePlaceholder.inputAU.addressUnparsed'
                )}`,
                className: `${errorClass}`,
              })}
            />
            {errors.addressUnparsed ? (
              <small className="form-group__error-text">
                <span className="material-icons">error</span>
                {errors.addressUnparsed}
              </small>
            ) : null}

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'form-overview__suggestion-item--active'
                  : 'form-overview__suggestion-item';

                if (addressUnparsed.length >= 15) {
                  handleGoogleSelect(formState.addressUnparsed, true);
                }

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <div className="form-overview__address-option">
        {transUTL('translateFormOverview.cantFindAddress')}

        <div
          onClick={manualAddressEntryToggle}
          className="form-overview__address-switch"
        >
          {transUTL('translateFormOverview.manualAddress')}
        </div>
      </div>
    </div>
  );
};

AddressAutoComplete.propTypes = {
  formState: PropTypes.object.isRequired,
  selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
  manualAddressEntryToggle: PropTypes.func.isRequired,
  handleGoggleOnChange: PropTypes.func.isRequired,
  handleGoogleSelect: PropTypes.func.isRequired,
  countryRestriction: PropTypes.string.isRequired,
};

export default AddressAutoComplete;
