import infoMsg from '../../../helpers/infoMsg';
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';
import sortAlphaUTL from "../../../../../utils/sortAlphaUTL";
import returnDataToString from '../../../helpers/returnDataToString';

const { default: title } = require("../../../helpers/title");

const returnedData = (doc, data, info, docStyles) => {
  const { Response } = data;

  title(
    doc,
    docStyles,
    'Returned Data',
    docStyles.headers.secondary,
    docStyles.separation
  );

  const columns = [
    { title: '', dataKey: 'col1'},
    {
      title: '',
      dataKey: 'col2',
    }
  ];

  const rows = [];

  let residential = Response.serviceResponses['Morocco Residential'];
  let watchlistAML = Response.serviceResponses['Watchlist AML'];

  if (residential) {
    let { returnedData, verifications, ...data} = residential;

    const string = returnDataToString({
      fields: { ...data, ...verifications, ...returnedData },
    });
    rows.push({
      col1: 'Morocco Residential',
      col2: string
    })
  } else {
    residential = { verifications: {}, returnedData: {}};
  }

  let watchlistAMLPaddingTrigger = false;
  if (watchlistAML) {
    let { returnedData, verifications, ...data } = watchlistAML;

    if (isEmptyVAL(returnedData.watchlistResults)) {
      returnedData = { watchlistResults: [{}]};
    }

    const fields = returnDataToString({
      fields: {
        ...data,
        ...verifications,
        ...returnedData.watchlistResults[0]
      }
    });

    rows.push({
      col1: 'Global Watchlist AML',
      col2: fields
    });
  } else {
    watchlistAML = {
      returnedData: { watchlistResults: [{}]},
      verifications: {}
    };
  }

  rows.sort((a,b) => sortAlphaUTL(a, b, 'col1'));

  const finalY = doc.previousAutoTable.finalY + docStyles.tableYFromTitle;

  doc.autoTable(columns, rows, {
    didParseCell: data => {
      const { cell, column } = data;
      cell.styles.fillColor = [255, 255, 255];

      if (column.raw.dataKey === 'col1') {
        cell.styles.textColor =  docStyles.colorGreen;
      }

      if (column.index === 0) {
        cell.styles.cellPadding = { top: 60, bottom: 60};
      }

      if (cell.raw ===  'Watchlist AML') {
        if (watchlistAMLPaddingTrigger) {
          cell.styles.cellPadding = { top: 125, bottom: 125};
        }
      }
    },
    showHead: 'never',
    styles: {
      textColor: docStyles.colorText,
      ...docStyles.tableStyles
    },
    columnStyles: {
      col1: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText,
        cellWidth: docStyles.cellWidth
      },
      col2: {
        fontStyle: docStyles.fontFamily.time,
        textColor: docStyles.colorText
      }      
    },
    startY: finalY
  });

  infoMsg(
    doc,
    docStyles,
    [{ title: '', dataKey: 'col1'}],
    [
      {
        col1: 'Name Match Score: A=Full Match | A1=Middle Name Mismatched | B=First Initial and Surname Matched | C=Surname Only Match | X=No Match' 
      },
      {
        col1: 'Address Match Score: 0=Full Match | 1=Suburb/Postcode Mismatch | 2=Unit # Mismatch | 2A=House Suf/Prefix Mismatch | 3=Street # Mismatch | Z=No Match'
      }
    ],
    docStyles.separation
  );
}

export default returnedData;