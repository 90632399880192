const watchlistAML = {
  title: '全球反洗钱观察名单',
  WatchlistStatusText: '国际PEP监视列表状态',
  watchlistStatusClear: '明确',
  watchlistStatusFound: '发现',
  category: '类别',
  otherNames: '其他名称',
  fullReport: '完整报告',
  safeHarbourScore: '安全港评分',
  pdfLink: 'PDF链接',
  searchSuccessful: '搜索成功',
};

export default watchlistAML;
