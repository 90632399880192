import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// VALIDATIONS
// import isEmptyVAL from '../../validations/checks/isEmptyVAL';

// UTILITIES
// import { transUTL } from '../../utils/transUTL';

const AboutUs = ({ authRXS, countryRXS }) => {
  return (
    <div className="trans-history common-form">
      <header className="header-primary">
        <h2>About US</h2>
      </header>
      <div className="common-form__body"></div>
    </div>
  );
};

AboutUs.propTypes = {
  authRXS: PropTypes.object.isRequired,
  countryRXS: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  authRXS: state.authRXS,
  countryRXS: state.countryRXS,
});

export default connect(mapStateToProps, {})(AboutUs);
