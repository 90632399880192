const formHeaders = {
  selectedServices: '精选服务',
  personalInformation: '个人信息',
  personalInformationFindIDU: '个人信息 (输入至少3个输入字段)',
  personalName: '个人信息',
  personalInformationResidential: '个人信息',
  personalInformationSuper: '个人信息',
  currentResidentialAddress: '当前居住地址',
  currentResidentialAddressSuper: '当前居住地址',
  currentResidentialAddressResidential: '当前居住地址',
  currentResidentialAddressAutoComplete: '当前居住地址',
  driversLicence: '驾照',
  medicare: '医疗保险',
  passport: '护照',
  phone: '电话',
  phoneNo: '电话',
  email: '电子邮件',
  visaAndPassport: '护照/签证',
  visa: '显示',
  voterIdentificationNumber: '选民编号',
  contactDetails: '联系方式（推荐）',
  termsAndConditions: '同意书和条款和条件',
  chineseSearch: '中文搜索',
  laserNumber: '激光数',
  identityDetails: '身份信息',
  birthCertificate: '出生证明',
  citizenship: '国籍',
  landLineNumber: '座机身份证数据',
  nationalIDData: '国家身份证数据',
  accountNumber: '账号',
  nik: '居民身份证（NIK）',
  extraNames: '多余的名字',
  passportAndVisa: '护照/签证',
  voterNo: '选民编号',
  clientRef: '客户参考',
  clientReference: '客户参考',
  watchlistAML: '希望停止了',
  nationalIDNumber: '身份证号码',
  phoneNumber: '电话号码',
  ocrAndBiometric: 'OCR和生物计量认证',
  centerlink: '中心链接',
  socialSecurity: '社会保障',
  taxRegistration: '税务登记',
  ABN: '澳洲商家号码',
  taxIDNo: '税号', // ITALY
  chinaID: '中国身份证',
  passportAustralia: '澳大利亚护照',
  passportIndia: '印度护照',
  driversLicenceAustralia: '澳大利亚驾照',
  driversLicenceNewZealand: '新西兰驾照',
  driversLicenceIndia: '印度驾照',
};
export default formHeaders;
