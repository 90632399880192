const dataSourcesHK = {
  creditBureau: {
    title: 'Biro Kredit Hong Kong',
    searchSuccessful: 'Carian Berjaya',
    nationalIDNo: 'ID kebangsaan',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Markah Padanan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    street: 'Jalan',
    district: 'Daerah',
    city: 'Bandar',
    state: 'Nyatakan',
    postCode: 'Poskod',
  },
  residential: {
    title: 'Kediaman Hong Kong',
    searchSuccessful: 'Carian Berjaya',
    nationalIDNo: 'ID kebangsaan',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Markah Padanan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    street: 'Jalan',
    district: 'Daerah',
    city: 'Bandar',
    state: 'Nyatakan',
    postCode: 'Poskod',
  },
};

export default dataSourcesHK;
