import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../../../../components/header/Header';
import UnsuccessfulSearchResultCards from "../../../reusableComponent/UnsuccessfulSearchResultCards";

// VALIDATIONS
import isEmptyVAL from '../../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../../utils/transUTL';

// COMPONENTS
const CambodiaConsumerData = ({ results, type }) => {
  if (isEmptyVAL(results[type])) return null;

  const data = results[type] ? results[type] : {};
  let {
    identityVerified,
    nameMatchScore,
    addressMatchScore,
    safeHarbourScore,
    verifications,
    returnedData,
    status,
    errorMessage
  } = data;

  if (isEmptyVAL(verifications)) verifications = {};
  if (isEmptyVAL(returnedData)) returnedData = {};

  return (
    <div className="search-verification__results">
      <Header
        title={transUTL(
          'translateSearchVerification.dataSourcesKH.consumerData.title'
        )}
      />

      <div className="search-verification__verify">
        {
          status === 3
            ? <UnsuccessfulSearchResultCards errorMessage={errorMessage} />
            : <>
              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.searchSuccessful'
                    )}
                  </p>
                  <span className="material-icons success">check_circle</span>
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.safeHarbourScore'
                    )}
                  </p>
                  {!isEmptyVAL(safeHarbourScore) ? (
                    <span>{safeHarbourScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.identityVerified'
                    )}
                  </p>
                  {!isEmptyVAL(identityVerified) ? (
                    identityVerified ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.voterNo'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.voterNo) ? (
                    verifications.voterNo ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.firstName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.firstName) ? (
                    verifications.firstName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.lastName'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.lastName) ? (
                    verifications.lastName ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>

              <div className="search-verification__col">
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.dateOfBirth'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.dateOfBirth) ? (
                    verifications.dateOfBirth ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.gender'
                    )}
                  </p>
                  {!isEmptyVAL(verifications.gender) ? (
                    verifications.gender ? (
                      <span className="material-icons success">check_circle</span>
                    ) : (
                      <span className="material-icons error">cancel</span>
                    )
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.nameMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(nameMatchScore) ? (
                    <span>{nameMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
                <div className="search-verification__verify-item">
                  <p>
                    {transUTL(
                      'translateSearchVerification.dataSourcesKH.consumerData.addressMatchScore'
                    )}
                  </p>
                  {!isEmptyVAL(addressMatchScore) ? (
                    <span>{addressMatchScore}</span>
                  ) : (
                    <span>{transUTL('translateReuse.na')}</span>
                  )}
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};

CambodiaConsumerData.propTypes = {
  results: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default CambodiaConsumerData;
