const consentChecker = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('Philippines Suspicious Activity')) {
    // CONSENT COLLECTOR
    consentObtained['Philippines Suspicious Activity'] = true;
  }
  if (dataSources.includes('Digital ID Safeguard')) {
    // CONSENT COLLECTOR
    consentObtained['Digital ID Safeguard'] = true;
  }

  return { consentObtained, isConsent: true };
};

export default consentChecker;
