// HELPERS
import tableFilter from '../../../../helpers/tableFilter';

// UTILS
import naUTL from '../../../../../../utils/naUTL';

const jsonTableRow = (dataSources) => {
  const { nationalID, taxRegistration, watchlistAML } = dataSources;

  const table = [
    {
      col1: 'Status',
      col2: naUTL(nationalID.status),
      col3: naUTL(taxRegistration.status),
      col4: naUTL(watchlistAML.status),
    },
    {
      col1: 'Safe Harbour Score',
      col2: naUTL(nationalID.safeHarbourScore),
      col3: naUTL(taxRegistration.safeHarbourScore),
      col4: naUTL(watchlistAML.safeHarbourScore),
    },
    {
      col1: 'Identity Verified',
      col2: naUTL(nationalID.identityVerified),
      col3: naUTL(taxRegistration.identityVerified),
      col4: naUTL(watchlistAML.identityVerified),
    },
    {
      col1: 'Curp Verified',
      col2: naUTL(nationalID.verifications.curp),
      col3: naUTL(taxRegistration.verifications.curp),
      col4: naUTL(watchlistAML.verifications.curp),
    },
    {
      col1: 'RFC Verified',
      col2: naUTL(nationalID.verifications.rfc),
      col3: naUTL(taxRegistration.verifications.rfc),
      col4: naUTL(watchlistAML.verifications.rfc),
    },
    {
      col1: 'First Name Verified',
      col2: naUTL(nationalID.verifications.firstName),
      col3: naUTL(taxRegistration.verifications.firstName),
      col4: naUTL(watchlistAML.verifications.firstName),
    },
    {
      col1: 'Middle Name Verified',
      col2: naUTL(nationalID.verifications.middleName),
      col3: naUTL(taxRegistration.verifications.middleName),
      col4: naUTL(watchlistAML.verifications.middleName),
    },
    {
      col1: 'Last Name Verified',
      col2: naUTL(nationalID.verifications.lastName),
      col3: naUTL(taxRegistration.verifications.lastName),
      col4: naUTL(watchlistAML.verifications.lastName),
    },
    {
      col1: 'Name Match Score',
      col2: naUTL(nationalID.nameMatchScore),
      col3: naUTL(taxRegistration.nameMatchScore),
      col4: naUTL(watchlistAML.nameMatchScore),
    },
    {
      col1: 'Date Of Birth Verified',
      col2: naUTL(nationalID.verifications.dateOfBirth),
      col3: naUTL(taxRegistration.verifications.dateOfBirth),
      col4: naUTL(watchlistAML.verifications.dateOfBirth),
    },
    {
      col1: 'Gender Verified',
      col2: naUTL(nationalID.verifications.gender),
      col3: naUTL(taxRegistration.verifications.gender),
      col4: naUTL(watchlistAML.verifications.gender),
    },
    {
      col1: 'Address Match Score',
      col2: naUTL(nationalID.addressMatchScore),
      col3: naUTL(taxRegistration.addressMatchScore),
      col4: naUTL(watchlistAML.addressMatchScore),
    },
    {
      col1: 'Error Message',
      col2: naUTL(nationalID.errorMessage),
      col3: naUTL(taxRegistration.errorMessage),
      col4: naUTL(watchlistAML.errorMessage),
    },
  ];

  return tableFilter(table);
};

export default jsonTableRow;
