const inputLabels = {
  // CREDENTIALS
  username: 'NAMA PENGGUNA', 
  password: 'KATA LALUAN',
  // PERSONAL INFORMATION
  firstName: 'NAMA PERTAMA',
  middleName: 'NAMA TENGAH',
  lastName: 'NAMA TERAKHIR',
  dateOfBirth: 'TARIKH LAHIR',
  gender: 'JANTINA',
  fullName: 'NAMA PENUH',
  yearOfBirth: 'TAHUN KELAHIRAN',

  // CURRENT RESIDENTIAL ADDRESS
  building: 'BANGUNAN',
  block: 'JALAN / BLOK NO.',
  unitNo: 'UNIT NO.',
  addressLine1: 'ALAMAT ALAMAT 1',
  addressLine2: 'ALAMAT ALAMAT 2',
  street: 'JALAN',
  streetNo: 'NOMBOR JALAN.',
  streetName: 'NAMA JALAN',
  streetType: 'JENIS JALAN',
  streetNumberAndName: 'NOMBOR DAN NAMA JALAN',
  townOrCity: 'BANDAR / KOTA',
  suburb: 'SUBURB',
  county: 'DAERAH',
  state: 'NEGERI',
  postCode: 'KOD POST',
  postcode: 'KOD POST',
  zipCode: 'POSKOD',
  postCodeSG: 'Unit # dan Poskod',
  streetNoAndUnitNo: 'UNIT / JALAN NO.',
  city: 'KOTA',
  ward: 'PERANG',
  region: 'WILAYAH',
  barangay: 'BARANGAY',
  province: 'PROVINSI',
  locality: 'LOKALITI',
  district: 'DAERAH',
  commune: 'AMAT',
  town: 'BANDAR',
  streetNameIDN: 'JALAN (ALAMAT) ', // Indonesiaia
  villageIDN: 'DESA (KELURAHAN) ', // Indonesiaia
  districtIDN: 'DAERAH (KECAMATAN) ', // Indonesiaia
  cityIDN: 'KOTA / REGENSI (DAERAH) ', // Indonesiaia
  addressOCRVN: 'Alamat ', // VIETNAMAM
  lga: 'lga', // NIGERIA - Local Government Area
  streetNameAndHouseNo: 'NAMA JALAN DAN RUMAH NO.',
  regionCode: 'KOD DAERAH', // ITALY, CZECH
  houseNumber: 'RUMAH NO.', // ITALY, CZECH

  // DRIVERS LICENCE
  driversLicenceNo: 'LESEN NO.',
  driversLicenceNumber: 'LESEN NO.',
  driversLicenceCardNo: 'KAD NO.',
  driversLicenceStateOfIssue: 'NEGERI MASALAH',
  driversLicenceExpiryDate: 'Tarikh luput',
  driversLicenceVersion: 'VERSI LESEN PEMANDU',
  vehiclePlate: 'PENDAFTARAN KENDERAAN',

  // MEDICARE
  medicareCardNo: 'NOMBOR KAD.',
  medicareCardType: 'JENIS KAD PERUBATAN',
  medicareReferenceNo: 'REF INDIVIDU BIL.',
  medicareExpiry: 'TARIKH LUPUT',

  // PASSPORT
  passportNo: 'PASSPORT NO.',
  passportIssuerCountry: 'NEGARA MASALAH',
  passportExpiry: 'TARIKH LUPUT',
  countryCode: 'KOD NEGARA',
  fileNo: 'failNo',
  dateOfIssue: 'Tarikh dikeluarkan',

  // CONTACT DETAILS
  mobileNo: 'TELEFON BERGERAK NO.',
  email: 'EMEL',
  phoneType: 'JENIS TELEFON',
  phoneNumber: 'TELEFON NO.',
  telephone: 'Telefon',
  emailAddress: 'ALAMAT EMEL',

  // CHINESE SEARCH
  idCardNo: 'KAD PENGENALAN NO.',
  bankCardNo: 'KAD BANK NO.',
  phoneNo: 'TELEFON NO.',
  ocrScan: 'SCAN & OCR',

  // IDENTITY DATA
  laserNo: 'Nombor Laser.',
  idNo: 'KAD PENGENALAN NO.',
  vin: 'ID Nasional No.',
  voterNo: 'ID Pengundi',
  nationalIDNo: 'No. ID Nasional',
  landlineNo: 'No. Talian Darat',
  nationalID: 'ID Nasional',
  nationalIDType: 'Jenis ID kebangsaan',
  curp: 'Curp',
  rfc: 'RFC',
  taxCodeNo: 'Kod Cukai No.',
  nationalIdNo: 'No. ID Nasional',
  
  // BIRTH CERTIFICATE
  birthRegistrationState: 'NEGERI PENDAFTARAN Kelahiran',
  birthRegistrationNo: 'PENDAFTARAN LAHIR NO.',
  birthRegistrationDate: 'TARIKH PENDAFTARAN Kelahiran',
  birthCertificateNo: 'SIJIL LAHIR NO.',
  isRequiredCertificateNo: 'Nombor sijil kelahiran mesti disediakan untuk:',
  isRequiredCertificateNo_NSW: 'Sijil kelahiran NSW dikeluarkan selepas 01/07/2010',
  isRequiredCertificateNo_SA: 'Sijil kelahiran SA dikeluarkan selepas 01/11/1999',
  isRequiredCertificateNo_Others: 'Semua sijil kelahiran ACT dan NT', // ACT and NT in Australia
  placeOfBirth: 'TEMPAT LAHIR',
  mothersFirstName: 'NAMA PERTAMA IBU',
  mothersLastName: 'IBU NAMA TERAKHIR',
  fathersFirstName: 'NAMA PERTAMA BAPA',
  fathersLastName: 'NAMA TERAKHIR BAPA',

  // CENTERLINK
  centrelinkCardType: 'JENIS KAD CENTERLINK',
  centrelinkCardExpiry: 'TAMBAHAN KAD CENTERLINK',
  centrelinkCustomerReferenceNo: 'RUJUKAN PELANGGAN CENTERLINK NO.',

  // CITIZENSHIP
  countryOfBirth: 'NEGARA KELAHIRAN',
  citizenshipCertificateNo: 'SIJIL NO.',

  // NATIONAL ID DATA
  idcardNoType: 'JENIS KAD ID',
  searchType: 'JENIS CARI',

  // ACCOUNT NUMBER
  pan: 'India PAN',
  epic: 'EPIC NO.',
  aadhaarNo: 'Aadhaar NO.',

  // RESIDENT IDENTITY CARD (NIK)
  nik: 'Saya',

  // CARD TYPE
  cardType: 'Jenis kad',

  // NIGERIA BANK NO
  bankVerificationNo: 'PENGESAHAN BANK NO.',

  // MATERNAL NAMES
  maternalName: 'NAMA BAHAN',
  paternalName: 'NAMA POLA',

  // MOBILE VERIFICATION
  pinCode: 'Kod PIN',
  receiver: 'Nombor telefon bimbit.',
  sender: 'Dari',

  // SOCIAL SECURITY
  socialSecurityNo: 'Jaminan Sosial No.',

  // Consent
  consent: 'Saya bersetuju dengan Terma dan Syarat',

  // REPORTING REFERENCE
  reportingReference: 'RUJUKAN PELANGGAN (ANDA)',
  clientReference: 'RUJUKAN PELANGGAN (ANDA)',

  // TAX REGISTRATION
  taxIDNo: 'No. ID Cukai',
  cpf: 'CPF (Cadastro de Pessoas Físicas)',
  npwp: 'NPWP (Nomor Pokok Wajib Pajak - Nombor ID Cukai)',

  // ABN
  ABN: 'Nombor perniagaan Australia',

  //FIND IDU
  orgId: 'ID ORGANISASI',
  emailID: 'ID EMAIL',
  hitTarget: 'HIT TARGET',
};
export default inputLabels;
