import React from 'react';
import PropTypes from 'prop-types';

// INPUT
import TextFieldGroup from '../../../../components/common/textFieldGroup/TextFieldGroup';
import CheckBoxGroup from "../../../../components/common/checkBoxGroup/CheckBoxGroup";

// VALIDATIONS
import isEmptyVAL from '../../../../validations/checks/isEmptyVAL';

// UTILITIES
import { transUTL } from '../../../../utils/transUTL';
import { outputMasterUTL } from '../../../../utils/outputMasterUTL';

const PersonalInformation = ({
  formState,
  includeWatchList,
  includeSafeguardSearch,
  handleOnChange,
  handleOnBlur,
  handleEnterKeyTrigger,
  handleToggleIncludeWatchlist,
  handleToggleIncludeSafeguardSearch
}) => {
  const {
    firstName,
    middleName,
    lastName,
    yearOfBirth,
    barangay,
    city,
    province,
    postCode,
    orgId,
    email,
    hitTarget,
    errors,
  } = formState;

  const inputElements = [

    {
      belongsTo: [
        'Digital ID Safeguard'
      ],
      element: (
        <TextFieldGroup
          key="email"
          id="email"
          type="text"
          name="email"
          dataName="email"
          labelClass="form-group__label"
          label={transUTL('translateFormOverview.inputLabels.email')}
          value={email}
          placeholder='jsmith@datazoo.co.nz'
          placeholderTrigger={isEmptyVAL(email) ? 'input-empty' : ''}
          error={errors.email}
          errorTrigger={errors.email ? 'input-error' : ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleEnterKeyTrigger={handleEnterKeyTrigger}
        />
      ),
    },
  ];

  return (
    <div className="form-overview__form-inputs">
      {inputElements.map(e => e.element)}

      <div className="form-overview__terms">
        {/* <CheckBoxGroup
          key="includeWatchList"
          checkboxBool={includeWatchList}
          label={transUTL('translateFormOverview.includeWatchList')}
          handleToggleCheckbox={() => handleToggleIncludeWatchlist(includeWatchList)} />

        <CheckBoxGroup
          key="includeSafeguardSearch"
          checkboxBool={includeSafeguardSearch}
          label={transUTL('translateFormOverview.includeSafeguardSearch')}
          handleToggleCheckbox={() => handleToggleIncludeSafeguardSearch(includeSafeguardSearch)} /> */}
      </div>
    </div>
  );
};

PersonalInformation.propTypes = {
  formState: PropTypes.object.isRequired,
  // selectedDataSources: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  handleEnterKeyTrigger: PropTypes.func.isRequired,
};

export default PersonalInformation;
