import * as Sentry from '@sentry/browser';

// TYPES
import { ERROR_FEEDBACK } from '../../types';

// UTILITIES
import findErrorUTL from '../../../utils/findErrorUTL';
import loggerUTL from '../../../utils/loggerUTL';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';

const feedbackCL = (errors, dispatch) => {
  loggerUTL('ERRORS...', errors);  
  const inputError = {
    personalInformation: {},
    nationalIDNumber: {}
  };

  const errorMsg = "You can't be born in the future"
  let dateOfBirthError = false;

  //Check whether user types the wrong date
  if (!isEmptyVAL(errors)) {
    for (let i = 0; i < errors.length; i++) {
      if (errors[i].substr(0,3) === "You") {
        dateOfBirthError = true;
      }
    }
  }
  
  try {
    /* ============================================
                PERSONAL INFORMATION
     ============================================ */
    if (findErrorUTL(errors, 'firstName')) {
      inputError.personalInformation.firstName = `First name ${
        errors.find((item) => item.includes('firstName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'lastName')) {
      inputError.personalInformation.lastName = `Last name ${
        errors.find((item) => item.includes('lastName')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'dateOfBirth')) {
      inputError.personalInformation.dateOfBirth = `Date of Birth ${
        errors.find((item) => item.includes('dateOfBirth')).split(':')[1]
      }`;
    }

    if (dateOfBirthError) {
      inputError.personalInformation.dateOfBirth = errorMsg;
    }

    if (findErrorUTL(errors, 'nationalIDNo')) { 
      inputError.nationalIDNumber.nationalIDNo = `National ID Number ${
        errors.find((item) => item.includes('nationalIDNo')).split(':')[1]
      }`;
    }

    if (findErrorUTL(errors, 'nationalIdNo')) { 
      inputError.nationalIDNumber.nationalIDNo = `National ID Number should be ${
        errors.find((item) => item.includes('nationalIdNo')).split(':')[1]
      }`;
    }

    /* ======================================
                  Residential
      ======================================= */
    if (findErrorUTL(errors, 'addressElement1')) {
      inputError.addressElement3 = `addressElement1 ${
        errors.find((item) => item.includes('addressElement')).split(':')[1]
      }`;
    }
    
    if (findErrorUTL(errors, 'addressElement3')) {
      inputError.addressElement3 = `addressElement3 ${
        errors.find((item) => item.includes('addressElement3')).split(':')[1]
      }`;
    }
    
  } catch (err) {
    Sentry.captureException(
      'FAILED TO PARES CHILE VALIDATION ERRORS.....',
      JSON.stringify
    )(errors);
  }

  dispatch({
    type: ERROR_FEEDBACK,
    payload: inputError,
  });
};

export default feedbackCL;