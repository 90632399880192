/*
  This array is being used temporarily.  It will be deleted after the template 
  has been applied to all services of all countries.
*/
const COUNTRIES_FOR_TEMPLATE = [
  'slovakia',
  'belgium',
  'morocco',
  'chile',
  'southafrica',
  'unitedkingdom',
  'switzerland',
  'thailand',
  'greece',
  'poland',
  'austria',
  'peru',
  'argentina',
  'italy',
  'czechrepublic',
  'bangladesh',
  // 'australia',
  'multiService',
  'kenya',
  'canada',
  'netherlands',
];

export default COUNTRIES_FOR_TEMPLATE;