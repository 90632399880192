const formHeaders = {
  selectedServices: 'SELECTED SERVICES',
  personalInformation: 'Personal Information',
  personalName: 'Personal Information',
  personalInformationWatchlistAML: 'Personal Information',
  personalInformationResidential: 'Personal Information',
  personalInformationSuper: 'Personal Information',
  personalInformationFindIDU: 'Personal Information (Enter at least 3 input fields)',
  currentResidentialAddress: 'Current Residential Address',
  currentResidentialAddressSuper: 'Current Residential Address',
  currentResidentialAddressResidential: 'Current Residential Address',
  currentResidentialAddressAutoComplete: 'Current Residential Address',
  AustraliaCurrentResidentialAddress: 'Australia Current Residential Address',
  driversLicence: 'Drivers Licence',
  medicare: 'Medicare',
  passport: 'Passport',
  phone: "Phone",
  phoneNo: "Phone",
  email: "Email",
  visaAndPassport: 'Passport / Visa',
  visa: 'Visa',
  voterIdentificationNumber: 'Voter Identification Number',
  contactDetails: 'Contact Details (Recommended)',
  termsAndConditions: 'Consent and Terms & Conditions',
  laserNumber: 'Laser Number',
  chineseSearch: 'Chinese Search',
  identityDetails: 'Identity Details',
  birthCertificate: 'Birth Certificate',
  citizenship: 'Citizenship',
  nationalIDData: 'National ID Data',
  landLineNumber: 'Land Line Number',
  accountNumber: 'Account Number',
  nik: 'Resident Identity Card (NIK)',
  extraNames: 'Extra Names',
  passportAndVisa: 'Passport / Visa',
  voterNo: 'Voter ID',
  clientRef: 'Client Reference',
  clientReference: 'Client Reference',
  watchlistAML: 'Watchlist AML',
  nationalIDNumber: 'National ID Number',
  phoneNumber: 'Phone Number',
  ocrAndBiometric: 'OCR & BIOMETRIC AUTHENTICATION',
  centerlink: 'CENTERLINK',
  socialSecurity: 'Social Security',
  taxRegistration: 'Tax Registration',
  ABN: 'ABN details',
  taxIDNo: 'Tax ID Number', // ITALY
  chinaID: 'China ID',
  passportAustralia: 'Australia Passport',
  passportIndia: 'India Passport',
  driversLicenceAustralia: 'Australia drivers Licence',
  driversLicenceNewZealand: 'New Zealand drivers Licence',
  driversLicenceIndia: 'India drivers Licence',
  
};
export default formHeaders;
