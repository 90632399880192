import React, { useState } from 'react';
import FORM_FIELD_TYPES from '../../../constants/formFieldTypes';
import TextFieldGroup from '../../common/textFieldGroup/TextFieldGroup';
import SelectListGroup from '../../common/selectListGroup/SelectListGroup';
import isEmptyVAL from '../../../validations/checks/isEmptyVAL';
import { transUTL } from '../../../utils/transUTL';
import translateOptions from "./helpers/optionsForSelectList";

const InputFields = ({
  sectionKey,
  fields,
  data,
  errors,
  handleOnBlur,
  handleOnChange,
  country
}) => {

  /*
    Temporary work:
    Add country name (lowercase with nospace, same as countryRXS) that needs to add placeholder.
    Adding this array to avoid impacting exsiting services.
    Once all countries using formOverviewTemplate are added placeholder, this array will be removed.
  */
 
  const COUNTRIES_FOR_PLACEHOLDER = [
    'italy'
  ]

  const getInputCountrySufix = (country) => {
    let inputCountrySufix = '';
    switch (country) {
      case 'italy':
        inputCountrySufix = 'IT';
        break;
      /* 
        Add new country below, which is using formOverviewTemplate
      */
      default:
        break;
    }
    return inputCountrySufix;
  }

  const transPlaceholder = (country, itemName) => {
    if (COUNTRIES_FOR_PLACEHOLDER.includes(country)) {
      const base = 'translatePlaceholder';
      const countryInput = getInputCountrySufix(country);
      return transUTL(`${base}.input${countryInput}.${itemName}`);
    } else {
      return ''
    }
  }

  const getInputType = (inputField) => {
    const {name, type} = inputField;
    const {medicareCardType} = data;
    let medicareExpiry_inputType = type;
    if (sectionKey === 'medicare') {
      if (name === 'medicareExpiry') {
        if (medicareCardType === "" || medicareCardType === "G") {
          medicareExpiry_inputType = 'month';
        }
      }
    }
    return medicareExpiry_inputType;
  }

  return (
    <div className="form-overview__form-inputs">
      {fields.map(item => {
        switch (item.type) {
          case FORM_FIELD_TYPES.TEXT:
          case FORM_FIELD_TYPES.DATE:
            return (
              <TextFieldGroup
                key={item.name}
                id={item.name}
                name={item.name}
                value={data[item.name] || ''}
                dataName={item.name}
                type={getInputType(item)}
                max="9999-12-31"
                error={errors[item.name]}
                errorTrigger={errors[item.name] ? 'input-error' : ''}
                labelClass="form-group__label"
                label={transUTL(
                  `translateFormOverview.inputLabels.${item.name}`
                )}
                placeholder={transPlaceholder(country, item.name)}
                placeholderTrigger={
                  isEmptyVAL(data[item.name]) ? 'input-empty' : ''
                }
                handleOnChange={event => handleOnChange(event, sectionKey)}
                handleOnBlur={handleOnBlur}
              />
            );
          case FORM_FIELD_TYPES.SELECTLIST:
            return (
              <SelectListGroup
                key={item.name}
                id={item.name}
                name={item.name}
                value={data[item.name] || ''}
                dataName={item.name}
                error={errors[item.name]}
                errorTrigger={errors[item.name] ? 'input-error' : ''}
                labelClass="form-group__label"
                label={transUTL(
                  `translateFormOverview.inputLabels.${item.name}`
                )}
                placeholderTrigger={
                  isEmptyVAL(data[item.name]) ? 'input-empty' : ''
                }
                options={isEmptyVAL(translateOptions(item.name)) ? item.options : translateOptions(item.name)}
                handleOnChange={event => handleOnChange(event, sectionKey)}
                handleOnBlur={handleOnBlur}
              />
            );
          case FORM_FIELD_TYPES.TEL:
            return (
              <TextFieldGroup
                key={item.name}
                id={item.name}
                name={item.name}
                value={data[item.name] || '+32' + data[item.name]}
                // pattern="[1-9]{1}[0-9]{9}"
                dataName={item.name}
                type={getInputType(item)}
                error={errors[item.name]}
                errorTrigger={errors[item.name] ? 'input-error' : ''}
                labelClass="form-group__label"
                label={transUTL(
                  `translateFormOverview.inputLabels.${item.name}`
                )}
                placeholder={transPlaceholder(country, item.name)}
                placeholderTrigger={
                  isEmptyVAL(data[item.name]) ? 'input-empty' : ''
                }
                handleOnChange={event => handleOnChange(event, sectionKey)}
                handleOnBlur={handleOnBlur}
              />
            );
          default:
            return '';
        }
      })}
      <span />
    </div>
  );
};

export default InputFields;
