import moment from 'moment';
import isEmptyVAL from '../checks/isEmptyVAL';
import isValidDateVAL from '../checks/isValidDateVAL';

import { transUTL } from '../../utils/transUTL';
import formatDateUTL from '../../utils/formatDateUTL';
import loggerUTL from '../../utils/loggerUTL';

const franceVAL = (data, dataSources) => {
  const inputErrors = {};
  
  // loggerUTL('VALIDATING FRANCE ALL...');
  data.firstName = !isEmptyVAL(data.firstName) ? data.firstName : '';
  data.lastName = !isEmptyVAL(data.lastName) ? data.lastName : '';

  // ERROR FEEDBACK ACCUMULATOR
  if (isEmptyVAL(data.firstName)) {
    inputErrors.firstName = transUTL(
      'translateFormOverview.formErrors.firstName'
    );
  }
  if (isEmptyVAL(data.lastName)) {
    inputErrors.lastName = transUTL(
      'translateFormOverview.formErrors.lastName'
    );
  }

  /* ============================================
          FRANCE RESIDENTIAL
  ============================================ */
  if (dataSources.includes('France Residential')) {
    loggerUTL('VALIDATING FRANCE RESIDENTIAL...');

    data.city = !isEmptyVAL(data.city) ? data.city : '';
    if (isEmptyVAL(data.streetAddress)) {
      inputErrors.streetAddress = transUTL(
        'translateFormOverview.formErrors.streetAddress'
      );
    }
    if (isEmptyVAL(data.city)) {
      inputErrors.city = transUTL(
        'translateFormOverview.formErrors.city'
      )
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      )
    }
  }

  /* ============================================
          FRANCE RESIDENTIAL 2
  ============================================ */
  if (dataSources.includes('France Residential 2')) {
    loggerUTL('VALIDATING FRANCE RESIDENTIAL 2...');

    if (isEmptyVAL(data.streetAddress)) {
      inputErrors.streetAddress = transUTL(
        'translateFormOverview.formErrors.streetAddress'
      );
    }
    if (isEmptyVAL(data.city)) {
      inputErrors.city = transUTL(
        'translateFormOverview.formErrors.city'
      )
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      )
    }
  }

    /* ============================================
          FRANCE RESIDENTIAL 3
  ============================================ */
  if (dataSources.includes('France Residential 3')) {
    loggerUTL('VALIDATING FRANCE RESIDENTIAL 3...');

    if (isEmptyVAL(data.streetAddress)) {
      inputErrors.streetAddress = transUTL(
        'translateFormOverview.formErrors.streetAddress'
      );
    }
    if (isEmptyVAL(data.city)) {
      inputErrors.city = transUTL(
        'translateFormOverview.formErrors.city'
      )
    }
    if (isEmptyVAL(data.postCode)) {
      inputErrors.postCode = transUTL(
        'translateFormOverview.formErrors.postCode'
      )
    }
  }

  /* ============================================
          WATCHLIST AML
  ============================================ */
  // if (dataSources.includes('Watchlist AML')) {
  //   loggerUTL('VALIDATING WATCHLIST AML...');
  // }


  /* ============================================
            DATE FORMAT
   ============================================ */
  if (!isEmptyVAL(data.dateOfBirth)) {
    const dateFormatted = formatDateUTL(data.dateOfBirth);

    if (!isEmptyVAL(dateFormatted) && !isValidDateVAL(dateFormatted)) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }

    if (dateFormatted > moment().format('YYYY-MM-DD')) {
      inputErrors.dateOfBirth = transUTL(
        'translateFormOverview.formErrors.dateOfBirthFormat'
      );
    }
  }

  // RETURN ERRORS
  return {
    inputErrors,
    isValid: isEmptyVAL(inputErrors),
  };
};

export default franceVAL;
