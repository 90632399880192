import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxGroup from '../checkBoxGroup/CheckBoxGroup';

const MultipleSelectGroup = ({
  selectItems,
  selectedItems,
  handleToggle,
  handleToggleAll,
}) => {

  const items = selectItems.map((item) => {
    const selected = selectedItems.includes(item);

    return ({
      label: item,
      selected: selected
    })
  });

  let allSelected = true;
  for (const item of items) {
    if (item.selected === false) {
      allSelected = false;
      break;
    }
  }

  return (
    <div className="form-overview__form-multiple-select">
      {
        items.map((item) => {
          return (
            <div
              key={item.label}
            >
              <CheckBoxGroup
                label={item.label}
                checkboxBool={item.selected}
                handleToggleCheckbox={() => handleToggle(item.label)}
              />
            </div>
          )
        })
      }
      <div>
        <CheckBoxGroup
          label='Select All'
          checkboxBool={allSelected}
          handleToggleCheckbox={() => handleToggleAll(selectItems, allSelected)}
        />
      </div>
    </div>
  )
}

MultipleSelectGroup.propTypes = {
  selectItems: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleToggleAll: PropTypes.func.isRequired
};

export default MultipleSelectGroup;