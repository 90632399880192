const consentCheckerVN = (dataSources, inputData) => {
  const consentObtained = {}; // COLLECTS USER CONSENTS

  // USER CONSENT CHECK
  if (dataSources.includes('Vietnam Consumer Data')) {
    // CONSENT COLLECTOR
    consentObtained['Vietnam Consumer Data'] = true;

    if (!inputData.vietnamConsumerDataConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Vietnam National ID')) {
    // CONSENT COLLECTOR
    consentObtained['Vietnam National ID'] = true;

    if (!inputData.nationalIDConsentObtained) {
      return { isConsent: false };
    }
  }
  if (dataSources.includes('Vietnam Social Security')) {
    // CONSENT COLLECTOR
    consentObtained['Vietnam Social Security'] = true;

    if (!inputData.socialSecurityConsentObtained) {
      return { isConsent: false };
    }
  }

  return { consentObtained, isConsent: true };
};

export default consentCheckerVN;
