const translateAlertMsg = {
  correctCardType: 'Please selected the correct card type', // ID CARD TYPE
  timeout: 'Timeout exceeded. Please try again later',
  pleaseSelectCountry: 'Please select a country',
  pleaseSelectDataSource: 'Data Source(s) must be selected',
  consent: 'You must agree to the terms and conditions',
  selectCompleteAddress: 'Please select the auto completed address',
  didNotPickUpStreetNo:
    'Opps, did not pick up street number, manual input required',
  didNotPickUpStreetName:
    'Opps, did not pick up street name, manual input required',
  didNotPickUpStreetSuburb:
    'Opps, did not pick up suburb, manual input required',
  didNotPickUpPostCode: 'Opps, did not pick up postcode, manual input required',
  uploadPhoto: 'Please take a photo or upload a file ',
  selectCardType: 'Card type must be selected ',
  acceptableFileType: 'Acceptable file types are: jpeg, jpg, bmp and png. ',
  thereIsNoImage: 'There is no image file uploaded',
  providePhoneNumber: 'Please provide your phone number',
  providePinCode: 'Please provide pin code',
  authIncorrectCredentials:
    "Hmm, that's not the right username and password combination. Please try again...",
  contactSupport:
    'An error has occurred during the search, please try again later. If the problems persist please contact the support at support@datazoo.com',
  error: 'error',
  success: 'success',
  warning: 'warning',
  inputErrors: 'Please fill in the forms correctly',
  emptyInput: 'Minimum 3 inputs are required. Either firstname or lastname should be one of the 3 inputs',
  addressInput: 'Request must include Street No and Name accompanied by either Suburb, City or Postcode',
  passwordAdviceMsg: 'Please provide a message',
  passwordAdviceReceiver: 'Please provide a the receiver',
  msgSent: 'Password distribution successful',
  searchSuccessful: 'Search successful',
  searchUnSuccessful: 'Search unsuccessful',
  sessionTimeout: 'Session timeout, please re-login',
  pdfError:
    'PDF generation is currently unavailable for the selected data source',
  completeDocumentScanFirst: 'You must scan an identity document',
  max3: 'Maximum of 3 files',
  max1: 'Maximum of 1 file',
  uploadIdentityDocs: 'Please upload identity documents',
  compareIDtoSelfie:
    'Please make sure to take a selfie and select a identity document you wish to compare to',
  takeASelfie: 'Please take a selfie or upload selfie',
  dateSelection: 'Please select date',
  clientSelection: 'Please select client',
  inputRequired: 'Input required for search',
  serverTimeOut: 'Server timeout. Please try again later',
  collectiveSize: 'The collective size of the document is too large',
  uploadError: 'The collective size of the document is too large',
  expiredDocument:
    'The scanned document has or may be expired and may result in a non-verification.',
  notMatchSelectedCountry:
    'The identification document(s) provided does not match the selected country.',
  inputMappingError:
    'An error has occurred during document scan, please try again later. If the problems persist please contact the support at support@datazoo.com',
  errTextMsg:
    'Error sending text... Make sure the phone number is formatted correctly (+61...)',
  daonOcrNotAvailable: 'Document scan not available for the selected country',
  scanResultsEmpty: 'The document(s) uploaded did not return any data...',
  unableToReadScan: 'Unable to scan and recognize the document(s)',
  networkError: 'Network Error... Please try again later',
  documentType: 'Please select document type',
  notSuportingUploadedFileFormat: "Supported formats are '.jpg', '.jpeg', '.gif', '.png', '.svg'.",
  fileTooLargeToUpload: 'Maximum file size is 9mb.',
  atLeast3InputsError: 'Please fill at least 3 input fields',
  noRecordsFound: 'No records found',
};

export default translateAlertMsg;
