export const CROSS_BORDER_VERIFICATION = 'multiService'

export const CROSS_BORDER_DATA_SOURCE_SELECTION_ROUTE = '/cross-border-data-source-selection'

export const CROSS_BORDER_DATA_SOURCES = [

  "Australia Residential",
  "Australia Driver Licence",
  "Australia Visa",
  "Australia Medicare Card",
  "Australia Passport",
  'China ID Verification',
  'Indonesia Resident Identity Card',
  'India PAN',
  'India Passport',
  'India Drivers Licence',
  'New Zealand Driver Licence',
  'Watchlist AML'

]
