const initialStatePH = {
  // NATIONAL ID DATA
  nationalIDNo: '',
  nationalIDType: '',
  searchType: '',

  // PERSONAL INFORMATION
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',

  // OCR FILE NAMES
  fileNames: {
    file1: '',
    file2: '',
  },

  // VIN
  vin: '',

  // CURRENT RESIDENTIAL ADDRESS
  streetNo: '',
  streetName: '',
  barangay: '',
  city: '',
  province: '',
  postCode: '',
  region: '',

  // CONTACT DETAILS
  phoneType: '',
  phoneNumber: '',

  // TERMS AND CONDITIONS
  residentialConsentObtained: false,
  creditBureauConsentObtained: false,

  // REFERENCE NUMBER
  reportingReference: '',

  // ERROR HANDLING
  errors: {},
};

export default initialStatePH;
