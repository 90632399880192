const dataSourcesPH = {
  residential: {
    title: 'Kediaman Filipina',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    firstName: 'Nama pertama',
    middleName: 'Nama tengah',
    lastName: 'Nama terakhir',
    dateOfBirth: 'Tarikh lahir',
    streetName: 'Nama jalan',
    province: 'Wilayah',
    city: 'Bandar',
    gender: 'Jantina',
    barangay: 'barangay',
    postcode: 'Poskod',
    address: 'Alamat',
  },
  creditBureau: {
    title: 'Biro Kredit Filipina',
    searchSuccessful: 'Carian Berjaya',
    identityVerified: 'Identiti Disahkan',
    safeHarbourScore: 'Skor Pelabuhan Selamat',
    nameMatchScore: 'Skor Pertandingan Nama',
    addressMatchScore: 'Markah Padanan Alamat',
    nationalIDNo: "National ID Number",
    firstName: 'Nama Pertama',
    middleName: 'Nama Tengah',
    lastName: 'Nama Terakhir',
    dateOfBirth: 'Tarikh Lahir',
    streetName: 'Nama Jalan',
    province: 'Wilayah',
    city: 'City',
    gender: 'Gender',
    barangay: 'Barangay',
    postcode: 'Post Code',
    address: 'Address',
    requestTimedOut: 'Request Timed Out',
  },
  suspiciousActivity: {
    // 'FIND (ID)U RESULTS',
    title: 'AKTIVITI MENCURIGIKAN FILIPINA',
    FirstName: 'Nama pertama',
    MiddleName: 'Nama tengah',
    LastName: 'Nama terakhir',
    Gender: 'Jantina',
    VoterID: 'ID Pengundi',
    DOB: 'DOB',
    DayDOB: 'Hari',
    MonthDOB: 'Bulan',
    YearDOB: "Tahun",
    MaritalStatus: 'Perkahwinan',
    Street: 'Jalan',
    Barangay: 'Barangay',
    Region: 'Poskod',
    City: 'Bandar',
    Province: 'Wilayah',
    MotherLastName: 'Nama Keluarga Ibu',
    requestTimedOut: 'Permintaan Tamat Masa',
    searchSuccessful: 'Cari Berjaya',
  },
  watchlistAml: {
    title: 'PEP & HASIL SEKATAN',
    additionalInfoURL: 'URL',
    category: 'kategori',
    deathIndex: 'Indeks Kematian',
    gender: 'Jantina',
    otherNames: 'Other Names',
    scanId: "ID imbasan",
    whitelist: 'Senarai putih',
    yearOfBirth: 'Tahun kelahiran',
    requestTimedOut: 'Permintaan Tamat Masa',
    searchSuccessful: 'Cari Berjaya',
  },
  digitalIDSafeguard: {
    title: 'Keputusan Perlindungan ID Digital',
    isSafe: 'isSafe',
    firstName: 'Pertama',
    middleName: 'Tengah',
    lastName: 'Terakhir',
    dateOfBirth: 'Tarikh lahir',
    streetName: 'Nama Jalan',
    province: 'Wilayah',
    city: 'Bandar',
    barangay: 'Barangay',
    postcode: 'Poskod',
    requestTimedOut: 'Permintaan Tamat Masa',
    searchSuccessful: 'Cari Berjaya',
  }
};

export default dataSourcesPH;
