const watchlistAML = {
  title: 'Senarai Pemantau Global AML',
  WatchlistStatusText: 'Status Senarai Pantau PEP Antarabangsa',
  watchlistStatusClear: 'BERSIH',
  watchlistStatusFound: 'DITEMUKAN',
  category: 'Kategori',
  otherNames: 'Nama lain',
  fullReport: 'Laporan Penuh',
  safeHarbourScore: 'Skor Pelabuhan Selamat',
  pdfLink: 'Pautan PDF',
  searchSuccessful: 'Carian Berjaya',
};

export default watchlistAML;
